import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import Box from '@mui/material/Box';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import moment from "moment";

import requests from "../../components/axios/Requests";
import { 
    getTheDataForReportingOnNumberOfRegisteredUsers, 
    getTheDataForReportingOnNumberOfActiveUsers,
    getTheDataForReportingOnNumberOfLoggedInUsersForSelectedAccount,
    getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions,
    getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions
} from "../../servicesRedux/AdminService";

import { getMonthName, constructTimeSlotBasedOnAverageWatchTime, fixDateString } from "../../hooks/utils";

import { styles } from "./DashboardComponentStyles";

const HomePageComponent = props => {
    const { 
        classes, 
        startDate, 
        endDate, 
        selectedCompany, 
        selectedDateRange, 
        selectedCompanyAsObj, 
        sendMoreDetailsComponentToContext,
        changeOfMonthlySelectionEndPoint,
        setIsLoading
    } = props;   
    const [dataNumberOfUsers, setDataNumberOfUsers] = useState([]);
    const [dataNumberOfActualUsers, setDataNumberOfActualUsers] = useState([]);
    const [dataNumberOfRegisteredUsers, setDataNumberOfRegisteredUsers] = useState([]);    
    const [dataNumberOfActualRegisteredUsers, setDataNumberOfActualRegisteredUsers] = useState([]);
    const [loggedInUsersDataSet, setLoggedInUsersDataSet] = useState({});
    const [, setDataNumberOfVideosViewed] = useState([]);
    const [, setDataNumberOfVideosInMinutes] = useState([]);
    const [, setDataNumberOfVideosCompleted] = useState([]);
    const [dataNumberOfVideosViewedUnique, setDataNumberOfVideosViewedUnique] = useState(null);
    const [dataNumberOfVideosCompletedUnique, setDataNumberOfVideosCompletedUnique] = useState(null);
    const [watchAverageTime, setWatchAverageTime] = useState(null);
    const [firstEndPointLoaded, setFirstEndPointLoaded] = useState(null);
    const [secondEndPointLoaded, setSecondEndPointLoaded] = useState(null);
    const [thirdEndPointLoaded, setThirdEndPointLoaded] = useState(null);
    const [fourthEndPointLoaded, setFourthEndPointLoaded] = useState(null);
    const [fifthEndPointLoaded, setFifthEndPointLoaded] = useState(null);
    const [sixthEndPointLoaded, setSixthEndPointLoaded] = useState(null);
    const [dataNumberOfVideosCompletedUniqueAsPercentage, setDataNumberOfVideosCompletedUniqueAsPercentage] = useState(0);

    const optionsOnNumberOfActiveUsers = {
        title: `${(changeOfMonthlySelectionEndPoint ? "Monthly" : "Daily")} active users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,
        hAxis: { title: (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), titleTextStyle: { color: "#333" }, minValue: 1 },
        vAxis: { title: `Active Users`, minValue: 1 },
        chartArea: { width: "50%", height: "70%" },
        colors: ['#FB7A21'],
    };
    const optionsOnNumberOfRegisteredUsers = {
        title: `${(changeOfMonthlySelectionEndPoint ? "Monthly" : "Daily")} registered users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,
        hAxis: { title: (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), titleTextStyle: { color: "#333" }, minValue: 1 },
        vAxis: { title: `Registered users`, minValue: 1 },
        chartArea: { width: "50%", height: "70%" },
        colors: ['#498bc2'],
    };

    useEffect(() => {
        let tempVideos = [];
        setIsLoading(true);
        /* For the users */
        setFirstEndPointLoaded(true);
        getTheDataForReportingOnNumberOfRegisteredUsers((changeOfMonthlySelectionEndPoint ? requests.GetNoOfRegisteredUsersForThisMonth : requests.GetNoOfRegisteredUsers), startDate, endDate, selectedCompany).then(items => {            
            let tempUsers = [];
            tempUsers.push([
                (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), 
                `Nb of Registered Users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                { role: "style" }
            ]);
            if (changeOfMonthlySelectionEndPoint) {
                items.forEach(item => tempUsers.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.nbOfRegisteredUser, 
                    ""
                ]));
            } else {                
                items.forEach(item => tempUsers.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfRegisteredUser, 
                    ""
                ]));
            }            
            setDataNumberOfRegisteredUsers(tempUsers);
            const regUsers = items.map(item => item.nbOfRegisteredUser);            
            setDataNumberOfActualRegisteredUsers(regUsers.reduce((a, b) => a + b, 0));
            setFirstEndPointLoaded(false);
        });  
        setSecondEndPointLoaded(true);

        getTheDataForReportingOnNumberOfActiveUsers((changeOfMonthlySelectionEndPoint ? requests.GetNoOfActiveUsersForThisMonth : requests.GetNoOfActiveUsers), startDate, endDate, selectedCompany).then(items => {
            let tempUsers = [];
            tempUsers.push([
                (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), 
                `Nb of Active Users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                { role: "style" }
            ]);
            if (changeOfMonthlySelectionEndPoint) {
                items.kpiNbOfMonthlyActiveUser.forEach(item => tempUsers.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.nbOfActiveUser, 
                    ""
                ]));
            } else {                
                items.kpiNbOfDailyActiveUser.forEach(item => tempUsers.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfActiveUser, 
                    ""
                ]));
            }
            setDataNumberOfUsers(tempUsers);             
            setDataNumberOfActualUsers(items.uniqueActiveUserCount);
            setSecondEndPointLoaded(false);
        }); 
        getTheDataForReportingOnNumberOfLoggedInUsersForSelectedAccount(requests.GetNoOfLoggedInUsers, selectedCompany).then(item => setLoggedInUsersDataSet(item));       
        /* For the users */

        /* For the videos viewed */
        if (changeOfMonthlySelectionEndPoint) {
            setThirdEndPointLoaded(true);
            getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions(requests.GetNoOfMonthlyVideosViewed, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Month", 
                    `Nb of videos viewed ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                items.kpiNbOfMonthlyVideoViewed.forEach(item => tempVideos.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.nbOfVideos, 
                    ""
                ]));
                setDataNumberOfVideosViewed(tempVideos);       
                setDataNumberOfVideosViewedUnique(items.uniqueVideoViewedCount);      
                setThirdEndPointLoaded(false);
            });
        } else {
            setThirdEndPointLoaded(true);
            getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions(requests.GetNoOfDailyVideosViewed, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Day", 
                    `Nb of videos viewed ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                items.kpiNbOfDailyVideoViewed.forEach(item => tempVideos.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfVideos, 
                    ""
                ]));
                setDataNumberOfVideosViewed(tempVideos);  
                setDataNumberOfVideosViewedUnique(items.uniqueVideoViewedCount);                 
                setThirdEndPointLoaded(false);
            });           
        }  
        /* For the videos viewed */    
        
        /* For the videos in munutes */
        if (changeOfMonthlySelectionEndPoint) {
            setFourthEndPointLoaded(true);
            getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions(requests.GetNoOfMonthlyVideosMinutes, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Month", 
                    `Nb of videos in minutes ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                items.kpiNbOfMonthlyVideoMinutes.forEach(item => tempVideos.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.nbOfVideoMinutes, 
                    ""
                ]));
                setDataNumberOfVideosInMinutes(tempVideos);       
                setFourthEndPointLoaded(false);
            });
        } else {
            setFourthEndPointLoaded(true);
            getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions(requests.GetNoOfDailyVideosMinutes, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Day", 
                    `Nb of videos in minutes ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                items.kpiNbOfDailyVideoMinutes.forEach(item => tempVideos.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfVideoMinutes, 
                    ""
                ]));
                setDataNumberOfVideosInMinutes(tempVideos);  
                setFourthEndPointLoaded(false);
            });           
        }  
        /* For the videos in munutes */
        
        /* For the videos completed */
        if (changeOfMonthlySelectionEndPoint) {
            setFifthEndPointLoaded(true);
            getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions(requests.GetNoOfMonthlyVideosCompleted, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Month", 
                    `Nb of videos completed ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                items.monthlyVideoCompletedPercentage.forEach(item => tempVideos.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.percentageCompleted, 
                    ""
                ]));
                setDataNumberOfVideosCompleted(tempVideos);     
                setDataNumberOfVideosCompletedUnique(items.totalCompletedNb);              
                setDataNumberOfVideosCompletedUniqueAsPercentage(items.totalCompletedPercentage);              
                setFifthEndPointLoaded(false);
            });
        } else {
            setFifthEndPointLoaded(true);
            getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions(requests.GetNoOfDailyVideosCompleted, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Day", 
                    `Nb of videos completed ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                items.dailyVideoCompletedPercentage.forEach(item => tempVideos.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.percentageCompleted, 
                    ""
                ]));
                setDataNumberOfVideosCompleted(tempVideos);             
                setDataNumberOfVideosCompletedUnique(items.totalCompletedNb);              
                setDataNumberOfVideosCompletedUniqueAsPercentage(items.totalCompletedPercentage);              
                setFifthEndPointLoaded(false);
            });           
        } 
        /* For the videos completed */

        /* For the watch average time for the videos */
        setSixthEndPointLoaded(true);
        getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions(requests.GetWatchAverageTime, startDate, endDate, selectedCompany).then(item => {
            setWatchAverageTime(item);
            setSixthEndPointLoaded(false);
        });
        /* For the watch average time for the videos */
    }, [startDate, endDate, selectedCompany, changeOfMonthlySelectionEndPoint]);

    useEffect(() => {
        if (
            firstEndPointLoaded === false &&
            secondEndPointLoaded === false && 
            thirdEndPointLoaded === false && 
            fourthEndPointLoaded === false &&
            fifthEndPointLoaded === false &&
            sixthEndPointLoaded === false
        ) {
            setIsLoading(false);
        }
    }, [firstEndPointLoaded, secondEndPointLoaded, thirdEndPointLoaded, fourthEndPointLoaded, fifthEndPointLoaded, sixthEndPointLoaded]);

    const openTheMoreDetailsChart = comp => sendMoreDetailsComponentToContext(comp);

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }} className="home__page-charts-row" style={{ display: 'flex' }}>
                <div className="home__page-charts-row-first-section">
                    <Paper 
                        className={classes.paper} 
                        sx={{ width: 200, overflow: 'hidden', marginRight: 10 }} 
                    >
                        <Card className={classes.card} elevation={1}>
                            <CardContent className={classes.cardContent}>
                                <Typography className={classes.title} style={{ marginBottom: 0 }} color="textSecondary">{`Number of Logged in users from the beginning ${selectedCompanyAsObj.label ? 'for ' + selectedCompanyAsObj.label : ''}`}</Typography>                            
                                <Typography 
                                    className={classes.subTitle} 
                                    component="p"
                                >{(loggedInUsersDataSet.loggedInUserCount && loggedInUsersDataSet.nbOfRegisteredUser) && 
                                loggedInUsersDataSet.loggedInUserCount + '/' + loggedInUsersDataSet.nbOfRegisteredUser}
                                </Typography>
                                <Typography 
                                    className={classes.subTitle} 
                                    component="h6"
                                >
                                {
                                    (loggedInUsersDataSet.loggedInUserCount && loggedInUsersDataSet.nbOfRegisteredUser) ? 
                                    '(' + ((loggedInUsersDataSet.loggedInUserCount/loggedInUsersDataSet.nbOfRegisteredUser) * 100).toFixed(2) + '%' + ')' : 
                                    '(0%)'
                                }
                                </Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                    <Paper 
                        className={classes.paper} 
                        sx={{ width: 175, overflow: 'hidden' }} 
                    >
                        <Card className={classes.card} elevation={1}>
                            <CardContent className={classes.cardContent}>
                                <Typography className={classes.title} color="textSecondary">{`Number of Active users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`}</Typography>                            
                                <Typography className={classes.subTitle} component="p">{dataNumberOfActualUsers}</Typography>
                                <Typography className={classes.subTitle} component="p"></Typography>
                            </CardContent>
                            <CardActions style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                <Typography onClick={() => openTheMoreDetailsChart('homepageActive')} className={classes.title} style={{ height: 10, cursor: 'pointer' }} color="textSecondary">More details</Typography>                            
                                <ChevronRightIcon fontSize="small" style={{ marginTop: 2, cursor: 'pointer' }} />
                            </CardActions>
                        </Card>
                    </Paper>
                    <Paper 
                        className={classes.paper} 
                        sx={{ width: 175, overflow: 'hidden' }}
                    >                    
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Typography className={classes.title} color="textSecondary">{`Number of Registered users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`}</Typography>                            
                                <Typography className={classes.subTitle} component="p">{dataNumberOfActualRegisteredUsers}</Typography>
                                <Typography className={classes.subTitle} component="p"></Typography>
                            </CardContent>
                            <CardActions style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                <Typography onClick={() => openTheMoreDetailsChart('homepageRegistered')} className={classes.title} style={{ height: 10, cursor: 'pointer' }} color="textSecondary">More details</Typography>                            
                                <ChevronRightIcon fontSize="small" style={{ marginTop: 2, cursor: 'pointer' }} />
                            </CardActions>
                        </Card>
                    </Paper>
                </div>
                <div className="home__page-charts-row-second-section">
                    <Paper 
                        className={classes.paper} 
                        sx={{ width: 175, overflow: 'hidden' }}                        
                    >                    
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Typography className={classes.title} color="textSecondary">{`Number of videos launched ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`}</Typography>                            
                                <Typography className={classes.subTitle} component="p">{dataNumberOfVideosViewedUnique}</Typography>
                                <Typography className={classes.subTitle} component="p"></Typography>
                            </CardContent>
                            <CardActions style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                <Typography onClick={() => openTheMoreDetailsChart('VideosViewed')} className={classes.title} style={{ height: 10, cursor: 'pointer' }} color="textSecondary">More details</Typography>                            
                                <ChevronRightIcon fontSize="small" style={{ marginTop: 2, cursor: 'pointer' }} />
                            </CardActions>
                        </Card>
                    </Paper>                                                     
                    <Paper 
                        className={classes.paper} 
                        sx={{ width: 175, overflow: 'hidden' }}
                    >                    
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Typography className={classes.title} color="textSecondary">{`Number of videos completed ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`}</Typography>                            
                                <Typography className={classes.subTitle} component="p">{dataNumberOfVideosCompletedUnique}</Typography>
                                <Typography className={classes.subTitle} component="p">{'(' + ((dataNumberOfVideosCompletedUniqueAsPercentage !== undefined && dataNumberOfVideosCompletedUniqueAsPercentage !== null) ? dataNumberOfVideosCompletedUniqueAsPercentage.toFixed(2) : "0") + '%' + ')'}</Typography>                                
                            </CardContent>
                            <CardActions style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                <Typography onClick={() => openTheMoreDetailsChart('VideosCompleted')} className={classes.title} style={{ height: 10, cursor: 'pointer' }} color="textSecondary">More details</Typography>                            
                                <ChevronRightIcon fontSize="small" style={{ marginTop: 2, cursor: 'pointer' }} />
                            </CardActions>
                        </Card>
                    </Paper>
                    <Paper 
                        className={classes.paper}  
                        sx={{ width: 175, overflow: 'hidden' }}
                    >                    
                        <Card className={classes.card}>
                            <CardContent className={classNames(classes.cardContent, classes.cardContentExtended)}>
                                <Typography className={classes.title} color="textSecondary">{`Average watch time ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`}</Typography>                                                            
                                <Typography className={classes.tinyTitle} style={{ marginBottom: 15 }} component="p">{watchAverageTime && "Avg: " + constructTimeSlotBasedOnAverageWatchTime(watchAverageTime?.average)}</Typography>
                                <Typography className={classes.tinyTitleExtended} component="p">{"(hh:mm:ss)"}</Typography>
                                <Typography className={classes.tinyTitle} style={{ marginBottom: 15 }} component="p">{watchAverageTime && "Tot: " + constructTimeSlotBasedOnAverageWatchTime(watchAverageTime?.total)}</Typography>                                
                                <Typography className={classes.tinyTitleExtended} component="p">{"(hh:mm:ss)"}</Typography>
                            </CardContent>
                            <CardActions style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                <Typography onClick={() => openTheMoreDetailsChart('VideosInMinutes')} className={classes.title} style={{ height: 10, cursor: 'pointer' }} color="textSecondary">More details</Typography>                            
                                <ChevronRightIcon fontSize="small" style={{ marginTop: 2, cursor: 'pointer' }} />
                            </CardActions>                            
                        </Card>
                    </Paper>
                </div>                    
            </Box>        
            <Box sx={{ width: '100%', typography: 'body1' }} style={{ justifyContent: 'flex-start' }} className="home__page-charts-row">
                <Paper className={classes.tablePaperExtended}>
                    {dataNumberOfUsers.length > 0 && 
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="400px"
                        data={dataNumberOfUsers}
                        options={optionsOnNumberOfActiveUsers}
                    />}
                </Paper>
                <Paper className={classes.tablePaper}>
                    {dataNumberOfRegisteredUsers.length > 0 &&
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="400px"
                        data={dataNumberOfRegisteredUsers}
                        options={optionsOnNumberOfRegisteredUsers}
                    />}                                                
                </Paper>
            </Box>            
        </>
    );
}

export default withStyles(styles)(HomePageComponent);