import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FrenchButton from "../../assets/images/french-icon.svg";
import EnglishButton from "../../assets/images/english-icon.svg";
import SpanishButton from "../../assets/images/spanish-icon.svg";
import Constants from "../constants/Constants";

import Fr_Register from "../../resources/fr-FR.json";
import En_Register from "../../resources/en-US.json";

import { GetTranslation, SetLanguage } from "../../redux/action/LanguageAction";
import requests from "../../components/axios/Requests";
import { patchPersonByID } from "../../servicesRedux/AdminService";



const SelectLanguage = ({preferredLanguage, setPreferredLanguagesCallback}) => {

    const { translation } = useSelector(state => state.languageReducer);
    const dispatch = useDispatch();

    const [preferredLanguages, setPreferredLanguages] = useState(preferredLanguage ?? []);
    const { userRedux } = useSelector(state => state.authentificationReducer);
    const languageList = ["Fr", "En"];
    const lang = localStorage.getItem("language") || languageList.find(item => item.includes(window.navigator.language)) || languageList[1];

    useEffect(() => {
        setPreferredLanguagesCallback(preferredLanguages);
    }, [preferredLanguages]);

    const handleChangeLang = langChoice => {
        const personRequest = {
            language: langChoice === "En" ? "en-US" : "fr-FR",
        };
        patchPersonByID(requests.PatchPerson, userRedux.id, personRequest);
        switch (langChoice) {
            case "En":
                localStorage.setItem("language", "En");
                dispatch(GetTranslation(En_Register));
                break;
            case "FR":
                localStorage.setItem("language", "FR");
                dispatch(GetTranslation(Fr_Register));
                break;
            default:
                dispatch(GetTranslation(Fr_Register));
                break;
        }
        dispatch(SetLanguage(langChoice));
    };

    const handlePreferredLanguageClick = (languageIds) => {
        // Ensure languageIds is an array
        if (!Array.isArray(languageIds)) {
            languageIds = [languageIds];
        }
        setPreferredLanguages((prevLanguages) => {
            // Find the languages that are already included in the current state
            const includedLanguages = prevLanguages.filter((id) => languageIds.includes(id));
            // If any of the provided languageIds are included, remove them from the state
            if (includedLanguages.length > 0) {
                return prevLanguages.filter((id) => !languageIds.includes(id));
            }
            // Otherwise, add the provided languageIds to the state
            else {
                if (prevLanguages === null) {
                    return languageIds;
                }
                if (Array.isArray(prevLanguages)) {
                    if (prevLanguages[0] === null) {
                        return languageIds;
                    }
                }
                return [...prevLanguages, ...languageIds];
            }
        });
    };

    return (
        <div className="corpo-onboarding-language_container">
            <div className="corpo-onboarding_language corpo-sys_lang">
                <h3>{translation.System_Language}</h3>
                <p>{translation.Choose_among_lang}</p>
                <SystemLanguageButtons
                    handleChangeLang={handleChangeLang}
                    lang={lang}
                    translation={translation}
                />
            </div>
            <div className="corpo-onboarding_language corpo-pref_lang">
                <h3>{translation.Preferred_language_for_content}</h3>
                <p>{translation.You_will_only_see_content_in_your_preferred_language}</p>
                <LanguageButtons
                    handlePreferredLanguageClick={handlePreferredLanguageClick}
                    languages={preferredLanguages}
                    translation={translation}
                />
            </div>
        </div>
    )
}

export default SelectLanguage;

const LanguageButtons = ({ handlePreferredLanguageClick, languages, translation }) => {

    return (
        <div >
            <button
                className={
                    languages.includes(Constants.Languages.ENGLISH_ID)
                        ? "preferred-language-button"
                        : "preferred-language-button unselected"
                }
                onClick={() => handlePreferredLanguageClick(Constants.Languages.ENGLISH_ID)}
            >
                <img src={EnglishButton} />
                {translation.English}
            </button>
            <button
                className={
                    languages.includes(Constants.Languages.FRENCH_ID)
                        ? "preferred-language-button"
                        : "preferred-language-button unselected"
                }
                onClick={() => handlePreferredLanguageClick(Constants.Languages.FRENCH_ID)}
            >
                <img src={FrenchButton} />
                {translation.French}
            </button>
            <button
                className={
                    languages.includes(Constants.Languages.SPANISH_ID)
                        ? "preferred-language-button"
                        : "preferred-language-button unselected"
                }
                onClick={() => handlePreferredLanguageClick(Constants.Languages.SPANISH_ID)}
            >
                <img src={SpanishButton} />
                {translation.Spanish}
            </button>
        </div>
    )
}

const SystemLanguageButtons = ({ translation, handleChangeLang, lang }) => {

    return (
        <div>
            <button className={lang === "En" ? "system__language-button" : "system__language-button unselected"} onClick={() => handleChangeLang("En")}>
                <img src={EnglishButton} />{translation.English}
            </button>
            <button className={lang === "FR" ? "system__language-button" : "system__language-button unselected"} onClick={() => handleChangeLang("FR")}>
                <img src={FrenchButton} />{translation.French}
            </button>
        </div>
    )

}