import * as types from "../constant";

export function GetAllSpeakersAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ALL_SPEAKERS, payload });
  };
}

export function GetSpeakerTotalItemsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_SPEAKER_TOTAL_ITEMS, payload });
  };
}

export function GetSpeakerFromConferenceAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_SPEAKER_FROM_CONFERENCE, payload });
  };
}

export function GetSpeakerFromMySpeakersAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_SPEAKER_FROM_MY_SPEAKERS, payload });
  };
}