//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Downloaded page *                                             *
 * ?                      Downloaded is a page named "Watch Later" in the app                                   *
 * ?                      A user can save video to watch it later, this page is where they will find them       *
 * ?                      Every conference can be "saved" by clicking on an icon                                *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import Row from "../components/row/Row";
import Paginator from "../components/pagination/Paginator";
import { TabPanel } from "../components/TabPanel/TabPanel";

import "../theme/downloaded/Downloaded.scss";
import requests from "../components/axios/Requests";
/* temp a delete apres les tests */

import DownloadedPlaylist from '../components/Download/DownloadedPlaylist';
import { getPeople } from "../servicesRedux/GlobalService";
import { useEffect } from "react";


const Downloaded = () => {
  const { downloadededCount } = useSelector((state) => state.globalReducer);
  const { translation } = useSelector((state) => state.languageReducer);
  const [value, setValue] = useState(0);
  const [dateAdded, setDateAdded] = useState('');
  /* temp a delete pour les tests */
  const [isActive] = useState(0);
  const [content, setContent] = useState([]);
  const [search, setSearch] = useState("");
  const [people, setPeople] = useState([]);
  const [triggerPlaylists, setTriggerPlaylists] = useState(false);
  const [, setIsSearching] = useState(false);

  const enableSearch = () => setIsSearching(true);

  const triggerPlaylistsReload = () => setTriggerPlaylists(!triggerPlaylists);

  const clearTheSearchDebounceText = () => {
    setSearch("");
    findPeople();
  };

  const findPeople = () => {
    getPeople(requests.GetPeople, search).then(item => setPeople(item.data));
    enableSearch();
  };

  useEffect(() => {
    if (search === undefined || search === "" || search.length > 2) getPeople(requests.GetPeople, search, "colleagues").then(item => setPeople(item.data));
  }, [search]);


  /* fin du temp */

  const [activeType, setActiveType] = useState(['video', 'podcast', 'pdf']);

  const handleChange = (newValue) => setValue(newValue);

  const handleChangeDateAdded = (event) => setDateAdded(event.target.value);

  const RowType = () => {
    //get vidoe from local storage
    const [playlist, setPlaylist] = useState([]);
    const addToPlaylist = (video) => {
      setPlaylist([...playlist, video]);
    };
    const removeFromPlaylist = (videoToRemove) => {
      setPlaylist(playlist.filter(video => video.id !== videoToRemove.id));
    };
    const allPlayLists = JSON.parse(localStorage.getItem("playlists"));
    const downloadedId = allPlayLists?.data?.find((p) => p.type === 2)?.id;
    return downloadededCount === 0 ? (
      <div className="downloaded__empty-state">
        <h3>{translation.WatchLater_NoSaved}</h3>
      </div>
    ) : (
      <Row
        title={allPlayLists?.data?.find((p) => p.type === 2)?.name}
        appearanceType={"row"}
        contentType={"DownloadedPlaylist"}
        playlistId={downloadedId}
        playListCatType={'my'}
        mediaType={activeType}
      />
    );
  };

  function setupIndexedDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("ConferenceDatabase", 2);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);

      request.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("conferences")) {
          db.createObjectStore("conferences", { keyPath: "id" });
        }
        if (!db.objectStoreNames.contains("metadata")) {
          db.createObjectStore("metadata");
        }
      };
    });
  }

  async function getAllConferences() {
    const db = await setupIndexedDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("conferences", "readonly");
      const store = transaction.objectStore("conferences");
      const request = store.getAll(); // Retrieves all entries from the store

      request.onsuccess = () => {
        resolve(request.result); // Returns an array of all conference objects
      };
      request.onerror = () => {
        reject('Error fetching data from IndexedDB:', request.error);
      };
    });
  }
  useEffect(() => {
    getAllConferences()
      .then(conferences => {
        setContent(conferences)
      })
      .catch(error => {
        console.error('Failed to retrieve all conferences:', error);
      });

  }, [])

  return (
    <div style={{ paddingTop: '21px' }}>
      <div className="downloaded">
        <div className="downloaded_page_title">
          <h1 className="downloaded_page_title--title">{translation.downloaded}</h1>
        </div>
        <div className="downloaded__content">
          <div className={`downloaded__commun ${isActive === 0 && "downloadedIsActive"}`}>
            <DownloadedPlaylist contents={content} />


          </div>
          {/*<span>{`${downloadededCount} ${translation.SideBar_Talks}`}</span>*/}        </div>
        <div>
          {<FormControl sx={{ m: 1, minWidth: 80 }} style={{
            width: '179px',
            height: '400px'
          }}>
            {/*<InputLabel id="demo-simple-select-label">{translation.Date_Added}</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              value={dateAdded}
              onChange={handleChangeDateAdded}
              autoWidth
              label="Date added"
              IconComponent={() => <img src={DropDownArrow} />}              
            >
        </Select>*/}
          </FormControl>}
          {/*<Button 
            className="remove__watched-button"           
      ><img src={RemoveIcon} />{translation.Remove_Watched}</Button>*/}
        </div>
        <TabPanel value={value} index={0}>
          <div className={`downloaded__commun ${value === 0 && "downloadedIsActive"}`}>
            <RowType appearanceType={"downloaded"} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={`downloaded__commun ${value === 1 && "downloadedIsActive"}`}>
            UPCOMING ...
          </div>
        </TabPanel>
        <Paginator />
      </div>
    </div>
  );
};

export default Downloaded;