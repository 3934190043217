//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * SearchBar component *                                         *
 * ?                       Whenever a user will search something it will redirect them to the search page       *
 * ?                       Right now (04/27) you can only search by clicking enter                              *
 *                         TODO: Add a search Icon and onClick function to trigger the search                   *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import { GetSearchedValue } from "../../../redux/action/SearchAction";

const SearchAppBar = lang => {
  let valeur = "";
  const navigate = useNavigate([]);  
  const location = useLocation("");
  const dispatch = useDispatch();

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      location.state = { provenance: "navBar" };
      valeur = event.target.value;
      navigate("/search", {
        state: { researchValue: { valeur }, provenance: "navBar" },
      });
    }
  };

  const handleOnChange = event => {
    const value = event.target.value;
    location.state = { provenance: "navBar" };
    dispatch(GetSearchedValue(value));
  };

  return (
    <Box sx={{ flexGrow: 1, paddingTop: "6px" }}>
      <TextField 
        label="Search" 
        variant="outlined" 
        size="small" 
        onKeyDown={handleKeyDown}
        onChange={handleOnChange}
        placeholder={lang.lang} 
        className="search__base-textbox search__base-textbox_mobile_view"
      />
    </Box>
  );
};

export default SearchAppBar;
