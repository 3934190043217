import axios from "axios";

export function getUserCategories(request) {
  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data.user);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCategories(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteUserCategory(requests, category) {
  requests = requests.replace("{category}", encodeURIComponent(category));
  return new Promise((resolve, reject) => {
    axios
      .delete(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postUserCategory(requests, category) {
  requests = requests.replace("{category}", encodeURIComponent(category));
  return new Promise((resolve, reject) => {
    axios
      .post(requests, category, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getWatchLaterCount(requests, userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests + userId, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postUserTags(requests, tags) {
  return new Promise((resolve, reject) => {
    axios
      .post(requests, tags, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUserTags(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getRecommandationsCount(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postSavedContent(request, id) {
  const req = { contentId: id };

  return new Promise((resolve, reject) => {
    axios
      .post(request, req, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteSavedContent(requests, id) {
  requests = requests.replace("{id}", id);

  return new Promise((resolve, reject) => {
    axios
      .delete(requests, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//get People

export function getPeople(requests, person, filter) {

   const request = 
   requests
  .replace("{filter}", filter ?? "")
  .replace("{name}", person);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function patchPerson(request, personId, data) {
  request = request.replace("{id}", personId);
  return new Promise((resolve, reject) => {
    axios
      .patch(request, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch(reject);
  });
}

export function putSpeaker(request, id, data) {
  request = request + id;
  return new Promise((resolve, reject) => {
    axios
      .put(request, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch(reject);
  });
}
export function putChannel(request, id, data) {
  request = request.replace("{id}", id);
  return new Promise((resolve, reject) => {
    axios
      .put(request, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response);
      })
      .catch(reject);
  });
}

export function getTenant(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllCategories(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getAllSpeakers(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getAllChannels(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getAllContributors(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postCategory(reqeusts, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(reqeusts, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function postSpeaker(reqeusts, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(reqeusts, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function patchProfile(reqeusts, data) {
  return new Promise((resolve, reject) => {
    axios
      .patch(reqeusts, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addChannelService(reqeusts, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(reqeusts, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateContent(request, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(request, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function createContent(request, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(request, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
          alert("Error on content creation");
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        alert("Error on content creation." + " " + error?.response?.data?.message);
      });
  });
}
export function createUploadJob(request, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(request, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUploadJob(request, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(request, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllTags(requests, pageParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createTag(requests, newTag) {

  return new Promise((resolve, reject) => {
    axios
      .post(requests, newTag, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        //if error is 400, alert
        if (error.response.status === 400) {
          alert(error.response.data.message);
        }
        reject(error);
      });
  });
}

export function UpdateTag(requests, id, newTag) {
  requests = requests.replace("{id}", id);
  return new Promise((resolve, reject) => {
    axios
      .put(requests, newTag, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error.response.data.message);
        }
        reject(error);
      });
  });
}

export function DeleteTag(requests, id) {
  requests = requests.replace("{id}", id);
  return new Promise((resolve, reject) => {
    axios
      .delete(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          alert(error.response.data.message);
        }
        reject(error);
      });
  });
}

export function getLanguages(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function LogUserActivities(requests, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(requests, data, { withCredentials: true })
      .then(resolve)
      .catch(reject);
  });
}


export function getIsoLanguages(request) {
  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function patchOnBoardingStep(request, step) {

  const requests = request.replace("{step}", step);

  return new Promise((resolve, reject) => {
    axios
      .patch(requests, {}, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postOnBoardingDone(request){
  
    return new Promise((resolve, reject) => {
      axios
        .post(request, {}, { withCredentials: true })
        .then(response => {
          if (response.status === 500) {
            reject(response.data);
          }
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  
}


export function updateDailyMotionThumbnail(request, data, contentId){
  
  return new Promise((resolve, reject) => {
    axios
      .post(request, {ContentId: contentId, ThumbnailURL : data}, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

}