//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Login component *                                             *
 * ?         The login component is in reality the name and avatar seen in the top right corner in the navbar   *
 * ?                       It can be clicked to go in the "Admin" page                                          *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import Constants from "../../constants/Constants";

import MenuDown from "../../../assets/images/menu_down.svg";

import "./NavbarLogin.scss";

export const Login = props => {
  const navigate = useNavigate();
  const { translation } = useSelector(state => state.languageReducer);
  const role = (Cookies.get("role") && JSON.parse(Cookies.get("role"))) ?? "";
  const roles = (Cookies.get("roles") && JSON.parse(Cookies.get("roles"))) ?? "";
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    setOpen(false);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
  };

  const handleSuggestionForm = () => navigate("/SuggestionForm");

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      onClick={handleToggle}
      style={{
        paddingTop: 7
      }}
    >
      <Avatar
        alt="userName"
        src={props.newProfileImage || props.user.avatar}
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
      />
      <img className="menu__down" src={MenuDown} alt="Avatar" />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          marginLeft: '-20px'
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper className="top__nav-listing-container">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ flexDirection: "column" }}
                >
                  {!props.publicConference &&
                    <MenuItem
                      onClick={() => navigate("/profile")}
                      className="top__nav-listing"
                    >
                      {translation.Edit_Profile}
                    </MenuItem>
                  }

                  <MenuItem
                    className="top__nav-listing"
                    onClick={handleSuggestionForm}
                  >
                    {translation.Suggest_form_menu}
                  </MenuItem>

                  {(roles && roles.includes(Constants.Roles.ADMIN) || roles.includes(Constants.Roles.MANAGER)) && (
                    <>
                      <MenuItem
                        className="top__nav-listing"
                        onClick={() => navigate("/dashboard/reporting")}
                      >
                        {translation.Reporting_Dashboard}
                      </MenuItem>
                    </>
                  )}
                  {(roles && roles.length > 0 && roles.includes(Constants.Roles.ADMIN) || roles.includes(Constants.Roles.CURATOR_MANAGER) || roles.includes("Curator")) && (
                    <>
                      <MenuItem
                        className="top__nav-listing"
                        onClick={() => navigate("/dashboard/contents")}
                      >
                        {translation.Manage_Content}
                      </MenuItem>
                    </>
                  )}
                  {(roles && roles.includes(Constants.Roles.ADMIN) || roles.includes(Constants.Roles.MANAGER)) && (
                    <>
                      <MenuItem
                        className="top__nav-listing"
                        onClick={() => navigate("/dashboard/users")}
                      >
                        {translation.Manage_Users}
                      </MenuItem>
                    </>
                  )}

                  {props.logout && !props.publicConference &&
                    <MenuItem className="top__nav-listing" onClick={props.handleLogout}>
                      {translation.Logout}
                    </MenuItem>}

                  {props.publicConference &&
                    <MenuItem className="top__nav-listing" onClick={() => navigate("/login")}>
                      {translation.Login}
                    </MenuItem>
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
}

export default Login;