import * as types from "../constant";

export function LoadNextAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_NEXT, payload });
  };
}

export function HasLoadedAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.HAS_LOADED, payload });
  };
}

export function IsOnScreenAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.IS_ON_SCREEN, payload });
  };
}