import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import LinearProgress from "@mui/material/LinearProgress";

import requests from "../components/axios/Requests";
import Playlist from "../components/playlist/Playlist";
import PlaylistEmpty from "../components/playlist/PlaylistEmpty";
import { TabPanel } from "../components/TabPanel/TabPanel";
import { CreatePlaylistInputModal } from "../components/createPlaylistInputModal";
import useDebounce from "../hooks/Debounce";

import { getPeople } from "../servicesRedux/GlobalService";
import { getPlaylists, createPlaylist, getMySharedPlaylist } from "../servicesRedux/PlaylistService";

import "../theme/playlists/Playlists.scss";

const Playlists = () => {
  const [requestReturn, setRequestReturn] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [load] = useState(false);
  const [content, setContent] = useState([]);
  const { translation } = useSelector(state => state.languageReducer);
  const { updatedPlaylist } = useSelector(state => state.playlistReducer);
  const [isActive] = useState(0);
  const [value, setValue] = useState(0);
  const prevParams = useRef();
  const pageParams = {
    limit: 20,
    page: totalPages >= pageNumber ? pageNumber : 1,
  };
  const [people, setPeople] = useState([]);
  const [search, setSearch] = useState("");
  const [playlistsShared, setPlaylistsShared] = useState([]);
  const [loader, setLoader] = useState(false);
  const [triggerPlaylists, setTriggerPlaylists] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [, setIsSearching] = useState(false);
  
  useEffect(() => {
    if (search === undefined || search === "" || search.length > 2) getPeople(requests.GetPeople, search, "colleagues").then(item => setPeople(item.data));
  }, [search]);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && debouncedSearchTerm.length > 2) {
      findPeople();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const comparison = JSON.stringify(prevParams.current) !== JSON.stringify(pageParams);
    if (totalPages ? totalPages >= pageNumber : true && comparison) {
      getPlaylists(requests.GetMyPlaylists, pageParams).then(item => {
        setLoader(false);
        setRequestReturn(item);
      });
      getMySharedPlaylist(requests.GetSharedPlaylist, pageParams).then(item => {
        setLoader(false);
        setPlaylistsShared(item);
      });
    }
    prevParams.current = pageParams;
  }, [load, triggerPlaylists]);

  useEffect(() => {
    const check = content.find(c => c.id === updatedPlaylist?.id);
    if (check) {
      const index = content.findIndex(c => c.id === updatedPlaylist?.id);
      content[index] = updatedPlaylist;      
      setContent([...content]);      
    }
  }, [updatedPlaylist]);

  useEffect(() => {
    if (requestReturn.currentPage !== undefined) {
      setTotalPages(requestReturn.totalPages);
      setPageNumber(requestReturn.currentPage);
    }
    const contentTemp = requestReturn.data;    
    contentTemp && setContent(contentTemp);    
  }, [requestReturn]);
  
  const enableSearch = () => setIsSearching(true);

  const findPeople = () => {  
    getPeople(requests.GetPeople, search).then(item => setPeople(item.data));
    enableSearch();
  };

  const addNewPlaylist = (playlistName, errorCallback, setHasLoaded) => {
    createPlaylist(requests.CreatePlaylist, playlistName)
      .then(item => {
        setHasLoaded(false);
        setContent([...content, item.data]);
      })
      .catch(error => {
        if (error.response.status === 409) {
          setHasLoaded(false);
          errorCallback("Playlist with this name already exists");
        }
      });
  };

  const triggerPlaylistsReload = () => setTriggerPlaylists(!triggerPlaylists);

  const clearTheSearchDebounceText = () => {
    setSearch("");
    findPeople();
  };
  
  return (
    <>
      {loader &&
      <div className="loader">
        <LinearProgress color="inherit" className="progress_bar" />
      </div>}
      <div className="downloaded playlist__wrapper">
        <div className="playlists_page_title">
          <h1 className="playlists_page_title--title">{translation.Create_Playlist}</h1>
          <CreatePlaylistInputModal addPlaylist={addNewPlaylist} />
        </div> 
        <div className="row__title-second-half row__title-second-half-extended">
          <Button 
            className={`${value == 0 ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`} 
            onClick={() => setValue(0)}
          >{translation.My_Playlist} ({requestReturn.totalItems})</Button>
          <Button 
            className={`${value == 1 ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`} 
            onClick={() => setValue(1)}
          >{translation.Shared_Playlist} ({playlistsShared.length})</Button>
        </div>
        <TabPanel value={value} index={0} className="playlist__tab">
          <div className={`downloaded__commun ${isActive === 0 && "downloadedIsActive"}`}>
            {content.length > 0 &&
              content?.map((item, index) => {
                if (item.contentCount === 0 && (item.type === 2 || item.type === 3)) {
                  return <PlaylistEmpty item={item} />;
                } else {
                  return <div>{item && (
                            <Playlist 
                              setSearch={setSearch} 
                              people={people} 
                              triggerPlaylistsReload={triggerPlaylistsReload} 
                              item={item} 
                              playlistMainType={"my"} 
                              clearTheSearch={clearTheSearchDebounceText}
                            />)}
                          </div>;
                }
            })}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} className="playlist__tab">
          <div className={`downloaded__commun ${isActive === 1 && "downloadedIsActive"}`}>            
            {playlistsShared.length > 0 &&
              playlistsShared?.map((item, index) => {
                if (item.contentCount === 0 && (item.type === 2 || item.type === 3)) {
                  return null;
                } else {
                  return <div>{item && (
                            <Playlist 
                              setSearch={setSearch} 
                              people={people} 
                              triggerPlaylistsReload={triggerPlaylistsReload} 
                              item={item} 
                              playlistMainType={"shared"} 
                            />)}
                          </div>;
                }
            })}
          </div>
        </TabPanel>
      </div>
    </>    
  );
};

export default Playlists;