import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';

import { postSpeakerSubscription, deleteSpeakerSubscription, postChannelSubscription, deleteChannelSubscription } from "../../servicesRedux/SubscriptionService";
import requests from "../../components/axios/Requests";

import { getSpeakerSubscriptions, getChannelSubscriptions } from "../../servicesRedux/SubscriptionService";

import "./Intervenants.scss"

export default function FollowSpeakerButton({ intervenantId, translation, speaker, channel }) {
    const [loading, setLoading] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    useEffect(() => {
        if(speaker)
            getSpeakerSubscriptions(requests.GetSpeakerSubscriptions).then(item => {
                if (speaker && item) {
                    const found = item.find((element) => element.id === speaker.id);
                    if (found) {
                        setIsFollowing(true)
                    } else {
                        setIsFollowing(false)
                    }
                }
            });
        if(channel){
            getChannelSubscriptions(requests.GetSubscriptionsChannel).then(item => {
                if (channel && item) {
                    const found = item.find((element) => element.id === channel.id);
                    if (found) {
                        setIsFollowing(true)
                    } else {
                        setIsFollowing(false)
                    }
                }
            });
        }
        
    }, []);

    const handleFollow = () => {
        setLoading(true);

        if(speaker) {
            if (isFollowing) {
                deleteSpeakerSubscription(requests.DeleteSpeakerSubscriptions, speaker.id).then(() => {
                    setIsFollowing(false);
                    setLoading(false);
                });
            } else {
                postSpeakerSubscription(requests.PostSpeakerSubscriptions, speaker.id).then(() => {
                    setIsFollowing(true);
                    setLoading(false);
                });
            }
        }

        if(channel) {
            if (isFollowing) {
                deleteChannelSubscription(requests.DeleteSubscriptionChannel, channel.id).then(() => {
                    setIsFollowing(false);
                    setLoading(false);
                });
            } else {
                postChannelSubscription(requests.PostSubscriptionChannel, channel.id).then(() => {
                    setIsFollowing(true);
                    setLoading(false);
                });
            }
        }
    };
    return (
        <div className={"speakerFollow"}>
            <button onClick={handleFollow} className={`buttonWithPicFollowButton ${isFollowing ? 'buttonWithPicFollowButtonFollwing' : ''}`}>
                {loading ? <CircularProgress size={20} color="inherit" style={{ color: "#f8f7f6" }} /> : (isFollowing ? translation.Following : translation.Follow)}
            </button>
        </div>
    )
}