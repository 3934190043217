import React from 'react'

import Youtube from "../../assets/images/YouTube.svg";
import Linkedin from "../../assets/images/LinkedIn.svg";
import Link from "../../assets/images/Link.svg";
import Twitter from "../../assets/images/Twitter.svg";

import "../../theme/speakerPage/SpeakerPage.scss";

const renderTheCorrectLogo = socialMediaType => {
    switch(socialMediaType) {
        case "Instagram.com":
            return Youtube;
        case "facebook.com":
            return Link;
        case "Twitter.com":
            return Twitter;
        case "Linkedin.com":
            return Linkedin;  
    }
};

const Social = (props) => {
    const { allSocialMedia, personSocialMedia, loadingSocialMedia } = props;
    return (
        <div className='social'>
            {personSocialMedia?.map((item,index) => 
                <a key={index} href={'https://' + item.url}>
                    {allSocialMedia[item.socialMediaId-1]?.logo && <img style={{
                        width: 18,
                        height: 16
                    }} src={renderTheCorrectLogo(item.url)} alt='twitter' />}
                    {item.url}
                </a>
            )}
        </div>
    )
}

export default Social