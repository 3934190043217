import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useTracking } from "react-tracking";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import GenericSearchSelect from 'react-select';
import { DateRangePicker } from 'rsuite';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import _ from "lodash";

import Login from "../login/Login";
import Tags from "../../../components/switching/Tags";

import requests from "../../../components/axios/Requests";
import { getCompanies } from "../../../servicesRedux/AdminService";
import { destroySession } from "../../../redux/action/AuthentificationAction";
import { GetCategoryFilter } from "../../../redux/action/CategoryFilterAction";
import { getContentCurationList } from "../../../servicesRedux/ContentManagementService";
import {
  getAllCategories,
  getAllSpeakers,
  getAllTags,
} from "../../../servicesRedux/GlobalService";
import {
  GetAllCategoriesAction,
  GetAllSpeakersAction,
} from "../../../redux/action/GlobalActions";

import Constants from "../../constants/Constants";

import BellNotification from "../../../assets/images/Bell-notification.svg";
import Notification from "../../../assets/images/Bell.svg";
import logout from "../../../assets/images/logout.svg";

const customStyles = {
  control: base => ({
    ...base,
    height: 30,
    minHeight: 30
  })
};

const DesktopNavigation = ({
  roles,
  tenant,
  redirectTo,
  newProfileImage,
  updateQuery,
  updateCustomerQuery,
  updateDashboardUsersOnCompanies,
  sendToContextTagsArray,
  sendToContextSpeakersArray,
  sendToContextCategoriesArray,
  sendMediaTypesToContext,
  sendToContextSubCategoriesArray,
  sendToContextCurationArray,
  sendStartAndEndDateToContext,
  sendTagsArrayToContext,
  triggerMediaContentAPI,
  sendEmptyContentPayloadToContext,
  updateTheContentAdminStatusForLeftMenu,
  sendContextEmptyContentParamsToState,
  sendRequestToResetTheLeftMenu
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { translation } = useSelector(state => state.languageReducer);
  const { userRedux } = useSelector(state => state.authentificationReducer);
  const [selected, setSelected] = useState([
    false,
    false,
    false,
    false,
    false
  ]);
  const todaysDate = new Date();
  const [, setAnchorEl] = useState(null);
  const [notifications] = useState([]);
  const tracking = useTracking();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const { allSpeakers } = useSelector(state => state.globalReducer);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [, setCheckState] = useState(false);
  const [selectedTagsArray, setSelectedTagsArray] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState("");
  const [mediaTypes, setMediaTypes] = useState([1, 2, 3]);
  const [selectedSubCategoryList, setSelectedSubCategoryList] = useState("");
  const [curationList, setCurationList] = useState([]);
  const [selectedCurationId, setSelectedCurationId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedSpeaker, setSelectedSpeaker] = useState({});
  const [, setSelectedTag] = useState([]);
  const { afterToday } = DateRangePicker;
  const [searchQuery, setSearchQuery] = useState("");
  const [openTheMobileViewForAddtionalFilters, setOpenTheMobileViewForAddtionalFilters] = useState(false);
  const [contentRequestHasBeenChanged, setContentRequestHasBeenChanged] = useState(false);

  useEffect(() => {
    setSearchQuery("");
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/dashboard/users") {
      getCompanies(requests.GetCompanies).then(items => {
        setCompanies(items);
        if (items && items.length === 1) setSelectedCompany(items[0]?.id);
        updateDashboardUsersOnCompanies(items);
      });
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/dashboard/contents") {
      getAllCategories(requests.getAllCategories).then(items => dispatch(GetAllCategoriesAction(allCategoriesList(items))));
      getAllSpeakers(requests.getAllSpeakersCuration).then(items => dispatch(GetAllSpeakersAction(allSpeakersList(items))));
      getAllTags(requests.GetAllTagsCuration).then(items => {
        setTags(items);
        sendTagsArrayToContext(items);
      });
      getContentCurationList(requests.GetContentCuration).then(items => {
        const tempCurators = items.data.map(c => ({
          value: c.accountUserId,
          label: c.firstName + " " + c.lastName
        }));
        getContentCurationList(requests.GetContentChiefCuration).then(items => {
          const tempChielfCurators = items.data.map(c => ({
            value: c.accountUserId,
            label: c.firstName + " " + c.lastName
          }))
          const tempAllCurators = [...tempCurators, ...tempChielfCurators];
          const uniqueCuratorsArray = _.uniqBy(tempAllCurators, 'value');
          const sortedCurtorsList = _.sortBy(uniqueCuratorsArray, "label");
          setCurationList(sortedCurtorsList);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (mediaTypes.length > 0) {
      sendMediaTypesToContext(mediaTypes);
    } else {
      sendMediaTypesToContext([]);
    }
    sendContextEmptyContentParamsToState(null);
  }, [mediaTypes]);

  useEffect(() => {
    sendStartAndEndDateToContext(startDate, endDate);
  }, [startDate, endDate]);

  const allCategoriesList = list => {
    let newListObject = [];
    if (list) {
      list.map((item, _) => {
        let temp = item;
        temp.id = item.parentId;
        temp.label = item.category;
        temp.subCategory = getAllSubCategoriesList(item.subCategory);
        newListObject.push(temp);
      });
    }
    setCategories(newListObject);
    return newListObject;
  };

  const getAllSubCategoriesList = list => {
    let newListObject = [];
    if (list) {
      list.map((item, _) => {
        let temp = item;
        temp.id = item.subCategoryId;
        temp.subSubCategory = getAllSubSubCategoriesList(item.subSubCategory);
        newListObject.push(temp);
      });
    }
    return newListObject;
  };

  const getAllSubSubCategoriesList = list => {
    let newListObject = [];
    if (list) {
      list.map((item, _) => {
        let temp = {};
        temp = item;
        temp.label = item.label;
        temp.value = item.subCategoryId;
        newListObject.push(temp);
      });
    }
    setSubCategories(newListObject);
    return newListObject;
  };

  const allSpeakersList = list => {
    let newListObject = [];
    if (list) {
      list.map((item, _) => {
        let temp = item;
        temp.value = item.id;
        temp.label = item?.persons?.firstName + " " + item?.persons?.lastName;
        newListObject.push(temp);
      });
    }
    return newListObject;
  };

  const handleYellowRectangle = (_, index) => {
    const newSelected = selected.map((_, i) => {
      if (i === index) {
        return true;
      } else {
        return false;
      }
    });
    setSelected(newSelected);
  };

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleLogout = () => {
    tracking.trackEvent(
      {
        UserEventId: Number(Constants.UserEvent.CLICK.enum),
        UserActionId: Number(Constants.UserAction.LOGOUT.enum),
      }
    );
    dispatch(destroySession("DESTROY_SESSION"));
    Cookies.remove("userID");
    Cookies.remove("Expire_in");
    Cookies.remove("Header_signature");
    Cookies.remove("Payload");
    Cookies.remove("role");
    Cookies.remove("roles");
    Cookies.remove("accountType");
    if (Cookies.get("saveId") !== "true") {
      Cookies.set("saveId", "false");
      Cookies.remove("password");
      Cookies.remove("email");
    }
    window.location.href = "/login";

  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      updateQuery(event.target.value);
    }
  };

  const handleChange = event => {
    setSearchQuery(event.target.value);
    updateQuery(event.target.value);
  };

  const handleChangeCustomer = event => {
    setSelectedCompany(event.target.value);
    updateCustomerQuery(event.target.value);
  };

  const handleCheckInfo = async val => setCheckState(!val);

  const handleMediaTypesForContentSearch = event => {
    let mediaTypeId = null;
    switch (event.target.name) {
      case "Talks":
        mediaTypeId = Constants.MediaType.Video
        break;
      case "PDF":
        mediaTypeId = Constants.MediaType.PDF;
        break;
      case "Podcasts":
        mediaTypeId = Constants.MediaType.Podcast;
        break;
    }
    if (mediaTypes.indexOf(mediaTypeId) === -1) {
      setMediaTypes([...mediaTypes, mediaTypeId]);
    } else {
      setMediaTypes(mediaTypes.filter(m => m != mediaTypeId));
    }
  };

  const removeSelectedTag = item => {
    const itemLeft = selectedTagsArray.filter(t => t.id !== item.id);
    setSelectedTagsArray(itemLeft);
    sendToContextTagsArray(itemLeft);
    if (itemLeft.length > 0) {
      const tagsForItemsLeft = itemLeft.map(i => i.label);
      triggerMediaContentAPI(tagsForItemsLeft);
    } else {
      triggerMediaContentAPI([]);
    }
  };

  const tagSelectionOnChangeHnadler = event => {
    setSelectedTag(event);
    setSelectedTagsArray(event);
    sendToContextTagsArray(event);
    setContentRequestHasBeenChanged(true);
  };

  const speakersSelectionChangeHandler = event => {
    setSelectedSpeaker(event.id);
    sendToContextSpeakersArray(event.id);
    setContentRequestHasBeenChanged(true);
  };

  const changeCategorySelectionHandler = event => {
    setSelectedCategoryList(event.id);
    sendToContextCategoriesArray(event.id);
    setSubCategories(event.subCategory);
    setContentRequestHasBeenChanged(true);
  };

  const changeSubCategorySelectionHandler = event => {
    setSelectedSubCategoryList(event.id);
    sendToContextSubCategoriesArray(event.id);
    setContentRequestHasBeenChanged(true);
  };

  const changeCuratorSelectionHandler = event => {
    setSelectedCurationId(event.value);
    sendToContextCurationArray(event.value);
    setContentRequestHasBeenChanged(true);
  };

  const handleTagsData = event => triggerMediaContentAPI(event);

  const dateRangeChangeHandler = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const updateTheDateRange = update => {
    if (update) {
      const [start, end] = update;
      if (start === end) {
        let tempStart = start;
        let tempEnd = end;
        setStartDate(tempStart.setUTCHours(0, 0, 1));
        setEndDate(tempEnd.setUTCHours(11, 59, 59));
      } else {
        setStartDate(start);
        setEndDate(end);
      }
    } else {
      setStartDate(new Date());
      setEndDate(new Date(todaysDate - 1));
    }
    setContentRequestHasBeenChanged(true);
  };

  const resetAllFiltersInContentPage = () => {
    setSelectedSpeaker({});
    setSelectedCategoryList("");
    setSelectedSubCategoryList("");
    setSelectedCurationId("");
    setSelectedTag(null);
    setSelectedTagsArray([]);
    setStartDate(null);
    setEndDate(null);
    sendEmptyContentPayloadToContext(true);
    setMediaTypes([1, 2, 3]);
    setSearchQuery("");
    updateQuery("");
    updateTheContentAdminStatusForLeftMenu("");
    sendToContextSpeakersArray("");
    sendToContextCategoriesArray("");
    sendToContextCurationArray("");
    sendToContextSubCategoriesArray("");
    sendToContextTagsArray([]);
    sendStartAndEndDateToContext(null, null);
    setTags([]);
    sendContextEmptyContentParamsToState({
      query: "",
      mediaTypeId: [1, 2, 3],
      speakerId: "",
      categoryId: "",
      curatorId: "",
      startDate: null,
      endDate: null,
      tags: [],
      subCategoryId: "",
      contentAdminStatus: null,
      sortBy: 8,
      orderBy: false
    });
    sendRequestToResetTheLeftMenu(true);
    getAllCategories(requests.getAllCategories).then(items => dispatch(GetAllCategoriesAction(allCategoriesList(items))));
    getAllSpeakers(requests.getAllSpeakersCuration).then(items => dispatch(GetAllSpeakersAction(allSpeakersList(items))));
    getAllTags(requests.GetAllTagsCuration).then(items => {
      setTags(items);
      sendTagsArrayToContext(items);
    });
    getContentCurationList(requests.GetContentCuration).then(items => {
      const tempCurators = items.data.map(c => ({
        value: c.accountUserId,
        label: c.firstName + " " + c.lastName
      }));
      getContentCurationList(requests.GetContentChiefCuration).then(items => {
        const tempChielfCurators = items.data.map(c => ({
          value: c.accountUserId,
          label: c.firstName + " " + c.lastName
        }))
        const tempAllCurators = [...tempCurators, ...tempChielfCurators];
        const uniqueCuratorsArray = _.uniqBy(tempAllCurators, 'value');
        const sortedCurtorsList = _.sortBy(uniqueCuratorsArray, "label");
        setCurationList(sortedCurtorsList);
      });
    });
    setOpenTheMobileViewForAddtionalFilters(false);
    setContentRequestHasBeenChanged(false);
  };

  const handleClickOpenTheMobileViewForAddtionalFilters = () => setOpenTheMobileViewForAddtionalFilters(true);

  const closeTheAdditionalFiltersDialogBox = () => setOpenTheMobileViewForAddtionalFilters(false);

  return (
    <>
      <Grid container className="dashboard__main-wrapper" spacing={2}>
        <Grid item xs={12} md={3} lg={3} className="sidebar">
          <div className="sidebar_container">
            {tenant && (
              <img
                className="sidebar__logo"
                src={tenant?.logoURL}
                onClick={() => { dispatch(GetCategoryFilter('')); redirectTo('/') }}
              />
            )}
            {tenant?.isWhiteLabelCustomer && (
              <div className="sidebar__white-label">
                <p>by Top Cream</p>
              </div>
            )}
            </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className="nav__menu-wrapper">
          <a
            {...(roles && (roles.includes("Admin") || roles.includes("Curator-Manager") || roles.includes("Curator")) ? { href: '/dashboard/contents' } : {})}
            className={`t-nav ${(selected[0] || location.pathname === "/dashboard/contents") ? "sidebar__content__selected" : "sidebar__content"}`}
            onClick={(roles && (roles.includes("Admin") || roles.includes("Curator") || roles.includes("Curator-Manager"))) ? e => handleYellowRectangle(e, 0) : undefined}
          >
            <span className={`${roles && (roles.includes("Admin") || roles.includes("Curator-Manager") || roles.includes("Curator")) ? '' : 'disabled_status'} `}>{translation.Top_Nav_Manage_Content}</span>
          </a>
          <a
            {...(roles && (roles.includes("Admin") || roles.includes("Manager")) ? { href: '/dashboard/users' } : {})}
            className={`t-nav ${(selected[1] || location.pathname === "/dashboard/users") ? "sidebar__content__selected" : "sidebar__content"}`}
            onClick={(roles && (roles.includes("Admin") || roles.includes("Manager"))) ? e => handleYellowRectangle(e, 1) : undefined}
          >
            <span style={{ marginLeft: '-5px' }} className={(roles && (roles.includes("Admin") || roles.includes("Manager"))) ? '' : 'disabled_status'}>{translation.Top_Nav_Manage_Users}</span>
          </a>
          <a
            className={`t-nav ${selected[2] ? "sidebar__content__selected" : "sidebar__content"}`}
          // onClick={e => handleYellowRectangle(e, 2)}
          >
            <span className="disabled_status">{translation.Top_Nav_Manage_Subscriptions}</span>
          </a>
          <a
            {...(roles && (roles.includes("Admin") || roles.includes("Manager")) ? { href: '/dashboard/reporting' } : {})}
            className={`t-nav ${(selected[3] || location.pathname === "/dashboard/reporting") ? "sidebar__content__selected" : "sidebar__content"}`}
            onClick={(roles && (roles.includes("Admin") || roles.includes("Manager"))) ? e => handleYellowRectangle(e, 3) : undefined}
          >
            <span className={(roles && (roles.includes("Admin") || roles.includes("Manager"))) ? '' : 'disabled_status'}>{translation.Reporting_Dashboard}</span>
          </a>
          <a
            {...(roles && (roles.includes("Admin") || roles.includes("Curator-Manager") || roles.includes("Curator")) ? { href: '/curationContentManagement' } : {})}
            className={`t-nav ${(selected[4] || location.pathname === "/curationContentManagement") ? "sidebar__content__selected" : "sidebar__content"}`}
            onClick={(roles && (roles.includes("Admin") || roles.includes("Curator-Manager") || roles.includes("Curator"))) ? e => handleYellowRectangle(e, 4) : undefined}
          >
            <span>{translation.Old_Dashboard}</span>
          </a>
        </Grid>
        <Grid item xs={12} md={3} lg={3} className="top__nav-bar-right">
          <ul>
            <li>
              <div className="navbar--notifications navbar--notifications-notifyicon" onClick={handleClick}>
                {notifications.length > 1 ? <img src={BellNotification} alt="notifications" /> : <img src={Notification} alt="notifications" />}
              </div>
            </li>
            <li>
              <div className="navbar--notifications navbar--notifications--extended-extra">
                <Login user={userRedux} logout={logout} handleLogout={handleLogout} newProfileImage={newProfileImage} />
              </div>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="common__top_filtering_panel">
        {location.pathname !== "/dashboard/reporting" &&
          <div className="common__top_filtering_panel_wrapper">
            <Box sx={{ flexGrow: 1, paddingTop: "6px" }} className="common__top_filtering_panel_wrapper__first_half">
              <TextField
                label={translation.NavBar_Search}
                variant="outlined"
                size="small"
                fullWidth
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={searchQuery}
                placeholder={translation.NavBar_Search}
                className="search__base-textbox" />
            </Box>
            {location.pathname === "/dashboard/users" &&
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth className="customer__mobile_drop_down customer__mobile_drop_down__users_section">
                  <InputLabel>{translation.By_Customer}</InputLabel>
                  <Select
                    label="role"
                    onChange={handleChangeCustomer}
                    value={selectedCompany}
                  >
                    {roles && !roles.includes("Manager") && <MenuItem value={translation.all}>{translation.all}</MenuItem>}
                    {companies.map(e => <MenuItem value={e.id}>{e.label}</MenuItem>)}
                  </Select>
                </FormControl>
              </Box>}
            {location.pathname === "/dashboard/contents" &&
              <>
                <Box className="dashboard__content-page-additional-filters-section-mobile">
                  {!contentRequestHasBeenChanged ?
                    <button
                      onClick={handleClickOpenTheMobileViewForAddtionalFilters}
                      className="default__reset-btn additional__filters-btn"
                    >{'Additional Filters'}</button> :
                    <button onClick={resetAllFiltersInContentPage} className="default__reset-btn">{translation.Reset_Filters}</button>}
                </Box>
                <Box className="dashboard__content-page-additional-filters-section-desktop">
                  <FormControl>
                    <DateRangePicker
                      format="yyyy-MM-dd"
                      placeholder={translation.Content_Update_Date}
                      placement={"bottomStart"}
                      onChange={update => updateTheDateRange(update)}
                      onClean={dateRangeChangeHandler}
                      className="dashboard__datepicker"
                      value={[startDate, endDate]}
                      disabledDate={afterToday()} />
                  </FormControl>
                  <FormGroup className="content__header_controls">
                    <FormControlLabel control={<Checkbox checked={mediaTypes.indexOf(Constants.MediaType.Video) > -1} />} label={translation.SideBar_Talks} name="Talks" onChange={handleMediaTypesForContentSearch} className="content__media_types_checkboxes1" />
                    <FormControlLabel control={<Checkbox checked={mediaTypes.indexOf(Constants.MediaType.PDF) > -1} />} label="PDF" name="PDF" onChange={handleMediaTypesForContentSearch} className="content__media_types_checkboxes2" />
                    <FormControlLabel control={<Checkbox checked={mediaTypes.indexOf(Constants.MediaType.Podcast) > -1} />} label={translation.New_Podcast} name="Podcasts" onChange={handleMediaTypesForContentSearch} className="content__media_types_checkboxes3" />
                  </FormGroup>
                  <FormControl className="customer__mobile_drop_down content__mobile_drop_down dashboard__speakers-drop-down">
                    <GenericSearchSelect
                      options={allSpeakers.filter(f => (f.persons.firstName != null && f.persons.lastName != null) ? f : null)}
                      className="other__drop-downs other__drop-downs-speakers-categories"
                      placeholder={translation.Conference_Speakers}
                      value={allSpeakers.filter(s => s.id === selectedSpeaker ? s : null)}
                      onChange={speakersSelectionChangeHandler}
                      styles={customStyles} />
                  </FormControl>
                  <FormControl className="customer__mobile_drop_down content__mobile_drop_down">
                    <GenericSearchSelect
                      options={categories}
                      className="other__drop-downs other__drop-downs-speakers-categories"
                      placeholder={translation.NavBar_Categories}
                      value={categories.filter(c => c.id === selectedCategoryList ? c : null)}
                      onChange={changeCategorySelectionHandler}
                      styles={customStyles} />
                  </FormControl>
                  <FormControl className="customer__mobile_drop_down content__mobile_drop_down">
                    <GenericSearchSelect
                      options={subCategories}
                      value={subCategories && subCategories.filter(sc => sc.id === selectedSubCategoryList ? sc : null)}
                      className="other__drop-downs other__drop-downs-speakers-categories"
                      placeholder={translation.Sub_Categories}
                      isDisabled={selectedCategoryList === null || selectedCategoryList === undefined || selectedCategoryList === "" || selectedCategoryList === ""}
                      onChange={changeSubCategorySelectionHandler}
                      styles={customStyles} />
                  </FormControl>
                  <FormControl className="customer__mobile_drop_down content__mobile_drop_down">
                    <GenericSearchSelect
                      options={curationList}
                      value={curationList.filter(c => c.value === selectedCurationId ? c : null)}
                      className="other__drop-downs other__drop-downs-speakers-categories"
                      placeholder={translation.Curators}
                      onChange={changeCuratorSelectionHandler}
                      styles={customStyles} />
                  </FormControl>
                  <button onClick={resetAllFiltersInContentPage} className="default__reset-btn">{translation.Reset_Filters}</button>
                </Box>
              </>}
          </div>}
        {location.pathname === "/dashboard/contents" &&
          <div className="common__top_filtering_panel_wrapper">
            <Box className="common__top_filtering_panel_wrapper__first_half">
              <FormControl className="customer__mobile_drop_down content__mobile_drop_down tags__dropdown">
                <GenericSearchSelect
                  options={tags}
                  getOptionLabel={tags => tags['label']}
                  getOptionValue={tags => tags['id']}
                  placeholder={"Tags"}
                  className="special__drop-down-case other__drop-downs-speakers-categories"
                  value={selectedTagsArray}
                  isMulti={true}
                  isDisabled={selectedTagsArray.length >= 4}
                  onChange={tagSelectionOnChangeHnadler}
                  styles={customStyles} />
                <span className="tagsPlaceholder">{translation.Tags}</span>
              </FormControl>
            </Box>
            <Box className="content__page-right-menu-section">
              {(tags && selectedTagsArray && selectedTagsArray.length > 0) &&
                <Tags
                  tags={selectedTagsArray}
                  GetformData={handleTagsData}
                  comboID={"tags"}
                  checkInfo={handleCheckInfo}
                  includedInDashboard={true}
                  removeSelectedTagOnSearch={removeSelectedTag} />}
            </Box>
          </div>}

        <Dialog open={openTheMobileViewForAddtionalFilters}>
          <DialogTitle>{'Additional Filters'}</DialogTitle>
          <DialogContent style={{ minWidth: 575, width: "100%", minHeight: 200 }}>
            <Box style={{ display: 'flex' }}>
              <FormGroup className="content__header_controls">
                <FormControlLabel control={<Checkbox checked={mediaTypes.indexOf(Constants.MediaType.Video) > -1} />} label={translation.SideBar_Talks} name="Talks" onChange={handleMediaTypesForContentSearch} className="content__media_types_checkboxes1" />
                <FormControlLabel control={<Checkbox checked={mediaTypes.indexOf(Constants.MediaType.PDF) > -1} />} label="PDF" name="PDF" onChange={handleMediaTypesForContentSearch} className="content__media_types_checkboxes2" />
                <FormControlLabel control={<Checkbox checked={mediaTypes.indexOf(Constants.MediaType.Podcast) > -1} />} label={translation.New_Podcast} name="Podcasts" onChange={handleMediaTypesForContentSearch} className="content__media_types_checkboxes3" />
              </FormGroup>
              <FormControl>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  placeholder={translation.Content_Update_Date}
                  placement={"bottomStart"}
                  onChange={update => updateTheDateRange(update)}
                  onClean={dateRangeChangeHandler}
                  className="dashboard__datepicker"
                  value={[startDate, endDate]}
                  disabledDate={afterToday()} />
              </FormControl>
            </Box>
            <Box>
              <FormControl className="customer__mobile_drop_down content__mobile_drop_down dashboard__speakers-drop-down">
                <GenericSearchSelect
                  options={allSpeakers.filter(f => (f.persons.firstName != null && f.persons.lastName != null) ? f : null)}
                  className="other__drop-downs other__drop-downs-speakers-categories"
                  placeholder={translation.Conference_Speakers}
                  value={allSpeakers.filter(s => s.id === selectedSpeaker ? s : null)}
                  onChange={speakersSelectionChangeHandler}
                  styles={customStyles} />
              </FormControl>
            </Box>
            <Box>
              <FormControl className="customer__mobile_drop_down content__mobile_drop_down">
                <GenericSearchSelect
                  options={categories}
                  className="other__drop-downs other__drop-downs-speakers-categories"
                  placeholder={translation.NavBar_Categories}
                  value={categories.filter(c => c.id === selectedCategoryList ? c : null)}
                  onChange={changeCategorySelectionHandler}
                  styles={customStyles} />
              </FormControl>
            </Box>
            <Box>
              <FormControl className="customer__mobile_drop_down content__mobile_drop_down">
                <GenericSearchSelect
                  options={subCategories}
                  value={subCategories && subCategories.filter(sc => sc.id === selectedSubCategoryList ? sc : null)}
                  className="other__drop-downs other__drop-downs-speakers-categories"
                  placeholder={translation.Sub_Categories}
                  isDisabled={selectedCategoryList === null || selectedCategoryList === undefined || selectedCategoryList === "" || selectedCategoryList === ""}
                  onChange={changeSubCategorySelectionHandler}
                  styles={customStyles} />
              </FormControl>
            </Box>
            <Box>
              <FormControl className="customer__mobile_drop_down content__mobile_drop_down">
                <GenericSearchSelect
                  options={curationList}
                  value={curationList.filter(c => c.value === selectedCurationId ? c : null)}
                  className="other__drop-downs other__drop-downs-speakers-categories"
                  placeholder={translation.Curators}
                  onChange={changeCuratorSelectionHandler}
                  styles={customStyles} />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions style={{ paddingBottom: 20 }}>
            <button onClick={closeTheAdditionalFiltersDialogBox} className="default__reset-btn">{translation.Close}</button>
            <button onClick={resetAllFiltersInContentPage} className="default__reset-btn">{translation.Reset_Filters}</button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default DesktopNavigation;
