import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useLocation,useNavigate } from "react-router-dom";
import { RedirectURLaction } from "../../redux/action/RedirectAction";
import { useEffect, useState } from "react";
const GetDate = (expires_in) => {
  const today = new Date();
  const exprDate = new Date(Date.parse(expires_in));
  return exprDate > today;
};

const Logout = () => {
  Cookies.remove("Expire_in");
  Cookies.remove("userID");
  Cookies.remove("Header_signature");
  Cookies.remove("Payload");
  Cookies.remove("roles");
  Cookies.remove("accountType");
  if (Cookies.get("saveId") !== "true") {
    Cookies.set("saveId", "false");
    Cookies.remove("password");
    Cookies.remove("email");
  }
};

// For routes that can only be accessed by authenticated users
const AuthGuard = ({ children }) => {
  const payload = Cookies.get("Payload");
  const expires_in = Cookies.get("Expire_in");
  const user = Cookies.get("userID");
  const role = Cookies.get("role") ? JSON.parse(Cookies.get("role")) : null;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (isOffline && !window.location.pathname.startsWith('/downloaded')) {
      navigate("/downloaded")
    }
  }, [isOffline, window.location.pathname])

  if (payload == null || payload === "Provided username and/or password is incorrect") {
    if (location.pathname.includes("/conference/")) {
      // Do not navigate if already on a conference page
      return children;
    } else {
      // Dispatch the action only if redirecting to login
      dispatch(RedirectURLaction(location.pathname));
      return <Navigate to="/login" replace />;
    }
  }

  const valid_token = (GetDate(expires_in));
  if (!valid_token) {
    Logout();
    alert("session expired")
    dispatch(RedirectURLaction(location.pathname));
    return <Navigate to="/login" />;
  }

  // loop over role, if role is not in the list and tries to access /dashboard/contents or /curationUploadApp or /curationModifyApp/:id, return to home page
  // accepted roles are "Admin", "Curator", "Curator-Manager"
  if (role) {
    if (
      (
        location.pathname === "/dashboard/contents" ||
        location.pathname === "/curationUploadApp" ||
        location.pathname.includes("/curationModifyApp/")
      ) &&
      !role.includes("Admin") &&
      !role.includes("Curator-Manager") &&
      !role.includes("Curator")
    ) {
      return <Navigate to="/" />;
    }
  }

  if (
    (
      location.pathname === "/dashboard/reporting" ||
      location.pathname === "/dashboard/users"
    ) &&
    !role.includes("Admin") &&
    !role.includes("Manager")
  ) {
    return <Navigate to="/" />;
  }

  return children;
};

export default AuthGuard;

AuthGuard.propTypes = {
  children: PropTypes.any,
};
