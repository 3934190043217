export const breakpoints = {
    350: {
      width: 250,
      slidesPerView: 1,
    },
    360: {
      width: 350,
      slidesPerView: 1,
    },
    375: {
      width: 342,
      slidesPerView: 1,
    },
    390: {
      width: 355,
      slidesPerView: 1,
    },
    393: {
      width: 355,
      slidesPerView: 1,
    },
    412: {
      width: 412,
      slidesPerView: 1,
    },
    414: {
      width: 414,
      slidesPerView: 1,
    },
    640: {
      width: 640,
      slidesPerView: 2,
    },
    768: {
      width: 768,
      slidesPerView: 2,
    },
    1268: {
      width: 1268,
      slidesPerView: 3,
    },
    1400: {
      width: 1400,
      slidesPerView: 4,
    },
    1900: {
      width: 1900,
      slidesPerView: 5,
    },
    2768: {
      width: 2768,
      slidesPerView: 6,
    },
    3768: {
      width: 3768,
      slidesPerView: 7,
    },
    4768: {
      width: 4768,
      slidesPerView: 8,
    },
  }