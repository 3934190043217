import * as types from "../constant/index";

export default function reducer(
    state = {
        relatedVideoTags: [],
        searchRelated: false,
    },
    action
    ) {
    switch (action.type) {
        case types.RELATED_VIDEO_TAGS:
            return {
                ...state,
                relatedVideoTags: action.payload,
            };
        case types.SEARCH_RELATED:
            return {
                ...state,
                searchRelated: action.payload,
            };

        default:
        return state;
    }
}
