import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setConferenceToDownload } from "../../redux/action/DownloadedAction.js";
import { getDownloadUrl } from "../../servicesRedux/ConferenceService.js";
import requests from "../axios/Requests";

const DownloadManager = () => {
    const dispatch = useDispatch();
    const { conferenceToDownload } = useSelector(state => state.downloadedReducer);

    useEffect(() => {
        if (conferenceToDownload && Object.keys(conferenceToDownload).length > 0) {
            const initiateDownload = async () => {
                try {
                    const downloadLink = await getDownloadUrl(requests.GetStreamingUrl, conferenceToDownload.contentId);
                    
                    const registration = await navigator.serviceWorker.ready;
                    registration.active.postMessage({
                        type: 'DOWNLOAD_CONFERENCE',
                        conference: conferenceToDownload,
                        downloadLink
                    });
                } catch (error) {
                    console.error("Error initiating download:", error);
                    // Optionally handle the error, e.g., show a notification to the user
                } finally {
                    dispatch(setConferenceToDownload({}));
                }
            };

            initiateDownload();
        }
    }, [conferenceToDownload, dispatch]);

    return null;
};

export default DownloadManager;