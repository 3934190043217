import React, { useEffect } from "react";
import { useLocation } from "react-router";
import ReactGA from 'react-ga4';

export default function GAtoplevel() {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: page });
  }, [location]);

  return null;
}