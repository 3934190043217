import * as types from "../constant";

export function RelatedVideoTags(payload) {
    return async (dispatch) => {
        dispatch({ type: types.RELATED_VIDEO_TAGS, payload });
    };
}

export function SearchRelated(payload) {
    return async (dispatch) => {
        dispatch({ type: types.SEARCH_RELATED, payload });
    };
}