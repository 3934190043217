import * as types from "../constant";

export function GetSideBar(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_SIDEBAR, payload });
  };
}

export function GetNavBar(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_NAVBAR, payload });
  };
}

export function GetNavBarFilter(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_NAVBARFILTER, payload });
  };
}

export function GetWatchLaterCountAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_WATCHLATERCOUNT, payload });
  };
}

export function GetCategoriesAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_CATEGORIES, payload });
  };
}

export function getRecommandationsCountAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_RECOMMENDATION_COUNT, payload });
  };
}

export function UpdateRecommandationsCountAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_RECOMMENDATION_COUNT, payload });
  };
}

export function GetAllCategoriesAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ALL_CATEGORIES, payload });
  };
}

export function GetAllSpeakersAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ALL_SPEAKERS, payload });
  };
}

export function GetAllChannelsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ALL_CHANNELS, payload });
  };
}

export function GetAllContributorsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ALL_CONTRIBUTORS, payload });
  };
}

export function GetNewContentAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_NEW_CONTENT, payload });
  };
}

export function setProgressPercent(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SET_PROGRESS_PERCENT, payload });
  };
}

export function getAllTagsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ALL_TAGS, payload });
  };
}

export function setOpenPersonalizedDialog(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SET_OPEN_PERSONALIZED_DIALOG, payload });
  };
}

export function dispatchedPlaylistAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.DISPATCHED_PLAYLIST, payload });
  };
}

export function GetLanguagesAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_LANGUAGES, payload });
  };
}