import * as types from "../constant/index";


export default function reducer(
    state = {
        subscriptions: [],
    },
    action
    ) {
    
    switch (action.type) {
        case types.GET_SUBSCRIPTIONS:
            return {
              ...state,
              subscriptions: action.payload,
            };
        case types.ADD_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: [...state.subscriptions, action.payload]
            }
        case types.DELETE_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: state.subscriptions.filter(item => item.id !== action.payload)
            }
        default:
            return state;
    }
}