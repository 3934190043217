//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * "New" page *                                                  *
 * ?                      "New" it a page named "new-on-TopCream" in the app                                    *
 * ?         it a page that displays the new videos available on topCream sorted by publication date.           *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";

import { TabPanel } from "../components/TabPanel/TabPanel";
import Paginator from "../components/pagination/Paginator";
import Row from "../components/row/Row";

import requests from "../components/axios/Requests";
import { GetCategoriesAction } from "../redux/action/GlobalActions";
import { getCategories } from "../servicesRedux/GlobalService.js";
// import ContentTypeToggle from "../components/smallComponents/ContentTypeToggle";

import "../theme/New/New.scss";

const ViewAll = () => {
  const [value] = useState(0);
  const dispatch = useDispatch();
  // const { translation } = useSelector(state => state.languageReducer);
  const [activeType, setActiveType] = useState(['video', 'podcast', 'pdf']);
  const { language } = useSelector(state => state.languageReducer);
  const languageList = ["fr-FR", "En"];
  const [isLoading, setIsLoading] = useState(false);
  const languageSystem = localStorage.getItem("language") || languageList.find((item) => item.includes(window.navigator.language)) || languageList[1];
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getCategories(requests.GetCategories + "?filterby=customerorder").then(item => {
      dispatch(GetCategoriesAction(item));      
      setCategories(item.all);      
    });    
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);    
  }, []);

  return (
    <>      
      <div className="downloaded">      
        <div className="display__loader">
          {isLoading && <LinearProgress color="inherit" className="progress_bar" />}
        </div> 
        {/* <ContentTypeToggle homePage={false} setActiveTypeCallback={setActiveType} translation={translation} />                     */}
        <TabPanel value={value} index={0}>
          <div className={`downloaded__commun ${value === 0 && "downloadedIsActive"}`}>
            {categories.map((item, index) => (
              <React.Fragment key={index}>
                <div className="section">
                  <Row
                      title={(language ? language === "En" : languageSystem === "En") ? item.label_En : item.label_Fr}
                      type={"Categories"}
                      page={"subscription"}
                      category={item.id}
                      appearanceType={"row"}
                      contentType={"subscription"}
                      mediaType={activeType}
                    />
                  </div>
              </React.Fragment>)              
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={`downloaded__commun ${value === 1 && "downloadedIsActive"}`}>
            UPCOMING ...
          </div>
        </TabPanel>
        <Paginator />
      </div>
    </>    
  );
};

export default ViewAll;