import * as types from "../constant/index";

//tenant reducer

export default function reducer(
    state = {
        tenant: {},
    },
    action
    ) {
    switch (action.type) {
        case types.GET_TENANT:
        return {
            ...state,
            tenant: action.payload,
        };
    
        default:
        return state;
    }
    }