//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                              *
 *                                              * My Speakers page *                                             *
 * ?                      My Speakers is a page named "Watch Later" in the app                                   *
 * ?                      A user can see all the speakers profiles, this page is where they will find them       *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";

import requests from "../components/axios/Requests";
import { getAllSpeakersByDuration } from "../servicesRedux/SpeakerService";

import MySpeaker from "../components/MySpeaker/MySpeaker.jsx";

const MySpeakers = () => {
    const pageParamsLimit = 10;
    const navigate = useNavigate();
    const [activeType, setActiveType] = useState(['video', 'podcast', 'pdf']);
    const { translation } = useSelector(state => state.languageReducer);    
    const [isLoadingForThisWeek, setIsLoadingForThisWeek] = useState(false);
    const [isLoadingForThisMonth, setIsLoadingForThisMonth] = useState(false);
    const [isLoadingForOlder, setIsLoadingForOlder] = useState(false);
    const [thisWeekSpeakers, setThisWeekSpeakers] = useState([]);
    const [thisMonthSpeakers, setThisMonthSpeakers] = useState([]);
    const [olderSpeakers, setOlderSpeakers] = useState([]);    
    const { userRedux } = useSelector(state => state.authentificationReducer);    
    const [pageNumberThisWeek, setPageNumberThisWeek] = useState(1);    
    const [pageNumberThisMonth, setPageNumberThisMonth] = useState(1);    
    const [pageNumberOldSpeakers, setPageNumberOldSpeakers] = useState(1);
    const [totalNoOfRecordsThisWeek, setTotalNoOfRecordsThisWeek] = useState(0);
    const [totalNoOfRecordsThisMonth, setTotalNoOfRecordsThisMonth] = useState(0);
    const [totalNoOfRecordsOldSpeakers, setTotalNoOfRecordsOldSpeakers] = useState(0);

    useEffect(() => {
        setIsLoadingForThisWeek(true);
        setIsLoadingForThisMonth(true);
        setIsLoadingForOlder(true);
        getAllSpeakersByDuration(requests.GetSpeakersByDurationCategory, activeType, "ThisWeek", userRedux.preferredLanguageIds, pageParamsLimit, pageNumberThisWeek).then(item => {
            setIsLoadingForThisWeek(false);
            setThisWeekSpeakers(item.data);
            setPageNumberThisWeek(item.currentPage);
            setTotalNoOfRecordsThisWeek(item.totalItems);
        });
        getAllSpeakersByDuration(requests.GetSpeakersByDurationCategory, activeType, "ThisMonth", userRedux.preferredLanguageIds, pageParamsLimit, pageNumberThisMonth).then(item => {
            setIsLoadingForThisMonth(false);
            setThisMonthSpeakers(item.data);
            setPageNumberThisMonth(item.currentPage);
            setTotalNoOfRecordsThisMonth(item.totalItems);
        });
        getAllSpeakersByDuration(requests.GetSpeakersByDurationCategory, activeType, "Older", userRedux.preferredLanguageIds, pageParamsLimit, pageNumberOldSpeakers).then(item => {
            setIsLoadingForOlder(false);
            setOlderSpeakers(item.data);
            setPageNumberOldSpeakers(item.currentPage);
            setTotalNoOfRecordsOldSpeakers(item.totalItems);
        });
    }, [activeType]);

    const addTypeToActiveType = type => {
        setActiveType(prevActiveType => {
            if (prevActiveType.includes(type)) {
                return prevActiveType.filter(t => t !== type);
            } else {
                return [...prevActiveType, type];
            }
        });
    };

    const loadMoreSpeakers = speakersType => {
        switch(speakersType) {
            case "ThisWeek":
                setIsLoadingForThisWeek(true);
                getAllSpeakersByDuration(requests.GetSpeakersByDurationCategory, activeType, "ThisWeek", userRedux.preferredLanguageIds, pageParamsLimit, (pageNumberThisWeek +  1)).then(item => {
                    setIsLoadingForThisWeek(false);
                    setThisWeekSpeakers(prevThisWeekSpeakers => [...prevThisWeekSpeakers, ...item.data]);
                    setPageNumberThisWeek(item.currentPage);
                });        
                break;
            case "ThisMonth":
                setIsLoadingForThisMonth(true);        
                getAllSpeakersByDuration(requests.GetSpeakersByDurationCategory, activeType, "ThisMonth", userRedux.preferredLanguageIds, pageParamsLimit, (pageNumberThisMonth +  1)).then(item => {
                    setIsLoadingForThisMonth(false);
                    setThisMonthSpeakers(prevThisMonthSpeakers => [...prevThisMonthSpeakers, ...item.data]);
                    setPageNumberThisMonth(item.currentPage);
                });        
                break;
            case "Older":
                setIsLoadingForOlder(true);
                getAllSpeakersByDuration(requests.GetSpeakersByDurationCategory, activeType, "Older", userRedux.preferredLanguageIds, pageParamsLimit, (pageNumberOldSpeakers +  1)).then(item => {
                    setIsLoadingForOlder(false);
                    setOlderSpeakers(prevOlderSpeakers => [...prevOlderSpeakers, ...item.data]);
                    setPageNumberOldSpeakers(item.currentPage);
                });                        
                break;                
        }
    };

    return (
        <>
            <div className="my__speakers-wrapper">                
                <div className="my__speakers-loading-wrapper">
                    {(isLoadingForThisWeek || isLoadingForThisMonth || isLoadingForOlder) && 
                    <LinearProgress color="inherit" className="progress_bar" />}
                </div>                    
                <div className={`row__title-second-half row__title-second-half-extended watch__later--buttons--wrapper my__speakers-first-child`}>
                    <Button 
                        className={`${activeType.indexOf("video") > -1 ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`} 
                        onClick={() => addTypeToActiveType("video")}
                    >{translation.New_Video}</Button>
                    <Button 
                        className={`${activeType.indexOf("podcast") > -1 ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`} 
                        onClick={() => addTypeToActiveType("podcast")}
                    >{translation.New_Podcast}</Button>
                    <Button 
                        className={`${activeType.indexOf("pdf") > -1 ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`} 
                        onClick={() => addTypeToActiveType("pdf")}
                    >{translation.SideBar_EditorialContent}</Button>
                </div>

                <div className="row__title-second-half row__title-second-half-extended my__speakers--content-wrapper my__speakers--content-wrapper-first-child">
                    <div className="my__speakers--content-wrapper-title myspeakers__manage-header">
                        <span>{translation.This_Week} ({totalNoOfRecordsThisWeek})</span>
                        <Button className="remove__watched-button" onClick={() => navigate("/manage-speakers")}>{translation.Manage}</Button>                    
                    </div>
                </div>

                {(thisWeekSpeakers && thisWeekSpeakers.length > 0) &&
                <div className="row__title-second-half row__title-second-half-extended my__speakers--content-wrapper my__speakers--content-wrapper-extended">                    
                    <div className="my__speakers--content-wrapper-content">
                        {thisWeekSpeakers.map(e => <MySpeaker item={e} />)}
                    </div>
                        {(thisWeekSpeakers.length >= pageParamsLimit && (pageNumberThisWeek * pageParamsLimit < totalNoOfRecordsThisWeek)) && (
                        <div className="my__speakers_load_more_btn_wrapper">
                            <button onClick={() => loadMoreSpeakers('ThisWeek')} className="linkedVideo--button">{translation.Conference_SeeMore}</button>
                        </div>
                    )}                
                </div>}
                                
                <div className="row__title-second-half row__title-second-half-extended my__speakers--content-wrapper my__speakers--content-wrapper-extended">                    
                    <div className="my__speakers--content-wrapper-title">
                        <span>{translation.This_Month} ({totalNoOfRecordsThisWeek})</span>
                    </div>
                    {(thisMonthSpeakers && thisMonthSpeakers.length > 0) &&
                    <div className="my__speakers--content-wrapper-content">
                        {thisMonthSpeakers.map(e => <MySpeaker item={e} />)}
                    </div>}
                    {(thisMonthSpeakers.length >= pageParamsLimit && (pageNumberThisMonth * pageParamsLimit < totalNoOfRecordsThisMonth)) && (
                        <div className="my__speakers_load_more_btn_wrapper">
                            <button onClick={() => loadMoreSpeakers('ThisMonth')} className="linkedVideo--button">{translation.Conference_SeeMore}</button>
                        </div>
                    )} 
                </div>
                
                <div className="row__title`-second-half row__title-second-half-extended my__speakers--content-wrapper my__speakers--content-wrapper-extended">                                        
                    <div className="my__speakers--content-wrapper-title">
                        <span>{translation.Older} ({totalNoOfRecordsOldSpeakers})</span>
                    </div>  
                    {(olderSpeakers && olderSpeakers.length > 0) &&
                    <div className="my__speakers--content-wrapper-content">                        
                        {olderSpeakers.map(e => <MySpeaker item={e} />)}
                    </div>} 
                    {(olderSpeakers.length >= pageParamsLimit && (pageNumberOldSpeakers * pageParamsLimit < totalNoOfRecordsOldSpeakers)) && (
                        <div className="my__speakers_load_more_btn_wrapper">
                            <button onClick={() => loadMoreSpeakers('Older')} className="linkedVideo--button">{translation.Conference_SeeMore}</button>
                        </div>
                    )}  
                </div>
            </div>
        </>
    );
};

export default MySpeakers;