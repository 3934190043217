//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Talks page *                                               *
 * ?                      TalksPage is the landing page after a user is logged in                                *
 * ?                      It contains 2 mains parts : "Banner" and "Row". Two components                        *
 * ?                      Banner is a simple component displaying one conference at a time                      *
 * ?            Row is a more complex component using a slider and receiving multiples list (more info in Row)  *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useState } from "react";
import { useSelector } from "react-redux";

import Banner from "../components/banner/DekstopBanner";
import Row from "../components/row/Row";
import discover from "../assets/images/discover.svg";
import heart from "../assets/images/heart.svg";
import education from "../assets/images/education.svg";
import star from "../assets/images/star.svg";
import Loader from "../components/async/Loader";

import "../theme/homepage/Homepage.scss";
import "../App.css";

const TalksPage = () => {

  const { translation } = useSelector(state => state.languageReducer);
  const { view } = useSelector(state => state.viewallReducer);
  const [rowsIsLoaded, setRowsIsLoaded] = useState(true);
  const logoList = [discover,education, heart, star];

  return (
    <div>
      {!rowsIsLoaded ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="homepage">
          <div className="conference">{translation.Home_Today}</div>
          <Banner type="Banner" />

          {view.map((item, index) => (
            <Row
              title={eval(view[index].title)}
              type={view[index].api}
              logo={logoList[index]}
              sectionUrl={view[index].url}
              appearanceType={"row"}
              contentType={"homepage"}
              setRowsIsLoaded={setRowsIsLoaded}
          />
          ))}
        </div>
      )}
    </div>
  );
}

export default TalksPage;
