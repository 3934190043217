import * as types from "../constant/index";
import Cookies from "js-cookie";
const deleteReply = (idParent, idReply, Reply) => {
  const updatedList = [];

  Reply.forEach((element) => {
    if (element.id === idParent) {
      const filtredReply = element.row.data.filter(
        (item) => item.id !== idReply
      );
      updatedList.push({
        ...element,
        row: {
          ...element.row,
          data: filtredReply,
          totalItems: element.row.totalItems - 1,
        },
      });
    } else {
      updatedList.push(element);
    }
  });
  return updatedList;
};

const addReply = (Reply, idParent, newReply, comments) => {
  const idUser = Cookies.get("userID");

  const updatedList = [];

  const newRow = {
    id: newReply.id,
    avatar: newReply.avatar,
    body: newReply.body,
    firstName: newReply.firstName,
    lastName: newReply.lastName,
    userID: idUser,
  };

  Reply.forEach((element) => {
    if (element.id === idParent) {
      updatedList.push({
        ...element,
        row: {
          ...element.row,
          data: [...element.row.data, newRow],
          totalItems: element.row.totalItems - 1,
        },
      });
    } else {
      updatedList.push(element);
    }
  });

  var existance = Reply.find((r) => r.id === idParent);
  if (existance === undefined) {
    var data = [newReply];
    var row = { data };
    var newReplyState = { id: idParent, row };
    updatedList.push(newReplyState);
  }

  return updatedList;
};

export default function reducer(
  state = {
    comments: {
      pageSize: 0,
      currentPage: 0,
      totalItems: 0,
      totalPages: 0,
      data: [],
    },
    Reply: [],
    replyState: false,
  },
  action
) {
  switch (action.type) {
    case types.GET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    case types.NEW_REPLY_STATE:
      return {
        ...state,
        replyState: action.payload,
      };

    case types.GET_ALL_REPLIES_BY_ID:
      return {
        ...state,
        Reply: [...state.Reply, { id: action.id, row: action.payload }],
      };

    case types.DELETE_REPLY_BY_ID:
      return {
        ...state,
        Reply: deleteReply(
          action.payload.idParent,
          action.payload.idReply,
          state.Reply
        ),
      };

    case types.ADD_REPLY:
      return {
        ...state,
        Reply: addReply(
          state.Reply,
          action.payload.idParent,
          action.payload.newReply,
          state.comments
        ),
      };

    case types.DELETE_COMMENT_BY_ID:
      return {
        ...state,
        comments: {
          ...state.comments,
          totalItems: state.comments.totalItems - 1,
          data: state.comments.data.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    case types.ADD_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: [ action.payload, ...state.comments.data],
        },
      };

    case types.UPDATE_COMMENTS:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((content, i) =>
            content.id === action.payload.id
              ? { ...content, body: action.payload.body }
              : content
          ),
        },
      };

    case types.ADD_CHILD_COUNT_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((content, i) =>
            content.id === action.payload
              ? { ...content, childCount: content.childCount + 1 }
              : content
          ),
        },
      };
    case types.REMOVE_CHILD_COUNT_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          data: state.comments.data.map((content, i) =>
            content.id === action.payload
              ? { ...content, childCount: content.childCount - 1 }
              : content
          ),
        },
      };

    default:
      return state;
  }
}
