import * as types from "../constant";

export function GetConferenceAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_CONFERENCE, payload });
  };
}

export function RelatedVideosAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_CONFERENCE_BY_TAG, payload });
  };
}

// export function getChaptersAction(payload) {
//   return async (dispatch) => {
//     dispatch({ type: types.GET_CHAPTERS, payload });
//   };
// }

// export function getHighlightsAction(payload) {
//   return async (dispatch) => {
//     dispatch({ type: types.GET_HIGHLIGHTS, payload });
//   };
// }

