//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Editorial Content page *                                      *
 * ?                      Editorial Content is a page that will display the pdf view to the user                *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Pdf from "@mikecousins/react-pdf";
import ReactFullScreenElement from "react-fullscreen-element";
import { Alert as MuiAlert } from "@material-ui/lab";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import requests from "../components/axios/Requests";
import LinkedVideoSkeleton from "../components/linkedVideo/LinkedVideoSkeleton";
import LinkedVideo from "../components/linkedVideo/LinkedVideo";
import LinkedArticleSkeleton from "../components/linkedArticle/LinkedArticleSkeleton";
import LinkedArticle from "../components/linkedArticle/LinkedArticle";
import Descriptions from "../components/descriptions/Descriptions";

import { getConference } from "../servicesRedux/ConferenceService.js";
import PdfViewerButtonsWrapper from  "../components/PdfViewerButtonsWrapper/PdfViewerButtonsWrapper";
import SamplePdf from "../assets/sample-pdf.pdf";

const EditorialContent = () => {
  const { id } = useParams();
  const [conference, setConference] = useState([]);
  const {translation} = useSelector((state) => state.languageReducer);
  const [transcriptList, setTranscriptList] = useState();
  const [linkedVideoHasLoaded, setLinkedVideoHasLoaded] = useState(false);
  const userID = Cookies.get("userID");
  const [page, setPage] = useState(1);
  const [scale, setScale] = useState(1.25);
  const [fullscreen, setFullscreen] = useState(false);
  const Alert = styled(MuiAlert)(spacing);

  useEffect(() => {
    getConference(requests.GetConference, id).then(
      (item) => setConference(item)
    );
    loadTranscript();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const loadTranscript = (url) => {
    if (url !== undefined && url !== null && url !== "") {
      fetch(url, {
        method: "get",
      })
      .then(handleResponse)
      .catch(function (err) {
        console.log(err);
      });
    }
  };

  const handleResponse = (response) => {
    return response.text().then((item) => {
      const data = item && JSON.parse(item);
      if (!response.ok) {
        return Promise.reject(new Error(data.error_description));
      }
      setTranscriptList(data);
      return data;
    });
  };

  const onDocumentDownload = () => {
    fetch(SamplePdf).then(response => {
      response.blob().then(blob => {
          const fileName = response.url.split("/").pop();
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = fileName;
          alink.click();
      })
    })
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setFullscreen(false);
    }
  }, []);

  return (
    <>
      <div className="conferences">
        <div className="conferences--styling">
          <div className="conferences__video">
            <div>
              <div className="all-page-container"> 
                <Pdf 
                  file={SamplePdf} 
                  page={page} 
                  scale={scale}
                >
                {({ pdfDocument, pdfPage, canvas }) => (
                  <>
                    {!pdfDocument && <span>Loading...</span>}

                    {canvas}

                    {Boolean(pdfDocument && pdfDocument.numPages) && (

                      <PdfViewerButtonsWrapper 
                        page={page}
                        setPage={setPage}
                        pdfDocument={pdfDocument}
                        scale={scale}
                        setScale={setScale}
                        setFullscreen={setFullscreen}
                        onDocumentDownload={onDocumentDownload}
                      />

                    )}
                    </>
                  )}
                </Pdf>

                <Descriptions
                  id={id}
                  tags={conference?.tag}
                  viewCount={conference?.viewCount}
                  contributor={conference?.contributors}
                  speaker={conference?.speaker}
                  infoConference={conference?.infoConference}
                  userID={userID}
                  transcriptList={transcriptList}
                  likes={conference?.review}
                  thumbnailURL={conference?.conference?.thumbnailURL}
                  translation={translation}
                  channel={conference?.contributors?.channel}
                  contentId={conference?.contentId}
                  isSaved={conference?.isSaved}
                />
                
              </div>
            </div>                    
          </div>

          <div className="sideBarRight__global">
            {!linkedVideoHasLoaded && <LinkedArticleSkeleton/>}
            {conference.contentId  && 
              <LinkedArticle 
                contentId={conference?.contentId}
                translation={translation}
                setLinkedVideoHasLoaded={setLinkedVideoHasLoaded}
              />
            }

            {!linkedVideoHasLoaded && <LinkedVideoSkeleton/>}
            {conference.contentId  && 
              <LinkedVideo 
                contentId={conference?.contentId}
                translation={translation}
                setLinkedVideoHasLoaded={setLinkedVideoHasLoaded}
              />
            }  

            {fullscreen && 
              <ReactFullScreenElement
                  fullScreen={fullscreen}
                  allowScrollbar={true}
              >
                <div className="all-page-container all-page-container--extended">  

                  <Alert mb={5} severity="info" style={{
                    zIndex: 1200
                  }}>
                    <strong>Please scroll down to see the full page!</strong>
                  </Alert>

                  <Pdf 
                    file={SamplePdf} 
                    page={page} 
                    scale={3}
                  >
                  {({ pdfDocument, pdfPage, canvas }) => (
                    <>
                      {!pdfDocument && <span>Loading...</span>}

                      {canvas}

                      <PdfViewerButtonsWrapper 
                        page={page}
                        setPage={setPage}
                        pdfDocument={pdfDocument}
                        scale={scale}
                        setScale={setScale}
                        setFullscreen={setFullscreen}
                        onDocumentDownload={onDocumentDownload}
                        fullScreen={fullscreen}
                      />  

                    </>
                  )}
                </Pdf>
              </div>
            </ReactFullScreenElement>
            }
          </div>
        </div>
      </div>
      {fullscreen && <div className="appMask"></div>}
    </>
  );
}

export default EditorialContent;
