import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import ProgressBar from "./ProgressBar";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ width: 800, height: 100 }} />
    </Draggable>
  );
}

export default function DialogLoader(props) {
    const { opened,value } = props;
  const { progressPercent } = useSelector((state) => state.globalReducer);


  return (
    <div>
      <Dialog
        open={opened}
        onClose={progressPercent === "100.0"}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{
            cursor: "move",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          id="draggable-dialog-title"
        >
          Loading {value}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <ProgressBar progress={progressPercent} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
