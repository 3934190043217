//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * SideBarRight component *                                      *
 * ?                                       SideBar is used in the conference page                               *
 * ?            It displays informations about the video, metadata choosen by the uploader of the conference    *
 * ?                            Metadata such as marking elements of the video, or special events               *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { React, useEffect, useState } from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { Chapters } from "./Chaptors";
import { Highlights } from "./Highlights";
import { TranscriptTab } from "./TranscriptTab";

import "./SideBarRight.scss";

const SideBarRight = props => {
  let { highlights, chapters, staticimg, translation, transcriptList } = props;
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = index => setToggleState(index);

  useEffect(() => {
    if (chapters?.length === 0) {
      setToggleState(2);
    }
    if (highlights?.length === 0) {
      setToggleState(1);
    }
  }, [chapters?.length, highlights?.length]);

  return (
    <div className="sideBarRight">
      <div className="sideBarRight__content">
        <button
          className={`sideBarRight__columTitle--chapter ${toggleState === 1 ? "isActive" : "notActive"}`}
          onClick={() => toggleTab(1)}
        >
          <p>{translation.Conference_Chapters}</p>
        </button>
        <button
          className={`sideBarRight__columTitle--marking 
          ${toggleState === 2 ? "isActive" : "notActive"
            }
            ${highlights?.length === 0 ? "disabled" : ""}`
          }
          onClick={() => toggleTab(2)}
          disabled={highlights?.length === 0}
        >
          <p>{translation.Conference_Highlights}</p>
        </button>
        <button
          onClick={() => toggleTab(3)}
          className={`sideBarRight__columTitle--marking ${toggleState === 3 ? "isActive" : "notActive"}`}
        >
          <p className="intervenants__retranscription--transcript">
            {translation.Conference_Transcript}
          </p>
        </button>
      </div>
      <div className={((chapters?.length == 0 && toggleState === 1) || (highlights?.length == 0 && toggleState === 2)) ? "sideBarRight_no_content" : ""}>        
      </div>
      {typeof window !== "undefined" && (
        <Scrollbar className="sideBarRight__scrollBar">
          <div className="sideBarRight__content--tabs">
            <div
              className={`sideBarRight--bg ${
                toggleState === 1 ? "sideBarRight--content  sideBarRight-contentActive" : "sideBarRight--content"
              }`}
            >
              {chapters &&
                chapters.map((item, index) => (
                  <div key={index} style={{ cursor: "pointer" }}>
                    <Chapters label={item.label} secs={item.timeIndex} imgsrc={staticimg} />
                  </div>
                ))}
            </div>
            <div
              className={`sideBarRight--bg ${
                toggleState === 2 ? "sideBarRight--content  sideBarRight-contentActive" : "sideBarRight--content"
              }`}
            >
              {highlights &&
                highlights.map((item, index) => (
                  <div key={index} style={{ cursor: "pointer" }}>
                    <Highlights label={item.label} start={item.startTimeIndex} end={item.endTimeIndex} imgsrc={staticimg} />
                  </div>
                ))}
            </div>
            <div
              className={`sideBarRight--bg ${
                toggleState === 3 ? "sideBarRight--content  sideBarRight-contentActive" : "sideBarRight--content"
              }`}
            >
              {transcriptList && (
                <div>
                  <TranscriptTab transcriptList={transcriptList} />
                </div>
              )}
            </div>
          </div>
        </Scrollbar>
      )}

    </div>
  );
};

export default SideBarRight;