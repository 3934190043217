//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * VideoEnLien component *                                       *
 * !                                       Naming should be changed ASAP                                        *
 * ?                                 VideoEnLien is used in the conference page                                 *
 * ?               It's use like a youtube design, showing the recommendation based on the current video        *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import Avatar from "@material-ui/core/Avatar";

import requests from "../../components/axios/Requests";
import { RelatedVideos } from "../../servicesRedux/ConferenceService.js";
import { useSelector } from "react-redux";
import starRating from "../../assets/images/star-icon-video.svg";
import video from "../../assets/icons/Icons/Label=Video.svg";
import podcast from "../../assets/icons/Icons/Label=Podcast.svg";
import article from "../../assets/icons/Icons/Label=Article.svg";

import "swiper/swiper-bundle.css";
import 'swiper/scss';
import "../row/Row.scss";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/grid";

const LinkedVideoMobile = props => {
  const { contentId, translation, setLinkedVideoHasLoaded } = props;  
  const [content, setContent] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const nbSlidesRef = useRef(0);
  const activeIndexRef = useRef(0);
  const pageParams = {
    "limit": 10,
    "page": totalPages >= pageNumber ? pageNumber : 1,
  };
  content?.data && setLinkedVideoHasLoaded(true);
  const { userRedux } = useSelector(state => state.authentificationReducer);

  useEffect(() => {
    RelatedVideos(requests.RelatedVideos, contentId, pageParams.limit, pageParams.page, userRedux.preferredLanguageIds).then(item => setContent(item));
  }, []);

  useEffect(() => {
    if (content.currentPage!==undefined) {
      setTotalPages(content.totalPages);
      setPageNumber(content.currentPage);
    }
  }, [content]);

  const goToConference = id => {
    if(props.isAuthenticated)
        window.location.href = `/conference/${id}`;
  }

  const durationFNC = obj => {
    if (!obj) return "0";
    const timeTable = obj.substring(0, obj.indexOf(".") !== -1 ? obj.indexOf(".") : obj.length).split(":");
    const hh = timeTable[0];
    const mm = timeTable[1];
    const ss = timeTable[2];
    if (hh === "00") {
      return mm + ":" + ss;
    } else {
      return hh + ":" + mm + ":" + ss;
    }
  };

  return (
    (contentId !== null && contentId !== undefined) &&
    <div className={content?.data ? "linkedVideo linkedVideo__mobile" : "linkedVideo-disable"}  >
      {contentId !== null && contentId !== undefined && (<h3 className="linkedVideo--styling">{translation.Related_Content}</h3>)}
      <div className="row linked__video--row-extended">
            <div className="row__cards">
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={30}
                    centeredSlidesBounds={true}
                    slidesPerView={4}
                    height={`${window.innerHeight * 0.6}px`}
                    pagination={{
                        clickable: true,
                        el: ".slider-pagination",
                        type: "bullets",
                    }}
                    className={"mySwiper"}
                    breakpoints={{
                        350: {
                            width: 250,
                            slidesPerView: 1,
                        },
                        360: {
                            width: 360,
                            slidesPerView: 1,
                        },
                        375: {
                            width: 375,
                            slidesPerView: 1,
                        },
                        390: {
                            width: 390,
                            slidesPerView: 1,
                        },
                        393: {
                            width: 388,
                            slidesPerView: 1,
                        },
                        412: {
                            width: 412,
                            slidesPerView: 1,
                        },
                        414: {
                            width: 414,
                            slidesPerView: 1,
                        },
                        640: {
                            width: 640,
                            slidesPerView: 2,
                        },
                        768: {
                            width: 768,
                            slidesPerView: 2,
                        },
                        1268: {
                            width: 1268,
                            slidesPerView: 3,
                        },
                        1400: {
                            width: 1400,
                            slidesPerView: 4,
                        },
                        1900: {
                            width: 1900,
                            slidesPerView: 5,
                        },
                        2768: {
                            width: 2768,
                            slidesPerView: 6,
                        },
                        3768: {
                            width: 3768,
                            slidesPerView: 7,
                        },
                        4768: {
                            width: 4768,
                            slidesPerView: 8,
                        },
                    }}
                    navigation
                    onBreakpoint={breakpoint => {
                        const slidePer = breakpoint.originalParams.breakpoints;
                        nbSlidesRef.current = slidePer[breakpoint.currentBreakpoint]?.slidesPerView;
                    }}
                    onNavigationPrev={swiper => {
                        swiper.allowSlideNext = true;
                    }}
                    onSlideChange={swiper => {
                        activeIndexRef.current++;
                        if (activeIndexRef.current === -1) {
                            activeIndexRef.current = 0;
                            swiper.activeIndex = 0;
                            swiper.slideTo(0);
                        }
                        if ((swiper.activeIndex <= pageParams.limit - nbSlidesRef.current ? swiper.activeIndex :
                            swiper.activeIndex - activeIndexRef.current * (pageParams.limit - nbSlidesRef.current)) === pageParams.limit - nbSlidesRef.current
                        ) {
                            activeIndexRef.current++;
                        }
                    }}
                    >
                    {content?.data?.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="relatedVideo" key={index}>
                                    <div className="relatedVideo__img-container" onClick={() => goToConference(item.catalogItemId)} style={{
                                        backgroundImage: `url(${item.image})`,                                        
                                    }}>
                                        <div className="row__top__poster_bottom">
                                            {<img src={(item.mediaTypeId || item.mediaType) === 1 ? video : ((item.mediaTypeId || item.mediaType) === 2 ? podcast : article)} />}
                                            <span className="row__top__poster_bottom_label">                                            
                                            {durationFNC(item.duration)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="relatedVideo__background">
                                        <div
                                            className="relatedVideo--text"
                                            onClick={() => goToConference(item.catalogItemId)}
                                        >
                                            {item.conferenceName.length > 50 ? item.conferenceName.substring(0, item.conferenceName.length - 40).concat('...') : item.conferenceName}
                                        </div>
                                        <div className="row__text__top">
                                            <div className="row__text--label--speakername">
                                            {(item?.speakers && item?.speakers[0] && item?.speakers[0]?.firstName) &&
                                            <Avatar
                                                className="row__top--logo"
                                                src={item?.speakers ? item?.speakers[0]?.avatar : item?.avatar}
                                            />
                                            }
                                            {item?.speakers && item?.speakers[0] ? item?.speakers[0]?.firstName +  " " + item?.speakers[0]?.lastName : null}
                                            </div>
                                            {item?.rating !== 0 &&
                                            <div className="row__text__bottom--recommended">
                                                <img src={starRating} />
                                                <span>{item?.rating}</span>
                                            </div>}
                                        </div>
                                    </div>
                                </div>                
                            </SwiperSlide>
                        )            
                    })}
                </Swiper>      
            </div>
        </div>            
    </div>
  )
};

export default LinkedVideoMobile;
