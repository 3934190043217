import { React } from "react";
import { useDispatch } from "react-redux";

import { GetVideoCurrentTime} from  "../../redux/action/VideoCurrentTimeAction";

import { secondsToTime } from "../../hooks/utils";

import play from "../../assets/images/small-play-icon.svg";

export const Chapters = (props) => {
    const dispatch = useDispatch();
    const { label, secs, imgsrc } = props;
  
    const convertedTimeIndex = secondsToTime(secs);
  
    const chaptersTime = () => dispatch(GetVideoCurrentTime({toggle: true, time: secs}));
  
    return (
      <div className="chapters" onClick={chaptersTime}>      
        <div style={{
          backgroundImage: `url("${imgsrc}")`,
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain'
        }} className="chapters__background-image"
      >    
          <img src={play} alt="play" className="row__top__play--logo" />      
        </div>
        <div className="chapters__other-information">
          <h3>{label}</h3>
            <p className="chapters--timer">
              {convertedTimeIndex.m}:{convertedTimeIndex.s}
            </p>
        </div>
      </div>
    );
  };