import * as types from "../constant";

export function GetUserAction(payload) {
    return async (dispatch) => {
        dispatch({ type: types.GET_USER, payload });
    };
}

export function GetCategoriesAction(payload) {
    return async (dispatch) => {
        dispatch({ type: types.GET_CATEGORIES, payload });
    };
}