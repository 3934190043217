import * as types from "../constant";

export function GetDownloadedAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_DOWNLOADED, payload });
  };
}

export function LoadNextAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.LOAD_NEXT, payload });
  };
}

export function HasLoadedAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.HAS_LOADED, payload });
  };
}
export function setConferenceToDownload(payload) {
  return async (dispatch) => {
    dispatch({ type: types.CONFERENCE_TO_DOWNLOAD, payload });
  };
}

