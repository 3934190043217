import axios from "axios";
import { register } from '../serviceWorkerRegistration';

export function getTokenByID(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        const appVersion = response.headers["front_app_version"];
        // Compare app version in local storage with app version in response header
        if (localStorage.getItem("front_app_version") !== appVersion) {
          // Clear localStorage
          localStorage.clear();
          
          // Save new app version in local storage
          localStorage.setItem("front_app_version", appVersion);
          
          // Construct new URL with version and timestamp
          const baseUrl = window.location.href.split('?')[0];
          const newUrl = `${baseUrl}?version=${appVersion}&t=${new Date().getTime()}`;
          console.log("eeeeeeeeeeeeeeeeeeee")
          register({
            onUpdate: registration => {
              const waitingServiceWorker = registration.waiting;
              if (waitingServiceWorker) {
                waitingServiceWorker.addEventListener("statechange", event => {
                  if (event.target.state === "activated") {
                    if (window.confirm('New version available! Reload to update?')) {
                      window.location.reload();
                    }
                  }
                });
                waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
              }
            },
            onSuccess: registration => {
              console.log('Service Worker registered successfully');
            }
          });
          
          // Redirect to the new URL, which will cause a hard refresh
          window.location.href = newUrl;
          
          // Note: The code execution will stop here due to the page reload
        }
        
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getMe(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postFirstTimeLogin(requests, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(requests, data, { withCredentials: true })
      .then(resolve)
      .catch(reject);
  });
}

export function postRegistration(requests, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(requests, data, { withCredentials: true })
      .then(resolve)
      .catch(reject);
  });
}

export function confirmRegistration(requests, data) {
  const request = requests.replace("{confirmationToken}", data);
  return new Promise((resolve, reject) => {
    axios
      .put(request)
      .then(resolve)
      .catch(reject);
  });
}

export function resendConfirmationEmail(requests, email) {
  const request = requests.replace("{email}", encodeURIComponent(email));
  return new Promise((resolve, reject) => {
    axios
      .post(request, {}, { withCredentials: true })
      .then(resolve)
      .catch(reject);
  });
}