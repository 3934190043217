import React from "react";

import "./svg-colors.scss";

const BookmarSVG = () => {
    return (
        <svg
            className="share-list-icon" 
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="20"
            fill="none"
            viewBox="0 0 18 20"
        >
            <path
                stroke="#656565"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 3v14.586c0 .89 1.077 1.337 1.707.707L9 12l6.293 6.293c.63.63 1.707.184 1.707-.707V3a2 2 0 00-2-2H3a2 2 0 00-2 2v0z"
            ></path>
        </svg>
    );
};

export default BookmarSVG;
