import { React } from "react";

import Transcript from '../transcript/Transcript';

export const TranscriptTab = ({ transcriptList, translation }) => {
    return (
      transcriptList !== undefined && (
        <div className="intervenants__retranscription--text">
          <Transcript transcript={transcriptList} />
        </div>
      ))
  };