//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Recommended page *                                            *
 * ?                Recommended is a page that will display the video that have been recommended by friends     *
 * ?                      Every user will have a "network", people with whom they work                          *
 * ?                      When on a conference page they will be able to share a video to their friends         *
 * ?                      The friend will see a new video in their recommendations                              *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React from "react";
import { useSelector } from "react-redux";

import RecommendedRow from "../components/recommendedRow/RecommendedRow";
import Row from "../components/row/Row";
import WatchHistoryRow from "../components/watchHistory/WatchHistoryRow";
import useReplacer from "../hooks/replacer";

import "../theme/recommended/Recommended.scss";

const Recommended = () => {
  const activeType = ['video', 'podcast', 'pdf'];
  const { translation } = useSelector((state) => state.languageReducer);
  const replacer = useReplacer();
  const [hasContentRow, setHasContentRow] = React.useState(false);
  const [recommendedHasContent, setRecommendedHasContent] = React.useState(false);

  return (
    <div className="recommended-mtc">
      <div className="recommended_title-container">
        <h1 className="recommended_title">{replacer(translation.Top_Nav_For_Me)}</h1>
      </div>
      <div className="recommended_content-container">
        <div>
          {hasContentRow && <h1 className="recommended_title-row">{replacer(translation.recommendedForMe)}</h1>}
          <Row
            type={"Recommended"}
            sectionUrl={"recommended"}
            appearanceType={"row"}
            contentType={"homepage"}
            mediaType={activeType}
            setHasContentRow={setHasContentRow}
          />
        </div>
        <div className={recommendedHasContent ? "recommended_content-separator" : ""}>
          <RecommendedRow 
            setRecommendedHasContent={setRecommendedHasContent}
          />
        </div>
        <WatchHistoryRow />
      </div>
    </div>
  );
};

export default Recommended;
