//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Dashboard layout *                                            *
 * ?                      This component is not a specific page, it contains our whole application              *
 * ?                      We can place global component here to style our app                                   *
 * ?                      For example we got the sidebar and navbar here because we want them everywhere        *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { useState, useEffect } from "react";
import { Outlet } from "react-router";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import requests from "../components/axios/Requests";
import { GetTokenByIdUser } from "../redux/action/AuthentificationAction";
import { getTokenByID } from "../servicesRedux/AuthentificationService";
import { patchPersonByID } from "../servicesRedux/AdminService";
import { GetWatchLaterCountAction } from "../redux/action/GlobalActions";
import { getTenant } from "../servicesRedux/GlobalService.js";

import Constants from "../components/constants/Constants";
import DashboardNavBar from "../components/navbar/DashboardNavBar";
import Footer from "../components/footer/Footer";

import "../App.css";

const DashboardLayout = () => {
  const location = useLocation();
  const [tenant, setTenant] = useState("");
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [newProfileImage, setNewProfileImage] = useState("");
  const [queryOnDashboardUsers, setQueryOnDashboardUsers] = useState("");
  const [selectedRoleOnMobileLayout, setSelectedRoleOnMobileLayout] = useState("");
  const [queryOnCustomerChange, setQueryOnCustomerChange] = useState("");
  const [companiesList, setCompaniesList] = useState([]);
  const languageList = ["Fr", "En"];
  const [myCompany, setMyCompany] = useState("");
  const lang = localStorage.getItem("language") || languageList.find(item => item.includes(window.navigator.language)) || languageList[1];
  const [updatedSelectedTagsList, setUpdatedSelectedTagsList] = useState([]);
  const [updatedSelectedSpeakersList, setUpdatedSelectedSpeakersList] = useState([]);
  const [updatedSelectedCategoriesList, setUpdatedSelectedCategoriesList] = useState([]);
  const [updatedMediaTypes, setUpdatedMediaTypes] = useState(null);
  const [updatedSubCategoryList, setUpdatedSubCategoryList] = useState([]);
  const [updatedCurationList, setupdatedCurationList] = useState([]);
  const [startDateForContent, setStartDateForContent] = useState(null);
  const [endtDateForContent, setEndDateForContent] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [tagsReset, setTagsReset] = useState(null);
  const [resetContentFilters, setResetContentFilters] = useState(null);
  const [contentAdminStatusDefault, setContentAdminStatusDefault] = useState(false);
  const [contextEmptyContentParamsToState, setContextEmptyContentParamsToState] = useState({
    query: "",
    mediaTypeId: [1, 2, 3],
    speakerId: "",
    categoryId: "",
    curatorId: "",
    startDate: null,
    endDate: null,
    tags: [],
    subCategoryId: "",
    contentAdminStatus: null,
    sortBy: 8,
    orderBy: false 
  });
  const accountType = Cookies.get("accountType");
  const [sendContextValueForResetLeftMenu, setSendContextValueForResetLeftMenu] = useState(false);

  useEffect(() => {
    requestForMeInformation();
  }, [location]);

  useEffect(() => {
    if (roles && roles.length > 0) {
      if (location.pathname === "/dashboard/contents") {
        if ((roles.includes(Constants.Roles.ADMIN)) || (roles.includes(Constants.Roles.CURATOR_MANAGER) || roles.includes("Curator"))) {
          // 
        } else {
          window.location.href = "/";
        }
      }
      if (location.pathname === "/dashboard/users") {
        if (((roles.includes(Constants.Roles.ADMIN)) || (roles.includes(Constants.Roles.CURATOR_MANAGER)) || (roles.includes(Constants.Roles.MANAGER))) && (accountType !== 1 || accountType !== 2)) {
          // 
        } else {
          window.location.href = "/";
        }
      }
      if (location.pathname === "/dashboard/reporting") {
        if (((roles.includes(Constants.Roles.ADMIN)) || (roles.includes(Constants.Roles.MANAGER)))) {
          // 
        } else {
          window.location.href = "/";
        }
      }
    }
  }, [roles]);

  const requestForMeInformation = () => {
    const personRequest = {
      language: lang === "En" ? "en-US" : "fr-FR",
    };
    getTokenByID(requests.GetMeInformation).then(item => {
      Cookies.set("roles", JSON.stringify(item.roles));
      Cookies.set("accountType", item.accountType);
      setRoles(item.roles);
      dispatch(GetTokenByIdUser(item));
      dispatch(GetWatchLaterCountAction(item.watchLaterCount));
      patchPersonByID(requests.PatchPerson, item.id, personRequest);
      setMyCompany(item.accountName);
    });
    getTenant(requests.GetTenant).then(item => setTenant(item)); 
  };

  const setProfileImage = props => setNewProfileImage(props);

  const updateQuery = e => setQueryOnDashboardUsers(e);

  const updateCustomerQuery = e => setQueryOnCustomerChange(e);

  const filterBasedOnRoleMobileView = e => setSelectedRoleOnMobileLayout(e.target.value);

  const updateDashboardUsersOnCompanies = items => setCompaniesList(items);

  const sendToContextTagsArray = items => setUpdatedSelectedTagsList(items);

  const sendToContextSpeakersArray = items => setUpdatedSelectedSpeakersList(items);

  const sendToContextCategoriesArray = items => setUpdatedSelectedCategoriesList(items);

  const sendMediaTypesToContext = items => setUpdatedMediaTypes(items);

  const sendToContextSubCategoriesArray = items => setUpdatedSubCategoryList(items);

  const sendToContextCurationArray = items => setupdatedCurationList(items);

  const sendStartAndEndDateToContext = (startDate, endDate) => {
    setStartDateForContent(startDate);
    setEndDateForContent(endDate);
  };

  const sendTagsArrayToContext = tags => setAllTags(tags);

  const triggerMediaContentAPI = tags => setTagsReset(tags);

  const sendEmptyContentPayloadToContext = () => setResetContentFilters(true);

  const updateTheContentAdminStatusForLeftMenu = () => setContentAdminStatusDefault(true);

  const sendContextEmptyContentParamsToState = content => setContextEmptyContentParamsToState(content);

  const sendRequestToResetTheLeftMenuToContext = () => setSendContextValueForResetLeftMenu(true);

  return (
    <div className="dashboardLayout">      
      <div className="navbar">
        <DashboardNavBar 
          roles={roles}
          newProfileImage={newProfileImage} 
          updateQuery={updateQuery}
          updateCustomerQuery={updateCustomerQuery}
          updateDashboardUsersOnCompanies={updateDashboardUsersOnCompanies}
          tenant={tenant}
          sendToContextTagsArray={sendToContextTagsArray}
          sendToContextSpeakersArray={sendToContextSpeakersArray}
          sendToContextCategoriesArray={sendToContextCategoriesArray}
          sendMediaTypesToContext={sendMediaTypesToContext}
          sendToContextSubCategoriesArray={sendToContextSubCategoriesArray}
          sendToContextCurationArray={sendToContextCurationArray}
          sendStartAndEndDateToContext={sendStartAndEndDateToContext}
          sendTagsArrayToContext={sendTagsArrayToContext}
          triggerMediaContentAPI={triggerMediaContentAPI}
          sendEmptyContentPayloadToContext={sendEmptyContentPayloadToContext}
          updateTheContentAdminStatusForLeftMenu={updateTheContentAdminStatusForLeftMenu}
          sendContextEmptyContentParamsToState={sendContextEmptyContentParamsToState}
          sendRequestToResetTheLeftMenu={sendRequestToResetTheLeftMenuToContext}
        />
      </div>
      <div className="main">
        {<Outlet context={{ 
          setProfileImage, 
          queryOnDashboardUsers, 
          filterBasedOnRoleMobileView, 
          selectedRoleOnMobileLayout,
          queryOnCustomerChange,
          companiesList,
          myCompany,
          updatedSelectedTagsList,
          updatedSelectedSpeakersList,
          updatedSelectedCategoriesList,
          updatedMediaTypes,
          updatedSubCategoryList,
          updatedCurationList,
          startDateForContent,
          endtDateForContent,
          allTags,
          tagsReset,
          sendEmptyContentPayloadToContext,
          resetContentFilters,
          contentAdminStatusDefault,
          contextEmptyContentParamsToState,
          sendContextValueForResetLeftMenu
        }} />}
      </div>
      <div className="footer">
        <Footer/>
      </div>
    </div>
  );
};

export default DashboardLayout;
