import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactGA from 'react-ga4';
import track from "react-tracking";

import AppLayout from "./pages/AppLayout";
import DashboardLayout from "./pages/DashboardLayout";
import Conferences from "./pages/Conferences";
import Homepage from "./pages/Homepage";
import TalksPage from "./pages/Talks";
import Login from "./pages/Login";
import FirstLogin from "./pages/FirstLogin";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import Research from "./pages/Search";
import Recommended from "./pages/Recommended";
import Downloaded from "./pages/Downloaded";
import MySpeakers from "./pages/MySpeakers";
import Admin from "./pages/Admin";
import SuggestionForm from "./pages/SuggestionForm";
import AuthGuard from "./core/guard/AuthGuard";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import ChannelPage from "./pages/ChannelPage";
import EditorialContent from "./pages/EditorialContent";
import New from "./pages/New";
import ManageSpeakers from "./pages/ManageSpeakers";
import ContentManagement from "./pages/ContentManagement";
import UploadApp from "./pages/UploadApp";
import ModifyApp from "./pages/ModifyApp";
import Playlists from "./pages/Playlists";
import ViewAll from "./pages/ViewAll";
import SpeakerPage from "./pages/SpeakerPage";
import Registration from "./components/registration/Registration";
import RegistrationConfirm from "./components/registration/RegistrationConfirm";
import EmailConfirmRegistration from "./components/registration/EmailConfirmRegistration";
import OnBoarding from "./components/onBoarding/OnBoarding";
import { LogUserActivities } from "./servicesRedux/GlobalService";
import requests from "./components/axios/Requests";
import LanguageSelect from "./components/language/LanguageSelect";
import GAtoplevel from "./components/googleAnalytics/GAtoplevel";
import DashboardUsers from "./pages/DashboardUsers";
import DashboardContent from "./pages/DashboardContent";
import DashboardReporting from "./pages/DashboardReporting";
import DownloadedVideoPage from "./pages/DownloadedVideoPage";
const App = () => {
  const { view } = useSelector(state => state.viewallReducer);
  const liveGAcode = "G-VV4FEHPPLG";
  const devGAcode = "G-BZRJFKLS3R";
  const testGAcode = "G-78NDM5KTC4";
  let currentLocation = window.location.href;

  const GAcode = 
    currentLocation.toString().toLowerCase().includes("live.top-cream.com") ? liveGAcode
  : currentLocation.toString().toLowerCase().includes("top-cream.com") ? liveGAcode
  : currentLocation.toString().toLowerCase().includes("tc-wa-dev.azurewebsites.net") ? devGAcode
  : currentLocation.toString().toLowerCase().includes("test.top-cream.com") ? testGAcode
  : devGAcode;

  const envGAcode =
    process.env.ASPNETCORE_ENVIRONMENT === "prod.prod" ? liveGAcode
  : process.env.ASPNETCORE_ENVIRONMENT === "dev.dev" ? devGAcode
  : process.env.ASPNETCORE_ENVIRONMENT === "dev.demo2" ? testGAcode
  : (process.env.ASPNETCORE_ENVIRONMENT === undefined || process.env.ASPNETCORE_ENVIRONMENT === null) ? null : devGAcode;

  ReactGA.initialize(envGAcode ?? GAcode);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <LanguageSelect />
        <GAtoplevel />
        <Routes>
          <Route
            path="/"
            element={<AuthGuard children={<AppLayout />}></AuthGuard>}
          >
            <Route index element={<Homepage />} />
            <Route path="/talks" element={<TalksPage />} />
            <Route path="/conference/:id" element={<Conferences />} />
            <Route path="/editorial-content/:id" element={<EditorialContent />} />          
            <Route path="/search" element={<Research />} />
            <Route path="/watch-later" element={<Downloaded />} />
            <Route path="/my-speakers" element={<MySpeakers />} />
            <Route path="/my-subscriptions" element={<ViewAll />} />
            <Route path="/my-top-cream" element={<Recommended />} />
            <Route path="/profile" element={<Admin />} />
            <Route path="/channel/:id" element={<ChannelPage />} />
            {/* <Route path="/profile/:id" element={<Profile />} /> */}
            <Route path="/new-on-TopCream" element={<New />} />
            <Route path="/suggestionForm" element={<SuggestionForm />} />
            <Route path="/playlists" element={<Playlists />} />
            <Route path="/downloaded" element={<Downloaded />} />
            <Route path="/downloaded/:id" element={<DownloadedVideoPage />} />
            <Route path="/speaker/:id" element={<SpeakerPage />} />
            <Route path="/manage-speakers" element={<ManageSpeakers />} />            
            {view.map(section => (
              <Route path={`/${section.url}`} element={<ViewAll section={section} />} />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>          
          <Route
            path="/"
            element={<AuthGuard children={<DashboardLayout />}></AuthGuard>}
          >                    
            <Route path="/dashboard/users" element={<DashboardUsers />} />
            <Route path="/dashboard/contents" element={<DashboardContent />} />
            <Route path="/dashboard/reporting" element={<DashboardReporting />} />
          </Route>
          <Route path="/curationContentManagement" element={<ContentManagement />} />           
          <Route
            path="/curationUploadApp"
            element={<AuthGuard children={<UploadApp />}></AuthGuard>}
          />
          <Route
            path="/curationModifyApp/:id"
            element={<AuthGuard children={<ModifyApp />}></AuthGuard>}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/first-login" element={<FirstLogin />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/register/confirm" element={<EmailConfirmRegistration />} />
          <Route path="/registration-confirmation/:id" element={<RegistrationConfirm />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/change-password/:token" element={<ChangePassword />} />
          <Route path="/onboarding" element={<OnBoarding />} />
          <Route path="/service-worker.js" element={"./service-worker.js"} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default track({App:"TopCream"}, {dispatch: data => {
  if (data.NbOfClick !== "undefined") {
    data.NbOfClick = 1;
  }
  LogUserActivities(requests.LogUserActivity, data)
}})(App);
