//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                              *
 *                                              * Manage Speakers page *                                         *
 * ?                      Manage Speakers is a page named "Manage Speakers" in the app                           *
 * ?                      A user can see all the speakers, this page is where they will find them                *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

import requests from "../components/axios/Requests";
import { getAllMySpeakersForManageView, deleteMySpeakersForManageView } from "../servicesRedux/SpeakerService";

import { truncate } from "../hooks/utils.js";

import PersonPlaceholder from "../assets/images/person-placeholder.jpg";

import "../components/MySpeaker/MySpeaker.scss";

const ManageSpeakers = () => {
    const navigate = useNavigate();    
    const [isLoading, setIsLoading] = useState(false);
    const { translation } = useSelector(state => state.languageReducer);
    const [speakers, setSpeakers] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getAllMySpeakersForManageView(requests.GetMySpeakersForManage).then(item => {
            setSpeakers(item);
            setIsLoading(false);
        });
    }, []);

    const followUnFollowOnSpeakerProfile = id => {
        setIsLoading(true);
        deleteMySpeakersForManageView(requests.DeleteMySpeakersForManageView, id).then(item => {
            getAllMySpeakersForManageView(requests.GetMySpeakersForManage).then(item => {
                setSpeakers(item);
                setIsLoading(false);
            });
        });
    };

    return (
        <div className="my__speakers-wrapper manage__speakers-container">   
            <div className="display__loader">
                {isLoading && <LinearProgress color="inherit" className="progress_bar" />}
            </div>  
            <div className="row__title-second-half row__title-second-half-extended my__speakers--content-wrapper my__speakers--content-wrapper-extended">                                        
                <div className="my__speakers--content-wrapper-title myspeakers__manage-header">
                    <span>{translation.Manage_Speakers}</span>
                    <div className="exit__button">
                        <IconButton onClick={() => navigate('/my-speakers')}>
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </div>                       
                </div>                
                <div className="my__speakers--content-wrapper-content">                        
                    {speakers && speakers.map(s => {
                        return (
                        <Grid className="my__speakers--content-wrapper-internal-content" item xs={12} md={3} lg={3}>
                            <Grid item xs={12} md={12} lg={12} className="speakers__profile-image-container">
                                <Grid item xs={12} md={4} lg={4} className="speaker__avatar-wrapper">
                                    <div className="speaker_person_avatar--img-wrapper">
                                        <div class="speaker_person_avatar" style={{ backgroundImage: `url(${s?.avatar || PersonPlaceholder})` }} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={8} lg={8} className="speaker_infor-wrapper">
                                    <Tooltip title={`${s.firstName} ${s.lastName}`}>
                                        <Typography variant="h5">{`${truncate(s.firstName, 15)} ${truncate(s.lastName, 15)}`}</Typography>
                                    </Tooltip>
                                    <Tooltip title={s.title}>
                                        <Typography variant="h6">{truncate(s.title, 15)}</Typography>
                                    </Tooltip>                                    
                                    <div className={"speakerFollow"}>
                                        <button onClick={() => followUnFollowOnSpeakerProfile(s.id)} className={`buttonWithPicFollowButtonFollwing`}>
                                            {translation.Following}
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>                           
                        </Grid>)
                    })}                                                 
                </div>              
            </div>
        </div>
    );
};

export default ManageSpeakers;