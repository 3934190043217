// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * NestedListFilter page *                                       *
 * ?   NestedListFilter : user can choose mulitple-filters search for any conference contains in the database   *
 *                        TODO: Change the checkbox List    *
 */
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@mui/material";
import { ClickAwayListener } from "@material-ui/core";
import { MdOutlineSearch } from "react-icons/md";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";

import "./NestedLists.scss";

const styles = () => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: "#CCC",
    color: "black",
  },
  nested: {
    zIndex: "3",
  },
});

const NestedListFilter = props => {
  const { 
    classes, 
    choosenList, 
    GetformData, 
    comboID, 
    checkInfo, 
    onChangeCategoryInput, 
    triggerResetTheCategoriesList,
    includedInSearch,
    disabledStatus,
    selectedTagsArray,
    checkState
  } = props;
  const [open, setOpen] = useState(false);
  const initialFormData = Object.freeze([]);
  const [filterList, setFilterList] = useState(initialFormData);
  const items = choosenList;
  const { translation } = useSelector((state) => state.languageReducer);
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(localStorage.getItem('language'));
  }, []);

  useEffect(() => {
    GetformData(filterList, comboID);
  }, [filterList]);

  useEffect(() => {
    if (disabledStatus != undefined && !disabledStatus && props.nestedListName == "Tags") {
      setOpen(false);
    }
  }, [disabledStatus]);

  /* **************************************** Display/Hide ComboBox items ************************************************ */
  const showCheckBoxList = () => setOpen(!open);

  const onItemClick = (itemName, sitem) => checkExistance(itemName, (sitem.id || sitem.value));

  /* ************************************* Check value Existance into List ********************************************** */
  const checkExistance = (itemName, id) => {
    if (itemName === "PUBLICATION") {
      setFilterList(id);
    } else {
      const temp = filterList.find(fl => fl === id);
      if (temp === undefined) {
        setFilterList([
          ...filterList,
          id,
        ]);
      } else {
        setFilterList(filterList.filter(fl => fl !== id));
      }
    }
    checkInfo(!checkState);
  };

  /* ************************************* Verifiy value checked into List ********************************************** */
  const getChecked = value => {
    if (props.nestedListName !== "PUBLICATION") {
      if (selectedTagsArray && selectedTagsArray.length > 0) {
        const temp = selectedTagsArray && selectedTagsArray.find(fl => fl.id === value);
        if (temp === undefined) {
          return false;
        } else {
          return true;
        }
      } else {
        const temp = filterList && filterList.find(fl => fl === value);
        if (temp === undefined) {
          return false;
        } else {
          return true;
        }
      }          
    }
  };

  const customCheckBox = val => {
    return (
      val === true && (
        <div>
          <Input
            type="text"
            endAdornment={
              <InputAdornment position="end">
                <MdOutlineSearch size={20} style={{ marginRight: 10 }} />
              </InputAdornment>
            }
            onChange={onChangeCategoryInput}
          />
          <div style={{ margin: 20, backgroundColor: includedInSearch ? "transparent" : "#f8f7f6" }}></div>
        </div>
      )
    );
  };

  const resetTheCategoriesList = () => {
    if (props.nestedListName !== "PUBLICATION") triggerResetTheCategoriesList();
    setOpen(false);
  };

  const renderHeadingForFilter = header => {
    switch(header) {
      case "PUBLICATION":
        return translation.Published_Date;
      default:
        return header;
    }
  };

  return (
    <ClickAwayListener onClickAway={resetTheCategoriesList}>
      <div>
        <List className={`NestedListFilter-root-Speaker NestedListFilter-root-tags ${disabledStatus ? 'disabled__drop_downs' : ''}`}>
          <ListItem button onClick={!disabledStatus ? () => showCheckBoxList() : null}>
            <ListItemText primary={renderHeadingForFilter(props.nestedListName)} />
            {(open || disabledStatus) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {(props.nestedListName !== "PUBLICATION") &&
          <ListItem className={`${!includedInSearch ? 'custom__checkbox--nested-taglist-extended' : 'custom__checkbox--nested-taglist'}`}>
            {customCheckBox(open, props.nestedListName)}
          </ListItem>}
        </List>
        <List className="NestedListFilter-root-Speaker NestedListFilter-root-tags">  
          {items && items.map((sitem, index) => {
            return (
              <Collapse
                className="nested_collapseList"
                component="li"
                in={open}
                timeout="auto"
                unmountOnExit
              >
                <div
                  onClick={() => onItemClick(props.nestedListName, sitem)}
                  style={{ backgroundColor: includedInSearch ? "transparent" : "#f8f7f6" }}
                >
                  {((props.nestedListName !== "PUBLICATION")) ?
                  (sitem.label_En !== '' || sitem.label_Fr !== '') && 
                  <ListItem disabled={disabledStatus != undefined && disabledStatus && props.nestedListName == "Tags"} button key={index} className={classes.nested}>
                    <ListItemText key={index} primary={(language == "En" ? sitem.label_En : sitem.label_Fr) || sitem.name || sitem.label} />
                    <Checkbox
                      checked={getChecked(sitem.id)}
                      style={{ color: "#b6aa90" }}
                      className="default__tags-checkbox"
                      disabled={disabledStatus != undefined && disabledStatus && props.nestedListName == "Tags"}                      
                    />
                  </ListItem> :
                  <ListItem disabled={disabledStatus != undefined && disabledStatus && props.nestedListName == "Tags"} button key={index} className={classes.nested}>
                    <ListItemText key={index} primary={sitem.value} />
                      <Checkbox
                        checked={getChecked(sitem.id)}
                        style={{ color: "#b6aa90" }}
                        className="default__tags-checkbox"
                        disabled={disabledStatus != undefined && disabledStatus && props.nestedListName == "Tags"}                      
                      />
                </ListItem>}
                </div>
              </Collapse>
            );
          })}
        </List>
      </div>      
    </ClickAwayListener>
  );
};

NestedListFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  choosenList: PropTypes.object,
  GetformData: PropTypes.func,
  comboID: PropTypes.object,
  checkInfo: PropTypes.func,
  triggerResetTheCategoriesList: PropTypes.func,
  includedInSearch: PropTypes.bool,
  disabledStatus: PropTypes.bool
};

export default withStyles(styles)(NestedListFilter);
