import VideoCard from "./VideoCard";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import "../../theme/row/Row.scss";
import { setIsSelectingAction, setSelectedContentsAction } from "../../redux/action/ExploreAiAction";

const useStyles = makeStyles(theme => ({
  rVideos: {
    flex: 0.85,
    [theme.breakpoints.between("sm", "md")]: {
      flex: 0.95,
    },
    padding: "40px 20px",
    paddingBottom: 0,
    "& a": {
      textDecoration: "none",
      color: "gray",
    },
  },
  heading: {
    marginLeft: "5px",
    marginBottom: "20px",
    color: theme.palette.text.primary,
    fontSize: "2em",
  },
  videos: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const RecommendedVideos = props => {
  const { research, userId, searchScreen } = props;
  const [selected, setSelected] = useState([]);
  const isSelecting = useSelector(state => state.exploreAiReducer.isSelecting);
  const selectedContents = useSelector((state) => state.exploreAiReducer.selectedContents);

  const dispatch = useDispatch();
  const classes = useStyles();

  let error = true;
  if (research === "") {
    error = false;
  }

  const handleSelectContent = (item, index) => {

    // Check if the item is already selected based on catalogItemId
    if (selectedContents.some(content => content.item.catalogItemId === item.catalogItemId)) {
      const updatedSelectedContents = selectedContents.filter(content => content.item.catalogItemId !== item.catalogItemId);
      setSelected(updatedSelectedContents);
      dispatch(setSelectedContentsAction(updatedSelectedContents));
      return;
    }
  
    // If more than 3 items are selected, do not add the new item
    if (selectedContents.length >= 3) {
      return;
    }
  
    const newSelectedContent = { item, index };
    const updatedSelectedContents = [...selectedContents, newSelectedContent];
    setSelected(updatedSelectedContents);
    dispatch(setSelectedContentsAction(updatedSelectedContents));
  };

  useEffect(() => {
    if (!isSelecting) {
      setSelected([]);
    } else {
      // Populate the selected state with the items from selectedContents
      const selectedItems = selectedContents.map(content => ({
        item: content.item,
        index: research.findIndex(item => item.catalogItemId === content.item.catalogItemId)
      }));
      setSelected(selectedItems);
    }
  }, [isSelecting, selectedContents, research]);

  return error ? (
    <div className={`recommended ${classes.videos}`}>
      {research?.map((item, index) => (
        <div
          className={`recommendedVideo_videoCard ${
            isSelecting && !selected.some(content => content.index === index)
              ? "video-card-selecting"
              : ""
          } 
          ${isSelecting && selected.some(content => content.index === index) ? "video-card-selected" : ""}`}
          key={index}
          onClick={() => handleSelectContent(item, index)}
        >
            {isSelecting && <div class="video-card-checkbox"></div>}

          <VideoCard
            item={item}
            userId={userId}
            isSaved={item?.isSaved}
            searchScreen={searchScreen}
          />
        </div>
      ))}
    </div>
  ) : (
    <div className="recommended__noVideo">Nothing found, try something else</div>
  );
};

RecommendedVideos.propTypes = {
  research: PropTypes.any,
};

export default RecommendedVideos;