import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import RecommendedVideoCard from "../videoCard/RecommendedVideoCard";
import requests from "../axios/Requests";
import {
  LoadNextAction,
  HasLoadedAction,
} from "../../redux/action/PaginationAction";
import Paginator from "../../components/pagination/Paginator";
import { UpdateRecommandationsCountAction } from "../../redux/action/GlobalActions";
import {
  getRecommended,
  deleteRecommendedReceipientId
} from "../../servicesRedux/RecommendedService";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { breakpoints } from "./RecommendedRowBreakpoints";
import "./RecommendedRow.scss";

export default function RecommendedRow(props) {
  const dispatch = useDispatch();
  const { recommandationsCount } = useSelector((state) => state.globalReducer);
  const [requestReturn, setRequestReturn] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [load, setLoad] = useState(false);
  const [content, setContent] = useState([]);
  const { loadNext } = useSelector((state) => state.paginationReducer);
  const { translation } = useSelector((state) => state.languageReducer);
  const nbSlidesRef = useRef(0);
  const activeIndexRef = useRef(0);
  
  const pageParams = {
    limit: 10,
    page: totalPages >= pageNumber ? pageNumber : 1,
  };

  const prevParams = useRef();
  const triggerPagination = () => {
    totalPages >= pageNumber && setPageNumber(pageNumber + 1);
    setLoad(!load);
  };

  useEffect(() => {
    const comparison =
      JSON.stringify(prevParams.current) !== JSON.stringify(pageParams);
    if (totalPages ? totalPages >= pageNumber : true && comparison) {
      dispatch(HasLoadedAction(false));
      getRecommended(requests.GetRecommendations, pageParams).then((item) =>
        setRequestReturn(item)
      );
    }
    prevParams.current = pageParams;
  }, [load]);

  useEffect(() => {
    if (requestReturn.currentPage !== undefined) {
      setTotalPages(requestReturn.totalPages);
      setPageNumber(requestReturn.currentPage);
    }

    const contentTemp =
      content.length === 0
        ? requestReturn.data
        : content.map((item) => item).concat(requestReturn.data);
    contentTemp && setContent(contentTemp);
  }, [requestReturn]);

  useEffect(() => {
    if (content.length !== 0) {
      dispatch(HasLoadedAction(true));
      props.setRecommendedHasContent(true);
    }
  }, [content.length]);

  const handleClick = async (id) => {
    setContent(content.filter((c) => c.id !== id));
    dispatch(UpdateRecommandationsCountAction(recommandationsCount - 1));
    await deleteRecommendedReceipientId(
      requests.RemoveRecommendation,
      [id],
    ).then(res => {
      console.log(res);
    });
  };

  return (
    <div className="recommended__container">
      {content.length !== 0 && (
      <div className="recommended_row-title">
        <div className="recommended--title-wrapper">
          <h1 className="recommended--title">{translation.Recommendation_Peers}</h1>
        </div>
      </div>
      )}
      <div className="recommended_row">
        {content ? (
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            centeredSlidesBounds={true}
            slidesPerView={4}
            height={`${window.innerHeight * 0.6}px`}
            pagination={{
              clickable: true,
              el: ".slider-pagination",
              type: "bullets",
            }}
            className={"mySwiper"}
            breakpoints={breakpoints}
            navigation
            onBreakpoint={breakpoint => {
              const slidePer = breakpoint.originalParams.breakpoints;
              nbSlidesRef.current = slidePer[breakpoint.currentBreakpoint]?.slidesPerView;
            }}
            onNavigationPrev={swiper => {
              swiper.allowSlideNext = true;
            }}
            onSlideChange={swiper => {
              activeIndexRef.current++;
              if (activeIndexRef.current === -1) {
                activeIndexRef.current = 0;
                swiper.activeIndex = 0;
                swiper.slideTo(0);
              }
              if (
                (
                  swiper.activeIndex <= pageParams.limit - nbSlidesRef.current ? swiper.activeIndex :
                    swiper.activeIndex - activeIndexRef.current * (pageParams.limit - nbSlidesRef.current)) === pageParams.limit - nbSlidesRef.current
              ) {
                activeIndexRef.current++;
                triggerPagination();
              }
            }}
          >
            {content.length !== 0 && content?.map((item, index) => (
              <SwiperSlide key={index}>
                <RecommendedVideoCard item={item} handleClick={handleClick} />
              </SwiperSlide>
            ))}
          </Swiper>) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
}
