import * as types from "../constant/index";

export default function reducer(
  state = {
    videoCurrentTime: {toggle: false, time: 0},
  },
  action
) {
  switch (action.type) {
    case types.GET_VIDEO_CURRENTTIME:
      return {
        ...state,
        videoCurrentTime: {toggle: !state.videoCurrentTime.toggle, time: action.payload.time}
      };

    default:
      return state;
  }
}