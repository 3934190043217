import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import azure from "../../components/UploadApp/build/azure-storage.blob";
import { GetNewContentAction } from "../../redux/action/GlobalActions";
import "./HeroContent.scss";
export default function HeroContent(props) {

    const { newContent } = useSelector(
        (state) => state.globalReducer
    );

    const dispatch = useDispatch();
    const [elementPictureNAME, setElementPictureNAME] = useState(null);
    const [elementPicture, setElementPicture] = useState(null);
    const [picture, setPicture] = useState(null);
    const [currentPicture, setCurrentPicture] = useState(newContent.heroBanner);
    const [errors, setErrors] = useState({});
    const [canUpload, setCanUpload] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(newContent.isHighlighted ?? false);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(GetNewContentAction(
            {
                ...newContent,
                isHighlighted: isHighlighted
            }
        ));
    }, [isHighlighted]);
    
    const handleChangeThumbnailFile = (event) => {
        setElementPictureNAME(event.target.value.split("\\").pop());
        setElementPicture(event.target.files[0]);

        const file = event.target.files[0];
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = function () {
            if (img.width < 1280 || img.height < 720) {
                setCanUpload(false);
                setElementPicture(null);
                setElementPictureNAME(null);
                setPicture(null);
                dispatch(GetNewContentAction(
                    {
                        ...newContent,
                        banner: null
                    }
                ));
                alert("The image is smaller than the 1280x720px limit and will not be uploaded.");

            }
            else if ((img.width >= 1280 || img.height >= 720) && (Math.round(img.width / img.height * 100) / 100 === 1.78)) {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                const width = 1280;
                const height = 720;
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
                const resizedImageData = canvas.toDataURL();
                setPicture(resizedImageData);

                const binaryString = window.atob(resizedImageData.split(",")[1]);
                const binaryLen = binaryString.length;
                const bytes = new Uint8Array(binaryLen);
                for (let i = 0; i < binaryLen; i++) {
                    const ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }
                // create a file
                const file = new File([bytes], "resizedImage.jpeg", {
                    type: "image/jpeg",
                });
                setElementPicture(file);
                setCanUpload(true);
            }
            else {
                setCanUpload(false);
                setElementPicture(null);
                setElementPictureNAME(null);
                setPicture(null);
                dispatch(GetNewContentAction(
                    {
                        ...newContent,
                        banner: null
                    }
                ));
                alert("The image aspect ratio is not 16:9 and will not be uploaded.")
            }
        };
    };

    useEffect(() => {
        dispatch(GetNewContentAction(
            {
                ...newContent,
                banner: elementPicture
            }
        ));
    }, [elementPicture]);

    return (
        <Grid container alignItems="center">
            {(newContent.contentProcessStatusId === 13 || newContent.contentProcessStatusId === "upload") && (
                <>
                    {!newContent.isTopCreamContent && (
                        <Grid item xs={12} sx={{ marginTop: "20px", display: "flex" }} alignItems="center">
                            <Typography
                                sx={{ fontSize: 18, marginLeft: 5 }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Show on Homepage as
                                <br />
                                'Today's talk' (Hero Image) ?
                            </Typography>


                            <Grid item xs={8} sx={{ marginLeft: "20px", display: "flex" }} alignItems="center">
                                <Checkbox checked={isHighlighted} onClick={() => setIsHighlighted(!isHighlighted)} />
                            </Grid>

                        </Grid>
                    )}


                    <Grid item xs={12} sx={{ marginTop: "10px", display: "flex" }} alignItems="center">
                        <div className="hero_homepage-container">
                            <Typography
                                sx={{ fontSize: 18, marginLeft: 5, width: "100%" }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Homepage Banner:
                            </Typography>
                            {/* image upload */}
                            <div></div>
                        </div>

                        <div className="container_banner">
                            <div className="modal_picture-text">
                                file (1280x720px JPG) : {elementPictureNAME}
                                {(picture ?? currentPicture) && <img src={(picture ?? (currentPicture + "?" + new Date().getTime()))} alt="pic" />}
                            </div>
                            {!newContent.isTopCreamContent && (
                                <>
                                    <div style={{ display: "flex", flexDirection: "row" }}>

                                        <label htmlFor="banner-upload" className="upload_file--modify">
                                            {currentPicture ? "change banner" : "upload banner"}
                                        </label>
                                        <button className="upload_file--modify" onClick={() => {
                                            window.open(`${window.location.origin}/banner-tool/index.html`, '_blank');
                                        }}>
                                            create hero banner
                                        </button>
                                    </div>
                                    <input
                                        className="video__upload--modify"
                                        id="banner-upload"
                                        type="file"
                                        accept="image/jpeg, image/jpg"
                                        style={{ display: "none" }}
                                        onChange={handleChangeThumbnailFile}
                                    />
                                </>
                            )}


                        </div>
                    </Grid>
                </>
            )}
        </Grid>
    );
}