import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import requests from "../axios/Requests";
import { postWatchHistory, patchWatchHistory, getWatchHistoryByContentId } from "../../servicesRedux/ConferenceService";

const DailymotionPlayer = React.memo(({ videoId, videoProps, playerId, params }) => {
  const [player, setPlayer] = useState(null);
  const [hasStarted, setHasStarted] = useState(false);
  const [playerError, setPlayerError] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const watchHistoryIdRef = useRef(null);
  const watchCursorTimeRef = useRef(null);
  const triggerAmountRef = useRef(0);
  const hasLoadedRef = useRef(false);
  const mountedRef = useRef(true);
  const playerContainerRef = useRef(null);
  const autoplayTimeoutRef = useRef(null);
  const { videoCurrentTime } = useSelector(state => state.videoCurrentTimeReducer);

  const setWatchHistoryRef = data => {
    watchHistoryIdRef.current = data;
  };

  const toTime = second => {
    let hours = Math.floor(second / 3600);
    let minutes = Math.floor((second - (hours * 3600)) / 60);
    let seconds = second - (hours * 3600) - (minutes * 60);
    seconds = Number(seconds.toPrecision(4));
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    if (hours < 0 || minutes < 0 || seconds < 0 || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return "00:00:00";
    }
    return hours + ':' + minutes + ':' + seconds;
  };

  const toSeconds = time => {
    if (typeof time === 'number') return time;
    if (time.length < 8) {
      return parseFloat(time);
    }
    const parts = time.split(':');
    return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseFloat(parts[2]);
  };

  useEffect(() => {
    // Detect iOS
    const iosDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(iosDevice);

    mountedRef.current = true;

    function extractDailymotionId(url) {
      try {
        const parsedUrl = new URL(url);
        const parts = parsedUrl.pathname.split('/');
        const videoId = parts.pop() || parts.pop();
        if (videoId && videoId.match(/^[a-zA-Z0-9]+$/)) {
          return videoId;
        } else {
          throw new Error("Invalid Dailymotion URL");
        }
      } catch (error) {
        console.error(error.message);
        return null;
      }
    }

    const loadPlayer = async () => {
      if (window.dailymotion && mountedRef.current) {
        let startTime = 0;
        if (videoProps.props.contentId) {
          try {
            const res = await getWatchHistoryByContentId(requests.GetWatchHistoryByContent, videoProps.props.contentId);
            if (mountedRef.current) {
              startTime = toSeconds(res.lastCursorTime);
              watchCursorTimeRef.current = res.lastCursorTime;
              setWatchHistoryRef(res.id);
            }
          } catch (error) {
            console.error("Error fetching watch history:", error);
          }
        }

        if (!mountedRef.current) return;
        const shouldAutoplay = !window.location.href.includes("curationModifyApp");

        try {
          const newPlayer = await window.dailymotion.createPlayer(playerId, {
            video: extractDailymotionId(videoId),
            params: {
              ...params,
              startTime: startTime,
              autoplay: shouldAutoplay,
              mute: shouldAutoplay // Only mute if autoplay is enabled
            },
          });

          if (newPlayer && mountedRef.current) {
            const eventHandlers = {
              [window?.dailymotion?.events?.PLAYER_START]: () => {
                setHasStarted(true);
                if (autoplayTimeoutRef.current) {
                  clearTimeout(autoplayTimeoutRef.current);
                }
                
                if (shouldAutoplay && !isIOS) {
                  setTimeout(() => {
                    newPlayer.setMute(false);
                  }, 1000); // 1-second delay for non-iOS devices
                }

                if (!watchHistoryIdRef.current) {
                  postWatchHistory(requests.PostWatchHistory, videoProps.props.contentId, watchCursorTimeRef.current ?? "00:00:01")
                    .then(res => mountedRef.current && setWatchHistoryRef(res.id));
                }
              },
              [window?.dailymotion?.events?.PLAYER_ERROR]: (error) => {
                console.error("Player error:", error);
                setPlayerError(error);
              },
              timeupdate: () => {
                triggerAmountRef.current += 1;

                if (triggerAmountRef.current === 40) {
                  triggerAmountRef.current = 0;
                  newPlayer.getState().then(state => {
                    if (mountedRef.current) {
                      const currentTime = state.videoTime;
                      patchWatchHistory(requests.PatchWatchHistory, watchHistoryIdRef.current, toTime(currentTime - (watchCursorTimeRef.current ? toSeconds(watchCursorTimeRef.current) : 0)), toTime(currentTime));
                      watchCursorTimeRef.current = toTime(currentTime);
                    }
                  });
                }
              },
              video_end: () => {
                newPlayer.getState().then(state => {
                  if (mountedRef.current) {
                    const currentTime = state.videoTime;
                    patchWatchHistory(requests.PatchWatchHistory, watchHistoryIdRef.current, toTime(currentTime - (watchCursorTimeRef.current ? toSeconds(watchCursorTimeRef.current) : 0)), toTime(currentTime));
                  }
                });
              }
            };

            // Attach event listeners
            Object.entries(eventHandlers).forEach(([event, handler]) => {
              newPlayer.on(event, handler);
            });

            setPlayer(newPlayer);
          }
        } catch (error) {
          console.error("Error creating Dailymotion player:", error);
          setPlayerError(error);
        }
      }
    };

    if (!window.dailymotion) {
      const script = document.createElement('script');
      script.src = `https://geo.dailymotion.com/libs/player/${playerId}.js`;
      script.async = true;
      script.onload = loadPlayer;
      document.body.appendChild(script);

      return () => {
        mountedRef.current = false;
        if (autoplayTimeoutRef.current) {
          clearTimeout(autoplayTimeoutRef.current);
        }
        cleanupPlayer();
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    } else {
      loadPlayer();
      return () => {
        mountedRef.current = false;
        if (autoplayTimeoutRef.current) {
          clearTimeout(autoplayTimeoutRef.current);
        }
        cleanupPlayer();
      };
    }
  }, [videoId, playerId, params, videoProps.props.contentId, isIOS]);

  const cleanupPlayer = () => {
    if (player) {
      try {
        // Remove all event listeners
        const events = [
          ...(Object.keys(window?.dailymotion?.events || {})),
          'timeupdate',
          'video_end'
        ];
        events.forEach(event => {
          try {
            player.off(event);
          } catch (e) {
            console.error(`Error removing event listener ${event}:`, e);
          }
        });

        // Destroy the player
        if (typeof player.destroy === 'function') {
          player.destroy();
        }
      } catch (error) {
        console.error("Error during player cleanup:", error);
      }
    }

    // Manually remove the player iframe if it still exists
    if (playerContainerRef.current) {
      const iframe = playerContainerRef.current.querySelector('iframe');
      if (iframe) {
        iframe.remove();
      }
    }
  };
  useEffect(() => {
    const handleSeek = async () => {
      if (player && videoCurrentTime && player.seek && player.play) {
        try {
          await player.seek(videoCurrentTime.time);
          await player.play();
        } catch (error) {
          console.error("Error during seek or play:", error);
        }
      }
    };

    handleSeek();
  }, [videoCurrentTime, player]);

  if (playerError) {
    return <div>Error loading video: {playerError.message || JSON.stringify(playerError)}</div>;
  }

  return (
    <div 
      id={playerId} 
      ref={playerContainerRef}
    />
  );
});

export default DailymotionPlayer;