// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * NestedListRadio component *                                   *
 * ?          Nested is a way found to create a multiple input choice usable to change the research filter      *
 */
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { ClickAwayListener } from "@material-ui/core";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import "./NestedLists.scss";

const styles = () => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: "#CCC",
    color: "black",
  },
  nested: {
    zIndex: "3",
  },
});

const NestedListRadio = props => {
  const { translation } = useSelector(state => state.languageReducer);
  const { classes, GetformData, checkInfo, comboID, data, nestedListName, checkState } = props;
  const items = data;
  const [open, setOpen] = useState(false);
  const initialFormData = Object.freeze([]);
  const [filterList, setFilterList] = useState(initialFormData);  
  const [mapState] = useState(false);

  useEffect(() => {
    GetformData(filterList, comboID);
  }, [filterList]);

  /* **************************************** Display/Hide ComboBox items ************************************************ */
  const showCheckBoxList = () => setOpen(!open);

  const onItemClick = id => checkExistance(id);

  /* ************************************* Check value Existance into List ********************************************** */
  const checkExistance = id => {
    const temp = filterList.find(fl => fl === id);
    if (temp === undefined) {
      setFilterList([
        ...filterList,
        id,
      ]);
    } else {
      setFilterList(filterList.filter(fl => fl !== id));
    }
    checkInfo(!checkState);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <List className="NestedListFilter-root-Speaker NestedListFilter-root-Speaker-extended">
          <ListItem button onClick={() => showCheckBoxList()}>
            <ListItemText primary={props.nestedListName} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </List>
        <List className="NestedListFilter-root-Speaker NestedListFilter-root-Speaker-extended">
          <div>
            <div style={{ borderRadius: 8 }}>
              <Collapse
                className="nested_collapseList"
                component="li"
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{ position: 'absolute' }}
              >
                <List disablePadding>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="English"
                      name="radio-buttons-group"
                    >
                      {(mapState === false || filterList?.length === 0 ? items : filterList)?.map((sitem, index) => (
                        <div
                          key={index++}                                                 
                          className="nested_collapseList_item"
                        >
                          <ListItem
                            button
                            key={sitem.id}
                            className={classes.nested}
                          >                                  
                            <FormControlLabel 
                              className="nested_collapseList_item_radio" 
                              value={sitem.id} 
                              control={<Radio onClick={() => onItemClick(nestedListName === translation.Published_Date ? sitem.id : sitem.name)} />} 
                              label={sitem.label} 
                            />
                          </ListItem>
                        </div>)
                    )}                        
                    </RadioGroup>
                  </FormControl>                                                 
                </List>
              </Collapse>{" "}
            </div>
          </div>
        </List>
      </div>
    </ClickAwayListener>
  );
};

NestedListRadio.propTypes = {
  classes: PropTypes.object.isRequired,
  GetformData: PropTypes.func,
  comboID: PropTypes.object,
  data: PropTypes.object,
};

export default withStyles(styles)(NestedListRadio);
