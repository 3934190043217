import * as types from "../constant/index";

export default function reducer(
  state = {
    speakers: [],
    totalItems: 0,
    isFromConference: false,
    isFromMySpeakers: false
  },
  action
) {
  switch (action.type) {
    case types.GET_ALL_SPEAKERS:
      return {
        ...state,
        speakers: action.payload,
      };
    case types.GET_SPEAKER_TOTAL_ITEMS:
      return {
        ...state,
        totalItems: action.payload,
      };
    case types.GET_SPEAKER_FROM_CONFERENCE:
      return {
        ...state,
        isFromConference: action.payload,
      };
    case types.GET_SPEAKER_FROM_MY_SPEAKERS:
      return {
        ...state,
        isFromMySpeakers: action.payload,
      };      
    default:
      return state;
  }
}
