import axios from 'axios';

export function getSettings(request) {
  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}