import * as types from "../constant/index";


export default function reducer(
    state = {
        downloaded: {},
        loadNext: false,
        hasLoaded: false,
        conferenceToDownload: {}
    },
    action
) {

    switch (action.type) {
        case types.GET_DOWNLOADED:
            return {
                ...state,
                downloaded: action.payload,
            };

        case types.LOAD_NEXT:
            return {
                ...state,
                loadNext: action.payload,
            };

        case types.HAS_LOADED:
            return {
                ...state,
                hasLoaded: action.payload,
            };
        case types.CONFERENCE_TO_DOWNLOAD:
            return {
                ...state,
                conferenceToDownload: action.payload,
            }; 
        default:
            return state;
    }
}