import { post } from "axios";
import Cookies from "js-cookie";

import requests from "../../../../components/axios/Requests";

export async function signIn(credentials, rememberId, history) {
  const data = { email: credentials.Email, Password: credentials.Password, RememberMe: credentials.remember };
  const promise = new Promise((resolve, reject) => {
    return post(requests.PostLogin, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 201) {
          if (response.data !== undefined && response.data !== null) {
            let expiry  = new Date(response.data.expire_in);
            Cookies.set("Expire_in", response.data.expire_in, {expires : expiry});
            Cookies.set("userID", response.data.user.id, {expires : expiry});
            Cookies.set("role", JSON.stringify(response.data.user.role), {expires : expiry});
            if (rememberId) {
              Cookies.set("email", credentials.Email, {expires : expiry});
              Cookies.set("saveId", "true", {expires : expiry});
            } else {
              Cookies.set("saveId", "false", {expires : expiry});
              Cookies.remove("email");
              Cookies.remove("password");
            }
            resolve(response.data);
          }
        }
        reject(response.data);
      })
      .catch(error => {
        if (error.response.status == 403 && error.response.data.message === "registration is not confirmed") {
          history("/register/confirm", { state: { email: credentials.Email } });
        } else if (error.response.status === 403) {
          reject(error);
        } else if (error.response.status === 404) {
            reject(error);
          }                
      });
  });
  const autorize = await promise;
  return autorize;
}

export function signOut() {
  localStorage.removeItem("interv");
  return;
}

export async function resetPassword(credentials) {
  const data = { email: credentials.Email, Password: credentials.Password };
  const promise = new Promise((resolve, reject) => {
    post(requests.ResetPassword, data, { withCredentials: true })
      .then(response => {
        if (response.status === 201 || response.status === 204) {
          if (response.data !== undefined && response.data !== null) {
            resolve(response.data);
          }
        }
        reject(response.data);
      })
      .catch((error) => {
        ErroHandeler(reject, error);
      });
  });
  const autorize = await promise;
  return autorize;
}

export async function changePassword(credentials) {
  const data = { token: credentials.token, newPassword: credentials.newPassword };
  const promise = new Promise((resolve, reject) => {
    post(requests.ChangePassword, data)
      .then(response => {
        if (response.status === 201 || response.status === 204) {
          if (response.data !== undefined && response.data !== null) {
            resolve(response.data);
          }
        }
        reject(response.data);
      })
      .catch(error => {
        if (error?.response?.data?.message == "Token expired") {
          return reject(new Error('401'));
        } else if (error.response?.data?.title == "Not Found") {
          return reject(new Error(error.response?.data?.title));
        } else if ((error.response.status == 400 || error.response.status == 404) && (error.response?.data?.title != "Not Found")) {
          return reject(new Error(error.response.status));
        } else  {
          return error.response.status;
        }
      });
  });  
  const autorize = await promise;
  return autorize;
}

function ErroHandeler(reject, error, detail = false) {
  if (detail) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      reject(new Error(error.response.data.error_description));
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
    }
  } else {
    if (error.response) {
      reject(new Error(error.response.data.error_description));
    }
  }
}
