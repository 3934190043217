import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { GetNewContentAction } from "../../redux/action/GlobalActions";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ModalForm from "../modalForm/ModalForm";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect } from "react";
import RatingStars from "../rating/RatingStars";
import Constants from "../../components/constants/Constants";
import Cookies from "js-cookie";
import ComplexityCheckboxes from "../CurationComponents/ComplexityCheckboxes";


export default function CurationCreation(props) {
  const { reloadForm } = props;
  const dispatch = useDispatch();
  const statusRef = React.createRef();
  const [showUpdateSpeaker, setShowUpdateSpeaker] = React.useState(false);
  const [selectedSpeaker, SetSelectedSpeaker] = React.useState("");
  const [isPublic, setIsPublic] = React.useState(false);
  const [valueRating, setValueRating] = React.useState(0);
  const { newContent, allContributors } = useSelector(
    (state) => state.globalReducer
  );
  const [openModal, setoOpenModal] = React.useState({
    createContributor: false,
    updateContributor: false,
  });

  let startPublicationDefault = new Date();
  startPublicationDefault.setHours(0);
  startPublicationDefault.setMinutes(0);
  startPublicationDefault.setSeconds(0);

  let endPublicationDefault = new Date();
  endPublicationDefault.setHours(1);
  endPublicationDefault.setMinutes(0);
  endPublicationDefault.setSeconds(0);

  let futurDefaultDate = new Date();
  futurDefaultDate.setFullYear(2030);
  futurDefaultDate.setHours(1);
  futurDefaultDate.setMinutes(0);
  futurDefaultDate.setSeconds(0);


  dayjs.extend(utc);

  var roles = Cookies.get("role");
  var isCuratorManager = roles.indexOf("Curator-Manager") > 0;

  const StatusListCuratorManager = [
    {
      label: Constants.ContentAdminStatus.IN_PROGRESS.labelStatus,
      value: Constants.ContentAdminStatus.IN_PROGRESS.enum,
    },
    {
      label: Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.labelStatus,
      value: Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.enum,
    },
    {
      label: Constants.ContentAdminStatus.APPROVED.labelStatus,
      value: Constants.ContentAdminStatus.APPROVED.enum,
    },
    {
      label: Constants.ContentAdminStatus.REJECTED.labelStatus,
      value: Constants.ContentAdminStatus.REJECTED.enum,
    },
  ];
  const StatusListCurator = [
    {
      label: Constants.ContentAdminStatus.IN_PROGRESS.labelStatus,
      value: Constants.ContentAdminStatus.IN_PROGRESS.enum,
    },
    {
      label: Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.labelStatus,
      value: Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.enum,
    },
  ];

  const [contentStatus, setContentStatus] =

    React.useState(isCuratorManager
      ?
      StatusListCuratorManager.find(
        (sl) => sl.value === (newContent?.contentAdminStatusId === 5 ? 3 : newContent?.contentAdminStatusId ?? '')
      )
      :
      StatusListCurator.find(
        (sl) => sl.value === (newContent?.contentAdminStatusId === 5 ? 3 : newContent?.contentAdminStatusId ?? '')
      ));

  useEffect(() => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        isPublic: isPublic,
      })
    );
  }, [isPublic]);

  const handleChangeCreator = (event) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        creatorName: event.target.value,
      })
    );
  };
  const handleChangeAuthorizedContent = (event) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        authorizedContent: event.target.value === "true" ? true : false,
      })
    );
  };
  const handleSelectStatus = (event) => {

    setContentStatus({ label: event.label, value: event.value });


    event.value === 3 ? (
      dispatch(
        GetNewContentAction({
          ...newContent,
          status: parseInt(Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum),
        })
      )
    ) : (
      dispatch(
        GetNewContentAction({
          ...newContent,
          status: event.value,
        })
      )
    )

  };
  const handleChangeStartCuration = (event) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        startCuration: event,
      })
    );
  };
  const handleChangeEndCuration = (event) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        endCuration: event,
      })
    );
  };

  const handleChangeStartPublication = (event) => {
    //date offset utc
    // console.log('event',dayjs(event).utc(true).hour(12).minute(60).second(60).format())
    // const date = dayjs(event).utc(true).hour(12).minute(60).second(60).format();
    // console.log('date',date)
    dispatch(
      GetNewContentAction({
        ...newContent,
        publicationDateStart: event,
      })
    );
  };
  const handleChangeEndPublication = (event) => {
    // const date = dayjs(event).utc(true).hour(0).minute(0).second(0).format();
    dispatch(
      GetNewContentAction({
        ...newContent,
        publicationDateEnd: event,
      })
    );
  };
  const handleChangeSelectedCreatedOn = (event) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        createdOn: event,
      })
    );
  };
  const handleChangeSummary = (event) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        fullSummary: event.target.value,
      })
    );
  };

  const handleChangeComment = (event) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        comment: event.target.value,
      })
    );
  };

  const handleSelectSpeaker = (event) => {
    setShowUpdateSpeaker(true);
    SetSelectedSpeaker(event.target.value);
  };

  const handleSubmitSpeakerModal = (contributor) => { };

  const handleDeleteContributor = (event, id) => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        contributor: newContent.contributor.filter((c) => c.id !== id),
      })
    );
  };

  useEffect(() => {
    statusRef.current?.setValue([]);
  }, [reloadForm]);

  useEffect(() => {
    if (!newContent.publicationDateEnd)
      dispatch(
        GetNewContentAction({
          ...newContent,
          publicationDateEnd: futurDefaultDate,
        })
      );
  }, [newContent.publicationDateStart]);



  return (
    <>
      {newContent && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: "100%",
              height: "100%",
            },
          }}
        >
          <Paper elevation={5} sx={{ padding: 2 }} className="parent_paper">
            <Typography
              sx={{ fontSize: 16, fontWeight: 800 }}
              color="text.secondary"
              gutterBottom
            >
              Curation
            </Typography>

            <Grid container>
              <ModalForm
                open={openModal.createContributor}
                onClose={() =>
                  setoOpenModal((prev) => ({
                    ...prev,
                    createContributor: false,
                  }))
                }
                contentType={"createContributor"}
                title={"Create Contributor"}
                handleSubmitModal={(
                  category,
                  subCategory,
                  subSubCategory,
                  contributor
                ) => handleSubmitSpeakerModal(contributor)}
              />

              <ModalForm
                open={openModal.updateContributor}
                onClose={() =>
                  setoOpenModal((prev) => ({
                    ...prev,
                    updateContributor: false,
                  }))
                }
                data={allContributors.find((c) => c.id === selectedSpeaker)}
                contentType={"updateContributor"}
                title={"Update Contributor"}
                handleSubmitModal={(
                  category,
                  subCategory,
                  subSubCategory,
                  contributor
                ) => handleSubmitSpeakerModal(contributor)}
              />
            </Grid>

            {/* <Grid container>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Authorized Content :
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="authorize-controlled-radio-buttons-group"
                    value={newContent?.authorizedContent}
                    onChange={handleChangeAuthorizedContent}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="true"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="false"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> 
            </Grid> */}

            <Grid container alignItems="center">
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Rate the content:
                </Typography>
              </Grid>

              <Grid item xs={8} alignItems="center">
                <div style={{ width: "95%", zIndex: 999 }}>
                  <RatingStars />
                </div>
              </Grid>
            </Grid>

            <ComplexityCheckboxes />

            <Grid container>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Start Curation :
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    disabled
                    label="Start curation"
                    value={newContent?.startCuration || startPublicationDefault}
                    onChange={handleChangeStartCuration}
                    maxDateTime={newContent?.endCuration}
                    ampm={false}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "95%", zIndex: "0" }}
                        {...params}
                      />
                    )}
                    required
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  End Curation :
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    disabled
                    label="End curation"
                    required
                    ampm={false}
                    value={newContent?.endCuration || endPublicationDefault}
                    onChange={handleChangeEndCuration}
                    minDateTime={
                      newContent?.startCuration || endPublicationDefault
                    }
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "95%", zIndex: "0" }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Start of Publication :
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Start of Publication"
                    required
                    ampm={false}
                    value={
                      newContent?.publicationDateStart !== undefined
                        ? newContent?.publicationDateStart
                        : ""
                    }
                    onChange={handleChangeStartPublication}
                    maxDateTime={newContent?.publicationDateEnd}
                    views={["year", "day", "hours"]}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "95%", zIndex: "0" }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  End of Publication :
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    required
                    label="End of publication"
                    views={["year", "day", "hours"]}
                    value={
                      newContent?.publicationDateEnd !== undefined
                        ? newContent?.publicationDateEnd
                        : ""
                    }
                    minDateTime={
                      newContent?.publicationDateStart || endPublicationDefault
                    }
                    ampm={false}
                    onChange={handleChangeEndPublication}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "95%", zIndex: "0" }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Original Publication Date :
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Publication Date"
                    value={newContent?.createdOn}
                    onChange={handleChangeSelectedCreatedOn}
                    ampm={false}
                    renderInput={(params) => (
                      <TextField
                        style={{ width: "95%", zIndex: "0" }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Is public content?
                </Typography>
              </Grid>
              <Grid item xs={8} sx={{ display: "flex" }} alignItems="center">
                <Checkbox checked={isPublic} onClick={() => setIsPublic(!isPublic)} />
              </Grid>

            </Grid>
            <Grid container>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Description :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  // required
                  id="outlined-basic"
                  label={!newContent.fullSummary ? "Description" : ""}
                  variant="outlined"
                  sx={{ width: "95%" }}
                  size="small"
                  multiline={true}
                  minRows={3}
                  defaultValue={newContent?.fullSummary}
                  o
                  onChange={handleChangeSummary}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Comment :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  // required
                  id="outlined-basic"
                  label="Comment"
                  placeholder="As a curator you can enter comments on this content here. This will not be shown to the end user."
                  variant="outlined"
                  sx={{ width: "95%" }}
                  size="small"
                  multiline={true}
                  minRows={3}
                  defaultValue={newContent?.comment}
                  onChange={handleChangeComment}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Status :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <div style={{ width: "95%" }}>
                  {contentStatus?.value !== 3 ? (
                    <Select
                      onChange={handleSelectStatus}
                      value={contentStatus}
                      options={isCuratorManager ? StatusListCuratorManager : StatusListCurator}
                      placeholder={"Select a status..."}

                    />
                  ) : (
                    <div className="approve_checkbox">
                      <div className="approve_select">
                        <Select
                          sx={{ pl: "50px" }}
                          onChange={handleSelectStatus}
                          value={contentStatus}
                          options={isCuratorManager ? StatusListCuratorManager : StatusListCurator}
                          placeholder={"Select a status..."}

                        />
                      </div>
                      <div className={'checkbox'}>
                        <ControlledCheckbox
                          status={StatusListCuratorManager.find(
                            (sl) => sl.value === 5
                          )}
                          handleSelectStatus={handleSelectStatus}
                        />
                        <p>Distribute content to white label customers</p>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </>
  );
}

const ControlledCheckbox = (handleSelectStatus) => {
  const [checked, setChecked] = React.useState(true);
  const status = {
    label: Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.labelStatus,
    value: Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum,
  }

  const statusApprove = {
    label: Constants.ContentAdminStatus.APPROVED.labelStatus,
    value: Constants.ContentAdminStatus.APPROVED.enum,
  }

  const handleChange = (chk) => {
    if (!chk) {
      handleSelectStatus.handleSelectStatus(status)
    }
    else {
      handleSelectStatus.handleSelectStatus(statusApprove)
    }
    setChecked(!checked);
  };

  // useEffect(() => {

  //   setEffect(true)
  // }, [checked])

  return (
    <Checkbox
      checked={checked}
      disabled
      onClick={() => handleChange(checked)}
      inputProps={{ 'aria-label': 'controlled' }}
      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
    />
  );
}