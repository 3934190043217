import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { GetNewContentAction } from "../../redux/action/GlobalActions";
import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

export default function CurationGraphical(props) {
  const { tab } = props;
  const dispatch = useDispatch();
  const { newContent } = useSelector((state) => state.globalReducer);
  const [handelThumbnail, setHandelThumbnail] = React.useState("true");
  const [thumnbailGenerated, setThumnbailGenerated] = React.useState("");
  const [extension, setExtension] = React.useState(tab);
  var extensions = [
    {
      id: 0,
      value: "video",
      label: "video",
      extension: "video/mp4,video/x-m4v,video/*",
    },
    { id: 1, value: "pdf", label: "pdf", extension: "application/pdf" },
    { id: 2, value: "podcast", label: "podcast", extension: ".mp3,audio/*" },
  ];
  const getVideoDuration = file =>
    new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file);
      const media = new Audio(url);

      const cleanup = () => {
        media.removeEventListener('loadedmetadata', onLoadedMetadata);
        media.removeEventListener('error', onError);
        URL.revokeObjectURL(url);
      };
      const onLoadedMetadata = () => {
        cleanup();
        resolve(media.duration);
      };

      const onError = error => {
        cleanup();
        reject(error);
      };

      media.addEventListener('loadedmetadata', onLoadedMetadata);
      media.addEventListener('error', onError);
    });

  const handleChangeMp4File = async (event, tab) => {

    var allowedExtensionsVideo = /(\mp4|\mov|\avi|\flv)$/i;
    var allowedExtensionsPodCast = /(\mp3|\audio|\mpeg)$/i;
    var allowedExtensionsPDF = /(\pdf)$/i;

    var file = event.target.files[0];

    switch (tab) {
      case "Video":
        if (!allowedExtensionsVideo.exec(file.type)) {
          return alert("Invalid file type");
        }

        validateFile(event.target.files[0]);
        dispatch(
          GetNewContentAction({
            ...newContent,
            videoDuration: await getVideoDuration(event.target.files[0]) ?? 0,
            videoName: event.target.value.split("\\").pop(),
            videoFile: event.target.files[0],
          })
        );
        break;
      case "PDF":
        if (!allowedExtensionsPDF.exec(file.type)) {
          return alert("Invalid file type");
        }
        validateFile(event.target.files[0]);


        dispatch(
          GetNewContentAction({
            ...newContent,
            pdfName: event.target.value.split("\\").pop(),
            pdfFile: event.target.files[0],
          })
        );

        break;
      case "Podcast":
        if (!allowedExtensionsPodCast.exec(file.type)) {
          return alert("Invalid file type");
        }
        validateFile(event.target.files[0]);

        await getVideoDuration(event.target.files[0])
        
        dispatch(
          GetNewContentAction({
            ...newContent,
            duration: await getVideoDuration(event.target.files[0]) ?? 0,
            podCastName: event.target.value.split("\\").pop(),
            podCastFile: event.target.files[0],
          })
        );

        break;

      default:
        return;
    }
  };
  async function validateFile(file) {
    var video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      if (video.duration < 1) {
        alert("Please choose a valid video");
        return;
      }

      var req = secondsToHms(video.duration);
      if (req) {
        var temp = req.hDisplay + ":" + req.mDisplay + ":" + req.sDisplay;
        setThumnbailGenerated(temp);
      }
    };

    video.src = URL.createObjectURL(file);
  }

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var obj = {
      hDisplay: h > 10 ? h : "0" + h,
      mDisplay: m > 10 ? m : "0" + m,
      sDisplay: s > 10 ? s : "0" + s,
    };

    return obj;
  }

  const handleChangeThumbnail = (event) => {
    setHandelThumbnail(event.target.value);
  };
  const handleChangeThumbnailFile = (event) => {
    let file = event.target.files[0];

    // Check if file size is greater than 500KB
    if (file.size > 500 * 1024) {
      alert("File size exceeds 500KB. Please choose a smaller file.");
      event.target.value = '';  // Reset the value to allow re-selection of the same file
      dispatch(
        GetNewContentAction({
          ...newContent,
          thunbnailURL: '', // Corrected the spelling from 'thunbnailURL' to 'thumbnailURL'
          thumbnailFile: '',
        })
      );
      return;
    }

    // Check for file type to be jpg
    if (!file.type.includes('jpeg') && !file.type.includes('jpg')) {
      alert("Please select a JPG file.");
      event.target.value = '';  // Reset the input for new selection
      dispatch(
        GetNewContentAction({
          ...newContent,
          thunbnailURL: '',
          thumbnailFile: '',
        })
      );
      return;
    }

    // Check image dimensions
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        let maxWidth = 640, maxHeight = 360;
        if (newContent?.isExternalContent || newContent?.MediaType === extensions[2].id) {
          maxWidth = 1280;
          maxHeight = 720;
        }
        if (img.width !== maxWidth || img.height !== maxHeight) {
          alert(`Thumbnail dimensions should be ${maxWidth}x${maxHeight}.`);
          event.target.value = '';  // Reset the input for new selection
          dispatch(
            GetNewContentAction({
              ...newContent,
              thunbnailURL: '',
              thumbnailFile: '',
            })
          );
        } else {
          // If all checks pass, dispatch the action with file details
          let path = event.target.value.split("\\").pop();
          dispatch(
            GetNewContentAction({
              ...newContent,
              thunbnailURL: path,
              thumbnailFile: file,
            })
          );
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        thunbnailURL: '',
        thumbnailFile: '',
      })
    );
  }, [newContent.isExternalContent]);



  useEffect(() => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        duration: thumnbailGenerated,
      })
    );
  }, [thumnbailGenerated]);

  useEffect(() => {
    var ext = extensions.find(
      (e) => e.label.toLowerCase() === tab.toLowerCase()
    );
    setExtension(ext.extension);
  }, [tab]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            height: "100%",
          },
        }}
      >
        <Paper elevation={5} sx={{ padding: 2 }} className="parent_paper">
          <Typography
            sx={{ fontSize: 16, fontWeight: 800 }}
            color="text.secondary"
            gutterBottom
          >
            Graphical contents : {tab}
          </Typography>
          <Grid container>
            <Grid item xs={2} sx={{ display: "flex" }}>
              <Typography
                sx={{ fontSize: 18, marginLeft: 5 }}
                color="text.secondary"
                gutterBottom
              >
                {tab} file :
              </Typography>
            </Grid>

            <label
              htmlFor="file-upload--thumbnail"
              className="upload_file--modify"
            >
              upload {tab.toLowerCase()}
            </label>

            <input
              className="video__upload--modify"
              id="file-upload--thumbnail"
              type="file"
              accept={extension}
              style={{ display: "none" }}
              onChange={(e) => {
                handleChangeMp4File(e, tab);
              }}
            />
            <div className="video-name">
              Selected file:
              {newContent?.videoName ||
                newContent?.pdfName ||
                newContent?.podCastName}
            </div>
            {/* 
            <div className="upload_file" required>

            </div> */}
          </Grid>
          <Grid container>
            <Grid item xs={2} sx={{ display: "flex" }}>
              <Typography
                sx={{ fontSize: 18, marginLeft: 5 }}
                color="text.secondary"
                gutterBottom
              >
                Thumbnail file :
              </Typography>
            </Grid>

            <label htmlFor="thumbnail-upload" className="upload_file--modify">
              upload thumbnail
            </label>

            <input
              className="video__upload--modify"
              id="thumbnail-upload"
              type="file"
              accept=".jpg,.jpeg"
              style={{ display: "none" }}
              onChange={handleChangeThumbnailFile}
            />
            <div className="video-name">
              {newContent.isExternalContent || newContent?.MediaType === extensions[2].id ?
                "Select thumbnail file (1280x720px JPG):"
                :
                "Select thumbnail file (640x360px JPG):"
              }
              :{newContent?.thunbnailURL ?? ""}
            </div>
          </Grid>

          <Grid container>
            <Grid item xs={2} sx={{ display: "flex" }}>
              <Typography
                sx={{ fontSize: 18, marginLeft: 5 }}
                color="text.secondary"
                gutterBottom
              >
                Is external content ?
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ display: "flex" }} alignItems="center">
              <Checkbox checked={newContent?.isExternalContent || false} onClick={() => {
                dispatch(
                  GetNewContentAction({
                    ...newContent,
                    isExternalContent: !newContent?.isExternalContent,
                  })
                );
              }} />
            </Grid>
          </Grid>
          {newContent.isExternalContent && (
            <Grid container>
              <Grid item xs={2} sx={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 18, marginLeft: 5 }}
                  color="text.secondary"
                  gutterBottom
                >
                  External content link :
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="outlined-basic"
                  label="Enter external content link"
                  variant="outlined"
                  sx={{ width: "76%" }}
                  size="small"
                  onChange={(e) => {
                    dispatch(
                      GetNewContentAction({
                        ...newContent,
                        externalContentLink: e.target.value,
                      })
                    );
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Box>
    </>
  );
}
