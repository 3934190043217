import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

import ResearchBar from "../../components/research/ResearchBar";
import NestedLists from "../../components/nestedList/NestedLists";
import SpecialNestedLists from "../../components/nestedList/SpecialNestedList";
import NestedListRadio from "../../components/nestedList/NestedListRadio";
import NestedCombo from "../../components/nestedList/NestedCombo";
import NestedListsFilter from "../../components/nestedList/NestedListsFilter";
import NestedListSpeakers from "../../components/nestedList/NestedListSpeakers";
import RecommendedVideos from "../../components/videoCard/RecommendedVideos";
import Tags from "../../components/switching/Tags";
import Paginator from "../../components/pagination/Paginator";
import PublishedList from "../../components/nestedList/publishedJson/PublishedList";
import duration from "../../components/nestedList/moreCriteriaJson/Duration.json";
import language from "../../components/nestedList/moreCriteriaJson/Language.json";
import mediaTypes from "../../components/nestedList/moreCriteriaJson/MediaTypes";
import ComplexityLevel from "../../components/nestedList/moreCriteriaJson/ComplexityLevel.json"

import FilterIcon from "../../assets/images/search-filter-icon.svg";
import MedalIcon from "../../assets/images/medal-icon.svg";
import ClockIcon from "../../assets/images/clock-icon.svg";
import CalendarIcon from "../../assets/images/calendar-icon.svg";
import SpeakerIcon from "../../assets/images/speaker-icon.svg";
import GlobalSVG from "../../assets/svg-icons/global.js";
import ComplexSVG from "../../assets/svg-icons/complex.js";

//AI stuff
import AiExplore from "../aiExplore/AiExplore.jsx";
const LargeScreenSearch = ({
    isLoading,
    translation,
    speakers,
    handleformData,
    handleCheckInfo,
    setResearch,
    searchedValue,
    tags,
    content,
    location,
    userId,
    categories,
    languages,
    totalSearchItems,
    onChangeCategoryInput,
    triggerResetTheCategoriesList,
    checkedState,
    setCheckedState,
    myInfo,
    checkState,
    propogateSpeakersAPI
}) => (
    <>
        <div className="left_filter left_filter--extended">
            <div className="filters__label--wrapper">
                <img src={FilterIcon} />
                <span>{translation.Filters}</span>
            </div>
            <div className="search__filter--left--column--items search__filter--left--column--items--extended">
                <GlobalSVG />
                <div className="nested__list--wrapper">
                    {myInfo &&
                        <NestedLists
                            GetformData={handleformData}
                            checkInfo={handleCheckInfo}
                            data={language}
                            comboID={10}
                            checkState={checkState}
                            nestedListName={translation.Suggestion_Language}
                            ListType={"language"}
                            myInfo={myInfo}
                        />}
                </div>
            </div>
            <div className="search__filter--left--column--items search__filter--left--column--items--extended">
                <ComplexSVG />
                <div className="nested__list--wrapper">
                    <SpecialNestedLists
                        GetformData={handleformData}
                        checkInfo={handleCheckInfo}
                        data={ComplexityLevel}
                        comboID={11}
                        nestedListName={translation.Complexity_level}
                        ListType={"complexityLevel"}
                        myInfo={myInfo}
                        checkState={checkState}
                    />
                </div>
            </div>
            <div className="search__filter--left--column--items search__filter--left--column--items--extended">
                <img src={ClockIcon} />
                <div className="nested__list--wrapper">
                    <NestedLists
                        GetformData={handleformData}
                        checkInfo={handleCheckInfo}
                        data={duration}
                        comboID={7}
                        nestedListName={translation.Duration}
                        ListType={"duration"}
                        myInfo={myInfo}
                        checkState={checkState}
                    />
                </div>
            </div>
            <div className="search__filter--left--column--items search__filter--left--column--items--extended">
                <img src={CalendarIcon} />
                <div className="nested__list--wrapper">
                    <NestedListRadio
                        GetformData={handleformData}
                        checkInfo={handleCheckInfo}
                        data={PublishedList()}
                        comboID={4}
                        nestedListName={translation.Published_Date}
                        ListType={translation.Published_Date}
                        checkState={checkState}
                    />
                </div>
            </div>
            <div className="search__filter--left--column--items search__filter--left--column--items--extended">
                <img src={SpeakerIcon} />
                <div className="nested__list--wrapper">
                    <NestedListSpeakers
                        choosenList={speakers}
                        GetformData={handleformData}
                        comboID={3}
                        checkInfo={handleCheckInfo}
                        includedInSearch={true}
                        checkState={checkState}
                        propogateSpeakersAPI={propogateSpeakersAPI}
                    />
                </div>
            </div>
            <div className="search__filter--left--column--items search__filter--left--column--items--extended">
                <img src={MedalIcon} />
                <div className="nested__list--wrapper">
                    <NestedLists
                        GetformData={handleformData}
                        checkInfo={handleCheckInfo}
                        data={languages}
                        comboID={6}
                        nestedListName={translation.Localisation}
                        ListType={"languages"}
                        myInfo={myInfo}
                        checkState={checkState}
                    />
                </div>
            </div>
        </div>
        <div className="research">
            <div className="research__top">
                <div className="research__searchBar">
                    <ResearchBar
                        setResearch={setResearch}
                        searchedValue={searchedValue}
                        checkInfo={handleCheckInfo}
                    />
                </div>

                <div className="filter">
                    <div className="filter__inputs--left--column">
                        <div className="nested__list--wrapper">
                            <NestedListsFilter
                                choosenList={categories}
                                GetformData={handleformData}
                                comboID={2}
                                checkInfo={handleCheckInfo}
                                nestedListName={"Categories"}
                                onChangeCategoryInput={onChangeCategoryInput}
                                triggerResetTheCategoriesList={triggerResetTheCategoriesList}
                                includedInSearch={false}
                                checkState={checkState}
                            />
                        </div>
                    </div>
                    <div className="filter__inputs--right--column">
                        <NestedCombo
                            GetformData={handleformData}
                            checkInfo={handleCheckInfo}
                            data={mediaTypes}
                            checkedState={checkedState}
                            setCheckedState={setCheckedState}
                        />
                    </div>
                </div>

                <div className="display__loader">
                    {isLoading && <LinearProgress color="inherit" className="progress_bar" />}
                </div>

                {tags &&
                    <Tags
                        tags={tags}
                        comboID={"tags"}
                        GetformData={handleformData}
                        checkInfo={handleCheckInfo}
                        includedInDashboard={false}
                        checkState={checkState}
                    />}
            </div>
            <div>
                <AiExplore orientation="right">
                    Explore results with AI
                </AiExplore>
            </div>
            {content.length > 0 &&
                <div className="results__found--text">
                    <span>
                        {`${totalSearchItems} `}
                        {searchedValue
                            ? `${totalSearchItems > 1 ? translation.Numbers_Of_Results : translation.Number_Of_Results} ${translation.Search_Result_For} "${searchedValue}"`
                            : translation.Most_Recent_Contents
                        }
                    </span>
                </div>}

            {content.length > 0 &&
                <div className="search__recommended">
                    <RecommendedVideos
                        research={content}
                        location={location}
                        userId={userId}
                        searchScreen={true}
                    />
                    <Paginator />
                </div>}

            {(isLoading) ?
                content.length === 0 &&
                <div className="search__recommended search__recommended_no_results">
                    {translation.Loading}
                </div>
                :
                content.length === 0 &&
                (<div className="search__recommended search__recommended_no_results">
                    {translation.No_Content_Found}
                </div>)
            }
        </div>
    </>
);

export default LargeScreenSearch;