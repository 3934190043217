import axios from "axios";
import Constants from "../components/constants/Constants";


export function getPlaylistContent(requests, id, pageParams, mediaType) {
  const mediaTypeEmpty = "MediaType=1&MediaType=2&MediaType=3"
  const mediaTypeString = 
  mediaType ? 
  mediaType.map(type => {
    const key = Object.keys(Constants.MediaType).find(k => k.toLowerCase() === type);
    return Constants.MediaType[key] || Constants.MediaType.NotFound;
  })
  .join('&MediaType=')
  : [];
  const queryString = mediaTypeString !== [] ? (`MediaType=${mediaTypeString === '' ? 0 : mediaTypeString}`) : '';

  requests = requests
    .replace("{id}", id)
    .replace("{limit}", pageParams.limit)
    .replace("{page}", pageParams.page)
    .replace("{MediaType}", mediaType ? queryString : mediaTypeEmpty);

  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getPlaylists(requests, pageParams) {
  requests = requests
    .replace("{limit}", pageParams.limit)
    .replace("{page}", pageParams.page);
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function createPlaylist(requests, name) {
  //PlaylistTypes (1 = standard, 2 = watch later, 3, = favorites, 4 = global)
  const data = { name: name, playlistTypeId: 1 };
  return new Promise((resolve, reject) => {
    axios
      .post(requests, data, { withCredentials: true })
      .then(resolve)
      .catch(reject);
  });
};

export function updatePlaylist(requests, name, playlistTypeId) {
  const data = { name: name, playlistTypeId: playlistTypeId };
  return new Promise((resolve, reject) => {
    axios
      .post(requests, data, { withCredentials: true })
      .then(resolve)
      .catch(reject);
  });
};

export function createPlaylistStandard(requests, name, playlistTypeId) {
  //PlaylistTypes (1 = standard, 2 = watch later, 3, = favorites, 4 = global)

  const data = { name: name, playlistTypeId: playlistTypeId };
  return new Promise((resolve, reject) => {
    axios
      .post(requests, data, { withCredentials: true })
      .then(resolve)
      .catch(reject);
  });
};

export function UpdatePlaylist(requests, name, id) {
  const data = { name: name };
  requests = requests.replace("{id}", id);
  return new Promise((resolve, reject) => {
    axios
      .post(requests, data, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          resolve(error.response.status);
        } else {
          reject(error);
        }
      });
  });
};

export function deletePlaylist(requests, id) {
  requests = requests.replace("{id}", id);
  return new Promise((resolve, reject) => {
    axios
      .delete(requests, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function deleteContentFromPlaylist(requests, id, contentId) {
  requests = requests.replace("{id}", id).replace("{contentId}", contentId);

  return new Promise((resolve, reject) => {
    axios
      .delete(requests, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function patchPlaylistName(requests, id, name, type) {
  requests = requests.replace("{id}", id);
  const newName = { name: name, playlistTypeId: type ?? 1 };
  return new Promise((resolve, reject) => {
    axios
      .patch(requests, newName, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//post content into playlist
export function postContentIntoPlaylist(requests, id, contentId) {
  requests = requests.replace("{id}", id);

  return new Promise((resolve, reject) => {
    axios
      .post(requests, { contentId: contentId }, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//get if content is in playlist
export function getPlaylistsWithContent(requests, id) {
  requests = requests.replace("{id}", id);

  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getUsersForSharedPlaylist(requests, id) {
  requests = requests.replace("{id}", id);

  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function deleteUsersFromSharedPlaylist(requests, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(requests,  { 
        data: {recommendationId : id}, 
        withCredentials: true 
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function postUsersSharePlaylist(requests, recipientId, id) {
  const data = {
    recipientIds: Array.isArray(recipientId) ? recipientId : [recipientId],
    playlistId: id,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(requests, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    }
  );
};

export function getMySharedPlaylist(requests, pageParams) {
  requests = requests
    .replace("{limit}", pageParams.limit)
    .replace("{page}", pageParams.page);
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function GetContentSharedPlaylist(requests, id, pageParams, mediaType) {
  requests = requests.replace("{id}", id)
  .replace("{limit}", pageParams.limit)
  .replace("{page}", pageParams.page + mediaType);
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function deleteSharedPlaylist(requests, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(requests,  { 
        data: {playlistSharedId : id}, 
        withCredentials: true 
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};