import React from "react";

import "./svg-colors.scss";

const ComplexSVG = props => {
    return (        
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            className="global__icon_color"
            {...props}
            style={{
                marginTop: 10,
                minWidth: '25px',
                maxWidth: '25px',
            }}
        >
            <g
                stroke="#8E8E8E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                clipPath="url(#clip0_776_14815)"
            >
                <path d="M14.653 17.653L12 15l-2.653 2.653M12 21.75V15M9.347 6.347L12 9l2.653-2.653M12 2.25V9M6.347 14.653L9 12 6.347 9.347M2.25 12H9M17.653 9.347L15 12l2.653 2.653M21.75 12H15"></path>
            </g>
            <defs>
                <clipPath id="clip0_776_14815">
                    <path fill="#fff" d="M0 0H24V24H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default ComplexSVG;
