import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

import {
  isLikedStateAction,
  isSubscribedStateAction,
} from "../../redux/action/PlaylistAction";
import { useDispatch } from "react-redux";
import { dispatchedPlaylistAction } from "../../redux/action/GlobalActions";

export default function Redirector() {
  const payload = Cookies.get("Payload");
  const user = Cookies.get("userID");
  // const roles = Cookies.get("roles");
  // const accountType = Cookies.get("accountType");
  let history = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { redirectURL } = useSelector(state => state.redirectReducer);
  const { dispatchedPlayList } = useSelector(state => state.globalReducer);
  const currentLocation = window.location.href;

  useEffect(() => {
    if (dispatchedPlayList === false && dispatchedPlayList !== undefined) {
      dispatch(isLikedStateAction(""));
      dispatch(isSubscribedStateAction(""));
      dispatch(dispatchedPlaylistAction(true));
    }
  }, [currentLocation]);

  // useEffect(() => {
  //   const pathname = window.location.pathname;
  //   if (pathname === "/dashboard/users") {      
  //     if (roles && (roles.includes("Admin") || roles.includes("Manager")) && (accountType == 1 || accountType == 2)) {
  //       navigate("/dashboard/users");
  //     } else {
  //       navigate("/");
  //     }
  //   }
  //   if (pathname === "/dashboard/contents") {      
  //     if (roles && (roles.includes("Admin") || roles.includes("Manager") || roles.includes("Curator"))) {
  //       navigate("/dashboard/contents");
  //     } else {
  //       navigate("/");
  //     }
  //   }
  //   if (pathname === "/dashboard/subscriptions") {      
  //     if (roles && (roles.includes("Admin") || roles.includes("Manager"))) {
  //       navigate("/dashboard/subscriptions");
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (payload === undefined || !payload || user === undefined || !user) {
      if (history.pathname.includes("/conference/")) {
        console.log(history.pathname);
        // Do not navigate if already on a conference page
        return undefined;
      } else {
        navigate("/login");
      }
    }
  }, [history]);

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname !== redirectURL) {
      navigate(pathname);
    } else {
      if (redirectURL !== "" && redirectURL !== "/") {
        navigate(redirectURL);
      }  
    }
  }, [redirectURL]);

  return <div />;
}
