import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { setOpenPersonalizedDialog } from "../../redux/action/GlobalActions";
import { useDispatch } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PersonalizedDialog(props) {
  const { open, title, missedAttributs } = props;
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setOpenPersonalizedDialog(false));
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign: "center", color: "red" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ minWidth: 300 }}
          >
            Please fill the missing data :
            <br />
            {missedAttributs.map((item, index) => {
              return (
                <>
                  {item}
                  <br />
                </>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleClose} sx={{ color: "gray" }}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
