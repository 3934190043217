import * as types from "../constant/index";

export default function reducer(
  state = {
    search: [],
    allTags: [],
    searchedValue: "",
    tags: [],
  },
  action
) {
  switch (action.type) {
    case types.GET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case types.GET_ALLTAGS:
      return {
        ...state,
        allTags: action.payload,
      };

    case types.GET_SEARCHED_VALUE:
      return {
        ...state,
        searchedValue: action.payload,
      };
    case types.GET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };

    default:
      return state;
  }
}
