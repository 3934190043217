import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FaPhotoVideo } from "react-icons/fa";
import { CgMediaPodcast } from "react-icons/cg";
import { ImFilePdf } from "react-icons/im";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { IoMdArrowRoundBack } from "react-icons/io";

import {
  getAllCategories,
  getAllSpeakers,
  getAllChannels,
  getAllContributors,
  createContent,
  createUploadJob,
  updateUploadJob,
  getAllTags,
  getTenant
} from "./../servicesRedux/GlobalService";
import requests from "../components/axios/Requests";
// import Rating from "@mui/material/Rating";
import {
  GetAllCategoriesAction,
  GetAllSpeakersAction,
  GetAllChannelsAction,
  GetAllContributorsAction,
  GetNewContentAction,
  setProgressPercent,
  getAllTagsAction,
  setOpenPersonalizedDialog,
} from "../redux/action/GlobalActions";

import CurationContent from "./../components/UploadApp/CurationContent";
import CurationCreation from "./../components/UploadApp/CurationCreation";
import CurationGraphical from "../components/UploadApp/CurationGraphical";
import azure from "../components/UploadApp/build/azure-storage.blob";
import DialogLoader from "../components/async/DialogLoader";
import { GetTokenByIdUser } from "../redux/action/AuthentificationAction";
import { getTokenByID } from "../servicesRedux/AuthentificationService";
import PersonalizedDialog from "../components/async/PersonalizedDialog";
import EditorialContent from "../components/CurationComponents/EditorialContent";

import "../theme/ModifyApp/ModifyApp.scss";

const uploadType = {
  video: "video",
  thumbnail: "thumbnail",
  pdf: "pdf",
  podcast: "podcast",
};
let curationUploadType = [
  {
    id: 0,
    value: "video",
    label: "video",
    disabled: false,
    MediaType: 1,
    uploadString: "videos",
  },
  {
    id: 1,
    value: "pdf",
    label: "pdf",
    disabled: false,
    MediaType: 3,
    uploadString: "documents",
  },
  {
    id: 2,
    value: "podcast",
    label: "podcast",
    disabled: true,
    MediaType: 2,
    uploadString: "podcasts",
  },
];

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#b6aa90",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#b6aa90",
    },
  },
});

export default function UploadApp() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [contentResponseVideo, setContentResponseVideo] = React.useState({});
  const [contentResponse, setContentResponse] = React.useState({});
  const [errors, setErrors] = React.useState({});

  const [videoJobID, setVideoJobID] = React.useState("");
  const [missedAttributs, setMissedAttributs] = React.useState([]);
  const [openSwitchTabs, setOpenSwitchTabs] = React.useState(false);
  const [openLoad, setOpenLoad] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [tempValue, setTempValue] = React.useState(0);
  const [processState, setProcessState] = React.useState(0);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [configuration, setConfiguration] = React.useState({});
  const navigate = useNavigate();
  let defaultDate = new Date();
  const { translation } = useSelector((state) => state.languageReducer);

  useEffect(() => {
    getTenant(requests.GetTenant).then(item => setConfiguration(item));
  }, []);

  let uploadStringURL = configuration?.azureContainerURL;

  const {
    allSpeakers,
    newContent,
    allContributors,
    allChannels,
    allTags,
    openPersonalizedDialog,
  } = useSelector((state) => state.globalReducer);
  const { userRedux } = useSelector((state) => state.authentificationReducer);
  const cleaningUserName = (source) => {
    let outString = source?.replace(/[^0-9a-z]/gi, "");
    if (outString?.length > 14) {
      return outString?.substring(0, 14);
    } else {
      return outString;
    }
  };
  const getNewName = (type) => {
    let date = new Date();

    switch (type) {
      case "video":
        return (
          date.getFullYear() +
          "" +
          (date.getMonth() + 1) +
          "" +
          date.getDate() +
          "" +
          date.getHours() +
          "" +
          date.getMinutes() +
          "" +
          date.getSeconds() +
          date.getMilliseconds() +
          cleaningUserName(userRedux.lastName) +
          ".mp4"
        );
        break;
      case "pdf":
        return (
          date.getFullYear() +
          "" +
          (date.getMonth() + 1) +
          "" +
          date.getDate() +
          "" +
          date.getHours() +
          "" +
          date.getMinutes() +
          "" +
          date.getSeconds() +
          date.getMilliseconds() +
          cleaningUserName(userRedux.lastName) +
          ".pdf"
        );
        break;
      case "podcast":
        return (
          date.getFullYear() +
          "" +
          (date.getMonth() + 1) +
          "" +
          date.getDate() +
          "" +
          date.getHours() +
          "" +
          date.getMinutes() +
          "" +
          date.getSeconds() +
          date.getMilliseconds() +
          cleaningUserName(userRedux.lastName) +
          ".mp3"
        );
        break;

      default:
        return null;
    }
  };


  let newVideoName = getNewName("video");
  let newPDFName = getNewName("pdf");
  let newPodCastName = getNewName("podcast");

  const getAllSpeakersID = (list) => {
    let idList = [];
    list?.map((item, index) => {
      idList.push(item.id);
    });
    return idList;
  };
  const getAllChannelsID = (list) => {
    if (list.length < 1) return undefined;
    let idList = [];
    list?.map((item, index) => {
      idList.push(item.id);
    });
    return idList;
  };
  const verifyForm = (data) => {
    let valid = false;
    let counter = 0;
    let nonValidKeys = [];
    for (const [key, value] of Object.entries(data)) {
      if (value !== undefined && value !== "" && value !== null) {
        counter = counter + 1;
      } else {
        if (
          key !== "contentId" &&
          key !== "creator" &&
          key !== "Channel" &&
          key !== "channel" &&
          key !== "channelId" &&
          key !== "comment" &&
          key !== "isAct" &&
          key !== "isExpert" &&
          key !== "isDiscover" &&
          key !== "heroBanner" &&
          key !== "isExternalContent" &&
          key !== "externalContentLink" &&
          key !== "isPublic"
        ) {
          nonValidKeys.push(key);
        }
      }
    }


    if (data.isExternalContent === true
      && (data.externalContentLink === null || data.externalContentLink === "" || data.externalContentLink === undefined)
    ) {
      valid = false;
      nonValidKeys.push("externalContentLink");
    }
    if (data.isExternalContent) {
      if (!data?.externalContentLink?.startsWith('https://')) {
        valid = false;
        nonValidKeys.push("externalContentLinkHttps");
      }
    }
    setMissedAttributs(personalizedLabels(nonValidKeys));

    if (nonValidKeys.length === 0) {
      valid = true
    }
    else {
      valid = false
    }


    if (valid === false) {
      setDisableSubmit(false);
      dispatch(setOpenPersonalizedDialog(true));
    }
    return valid;
  };
  const personalizedLabels = (list) => {
    let strList = [];
    list.map((item, index) => {
      switch (item) {
        case "title":
          strList.push("- Fill a Title");
          break;
        case "category":
          strList.push("- Choose a Category");
          break;
        case "externalContentLink":
          strList.push("- External content link must be filled");
          break;
        case "externalContentLinkHttps":
          strList.push("- External content link must start with https://");
        case "subCategory":
          strList.push("- Choose a Sub-Category");
          break;
        case "subSubCategory":
          strList.push("- Choose a SubSubCategory");
          break;
        case "FilePath":
          if (value === 0) strList.push("- Upload a Video");
          if (value === 1) strList.push("- Upload a PDF File");
          if (value === 2) strList.push("- Upload a Podcast File");

          break;
        case "speakers":
          strList.push("- Add Speakers");
          break;
        case "Speakers":
          strList.push("- Add Speakers");
          break;
        case "contributors":
          strList.push("- Add Contributors");
          break;
        case "thunbnailURL":
          strList.push("- Upload a Thumbnail");
          break;
        case "location":
          strList.push("- Choose a Location");
          break;

        case "language":
          strList.push("- Choose a Language");
          break;
        case "keywords":
          strList.push("- Please add some Tags");

          break;
        case "status":
          strList.push("- Choose a Status");
          break;
        case "startCuration":
          strList.push("- Add Start-Curation Date");
          break;

        case "endCuration":
          strList.push("- Add End-Curation Date");
          break;
        case "publicationDate":
          strList.push("- Add Publication-Curation Date");
          break;
        case "summary":
          strList.push("- Fill a Summary");
          break;
        case "publicationDateStart":
          strList.push("- Fill Start Publication Date");
          break;
        case "publicationDateEnd":
          strList.push("- Fill End Publication Date");
          break;
        case "contentRating":
          strList.push("- Rate the content");
          break;
        default:
          break;
      }
    });
    return strList;
  };

  const getFilePathMedia = (type) => {
    switch (type) {
      case "video":
        return newContent?.videoName !== undefined
          ? (uploadStringURL + "videos/" + newVideoName).toLowerCase()
          : null;

        break;
      case "pdf":
        return newContent?.pdfName !== undefined
          ? (uploadStringURL + "documents/" + newPDFName).toLowerCase()
          : null;

        break;
      case "podcast":
        return newContent?.podCastFile !== undefined
          ? (uploadStringURL + "podcasts/" + newPodCastName).toLowerCase()
          : null;

        break;
      default:
        return;
    }
  };

  const getFilePathThumbnail = (type, newName) => {
    switch (type) {
      case "video":
        return newContent?.thunbnailURL !== undefined
          ? (
            uploadStringURL +
            "videos/thumbnails/" +
            newName
          ).toLowerCase()
          : null;

        break;
      case "pdf":
        return newContent?.thunbnailURL !== undefined
          ? (
            uploadStringURL +
            "documents/thumbnails/" +
            newName
          ).toLowerCase()
          : null;

        break;
      case "podcast":
        return newContent?.thunbnailURL !== undefined
          ? (
            uploadStringURL +
            "podcasts/thumbnails/" +
            newName
          ).toLowerCase()
          : null;

        break;
      case "banner":
        return (newContent?.banner !== undefined && newContent?.banner !== null && newContent?.banner !== "")
          ? (
            uploadStringURL +
            "videos/banners/" +
            newName
          ).toLowerCase()
          : null;
      default:
        return;
    }
  };

  function toTime(seconds) {
    //regex verify if seconds is "00:00:00" format
    if (/^([0-9]{2})\:([0-9]{2})\:([0-9]{2})$/.test(seconds)) {
      return seconds;
    }

    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds - (hours * 3600)) / 60);
    var seconds = seconds - (hours * 3600) - (minutes * 60);
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + Math.floor(seconds);
  }




  const create = () => {
    const typeUpl = curationUploadType.find((cut) => cut.id === value).label;

    let temp = [];
    newContent?.keyWord?.forEach((st) => {
      if (!st.id) {
        let tag = st;
        tag.id = st.value;
        delete tag.value;
        temp.push(tag);
      } else {
        temp.push(st);
      }
    });
    let randDate = new Date();
    const newBannerName = newContent?.banner ? randDate.getTime().toString() + Math.floor(Math.random() * 100000).toString() + "." + newContent?.banner?.name?.split('.').pop() : "";
    const newThumbnailName = randDate.getTime().toString() + Math.floor(Math.random() * 100000).toString() + "." + newContent?.thunbnailURL?.split('.').pop().toLowerCase();

    let keywords = temp.map((item) => item.label).join(",");

    let dataToSend = {
      title: newContent.title,
      language: newContent?.language,
      category: newContent.category?.category ?? "",
      subCategory: newContent.selectedSubCategory,
      subSubCategory: newContent.selectedSubSubCategory ?? "",
      speakers: newContent.speaker !== undefined && newContent?.speaker?.length > 0 ? getAllSpeakersID(newContent.speaker) : null,
      Channel: newContent?.Channel?.id,
      contributors: [],
      keywords: keywords ?? "Sustainability",
      location: newContent.location,
      FilePath: getFilePathMedia(typeUpl),
      thunbnailURL: getFilePathThumbnail(typeUpl, newThumbnailName),
      status: newContent.status,
      startCuration: newContent.startCuration ?? defaultDate,
      endCuration: newContent.endCuration ?? defaultDate,
      publicationDate: newContent.createdOn ?? defaultDate,
      contentId: null,
      isNewVideo: true,
      summary: newContent.fullSummary,
      publicationDateStart: newContent.publicationDateStart,
      publicationDateEnd: newContent.publicationDateEnd,
      duration: toTime(newContent.videoDuration ?? newContent.duration) ?? "00:00:00",
      MediaType: newContent.MediaType,
      comment: newContent.comment ?? " ",
      contentRating: newContent.contentRating,
      ContentAdminStatus: newContent.status,
      isAct: newContent.isAct,
      isExpert: newContent.isExpert,
      isDiscover: newContent.isDiscover,
      bookmark: newContent.bookmark ?? [],
      highlight: newContent.highlight ?? [],
      heroBanner: getFilePathThumbnail("banner", newBannerName),
      isHighlighted: newContent.isHighlighted ?? false,
      ContentOpenAIProcessStatusId: (newContent?.generateOpenAI ? 0 : -1),
      externalContentLink: newContent.externalContentLink,
      isExternalContent: newContent.isExternalContent,
      isPublic: newContent.isPublic ?? false,
    };

    if (verifyForm(dataToSend) === true) {
      createContent(requests.CreateContent, dataToSend).then((item) => {
        setDisableSubmit(true);
        const typeUpl = curationUploadType.find(
          (cut) => cut.id === value
        ).uploadString;

        if (typeUpl === "videos") {
          let dataVideo = {
            Status: "Inprocess",
            Name: "UploadVideo To container",
            Completion: 0,
            contentId: item.data.id,
            URL: item.containerUri + "/videos/" + item.data.filename,
            MediaType: curationUploadType.find((cut) => cut.id === value)
              ?.MediaType,
          };
          setContentResponseVideo(dataVideo);

          if (newContent.videoFile) {
            uploadBlob(item, uploadType.video, dataVideo);
          }
        }

        let dataPDF = {
          Status: "Inprocess",
          Name: "Upload PDF To container",
          Completion: 0,
          contentId: item.data.id,
          URL: item.containerUri + "/" + typeUpl + "/" + item.data.filename,
          MediaType: curationUploadType.find((cut) => cut.id === value)
            ?.MediaType,
        };

        let dataPodcast = {
          Status: "Inprocess",
          Name: "Upload Podcast To container",
          Completion: 0,
          contentId: item.data.id,
          URL: item.containerUri + "/" + typeUpl + "/" + item.data.filename,
          MediaType: curationUploadType.find((cut) => cut.id === value)
            ?.MediaType,
        };
        //alert("Content Created Successfully");

        setContentResponse(dataPDF);

        if (newContent.thumbnailFile) {
          uploadBlob(item, uploadType.thumbnail, dataPDF, newThumbnailName);
        }
        if (newContent.banner) {
          uploadBlob(item, "banner", dataPDF, newBannerName);
        }
        if (newContent.pdfFile) {
          uploadBlob(item, uploadType.pdf, dataPDF);
        }
        if (newContent.podCastFile) {
          uploadBlob(item, uploadType.podcast, dataPodcast);
        }
      })
        .catch((err) => {
          console.log(err);
          setDisableSubmit(false);
        });
    }
  };
  const renameMediaFile = (media, type) => {
    let data = new FormData();
    let newVideoFile = "";
    let newPdfFile = new File([media], newPDFName);
    let newPodCastFile = new File([media], newPodCastName);
    switch (type) {
      case "video":
        data.append("file", media, newVideoName);
        newVideoFile = data.get("file");
        return newVideoFile;

        break;
      case "pdf":
        return newPdfFile;

        break;
      case "podcast":
        return newPodCastFile;

        break;

      default:
        return null;
    }
  };

  function refreshProgress(speedSummary) {
    setOpenLoad(true);

    var process = () => {
      return speedSummary?.getCompletePercent();
    };

    const interval = setInterval(function () {
      dispatch(setProgressPercent(process()));
      if (process === "100.0") {
        setOpenLoad(false);
        clearTimeout(interval);
      }
    }, 500);

    return interval;
  }
  const d = new Date();

  const uploadBlob = (response, typeBlob, data, newThumbnailName) => {
    let file;
    let blobUri;
    let containerName;
    let sasToken;
    let blobService;
    let customBlockSize;
    let finishedOrError;
    let speedSummary;

    const typeUpl = curationUploadType.find(
      (cut) => cut.id === value
    ).uploadString;

    switch (typeBlob) {
      case uploadType.thumbnail:
        file = newContent.thumbnailFile;
        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;

        const newFile = new File([file], newThumbnailName);
        const blobPath = typeUpl + "/thumbnails/" + newThumbnailName.toLowerCase();

        // Function to upload or retry upload
        function uploadOrRetry(retryCount = 0, maxRetries = 1) {
          blobService.createBlockBlobFromBrowserFile(
            containerName,
            blobPath,
            newFile,
            { blockSize: customBlockSize },
            function (error, result, response) {
              if (error && retryCount < maxRetries) {
                console.log("Upload failed, attempting retry:", retryCount + 1);
                uploadOrRetry(retryCount + 1); // Retry upload
              } else {
                // Check if upload was successful by verifying blob properties
                verifyUpload(blobPath, retryCount, maxRetries);
              }
            }
          );
        }
        // Function to verify upload success
        function verifyUpload(blobName, retryCount, maxRetries) {
          blobService.getBlobProperties(containerName, blobName, function (error, properties) {
            if (error || !properties) {
              if (retryCount < maxRetries) {
                console.log("Verification failed, attempting retry:", retryCount + 1);
                uploadOrRetry(retryCount + 1, maxRetries); // Retry upload
              } else {
                alert("Failed to upload and verify thumbnail after retries.");
                setErrors({
                  ...errors,
                  thumbnailError: "Verification failed after retries.",
                });
              }
            } else {
              finishedOrError = true;
              // Proceed with any further logic required after successful upload and verification
            }
          });
        }
        // Initial attempt
        uploadOrRetry();
        break;
      case "banner":
        file = newContent.banner;
        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;

        const newFileBanner = new File([file], newThumbnailName);

        speedSummary = blobService.createBlockBlobFromBrowserFile(
          containerName,
          typeUpl + "/banners/" + newThumbnailName,
          newFileBanner,
          { blockSize: customBlockSize },
          function (error, result, response) {
            finishedOrError = true;
            if (error) {
              alert("Error when uploading Banner");

              setErrors({
                ...errors,
                bannerError: error,
              });
            }

          }
        );
        break;
      case uploadType.podcast:
        file = renameMediaFile(newContent.podCastFile, "podcast");
        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;
        speedSummary = blobService.createBlockBlobFromBrowserFile(
          containerName,
          "podcasts/" + file.name.toLowerCase(),
          file,
          { blockSize: customBlockSize },
          function (error, result, response) {
            finishedOrError = true;

            if (error) {
              alert("Error when uploading");
              setErrors({
                ...errors,
                podcastError: error,
              });
            } else {
              let temp = speedSummary?.getCompletePercent();
              setProcessState(temp);
              displayProcess(temp, data);
            }
          }
        );

        refreshProgress(speedSummary);
        break;
      case uploadType.pdf:
        file = renameMediaFile(newContent.pdfFile, "pdf");
        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;

        speedSummary = blobService.createBlockBlobFromBrowserFile(
          containerName,
          "documents/" + file.name.toLowerCase(),
          file,
          { blockSize: customBlockSize },
          function (error, result, response) {
            finishedOrError = true;

            if (error) {
              alert("Error when uploading ");
              setErrors({
                ...errors,
                pdfError: error,
              });
            } else {
              let temp = speedSummary?.getCompletePercent();
              setProcessState(temp);
              displayProcess(temp, data);
            }
          }
        );
        refreshProgress(speedSummary);
        break;
      case uploadType.video:
        file = renameMediaFile(newContent.videoFile, "video");
        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;
        speedSummary = blobService.createBlockBlobFromBrowserFile(
          containerName,
          "videos/" + file.name.toLowerCase(),
          file,
          { blockSize: customBlockSize },
          function (error, result, response) {
            finishedOrError = true;

            if (error) {
              alert("Error when uploading");
              setErrors({
                ...errors,
                videoError: error,
              });
            } else {
              let temp = speedSummary?.getCompletePercent();
              setProcessState(temp);
              displayProcess(temp, data);
            }
          }
        );

        refreshProgress(speedSummary);

        break;

      default:
        return null;
    }
  };
  const displayProcess = (process, data) => {
    createUpload(data);
  };

  const createUpload = (data) => {
    createUploadJob(requests.CreateUploadJob, data).then((item) => {
      setVideoJobID(item.id);
    });
  };
  const validationSchema = () =>
    Yup.object().shape({
      all: Yup.string().required("Required"),
    });

  const allTagsList = (list) => {
    var newListObject = [];
    if (list) {
      list.map((item, index) => {
        newListObject.push({
          value: item.id,
          label: item.label,
          label_fr: item.label_fr,
          contentCount: item.contentCount,
        });
      });
    }
    return newListObject;
  };
  const allSpeakersList = (list) => {
    var newListObject = [];
    if (list) {
      list.map((item, index) => {
        var temp = item;
        temp.value = item.id;
        temp.label = item?.persons?.firstName + " " + item?.persons?.lastName;
        newListObject.push(temp);
      });
    }
    return newListObject;
  };

  const allChannelsList = (list) => {
    let newListObject = [];
    newListObject.push({ value: null, label: "None", name: "None", id: null });
    if (list) {
      list.map((item, index) => {
        let temp = item;
        temp.value = item.id;
        temp.label = item.name;
        newListObject.push(temp);
      });
    }

    return newListObject;
  };
  const allCategoriesList = (list) => {
    let newListObject = [];
    if (list) {
      list.map((item, index) => {
        let temp = item;
        temp.value = item.parentId;
        temp.label = item.category;
        temp.subCategory = getAllSubCategoriesList(item.subCategory);
        newListObject.push(temp);
      });
    }

    return newListObject;
  };
  const getAllSubCategoriesList = (list) => {
    let newListObject = [];
    if (list) {
      list.map((item, index) => {
        let temp = item;
        temp.value = item.subCategoryId;
        temp.subSubCategory = getAllSubSubCategoriesList(item.subSubCategory);

        newListObject.push(temp);
      });
    }

    return newListObject;
  };
  const getAllSubSubCategoriesList = (list) => {
    let newListObject = [];
    if (list) {
      list.map((item, index) => {
        let temp = {};
        temp = item;
        temp.value = index;

        newListObject.push(temp);
      });
    }

    return newListObject;
  };
  const switchTabsIcons = (val, isActive) => {
    switch (val) {
      case "video":
        return (
          <>
            <FaPhotoVideo
              size={20}
              color={isActive === true ? "#b6aa90" : "black"}
              style={{ marginRight: 10 }}
            />
          </>
        );

      case "pdf":
        return (
          <>
            <ImFilePdf
              size={20}
              color={isActive === true ? "#b6aa90" : "black"}
              style={{ marginRight: 10 }}
            />
          </>
        );

      case "podcast":
        return (
          <>
            <CgMediaPodcast
              size={20}
              color={isActive === true ? "#b6aa90" : "grey"}
              style={{ marginRight: 10 }}
            />
          </>
        );
    }
  };
  const switchTabsView = (val) => {
    const temp = curationUploadType.find((cut) => cut.id === val).label;
    switch (temp) {
      case "video":
        return (
          <>
            <CurationContent
              allTags={allTags}
              tab={"Video"}
              reloadForm={value}
            />

            <CurationGraphical tab={"Video"} />
            <EditorialContent />
            <CurationCreation reloadForm={value} />
          </>
        );
        break;
      case "pdf":
        return (
          <>
            <CurationContent allTags={allTags} tab={"PDF"} reloadForm={value} />
            <CurationGraphical tab={"PDF"} />
            <CurationCreation reloadForm={value} />
          </>
        );

        break;
      case "podcast":
        return (
          <>
            <CurationContent
              allTags={allTags}
              tab={"Podcast"}
              reloadForm={value}
            />
            <CurationGraphical tab={"Podcast"} />
            <EditorialContent />
            <CurationCreation reloadForm={value} />
          </>
        );

        break;
      default:
        <></>;
    }
  };
  const handleOpenSwitchTabs = (event, newValue) => {
    setTempValue(newValue);
    setOpenSwitchTabs(true);
  };
  const handleCloseSwitchTabs = () => {
    setOpenSwitchTabs(false);
  };
  const handleConfirmSwitchTabs = () => {
    setValue(tempValue);
    dispatch(GetNewContentAction({}));
    dispatch(
      GetNewContentAction({
        MediaType: curationUploadType.find(c => c.id === tempValue)
          ?.MediaType,
      })
    );

    setOpenSwitchTabs(false);
  };
  useEffect(() => {
    getTokenByID(requests.GetMeInformation).then(item => {
      if (!Cookies.get("roles")) {
        Cookies.set("roles", JSON.stringify(item.roles));
      }
      dispatch(GetTokenByIdUser(item));
    });
  }, []);

  useEffect(() => {
    getAllCategories(requests.getAllCategories).then((item) =>
      dispatch(GetAllCategoriesAction(allCategoriesList(item)))
    );
    getAllSpeakers(requests.getAllSpeakersCuration).then((item) =>
      dispatch(GetAllSpeakersAction(allSpeakersList(item)))
    );

    getAllChannels(requests.GetAllChannels).then((item) =>
      dispatch(GetAllChannelsAction(allChannelsList(item)))
    );

    getAllContributors(requests.getAllContributors).then((item) =>
      dispatch(GetAllContributorsAction(allSpeakersList(item)))
    );

    getAllTags(requests.GetAllTagsCuration).then((item) =>
      dispatch(getAllTagsAction(allTagsList(item)))
    );

    dispatch(
      GetNewContentAction({
        ...newContent,
        MediaType: curationUploadType.find((c) => c.id === 0)?.MediaType,
        contentProcessStatusId: "upload",
      })
    );


  }, []);

  const updateCheck = processState && contentResponseVideo && videoJobID;
  useEffect(() => {

    const typeUpl = curationUploadType.find(
      (cut) => cut.id === value
    ).uploadString;
    if (
      processState.toString().includes("100") &&
      contentResponseVideo &&
      typeUpl === "videos" &&
      videoJobID
    ) {
      let requestVideo = {
        JobId: videoJobID,
        ContentId: contentResponseVideo.contentId,
        Status: processState == 100 ? "Completed" : "Inprocess",
        Completion: processState,
        URL: contentResponseVideo.URL,
        MediaType: curationUploadType.find((c) => c.id === value)?.MediaType,
      };
      updateUploadJob(requests.UpdateUploadJob, requestVideo);

      navigate("/dashboard/contents");
    }

  }, [updateCheck]);

  useEffect(() => {
    const typeUpl = curationUploadType.find(
      (cut) => cut.id === value
    ).uploadString;

    if (
      processState.toString().includes("100") &&
      contentResponse &&
      typeUpl !== "videos" &&
      (typeUpl === 'podcasts' ? videoJobID : true)
    ) {
      let request = {
        JobId: videoJobID,
        ContentId: contentResponse.contentId,
        Status: processState == 100 ? "Completed" : "Inprocess",
        Completion: processState,
        URL: contentResponse.URL,
        errorMessage: errors.pdfError ?? "",
        mediaType:
          curationUploadType.find((c) => c.id === value)?.MediaType ?? 3,
      };
      updateUploadJob(requests.UpdateUploadJob, request);
      navigate("/dashboard/contents");
    }
  }, [updateCheck]);

  return (
    <Formik
      initialValues={{
        submit: false,
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        resetForm,
      }) => (
        <form
          id="create-form"
          onSubmit={(e) => {
            e.preventDefault();
            if (e?.target?.id === "create-form") {
              setDisableSubmit(true);
              create();
            }
          }}
        >
          <div className="uploadAppContainer">
            <>
              <div style={{ display: "flex", justifyContent: "left" }}>
                <div></div>
              </div>

              <Grid container>
                <Grid item xs={5} sx={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Create Content
                  </Typography>
                  <IoMdArrowRoundBack
                    className="arrowButton"
                    size={25}
                    style={{ marginLeft: "15px", marginTop: "5px" }}
                    onClick={() => {
                      navigate("/dashboard/contents");
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Tabs
                    value={value}
                    onChange={handleOpenSwitchTabs}
                    aria-label="disabled tabs example"
                    className={classes.tabs}
                  >
                    {curationUploadType.map((item, index) => {
                      return (
                        <Tab
                          label={item.label}
                          key={index}
                          color="#b6aa90"
                          icon={switchTabsIcons(item.label, value === item.id)}
                          iconPosition="start"
                        />
                      );
                    })}
                  </Tabs>
                </Grid>
              </Grid>

              {switchTabsView(value)}
            </>

            <button
              type="submit"
              className={
                disableSubmit === true
                  ? "submit-modify-disabled"
                  : "submit-modify"
              }
              disabled={disableSubmit}
            >
              Create Content
            </button>
          </div>

          <Dialog
            open={openSwitchTabs}
            onClose={handleCloseSwitchTabs}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{ textAlign: "center" }}>
              {"Notification"}
            </DialogTitle>

            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ color: "red" }}
              >
                {translation.Confirm_Switch_Tabs}
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              <Button onClick={handleCloseSwitchTabs}>
                {translation.Cancel}
              </Button>
              <Button onClick={handleConfirmSwitchTabs} autoFocus>
                {translation.Confirm}
              </Button>
            </DialogActions>
          </Dialog>

          <DialogLoader
            opened={openLoad}
            value={curationUploadType.find((c) => c.id === value).label}
          />
          {openPersonalizedDialog && (
            <PersonalizedDialog
              open={openPersonalizedDialog}
              title={"Required data"}
              missedAttributs={missedAttributs}
            />
          )}
        </form>
      )}
    </Formik>
  );
}
