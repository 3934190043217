import axios from "axios";

export function postComment(requests, comment) {
  return new Promise((resolve, reject) => {
    axios
      .post(requests, comment, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);

return(response.data)
        
      }).catch((error) => {
        reject(error);
      });
  });
}


export function updateCommentByID(requests, CommentId, CommentText) {
  return new Promise((resolve, reject) => {
    axios
      .put(requests, { CommentId, CommentText }, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteCommentByID(requests, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(requests + id, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllRepliesByID(requests, id,limit,page) {

 var req = requests
  .replace("{id}", id)
  .replace("{limit}",limit)
  .replace("{page}", page);

  return new Promise((resolve, reject) => {
    axios
      .get(req , { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
