import axios from "axios";

export function PostSuggestion(requests, obj) {
  return new Promise((resolve, reject) => {
    axios
      .post(requests, obj, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
