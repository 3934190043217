// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * RecommendedVideo component *                                       *
 * ?                                       SideBar is used in the conference page                               *
 * ?            It displays informations about the video, metadata choosen by the uploader of the conference    *
 * ?                            Metadata such as marking elements of the video, or special events               *
 */
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import DownloadedVideoCard from "./DownloadedVideoCard";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import "./DownloadedPlaylist.scss";

const DownloadedPlaylist = props => {
  const { contents, userId, searchScreen } = props;
  let error = true;
  
  if (contents === "") {
    error = false;
  }

  return error ? (
    <div className={`download`}>
      {contents?.map((item, index) => (
        <div className="DownloadedPlaylist" key={index}>
          <DownloadedVideoCard 
            item={item} 
            userId={userId} 
            isSaved={item?.isSaved}
            searchScreen={searchScreen}
          />
        </div>
      ))}
    </div>
  ) : (
    <div className="download__noVideo">
      {" "}
      Nothing found, try something else{" "}
    </div>
  );
};

DownloadedPlaylist.propTypes = {
  research: PropTypes.any,
};

export default DownloadedPlaylist;
