//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Conferences page *                                            *
 * ?                      Conferences is a page that will display the video clicked by the user                 *
 * ?                      It works with multiple components listed in the import                                *
 * ?                      Each components are differents and placed in a certain way (mockups)                  *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router";

import requests from "../components/axios/Requests";
import { RelatedVideoTags } from "../redux/action/RelatedVideoAction";
import { dispatchedPlaylistAction } from "../redux/action/GlobalActions";
import { GetConferenceAction } from "../redux/action/ConferenceAction";
import { getConference } from "../servicesRedux/ConferenceService.js";
import { getSpeakerSubscriptions } from "../servicesRedux/SubscriptionService";
import {
  getPlaylists,
  getPlaylistsWithContent,
} from "../servicesRedux/PlaylistService";
import { getAllPlaylistsAction, checkAndUpdateLikesCountAction } from "../redux/action/PlaylistAction";
import { destroySession } from "../redux/action/AuthentificationAction";

import useBreakpoints from "../hooks/UseBreakpoint";
import LargeScreenConferencePage from "../components/conferencePageComponents/LargeScreenConferencePage";
import SmallScreenConferencePage from "../components/conferencePageComponents/SmallScreenConferencePage";
import { useTracking } from "react-tracking";
import Constants from "../components/constants/Constants";
import { setConferenceToDownload } from "../redux/action/DownloadedAction.js";
import 'react-toastify/dist/ReactToastify.css';
import "../theme/conferences/Conferences.scss";

const Conferences = () => {
  const dispatch = useDispatch();
  const [conference, setConference] = useState([]);
  const { translation } = useSelector(state => state.languageReducer);
  const [transcriptList, setTranscriptList] = useState();
  const [videoHasLoaded, setVideoHasLoaded] = useState(false);
  const [linkedVideoHasLoaded, setLinkedVideoHasLoaded] = useState(false);
  const { id } = useParams();
  const userID = Cookies.get("userID");
  const [isLoading, setIsLoading] = useState(false);
  const [isSavedContent, setIsSavedContent] = useState(false);
  const [like, setLike] = useState(false);
  const [likeCount, setLikeCount] = useState(null);
  const pageParams = {
    limit: 20,
    page: 1,
  };
  const point = useBreakpoints();
  const tracking = useTracking();
  const [following, setFollowing] = useState(false);
  const [isDownloaded, setIsDownload] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    dispatch(dispatchedPlaylistAction(false));
  }, []);
  useEffect(() => {
    setIsLoading(true);
    getConference(requests.GetConference, id).then(item => {
      setConference(item);
      setIsLoading(false);
      setLikeCount(item?.review);
      dispatch(GetConferenceAction(item));

      if(item.isAuthenticated !== false){
        getPlaylistsWithContent(requests.GetPlaylistsWithContent, item.contentId).then(pItem => {
          getPlaylists(requests.GetMyPlaylists, pageParams).then(playlists => {
            const watchLaterPlaylist = playlists.data.filter(p => p.type === 2)[0];
            const myFavoritesPlaylist = playlists.data.filter(p => p.type === 3)[0];
            if (pItem.includes(watchLaterPlaylist.id)) {
              setIsSavedContent(true);
            }
            if (pItem.includes(myFavoritesPlaylist.id)) {
              setLike(true);
            }
            dispatch(getAllPlaylistsAction(playlists));
            dispatch(checkAndUpdateLikesCountAction(item?.review));
            getSpeakerSubscriptions(requests.GetSpeakerSubscriptions).then(subscriptions => {
              if (item.speaker) {
                item.speaker.forEach(sItem => {
                  const found = subscriptions.find(element => {
                    return element.id === sItem.id;
                  });
                  if (found) {
                    setFollowing(true);
                  }                
                });
              }
            });          
          });
        });
      }
    }).catch(error => {
      if (error?.response?.status === 403) {
        toast(translation.Account_Deactivation_Msg);
        tracking.trackEvent(
          {
            UserEventId: Number(Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.LOGOUT.enum),
          }
        );  
        dispatch(destroySession("DESTROY_SESSION"));
        Cookies.remove("userID");
        Cookies.remove("Expire_in");
        Cookies.remove("Header_signature");
        Cookies.remove("Payload");
        Cookies.remove("role");
        Cookies.remove("roles");
        Cookies.remove("accountType");
        if (Cookies.get("saveId") !== "true") {
          Cookies.set("saveId", "false");
          Cookies.remove("password");
          Cookies.remove("email");
        }
        history("/login", { state: { accountDeactivated: true } });
      }
      if (error?.response?.status === 401) {
        history("/login");
      }
    });
    loadTranscript();
    tracking.trackEvent(
      {
        UserEventId: Number(Constants.UserEvent.LOAD.enum),
        UserActionId: Number(Constants.UserAction.VIEW_MEDIA.enum),
        DataId: id,
        DataTypeId: Number(Constants.UserLogDataType.CATALOGITEM_ID.enum),
        MediaTypeId: Number(Constants.MediaType.Video),
      }
    );
  }, []);

  useEffect(() => {
    loadTranscript(conference?.conference?.transcriptURL);
  }, [conference?.conference?.transcriptURL]);

  useEffect(() => {
    dispatch(RelatedVideoTags(conference.tag));
  }, [conference.tag]);

  const handleResponse = async response => {
    // Retrieve the content type from the response headers
    const contentType = response.headers.get("Content-Type");
  
    if (contentType.includes("application/json")) {
      // Handle JSON content type
      return response.text().then(text => {
        const data = text ? JSON.parse(text) : {};
        if (!response.ok) {
          return Promise.reject(new Error(data.error_description || 'Unknown Error'));
        }
        setTranscriptList(data);
        return data;
      });
    } else if (contentType.includes("text/plain") || contentType.includes("text/vtt")) {
      // Handle plain text or VTT content type
      return response.text();
    } else if (contentType === "application/octet-stream") {
      // Assume octet-stream is an SRT file and treat it as text
      setTranscriptList(await response.text());
      return response.text();
    } else {
      // Handle other content types or unexpected content types
      return Promise.reject(new Error("Unsupported content type: " + contentType));
    }
  };
  
  

  const loadTranscript = url => {
    if (url !== undefined && url !== null && url !== "") {
      fetch(url, {
        method: "get",
      })
      .then(handleResponse)
      .catch(err => console.log(err));
    }
  };

  const triggerGetConferenceApi = () => getConference(requests.GetConference, id).then(item => {
    setConference(item);
    setLikeCount(item?.review);
  });

  const triggerLoader = status => setIsLoading(status);

  const handleDownload = () => {
    setIsDownload(!isDownloaded);
    dispatch(setConferenceToDownload(conference));
    // handleDownloadAndStore(conference); // Pass your conference object here
  }

  async function downloadVideo(videoUrl) {
    const response = await fetch(videoUrl);
    if (!response.ok) throw new Error('Failed to download video');
    return response.blob();
  }

  async function fetchFormattedVideoUrl(originalUrl) {
    const metadataUrl = originalUrl.replace("insights.json", "metadata.json");
    const response = await fetch(metadataUrl);
    const metadata = await response.json();
    const baseUri = metadata.AssetFile[0].Uri;
    const videoId = baseUri.split('/').pop().split('.')[0]; // Extracts '20233211728057postlegendre' from the URI
    return originalUrl.replace("insights.json", `Video-${videoId}-HD-1000.mp4`);
  }
  
  function setupIndexedDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("ConferenceDatabase", 2);
  
      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
  
      request.onupgradeneeded = event => {
        const db = event.target.result;
        db.createObjectStore("conferences", { keyPath: "id" });
      };
    });
  }

  async function storeConferenceData(conference, videoBlob) {
    const db = await setupIndexedDB();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("conferences", "readwrite");
      const store = transaction.objectStore("conferences");
      const dataToStore = { ...conference, videoBlob, id: conference.contentId };
  
      const request = store.put(dataToStore);
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }

  async function handleDownloadAndStore(conference) {
    try {
      const videoUrl = await fetchFormattedVideoUrl(conference.conference.transcriptURL);
      const videoBlob = await downloadVideo(videoUrl);
      await storeConferenceData(conference, videoBlob);
      console.log("Conference and video stored successfully!");
    } catch (error) {
      console.error("Error handling download and storage:", error);
    }
  }
  

  const renderConferencePageContentOnBreakPoint = mode => {
    switch(mode) {
      case "xs":
      case "sm":
      case "md":
      case "ml":
        return (
          <>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <SmallScreenConferencePage
              conference={conference}
              setVideoHasLoaded={setVideoHasLoaded}
              videoHasLoaded={videoHasLoaded}
              id={id}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              userID={userID}
              transcriptList={transcriptList}
              translation={translation}
              triggerGetConferenceApi={triggerGetConferenceApi}
              setLinkedVideoHasLoaded={setLinkedVideoHasLoaded}
              isSavedContent={isSavedContent}
              like={like}
              linkedVideoHasLoaded={linkedVideoHasLoaded}
              triggerLoader={triggerLoader}
              point={point}
              likeCount={likeCount}  
              following={following}
              handleDownload={handleDownload}
              isDownloaded={isDownloaded}
            />
          </>          
        );                  
      case "lg":
        return (
          <>
            <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <LargeScreenConferencePage 
              conference={conference}
              setVideoHasLoaded={setVideoHasLoaded}
              videoHasLoaded={videoHasLoaded}
              id={id}
              isLoading={isLoading}
              userID={userID}
              transcriptList={transcriptList}
              translation={translation}
              triggerGetConferenceApi={triggerGetConferenceApi}
              setLinkedVideoHasLoaded={setLinkedVideoHasLoaded}
              isSavedContent={isSavedContent}
              like={like}
              linkedVideoHasLoaded={linkedVideoHasLoaded}   
              likeCount={likeCount}  
              following={following}  
              handleDownload={handleDownload}
              isDownloaded={isDownloaded}
            />
          </>
        );
    }
  };
  
  return renderConferencePageContentOnBreakPoint(point); 
}

export default React.memo(Conferences);
