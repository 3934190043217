// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Change Password page *                                        *
 * ?                      Once the end user receieved the reset password email then                             *
 * ?                      the user should change the password with this page                                    *
 * ?                                                                                                            *
 */
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// import AuthGuard from '../core/Guard/AuthGuard';

import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core';

import AuthGuard from "../core/guard/AuthGuard";
import { changePassword } from "../core/services/api/authService/AuthService";

import logo from "../assets/images/logo.png";
import "../theme/login/Login.scss";
import Loader from "../components/async/Loader";

import MultiLanguage from "../components/language/MultiLanguage";

const theme = createTheme();
const Alert = styled(MuiAlert)(spacing);
const useStyles = makeStyles(_ => ({
  customisedAlert: {
    marginBottom: "10px !important",
  }
}));

export const ChangePassword = () => {
  const { token } = useParams();  
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [additonalErrorMessage, setAdditonalErrorMessage] = useState('');
  const [additonalSuccessMessage, setAdditonalSuccessMessage] = useState('');
  const [isChangePassworsSuccess, setIsChangePassworsSuccess] = useState(false);
  const { translation } = useSelector(state => state.languageReducer);  

  const validationSchema = () =>
    Yup.object({
      newPassword: Yup.string().max(255).required(translation.New_Password_Required)
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#~`!@#$%^&*()_+=[{};:|.?,-])[A-Za-z\d#~`!@#$%^&*()_+=[{};:|.?,-]{8,30}$/,
      translation.Password_Not_Meeting_Standards),      
      confirmPassword: Yup.string().max(255).required(translation.Confirm_Password_Required).oneOf([Yup.ref('newPassword'), null], translation.New_password_matching_hint)
    });  

  const handleResetPassword = (values, { setErrors, setStatus, setSubmitting }) => {
    setLoader(true);
    tryChangePassword(values, { setErrors, setStatus, setSubmitting });
  };

  const tryChangePassword = async (values, { setErrors, setStatus, setSubmitting }) => {
    let message;
    try {
      await changePassword(
        {
          token: token,
          newPassword: values.newPassword
        },
      );
      setLoader(false);
      setIsChangePassworsSuccess(true);
      setAdditonalSuccessMessage(translation.Password_Changed)
    } catch (error) {
      let erroMsg = error.toString();
      if (erroMsg == 'Error: 400') {
        message = translation.Password_Not_Meeting_Standards;        
      } else if (erroMsg == 'Error: 404') {
        message = translation.Invalid_Token_Link;         
      } else if (erroMsg == 'Error: Not Found') {
        message = translation.Invalid_Token_Link;        
      } else if (erroMsg.indexOf('Error: 401') > -1) {
        message = translation.Token_Expired;        
      } else {
        message = translation.Invalid_Token_Link;     
      }
      setLoader(false);
      setStatus({ success: false });
      setErrors({ submit: message });
      setAdditonalErrorMessage(message);
      setSubmitting(false);
    }
  };

  return (
    <div className="login">
      <img className="login--logo" src={logo} alt="logo" />
      <div className="login--connect">
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {!isChangePassworsSuccess ?
                <>
                  <Typography
                    className="login--connect--typography login--connect--typography--extended"
                    component="h1"
                    variant="h5"
                  >
                    {translation?.Reset_Password}
                  </Typography>
                  <span>{translation?.Change_Password_Hint}</span>
                  <Formik
                    initialValues={{
                      submit: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleResetPassword}
                  >
                    {loader ? (
                      <Loader />
                    ) : (
                      ({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                      }) => (
                        <Box
                          component="form"
                          noValidate
                          sx={{ mt: 1 }}
                          onSubmit={handleSubmit}
                        >
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            name="newPassword"
                            variant="outlined"
                            label={translation?.New_Password}
                            value={values.newPassword}
                            error={Boolean(touched.newPassword && errors.newPassword)}
                            helperText={touched.newPassword && errors.newPassword}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            name="confirmPassword"
                            variant="outlined"
                            label={translation?.Confirm_Password}
                            value={values.confirmPassword}
                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />

                          <Alert mb={5} severity="info" className={classes.customisedAlert}>
                            <p className="password--hint--text" dangerouslySetInnerHTML={{__html: translation.Password_Matching_Criterias}}></p>
                          </Alert>
                          {additonalErrorMessage != '' && (
                            <Alert mb={5} severity="error" className={classes.customisedAlert}>
                              <p className="password--hint--text" dangerouslySetInnerHTML={{__html: additonalErrorMessage }}></p>
                            </Alert>
                          )}

                          <Button
                            className="login--connect--button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isSubmitting}
                            onClick={(e) => {
                              AuthGuard(e);
                            }}
                          >
                            {translation?.Reset_Password_Btn_Text}
                          </Button>

                          <Grid container>
                            <Grid item xs>
                              <MultiLanguage isFromLogin={true} />
                            </Grid>
                          </Grid>
                        </Box>
                      )
                    )}
                  </Formik>  
                </> : 
                <>
                  {additonalSuccessMessage != '' && (
                    <Alert mb={5} severity="success" className={classes.customisedAlert}>
                      <p className="password--hint--text" dangerouslySetInnerHTML={{__html: translation.Password_Changed}}></p>
                    </Alert>
                  )}  
                  <Grid container>
                    <Grid item xs>
                      <MultiLanguage isFromLogin={true} />
                    </Grid>
                  </Grid>
                </>
              }              
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default ChangePassword;
