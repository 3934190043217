// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Search page *                                                 *
 * ?                Search will be where a user can search any conference contains in the database              *
 * ?                      Right now (04/27) only the title/name/keywords are searchable                         *
 * ?                      The rendering is using the "Row" components BUT                                       *
 * !           Due to some styling changes between the homepage and search page a copy of row has been made     *
 * !                      The name of the component is "videoCard"                                              *
 *                        TODO: Change the component "Row" to be able to use it everywhere with more props      *
 */
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { useTracking } from "react-tracking";

import LargeScreenSearch from "../components/searchMobileComponents/LargeScreenSearch";
import MobileScreenSearch from "../components/searchMobileComponents/MobileScreenSearch";
import MediumScreenSearch from "../components/searchMobileComponents/MediumScreenSearch";
import useBreakpoints from '../hooks/UseBreakpoint';

import requests from "../components/axios/Requests";
import { getSearch, getTags } from "../servicesRedux/SearchService";
import { getAllSpeakers } from "../servicesRedux/SpeakerService";
// import { GetAllSpeakersAction } from "../redux/action/SpeakerAction";
import { HasLoadedAction } from "../redux/action/PaginationAction";
import { GetCategoriesAction, GetLanguagesAction } from "../redux/action/GlobalActions";
import { getCategories, getLanguages } from "../servicesRedux/GlobalService.js";
import { SearchRelated } from "../redux/action/RelatedVideoAction";
import { GetSearchedValue } from "../redux/action/SearchAction";
import { setRunAIComparisonAction, setAIResultAction, setIsGenerating, setCanRunComparisonAction } from "../redux/action/ExploreAiAction";
import Constants from "../components/constants/Constants";

import "../theme/homepage/Homepage.scss";
import "../theme/search/Search.scss";

const Research = () => {
  const limit = 10;
  const scrollFixedValue = 0;
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { searchedValue } = useSelector(state => state.searchReducer);
  // const { speakers } = useSelector(state => state.speakerReducer);
  const [speakers, setSpeakers] = useState([]);
  const { loadNext } = useSelector(state => state.paginationReducer);
  const { translation } = useSelector(state => state.languageReducer);
  const { relatedVideoTags, searchRelated } = useSelector(state => state.relatedVideoReducer);
  const { userRedux } = useSelector(state => state.authentificationReducer);
  const [, setResearchBar] = useState("");
  const [checkState, setCheckState] = useState(false);
  const [currentScrollValue, setCurrentScrollValue] = useState(scrollFixedValue);
  const [currentPageTags, setCurrentPageTags] = useState(1);
  const [totalPageTags, setTotalPageTags] = useState(0);
  const [dropDownTags, setDropDownTags] = useState([]);
  const [tagsFilterSearch, setTagsFilterSearch] = useState('');
  const [previousParams, setPreviousParams] = useState([]);
  const [formData, setFormData] = useState([]);
  const userId = Cookies.get("userID");
  const [pagination, setPagination] = useState({ pageNumber: 1, totalPages: 1, totalItems: 100 });
  const [content, setContent] = useState([]);
  const point = useBreakpoints();
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [totalSearchItems, setTotalSearchItems] = useState(0);
  const [checkedState, setCheckedState] = useState([true, true, true]);
  const tracking = useTracking();
  const { myInfo } = useOutletContext();
  const [isPageFirstLoad, setIsPageFirstLoad] = useState(true);
  const [speakerValue, setSpeakerValue] = useState("");

  const selectedContents = useSelector((state) => state.exploreAiReducer.selectedContents);
  const runAIcomparison = useSelector((state) => state.exploreAiReducer.runAIcomparison);
  const aiResult = useSelector((state) => state.exploreAiReducer.aiResult);
  const canRunComparison = useSelector((state) => state.exploreAiReducer.canRunComparison)

  const [isDone, setIsDone] = useState(false);

  const [messages, setMessages] = useState([]);

  useEffect(() =>{
    if (messages.length !== 0 && aiResult !== '') {
      dispatch(setIsGenerating(!isDone))
    }
  }, [messages, isDone]);

  useEffect(() => {

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        const catalogItemIds = selectedContents.map(content => content.item.catalogItemId);
        const response = await fetch(requests.AiStreaming, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(catalogItemIds),
          credentials: 'include',
          signal, // Pass the AbortSignal to the fetch request
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let messageBuffer = '';
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value);
          messageBuffer += chunk;
          const messages = messageBuffer.split('~n~');
          messageBuffer = messages.pop();
          for (const message of messages) {
            if (message.startsWith('data: ')) {
              const messageContent = message.substring(6);
              if (messageContent.trim() !== '[DONE]') {
                setMessages((prevMessages) => [...prevMessages, messageContent]);
              } else if (messageContent.trim() === '[DONE]') {
                setIsDone(true);
                dispatch(setCanRunComparisonAction(false));
              }
            }
          }
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Request aborted');
        } else {
          console.error('Error:', error);
        }
      }
    };
  
    if (runAIcomparison && canRunComparison) {
      setMessages([])
      fetchData();
    }

    return () => {
      controller.abort(); // Abort the request when the component unmounts or runAIcomparison changes
    };

  }, [selectedContents, runAIcomparison, canRunComparison]);

  useEffect(() => {
    // Join all messages into a single string, replacing newline and carriage return characters with two spaces and a newline
    // const newMessages = messages.map(message => message === "" ? "\n" : message);
    let combinedText = messages.join("");

    dispatch(setAIResultAction(combinedText))
  }, [messages]);

  useEffect(() => {
      
    if(!runAIcomparison){
      dispatch(setAIResultAction(""))
      setMessages([])
    }
    
  }, [runAIcomparison])

  useEffect(() => {
    document.body.classList.add('home_layout--search');
    return () => {
      document.body.classList.remove('home_layout--search');
    }
  }, []);

  useEffect(() => {
    content.length > 0 && dispatch(HasLoadedAction(content.length));
  }, [content]);

  useEffect(() => {
    let keywords = []; 
    if (isPageFirstLoad) {
      dispatch(HasLoadedAction(false));
      const pageParams = {
        limit: limit,
        page: 1,
      };
      setIsLoading(true);
      const tempFormData = {...formData, mediatypesList: [1, 2, 3]};
      getSearch(requests.GetResearch, searchedValue, tempFormData, pageParams, userRedux.preferredLanguageIds).then(item => {
        if (item.currentPage !== undefined) {
          setTotalSearchItems(item.totalItems);
          setPagination({ ...pagination, totalPages: item.totalPages, pageNumber: item.currentPage, totalItems: item.totalItems });
          const pageParams = {
            limit: limit,
            page: item.currentPage,
          };
          setPreviousParams(pageParams);
        }
        setContent(item.data[0].searchContentResponse);
        setFormData(tempFormData);
        setTotalPageTags(item.data[0].tagsResponses);
        setIsLoading(false);
        setIsPageFirstLoad(false);
        formData.keywordsList?.map(item => keywords.push(item.id));
        tracking.trackEvent(
          {
            UserEventId: Number(Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.SEARCH.enum),
            Note1: searchedValue,
            Note2: (formData.interestsList === undefined ? "" : " #$# InterestsList=" + formData.interestsList)
                            + (keywords[0] ? " #$# KeyWordsList=" + keywords : " #$# KeyWordsList=" + formData.keywordsList)
                            + (formData.publicationList === undefined ? "" : " #$# PublicationDate=" + formData.publicationList)
                            + (formData.recommendedList === undefined ? "" : " #$# RecommendedList=" + formData.recommendedList)
                            + (formData.speakerList === undefined ? "" : " #$# SpeakerList=" + formData.speakerList)
                            + (formData.durationsearchList === undefined ? "" : " #$# DurationsearchList=" + formData.durationsearchList.durationStart + "-" + formData.durationsearchList.durationEnd)
                            + (formData.mediatypesList === undefined ? "" : " #$# MediatypesList=" + formData.mediatypesList)
                            + (formData.localization === undefined ? "" : " #$# Localization=" + formData.localization),
            
          }
        );
      });
    }

    getLanguages(requests.LoadISOEntries).then(item => {      
      dispatch(GetLanguagesAction(item));      
      setLanguages(item);
    });
    getAllSpeakers(requests.getAllSpeakers, speakerValue).then(item => {
      // dispatch(GetAllSpeakersAction(item.data));
      setSpeakers(item.data);
    });
    if (searchRelated) {
      dispatch(GetSearchedValue(""));
      handleformData([relatedVideoTags], "tags");
      dispatch(SearchRelated(false));
    }    
  }, []);

  useEffect(() => {
    if (speakerValue.length >= 2) {
      getAllSpeakers(requests.getAllSpeakers, speakerValue).then(item => {
        // dispatch(GetAllSpeakersAction(item.data));
        setSpeakers(item.data);
      });
    } else {
      getAllSpeakers(requests.getAllSpeakers).then(item => {
        // dispatch(GetAllSpeakersAction(item.data));
        setSpeakers(item.data);
      });
    }   
  }, [speakerValue]);  

  useEffect(() => {
    let keywords = []; 
    dispatch(HasLoadedAction(false));
    const pageParams = {
      limit: limit,
      page: 1,
    };
    if ((formData.mediatypesList && formData.mediatypesList.length !== 0) && (!isPageFirstLoad)) {
      setIsLoading(true);
      getSearch(requests.GetResearch, searchedValue, formData, pageParams, userRedux.preferredLanguageIds).then(item => {
        if (item.currentPage !== undefined) {
          setTotalSearchItems(item.totalItems);
          setPagination({ ...pagination, totalPages: item.totalPages, pageNumber: item.currentPage, totalItems: item.totalItems });
          const pageParams = {
            limit: limit,
            page: item.currentPage,
          };
          setPreviousParams(pageParams);
        }
        setContent(item.data[0].searchContentResponse);
        setTotalPageTags(item.data[0].tagsResponses);
        setIsLoading(false);
        formData.keywordsList?.map(item => keywords.push(item.id));
        tracking.trackEvent(
          {
            UserEventId: Number(Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.SEARCH.enum),
            Note1: searchedValue,
            Note2: (formData.interestsList === undefined ? "" : " #$# InterestsList=" + formData.interestsList)
                            + (keywords[0] ? " #$# KeyWordsList=" + keywords : " #$# KeyWordsList=" + formData.keywordsList)
                            + (formData.publicationList === undefined ? "" : " #$# PublicationDate=" + formData.publicationList)
                            + (formData.recommendedList === undefined ? "" : " #$# RecommendedList=" + formData.recommendedList)
                            + (formData.speakerList === undefined ? "" : " #$# SpeakerList=" + formData.speakerList)
                            + (formData.durationsearchList === undefined ? "" : " #$# DurationsearchList=" + formData.durationsearchList.durationStart + "-" + formData.durationsearchList.durationEnd)
                            + (formData.mediatypesList === undefined ? "" : " #$# MediatypesList=" + formData.mediatypesList)
                            + (formData.localization === undefined ? "" : " #$# Localization=" + formData.localization),
            
          }
        );
      });
    }
  }, [searchedValue, checkState, checkedState, userRedux]);

  useEffect(() => {
    loadNext && triggerPagination();
  }, [loadNext]);

  useEffect(() => {
    dropDownTagsFn();
  }, [tagsFilterSearch]);

  const propogateSpeakersAPI = e => setSpeakerValue(e);

  const onChangeCategoryInput = e => {    
    let temp = [];
    const searchedValue = e.target.value.toLowerCase();    
    if (searchedValue !== "") {
      if (localStorage.getItem("language") == "En") {
        temp = categories.filter(f => f.label_En.toLowerCase().indexOf(searchedValue) > -1);  
      } else {
        temp = categories.filter(f => f.label_Fr.toLowerCase().indexOf(searchedValue) > -1);  
      }    
      setCategories(temp);
    } else {
      triggerResetTheCategoriesList();
    }
  };

  const triggerPagination = () => {
    dispatch(HasLoadedAction(false));
    let pageNumberValue = pagination.pageNumber;
    if (pagination.totalPages > pagination.pageNumber) {
      pagination.totalPages && setPagination({ ...pagination, pageNumber: pagination.pageNumber + 1 });
      pageNumberValue = pagination.totalPages >= (pagination.pageNumber + 1) ? (pagination.pageNumber + 1) : 1
    }
    const pageParams = {
      limit: limit,
      page: pageNumberValue,
    };
    const comparison = previousParams.page !== pageNumberValue;
    const contentCheck = content.length !== 0 ? (pagination.totalItems ? content.length < pagination.totalItems : true) : true;
    if (
      (pagination.totalPages ? pagination.totalPages >= pageNumberValue : true) &&
      comparison &&
      contentCheck
    ) {
      getSearch(requests.GetResearch, searchedValue, formData, pageParams, userRedux.preferredLanguageIds).then(item => {
        if (item.currentPage !== undefined) {
          setPagination({ ...pagination, totalPages: item.totalPages, pageNumber: item.currentPage, totalItems: item.totalItems });
          const pageParams = {
            limit: limit,
            page: item.currentPage,
          };
          setPreviousParams(pageParams);
        }      
        setContent(content => [...content, ...item.data[0].searchContentResponse]);
        setTotalPageTags(item.data[0].tagsResponses);
      });
      let keywords = [];
      formData.keywordsList?.map(item => keywords.push(item.id));
      tracking.trackEvent(
        {
          UserEventId: Number(Constants.UserEvent.CLICK.enum),
          UserActionId: Number(Constants.UserAction.SEARCH.enum),
          Note1: searchedValue,
          Note2: (formData.interestsList === undefined ? "" : " #$# InterestsList=" + formData.interestsList)
                            + (keywords[0] ? " #$# KeyWordsList=" + keywords : " #$# KeyWordsList=" + formData.keywordsList)
                            + (formData.publicationList === undefined ? "" : " #$# PublicationDate=" + formData.publicationList)
                            + (formData.recommendedList === undefined ? "" : " #$# RecommendedList=" + formData.recommendedList)
                            + (formData.speakerList === undefined ? "" : " #$# SpeakerList=" + formData.speakerList)
                            + (formData.durationsearchList === undefined ? "" : " #$# DurationsearchList=" + formData.durationsearchList.durationStart + "-" + formData.durationsearchList.durationEnd)
                            + (formData.mediatypesList === undefined ? "" : " #$# MediatypesList=" + formData.mediatypesList)
                            + (formData.localization === undefined ? "" : " #$# Localization=" + formData.localization),
          
        }
      );
    }
  };

  const handleformData = async (childformData, id) => {
    let tempFormData = formData;
      switch (id) {
        case 1:
          tempFormData.recommendedList = childformData;
          break;
        case 2:
          tempFormData.interestsList = childformData;
          break;
        case 3:
          tempFormData.speakerList = childformData;
          break;
        case 4:
          tempFormData.publicationList = childformData;
          break;
        case 5:
           tempFormData.dateList = childformData;
          break;
        case 6:
            tempFormData.localization = childformData;
          break;
        case "tags":
           tempFormData.keywordsList = childformData;
          break;
        case 7:
           tempFormData.durationsearchList = childformData;
          break;
        case 8:
           tempFormData.mediatypesList = childformData;
          break;
        case 9:
          tempFormData.keywordsList = childformData;
          break;
        case 10:
          tempFormData.language = childformData;          
          break;
        case 11:
            tempFormData.discoverExpertACTSearch = childformData;          
          break; 
    }  
    for (let key in tempFormData) {
      if (tempFormData[key].length === 0) {
        delete tempFormData[key];
      }
    }
    setFormData(tempFormData);
  };

  const handleCheckInfo = async val => setCheckState(val);

  const scrollParams = async scrollValue => {
    if (
      scrollValue > currentScrollValue &&
      scrollValue - currentScrollValue > 800
    ) {
      setCurrentScrollValue(scrollValue);
      if (currentPageTags <= totalPageTags) {
        getTags(requests.GetTags, tagsFilterSearch, 100, currentPageTags).then((item) => {
          setCurrentPageTags(currentPageTags + 1);
          const temp = dropDownTags;
          temp.push(...item.data);
          const dataTemp = { ...item, data: temp };
          setDropDownTags(dataTemp.data);
        });
      }
    }
  };

  const dropDownTagsFn = async () => {
    getTags(requests.GetTags, tagsFilterSearch, 100, 1).then(item => {
      setCurrentPageTags(currentPageTags + 1);
      setDropDownTags(item.data);
    });
  };

  const setResearch = value => setResearchBar(value);

  const triggerResetTheCategoriesList = () => {
    getCategories(requests.GetCategories).then(item => {
      dispatch(GetCategoriesAction(item));      
      setCategories(item.all);
    });    
  };

  const renderSearchPageContentOnBreakPoint = mode => {
    switch(mode) {
      case "xs":
      case "sm":
        return (
          <MobileScreenSearch 
            isLoading={isLoading}
            translation={translation}
            speakers={speakers}
            handleformData={handleformData}
            handleCheckInfo={handleCheckInfo}
            setResearch={setResearch}
            searchedValue={searchedValue}
            dropDownTags={dropDownTags}
            scrollParams={scrollParams}
            setTagsFilterSearch={setTagsFilterSearch}
            tags={totalPageTags}
            content={content}
            location={location}
            userId={userId}
            categories={categories}
            languages={languages}
            totalSearchItems={totalSearchItems}
            onChangeCategoryInput={onChangeCategoryInput}
            triggerResetTheCategoriesList={triggerResetTheCategoriesList}
            checkedState={checkedState}
            setCheckedState={setCheckedState}
            myInfo={myInfo}
            checkState={checkState}
            propogateSpeakersAPI={propogateSpeakersAPI}
          />);
      case "md":
      case "ml":
        return (
          <MediumScreenSearch 
            isLoading={isLoading}
            translation={translation}
            speakers={speakers}
            handleformData={handleformData}
            handleCheckInfo={handleCheckInfo}
            setResearch={setResearch}
            searchedValue={searchedValue}
            dropDownTags={dropDownTags}
            scrollParams={scrollParams}
            setTagsFilterSearch={setTagsFilterSearch}
            tags={totalPageTags}
            content={content}
            location={location}
            userId={userId}
            categories={categories}
            languages={languages}
            totalSearchItems={totalSearchItems}
            onChangeCategoryInput={onChangeCategoryInput}
            triggerResetTheCategoriesList={triggerResetTheCategoriesList}
            checkedState={checkedState}
            setCheckedState={setCheckedState}
            myInfo={myInfo}
            checkState={checkState}
            propogateSpeakersAPI={propogateSpeakersAPI}
          />);      
      case "lg":
        return (
          <LargeScreenSearch 
            isLoading={isLoading}
            translation={translation}
            speakers={speakers}
            handleformData={handleformData}
            handleCheckInfo={handleCheckInfo}
            setResearch={setResearch}
            searchedValue={searchedValue}
            dropDownTags={dropDownTags}
            scrollParams={scrollParams}
            setTagsFilterSearch={setTagsFilterSearch}
            tags={totalPageTags}
            content={content}
            location={location}
            userId={userId}
            categories={categories}
            languages={languages}
            totalSearchItems={totalSearchItems}
            onChangeCategoryInput={onChangeCategoryInput}
            triggerResetTheCategoriesList={triggerResetTheCategoriesList}
            checkedState={checkedState}
            setCheckedState={setCheckedState}
            myInfo={myInfo}
            checkState={checkState}
            propogateSpeakersAPI={propogateSpeakersAPI}
          />);
    }
  };

  return (
    <div className="app">      
      {renderSearchPageContentOnBreakPoint(point)}      
    </div>
  );
}

export default Research;
