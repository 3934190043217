//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * VideoEnLien component *                                       *
 * !                                       Naming should be changed ASAP                                        *
 * ?                                 VideoEnLien is used in the conference page                                 *
 * ?               It's use like a youtube design, showing the recommendation based on the current video        *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./LinkedArticle.scss";
import {
  getConference,
  RelatedVideos,
} from "../../servicesRedux/ConferenceService.js";
import { SearchRelated } from "../../redux/action/RelatedVideoAction";
import requests from "../axios/Requests";
import { useNavigate } from "react-router-dom";

const LinkedArticle = (props) => {
  const { contentId, translation, setLinkedVideoHasLoaded } = props;
  const dispatch = useDispatch();
  const [content, setContent] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();

  const { userRedux } = useSelector(state => state.authentificationReducer);

  const pageParams = {
    "limit": 4,
    "page": totalPages >= pageNumber ? pageNumber : 1,
  };

  const triggerPagination = () => {
    if ((totalPages > pageNumber)) {
      setPageNumber(pageNumber + 1);
      setLoad(!load)
    }
  }

  useEffect(() => {
    RelatedVideos(requests.RelatedVideos, contentId, pageParams.limit, pageParams.page, userRedux.preferredLanguageIds).then((item) =>
      setContent(item));
  }, []);

  //   useEffect(() => {
  //     RelatedVideos(requests.RelatedVideos, conferenceId, pageParams.limit, pageParams.page).then((item) => {
  //       const contentTemp = content?.data?.length===0 ? item : {...item, data : (content?.data?.map((item) => item)).concat(item?.data)};
  //       contentTemp && setContent(contentTemp);
  //     });
  // }, [load]);

  useEffect(() => {
    if (content.currentPage !== undefined) {
      setTotalPages(content.totalPages);
      setPageNumber(content.currentPage);
    }

  }, [content]);

  function redirectToSearch() {
    dispatch(SearchRelated(true));
    navigate("/search");
  }
  content?.data && setLinkedVideoHasLoaded(true);
  if (contentId !== null && contentId !== undefined) {
    return (
      <div className={content?.data ? "linkedArticle" : "linkedVideo-disable"} style={{
        paddingTop: '0 !important',
        marginTop: '-7px !important'
      }}>
        {contentId !== null &&
          contentId !== undefined ? (
          <h3 className="linkedVideo--styling">{translation.Conference_RelatedArticles}</h3>
        ) : (
          <></>
        )}

        {content?.data?.map((item, index) => {
          return (
            <div className="relatedVideo" key={index}>
              <div>
                <Link to={`/conference/${item.catalogItemId}`}>
                  <img
                    src={item.image}
                    className="highlights--icon"
                    alt="icon"
                  />
                </Link>
              </div>
              <div className="relatedVideo__background">
                <Link to={`/conference/${item.catalogItemId}`}>

                  <div
                    className="relatedVideo--text"
                    style={{ cursor: "pointer" }}
                  >
                    {item.conferenceName}
                  </div>
                </Link>

                <Link
                  to={`/channel/${item?.channelId}`}
                  src="author__name"
                  className="relatedVideo--author"
                >
                  {item.firstName} {item.lastName}
                </Link>
                <div className="relatedVideo__viewBackground">
                  <div>
                    <p className="relatedVideo__viewBackground--view">
                      {translation.Conference_Views.replace('@number@', item.viewCount)}
                    </p>
                  </div>
                  <div>
                    <p className="relatedVideo__viewBackground--recommended">
                      {translation.VideoCard_Rating} {item?.rating}/10
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {contentId !== null &&
          contentId !== undefined ? (
          <button onClick={redirectToSearch} className="linkedVideo--button">{translation.Conference_SeeMore}</button>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default LinkedArticle;
