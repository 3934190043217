// SelectAIExploreContent.jsx
import React from 'react';
import './SelectAIExploreContent.scss'; // Ensure this is the correct path to your styling file
import iButton from '../../assets/icons/Icons/iButton.svg';
import { useSelector, useDispatch } from "react-redux";
import { setAIResultAction, setRunAIComparisonAction, setCanRunComparisonAction } from "../../redux/action/ExploreAiAction";

const SelectAIExploreContent = ({onClick, doesResultExist, translation}) => {
    const dispatch = useDispatch();
    const selectedContents = useSelector((state) => state.exploreAiReducer.selectedContents);

    const handleRunAIcomparison = () => {
        if(selectedContents.length === 0 || selectedContents.length === 1) return;
        dispatch(setAIResultAction(""))
        dispatch(setCanRunComparisonAction(true));
        dispatch(setRunAIComparisonAction(true));
    }

    return (
        <div className="selectAIExploreContent">
            <div className="contentTitle">
                <div>
                    <span className="title">{translation.select_content_ai}</span>
                    {!doesResultExist && <button className="closeButton" onClick={onClick}>✕</button>}
                </div>
                <div className="infoAndSelected">
                    <div className='selectedCount_container'>
                        <span className="selectedCount">{translation.selected} </span>
                        <span className="selectedCount_number"> ({selectedContents.length})</span>
                    </div>     
                    <div className='button_info_container'>
                        <button onClick={handleRunAIcomparison} className="nextButton" style={{ background: selectedContents.length > 1 ? "#514F4F" : "#b4b0a7" }}>{selectedContents.length > 1 ? "Go 🡒" : "🡒"}</button>
                        {/* <div>
                            <img src={iButton} alt="iButton" />
                        </div> */}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SelectAIExploreContent;
