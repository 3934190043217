import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Item from "@mui/material/Grid";

import requests from "../axios/Requests";
import {
  postContentIntoPlaylist,
  deleteContentFromPlaylist,
} from "../../servicesRedux/PlaylistService";
import { GetWatchLaterCountAction } from "../../redux/action/GlobalActions";
import {
  isLikedStateAction,
  isSubscribedStateAction,
  checkAndUpdateLikesCountAction
} from "../../redux/action/PlaylistAction";

const PlaylistCheckbox = props => {
    const dispatch = useDispatch();
    const { 
        likes,
        type, 
        triggerTheRealTimeUpdateOnLikes, 
        contentId, 
        playlists, 
        setPlaylists, 
        playlistStandard, 
        setPlaylistStandard,
        setIsLoading
    } = props;
    const { watchLaterCount } = useSelector(state => state.globalReducer);
    const { isSubscribedState, isLikedState } = useSelector(state => state.playlistReducer);
    const [checked, setChecked] = useState(props.checked);
    const [toBeAdded, setToBeAdded] = useState(props.toAdd);
    const [inList, setInList] = useState(false);
    const [, setLoadingCheck] = useState(false);

    useEffect(() => {
      if (isLikedState !== undefined && isLikedState !== null && type === 3) {
        setChecked(isLikedState);
      }
    }, [isLikedState]);

    useEffect(() => {
      if (
        isSubscribedState !== undefined &&
        isSubscribedState !== null &&
        type === 2
      ) {
        setChecked(isSubscribedState);
      }
    }, [isSubscribedState]);

    useEffect(() => {
      if (toBeAdded) {
        postContentIntoPlaylist(
          requests.PostContentIntoPlaylist,
          props.playlistId,
          contentId
        ).then(() => {
          setInList(true);
          setToBeAdded(false);
          const newPlaylists = playlists.map(playlist => {
            if (playlist.id === props.playlistId) {
              playlist.toAdd = false;
            }
            return playlist;
          });
          setPlaylists(newPlaylists);
        });
      }
    }, []);

    const handleChange = event => {
      setLoadingCheck(true);
      setIsLoading(true);
      if (event.target.checked && !inList) {
        postContentIntoPlaylist(
          requests.PostContentIntoPlaylist,
          props.playlistId,
          contentId
        )
        .then(() => {
          setInList(true);
          const newPlaylists = playlists.map(playlist => {
            if (playlist.id === props.playlistId) {
              playlist.checked = true;
            }
            return playlist;
          });
          setPlaylists(newPlaylists);
          setLoadingCheck(false);
          setIsLoading(false);
        })
        .catch(error => {
          if (error.response.status === 409);
          setLoadingCheck(false);
        });

        if (props.standard) {
          const newPlaylists = playlistStandard.map(playlist => {
            if (playlist.id === props.playlistId) {
              playlist.checked = true;
            }
            return playlist;
          });
          setPlaylistStandard(newPlaylists);
        }

        if (type === 2) {
          dispatch(GetWatchLaterCountAction(watchLaterCount + 1));
          dispatch(isSubscribedStateAction(true));          
        }

        if (type === 3) {
          dispatch(checkAndUpdateLikesCountAction(likes));          
          dispatch(isLikedStateAction(true));
          triggerTheRealTimeUpdateOnLikes(true);
        }
      } else {
        deleteContentFromPlaylist(
          requests.DeleteContentFromPlaylist,
          props.playlistId,
          contentId
        ).then(() => {
          setInList(false);

          const newPlaylists = playlists.map(playlist => {
            if (playlist.id === props.playlistId) {
              playlist.checked = false;
            }
            return playlist;
          });
          setPlaylists(newPlaylists);
          setIsLoading(false);
        });
        setLoadingCheck(false);

        if (props.standard) {
          const newPlaylists = playlistStandard.map((playlist) => {
            if (playlist.id === props.playlistId) {
              playlist.checked = false;
            }
            return playlist;
          });
          setPlaylistStandard(newPlaylists);
        }

        if (type === 2) {
          dispatch(GetWatchLaterCountAction(watchLaterCount - 1));
          dispatch(isSubscribedStateAction(false));
        }

        if (type === 3) {
          dispatch(checkAndUpdateLikesCountAction(likes - 1));
          dispatch(isLikedStateAction(false));
          triggerTheRealTimeUpdateOnLikes(false);
        }
      }
      setChecked(event.target.checked);
    };

    return (
      <Item xs className={`description__playlist--modal__checkbox-grid description__playlist--modal__checkbox-grid-dynamic-${props.playlistName.replace(/\s/g, '')}`}>       
        <FormControlLabel
          sx={{ margin: 0, width: "100%" }}
          className={`description__playlist--modal__checkbox description__playlist--modal__checkbox_dynamic_${props.playlistName.replace(/\s/g, '')}`}
          control={<Checkbox defaultChecked={checked} onChange={handleChange} />}
          label={props.playlistName}
        />        
      </Item>
    );
};

export default PlaylistCheckbox;