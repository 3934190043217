import React, { useState, useEffect } from "react"
import playIcon from "../../assets/images/play.svg";

import useBreakpoints from "../../hooks/UseBreakpoint";

import "./Video.scss"

const DEFAULT_RATIO = [16, 9];

const VideoThumbnail = ({thumbnailURL, setVideoHasLoaded, externalContentLink}) => {
    const point = useBreakpoints();

    const getRatioStyles = ratio => {
        if (!ratio) {
            return {};
        }
        return { 
            position: 'relative',
            paddingBottom: (ratio[1] / ratio[0]) * 100 + "%" 
        };
    };

    useEffect(() => {
        setVideoHasLoaded(true);
    }, []);

    return (
        <div
            className={"video__thumbnail--container"}
            style={getRatioStyles(DEFAULT_RATIO)}
        >
            <div className="video__logo displayed video__thumbnail-play" 
                  onClick={() => window.open(externalContentLink, '_blank')}>
                <div className="video__button">
                    <img src={playIcon} alt="play" className="banner--play" />
                </div>
            </div>
            <img 
                src={thumbnailURL} 
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover' // Adjust this as needed
                }}
            />
        </div>
    )
}

export default VideoThumbnail;
