//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Dashboard Navbar component *                                  *
 * ?                                 Dashboard Navbar is a global component used on everypage (top of the page  *
 * ?                          It contains 3 things : 6 topics of interest / research bar / administration       *
 * ?                                    It's sticky and will always be present on the page                      *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";

import useBreakpoints from '../../hooks/UseBreakpoint';
import { theme } from "../../hooks/theme";
import DesktopNavigation from "./DashboardApp/DesktopNavigation";
import MobileNavigation from "./DashboardApp/MobileNavigation";

import "./NavBar.scss";

const NavBar = ({ 
  newProfileImage, 
  updateQuery, 
  updateCustomerQuery, 
  updateDashboardUsersOnCompanies, 
  tenant, 
  roles, 
  sendToContextTagsArray,
  sendToContextSpeakersArray,
  sendToContextCategoriesArray,
  sendMediaTypesToContext,
  sendToContextSubCategoriesArray,
  sendToContextCurationArray,
  sendStartAndEndDateToContext,
  sendTagsArrayToContext,
  triggerMediaContentAPI,
  sendEmptyContentPayloadToContext,
  updateTheContentAdminStatusForLeftMenu,
  sendContextEmptyContentParamsToState,
  sendRequestToResetTheLeftMenu
}) => {
  const navigate = useNavigate();
  const styles = {
    toolbarMargin: {
      ...theme.mixins.toolbar,
      marginBottom: "3em",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "1.25em",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "2em",
      },
      [theme.breakpoints.down("md")]: {
        marginBottom: "2em",
      },
      [theme.breakpoints.down("lg")]: {
        marginBottom: "1.25em",
      },
      [theme.breakpoints.down("xl")]: {
        marginBottom: "2em",
      },      
    },
    logo: {
      height: "8em",
      [theme.breakpoints.down("md")]: {
        height: "7em",
      },
      [theme.breakpoints.down("xs")]: {
        height: "5.5em",
      },
    },
    logoContainer: {
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    tabs: {
      marginLeft: "auto",
      "& .MuiButtonBase-root.MuiTab-root": {
        fontSize: 20,
      },
      "& .Mui-selected": {
        backgroundColor: "transparent",
        color: "#000",
        opacity: 0.7,
        borderRadius: 2,
      },
    },
    tab: {
      ...theme.typography.tab,
      minWidth: 10,
      marginLeft: "25px",
      color: "white",
    }, 
    hamburgerMenuIcon: {
      height: "50px",
      width: "50px",
    },
    menuIconContainer: {
      marginLeft: "auto",
      color: '#000',
      "&:hover": {
        opacity: 1,
      },
    },
    appbar: {
      zIndex: theme.zIndex.modal + 1,
      backgroundColor: 'transparent',
      color: '#000',
      width: '100%',
      boxShadow: 'none',
      padding: '0 70px 0 70px ',
      zIndex: 1
    },
  };
  
  const point = useBreakpoints();

  const renderNavigationOnBreakPoint = mode => {
    switch(mode) {
      case "xs":
      case "sm":
      case "md":
      case "ml":    
        return (
          <MobileNavigation 
            roles={roles}
            tenant={tenant} 
            redirectTo={redirectTo}
            newProfileImage={newProfileImage} 
            updateQuery={updateQuery}
            updateCustomerQuery={updateCustomerQuery}
            updateDashboardUsersOnCompanies={updateDashboardUsersOnCompanies}
            sendToContextTagsArray={sendToContextTagsArray}
            sendToContextSpeakersArray={sendToContextSpeakersArray}
            sendToContextCategoriesArray={sendToContextCategoriesArray}
            sendMediaTypesToContext={sendMediaTypesToContext}
            sendToContextSubCategoriesArray={sendToContextSubCategoriesArray}
            sendToContextCurationArray={sendToContextCurationArray}
            sendStartAndEndDateToContext={sendStartAndEndDateToContext}
            sendTagsArrayToContext={sendTagsArrayToContext}
            triggerMediaContentAPI={triggerMediaContentAPI}
            sendEmptyContentPayloadToContext={sendEmptyContentPayloadToContext}
            updateTheContentAdminStatusForLeftMenu={updateTheContentAdminStatusForLeftMenu}
            sendContextEmptyContentParamsToState={sendContextEmptyContentParamsToState}
            sendRequestToResetTheLeftMenu={sendRequestToResetTheLeftMenu}
          />
        );
      case "lg":
      case "xl":
        return (
          <DesktopNavigation 
            roles={roles}
            tenant={tenant} 
            redirectTo={redirectTo}
            newProfileImage={newProfileImage} 
            updateQuery={updateQuery}
            updateCustomerQuery={updateCustomerQuery}
            updateDashboardUsersOnCompanies={updateDashboardUsersOnCompanies}
            sendToContextTagsArray={sendToContextTagsArray}
            sendToContextSpeakersArray={sendToContextSpeakersArray}
            sendToContextCategoriesArray={sendToContextCategoriesArray}
            sendMediaTypesToContext={sendMediaTypesToContext}
            sendToContextSubCategoriesArray={sendToContextSubCategoriesArray}
            sendToContextCurationArray={sendToContextCurationArray}
            sendStartAndEndDateToContext={sendStartAndEndDateToContext}
            sendTagsArrayToContext={sendTagsArrayToContext}
            triggerMediaContentAPI={triggerMediaContentAPI}
            sendEmptyContentPayloadToContext={sendEmptyContentPayloadToContext}
            updateTheContentAdminStatusForLeftMenu={updateTheContentAdminStatusForLeftMenu}
            sendContextEmptyContentParamsToState={sendContextEmptyContentParamsToState}
            sendRequestToResetTheLeftMenu={sendRequestToResetTheLeftMenu}
          />
        );
    }
  };

  const redirectTo = path => navigate(path);

  return (
    <>
      <AppBar
        sx={styles.appbar}
        color="secondary"
        elevation={9}
        position="relative"
      >
        <Toolbar className="dashboard__toolbar" disableGutters={true}>
          {renderNavigationOnBreakPoint(point)}                 
        </Toolbar>
      </AppBar>
    </>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;