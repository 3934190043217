const Constants = {
  MediaType: {
    NotFound: 0,
    Video: 1,
    Podcast: 2,
    PDF: 3,
  },
  ContentAdminStatus: {
    NOT_SET: {enum: 0, name: "NotSet", labelDashboard: "Not Set", labelStatus: "Not Set"},
    IN_PROGRESS: {enum: 1, name: "InProcess", labelDashboard: "In Progress", labelStatus: "In Progress"},
    SUBMITTED_FOR_REVIEW: {enum: 2, name: "SubmittedForReview", labelDashboard: "Submitted for Review", labelStatus: "Submitted for Review"},
    APPROVED: {enum: 3, name: "Approved", labelDashboard: "Approved (TopCream only)", labelStatus: "Approved"},
    REJECTED: {enum: 4, name: "Rejected", labelDashboard: "Rejected", labelStatus: "Rejected"},
    APPROVED_FOR_DISTRIBUTION: {enum: 5, name: "ApprovedForDistribution", labelDashboard: "Approved for Distribution (TopCream & all white label customers)", labelStatus: "Approved for Distribution"},
  },
  Roles : {
    ADMIN: "Admin",
    CURATOR: "Curator",
    CURATOR_MANAGER: "Curator-Manager",
    USER: "User",
    MANAGER: "Manager"
  },
  AccountType : {
    WhiteLabel: {enum: 1, label: "White Label"},
    Company: {enum: 2, label: "Company"},
    Individual: {enum: 3, label: "Individual"},
  },
  ContentProcessStatus :
  {
    DUPLICATE :{enum: 19, labelDashboard: "Duplicate", labelStatus: "Duplicate"},
  },
  ComplexityCheckboxText : {
    Discover: `Discover, is a content where you discover the basics, the fundamental concepts, 
    a pedagogical and simple, user-friendly explanation that explains the "need to know",
    equivalent of "for les nuls en france", for "dummies" in English.`,
    Expert: `Expert, goes much further, in details, explanation of the mechanisms, example on how the concept is used, it goes deeper into it.`,
    ACT: `ACT, is "lessons learnt on the field", HOW and WHY I did something innovative, revolutionary, new, surprising, the challenges, the outcome, results measured.. `,
  },
  UserAction :
  {
    NOT_SET : {enum: 0, labelDashboard: "NotSet ",  labelStatus :  "Not Set "},
    LOGIN : {enum: 1, labelDashboard: "Login ",  labelStatus :  "Login "},
    LOGOUT : {enum: 2, labelDashboard: "Logout ",  labelStatus :  "Logout "},
    UPDATE_PROFILE : {enum: 3, labelDashboard: "UpdateProfile ",  labelStatus :  "Update Profile "},
    VIEW_MEDIA : {enum: 4, labelDashboard: "ViewMedia ",  labelStatus :  "View Media "},
    RECOMMEND_MEDIA : {enum: 5, labelDashboard: "RecommendMedia ",  labelStatus :  "Recommend Media "},
    SHARE_MEDIA : {enum: 6, labelDashboard: "ShareMedia ",  labelStatus :  "Share Media "},
    LIKE_MEDIA : {enum: 7, labelDashboard: "LikeMedia ",  labelStatus :  "Like Media "},
    PLAY_MEDIA : {enum: 8, labelDashboard: "PlayMedia ",  labelStatus :  "Play Media "},
    ADD_TO_PLAYLIST : {enum: 9, labelDashboard: "AddToPlaylist ",  labelStatus :  "Add To Playlist "},
    SHARE_PLAYLIST : {enum: 10, labelDashboard: "SharePlaylist ",  labelStatus :  "Share Playlist "},
    ADD_COMMENT_TO_MEDIA : {enum: 11, labelDashboard: "AddCommentToMedia ",  labelStatus :  "Add Comment To Media "},
    READ_TRANSCRIPT : {enum: 12, labelDashboard: "ReadTranscript ",  labelStatus :  "Read Transcript "},
    VIEW_MEDIA_COMPLETED : {enum: 13, labelDashboard: "ViewMediaCompleted ",  labelStatus :  "View Media Completed "},
    UPDATE_COMMENT_TO_MEDIA : {enum: 14, labelDashboard: "UpdateCommentToMedia ",  labelStatus :  "Update Comment To Media "},
    SUGGESTION_FORM : {enum: 15, labelDashboard: "SuggestionForm",  labelStatus :  "Suggestion Form "},
    SEARCH : {enum: 16, labelDashboard: "Search",  labelStatus :  "Search "},
  },
  UserEvent : {
    NOTSET : {enum: 0, labelDashboard: "NotSet ",  labelStatus :  "NotSet "},
    CHANGE : {enum: 1, labelDashboard: "Change ",  labelStatus :  "Change "},
    CLICK : {enum: 2, labelDashboard: "Click ",  labelStatus :  "Click "},
    MOUSE_OVER : {enum: 3, labelDashboard: "MouseOver ",  labelStatus :  "Mouse Over "},
    MOUSE_OUT : {enum: 4, labelDashboard: "MouseOut ",  labelStatus :  "Mouse Out "},
    KEY_UP : {enum: 5, labelDashboard: "KeyUp ",  labelStatus :  "Key Up "},
    KEY_DOWN : {enum: 6, labelDashboard: "Keydown ",  labelStatus :  "Key down "},
    SUBMIT : {enum: 7, labelDashboard: "Submit ",  labelStatus :  "Submit "},
    LOAD : {enum: 8, labelDashboard: "Load ",  labelStatus :  "Load "},
  },
  UserLogDataType : {
    NOTSET : {enum: 0, labelDashboard: "NotSet ",  labelStatus :  "NotSet "},
    CONTENT_ID : {enum: 1, labelDashboard: "ContentId ",  labelStatus :  "Content Id "},
    CONTENTMEDIA_ID : {enum: 2, labelDashboard: "ContentMediaId ",  labelStatus :  "ContentMedia Id "},
    CATALOGITEM_ID : {enum: 3, labelDashboard: "CatalogItemId ",  labelStatus :  "CatalogItem Id "},
    PLAYLIST_ID : {enum: 4, labelDashboard: "PlaylistId ",  labelStatus :  "Playlist Id "},
  },
  SpeakersDurationTypes : {
    ThisWeek: "This Week",
    ThisMonth: "This Month",
    Older: "Older",
  },  
  Languages : {
    ENGLISH_ID : "en-US",
    FRENCH_ID : "fr-FR",
    SPANISH_ID : "es-ES"
  },
  TimeConstraints: {
    startingSlot: "00:00:00",
    endingSlot: "23:59:59"
  }
};

export default Constants;
