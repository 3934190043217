import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@mui/material";
import TextfieldWithTimeHighlight from "./TextfieldWithTimeHighlight";
import { useDispatch, useSelector } from "react-redux";
import { GetNewContentAction } from "../../redux/action/GlobalActions";



export default function HighlightComponent(props) {
    const [highlights, setHighlights] = useState([]);
    const { newContent } = useSelector(
        (state) => state.globalReducer
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (newContent.highlight) {
            let oldHighlights = JSON.parse(JSON.stringify(newContent.highlight ?? []));
            oldHighlights.forEach((highlight, index) => {
                if (highlight.startTime === undefined || highlight.endTime === undefined) {
                    highlight.startTime = toTime(highlight.startTimeIndex);
                    highlight.endTime = toTime(highlight.endTimeIndex);
                }
            })
            setHighlights(oldHighlights)
        }
    }, [])


    function toSeconds(time) {
        //check if time is in seconds
        if (time.length < 8) {
            return time;
        }

        var parts = time.split(':');
        return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
    }

    //inverse of toSeconds
    function toTime(seconds) {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds - (hours * 3600)) / 60);
        var seconds = seconds - (hours * 3600) - (minutes * 60);
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    const handleAddHighlight = () => {
        setHighlights([...highlights, { label: "", labelFr: "", startTime: "00:00:00", endTime: "00:00:00" }])
    }

    const handleChangeHighlight = (highlight, index) => {
        let newHighlights = [...highlights]
        newHighlights[index] = highlight
        setHighlights(newHighlights)
    }

    const handleRemoveHighlight = (index) => {
        let newHighlights = [...highlights]
        newHighlights.splice(index, 1)
        setHighlights(newHighlights)
    }

    useEffect(() => {
        //create a copy of chapters and set all time in chapters to seconds and change field name to timeIndex
        let newHighlights = JSON.parse(JSON.stringify(highlights))
        newHighlights.forEach((highlight, index) => {
            highlight.startTimeIndex = toSeconds(highlight.startTime)
            highlight.endTimeIndex = toSeconds(highlight.endTime)
            //remove time
        })
        //sort chapters by timeIndex
        newHighlights.sort((a, b) => a.timeIndex - b.timeIndex)
        //set new chapters to redux

        //delete time
        newHighlights.forEach((highlight, index) => {
            delete highlight.startTime
            delete highlight.endTime
        })
        //filter out empty highlights
        newHighlights = newHighlights.filter(highlight => highlight.label !== "")

        dispatch(GetNewContentAction(
            {
                ...newContent,
                highlight: newHighlights
            }
        ));

    }, [highlights])


    return (
        <>
            <Grid container alignItems="center">

                <Grid item xs={2} sx={{ display: "flex" }} alignItems="center">
                    <Typography
                        sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Highlights
                    </Typography>
                    {!newContent.isTopCreamContent &&

                        <IconButton
                            variant="contained" size="small"
                            onClick={() => handleAddHighlight()}
                        >
                            <AddIcon sx={{ fontSize: "30px" }} />
                        </IconButton>
                    }
                </Grid>

                <Grid item xs={10} sx={{ display: "flex", flexDirection: "column" }}>
                    {highlights.map((highlight, index) => (
                        <TextfieldWithTimeHighlight
                            handleChangeHighlight={handleChangeHighlight}
                            handleRemoveHighlight={handleRemoveHighlight}
                            isTopCreamContent={newContent.isTopCreamContent}
                            index={index}
                            label={highlight.label}
                            labelFr={highlight.labelFr}
                            startTime={highlight.startTime}
                            endTime={highlight.endTime}
                            duration={newContent.videoDuration}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    )
}