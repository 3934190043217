import React, { useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ResearchBar from "../../components/research/ResearchBar";
import NestedLists from "../../components/nestedList/NestedLists";
import NestedListRadio from "../../components/nestedList/NestedListRadio";
import NestedCombo from "../../components/nestedList/NestedCombo";
import NestedListsFilter from "../../components/nestedList/NestedListsFilter";
import NestedListSpeakers from "../../components/nestedList/NestedListSpeakers";
import SpecialNestedLists from "../../components/nestedList/SpecialNestedList";
import language from "../../components/nestedList/moreCriteriaJson/Language.json";
import RecommendedVideos from "../../components/videoCard/RecommendedVideos";
import Tags from "../../components/switching/Tags";
import Paginator from "../../components/pagination/Paginator";
import PublishedList from "../../components/nestedList/publishedJson/PublishedList";
import duration from "../../components/nestedList/moreCriteriaJson/Duration";
import mediaTypes from "../../components/nestedList/moreCriteriaJson/MediaTypes";
import ComplexityLevel from "../../components/nestedList/moreCriteriaJson/ComplexityLevel.json"
import AiExplore from "../aiExplore/AiExplore";
import FilterIcon from "../../assets/images/search-filter-icon.svg";

const MobileScreenSearch = ({
    isLoading,
    translation,
    speakers,
    handleformData,
    handleCheckInfo,
    setResearch,
    searchedValue,
    tags,
    content,
    location,
    userId,
    categories,
    languages,
    totalSearchItems,
    onChangeCategoryInput,
    triggerResetTheCategoriesList,
    checkedState,
    setCheckedState,
    myInfo,
    checkState,
    propogateSpeakersAPI
}) => {
    const [expanded, setExpanded] = useState('');

    const handleChange = panel => (event, newExpanded) => {
        if (event.target.innerText === "" || event.target.innerText === translation.Filters) {
            setExpanded(newExpanded ? panel : false);
        } else {
            event.stopPropagation();
        }
    };

    return (
        <>
            <div className="research research--extended">
                <div className="research__top research__top--extended">
                    <div className="research__searchBar">
                        <ResearchBar
                            setResearch={setResearch}
                            searchedValue={searchedValue}
                            checkInfo={handleCheckInfo}
                        />
                    </div>

                    <Accordion style={{
                        backgroundColor: '#f8f7f6 !important'
                    }} className={`${expanded ? 'expandedViewOverrider' : ''}`} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="filtering__accordian-panel"
                            style={{
                                marginTop: 0,
                                marginBottom: 0,
                                minHeight: 50,
                            }}
                        >
                            <div className="filters__label--wrapper">
                                <img src={FilterIcon} />
                                <span>{translation.Filters}</span>
                            </div>
                            <div className={`filter__inputs--right--column filter__inputs--right--column--extended ${expanded ? 'mobile_search-filter-expanded' : ''}`}>
                                <NestedCombo
                                    GetformData={handleformData}
                                    checkInfo={handleCheckInfo}
                                    data={mediaTypes}
                                    checkedState={checkedState}
                                    setCheckedState={setCheckedState}
                                />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="accordian__filtering-section">
                            <div className="filter filter--extended">
                                <div className="filter__inputs--left--column filter__inputs--left--column--extended">
                                    <div className="nested__list--wrapper nested__list--wrapper--extended medium__screen--search--screen">
                                        {(myInfo) &&
                                            <NestedLists
                                                GetformData={handleformData}
                                                checkInfo={handleCheckInfo}
                                                data={language}
                                                comboID={10}
                                                checkState={checkState}
                                                nestedListName={translation.Suggestion_Language}
                                                ListType={"language"}
                                                myInfo={myInfo}
                                            />}
                                    </div>
                                    <div className="nested__list--wrapper nested__list--wrapper--extended medium__screen--search--screen">
                                        <SpecialNestedLists
                                            GetformData={handleformData}
                                            checkInfo={handleCheckInfo}
                                            data={ComplexityLevel}
                                            comboID={11}
                                            nestedListName={translation.Complexity_level}
                                            ListType={"complexityLevel"}
                                            myInfo={myInfo}
                                            checkState={checkState}
                                        />
                                    </div>
                                    <div className="nested__list--wrapper nested__list--wrapper--extended medium__screen--search--screen">
                                        <NestedLists
                                            GetformData={handleformData}
                                            checkInfo={handleCheckInfo}
                                            data={duration}
                                            comboID={7}
                                            nestedListName={translation.Duration}
                                            ListType={"duration"}
                                            myInfo={myInfo}
                                            checkState={checkState}
                                        />
                                    </div>
                                    <div className="nested__list--wrapper nested__list--wrapper--extended medium__screen--search--screen">
                                        <NestedListRadio
                                            GetformData={handleformData}
                                            checkInfo={handleCheckInfo}
                                            data={PublishedList()}
                                            comboID={4}
                                            nestedListName={translation.Published_Date}
                                            ListType={translation.Published_Date}
                                            checkState={checkState}
                                        />
                                    </div>
                                    <div className="nested__list--wrapper nested__list--wrapper--extended">
                                        <NestedListSpeakers
                                            choosenList={speakers}
                                            GetformData={handleformData}
                                            comboID={3}
                                            checkInfo={handleCheckInfo}
                                            includedInSearch={true}
                                            checkState={checkState}
                                            propogateSpeakersAPI={propogateSpeakersAPI}
                                        />
                                    </div>
                                    <div className="nested__list--wrapper nested__list--wrapper--extended medium__screen--search--screen">
                                        <NestedLists
                                            GetformData={handleformData}
                                            checkInfo={handleCheckInfo}
                                            data={languages}
                                            comboID={6}
                                            nestedListName={translation.Localisation}
                                            ListType={"languages"}
                                            myInfo={myInfo}
                                            checkState={checkState}
                                        />
                                    </div>
                                    <div className="nested__list--wrapper nested__list--wrapper--extended medium__screen--search--screen">
                                        <NestedListsFilter
                                            choosenList={categories}
                                            GetformData={handleformData}
                                            comboID={2}
                                            checkInfo={handleCheckInfo}
                                            nestedListName={"Categoties"}
                                            onChangeCategoryInput={onChangeCategoryInput}
                                            triggerResetTheCategoriesList={triggerResetTheCategoriesList}
                                            includedInSearch={false}
                                            checkState={checkState}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Tags
                                tags={tags}
                                comboID={"tags"}
                                GetformData={handleformData}
                                checkInfo={handleCheckInfo}
                                includedInDashboard={false}
                                checkState={checkState}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className="display__loader">
                    {isLoading && <LinearProgress color="inherit" className="progress_bar" />}
                </div>
                <div>
                    <AiExplore orientation="right">
                        Explore results with AI
                    </AiExplore>
                </div>
                {content.length > 0 &&
                    <div className="results__found--text">
                        <span>{`${totalSearchItems} ${totalSearchItems > 1 ? translation.Numbers_Of_Results : translation.Number_Of_Results}`}</span>
                    </div>}

                {content.length > 0 &&
                    <div className="search__recommended search__recommended--small--screen">
                        <RecommendedVideos
                            research={content}
                            location={location}
                            userId={userId}
                            searchScreen={true}
                        />
                        <Paginator />
                    </div>}

                {(isLoading) ?
                    content.length === 0 &&
                    <div className="search__recommended search__recommended_no_results">
                        {translation.Loading}
                    </div> :
                    content.length === 0 &&
                    (<div className="search__recommended search__recommended_no_results">
                        {translation.No_Content_Found}
                    </div>)
                }
            </div>
        </>
    )
};

export default MobileScreenSearch;