import * as types from "../constant/index";

const reducer = (

  state = {
    view: [
      {
        title: "translation.TC_Recommended",
        api: "Recommended",
        url: "recommended",
        logo: "Recommended",
        id: 0,
      },
      {
        title: "translation.Home_New",
        api: "NewOnTopCream",
        url: "new-on-TopCream",
        logo: "NewOnTopCream",
        id: 1,
      },
      {
        title: "title",
        category: "categories",
        url: "categories",
        logo: "categories",
        id: 2,
      },
      {
        title: "translation.Home_Trending",
        api: "TrendingNow",
        url: "trending-now",
        logo : "TrendingNow",
        id: 3,
      },
      {
        title: "translation.Home_MightLike",
        api: "ConferencesYouMightLike",
        url: "conferences-you-might-like",
        logo: "ConferencesYouMightLike",
        id: 4,
      },
      {
        title: "translation.Home_ByYourPeers",
        api: "Random",
        url: "watched-by-your-peers",
        logo: "peer",
        id: 5,
      },
      {
        title: "translation.TC_TopVideos",
        api: "TopTcVideo",
        url: "top-videos",
        logo: "TopVideos",
        id: 6,
      },
      {
        title: "translation.TC_MostViewed",
        api: "MostViewed",
        url: "most-viewed",
        logo: "MostViewed",
        id: 7,
      },
      {
        title: "translation.Home_Exclusive",
        api: "ContentOwner",
        url: "exclusive",
        logo: "ContentOwner",
        id: 8,
      },
    ],
  },
  action
) => {
  switch (action.type) {
    case types.GET_VIEWALL:
      return {
        ...state,
        view: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;