import { useSelector } from "react-redux";

const PublishedList = () => {
    const { translation } = useSelector(state => state.languageReducer);
    const search_recommendation = translation.Search_Date.split(',');
    const today = search_recommendation[0];
    const week = search_recommendation[1];
    const month = search_recommendation[2];
    const year = search_recommendation[3];

    return (
        [
            {
                "id": 1,
                "name": today,
                "label" : "Today"
            },
            {
                "id": 2,
                "name": week,
                "label" : "This Week"
            },
            {
                "id": 3,
                "name": month,
                "label" : "This Month"
            },
            {
                "id": 4,
                "name": year,
                "label" : "This Year"
            }
        ]   
    );
}

export default PublishedList;