import React from "react";
import Grid from "@mui/material/Grid";
import { useState, memo } from "react";

import VideoSkeleton from "../../components/video/VideoSkeleton";
import LinkedVideoSkeleton from "../../components/linkedVideo/LinkedVideoSkeleton";

import Descriptions from "../../components/descriptions/Descriptions";
import LinkedVideo from "../../components/linkedVideo/LinkedVideo";
import Video from "../../components/video/Video";
import SideBarRight from "../../components/sideBarRight/SideBarRight";
import VideoBlocked from "../../components/video/VideoBlocked";
import VideoThumbnail from "../../components/video/VideoThumbnail";
import UnauthorizedConferenceModal from "./UnauthorizedConferenceModal";

import "../../theme/conferences/Conferences.scss";

const LargeScreenConferencePage = memo(({
    conference,
    setVideoHasLoaded,
    videoHasLoaded,
    id,
    isLoading,
    userID,
    transcriptList,
    translation,
    triggerGetConferenceApi,
    setLinkedVideoHasLoaded,
    isSavedContent,
    linkedVideoHasLoaded,
    comments,
    likeCount,
    following,
    like,
    handleDownload,
    isDownloaded
}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpen = (e) => {
        if(conference?.isAuthenticated) return;
        e.stopPropagation(); // Stop the event from propagating further
        setModalOpen(true);
    };
    const handleClose = () => setModalOpen(false);
    return (
        <div className="conferences">
            <UnauthorizedConferenceModal
                open={modalOpen}
                handleClose={handleClose}
                severity={"error"}
                translation={translation}/>
            <Grid container className="conferences--styling" sx={{ display: "flex" }}>
                <Grid item xs={12} md={8} className="conferences__video">
                    {conference.conference && (
                        <>
                            {conference?.conference?.isExternalContent ?
                                <VideoThumbnail
                                    thumbnailURL={conference.conference.thumbnailURL}
                                    setVideoHasLoaded={setVideoHasLoaded}
                                    externalContentLink={conference?.conference?.externalContentLink}
                                />
                                :
                                (!conference?.isContentAllowed?.isContentAllowed ?
                                    <VideoBlocked
                                        setVideoHasLoaded={setVideoHasLoaded}
                                        calledComponent={"Conferences"}
                                        isContentAllowed={conference?.isContentAllowed}
                                    />
                                    :
                                    <Video
                                        setVideoHasLoaded={setVideoHasLoaded}
                                        props={conference.conference}
                                        calledComponent={"Conferences"}
                                    />
                                )
                            }
                        </>
                    )}
                    {!videoHasLoaded && <VideoSkeleton className="video__skeleton" />}
                    <Descriptions
                        id={id}
                        isLoading={isLoading}
                        tags={conference?.tag}
                        viewCount={conference?.viewCount}
                        contributor={conference?.contributors}
                        speaker={conference?.speaker}
                        infoConference={conference?.infoConference}
                        userID={userID}
                        transcriptList={transcriptList}
                        likes={conference?.review}
                        thumbnailURL={conference?.conference?.thumbnailURL}
                        translation={translation}
                        channel={conference?.channel}
                        contentId={conference?.contentId}
                        isSaved={conference?.isSaved || isSavedContent}
                        triggerGetConferenceApi={triggerGetConferenceApi}
                        comments={comments}
                        chapters={conference.contentBookmarks}
                        highlights={conference.contentHighlight}
                        staticimg={conference?.conference?.thumbnailURL}
                        likeCount={likeCount}
                        following={following}
                        isAuthenticated={conference?.isAuthenticated}
                        isliked={conference?.isliked || like}
                        isContentExternalLink={conference?.conference?.isExternalContent ?? false}
                        externalContentLink={conference?.conference?.externalContentLink}
                        handleDownload={handleDownload}
                        isDownloaded={isDownloaded}
                    />
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: "flex" }} className="sideBarRight__global" >
                    <div className="conference_highlights_enabled">
                        <SideBarRight
                            chapters={conference.contentBookmarks}
                            highlights={conference.contentHighlight}
                            staticimg={conference?.conference?.thumbnailURL}
                            translation={translation}
                            transcriptList={transcriptList}
                        />
                    </div>
                    {!linkedVideoHasLoaded && <LinkedVideoSkeleton />}
                    {conference.contentId && (
                        <div onClick={handleOpen}>
                            <LinkedVideo
                                contentId={conference?.contentId}
                                translation={translation}
                                setLinkedVideoHasLoaded={setLinkedVideoHasLoaded}
                                isAuthenticated={conference?.isAuthenticated}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
});

export default LargeScreenConferencePage;