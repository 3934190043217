//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Description component *                                       *
 * ?                                    Description contain many things inside it here is the list              *
 * ?                          Description is used in the conference page to give information on the video       *
 * ?               Like/Save/share buttons || Speakers/Keywords/Comments || Overview of the video's stats       *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import ImCross from "@mui/icons-material/Close";
import { FacebookShareButton, LinkedinShareButton, FacebookIcon, LinkedinIcon } from "react-share";
import Modal from "@mui/material/Modal";

import Tags from "../../components/switching/Tags";
import Intervenants from "../../components/switching/Intervenants";
import Comments from "../../components/switching/comments/Comments";

import requests from "../axios/Requests";
import {
  postContentIntoPlaylist,
  deleteContentFromPlaylist,
} from "../../servicesRedux/PlaylistService";
import { checkAndUpdateLikesCountAction } from "../../redux/action/PlaylistAction";
import { GetWatchLaterCountAction } from "../../redux/action/GlobalActions.js";

import TopCreamShare from "../tcshare/TopCreamShare";
import { getRecommendationReceipients } from "../../servicesRedux/RecommendedService";
import DescriptionsPlaylistModal from "./DescriptionsPlaylistModal";
import ViewCount from "./ViewCount";
import {
  isLikedStateAction,
  isSubscribedStateAction,
} from "../../redux/action/PlaylistAction";
import UnauthorizedConferenceModal from "../conferencePageComponents/UnauthorizedConferenceModal.jsx";

import "./Description.scss";
import "../../theme/search/Search.scss";

const Descriptions = props => {
  const {
    id,
    tags,
    isLoading,
    viewCount,
    speaker,
    infoConference,
    userID,
    contributor,
    transcriptList,
    thumbnailURL,
    translation,
    channel,
    contentId,
    isSaved,
    isliked,
    triggerGetConferenceApi,
    following,
    isAuthenticated,
    isContentExternalLink,
    externalContentLink,
    handleDownload,
    isDownloaded
  } = props;
  let keyWords = [];

  const dispatch = useDispatch();
  const [like, setLike] = useState(false);
  const [isSavedContent, setIsSavedContent] = useState(isSaved ?? false);
  const { watchLaterCount } = useSelector(state => state.globalReducer);
  const { isSubscribedState, isLikedState, likesCount } = useSelector(state => state.playlistReducer);
  const [, setReceipients] = useState([]);
  const url = new URL(window.location.href);
  const shareUrl = url.origin + "/share" + url.pathname;
  const [likeCount, setLikeCount] = useState(likesCount);
  const [openPlaylistModal, setOpenPlaylistModal] = useState(false);
  const { allPlayLists } = useSelector(state => state.playlistReducer);
  tags?.forEach(element => keyWords.push(element.toLowerCase().trim()));
  const [triggerUpdatingOfPlaylistModalBox, setTriggerUpdatingOfPlaylistModalBox] = useState(false);
  const [triggerLikesCountIncrementationOrDecrementation, setTriggerLikesCountIncrementationOrDecrementation] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = (e) => {
    if(isAuthenticated) return;
    e.stopPropagation(); // Stop the event from propagating further
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  useEffect(() => {
    isSaved && setIsSavedContent(isSaved);
  }, [isSaved]);

  useEffect(() => {
    setLikeCount(likesCount);
  }, [likesCount]);

  useEffect(() => {
    if (triggerLikesCountIncrementationOrDecrementation) {
      setLikeCount(prevLikeCount => prevLikeCount + 1);
      dispatch(isLikedStateAction(true));
    } else {
      if (likeCount != 0) setLikeCount(prevLikeCount => prevLikeCount - 1);
      dispatch(isLikedStateAction(false));
    }
    triggerGetConferenceApi();
  }, [triggerLikesCountIncrementationOrDecrementation]);

  useEffect(() => {
    if (isSubscribedState !== null && isSubscribedState !== undefined) {
      setIsSavedContent(isSubscribedState);
    }
  }, [isSubscribedState]);

  useEffect(() => { }, []);

  useEffect(() => {
    if (isLikedState !== null && isLikedState !== undefined) {
      setLike(isLikedState);
    }
  }, [isLikedState]);

  useEffect(() => {
    if (isliked !== null && isliked !== undefined) {
      setLike(isliked);
    }
  }, [isliked]);

  const handleClick = likeStatus => {
    if (likeStatus) {
      setLike(true);
      setLikeCount(preLikeCount => preLikeCount + 1);
      dispatch(isLikedStateAction(true));
      dispatch(checkAndUpdateLikesCountAction(likesCount + 1));
      postContentIntoPlaylist(
        requests.PostContentIntoPlaylist,
        allPlayLists?.data?.find(p => p.type === 3)?.id,
        contentId
      ).then(() => {
        setTriggerUpdatingOfPlaylistModalBox(!triggerUpdatingOfPlaylistModalBox);
      });
    } else {
      setLike(false);
      if (likeCount != 0) setLikeCount(preLikeCount => preLikeCount - 1);
      dispatch(isLikedStateAction(false));
      dispatch(checkAndUpdateLikesCountAction(likesCount - 1));
      deleteContentFromPlaylist(
        requests.DeleteContentFromPlaylist,
        allPlayLists?.data?.find(p => p.type === 3)?.id,
        contentId
      ).then(() => {
        setTriggerUpdatingOfPlaylistModalBox(!triggerUpdatingOfPlaylistModalBox);
      });
    }
  };

  const handleSave = isSavedContentStatus => {
    if (!isSavedContentStatus) {
      try {
        postContentIntoPlaylist(
          requests.PostContentIntoPlaylist,
          allPlayLists?.data?.find(p => p.type === 2).id,
          contentId
        ).then(_ => {
          setIsSavedContent(true);
          triggerGetConferenceApi();
          dispatch(GetWatchLaterCountAction(watchLaterCount + 1));
          setTriggerUpdatingOfPlaylistModalBox(!triggerUpdatingOfPlaylistModalBox);
        });
        dispatch(isSubscribedStateAction(true));
      } catch (error) {
        console.error(error.message);
      }
    } else {
      try {
        deleteContentFromPlaylist(
          requests.DeleteContentFromPlaylist,
          allPlayLists?.data?.find(p => p.type === 2).id,
          contentId
        ).then(() => {
          setIsSavedContent(false);
          triggerGetConferenceApi();
          dispatch(GetWatchLaterCountAction(watchLaterCount - 1));
          setTriggerUpdatingOfPlaylistModalBox(!triggerUpdatingOfPlaylistModalBox);
        });
        dispatch(isSubscribedStateAction(false));
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const handleAddToPlaylist = () => { if(isAuthenticated) setOpenPlaylistModal(true);}

  const VideoInfoCard = () => {
    return (
      <div className="shar__VideoCard__info conference__page_share_content">
        <img
          src={thumbnailURL}
          alt="videoImage"
          className="shar__VideoCard__info--img"
        />
        <div className="shar__VideoCard__info__aligne">
          <div className="shar__VideoCard__info--speaker">
            {speaker?.lastName} {speaker?.firstName}
          </div>
          <div className="shar__VideoCard__info--viewcount">
            {translation.VideoCard_Views.replace(
              "@number@",
              viewCount === undefined ? 0 : viewCount
            )}
          </div>
          <Avatar
            src={speaker?.image}
            className="shar__VideoCard__info--avatar"
          />
        </div>
        <div className="shar__VideoCard__info--title">
          {infoConference?.name}
        </div>
      </div>
    );
  };

  const copyLink = e => {
    navigator.clipboard.writeText(url);
    e.currentTarget.innerHTML = `Copied &#10004`;
  };

  const triggerTheRealTimeUpdateOnLikes = status => setTriggerLikesCountIncrementationOrDecrementation(status);

  const showShareModal = () => {
    if(isAuthenticated) {
      getRecommendationReceipients(requests.RecommendationReceipients, contentId).then(recpientsRes => {
        setReceipients(recpientsRes.map(r => r.recipientId != undefined && r.recipientId))
        setShareModalOpen(true);
      });
    }
  };

  const closeShareModal = () => setShareModalOpen(false);

  return (
    <div className="description">
      <UnauthorizedConferenceModal
        open={modalOpen}
        handleClose={handleClose}
        severity={"error"}
        translation={translation}/>
      <div className="description__left">
        <div>
          <p className="intervenants--title">
            {infoConference?.name}{isContentExternalLink && ":"}
            {isContentExternalLink && ' '}
            {isContentExternalLink &&
              <span
                className="intervenants--title"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => window.open(externalContentLink, '_blank')}
              >
                {translation.External_content_click_here}
              </span>
            }
          </p>
          <p className="intervenants--text">{infoConference?.fullSummary}</p>
        </div>
        <div>
          <Tags tags={keyWords.sort()} isConference={true} includedInDashboard={false} />
        </div>
        <div className="description__left__container">
          <Link
            to={`/channel/${channel?.id}`}
            className="description__informations__name"
          />
        </div>
        <div className="description__switching" onClick={handleOpen}>
          <Intervenants
            id={id}
            intervenant={speaker}
            contributor={contributor}
            channel={channel}
            infoConference={infoConference}
            transcriptList={transcriptList}
            translation={translation}
            isAuthenticated={isAuthenticated}
            following={following}
          />
        </div>
      </div>
      <div className="description__stack"  onClick={handleOpen}>
        <div className="description__viewcount">
          <ViewCount
            isLoading={isLoading}
            views={viewCount}
            like={like}
            likes={likeCount}
            translation={translation}
            handleClick={handleClick}
            isSavedContent={isSavedContent}
            handleSave={handleSave}
            showShareModal={showShareModal}
            handleAddToPlaylist={handleAddToPlaylist}
            infoConference={infoConference}
            isDownloaded={isDownloaded}
            handleDownload={handleDownload}
          />
        </div>
      </div>
      <div className="">
        {isAuthenticated &&
          <Comments
            id={id}
            currentUserId="1"
            translation={translation}
            intervenantImage={speaker?.image}
          />
        }
      </div>

      {openPlaylistModal &&
        <DescriptionsPlaylistModal
          id={id}
          likes={likeCount}
          contentId={contentId}
          open={openPlaylistModal}
          setOpen={setOpenPlaylistModal}
          triggerUpdatingOfPlaylistModalBox={triggerUpdatingOfPlaylistModalBox}
          triggerTheRealTimeUpdateOnLikes={triggerTheRealTimeUpdateOnLikes}
        />}

      <Modal
        open={shareModalOpen}
        onClose={closeShareModal}
        className="shareContentModalConferencePageMainModal"
      >
        <div className="modal__content shareContentModalConferencePage">
          <div className="modal__content__header">
            <h1 className="research--modalTitle">
              {translation.Conference_Share}
            </h1>
            <ImCross
              size={20}
              onClick={closeShareModal}
              className="description__playlists--exit modal__close"
            />
          </div>
          <VideoInfoCard />
          <div className="research--modal__container">
            <FacebookShareButton
              url={`${shareUrl}`}
              quote={infoConference?.fullSummary}
              title={infoConference?.name}
              hashtag={"#TopCream"}
              description={"facebook"}
              className="share-button"
            >
              <FacebookIcon size={48} round />
              <div className="share-button--text">Facebook</div>
            </FacebookShareButton>
            <LinkedinShareButton
              url={`${shareUrl}`}
              title={infoConference?.name}
              className="share-button"
            >
              <LinkedinIcon size={48} round />
              <div className="share-button--text">Linkedin</div>
            </LinkedinShareButton>
            <TopCreamShare
              userId={userID}
              mobileLayout={false}
              contentId={contentId}
            />
          </div>
          <div className="shar__VideoCard__copyLink">
            {translation.Conference_CopyLink}
            <div className="shar__VideoCard__copyLink--content">
              <input type="url" value={`${shareUrl}`} />
              <button
                className="shar__VideoCard__copyLink--copy"
                onClick={copyLink}
              >
                {translation.Conference_Copy}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Descriptions;
