import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import requests from "../axios/Requests";
import {  resendConfirmationEmail } from "../../servicesRedux/AuthentificationService";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import useReplacer from "../../hooks/replacer";
import "./Registration.scss";
import { getSettings } from "../../servicesRedux/SettingsService";

export default function EmailConfirmRegistration(props) {
    const { translation } = useSelector((state) => state.languageReducer);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [settings, setSettings] = useState(null);
    const replacer = useReplacer();
    const location = useLocation();
    const email = location.state.email;
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        getSettings(requests.GetSettings).then((res) => {
          setSettings(res);
        }
        );
      }, []);

    const useStyles = makeStyles((theme) => ({
        snackbar: {
            // Add custom styles for Snackbar
            maxWidth: '100%',
            margin: '0 auto',
        },
        alert: {
            // Add custom styles for Alert
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Barlow',
        },
    }));

    const classes = useStyles();

    const handleResendEmail = () => {
        resendConfirmationEmail(requests.ResendConfirmationEmail, email)
            .then((response) => {
                const { status, message } = response.data;

                switch (status) {
                    case 'Success':
                        setSnackbarMessage(translation.successMessage);
                        setSnackbarSeverity('success');
                        setSnackbarOpen(true);
                        break;
                    case 'Error':
                        if (message === 'email is invalid') {
                            setSnackbarMessage(translation.emailInvalidMessage);
                        } else if (message === 'registration confirmation is already done!') {
                            setSnackbarMessage(translation.confirmationAlreadyDoneMessage);
                        } else {
                            setSnackbarMessage(translation.unknownErrorMessage);
                        }
                        setSnackbarSeverity('error');
                        setSnackbarOpen(true);
                        break;
                    default:
                        setSnackbarMessage(translation.unknownErrorMessage);
                        setSnackbarSeverity('error');
                        setSnackbarOpen(true);

                }
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error(error);
                setSnackbarMessage(translation.resendConfirmationError);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    return (
        <div className="b2b-registration">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                style={{
                    marginTop: 0,
                }}
            >
                <Grid container spacing={2} className="reg__background--container">
                    <Grid item xs={12} md={6} lg={6} className="reg__left--background">

                        <div className="reg__left--background--internal">
                            <img className="login--logo" src={settings?.logoUrl} alt="logo" />
                            <div className="thank-you">
                                <h1>{replacer(translation.Thank_You_Register)}</h1>
                                <br />
                                <p>{translation.Receive_Email_Shortly}</p>
                                <br />
                                <p>{translation.Receive_Email_Confirm}</p>
                                <br />
                                <p>{translation.Receive_Email_Not_Received}</p>
                                <div className="thank-you_links">
                                    <a onClick={handleResendEmail}>{translation.Resend_Confirmation_Email}</a>
                                    <a href="https://topcream.global/contact/" target="_blank">{translation.Contact_Us}</a>
                                </div>
                                <Snackbar
                                    open={snackbarOpen}
                                    autoHideDuration={6000}
                                    onClose={handleSnackbarClose}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    className={classes.snackbar} // Apply custom styles to Snackbar
                                >
                                    <MuiAlert
                                        elevation={6}
                                        variant="filled"
                                        onClose={handleSnackbarClose}
                                        severity={snackbarSeverity}
                                        className={classes.alert} // Apply custom styles to Alert
                                    >
                                        {snackbarMessage}
                                    </MuiAlert>
                                </Snackbar>
                            </div>
                        </div>
                    </Grid>
                    </Grid>
            </Box>
        </div>
                            )
  }