//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Admin page *                                                  *
 * ?                      This page is used when a user wants to change is informations and settings            *
 * ?                      It is accessible by clicking on his name on the navbar                               *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Avatar, Stack } from "@mui/material";
import Alert from "@mui/material/Alert";
import { FormControl } from '@mui/material';
import UserTags from "../components/userTags/UserTags.jsx";

import requests from "../components/axios/Requests";
import { patchProfile } from "../servicesRedux/GlobalService";
import { GetTokenByIdUser } from "../redux/action/AuthentificationAction";
import { getTokenByID } from "../servicesRedux/AuthentificationService";

import ProfileEdit from "../assets/images/profile_edit.svg";
import FrenchButton from "../assets/images/french-icon.svg";
import EnglishButton from "../assets/images/english-icon.svg";
import SpanishButton from "../assets/images/spanish-icon.svg";

import azure from "../components/UploadApp/build/azure-storage.blob";
import CircularProgress from '@mui/material/CircularProgress';

import { GetTranslation, SetLanguage } from "../redux/action/LanguageAction";
import { patchPersonByID } from "../servicesRedux/AdminService";

import Fr_Register from "../resources/fr-FR.json";
import En_Register from "../resources/en-US.json";

import UserCategories from "../components/userCategories/UserCategories.jsx";
import Constants from "../components/constants/Constants.js";
import "../theme/admin/Admin.scss";

const Admin = () => {
  const dispatch = useDispatch();
  const { userRedux } = useSelector(state => state.authentificationReducer);
  const { translation } = useSelector(state => state.languageReducer);
  const [surname, setSurname] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [uploadInformation] = useState("");
  const [tempPhoto] = useState("");
  const [tagList, setTagList] = useState([]);
  const userId = Cookies.get("userID");
  const history = useNavigate();
  const [elementPictureNAME, setElementPictureNAME] = useState(null);
  const [elementPicture, setElementPicture] = useState(null);
  const [picture, setPicture] = useState(null);
  const [errors, setErrors] = useState({});
  const [submitIsDone, setSubmitIsDone] = useState(false);
  const [hasBeenSaved, setHasBeenSaved] = useState(false);
  const [categories, setCategories] = useState([]);
  const [preferredLanguages, setPreferredLanguages] = useState([]);
  const date = new Date();
  const languageList = ["Fr", "En"];
  const lang = localStorage.getItem("language") || languageList.find(item => item.includes(window.navigator.language)) || languageList[1];
  const { setProfileImage } = useOutletContext();

  useEffect(() => {
    getTokenByID(requests.GetMeInformation).then(item => {
      if (!Cookies.get("roles")) {
        Cookies.set("roles", JSON.stringify(item.roles));
      }      
      if (Array.isArray(item.preferredLanguageIds)) {
        item.preferredLanguageIds = item.preferredLanguageIds.flat();
      }
      handlePreferredLanguageClick(item.preferredLanguageIds);
      dispatch(GetTokenByIdUser(item))
    });
  }, []);

  useEffect(() => {
    if (hasBeenSaved) {
      setTimeout(() => {
        setHasBeenSaved(false);
      }, 1000);
    }
  }, [hasBeenSaved]);

  const handleChangeLang = langChoice => {
    const personRequest = {
      language: langChoice === "En" ? "en-US" : "fr-FR",
    };
    patchPersonByID(requests.PatchPerson, userRedux.id, personRequest);
    switch (langChoice) {
      case "En":
        localStorage.setItem("language", "En");
        dispatch(GetTranslation(En_Register));
        break;
      case "FR":
        localStorage.setItem("language", "FR");
        dispatch(GetTranslation(Fr_Register));
        break;
      default:
        dispatch(GetTranslation(Fr_Register));
        break;
    }
    dispatch(SetLanguage(langChoice));
  };

  const handlePreferredLanguageClick = (languageIds) => {
    if (!Array.isArray(languageIds)) {
      languageIds = [languageIds];
    }
    setPreferredLanguages((prevLanguages) => {
      const includedLanguages = prevLanguages.filter((id) => languageIds.includes(id));
      if (includedLanguages.length > 0) {
        return prevLanguages.filter((id) => !languageIds.includes(id));
      } else {
        if (prevLanguages === null) {
          return languageIds;
        }
        if (Array.isArray(prevLanguages)) {
          if (prevLanguages[0] === null) {
            return languageIds;
          }
        }
        return [...prevLanguages, ...languageIds];
      }
    });
  };

  const handleChangeThumbnailFile = event => {
    if (event && event?.target && event?.target?.value) {
      setElementPictureNAME(event?.target?.value?.split("\\").pop());
      setElementPicture(event?.target?.files[0]);
      const file = event.target.files[0];
      let img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.width < 500 || img.height < 500) {
          alert("The image is smaller than the 500x500px limit and will not be uploaded.");
        } else if ((img.width >= 500 || img.height >= 500) && (img.width === img.height)) {
          const maxDimension = Math.max(img.width, img.height);
          const ratio = 500 / maxDimension;
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const width = Math.round(img.width * ratio);
          const height = Math.round(img.height * ratio);
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          const resizedImageData = canvas.toDataURL();
          setPicture(resizedImageData);
          const binaryString = window.atob(resizedImageData.split(",")[1]);
          const binaryLen = binaryString.length;
          const bytes = new Uint8Array(binaryLen);
          for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }
          const file = new File([bytes], "resizedImage.jpeg", {
            type: "image/jpeg",
          });
          setElementPicture(file);
        } else {
          alert("The image aspect ratio is not 1:1 and will not be uploaded.")
        }
      };
    }
  };

  const getTimestamp = () => {
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    return year + month + day + hour + minute + second;
  };

  const getFilenameFromUrl = url => {
    const lastSlashIndex = url.lastIndexOf('/');
    if (lastSlashIndex !== -1) {
      return url.substring(lastSlashIndex + 1);
    } else {
      return url;
    }
  };

  const handleSubmit = event => {
    setSubmitIsDone(true);
    const tags = tagList.map(item => item.id);
    event.preventDefault();
    let newThumbnailName = null;
    let personName = null;
    let fileName = null;
    if (userRedux.avatar) {
      fileName = getFilenameFromUrl(userRedux.avatar);
    } else {
      personName = "PROFILE";
      newThumbnailName = getTimestamp() + "_" + personName + "_" +
        date.getTime().toString() + Math.floor(Math.random() * 100000).toString()
        + "." + elementPictureNAME?.split('.').pop();
    } 
    let preferredLangSubmit;
    if (Array.isArray(preferredLanguages)) {
      preferredLangSubmit = preferredLanguages.filter(item => item !== null);
    }
    const change = {
      userId: userId,
      personId: userRedux.id,
      lastName: name,
      firstName: surname,
      email: email,
      phone: phone,
      avatar: elementPictureNAME ? ("persons" + "/pictures/" + newThumbnailName) : null,
      label: "string",
      preferredLanguageIds: preferredLangSubmit,
    };
    if (userRedux.avatar) {
      delete change.avatar;
      change.avatar = "persons" + "/pictures/" + fileName;
    }
    const profile = {
      userPersonRequest: change,
      interestIds: categories,
      tagIds: tags,
    };
    try {
      patchProfile(requests.PatchProfile, profile).then(async item => {
        if (elementPicture) {
          let blobUri;
          let containerName;
          let sasToken;
          let blobService;
          let customBlockSize;
          let finishedOrError;
          let speedSummary;
          let newFile;
          let file = elementPicture;

          if (userRedux.avatar) {
            const response = item;
            const imgFileNameOnBlob = getFilenameFromUrl(response?.data?.person?.image);
            blobUri = response.accountUrl;
            containerName = response.containerName;
            sasToken = response.sasToken;
            blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
            customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
            blobService.singleBlobPutThresholdInBytes = customBlockSize;
            finishedOrError = false;

            newFile = new File([file], imgFileNameOnBlob);
            blobService.deleteBlob(containerName, "persons" + "/pictures/" + imgFileNameOnBlob, function (error) {
              if (error) {
                console.error('An error occurred while deleting the blob:', error);
              } else {
                console.log('The blob was deleted successfully');
                speedSummary = blobService.createBlockBlobFromBrowserFile(
                  containerName,
                  "persons" + "/pictures/" + imgFileNameOnBlob,
                  newFile,
                  { blockSize: customBlockSize }, error => {
                    finishedOrError = true;
                    if (error) {
                      alert("Error when uploading Thumbnail");
                      setErrors({
                        ...errors,
                        thumbnailError: error,
                      });
                    }
                  }
                );
              }
            });
          } else {
            const response = item;
            blobUri = response.accountUrl;
            containerName = response.containerName;
            sasToken = response.sasToken;
            blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
            customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
            blobService.singleBlobPutThresholdInBytes = customBlockSize;
            finishedOrError = false;
            newFile = new File([file], newThumbnailName);
            await blobService.createBlockBlobFromBrowserFile(
              containerName,
              "persons" + "/pictures/" + newThumbnailName,
              newFile,
              { blockSize: customBlockSize },
              function (error, result, response) {
                finishedOrError = true;
                if (error) {
                  alert("Error when uploading Thumbnail");
                  setErrors({
                    ...errors,
                    thumbnailError: error,
                  });
                }
              }
            );
            dispatch(GetTokenByIdUser(item.data.person));
          }
        }
        setProfileImage(picture);
        setSubmitIsDone(false);
        setHasBeenSaved(true);
      })
      .finally(() => {
        getTokenByID(requests.GetMeInformation).then(item => {
          dispatch(GetTokenByIdUser(item))
        });
      });
    } catch (error) {
      console.error(error.message);
    }
  };

  const informationSection = (val) => {
    if (val === "valid") {
      return (
        <div style={{ width: "50%" }}>
          <Alert
            variant="standard"
            severity="success"
            style={{ backgroundColor: "transparent" }}
          >
            {translation.Admin_FileUploaded}
          </Alert>
        </div>
      );
    } else if (val === "not valid") {
      return (
        <div style={{ width: "50%" }}>
          <Alert
            variant="standard"
            severity="warning"
            style={{ backgroundColor: "transparent" }}
          >
            {translation.Admin_ValidFile}
          </Alert>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="admin">
      <div className="admin__middle-container">
        <div className="admin--title">{translation.Settings}</div>
        <div className="admin__account">
          {translation.Admin_My_Profile}
          <div className="admin__account__infos">
            <div className="admin__account__picture only__mobile">
              <Stack spacing={2}>
                {picture ? (
                  <Avatar
                    className="admin__account__picture--avatar"
                    src={tempPhoto || picture}
                    alt="Remy Sharp"
                  ></Avatar>)
                  : (
                    <Avatar
                      className="admin__account__picture--avatar"
                      src={tempPhoto || userRedux.avatar}
                      alt="Remy Sharp"
                    ></Avatar>
                  )
                }

                <div className="upload_image_button">
                  <label
                    for="file-upload"
                    className="admin__account__picture--modify"
                  >
                    <img for="file-upload" onClick={handleChangeThumbnailFile} className="profile__edit-icon" src={ProfileEdit} />
                  </label>
                  <input
                    data-max-size="2048"
                    className="admin__account__picture--modify"
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleChangeThumbnailFile}
                  />
                </div>
                {informationSection(uploadInformation)}
              </Stack>
            </div>
            <div className="admin__account__details">
              <div>
                <FormControl className="admin__form-control">
                  <label>{translation.First_Name}</label>
                  <input
                    className="admin__account__details--input"
                    name="surname"
                    type="surname"
                    value={surname || userRedux.firstName}
                    onChange={e => setSurname(e.target.value)}
                    required
                  />
                </FormControl>

                <FormControl className="admin__form-control">
                  <label>{translation.Last_Name}</label>
                  <input
                    className="admin__account__details--input"
                    name="name"
                    type="name"
                    value={name || userRedux.lastName}
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </FormControl>

                <div className="admin__account__details__inputs">
                  <FormControl className="admin__form-control">
                    <label>{translation.Email}</label>
                    <input
                      className="admin__account__details--input"
                      name="email"
                      type="email"
                      value={email || userRedux.email}
                      placeholder={"email"}
                      onChange={e => setEmail(e.target.value)}
                      disabled
                    />
                  </FormControl>

                  <FormControl className="admin__form-control">
                    <label>{translation.Job_Title}</label>
                    <input
                      className="admin__account__details--input"
                      name="job_title"
                      type="text"
                      value={phone || userRedux.phone}
                      onChange={e => setPhone(e.target.value)}
                      required
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="admin__account__picture only__desktop">
              <Stack spacing={2}>
                {picture ? (
                  <Avatar
                    className="admin__account__picture--avatar"
                    src={tempPhoto || picture}
                    alt="Remy Sharp"
                  />)
                  : (
                    <Avatar
                      className="admin__account__picture--avatar"
                      src={tempPhoto || userRedux.avatar}
                      alt="Remy Sharp"
                    />
                  )
                }

                <div className="upload_image_button">
                  <label
                    for="file-upload"
                    className="admin__account__picture--modify"
                  >
                    <img for="file-upload" onClick={handleChangeThumbnailFile} className="profile__edit-icon" src={ProfileEdit} />
                  </label>
                  <input
                    data-max-size="2048"
                    className="admin__account__picture--modify"
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    style={{ visibility: 'hidden' }}
                    onChange={handleChangeThumbnailFile}
                  />
                </div>
                {informationSection(uploadInformation)}
              </Stack>
            </div>
          </div>
        </div>
        <div>
          <div className="admin__account__details--text">
            <span>{translation.Admin_Business}</span>
            {userRedux.label?.toLowerCase()}
          </div>
        </div>
        {/* To DO */}
        <div className="admin__membership">
          <h3>{translation.Admin_Subscription}</h3>
          <div className="admin__membership__level">
            <div className="admin__membership__level__global">
              {translation.Admin_SubscriptionCategory}
              {/* <div className="admin__membership__level__global--subscription">
                  {"Enterprise"}
                </div> */}
            </div>
          </div>
        </div>
        {/* To DO */}

        <div className="admin__interest">
          <h3>{translation.SideBar_MyInterests}</h3>
          <span>{translation.My_Company_Categories_Label}</span>
          <UserCategories categoryCallback={setCategories} lang={lang} userRedux={userRedux} />
        </div>
        <UserTags setTagList={setTagList} />

        <div className="admin__settings">
          <div className="admin__settings__display">
            <input type="checkbox" id="notification" name="notification" />
            <label className="admin__settings__display--label" for="notification">
              {translation.Admin_ActivateNotifications}
            </label>
          </div>
          <div className="admin__settings__display">
            <input type="checkbox" id="privacy" name="privacy" />
            <label className="admin__settings__display--label" for="privacy">
              {translation.Admin_PrivateViews}
            </label>
          </div>
        </div>

        <div className="admin__account__contentManagement system__language">
          <h3>{translation.System_Language}</h3>
          <div>
            <button className={lang === "En" ? "system__language-button" : "system__language-button unselected"} onClick={() => handleChangeLang("En")}>
              <img src={EnglishButton} />{translation.English}
            </button>
            <button className={lang === "FR" ? "system__language-button" : "system__language-button unselected"} onClick={() => handleChangeLang("FR")}>
              <img src={FrenchButton} />{translation.French}
            </button>
          </div>
          {/* <button
              className="admin__account__details--button"
              onClick={handleSubmit}
            >
              {translation.Admin_Submit}
            </button> */}
        </div>
        <div className="admin__account__contentManagement preferred-language">
          <h3>{translation.Preferred_language_for_content}</h3>
          <p>{translation.You_will_only_see_content_in_your_preferred_language}</p>
          <div>
            <button
              className={
                preferredLanguages.includes(Constants.Languages.ENGLISH_ID)
                  ? "preferred-language-button"
                  : "preferred-language-button unselected"
              }
              onClick={() => handlePreferredLanguageClick(Constants.Languages.ENGLISH_ID)}
            >
              <img src={EnglishButton} />
              {translation.English}
            </button>
            <button
              className={
                preferredLanguages.includes(Constants.Languages.FRENCH_ID)
                  ? "preferred-language-button"
                  : "preferred-language-button unselected"
              }
              onClick={() => handlePreferredLanguageClick(Constants.Languages.FRENCH_ID)}
            >
              <img src={FrenchButton} />
              {translation.French}
            </button>
            <button
              className={
                preferredLanguages.includes(Constants.Languages.SPANISH_ID)
                  ? "preferred-language-button"
                  : "preferred-language-button unselected"
              }
              onClick={() => handlePreferredLanguageClick(Constants.Languages.SPANISH_ID)}
            >
              <img src={SpanishButton} />
              {translation.Spanish}
            </button>
          </div>
        </div>


        <div className="profile__bottom-update-buttons">
          <button
            className="admin__account__details--button unselected"
            onClick={() => history('/')}
          >
            {translation.Suggestion_Cancel_Button}
          </button>
          <button
            className="admin__account__details--button"
            onClick={handleSubmit}
          >
            {!submitIsDone ?
              (hasBeenSaved ? translation.WatchLater_Saved + " ✓" : translation.Conference_Save)
              : <CircularProgress color="inherit" style={{ color: "#f8f7f6" }} />
            }
          </button>
        </div>
      </div>
    </div>
  );
}

export default Admin;
