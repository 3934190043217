import { React, useState, useEffect, memo } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const Transcript = props => {
  const { transcript } = props;
  const [podcastTranscript, setPodcastTranscript] = useState(null);

  function parsePodcastTranscriptToJson(transcript) {
    // Assuming transcript is the parsed JSON object that contains the recognizedPhrases array
    const phrases = transcript?.recognizedPhrases;
    const formattedPhrases = phrases.map(phrase => {
      // Convert the offset to a readable time format, assuming offset is in ISO 8601 duration format
      const time = phrase.offset.match(/PT(\d+H)?(\d+M)?(\d+(\.\d+)?S)?/);
      let hours = 0, minutes = 0, seconds = 0;
      if (time[1]) hours = parseInt(time[1], 10);
      if (time[2]) minutes = parseInt(time[2], 10);
      if (time[3]) seconds = parseFloat(time[3]);
      seconds = seconds.toFixed().toString().padStart(2, '0').split(".")[0];
      let formattedTime = '';
      if (hours > 0) {
        formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds}`;
      } else {
        formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds}`;
      }
      // Use the first nBest entry's display property as the phrase
      const phraseText = phrase.nBest[0].display;
      if(phraseText === undefined || phraseText === null || phraseText ===  '') return null;
      return (
        <ListItem className="transcript__list-item">
          <ListItemText
            primary={formattedTime}
            secondary={<>{phraseText}</>}
          />
        </ListItem>
      );
    });

    return formattedPhrases;
  }

  function formatTime(timeString) {
    // Time format from SRT is generally "hh:mm:ss,msms"
    const parts = timeString.split(':');
    if (parts.length === 3) {
      const hours = parseInt(parts[0], 10);
      const minutes = parts[1];
      const seconds = parts[2].substring(0, 2);
      if (hours > 0) {
        // Hours are significant, format as hh:mm:ss
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds}`;
      } else {
        // Hours are zero, format as mm:ss
        return `${minutes}:${seconds}`;
      }
    } else {
      // Unlikely to hit this condition given SRT format, but just in case
      return timeString; // Fallback to the original string if the format is unexpected
    }
  }

  function parseSrtToComponents(srtData) {
    // Split the content by double newlines to separate each subtitle block
    const unwantedText = "la communauté d'Amara.org]";

    const blocks = srtData.split('\n\n');
    
    const formattedComponents = blocks.map(block => {
      const lines = block.split('\n');
      if (lines.length < 3) return null; // Skip any malformed blocks
  
      const index = lines[0]; // Not used directly in UI, but useful if needed
      const timeRange = lines[1];
      const subtitleText = lines.slice(2).join(' '); // Join all lines of subtitles
      if (subtitleText.includes(unwantedText)) return null; // Skip blocks containing the unwanted text

      // Extract start and end times
      const times = timeRange.split(' --> ');
      if (times.length !== 2) return null;
  
      // Convert times to a more readable format, if necessary
      const startTime = times[0];
      const formattedStartTime = formatTime(startTime);
  
      // Return the formatted component (using your preferred component framework)
      return (
        <ListItem className="transcript__list-item">
          <ListItemText
            primary={`${formattedStartTime}`}
            secondary={<>{subtitleText}</>}
          />
        </ListItem>
      );
    }).filter(component => component !== null); // Remove any null entries
  
    return formattedComponents;
  }

  useEffect(() => {
    if (transcript?.combinedRecognizedPhrases)
      setPodcastTranscript(parsePodcastTranscriptToJson(transcript));
    else if (transcript.version)
      return
    else if (transcript)
      setPodcastTranscript(parseSrtToComponents(transcript));

  }, [transcript]);

  return (
    (transcript?.transcript !== null && transcript?.transcript !== undefined) &&
      transcript.version ? (
      <List sx={{ width: "100%", maxWidth: 360 }} className="transcript__list">
        {transcript?.transcript.map((item, index) => {
          const time = item.instances[0].adjustedStart;
          const [hour, minutes, seconds] = time.split(":");
          const [sec] = seconds.split(".");
          const temptime = `${minutes}:${sec}`;
          const timewithhour = `${hour}:${temptime}`;
          const timing = hour !== '0' ? timewithhour : temptime;
          return (
            <ListItem className="transcript__list-item">
              <ListItemText
                primary={timing}
                secondary={<>{item.text}</>}
              />
            </ListItem>
          );
        })}
      </List>
    ) : (
      podcastTranscript &&
      podcastTranscript?.map((item, index) => {
        return (
        <List sx={{ width: "100%", maxWidth: 360 }} className="transcript__list">
          {item}
        </List>
        );
      })
    )
  );
}

export default memo(Transcript);