import React, { useState } from "react";
import { useSelector } from "react-redux";

import PlaylistEditIcon from "../../assets/images/pencil-icon-playlist.svg";

import "./Playlist.scss";

export const PlaylistEmpty = props => {
  const { playlistMainType } = props;
  const { name, contentCount, type } = props.item;
  const { translation } = useSelector(state => state.languageReducer);

  const favPlayList = (name, type) => {
    if (type === 2 || type === 3) {
      return translation.PlayListName.find((p) => p.type === type).label;
    } else {
      return name;
    }
  };

  return (
    <div>
        <div className={`playlist ${contentCount > 0 ? '' : 'playlist__empty-row'}`}>
          <div className="playlist_label">
            <div className="playlist_label_title">    
                <div className="playlist_edit_flex">
                    <span>{favPlayList(name, type)}</span>
                    {(type !== 2 && type !== 3 && playlistMainType === "my") &&                       
                    <img src={PlaylistEditIcon} className="playlist_edit_icon" />}
                </div>                                        
            </div>                        
          </div>                 
        </div>
    </div>
  );
}

export default PlaylistEmpty;