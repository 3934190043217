//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Video component *                                             *
 * ?                      A simple component to render video it's used for the conference page                  *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useEffect, useRef } from "react";
import PlayIconBlocked from "./PlayIconBlocked";
import AzureMP from "./mp-component-blocked.jsx";

import "./Video.scss";

const VideoBlocked = videoProps => {

    useEffect(() => {
        if (videoProps?.calledComponent) videoProps.setVideoHasLoaded(true);
    }, [videoProps.calledComponent]);

    return (
        <div>
            {videoProps.props !== "" ? (
                <div className="video__play">
                    <PlayIconBlocked
                        displayLogo={true}
                        isContentAllowed={videoProps?.isContentAllowed}
                    />
                    <div className="video">
                        {/*{player&&<RatingPopup player={player}/>}*/}
                        <AzureMP
                            skin="amp-flush"
                            src="https://"
                        />
                    </div>
                </div>
            ) : (
                <div>Video not found</div>
            )}
        </div>
    );
};

export default VideoBlocked;
