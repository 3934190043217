import * as types from "../constant/index";


export default function reducer(
    state = {
        banner: [],
        row: []
    },
    action
) {

    switch (action.type) {
        case types.GET_BANNER:
            return {
                ...state,
                banner: action.payload,
            };

        case types.GET_ROW:
            return {
                ...state,
                row: action.payload,
            };
        default:
            return state;
    }
}