// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Login page *                                                  *
 * ?                      When a user isnt logged in he will see this page                                      *
 * ?                      A token will be generated once he logged in, for a certain amount of time             *
 * ?                                                                                                            *
 */
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Navigate, Link } from "react-router-dom";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FormControl } from '@mui/material';
import { Alert as MuiAlert } from "@material-ui/lab";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTracking } from "react-tracking";
import { Field } from "formik";
import { useLocation } from "react-router-dom";
import { getTokenByID } from "../servicesRedux/AuthentificationService";
import AuthGuard from "../core/guard/AuthGuard";
import Loader from "../components/async/Loader";
import MultiLanguage from "../components/language/MultiLanguage";
import Constants from "../components/constants/Constants";
import version from "../version";
import requests from "../components/axios/Requests";
import useReplacer from "../hooks/replacer";
import { signIn } from "../core/services/api/authService/AuthService";
import { SetTenantAction } from "../redux/action/TenantAction";

import "../theme/login/Login.scss";

const theme = createTheme();
const Alert = styled(MuiAlert)(spacing);

export const signOut = () => localStorage.removeItem("interv");

export const Login = () => {
  const [loader, setLoader] = useState(false);
  const [rememberId] = useState(Cookies.get("saveId") === "true" ? true : false);
  const [loggedState] = useState(Cookies.get("Payload") ?? false);
  const { translation } = useSelector((state) => state.languageReducer);
  const [rememberMe] = useState(false);
  const history = useNavigate();
  const tracking = useTracking();
  const [accountDeactivationNotification, setAccountDeactivationNotification] = useState(false);
  const [settings, setSettings] = useState(null);
  const tenant = useSelector(state => state.tenantReducer.tenant);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const replacer = useReplacer();

  useEffect(() => {
    if (location.state && location.state.accountDeactivated) {
      setAccountDeactivationNotification(true);
    }
  }, [location.state]);


  useEffect(() => {
    getTokenByID(requests.GetSettings).then((res) => {
      setSettings(res);
      if (res.fullCustomerName) {
        dispatch(SetTenantAction({
          ...tenant,
          fullCustomerName: res.fullCustomerName
        }));
      }
    }
    );
  }, []);

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(translation.Login_EmailNotValid)
        .max(255)
        .required(translation.Login_EmailError),
      password: Yup.string().max(255).required(translation.Login_PasswordError),
      remember: Yup.boolean(),
    });

  const handleLogin = (values, { setErrors, setStatus, setSubmitting }) => {
    setLoader(true);
    tryLogin(values, { setErrors, setStatus, setSubmitting });
  };

  const tryLogin = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      await signIn(
        {
          Email: values.email,
          Password: values.password,
          remember: values.remember
        },
        values.remember ? values.remember : rememberMe,
        history,
        translation
      );
      setTimeout(() => { history("/"); }, [1500]);
      tracking.trackEvent(
        {
          UserEventId: Number(Constants.UserEvent.CLICK.enum),
          UserActionId: Number(Constants.UserAction.LOGIN.enum),
        }
      );
    } catch (error) {
      if (error.response.status === 403) {
        setAccountDeactivationNotification(true);
      }
      setLoader(false);
      const message = error || translation.Login_FormError;
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <>
      {accountDeactivationNotification &&
        <Alert onClose={() => {
          setAccountDeactivationNotification(false);
          navigate('/', { replace: true });
        }} mb={5} severity="error">
          <p className="user__detactivation-message" dangerouslySetInnerHTML={{ __html: translation.Account_Deactivation_Msg }}></p>
        </Alert>}
      <div className="login">
        {loggedState && <Navigate to="/" />}
        <div className="login--connect">
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              style={{
                marginTop: 0
              }}
            >
              <Formik
                initialValues={{
                  email:
                    Cookies.get("email") !== undefined
                      ? Cookies.get("email")
                      : "",
                  password:
                    Cookies.get("password") !== undefined
                      ? Cookies.get("password")
                      : "",
                  remember: rememberMe,
                  submit: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                {loader ? (
                  <Box
                    component="div"
                    sx={{ mt: 1 }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100vh'
                    }}
                  >
                    <Loader />
                  </Box>
                ) : (
                  ({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <Box
                      component="form"
                      noValidate
                      sx={{ mt: 1 }}
                      onSubmit={handleSubmit}
                    >
                      <Grid container spacing={2} className="auth__background--container">
                        <Grid item xs={12} md={6} lg={6} className="auth__left--background">
                          <div className="auth__left--background--internal">
                            <img className="login--logo" src={settings?.logoUrl} alt="logo" />
                            <Typography
                              className="login--connect--typography"
                              component="h1"
                              variant="h5"
                            >
                              {translation?.Login_Welcome}
                            </Typography>
                            {settings?.registrationFormState &&
                              <Typography
                                className="login--new--typography"
                                component="h1"
                                variant="h5"
                              >
                                {replacer(translation?.New_to_TC)}
                                <Link to="/register" className="create_account">
                                  <p>{translation?.Create_Account}</p>
                                </Link>
                              </Typography>
                            }

                            <FormControl className="admin__form-control">
                              <label>{translation.Email}</label>
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="email"
                                name="email"
                                variant="outlined"
                                label={translation?.Login_Email}
                                value={values.email}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </FormControl>

                            <FormControl className="admin__form-control">
                              <label>{translation?.Login_Password}</label>
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="password"
                                name="password"
                                variant="outlined"
                                label={translation?.Login_Password}
                                value={values.password}
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                              <div className="remember_container">
                                <div className="remember-label">
                                  <Field id="remember" name="remember" className="checkboxfield" type="checkbox" />
                                  <label for="remember" className="login--grid--label">{translation?.Login_Remember}</label>
                                </div>
                                <Link to="/reset-password" variant="body2">
                                  <p className="login--grid--paragraph">
                                    {translation?.Login_Forgot}
                                  </p>
                                </Link>
                              </div>
                            </FormControl>

                            {(errors.submit && !accountDeactivationNotification) && (
                              <Alert mb={5} severity="warning" className="login__alert--container">
                                {translation.Incorrect_Credentials}
                              </Alert>
                            )}

                            <Button
                              className="login--connect--button"
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                              disabled={isSubmitting}
                              onClick={(e) => AuthGuard(e)}
                            >
                              {translation?.Login_In}
                            </Button>

                            <MultiLanguage isFromLogin={true} />

                            <FormControl className="admin__form-control">
                              <h6 style={{ textAlign: "center" }}>Version {version}</h6>
                            </FormControl>

                          </div>
                        </Grid>
                        <Grid className="auth__right-background" item xs={12} md={6} lg={6}>
                          <Box
                            component={Grid}
                            item
                            xs={12}
                            display={{ xs: "none", md: "block", lg: "block" }}
                          >
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )
                )}
              </Formik>
            </Box>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default Login;
