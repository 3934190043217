import "./OnBoarding.scss"
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const EnjoyTC = ({handleDoneOnboarding}) => {

    const { translation } = useSelector(state => state.languageReducer);

    const [time, setTime] = useState(5);

    useEffect(() => {

        const interval = setInterval(() => {
            setTime(time - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [time]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            handleDoneOnboarding();
        }, 5000);

        return () => clearTimeout(timeout);

    }, []);

    
    
    return (
        <div className="onBoarding__content">
            <div className="onBoarding__content--title">
                <h1>{translation.Enjoy_Top_Cream}</h1>
            </div>
            <div className="onBoarding__content--text">
                <p>{translation.Start_Watching_Conferences}</p>
            </div>
            <div className="onBoarding__content--redirect">
                {translation.Redirect_To_Home_Page.replace("{time}", time)}
            </div>
        </div>
    )
}

export default EnjoyTC;