import { combineReducers } from "redux";
import authentificationReducer from "./AuthentificationReducer";
import homepageReducer from "./HomepageReducer";
import globalReducer from "./GlobalReducer";
import adminReducer from "./AdminReducer";
import conferenceReducer from "./ConferenceReducer";
import downloadedReducer from "./DownloadedReducer";
import recommendedReducer from "./RecommendedReducer";
import searchReducer from "./SearchReducer";
import channelReducer from "./ChannelReducer";
import speakerReducer from "./SpeakerReducer";
import paginationReducer from "./PaginationReducer";
import commentsReducer from "./CommentsReducer";
import redirectReducer from "./RedirectReducer";
import videoCurrentTimeReducer from "./VideoCurrentTimeReducer";
import languageReducer from "./LanguageReducer";
import subscriptionsReducer from "./SubscriptionReducer";
import relatedVideoReducer from "./RelatedVideoReducer";
import playlistReducer from "./PlaylistReducer";
import viewallReducer from "./ViewAllReducer";
import mediaTypeReducer from "./MediaTypeReducer";
import categoryFilterReducer from "./CategoryFilterReducer";
import tenantReducer from "./TenantReducer";
import exploreAiReducer from "./ExploreAiReducer";

const roots = combineReducers({
  authentificationReducer,
  homepageReducer,
  globalReducer,
  adminReducer,
  conferenceReducer,
  downloadedReducer,
  recommendedReducer,
  searchReducer,
  channelReducer,
  speakerReducer,
  paginationReducer,
  commentsReducer,
  redirectReducer,
  videoCurrentTimeReducer,
  languageReducer,
  subscriptionsReducer,
  relatedVideoReducer,
  playlistReducer,
  viewallReducer,
  mediaTypeReducer,
  categoryFilterReducer,
  tenantReducer,
  exploreAiReducer
});

export const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === "DESTROY_SESSION") {
    state = undefined;
  }

  return roots(state, action);
};
