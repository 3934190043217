import * as types from "../constant";

export function SetLanguage(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SET_LANGUAGE, payload });
  };
}

export function GetTranslation(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_TRANSLATION, payload });
  };
}

export function updateTranslation(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_TYPE_TRANSLATION, payload });
  };
}


