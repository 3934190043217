import { useEffect } from "react";
import { useSelector } from "react-redux";

const Control = props => {
    const { videoCurrentTime } = useSelector(state => state.videoCurrentTimeReducer);
    useEffect(() => {
        if (props && props.player && props.player.current && props.player.current.player) {
            props.player.current.player.currentTime(videoCurrentTime.time);
            props.player.current.player.play()
        }
    }, [videoCurrentTime]);
  
    return <></>;
};

export default Control;