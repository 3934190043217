// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * NestedList component *                                        *
 * ?          Nested is a way found to create a multiple input choice usable to change the research filter      *
 */
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@mui/material";
import { ClickAwayListener } from "@material-ui/core";

import "./NestedLists.scss";

const styles = () => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: "#CCC",
    color: "black",
  },
  nested: {
    zIndex: "3",
  },
});

const SpecialNestedLists = props => {
  const { classes, GetformData, checkInfo, comboID, data, checkState } = props;
  const [open, setOpen] = useState(false);
  const initialFormData = Object.freeze([]);
  const [filterList, setFilterList] = useState(initialFormData);
  const items = data;
  const [mapState] = useState(false);

  useEffect(() => {
    if (filterList.length === 0) {        
      GetformData({
        "isDiscover": false,
        "isExpert": false,
        "isACT": false
      }, comboID);
    } else { 
      GetformData({
        "isDiscover": filterList.includes("isDiscover"),
        "isExpert": filterList.includes("isExpert"),
        "isACT": filterList.includes("isACT")
      }, comboID);
    }
    checkInfo(!checkState);
  }, [filterList]);

  /* ************************************* Check value Existance into List ********************************************** */
  const checkExistance = id => {
    const temp = filterList.find(fl => fl === id);
    if (temp === undefined) {
      setFilterList([
        ...filterList,
        id,
      ]); 
    } else {
      setFilterList(filterList.filter(fl => fl !== id));
    }    
  };

  /* **************************************** Display/Hide ComboBox items ************************************************ */
  const showCheckBoxList = () => setOpen(!open);

  const onItemClick = id => checkExistance(id);

  /* ************************************* Verifiy value checked into List ********************************************** */
  const getChecked = id => {
    const temp = filterList.find(fl => fl === id);
    if (temp === undefined) {
      return false;
    } else {
      return true;
    }  
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <List className="NestedListFilter-root-Speaker NestedListFilter-root-Speaker-extended">
          <ListItem button onClick={() => showCheckBoxList()}>
            <ListItemText primary={props.nestedListName} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </List>
        <List className="NestedListFilter-root-Speaker NestedListFilter-root-Speaker-extended">
          <div>
            <div style={{ borderRadius: 8 }}>
              <Collapse
                className="nested_collapseList"
                component="li"
                in={open}
                timeout="auto"
                unmountOnExit
                sx={{ position: 'absolute' }}
              >
                <List disablePadding>
                {(mapState === false || filterList?.length === 0
                  ? items
                  : filterList
                )?.map((sitem, index) => (
                  <div
                    onClick={() => onItemClick(sitem.code)}
                    key={index++}
                    className="nested_collapseList_item"
                  >
                    <ListItem
                      button
                      key={sitem.id}
                      className={classes.nested}
                    >
                      <ListItemText
                        key={sitem.id}
                        primary={sitem.label}                                     
                      />
                      <Checkbox
                        style={{ color: "#b6aa90" }}
                        checked={getChecked(sitem.code)}
                        className="duration__default-checkbox"
                      />
                    </ListItem>
                  </div>)
                  )}
                </List>
              </Collapse>{" "}
            </div>
          </div>
        </List>
      </div>
    </ClickAwayListener>
  );
};

SpecialNestedLists.propTypes = {
  classes: PropTypes.object.isRequired,
  GetformData: PropTypes.func,
  checkInfo: PropTypes.any,
  comboID: PropTypes.object,
  data: PropTypes.object,
  myInfo: PropTypes.object
};

export default withStyles(styles)(SpecialNestedLists);
