import { getAllSocialMedia } from "../../servicesRedux/ChannelService";
import * as types from "../constant";
import requests from "../../components/axios/Requests";

export function GetContentAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_CONTENT, payload });
  };
}
export function GetChannelAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_CHANNEL, payload });
  };
}

export function GetAllSocialMediaAction() {
  return (dispatch) => {
    dispatch({ type: types.GET_ALL_SOCIAL_MEDIA });

    getAllSocialMedia(requests.GetAllSocialMedia).then((item) =>
      dispatch({ type: types.GET_ALL_SOCIAL_MEDIA_SUCCESS, payload: item })
    );
  };
}
