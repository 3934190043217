import axios from "axios";

export function getContentList(requests, type, pageParams) {
  requests = requests.replace("{type}", type)
    .replace("{limit}", pageParams.limit)
    .replace("{page}", pageParams.page);
    
  return new Promise((resolve, reject) => {
    axios
      .get(requests, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getContentCurationList(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSatatusSummary(requests) {
  return new Promise((resolve, reject) => {
    axios.get(requests, { withCredentials: true, })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getContentById(requests, id) {
  requests = requests.replace("{id}", id);
  return new Promise((resolve, reject) => {
    axios.get(requests, { withCredentials: true, })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}