import React, { useState, useEffect } from 'react';
import "./DownloadProgressBar.scss"
const DownloadProgressBar = () => {
  const [downloadProgress, setDownloadProgress] = useState({});

  useEffect(() => {
    const handleDownloadMessage = (event) => {
      const { type, conferenceId, progress } = event.data;

      if (type === 'DOWNLOAD_PROGRESS') {
        // Update the download progress state based on the received data
        setDownloadProgress((prevProgress) => ({
          ...prevProgress,
          [conferenceId]: progress,
        }));
      } else if (type === 'DOWNLOAD_SUCCESS') {
        // Remove the completed conference from the download progress state
        setDownloadProgress((prevProgress) => {
          const { [conferenceId]: _, ...updatedProgress } = prevProgress;
          return updatedProgress;
        });
      }
    };

    // Listen for the 'message' event from the service worker
    navigator.serviceWorker.addEventListener('message', handleDownloadMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      navigator.serviceWorker.removeEventListener('message', handleDownloadMessage);
    };
  }, []);

  const totalProgress = Object.values(downloadProgress).reduce((sum, progress) => sum + progress, 0);
  const averageProgress = totalProgress / Object.keys(downloadProgress).length;
  const roundedProgress = Math.round(averageProgress) || 0;

  return (
    <>
      {Object.keys(downloadProgress).length > 0 && (
        <div className="progress-bar">
          <div className="progress" style={{ width: `${roundedProgress}%` }}></div>
        </div>
      )}
    </>
  );
};

export default DownloadProgressBar;