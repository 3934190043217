import * as types from "../constant";

export function GetCommentsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_COMMENTS, payload });
  };
}

export function AddCommentsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.ADD_COMMENTS, payload });
  };
}

export function AddReplyAction(idParent, newReply) {
  const payload = { idParent, newReply };
  return async (dispatch) => {
    dispatch({ type: types.ADD_REPLY, payload });
  };
}

export function newReplyStateAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.NEW_REPLY_STATE, payload });
  };
}

export function UpdateCommentsByIDAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_COMMENTS, payload });
  };
}
export function AddChildCountCommentsByIDAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.ADD_CHILD_COUNT_COMMENT, payload });
  };
}
export function RemoveChildCountCommentsByIDAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.REMOVE_CHILD_COUNT_COMMENT, payload });
  };
}

export function deleteCommentByIDAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_COMMENT_BY_ID, payload });
  };
}

export function deleteReplyByIDAction(idReply, idParent) {
  const payload = { idReply, idParent };
  return async (dispatch) => {
    dispatch({ type: types.DELETE_REPLY_BY_ID, payload });
  };
}

export function getAllRepliesByIDAction(payload, id) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ALL_REPLIES_BY_ID, payload, id });
  };
}
