import React from "react";
import Alert from "@mui/material/Alert";

export const AlertContainer = props => {
  return (
    <div className={"playlists_page_new_playlist_error"}>
      <Alert severity="error">{props.errorMessage}</Alert>
    </div>
  );
};
  