import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from "@material-ui/core";
import { Chip, Stack } from "@mui/material";

import requests from "../components/axios/Requests";
import { GetAllSocialMediaAction } from "../redux/action/ChannelAction";
import { getChannelById } from "../servicesRedux/ChannelService";
import Social from "../components/social/Social";
import Row from "../components/row/Row";
import { TabPanel } from "../components/TabPanel/TabPanel";
import FollowSpeakerButton from "../components/switching/FollowSpeakerButton";

import PersonPlaceholder from "../assets/images/person-placeholder.jpg";

import "../theme/channelPage/ChannelPage.scss";

const styledContentOwnerSubscribed = {
  marginTop: 4,
  whiteSpace: "nowrap",
};

const ChannelPage = () => {
  const dispatch = useDispatch();
  const { allSocialMedia, loadingSocialMedia } = useSelector(state => state.channelReducer);
  const { translation } = useSelector(state => state.languageReducer);
  const { id } = useParams();
  const [contentOwner, setContentOwner] = useState(null);
  const [value, setValue] = useState(1);
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (id !== undefined && id !== null) {
      getChannelById(requests.GetChannelById, id).then(item => setContentOwner(item));
    }
  }, [id]);

  useEffect(() => {
    dispatch(GetAllSocialMediaAction());
  }, []);

  const handleChange = newValue => setValue(newValue);

  const StyledChip = withStyles({
    root: {
      backgroundColor: '#F7EED8',
      color: '#000000',
      alignSelf: 'center',
      alignItems: 'center',
      textTransform: 'capitalize',
      marginLeft: '0 !important',
      display: 'flex',
      justifyContent: 'space-between',
    },
  })(Chip);

  return (
    <div className="channel">
      <div className="contentOwner__banner">
        {contentOwner !== undefined && contentOwner !== null && (
          <Box className="contentOwner__container" sx={{ flexGrow: 1, padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} lg={2}>
                <Box
                  component={Grid}
                  item
                  xs={12}
                  display={{ xs: "none", md: "block", lg: "block" }}
                >
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <>
                  <Grid item xs={12} md={12} lg={12} className="contentOwners__logo-image-container" style={{
                    display: 'flex'
                  }}>
                    <Grid item xs={12} md={4} lg={4} className="contentOwner__logo-wrapper"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        flexDirection: 'row'
                      }}
                    >
                      <div className="contentOwner_logo_image--img-wrapper">
                        <div className="contentOwner_logo_image" style={{
                          backgroundImage: `url(${(contentOwner?.avatar && contentOwner?.avatar !== "") ?? PersonPlaceholder})`
                        }}>
                        </div>
                      </div>
                      <div className="exit__button-channel only__mobile">
                        <IconButton onClick={() => navigate(-1)}>
                          <CloseIcon fontSize="large" />
                        </IconButton>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} className="contentOwner_details-wrapper">
                      <Typography variant="h4" className="contentOwner__name">
                        {contentOwner?.name}
                      </Typography>
                      <Typography variant="h6" className="contentOwner__desc">
                        {contentOwner?.description}
                      </Typography>
                      {contentOwner &&
                        <FollowSpeakerButton translation={translation} channel={contentOwner} />}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} sx={styledContentOwnerSubscribed}>
                    <div className="contentOwner__informations">
                      <div className="contentOwner__informations__content">
                        <div className="contentOwner__informations--summary">
                          <p className="contentOwner__description">{contentOwner?.contentOwners?.description}</p>
                          <div className="contentOwner__informations--social-media">
                            <Social
                              allSocialMedia={allSocialMedia}
                              contentOwnerSocialMedia={contentOwner?.contentOwners?.contentOwnerSocialMedia}
                              loadingSocialMedia={loadingSocialMedia}
                            />
                          </div>
                          <div className="talks__about-section">
                            <span>{translation.Talks_About}</span>
                            <div className="tabs__section">
                              <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
                                {tags?.map((item, index) => <StyledChip label={item.label} sx={{ backgroundColor: "#F7EED8" }} />)}
                              </Stack>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Box
                  component={Grid}
                  item
                  xs={12}
                  display={{ xs: "none", md: "block", lg: "block" }}
                >
                  <div className="exit__button-channel only__desktop">
                    <IconButton onClick={() => navigate(-1)}>
                      <CloseIcon fontSize="large" />
                    </IconButton>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
      <div className="contentOwner__informations--content--title">
        <div className="contentOwner-page__row__container">
          <TabPanel value={value} index={0}>
            <div className={`downloaded__commun ${value === 0 && "downloadedIsActive"}`}>
              {contentOwner?.id && (
                <Row
                  contentType={"channel"}
                  contentOwner={true}
                  appearanceType={"row"}
                  callback={setTags}
                  person={contentOwner}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={`downloaded__commun ${value === 1 && "downloadedIsActive"}`}>
              {contentOwner?.id && (
                <Row
                  contentType={"channel"}
                  contentOwner={true}
                  appearanceType={"row"}
                  callback={setTags}
                  person={contentOwner}
                />
              )}
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default ChannelPage;

