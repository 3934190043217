import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox } from "@material-ui/core";

function TermsAndConditions(props) {
    const { acceptTerms, setAcceptTerms, translation } = props;

    const [open, setOpen] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const buttonStyle = {
        borderColor: acceptTerms ? 'green' : 'black',
    };
    //check mark unicode


    return (
        <div>
            <div onClick={handleClickOpen}>
                <Checkbox
                    checked={acceptTerms}
                    // onChange={(e) => setAcceptTerms(e.target.checked)}
                    error={isFormSubmitted ? !acceptTerms : ""}
                    name="acceptTerms"
                    color="primary"
                    style={{ paddingLeft: '0px', paddingRight: '0px', transform: "scale(1.3)" }}
                />
                <Button variant="text" style={buttonStyle} >
                    {acceptTerms ?
                        <p style={{ fontSize: '1rem', color: 'green', fontFamily: ' "Roboto", "Helvetica", "Arial", sans-serif ', textTransform: "none" }}>{translation.Accepted_Terms}</p>
                        :
                        <p style={{ fontSize: '1rem', color: 'black', fontFamily: ' "Roboto", "Helvetica", "Arial", sans-serif ', textTransform: "none" }}>{translation.Accept_Terms}</p>
                    }
                </Button>
            </div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="terms-dialog-title">
                <DialogTitle id="terms-dialog-title">Terms and Conditions</DialogTitle>
                <DialogContent>
                    {/* Add your terms and conditions content here */}
                    <div className="container">

                        <h1 className="title align-center">Privacy Policy</h1>

                        <div className="home__discover__mobile rellax" data-rellax-speed="1">
                        </div>


                        <div className="content">

                            <p className="p1">Revised:<span className="Apple-converted-space">&nbsp; </span>July 2022</p>
                            <p className="p1">These Terms of Service (“<b><i>Terms</i></b>”) govern your use of the<span
                                className="Apple-converted-space">&nbsp; </span>website (“<b><i>Site</i></b>”) and any mobile application,
                                application programming interfaces, and other services offered by<span className="Apple-converted-space">&nbsp;
                                </span>as well as services offered through third parties integrating<span
                                    className="Apple-converted-space">&nbsp; </span>functionality (“<b><i>Services</i></b>”).<span
                                        className="Apple-converted-space">&nbsp; </span>TOP CREAM<span className="Apple-converted-space">&nbsp;
                                </span>(“<b><i>we</i></b>” or “<b><i>us</i></b>”) provides the Site and Services.<span
                                    className="Apple-converted-space">&nbsp; </span>“<b><i>You</i></b>” refers to you as a user of the Site or
                                Services.</p>
                            <p className="p1">BY USING THE SITE OR SERVICES, YOU ARE AGREEING TO THESE TERMS.<span
                                className="Apple-converted-space">&nbsp; </span>PLEASE READ THEM CAREFULLY.</p>
                            <p><b>Eligibility</b></p>
                            <p className="p1">You must be at least 18 years old to use the Site or the Services.<span
                                className="Apple-converted-space">&nbsp; </span>If you are under the age of majority in your state of
                                residence, a minor, your parent or legal guardian must agree to these Terms on your behalf and you may only
                                access and use the Site and Services with permission from your parent or legal guardian.<span
                                    className="Apple-converted-space">&nbsp;</span></p>
                            <p><b>Additional Terms</b></p>
                            <p className="p1">Some of our Services have additional terms and conditions (“<b><i>Additional Terms</i></b>”).<span
                                className="Apple-converted-space">&nbsp; </span>Where Additional Terms apply to a Service, we will make them
                                available for you to read through your use of that Service.<span className="Apple-converted-space">&nbsp;
                                </span>By using that Service, you agree to the Additional Terms.</p>
                            <p><b>Acceptable Use of the Site and Services</b></p>
                            <p className="p1">You are responsible for your use of the Site and Services, and for any use of the Site or Services
                                made using your account.<span className="Apple-converted-space">&nbsp; </span>Our goal is to create a positive,
                                useful, and safe user experience.<span className="Apple-converted-space">&nbsp; </span>To promote this goal, we
                                prohibit certain kinds of conduct that may be harmful to other users or to us.<span
                                    className="Apple-converted-space">&nbsp; </span>When you use the Site or Services, you may not:</p>
                            <ul>
                                <li className="p2">violate any law or regulation;</li>
                                <li className="p2">violate, infringe, or misappropriate other people's intellectual property, privacy,
                                    publicity, or other legal rights;</li>
                                <li className="p2">post or share anything that is illegal, abusive, harassing, harmful to reputation,
                                    pornographic, indecent, profane, obscene, hateful, racist, or otherwise objectionable;</li>
                                <li className="p2">send unsolicited or unauthorized advertising or commercial communications, such as spam;</li>
                                <li className="p2">engage in spidering or harvesting, or participate in the use of software, including spyware,
                                    designed to collect data from the Site or Services;</li>
                                <li className="p2">transmit any viruses or other computer instructions or technological means whose purpose is
                                    to disrupt, damage, or interfere with the use of computers or related systems;</li>
                                <li className="p2">stalk, harass, or harm another individual;</li>
                                <li className="p2">impersonate any person or entity or perform any other similar fraudulent activity, such as
                                    phishing;</li>
                                <li className="p2">use any means to scrape or crawl any Web pages contained in the Site;</li>
                                <li className="p2">attempt to circumvent any technological measure implemented by us or any of our providers or
                                    any other third party (including another user) to protect the Site or Services;</li>
                                <li className="p2">attempt to decipher, decompile, disassemble, or reverse engineer any of the software or other
                                    underlying code used to provide the Site or Services; or</li>
                                <li className="p2">advocate, encourage, or assist any third party in doing any of the foregoing.</li>
                            </ul>
                            <p><b>Ownership</b></p>
                            <p className="p1">Other than User Content, we own or license all right, title, and interest in and to (a) the Site
                                and Services, including all software, text, media, and other content available on the Site and Services
                                (“<b><i>Our</i></b> <b><i>Content</i></b>”); and (b) our trademarks, logos, and brand elements
                                (“<b><i>Marks</i></b>”).<span className="Apple-converted-space">&nbsp; </span>The Site and Services, Our
                                Content, and Marks are all protected under U.S. and international laws.<span
                                    className="Apple-converted-space">&nbsp; </span>The look and feel of the Site and Services are copyright ©
                                All rights reserved.<span className="Apple-converted-space">&nbsp; </span>You may not duplicate, copy, or reuse
                                any portion of the HTML/CSS, Javascript, or visual design elements or concepts without express written
                                permission from TOP CREAM.</p>
                            <p><b>Privacy</b></p>
                            <p className="p1">Your privacy is very important to us.<span className="Apple-converted-space">&nbsp; </span>Our<span
                                className="Apple-converted-space">&nbsp; </span>explains how we collect, use, protect, and when we share
                                personal information and other data with others.<span className="Apple-converted-space">&nbsp; </span>You are
                                responsible for maintaining the confidentiality of your account information, including your username and
                                password.<span className="Apple-converted-space">&nbsp; </span>You are responsible for all activities that occur
                                under your account and you agree to notify us immediately of any unauthorized access or use of your
                                account.<span className="Apple-converted-space">&nbsp; </span>We are not responsible or liable for any damage or
                                loss related to any unauthorized access or use of your account.</p>
                            <p><b>Links</b></p>
                            <p className="p1">The Site and Services may contain links to other websites and online resources.<span
                                className="Apple-converted-space">&nbsp; </span>A link to a third party’s website does not mean that we
                                endorse it or that we are affiliated with it.<span className="Apple-converted-space">&nbsp; </span>We are not
                                responsible or liable for any damage or loss related to the use of any third-party website.<span
                                    className="Apple-converted-space">&nbsp; </span>You should always read the terms and conditions and privacy
                                policy of a third-party website before using it. <span className="Apple-converted-space">&nbsp;</span></p>
                            <p><b>Changes to the Site or Services</b></p>
                            <p className="p1">We enhance and update the Site and Services often.<span className="Apple-converted-space">&nbsp;
                            </span>We may change or discontinue the Site or Services at any time, with or without notice to you.</p>
                            <p><b>Termination</b></p>
                            <p className="p1">We reserve the right to not provide the Site or Services to any person.<span
                                className="Apple-converted-space">&nbsp; </span>We also reserve the right to terminate any user's right to
                                access the Site or Services at any time, in our discretion.<span className="Apple-converted-space">&nbsp;
                                </span>If you violate any of these Terms, your permission to use the Site and Services automatically
                                terminates.</p>
                            <p><b>Disclaimer and Limitations on Our Liability</b></p>
                            <p>You use the site and services at your own risk. The site and services are provided on an “as is” and “as
                                available” basis. To the extent permitted by applicable law, our company and its officers, employees,
                                directors, shareholders, parents, subsidiaries, affiliates, agents, and licensors (“affiliates”) disclaims
                                all warranties, conditions, and representations of any kind, whether express, implied, statutory, or
                                otherwise, including those related to merchantability, fitness for a particular purpose, and
                                non-infringement and those arising out of course of dealing or usage of trade.</p>
                            <p>Our company and its affiliates make no representations or warranties about the accuracy or completeness of
                                content available on or through the site or services, or the content of any websites or online services
                                linked to or integrated with the site or services.&nbsp; Our company and its affiliates will have no
                                liability for any:&nbsp; (a) errors, mistakes, or inaccuracies of content; (b) personal injury or property
                                damage resulting from your access to or use of the site or services; (c) any unauthorized access to or use
                                of our servers or of any personal information or user data; (d) any interruption of transmission to or from
                                the site or services; (e) any bugs, viruses, trojan horses, or the like which may be transmitted on or
                                through the site or services by any third party; or (f) any loss or damage of any kind incurred as a result
                                of the use of any content posted or shared through the site or services.</p>
                            <p>You understand and agree that any material or information downloaded or otherwise obtained through the use of
                                the site or services is done at your own risk and that you will be solely responsible for any damage arising
                                from doing so.&nbsp; No advice or information, whether oral or written, obtained by you from us or through
                                the site or services will create any warranty not expressly made.</p>
                            <p>To the maximum extent permitted by applicable law, in no event will we be liable to you or to any third party
                                for any indirect, special, incidental, punitive, or consequential damages (including for loss of profits,
                                revenue, or data) or for the cost of obtaining substitute products arising out of or in connection with
                                these terms, however caused, whether such liability arises from any claim based upon contract, warranty,
                                tort (including negligence), strict liability or otherwise, and whether or not we've been advised of the
                                possibility of such damages.</p>
                            <p>To the maximum extent permitted by applicable law, our total cumulative liability to you or any third party
                                under these terms, from all causes of action and all theories of liability, will be limited to and will not
                                exceed the fees you have actually paid us during the twelve (12) months preceding the claim giving rise to
                                such liability.</p>
                            <p>You understand and agree that we have set our prices and entered into these Terms with you in reliance upon
                                the limitations of liability set forth in these Terms, which allocate risk between us and form the basis of
                                a bargain between the parties.</p>
                            <p><b>Indemnification</b></p>
                            <p className="p1">You agree to indemnify and hold harmless our company and its Affiliates from and against any and
                                all claims, costs, proceedings, demands, losses, damages, and expenses (including, without limitation,
                                reasonable attorney's fees and legal costs) of any kind or nature, arising from or relating to, any actual
                                or alleged breach of these Terms by you or anyone using your account.<span
                                    className="Apple-converted-space">&nbsp; </span>If we assume the defense of such a matter, you will
                                reasonably cooperate with us in such defense.</p>
                            <p><b>Arbitration Agreement &amp; Waiver of Certain Rights</b></p>
                            <p className="p1">You and TOP CREAM agree that we will resolve any disputes between us through binding and final
                                arbitration instead of through court proceedings.<span className="Apple-converted-space">&nbsp; </span>You and
                                TOP CREAM hereby waive any right to a jury trial of any Claim (defined below).<span
                                    className="Apple-converted-space">&nbsp; </span>All controversies, claims, counterclaims, or other disputes
                                arising between you and TOP CREAM relating to these Terms or the Offerings (each a “<b><i>Claim</i></b>”)
                                shall be submitted for binding arbitration in accordance with the Rules of the American Arbitration
                                Association (“<b><i>AAA Rules</i></b>”).<span className="Apple-converted-space">&nbsp; </span>The arbitration
                                will be heard and determined by a single arbitrator.<span className="Apple-converted-space">&nbsp; </span>The
                                arbitrator’s decision in any such arbitration will be final and binding upon the parties and may be enforced
                                in any court of competent jurisdiction.<span className="Apple-converted-space">&nbsp; </span>The parties agree
                                that the arbitration will be kept confidential and that the existence of the proceeding and any element of
                                it (including, without limitation, any pleadings, briefs or other documents submitted or exchanged and any
                                testimony or other oral submissions and awards) will not be disclosed beyond the arbitration proceedings,
                                except as may lawfully be required in judicial proceedings relating to the arbitration or by applicable
                                disclosure rules and regulations of securities regulatory authorities or other governmental agencies.<span
                                    className="Apple-converted-space">&nbsp;</span></p>
                            <p className="p1">If you demonstrate that the costs of arbitration will be prohibitive as compared to the costs of
                                litigation, TOP CREAM will pay as much of the administrative costs and arbitrator's fees required for the
                                arbitration as the arbitrator deems necessary to prevent the cost of the arbitration from being
                                prohibitive.<span className="Apple-converted-space">&nbsp; </span>In the final award, the arbitrator may
                                apportion the costs of arbitration and the compensation of the arbitrator among the parties in such amounts
                                as the arbitrator deems appropriate.<span className="Apple-converted-space">&nbsp;</span></p>
                            <p className="p1">This arbitration agreement does not preclude you or TOP CREAM from seeking action by federal,
                                state, or local government agencies.<span className="Apple-converted-space">&nbsp; </span>You and TOP CREAM also
                                have the right to bring qualifying claims in small claims court.<span className="Apple-converted-space">&nbsp;
                                </span>In addition, you and TOP CREAM retain the right to apply to any court of competent jurisdiction for
                                provisional relief, including pre-arbitral attachments or preliminary injunctions, and any such request
                                shall not be deemed incompatible with these Terms, nor a waiver of the right to have disputes submitted to
                                arbitration as provided in these Terms.</p>
                            <p className="p1">Neither you nor TOP CREAM may act as a className representative or private attorney general, nor
                                participate as a member of a className of claimants, with respect to any Claim.<span
                                    className="Apple-converted-space">&nbsp; </span>Claims may not be arbitrated on a className or representative
                                basis.<span className="Apple-converted-space">&nbsp; </span>The arbitrator can decide only your and/or TOP
                                CREAM’s individual Claims.<span className="Apple-converted-space">&nbsp; </span>The arbitrator may not
                                consolidate or join the claims of other persons or parties who may be similarly situated.<span
                                    className="Apple-converted-space">&nbsp;</span></p>
                            <p className="p1">If any provision of this Section is found to be invalid or unenforceable, then that specific
                                provision shall be of no force and effect and shall be severed, but the remainder of this Section shall
                                continue in full force and effect.<span className="Apple-converted-space">&nbsp; </span>No waiver of any
                                provision of this Section of the Terms will be effective or enforceable unless recorded in a writing signed
                                by the party waiving such a right or requirement.<span className="Apple-converted-space">&nbsp; </span>Such a
                                waiver shall not waive or affect any other portion of this Terms.<span className="Apple-converted-space">&nbsp;
                                </span>This Section of the Terms will survive the termination of your relationship with TOP CREAM.</p>
                            <p className="p1">This section limits certain rights, including the right to maintain a court action, the right to a
                                jury trial, the right to participate in any form of className or representative claim, the right to engage in
                                discovery except as provided in AAA rules, and the right to certain remedies and forms of relief. Other
                                rights that you or TOP CREAM would have in court also may not be available in arbitration.</p>
                            <p><b>Other Provisions</b></p>
                            <p className="p1">Under no circumstances will we be held liable for any delay or failure in performance due in whole
                                or in part to any acts of nature or other causes beyond our reasonable control.</p>
                            <p className="p1">These Terms will be governed by and construed in accordance with the laws of the State of New
                                York, without giving effect to any conflict of laws rules or provisions. <span
                                    className="Apple-converted-space">&nbsp;</span></p>
                            <p className="p1">You agree that any action of whatever nature arising from or relating to these Terms, the Site, or
                                Services will be filed only in the state or federal courts located in New York, New York.<span
                                    className="Apple-converted-space">&nbsp; </span>You consent and submit to the personal jurisdiction of such
                                courts for the purposes of any such action. <span className="Apple-converted-space">&nbsp;</span></p>
                            <p className="p1">If any provision of these Terms is found to be unlawful or unenforceable, then that provision will
                                be deemed severable from these Terms and will not affect the enforceability of any other provisions. <span
                                    className="Apple-converted-space">&nbsp;</span></p>
                            <p className="p1">The failure by us to enforce any right or provision of these Terms will not prevent us from
                                enforcing such right or provision in the future.</p>
                            <p className="p1">We may assign our rights and obligations under these Terms, including in connection with a merger,
                                acquisition, sale of assets or equity, or by operation of law.</p>
                            <p><b>Changes to these Terms</b></p>
                            <p className="p1">From time to time, we may change these Terms.<span className="Apple-converted-space">&nbsp; </span>If
                                we change these Terms, we will give you notice by posting the revised Terms on the Site.<span
                                    className="Apple-converted-space">&nbsp; </span>Those changes will go into effect on the Revision Date shown
                                in the revised Terms.<span className="Apple-converted-space">&nbsp; </span>By continuing to use the Site or
                                Services, you are agreeing to the revised Terms. <span className="Apple-converted-space">&nbsp;</span></p>
                            <p className="p1"><b>PLEASE PRINT A COPY OF THESE TERMS FOR YOU RECORDS AND PLEASE CHECK THE SITE FREQUENTLY FOR ANY
                                CHANGES TO THESE TERMS.</b></p>
                            <p>&nbsp;</p>
                            <p><strong>Owner:</strong>&nbsp;WORLD WATCH CONFERENCE&nbsp;(called&nbsp;TOP CREAM), French SAS which
                                headquarter is&nbsp;68 rue du faubourg saint honoré,&nbsp;75008, France,&nbsp;registered in the Paris Trade
                                and Companies Register under number&nbsp;902436914,&nbsp;under the&nbsp;responsibility of M. Denis Jacquet,
                                as President.</p>
                            <p><strong>Responsible for publication:</strong> topcream.global</p>
                            <p><strong>Host:</strong> OVH - 2 rue Kellermann - 59100 Roubaix - France</p>
                            <p><strong>Photo credit:</strong> TOP CREAM</p>

                        </div>



                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={acceptTerms}
                                onChange={(e) => { setAcceptTerms(e.target.checked); setOpen(false) }}
                                error={isFormSubmitted ? !acceptTerms : ""}
                                name="acceptTerms"
                                color="primary"
                            />
                        }
                        label="I accept the terms and conditions"
                        error={isFormSubmitted && !acceptTerms}
                    />
                    {isFormSubmitted && !acceptTerms && (
                        <p className={"terms_error"}>{"Please accept the terms and conditions."}</p>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default TermsAndConditions;