import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { WithContext as ReactTags } from "react-tag-input";
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from "react-select";
import DefaultSelect from '@mui/material/Select';
import { Alert as MuiAlert } from "@material-ui/lab";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import requests from "../components/axios/Requests";
import { getAllCategories } from "../servicesRedux/GlobalService";
import { PostSuggestion } from "../servicesRedux/SuggestionService";
import { GetAllCategoriesAction } from "../redux/action/GlobalActions";
import useReplacer from "../hooks/replacer";
import ContentList from "../components/nestedList/contentJson/Content.json";
import { useTracking } from "react-tracking";
import Constants from "../components/constants/Constants";

import "../theme/suggestion/Suggestion.scss";

const SuggestionForm = () => {
  const dispatch = useDispatch();
  const { translation } = useSelector(state => state.languageReducer);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [content, setContent] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const tracking = useTracking();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const Alert = styled(MuiAlert)(spacing);
  const [submitted, setSubmitted] = useState(false);
  const replacer = useReplacer();
  useEffect(() => {
    getAllCategories(requests.getAllCategories).then(item => {
      dispatch(GetAllCategoriesAction(item));
      setAllCategories(item);
    });
    tracking.trackEvent(
      {
        UserEventId: Number(Constants.UserEvent.LOAD.enum),
        UserActionId: Number(Constants.UserAction.SUGGESTION_FORM.enum),
      }
    );
  }, []);

  useEffect(() => {
    const filteredSpeaker = filterSpeakers();
    if (filteredSpeaker.length) {
      setContent({
        ...content,
        speakers: filteredSpeaker,
      });
    }
  }, [selectedTags]);

  const handleCategory = event => {
    setContent({
      ...content,
      [event.target.name]: event.target.value,
    });
    setSubCategory(allCategories.find(all => all.parentId === event.target.value).subCategory);
  };

  const handleSubCategory = event => {
    setContent({
      ...content,
      [event.target.name]: event.target.value,
    });
  };  

  const handleAddition = e => {
    if (e && e.text) {
      setSelectedTags(prev => [
        ...prev,
        { id: (prev.length + 1).toString(), text: e.text },
      ]);
      setContent({
        ...content,
        speakers: selectedTags.text,
      });  
    } else if (e && !e.text) {
      setSelectedTags(prev => [
        ...prev,
        { id: (prev.length + 1).toString(), text: e },
      ]);
      setContent({
        ...content,
        speakers: selectedTags.text,
      });  
    }
  };

  const handleDelete = e => setSelectedTags(prev => prev.filter((tag, index) => index !== e));

  const onChange = event => {
    setContent({
      ...content,
      [event.target.name]: event.target.value,
    });
  };

  const onChangeLanguageEntry = event => {
    setContent({
      ...content,
      'language': event.id,
    });
  };

  const filterSpeakers = () => {
    const list = [];
    {
      Object.keys(selectedTags).map((key, index) => list.push(selectedTags[key].text));
    }
    return list;
  };

  const validationSchema = () => Yup.object().shape({ all: Yup.string().required("Required") });

  const handleSubmit = () => {
    setSubmitted(true);
    const speakers = selectedTags.map(e => e.text && e.text);
    setContent({
      ...content,
      speakers: speakers,
    });    
    const req = {
      title: content.title,
      url: content.link,
      description: content.description,
      languageId: content.language,
      categoryId: content.subCategory !== undefined && content.subCategory ? content.subCategory : content.category,
      speakers: speakers,
    };
    if (
      (speakers && speakers !== "" && speakers.length !== 0) &&
      content.title && 
      content.link && 
      content.description && 
      content.language && 
      content.subCategory && 
      content.category
    ) {
      setSuccess(true);
      PostSuggestion(requests.PostSuggestion, req).then();
      setTimeout(() => setSuccess(false), 2000);
      clearForm();
      setSubmitted(false);
    } else {
      setError(translation.Speakers_missing);
      setTimeout(() => {
        setError("");
        setSubmitted(false);
      }, 2500);
    }
    tracking.trackEvent(
      {
        UserEventId: Number(Constants.UserEvent.SUBMIT.enum),
        UserActionId: Number(Constants.UserAction.SUGGESTION_FORM.enum),
      }
    );
  };

  const clearForm = () => {
    setContent({
      ...content,
      title: null,
      link: null,
      speakers: [],
      category: [],
      subCategory: [],
      description: null,
      language: null,
    });
    setSelectedTags([]);
  };
  
  return (            
      <div className="suggestion">
        <div className="admin__middle-container suggestion__middle-container">
          <Formik
              initialValues={{
                submit: false,
              }}
              validationSchema={validationSchema}
            >
              {() => (
                <form
                  id="create-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (e?.target?.id === "create-form") {
                      handleSubmit();
                    }
                  }}
                >
                  <div className="container_Suggestion">
                    <div className="suggestion_Title">{replacer(translation.Suggestion_Form)}</div>
                      {success &&
                      <div className="first_time_login_success">
                        <Alert severity="success">
                          {translation?.Suggesstion_form_submitted}
                        </Alert>
                      </div>}

                      {error && 
                      <div className="first_time_login_success">
                        <Alert severity="error">
                          {translation?.Fill_out_form_errors}
                        </Alert>
                      </div>}

                        <FormControl className="admin__form-control">
                          <label>{translation.Suggestion_Title}</label>
                          <input
                            className={`admin__account__details--input ${
                              (submitted && 
                                (content?.title === undefined || 
                                content?.title === null || 
                                content?.title === '')
                              ) ? 'error__border_indicator' : ''}`}
                            name="title"
                            type="text"
                            value={content?.title ?? ""}
                            onChange={onChange}
                          />
                        </FormControl>                         

                        <FormControl className="admin__form-control">
                          <label>{translation.Suggestion_Link}</label>
                          <input
                            className={`admin__account__details--input ${(submitted && 
                              (content?.link === undefined || 
                                content?.link === null || 
                                content?.link === '')
                              ) ? 'error__border_indicator' : ''}`}
                            name="link"
                            type="text"
                            value={content?.link ?? ""}
                            onChange={onChange}
                          />
                        </FormControl>                         

                        <label className="suggestion__form--label">{translation.Suggestion_Category}</label>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className={`admin__form-control ${(submitted && 
                          ((content?.category && content?.category.length === 0) || 
                          content?.category === undefined || 
                          content?.category === null || 
                          content?.category === '')
                        ) ? 'error__border_indicator' : ''}`}>                          
                          <InputLabel id="demo-select-small">{translation.Suggestion_Category}</InputLabel>
                          <DefaultSelect
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={content?.category}
                            label={translation.Suggestion_Category}
                            onChange={handleCategory}
                            key={content?.category}
                            className={"suggestion__drop_downs"}
                            name="category"
                          >
                            {allCategories.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.parentId}
                              style={{
                                marginLeft: 0,
                                marginRight: "80%",
                                float: "left",
                              }}
                            >
                              {item.category}
                            </MenuItem>
                          ))}
                          </DefaultSelect>
                        </FormControl> 

                        <label className="suggestion__form--label">{translation.Suggestion_SubCategory}</label>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className={`admin__form-control ${(submitted && 
                          ((content?.subCategory && content?.subCategory.length === 0) || 
                          content?.subCategory === undefined || 
                          content?.subCategory === null || 
                          content?.subCategory === '')) ? 'error__border_indicator' : ''}`}>
                          <InputLabel id="demo-select-small">{translation.Suggestion_SubCategory}</InputLabel>
                          <DefaultSelect
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={content?.subCategory}
                            key={content?.subCategory}
                            label="subCategory"
                            onChange={handleSubCategory}
                            className={"suggestion__drop_downs"}
                            name="subCategory"
                          >
                            {subCategory?.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.subCategoryId}
                              style={{
                                marginLeft: 0,
                                marginRight: "80%",
                                float: "left",
                              }}
                            >
                              {item.label}
                            </MenuItem>
                            ))}
                          </DefaultSelect>
                        </FormControl>                                              

                        <label className="suggestion__form--label">{translation.Suggestion_Language}</label>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" className={`admin__form-control ${(submitted && 
                          (content?.language === undefined || 
                          content?.language === null || 
                          content?.language === '')) ? 'error__border_indicator' : ''}`}>
                          <Select
                            onChange={onChangeLanguageEntry}
                            options={ContentList.Language}
                            placeholder={translation.Suggestion_Language}
                            defaultValue={content?.language}
                          />
                        </FormControl>                          

                        <FormControl className="admin__form-control">
                          <label>{translation.Suggestion_Description}</label>
                          <input
                            className={`admin__account__details--input ${(submitted && 
                              (content?.description === undefined || 
                                content?.description === null || 
                                content?.description === '')) ? 'error__border_indicator' : ''}`}
                            name="description"
                            type="text"
                            value={content?.description ?? ""}
                            onChange={onChange}
                          />
                        </FormControl>   

                        <FormControl className={`admin__form-control add__speakers_extended ${(submitted && 
                          (content?.speakers === undefined || 
                          content?.speakers === null || 
                          content?.speakers === '' || 
                          (content?.speakers && content?.speakers.length === 0))) ? 'error__border_indicator' : ''}`}>
                          <label className="suggestion__form--label">{translation.Suggestion_Speakers}</label>
                          <ReactTags
                            tags={selectedTags}
                            handleDelete={handleDelete}
                            handleAddition={e => handleAddition(e)}
                            placeholder={translation.Add_speaker}
                            handleInputBlur={e => handleAddition(e)}
                          />
                          <div className="profile__bottom-update-buttons_extended">
                            <button type="button" className="add_speakers">
                              {translation.Add_speaker}
                            </button>
                          </div>
                          <div className="profile__bottom-update-buttons">
                          <button
                            type="reset"
                            className="suggestion_Button admin__account__details--button unselected"
                            onClick={clearForm}
                          >
                            {translation.Suggestion_Cancel_Button}
                          </button>
                          <button type="submit" className="suggestion_Button">
                            {translation.Suggestion_Send_Button}
                          </button>                        
                        </div>
                        </FormControl>
                  </div>
                </form>
              )}
            </Formik>
        </div>
      </div>             
  );
}

export default SuggestionForm;