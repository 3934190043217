import React from "react";
import ContentLoader from "react-content-loader";

const BannerSkeleton = (props) => (
  <ContentLoader viewBox="0 0 600 200" {...props}>
    <rect x="0" y="6" rx="2" ry="2" width="240" height="10" />
    <rect x="0" y="22" rx="2" ry="2" width="60" height="5" />
    <rect x="300" y="30" rx="2" ry="2" width="60" height="5" />
    <rect x="0" y="32" rx="2" ry="2" width="60" height="3" />
    <rect x="0" y="38" rx="2" ry="2" width="98%" height="100%" />
  </ContentLoader>
);

export default BannerSkeleton;
