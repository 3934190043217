//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Banner component *                                            *
 * ?                                    Very simple component used on the Homepage                              *
 * ?                                    It displays a single conference in full width                           *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import requests from "../axios/Requests";
import { GetBannerAction } from "../../redux/action/HomepageActions";
import { getBanner } from "../../servicesRedux/HomepageService";
import BannerSkeleton from "./BannerSkeleton";
import WatchLaterButton from "./WatchLaterButton";

import "../../theme/banner/Banner.scss";

const DekstopBanner = props => {
  const { type, categoryFilter } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { banner } = useSelector(state => state.homepageReducer);
  const { navbarFilter } = useSelector(state => state.globalReducer);
  const [, setDuration] = useState(0);
  const { translation } = useSelector(state => state.languageReducer);
  const { userRedux } = useSelector(state => state.authentificationReducer);

  useEffect(() => {
    getBanner(requests.GetContent, type, categoryFilter, userRedux.preferredLanguageIds).then(item => dispatch(GetBannerAction(item.data)));
  }, [navbarFilter,categoryFilter]);

  useEffect(() => {
    if (banner[0]?.image !== undefined && banner[0]?.image !== null && banner[0]?.image !== "") {
      const url = new URL(banner[0].image);
      const metadataURL = banner[0].image.replace(url.pathname.split("/")[2], "metadata.json");
      if (banner.catalogItemId) {
        loadMetadata(metadataURL);
      }
    }
  }, [banner[0]?.image]);

  const loadMetadata = url => {
    if (url !== undefined && url !== null && url !== "") {
      fetch(url, {
        method: "get",
      })
      .then(handleResponse)
      .catch(err => console.log(err));
    }
  };

  const convertISO8601 = iso8601Duration => {
    const iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;
    const matches = iso8601Duration.match(iso8601DurationRegex);
    return {
      hours: matches[6] === undefined ? 0 : matches[6],
      minutes: matches[7] === undefined ? 0 : matches[7],
      seconds: matches[8] === undefined ? 0 : matches[8]
    };
  };

  const handleResponse = response => {
    return response.text().then(item => {
      const data = item && JSON.parse(item);
      if (!response.ok) {
        return Promise.reject(new Error(data.error_description));
      }
      setDuration(convertISO8601(data.AssetFile[0].Duration));
      return data;
    });
  };

  const getTitleFontSize = text => {
    if (text.length > 50){
      return "1.4vw";
    } else if(text.length > 40){
      return "1.6vw";
    } else if(text.length > 30){
      return "1.8vw";
    } else if(text.length > 20){
      return "2.3vw";
    } else{
      return "2.5vw";
    }
  };

  const getLineHeightSize = text => {
    if (text.length > 80){
      return "2.5vw";
    } else if (text.length > 65){
      return "3vw";
    } else if(text.length > 50){
      return "3vw";
    } else if(text.length > 35){
      return "3.5vw";
    } else if(text.length > 20){
      return "4vw";
    } else{
      return "7vw";
    }
  };

  const getMarginHeightSize = text => {
    if (text.length > 80){
      return "3vw";
    } else if (text.length > 65){
      return "2.5vw";
    } else if(text.length > 50){
      return "2.2vw";
    } else if(text.length > 35){
      return "2vw";
    } else if(text.length > 20){
      return "1.8vw";
    } else{
      return "1.5vw";
    }
  };

  return (
    <div className="banner">
      {banner.length ? (
        banner.slice(0, 1).map(
          (
            ban,
            index
          ) => (
            <Box
              m="{1}"
              component="div"
              key={index}
            >
              <Box
                m="{1}"
                component="div"
                className="banner__video"
              >
                <Box
                  m="{1}"
                  component="div"
                  className="banner__internal-content-container"
                >
                  <div className="banner__internal-content-container-test"/>
                  <Box
                    m="{1}"
                    component="div"
                    className="banner__internal-content-container-inside"
                  >
                    <Box component="div" m="{1}" className="banner__internal-content-container-inside-div1">
                      <Box component="div" m="{1}" className="banner__internal-content-container-inside-div2">
                        <Typography variant="h4" component="h4">
                          {translation.Home_Today}
                        </Typography>
                      </Box>

                      <Box component="div" m="{1}" className="banner__internal-content-container-top">
                        <Typography variant="h4" component="h4" className="banner__video-short-desc" style={{fontSize: getTitleFontSize(ban.name), lineHeight: getLineHeightSize(ban.name) }}>
                        {
                          ban.name.length > 100 
                          ? `${ban.name.substring(0, ban.name.substring(0, 100).lastIndexOf(" "))}...` 
                          : ban.name
                        }
                        </Typography>
                      </Box>

                      <Box component="div" m="{1}" className="banner__internal-content-container-bottom" style={{marginTop: getMarginHeightSize(ban.name)}}>
                        <p>{
                          ban.fullSummary.length > 100 
                          ? `${ban.fullSummary.substring(0, ban.fullSummary.substring(0, 100).lastIndexOf(" "))}...` 
                          : ban.fullSummary
                        }</p>
                      </Box>
                    </Box>
                  </Box>
                  <Box component="div" m="{1}" className="banner__internal-content-container-inside-second" 
                  style={{
                    backgroundImage: `url("${ban.heroBanner ?? ban.image}")`,
                  }}
                  onClick={() => navigate(`/conference/${ban.catalogItemId}`)}>
                    <Stack spacing={2} direction="row">
                      <WatchLaterButton item={ban} />
                      <Button
                        variant="contained"
                        className="description__watch__now"
                        onClick={() => navigate(`/conference/${ban.catalogItemId}`)}
                      >Watch now</Button>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        )
      ) : (
        <BannerSkeleton />
      )}
    </div>
  );
}

export default DekstopBanner;