import axios from "axios";

export function getUser(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function putUser(requests, changes) {
  return new Promise((resolve, reject) => {
    axios
      .put(requests, changes, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function patchPersonByID(requests, id, personRequest) {
  requests = requests.replace("{personId}", id);

  return new Promise((resolve, reject) => {
    axios
      .patch(requests, personRequest, { withCredentials: true })
      .then(response => {
        if (response.status >= 350) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function patchContentStatusTagsUpdate(requests, contentId, contentRequest) {
  requests = requests.replace("{contentId}", contentId);

  return new Promise((resolve, reject) => {
    axios
      .patch(requests, contentRequest, { withCredentials: true })
      .then(response => {
        if (response.status >= 350) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUsers(requests, query, sortBy, orderBy, pageParams, page, accountId, roleName) {
  const request = requests
  .replace("{query}", query)
  .replace("{sortBy}", sortBy)
  .replace("{orderBy}", orderBy)
  .replace("{limit}", pageParams.limit)
  .replace("{page}", page)
  .replace("{accountId}", accountId)
  .replace("{roleName}", roleName);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getContent(requests, data, page, limit) {
  const request = requests.replace("{limit}", limit).replace("{page}", page);
  return new Promise((resolve, reject) => {
    axios
    .post(request, data, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeUserActiveStatus(requests, id, status) {
  requests = requests.replace("{accountUserId}", id).replace("{isActive}", status);
  return new Promise((resolve, reject) => {
    axios
      .patch(requests, {}, { withCredentials: true })
      .then(response => {
        if (response.status >= 350) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function doDeleteAdminUser(requests, accountUserId) {  
  requests = requests.replace("{accountUserId}", accountUserId);
    return new Promise((resolve, reject) => {
        axios.delete(requests,{withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getCompanies(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addNewUser(requests, data) {
  const dataModified = data.length > 0 ? data : [data];
  return new Promise((resolve, reject) => {
    axios
      .post(requests, dataModified, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    }
  );
};

export function getRoles(request) {  
  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function addRole(request, userId, roleId) {  
  const requests = request.replace("{userId}", userId).replace("{roleId}", roleId);
  return new Promise((resolve, reject) => {
    axios
      .post(requests, { }, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function removeRole(request, userId, roleId) {  
  const requests = request.replace("{userId}", userId).replace("{roleId}", roleId);
  return new Promise((resolve, reject) => {
    axios
      .delete(requests, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getAccountGroups(request, accountId) {
  const requests = request.replace("{accountId}", accountId);
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getTheDataForReportingOnNumberOfRegisteredUsers(requests, startDate, endDate, accountId) {
  const request = requests.replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{accountId}", accountId);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTheDataForReportingOnNumberOfActiveUsers(requests, startDate, endDate, accountId) {
  const request = requests.replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{accountId}", accountId);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTheDataForReportingOnNumberOfRegisteredUsersForThisMonth(requests, startMonth, endMonth, accountId) {
  const request = requests.replace("{startMonth}", startMonth).replace("{endMonth}", endMonth).replace("{accountId}", accountId);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTheDataForReportingOnNumberOfActiveUsersForThisMonth(requests, startMonth, endMonth, accountId) {
  const request = requests.replace("{startMonth}", startMonth).replace("{endMonth}", endMonth).replace("{accountId}", accountId);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTheDataForReportingOnNumberOfLoggedInUsersForSelectedAccount(requests, accountId) {
  const request = requests.replace("{accountId}", accountId);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions(requests, startDate, endDate, accountId) {
  const request = requests.replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{accountId}", accountId);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions(requests, startDate, endDate, accountId) {
  const request = requests.replace("{startDate}", startDate).replace("{endDate}", endDate).replace("{accountId}", accountId);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}