import React, { useState, useRef, useEffect } from 'react'
import { IconButton } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { makeStyles } from "@mui/styles";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Constants from '../constants/Constants';
import { GetNewContentAction } from "../../redux/action/GlobalActions";
import { useDispatch, useSelector } from "react-redux";
import "./ComplexityCheckboxes.scss"
import { blue } from '@material-ui/core/colors';


export default function ComplexityCheckboxes(props) {

    const { newContent } = useSelector(
        (state) => state.globalReducer
    );

    const [checkboxes, setCheckboxes] = useState([
        { id: 0, name: "Discover", checked: newContent?.isDiscover ?? false },
        { id: 1, name: "Expert", checked: newContent?.isExpert ?? false },
        { id: 2, name: "ACT", checked: newContent?.isAct ?? false },
    ]);



    const dispatch = useDispatch();

    const useStyles = makeStyles((theme) => ({
        root: {
            margin: "0 !important",
            padding: "3px"
        },
        helpRoot: {
            marginRight: "15px",
        },
    }));

    const classes = useStyles();

    const handleCheckboxChange = (event, id) => {
        setCheckboxes(
            checkboxes.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
            )
                
        );

    };

    useEffect(() => {
        dispatch(GetNewContentAction(
            {
                ...newContent,
                isAct: checkboxes[2]?.checked,
                isExpert: checkboxes[1]?.checked,
                isDiscover: checkboxes[0]?.checked,
            }
        ));
    }, [checkboxes]);

    const MyPopOver = (props) => {
        const { text } = props;
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);



        return (
            <>
                <IconButton size="small" onClick={handleClick} classes={{ root: classes.helpRoot }}><HelpOutlineIcon color="primary" /></IconButton>

                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }}>
                        {text}
                    </Typography>
                </Popover>
            </>
        )
    }

    return (
        <>
            <Grid container alignItems="center" sx={{marginBottom:"25px"}}>
                <Grid item xs={2} sx={{ display: "flex" }}>
                    <Typography
                        sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Complexity Level :
                    </Typography>
                </Grid>

                <Grid item xs={8} alignItems="center">
                    <div style={{ width: "95%", zIndex: 999 }} className="checkbox_container">
                        <label className="checkbox_label"> Discover </label>
                        <Checkbox
                            checked={checkboxes[0].checked}
                            disabled={newContent.isTopCreamContent}
                            classes={{ root: classes.root }} onClick={(e) => handleCheckboxChange(e, 0)} />
                        <MyPopOver text={Constants.ComplexityCheckboxText.Discover} />
                        <label className="checkbox_label"> Expert </label>
                        <Checkbox
                            checked={checkboxes[1].checked}
                            disabled={newContent.isTopCreamContent}
                            classes={{ root: classes.root }} onClick={(e) => handleCheckboxChange(e, 1)} />
                        <MyPopOver text={Constants.ComplexityCheckboxText.Expert} />
                        <label className="checkbox_label"> ACT </label>
                        <Checkbox
                            checked={checkboxes[2].checked}
                            disabled={newContent.isTopCreamContent}
                            classes={{ root: classes.root }} onClick={(e) => handleCheckboxChange(e, 2)} />
                        <MyPopOver text={Constants.ComplexityCheckboxText.ACT} />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}