import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./ContentTable.scss";
import { useEffect } from "react";

import Collapsible from "react-collapsible";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";
import LocaleData from "dayjs/plugin/localeData";

const ContentTable = (props) => {
  const { content, title } = props;
  const [count, setCount] = React.useState(0);
  useEffect(() => {
    setCount(content?.length);
  }, [content]);
  const test = (id) => {
    window.location.href = "/curationModifyApp/" + id;
  };

  function TitleTrigger() {
    return (
      <h2 className="section--title">
        {title} - {count}
      </h2>
    );
  }
  const LOCALE = navigator.language;

  try {
    require(`dayjs/locale/${LOCALE}.js`);
  } catch (e) {
    console.log(e);
  }

  dayjs.extend(utc);
  dayjs.extend(updateLocale);
  dayjs.extend(LocaleData);

  return (
    <div className={`Collapsible__extended_${title}`}>
      <Collapsible
        triggerClassName={"Collapsible_component"}
        triggerOpenedClassName={"Collapsible_component"}
        trigger={<TitleTrigger />}
      >
        {content && content.length > 0 && (
          <div>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ContentID</TableCell>
                    <TableCell>Name of Conference</TableCell>
                    <TableCell>Curator</TableCell>
                    <TableCell>Validator</TableCell>
                    <TableCell>Language</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Updated</TableCell>
                    <TableCell>Summary</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content.map((item) => {
                    return (
                      <TableRow
                        key={item?.id}
                        onClick={() => test(item?.id)}
                        className="management--row"
                      >
                        <TableCell>{item?.id}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.curator}</TableCell>
                        <TableCell>{item?.validator}</TableCell>
                        <TableCell>{item?.language}</TableCell>
                        <TableCell>
                          {dayjs
                            .utc(item?.created)
                            .locale(LOCALE)
                            .format("DD MMMM YYYY, HH:mm")
                          }
                        </TableCell>
                        <TableCell>
                          {
                          dayjs
                            .utc(item?.updated)
                            .locale(LOCALE)
                            .format("DD MMMM YYYY, HH:mm")
                          }
                        </TableCell>
                        <TableCell>{item?.fullSummary}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Collapsible>
    </div>
  );
};
export default ContentTable;
