import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import requests from "../axios/Requests";
import {
    LoadNextAction,
    HasLoadedAction,
} from "../../redux/action/PaginationAction";
import { getWatchHistory } from "../../servicesRedux/WatchHistoryService";
import Paginator from "../../components/pagination/Paginator";
import VideoCardHistory from "../videoCard/VideoCardHistory";
import Cookies from "js-cookie";

import "./WatchHistoryRow.scss";

export default function WatchHistoryRow(props) {
    const dispatch = useDispatch();
    const [requestReturn, setRequestReturn] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [load, setLoad] = useState(false);
    const [content, setContent] = useState([]);
    const { loadNext } = useSelector((state) => state.paginationReducer);
    const { translation } = useSelector((state) => state.languageReducer);
    const prevParams = useRef();
    const userId = Cookies.get("userID");
    const pageParams = {
        limit: 10,
        page: totalPages >= pageNumber ? pageNumber : 1,
    };

    useEffect(() => {
        const comparison =
            JSON.stringify(prevParams.current) !== JSON.stringify(pageParams);
        if (totalPages ? totalPages >= pageNumber : true && comparison) {
            dispatch(HasLoadedAction(false));
            getWatchHistory(requests.GetWatchHistory, pageParams).then((item) =>
                setRequestReturn(item)
            );
        }
        prevParams.current = pageParams;
    }, [load]);

    useEffect(() => {
        if (requestReturn.currentPage !== undefined) {
            setTotalPages(requestReturn.totalPages);
            setPageNumber(requestReturn.currentPage);
        }

        if (requestReturn.data) {
            const contentTemp = content.length === 0
                ? groupByWatchedDate(requestReturn.data)
                : Object.keys(content).reduce((acc, date) => {
                    acc[date] = content[date].concat(groupByWatchedDate(requestReturn.data)[date] || []);
                    return acc;
                }, {});

            contentTemp && setContent(contentTemp);
        }
    }, [requestReturn]);

    useEffect(() => {
        if (content.length !== 0) {
            dispatch(HasLoadedAction(content.length));
        }
    }, [content.length]);

    const groupByWatchedDate = videos => {
        return videos.reduce((acc, video) => {
            const date = new Date(video.created).toLocaleDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(video);
            return acc;
        }, {});
    };

    const triggerPagination = () => {
        totalPages >= pageNumber && setPageNumber(pageNumber + 1);
        setLoad(!load);
        dispatch(LoadNextAction(false));
    };
    if (loadNext) triggerPagination();

    const formatDate = (inputDate, locale = 'en-US') => {
        const [day, month, year] = inputDate.split('/');
        const dateObj = new Date(`${year}-${month}-${day}`);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };

        if (dateObj.toString() !== "Invalid Date") {
            return new Intl.DateTimeFormat(locale, options).format(dateObj);
        }       
    };

    return (
        content.length !== 0 && (
            <div className="wh__container">
                <div className="wh--title-wrapper">
                    <h1 className="wh--title">{translation.My_History}</h1>
                </div>
                <div className="wh__content">
                    <div className="wh__content--wrapper">
                        {Object.keys(content).map((date, index) => (
                            <div key={index}>
                                <h2 className="wh--date">{date && formatDate(date, translation.locale)}</h2>
                                <div>
                                    {content[date].map((item, ind) => (
                                        <VideoCardHistory
                                            key={item.id}
                                            item={item}
                                            userId={userId}
                                            appearanceType={"watchHistory"}
                                            isSaved={item.isSaved}
                                            ind={ind}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <Paginator />
                    </div>
                </div>
            </div>
        )
    );
}
