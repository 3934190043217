//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Homepage page *                                               *
 * ?                      Homepage is the landing page after a user is logged in                                *
 * ?                      It contains 2 mains parts : "Banner" and "Row". Two components                        *
 * ?                      Banner is a simple component displaying one conference at a time                      *
 * ?            Row is a more complex component using a slider and receiving multiples list (more info in Row)  *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Medal, Users, Sparkle, StarFour, TrendUp, UserPlus, CircleWavyCheck, Brain } from "phosphor-react";
import useBreakpoints from "../hooks/UseBreakpoint";

import DekstopBanner from "../components/banner/DekstopBanner";
import MobileBanner from "../components/banner/MobileBanner";
import Row from "../components/row/Row";

import heart from "../assets/images/heart.svg";
import education from "../assets/images/education.svg";
import Loader from "../components/async/Loader";
import useReplacer from "../hooks/replacer";
import ContentTypeToggle from "../components/smallComponents/ContentTypeToggle";

import "../theme/homepage/Homepage.scss";

const Homepage = () => {
  const { translation } = useSelector(state => state.languageReducer);
  const { view } = useSelector((state) => state.viewallReducer);
  const { categoryFilter } = useSelector((state) => state.categoryFilterReducer);
  const { categories } = useSelector((state) => state.globalReducer);
  const [rowsIsLoaded, setRowsIsLoaded] = useState(true);
  const [sortedCategories, setSortedCategories] = useState(null);
  const replacer = useReplacer();
  // const sortedCategories = categories?.all?.sort((a, b) => a.homepageCarouselOrder - b.homepageCarouselOrder);
  const logoList = {
    TrendingNow: TrendUp,
    peer: UserPlus,
    heart: heart,
    NewOnTopCream: Sparkle,
    education: education,
    TopVideos: CircleWavyCheck,
    MostViewed: Medal,
    Recommended: StarFour,
    Ceo: Users,
    ContentOwner: StarFour,
    ConferencesYouMightLike: StarFour,
    BrainDetox: Brain,
  }
  const [activeType, setActiveType] = useState(['video', 'podcast', 'pdf']);
  const point = useBreakpoints();

  useEffect(() => {
    setSortedCategories(categories?.sort((a, b) => a.homepageCarouselOrder - b.homepageCarouselOrder))
  }, [categories]);

  useEffect(() => {
  }, [categoryFilter]);

  const renderHomePageBanner = mode => {
    switch(mode) {
      case "xs":
      case "sm":      
      case "md":
        return <MobileBanner categoryFilter={categoryFilter} type="Banner" />;        
      case "ml":             
      case "lg":
        return <DekstopBanner categoryFilter={categoryFilter} type="Banner" />;    
    }
  };

  const filterByList = (filter, category) => {
    if(!category) return '';
    const list = ["CEO Future Visions", "Brain Detox", "watched-by-your-peers"]
    if (list.includes(filter)) {
      return '';
    } else {
      return category
    }
  };
  
  function getCategoriesIdByName(name) {
    if (!categories) return null;
    const category = categories.find(item => item.label_En === name);
    return category ? category.id : null;
  }
  return (
    <div>
      {categories && !rowsIsLoaded && sortedCategories ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="homepage">
          {renderHomePageBanner(point)}
          <ContentTypeToggle homePage={true} setActiveTypeCallback={setActiveType} translation={translation} />
          <div className="home__page--row--wrapper">
            {view.map((item, index) => {              
              if (item.category === "categories" && sortedCategories) {
                return(
                  sortedCategories?.map((category) => (
                    category.isHomepageCarousel &&
                    <Row
                      key={category?.id}
                      title={
                        replacer(category?.label_En)
                      }
                      type={"Categories"}
                      category={category?.id}
                      Logo={Users}
                      appearanceType={"row"}
                      contentType={"homepage"}
                      setRowsIsLoaded={setRowsIsLoaded}
                      mediaType={activeType}
                    />
                  ))
                )
              } else if ((view[index].api !== "TopTcVideo" || activeType.includes("video")) && item.category !== "categories") {
                return (
                  <Row
                    key={index}
                    title={
                      replacer(
                        eval(view[index].title)
                        )}
                    type={view[index].api}
                    Logo={logoList[view[index]?.logo]}
                    sectionUrl={view[index].url}
                    appearanceType={"row"}
                    category={filterByList(view[index].url, categoryFilter)}
                    categoryFilter={filterByList(view[index].url, categoryFilter)}
                    contentType={"homepage"}
                    setRowsIsLoaded={setRowsIsLoaded}
                    mediaType={activeType}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
    </div>
  );
  
  
}

export default Homepage;
