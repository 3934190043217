//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                              *
 *                                              * Admin Content Dashboard Page *                                 *
 * ?                      An admin Content can see all the Content, this page is where they will find them       *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from "@mui/material/LinearProgress";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Cookies from "js-cookie";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import GenericSelect from "react-select";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import requests from "../components/axios/Requests";
import { getContent, patchContentStatusTagsUpdate } from "../servicesRedux/AdminService";

import { truncate } from "../hooks/utils.js";
import useDebounce from "../hooks/Debounce";
import Constants from "../components/constants/Constants";

import video_dashboard_icon from "../assets/icons/Icons/Label=Video.svg";
import pdf_dashboard_icon from "../assets/images/pdf_dashboard_icon_colored.png";
import PodCast from "../assets/icons/Icons/Label=Podcast.svg";
import video_dashboard_icon_normal from "../assets/images/video_dashboard_icon.png";
import pdf_dashboard_icon_normal from "../assets/images/pdf_dashboard_icon.png";
import PodCast_normal from "../assets/images/podcast.png";

import "../theme/dashboard/dashboard.scss";

const DashboardContent = () => {
    const {
        queryOnDashboardUsers,
        selectedRoleOnMobileLayout,
        updatedSelectedTagsList,
        updatedSelectedSpeakersList,
        updatedSelectedCategoriesList,
        updatedMediaTypes,
        updatedSubCategoryList,
        updatedCurationList,
        startDateForContent,
        endtDateForContent,
        allTags,
        tagsReset,
        resetContentFilters,
        contentAdminStatusDefault,
        contextEmptyContentParamsToState,
        sendContextValueForResetLeftMenu
    } = useOutletContext();

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { translation } = useSelector(state => state.languageReducer);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const pageParams = { limit: 10 };
    const [currentFilter, setCurrentFilter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const columns = [
        { index: -1, id: 'media', label: translation.Media, minWidth: '5%' },
        { index: 0, id: 'created', label: translation.Created, minWidth: '5%' },
        { index: 8, id: 'updated', label: translation.Updated, minWidth: '5%' },
        { index: 13, id: 'title', label: translation.Title, minWidth: '10%' },
        { index: 3, id: 'summary', label: translation.Channel_Summary, minWidth: '10%' },
        { index: 4, id: 'tags', label: translation.Tags, minWidth: '10%' },
        { index: 5, id: 'curator', label: translation.curator, minWidth: '10%' },
        { index: 6, id: 'chiefCurator', label: translation.chief_curator, minWidth: '5%' },
        { index: 7, id: 'comment', label: translation.Conference_Comments, minWidth: '10%' },
        { index: 9, id: 'rating', label: translation.VideoCard_Rating, minWidth: '5%' },
        { index: 2, id: 'status', label: 'Status', minWidth: '10%' },
        { index: 15, id: 'processing_status', label: translation.Processing_Status, minWidth: '10%' },
        { index: 11, id: 'modify', label: translation.Modify, minWidth: '5%' },
    ];
    const [tags, setTags] = useState([]);
    const debouncedSearchTerm = useDebounce((queryOnDashboardUsers), 500);
    const [filterColumn, setFilterColumn] = useState("created");
    const [orderType, setOrderType] = useState(null);
    const [sortingStarted, setSortingStarted] = useState("");
    const [, setFilterColumnId] = useState("");
    const [content, setContent] = useState({
        query: "",
        mediaTypeId: [1, 2, 3],
        speakerId: "",
        categoryId: "",
        curatorId: "",
        startDate: null,
        endDate: null,
        tags: [],
        subCategoryId: "",
        contentAdminStatus: null,
        sortBy: 8,
        orderBy: false
    });
    const [mediaContents, setMediaContents] = useState([]);
    const [contentTypes, setContentTypes] = useState([]);
    const [searchStarted, setSearchStarted] = useState(false);
    const [, setContentStatus] = useState("");
    const [speakerId, setSpeakerId] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [subCategoryId, setSubCategoryId] = useState("");
    const [curatorId, setCuratorId] = useState("");
    const [contentIdToSetStatus, setContentIdToSetStatus] = useState(null);
    const [changedTheStatusEditView, setChangedTheStatusEditView] = useState(false);
    const roles = Cookies.get("roles") && JSON.parse(Cookies.get("roles"));
    const [openStatusEditModal, setOpenStatusEditModal] = useState();
    const [starFilteringFromDates, setStarFilteringFromDates] = useState(false);
    const [contentIdToSetTags, setContentIdToSetTags] = useState("");
    const [changedTheTagsEditView, setChangedTheTagsEditView] = useState(false);
    const [selectedStatusIdForEditStatus, setSelectedStatusIdForEditStatus] = useState(null);
    const [modifyTag, setModifyTag] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isNotDropDown] = useState([]);
    const { newContent } = useSelector(state => state.globalReducer);
    const [updatedTagsAfterRecordUpdate, setUpdatedTagsAfterRecordUpdate] = useState([]);
    const [, setNewStatusForTheSelectedContent] = useState(null);

    useEffect(() => {
        if (contentAdminStatusDefault && contextEmptyContentParamsToState === null) filterBasedOnContentTypeStatus("");
    }, [contentAdminStatusDefault]);

    useEffect(() => {
        if (contentAdminStatusDefault) filterBasedOnContentTypeStatus("");
    }, [sendContextValueForResetLeftMenu]);

    useEffect(() => {
        let sum = 0;
        if (resetContentFilters === null) {
            const contentTemp = {
                query: "",
                mediaTypeId: [1, 2, 3],
                speakerId: "",
                categoryId: "",
                curatorId: "",
                startDate: null,
                endDate: null,
                tags: [],
                subCategoryId: "",
                contentAdminStatus: null,
                sortBy: 8,
                orderBy: false
            };
            setContent(contentTemp);
            getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        }
    }, [resetContentFilters]);

    useEffect(() => {
        let sum = 0;
        if (contextEmptyContentParamsToState !== null) {
            setContent(contextEmptyContentParamsToState);
            getContent(requests.getContentPost, contextEmptyContentParamsToState, page, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        }
    }, [contextEmptyContentParamsToState]);

    useEffect(() => {
        let sum = 0;
        if (debouncedSearchTerm !== undefined && debouncedSearchTerm.length > 2) {
            setSearchStarted(true);
            setIsLoading(true);
            const contentTemp = {
                ...content,
                query: debouncedSearchTerm,
            };
            setContent(contentTemp);
            getContent(requests.getContentPost, contentTemp, 1, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        }
        if (
            searchStarted &&
            queryOnDashboardUsers === '' &&
            debouncedSearchTerm !== undefined &&
            debouncedSearchTerm.length === 0
        ) {
            const contentTemp = {
                ...content,
                query: debouncedSearchTerm,
            };
            setIsLoading(true);
            getContent(requests.getContentPost, contentTemp, 1, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
                setSearchStarted(false);
            });
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        setTags(allTags);
    }, [allTags]);

    useEffect(() => setCurrentFilter(selectedRoleOnMobileLayout), [selectedRoleOnMobileLayout]);

    useEffect(() => {
        let sum = 0;
        if (tagsReset !== null && updatedSelectedTagsList.length === 0) {
            const contentTemp = {
                ...content,
                "tags": tagsReset.length === 0 ? [] : [tagsReset],
            };
            setContent(contentTemp);
            setIsLoading(true);
            getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        }
    }, [tagsReset]);

    useEffect(() => {
        let sum = 0;
        if (startDateForContent !== "" && startDateForContent !== null && endtDateForContent !== "" && endtDateForContent !== null) {
            setStarFilteringFromDates(true);
            const contentTemp = {
                ...content,
                startDate: new Date(startDateForContent).toISOString(),
                endDate: new Date(endtDateForContent).toISOString()
            };
            setContent(contentTemp);
            setIsLoading(true);
            getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        } else {
            if (starFilteringFromDates && contextEmptyContentParamsToState === null) {
                const contentTemp = {
                    ...content,
                    startDate: "",
                    endDate: ""
                };
                setContent(contentTemp);
                setIsLoading(true);
                getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                    const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                    adminStatusCount.forEach(num => sum += num);
                    setTotalRecords(sum);
                    setMediaContents(items.data);
                    setRowsPerPage(items.data.length);
                    setTotalPages(items.totalPages);
                    setContentTypes(items.contentAdminStatus);
                    setIsLoading(false);
                });
                setStarFilteringFromDates(false);
            }
        }
    }, [startDateForContent, endtDateForContent]);

    useEffect(() => {
        let sum = 0;
        if (updatedMediaTypes !== null && contextEmptyContentParamsToState === null) {
            const contentTemp = {
                ...content,
                mediaTypeId: updatedMediaTypes,
            };
            setContent(contentTemp);
            setIsLoading(true);
            setPage(1);
            getContent(requests.getContentPost, contentTemp, 1, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        }
    }, [updatedMediaTypes]);

    useEffect(() => {
        let sum = 0;
        if (updatedSelectedTagsList.length > 0) {
            const tagsNameList = updatedSelectedTagsList.map(t => t.label);
            const contentTemp = {
                ...content,
                tags: tagsNameList,
            };
            setContent(contentTemp);
            setIsLoading(true);
            getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        }
    }, [updatedSelectedTagsList]);

    useEffect(() => {
        let sum = 0;
        if (updatedSelectedSpeakersList != "" && updatedSelectedSpeakersList != undefined && updatedSelectedSpeakersList != []) {
            setSpeakerId(updatedSelectedSpeakersList);
            const contentTemp = {
                ...content,
                speakerId: updatedSelectedSpeakersList,
            };
            setContent(contentTemp);
            setIsLoading(true);
            getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        } else {
            if (speakerId != "") {
                setSpeakerId("");
                const contentTemp = {
                    query: "",
                    mediaTypeId: [1, 2, 3],
                    speakerId: "",
                    categoryId: "",
                    curatorId: "",
                    startDate: null,
                    endDate: null,
                    tags: [],
                    subCategoryId: "",
                    contentAdminStatus: null,
                    sortBy: 8,
                    orderBy: false
                };
                setIsLoading(true);
                setContent(contentTemp);
                getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                    const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                    adminStatusCount.forEach(num => sum += num);
                    setTotalRecords(sum);
                    setMediaContents(items.data);
                    setRowsPerPage(items.data.length);
                    setTotalPages(items.totalPages);
                    setContentTypes(items.contentAdminStatus);
                    setIsLoading(false);
                });
            }
        }
    }, [updatedSelectedSpeakersList]);

    useEffect(() => {
        let sum = 0;
        if (updatedSelectedCategoriesList != "" && updatedSelectedCategoriesList != undefined && updatedSelectedCategoriesList != []) {
            setCategoryId(updatedSelectedCategoriesList);
            const contentTemp = {
                ...content,
                categoryId: updatedSelectedCategoriesList,
            };
            setContent(contentTemp);
            setIsLoading(true);
            getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setContentTypes(items.contentAdminStatus);
                setTotalRecords(items.totalItems);
                setIsLoading(false);
            });
        } else {
            if (categoryId != "") {
                setCategoryId("");
                const contentTemp = {
                    query: "",
                    mediaTypeId: [1, 2, 3],
                    speakerId: "",
                    categoryId: "",
                    curatorId: "",
                    startDate: null,
                    endDate: null,
                    tags: [],
                    subCategoryId: "",
                    contentAdminStatus: null,
                    sortBy: 8,
                    orderBy: false
                };
                setIsLoading(true);
                setContent(contentTemp);
                getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                    const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                    adminStatusCount.forEach(num => sum += num);
                    setTotalRecords(sum);
                    setMediaContents(items.data);
                    setRowsPerPage(items.data.length);
                    setContentTypes(items.contentAdminStatus);
                    setTotalRecords(items.totalItems);
                    setIsLoading(false);
                });
            }
        }
    }, [updatedSelectedCategoriesList]);

    useEffect(() => {
        let sum = 0;
        if (updatedSubCategoryList != "" && updatedSubCategoryList != undefined && updatedSubCategoryList != []) {
            setSubCategoryId(updatedSubCategoryList);
            const contentTemp = {
                ...content,
                subCategoryId: updatedSubCategoryList,
            };
            setContent(contentTemp);
            setIsLoading(true);
            getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        } else {
            if (subCategoryId != "") {
                setSubCategoryId("");
                const contentTemp = {
                    query: "",
                    mediaTypeId: [1, 2, 3],
                    speakerId: "",
                    categoryId: "",
                    curatorId: "",
                    startDate: null,
                    endDate: null,
                    tags: [],
                    subCategoryId: "",
                    contentAdminStatus: null,
                    sortBy: 8,
                    orderBy: false
                };
                setIsLoading(true);
                setContent(contentTemp);
                getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                    const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                    adminStatusCount.forEach(num => sum += num);
                    setTotalRecords(sum);
                    setMediaContents(items.data);
                    setRowsPerPage(items.data.length);
                    setContentTypes(items.contentAdminStatus);
                    setIsLoading(false);
                });
            }
        }
    }, [updatedSubCategoryList]);

    useEffect(() => {
        let sum = 0;
        if (updatedCurationList != "" && updatedCurationList != undefined && updatedCurationList != []) {
            setCuratorId(updatedCurationList);
            const contentTemp = {
                ...content,
                curatorId: updatedCurationList,
            };
            setContent(contentTemp);
            setIsLoading(true);
            getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                adminStatusCount.forEach(num => sum += num);
                setTotalRecords(sum);
                setMediaContents(items.data);
                setRowsPerPage(items.data.length);
                setTotalPages(items.totalPages);
                setContentTypes(items.contentAdminStatus);
                setIsLoading(false);
            });
        } else {
            if (curatorId != "") {
                setCuratorId("");
                const contentTemp = {
                    query: "",
                    mediaTypeId: [1, 2, 3],
                    speakerId: "",
                    categoryId: "",
                    curatorId: "",
                    startDate: null,
                    endDate: null,
                    tags: [],
                    subCategoryId: "",
                    contentAdminStatus: null,
                    sortBy: 8,
                    orderBy: false
                };
                setIsLoading(true);
                setContent(contentTemp);
                getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
                    const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
                    adminStatusCount.forEach(num => sum += num);
                    setTotalRecords(sum);
                    setMediaContents(items.data);
                    setRowsPerPage(items.data.length);
                    setTotalPages(items.totalPages);
                    setContentTypes(items.contentAdminStatus);
                    setIsLoading(false);
                });
            }
        }
    }, [updatedCurationList]);

    const Tags = () => {
        const modifyThisTag = data => setModifyTag(data);

        const MyIconButtonTag = (props) => {
            const { data } = props;
            return <div className={"icon_button"} onClick={() => modifyThisTag(data)}><CreateRoundedIcon /></div>;
        };

        const handleDeleteTag = item => setSelectedTags(selectedTags.filter(st => st.label || st !== item));

        return (
            <>
                {selectedTags && selectedTags.length > 0 && selectedTags?.map((item, index) => {
                    return (
                        <Stack direction="row" spacing={1} key={index}>
                            {item.isNew && modifyTag !== null && item.canModify && !(isNotDropDown && isNotDropDown?.find(elem => elem === item.label) !== undefined) ? (
                                <Chip
                                    label={item.label || item}
                                    variant="outlined"
                                    sx={{ margin: "5px" }}
                                    onDelete={_ => handleDeleteTag(item.label || item.label)}
                                    icon={<MyIconButtonTag data={item} />}
                                />
                            ) : (
                                (newContent.isTopCreamContent ? (
                                    <Chip
                                        label={item.label || item}
                                        variant="outlined"
                                        sx={{ margin: "5px" }}
                                    />
                                ) : (
                                    <Chip
                                        label={item.label || item}
                                        variant="outlined"
                                        sx={{ margin: "5px" }}
                                        onDelete={_ => handleDeleteTag(item.label || item)}
                                    />
                                ))
                            )}
                        </Stack>
                    );
                })}
            </>
        );
    };

    const filterBasedOnContentTypeStatus = contentTypeStatus => {
        let sum = 0;
        setMediaContents([]);
        setIsLoading(true);
        setContentStatus(contentTypeStatus);
        setCurrentFilter(contentTypeStatus);
        setPage(1);
        const contentTemp = {
            ...content,
            contentAdminStatus: (contentTypeStatus === undefined || contentTypeStatus === null || contentTypeStatus === "") ? "" : contentTypeStatus,
        };
        setContent(contentTemp);
        getContent(requests.getContentPost, contentTemp, 1, pageParams.limit).then(items => {
            const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
            adminStatusCount.forEach(num => sum += num);
            setTotalRecords(sum);
            setMediaContents(items.data);
            setRowsPerPage(items.data.length);
            setTotalPages(items.totalPages);
            setContentTypes(items.contentAdminStatus);
            setIsLoading(false);
        });
    };

    const changeTheEditStatus = event => {
        setChangedTheStatusEditView(false);
        setOpenStatusEditModal(true);
        setSelectedStatusIdForEditStatus(event.target.value);
    };

    const resetToNormalDataTable = () => {
        setChangedTheStatusEditView(false);
        setOpenStatusEditModal(false);
    };

    const renderContentStatusBasedOnTheActualValue = adminContentStatus => {
        switch (adminContentStatus) {
            case Constants.ContentAdminStatus.NOT_SET.enum:
                return Constants.ContentAdminStatus.NOT_SET.labelDashboard;
            case Constants.ContentAdminStatus.IN_PROGRESS.enum:
                return Constants.ContentAdminStatus.IN_PROGRESS.labelDashboard;
            case Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.enum:
                return Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.labelDashboard;
            case Constants.ContentAdminStatus.APPROVED.enum:
                return Constants.ContentAdminStatus.APPROVED.labelDashboard;
            case Constants.ContentAdminStatus.REJECTED.enum:
                return Constants.ContentAdminStatus.REJECTED.labelDashboard;
            case Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum:
                return Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.labelDashboard;
        }
    };

    const renderContentStatus = (id, status, tags) => {
        const statusToPresent = renderContentStatusBasedOnTheActualValue(status);
        if (
            changedTheStatusEditView &&
            (id === contentIdToSetStatus)
        ) {
            return (
                <>
                    <FormControl fullWidth>
                        <InputLabel id={`status-select-label-${id}`}>{translation.Status}</InputLabel>
                        {((roles.includes(Constants.Roles.ADMIN)) || (roles.includes(Constants.Roles.CURATOR_MANAGER)) || (roles.includes(Constants.Roles.MANAGER))) ?
                            <Select
                                label="Status"
                                id={`status-select-${id}`}
                                labelId={`status-select-label-${id}`}
                                onChange={event => changeTheEditStatus(event)}
                                className="status__edit_drop_down"
                            >
                                <MenuItem value={Constants.ContentAdminStatus.IN_PROGRESS.enum}>{Constants.ContentAdminStatus.IN_PROGRESS.labelDashboard}</MenuItem>
                                <MenuItem value={Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.enum}>{Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.labelDashboard}</MenuItem>
                                <MenuItem value={Constants.ContentAdminStatus.REJECTED.enum}>{Constants.ContentAdminStatus.REJECTED.labelDashboard}</MenuItem>
                                <MenuItem value={Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum}>{Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.labelDashboard}</MenuItem>
                            </Select> :
                            <Select
                                label="Status"
                                id={`status-select-${id}`}
                                labelId={`status-select-label-${id}`}
                                onChange={event => changeTheEditStatus(event)}
                                className="status__edit_drop_down"
                            >
                                <MenuItem value={Constants.ContentAdminStatus.IN_PROGRESS.enum}>{Constants.ContentAdminStatus.IN_PROGRESS.labelDashboard}</MenuItem>
                                <MenuItem value={Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.enum}>{Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.labelDashboard}</MenuItem>
                            </Select>}
                    </FormControl>
                    <CloseIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => resetToNormalDataTable()} />
                </>
            );
        } else {
            return (
                <>
                    {!isLoading && <ModeEditOutlineOutlinedIcon onClick={() => modifyTheStatus(id, tags)} className="edit__item_users" />}
                    <span title={statusToPresent}>
                        {statusToPresent && truncate(statusToPresent, 30)}
                    </span>
                </>
            );
        }
    };

    const renderMediaIcon = (mediaTypeId, contentAdminStatusId, id, contentProcessStatusId) => {
        let fullImage = null;
        switch (mediaTypeId) {
            case Constants.MediaType.Video:
                fullImage = ((contentAdminStatusId === Constants.ContentAdminStatus.APPROVED.enum || contentAdminStatusId === Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum) && (contentProcessStatusId === 13)) ? video_dashboard_icon : video_dashboard_icon_normal;
                break;
            case Constants.MediaType.PDF:
                fullImage = ((contentAdminStatusId === Constants.ContentAdminStatus.APPROVED.enum || contentAdminStatusId === Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum) && (contentProcessStatusId === 13)) ? pdf_dashboard_icon : pdf_dashboard_icon_normal;
                break;
            case Constants.MediaType.Podcast:
                fullImage = ((contentAdminStatusId === Constants.ContentAdminStatus.APPROVED.enum || contentAdminStatusId === Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum) && (contentProcessStatusId === 13)) ? PodCast : PodCast_normal;
                break;
        }
        if ((contentAdminStatusId === 3 || contentAdminStatusId === 5)) {
            return <a href={`/conference/${id}`} target="_new"><img src={fullImage} className={`media__icon-default-size media__icon-default-cursor ${mediaTypeId === Constants.MediaType.Podcast ? 'podCastIcon' : ''}`} /></a>;
        } else {
            return <img src={fullImage} className={`media__icon-default-size ${mediaTypeId === Constants.MediaType.Podcast ? 'podCastIcon' : ''}`} />;
        }
    };

    const modifyTheStatus = (id, tagsDfault) => {
        let tagsStringArray = [];
        setNewStatusForTheSelectedContent(null);
        if (tagsDfault !== "") {
            tagsStringArray = tagsDfault.split(",");
            setSelectedTags(tagsStringArray);
        } else {
            if (contentIdToSetTags !== id) {
                setSelectedTags([]);
            }
        }
        setContentIdToSetStatus(id);
        setChangedTheStatusEditView(true);
    };

    const modifyTheTags = (id, tagsDfault, status) => {
        let tagsStringArray = [];
        if (tagsDfault !== "") {
            tagsStringArray = tagsDfault.split(",");
            setSelectedTags(tagsStringArray);
        } else {
            if (contentIdToSetTags !== id) {
                setSelectedTags([]);
            }
        }
        setContentIdToSetTags(id);
        setSelectedStatusIdForEditStatus(status);
        setChangedTheTagsEditView(true);
    };

    const renderContentProcessingStatus = (processStatusId, isExternalContent) => {
        switch (processStatusId) {
            case 13:
                return <span className="available__text">{translation.Available}</span>;
            case 21:
                return <span className="processing__duplicate">{translation.LowQualityVideo}</span>;
            case 19:
                return isExternalContent ?
                    <span className="available__text">{translation.Available}</span>
                    :
                    <span className="processing__duplicate">{translation.Duplicate}</span>;
            default:
                return <span className="processing__text">{translation.Processing}</span>;
        }
    };

    const renderRowsOnContentTable = row => {
        return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                <TableCell width={'5%'} className="media__type_column dashboard__content_text-center">{renderMediaIcon(row?.mediaTypeId, row?.contentAdminStatusId, row?.catelogItemId, row?.contentProcessStatusId)}</TableCell>
                <TableCell width={'5%'} className="dashboard__content_text-left table__header-no-padding-left">{moment(row?.created).utc().format('MM-DD-YYYY hh:mm:ss')}</TableCell>
                <TableCell width={'5%'} className="dashboard__content_text-left table__header-no-padding-left">{moment(row?.updated).utc().format('MM-DD-YYYY hh:mm:ss')}</TableCell>
                <TableCell width={'10%'}>
                    <span title={row?.name}>
                        {`${row?.name && truncate(row?.name, 15)}`}
                    </span>
                </TableCell>
                <TableCell width={'10%'}>
                    <span title={row?.fullSummary}>
                        {`${row?.fullSummary != null ? truncate(row?.fullSummary, 15) : ""}`}
                    </span>
                </TableCell>
                <TableCell className="table__separator" width={'10%'}>
                    <div class="popover__wrapper flex">
                        {!isLoading && <ModeEditOutlineOutlinedIcon fontSize="medium" onClick={() => modifyTheTags(row?.id, row?.tags, row?.contentAdminStatusId)} className="edit__item_users" />}
                        <span title={row?.tags.replaceAll(",", ", ")}>
                            {contentIdToSetTags === row?.id && updatedTagsAfterRecordUpdate.length > 0 ? updatedTagsAfterRecordUpdate.join(",") : (row?.tags && truncate(row?.tags, 15))}
                        </span>
                    </div>
                </TableCell>
                <TableCell className="dashboard__content_text-left" width={'10%'}>{row.curator}</TableCell>
                <TableCell className="dashboard__content_text-left" width={'5%'}>{row.validator}</TableCell>
                <TableCell className="dashboard__content_text-left" width={'10%'}>
                    <span title={row?.comment}>
                        {`${row?.comment !== null ? truncate(row?.comment, 15) : ""}`}
                    </span>
                </TableCell>
                <TableCell className="dashboard__content_text-center" width={'5%'}>{row?.rating}</TableCell>
                <TableCell className="dashboard__content_text-center flex status__column" width={'10%'}>{renderContentStatus(row?.id, row?.contentAdminStatusId, row?.tags)}</TableCell>
                <TableCell className="table__separator" width={'10%'} style={{ padding: "0px" }} >{renderContentProcessingStatus(row?.contentProcessStatusId, row?.isExternalContent)}</TableCell>
                <TableCell className="actions_td" width={'5%'}>
                    <a target="_blank" href={`/curationModifyApp/${row?.id}?fromdashboard=true`}>
                        {!isLoading && <ModeEditOutlineOutlinedIcon fontSize="medium" className="edit__item_users" />}
                    </a>
                </TableCell>
            </TableRow>
        );
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };

    const handleChangePage = (_, newPage) => {
        let sum = 0;
        setIsLoading(true);
        setPage(newPage);
        getContent(requests.getContentPost, content, newPage, pageParams.limit).then(items => {
            const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
            adminStatusCount.forEach(num => sum += num);
            setTotalRecords(sum);
            setMediaContents(items.data);
            setRowsPerPage(items.data.length);
            setTotalPages(items.totalPages);
            setContentTypes(items.contentAdminStatus);
            setIsLoading(false);
        });
    };

    const handleSort = columnIndex => {
        let sum = 0;
        let column = "";
        switch (columnIndex) {
            case 0:
                column = "created";
                break;
            case 8:
                column = "updated";
                break;
            case 9:
                column = "rating";
                break;
        }
        setIsLoading(true);
        setFilterColumn(column);
        setFilterColumnId(columnIndex);
        setSortingStarted(true);
        setOrderType(!orderType);
        const contentTemp = {
            ...content,
            sortBy: columnIndex,
            orderBy: !orderType
        };
        setContent(contentTemp);
        getContent(requests.getContentPost, contentTemp, page, pageParams.limit).then(items => {
            const adminStatusCount = items.contentAdminStatus.map(eachStatus => eachStatus.count);
            adminStatusCount.forEach(num => sum += num);
            setTotalRecords(sum);
            setMediaContents(items.data);
            setRowsPerPage(items.data.length);
            setTotalPages(items.totalPages);
            setContentTypes(items.contentAdminStatus);
            setIsLoading(false);
        });
    };

    const renderTheContentCount = contentType => {
        const cType = contentTypes && contentTypes.filter(e => e.name === contentType);
        if (cType.length > 0) {
            return '(' + cType[0]?.count + ')';
        } else {
            return '';
        }
    };

    const renderWidth = columnId => {
        switch (columnId) {
            case "media":
                return "5%";
            case "created":
                return "5%";
            case "updated":
                return "5%";
            case "title":
                return "10%";
            case "summary":
                return "10%";
            case "tags":
                return "10%";
            case "curator":
                return "10%";
            case "chiefCurator":
                return "5%";
            case "comment":
                return "10%";
            case "rating":
                return "5%";
            case "status":
                return "10%";
            case "processing_status":
                return "10%";
            case "modify":
                return "5%";
        }
    };

    const saveSelectedNewStatus = () => {
        let sum = 0;
        const editStatusLine = {
            "contentAdminStatus": selectedStatusIdForEditStatus,
            "tags": selectedTags
        };
        if (contentIdToSetStatus !== "") {
            setIsLoading(true);
            setNewStatusForTheSelectedContent(null);
            handleClickCloseEditStatusModal();
            patchContentStatusTagsUpdate(requests.patchContentStatusTagsUpdate, contentIdToSetStatus, editStatusLine).then(() => {
                const modifiedMediaContents = mediaContents.map(m => m.id === contentIdToSetStatus ? {
                    ...m,
                    contentAdminStatusId: selectedStatusIdForEditStatus
                } : m)
                setMediaContents(modifiedMediaContents);
                setIsLoading(false);
            });
        }
    };

    const handleClickCloseEditStatusModal = () => {
        setChangedTheStatusEditView(false);
        setOpenStatusEditModal(false);
        setSelectedStatusIdForEditStatus("");
    };

    const handleClickCloseEditTagsModal = () => {
        if (contentIdToSetTags === "") setSelectedTags([]);
        setChangedTheTagsEditView(false);
    };

    const saveSelectedNewTags = () => {
        const editStatusLine = {
            "contentAdminStatus": selectedStatusIdForEditStatus,
            "tags": selectedTags
        };
        setUpdatedTagsAfterRecordUpdate([]);
        if (contentIdToSetTags !== "") {
            setIsLoading(true);
            setChangedTheStatusEditView(false);
            setOpenStatusEditModal(false);
            setChangedTheTagsEditView(false);
            patchContentStatusTagsUpdate(requests.patchContentStatusTagsUpdate, contentIdToSetTags, editStatusLine).then(() => {
                const modifiedMediaContents = mediaContents.map(m => m.id === contentIdToSetTags ? {
                    ...m,
                    tags: selectedTags.toString()
                } : m)
                setMediaContents(modifiedMediaContents);
                setIsLoading(false);
            });
        }
    };

    const handleAddition = tag => {
        let check = selectedTags?.find(st => st.label === tag.label);
        if (!check) {
            setSelectedTags([...selectedTags, tag.label]);
        }
    };

    return (
        <>
            <div className="dashboard__users_wrapper dashboard__media_content_wrapper dashboard__users_wrapper_content">
                <div className="dashboard__left_panel dashboard__left_panel-extended">
                    <nav aria-label="secondary mailbox folders">
                        <List>
                            {((roles && roles.indexOf(Constants.Roles.ADMIN) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR_MANAGER) > -1)) &&
                                <ListItem>
                                    <a className="add__users_btn" target="_blank" href="/curationUploadApp">
                                        {translation.Add_Content}
                                    </a>
                                </ListItem>}
                            {((roles && roles.indexOf(Constants.Roles.ADMIN) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR_MANAGER) > -1)) &&
                                <ListItem className="only__desktop">
                                    <ListItemButton onClick={() => filterBasedOnContentTypeStatus("")} selected={currentFilter === undefined || currentFilter === null || currentFilter === "null" || currentFilter === ""} component="button">
                                        <ListItemText primary={`${translation.all} (${totalRecords})`} />
                                    </ListItemButton>
                                </ListItem>}
                            {((roles && roles.indexOf(Constants.Roles.ADMIN) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR_MANAGER) > -1)) &&
                                <ListItem className="only__desktop">
                                    <ListItemButton onClick={() => filterBasedOnContentTypeStatus(Constants.ContentAdminStatus.IN_PROGRESS.enum)} selected={currentFilter === Constants.ContentAdminStatus.IN_PROGRESS.enum} component="button">
                                        <ListItemText primary={`${Constants.ContentAdminStatus.IN_PROGRESS.labelDashboard} ${renderTheContentCount(Constants.ContentAdminStatus.IN_PROGRESS.name)}`} />
                                    </ListItemButton>
                                </ListItem>}
                            {((roles && roles.indexOf(Constants.Roles.ADMIN) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR_MANAGER) > -1)) &&
                                <ListItem className="only__desktop">
                                    <ListItemButton onClick={() => filterBasedOnContentTypeStatus(Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.enum)} selected={currentFilter === Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.enum} component="button">
                                        <ListItemText primary={`${Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.labelDashboard} ${renderTheContentCount(Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.name)}`} />
                                    </ListItemButton>
                                </ListItem>}
                            {((roles && roles.indexOf(Constants.Roles.ADMIN) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR_MANAGER) > -1)) &&
                                <ListItem className="only__desktop">
                                    <ListItemButton onClick={() => filterBasedOnContentTypeStatus(Constants.ContentAdminStatus.APPROVED.enum)} selected={currentFilter === Constants.ContentAdminStatus.APPROVED.enum} component="button">
                                        <ListItemText primary={`${Constants.ContentAdminStatus.APPROVED.labelDashboard} ${renderTheContentCount(Constants.ContentAdminStatus.APPROVED.name)}`} />
                                    </ListItemButton>
                                </ListItem>}
                            {((roles && roles.indexOf(Constants.Roles.ADMIN) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR_MANAGER) > -1)) &&
                                <ListItem className="only__desktop">
                                    <ListItemButton onClick={() => filterBasedOnContentTypeStatus(Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum)} selected={currentFilter === Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum} component="button">
                                        <ListItemText primary={`${Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.labelDashboard} ${renderTheContentCount(Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.name)}`} />
                                    </ListItemButton>
                                </ListItem>}
                            {((roles && roles.indexOf(Constants.Roles.ADMIN) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR) > -1) || (roles && roles.indexOf(Constants.Roles.CURATOR_MANAGER) > -1)) &&
                                <ListItem className="only__desktop">
                                    <ListItemButton onClick={() => filterBasedOnContentTypeStatus(Constants.ContentAdminStatus.REJECTED.enum)} selected={currentFilter === Constants.ContentAdminStatus.REJECTED.enum} component="button">
                                        <ListItemText primary={`${Constants.ContentAdminStatus.REJECTED.labelDashboard}  ${renderTheContentCount(Constants.ContentAdminStatus.REJECTED.name)}`} />
                                    </ListItemButton>
                                </ListItem>}
                        </List>
                    </nav>
                </div>
                <div className="dashboard__right_panel">
                    <div className="display__loader">
                        {isLoading && <LinearProgress color="inherit" className="progress_bar" />}
                    </div>
                    <div className="table__paper" sx={{ width: '100%', overflow: 'hidden' }}>
                        {(mediaContents && mediaContents.length > 0) ?
                            <>
                                <TableContainer sx={{ maxHeight: 120 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={`manage__content_sticky_header_info extended__table_headers ${changedTheStatusEditView ? 'media__Info_top_header-extended' : 'media__Info_top_header'}`}>
                                                    {translation.Media_Info}
                                                </TableCell>
                                                <TableCell className={`manage__content_sticky_header_info extended__table_headers ${changedTheStatusEditView ? 'curation__Info_top_header-extended' : 'curation__Info_top_header'}`}>
                                                    {translation.Curation_Info}
                                                </TableCell>
                                                <TableCell className="manage__content_sticky_header_info extended__table_headers">
                                                    {''}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </TableContainer>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map(column => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        className={`sticky__header dashboard__content_text-left ${(column.id === 'status' || column.id === 'tags') ? 'dashboard__content_text-center' : ''} ${(column.id === 'tags' || column.id === 'processing_status') ? 'table__separator' : ''} ${column.id == "created" || column.id == "updated" || column.id == "rating" ? 'users__table_header_column' : 'users__table_header_normal_data_column'} ${column.id == "processing_status" ? 'processing__status-column' : ''} ${column.id == "created" || column.id == "updated" ? 'table__header-no-padding-left' : ''}`}
                                                        {...((column.id == "created" || column.id == "updated" || column.id == "rating") ? { onClick: () => { handleSort(column.index) } } : {})}
                                                        width={renderWidth(column.id)}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        <span>{column.label}</span>
                                                        {(column.id == "created" || column.id == "updated" || column.id == "rating") ?
                                                            (orderType === true && (sortingStarted && filterColumn === column.id)) ? <ArrowDropUpIcon fontSize="medium" /> : <ArrowDropDownIcon fontSize="medium" /> : null}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(mediaContents && mediaContents.length > 0 && currentFilter === '') && mediaContents.map(row => renderRowsOnContentTable(row))}
                                            {(mediaContents && mediaContents.length > 0 && currentFilter !== '') && mediaContents.filter(item => item.contentAdminStatusId === currentFilter).map(row => renderRowsOnContentTable(row))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={totalRecords}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[]}
                                    labelRowsPerPage=''
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    backIconButtonProps={{ disabled: page === 1 }}
                                    nextIconButtonProps={{ disabled: page >= totalPages }}
                                />
                                <div className="rows__page_by_page_wrapper">{'page ' + page + ' of ' + totalPages}</div>
                            </> : <div className="empty__media-container"><h3>{`${isLoading ? (translation.Loading + '...') : translation.No_Content_Found}`}</h3></div>}
                    </div>
                </div>
            </div>

            <Dialog open={openStatusEditModal} onClose={handleClickCloseEditStatusModal}>
                <DialogTitle>{translation.Edit_Status}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{translation.Change_Status_Question}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCloseEditStatusModal}>{translation.Cancel}</Button>
                    <Button className="add__users_btn_in_modal" onClick={saveSelectedNewStatus}>
                        {translation.Yes}
                        {isLoading && <CircularProgress
                            size={20}
                            color="inherit"
                            className="progress_bar_circular"
                        />}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={changedTheTagsEditView} onClose={handleClickCloseEditTagsModal}>
                <DialogTitle>{translation.Edit_Tags}</DialogTitle>
                <DialogContent style={{ minWidth: 575, width: "100%", minHeight: 400 }}>
                    <Box sx={{ flexGrow: 1, paddingTop: "6px" }} className="common__top_filtering_panel_wrapper__first_half">
                        <FormControl className="customer__mobile_drop_down content__mobile_drop_down tags__dropdown" style={{ width: "100%" }}>
                            <div>
                                <Grid container style={{ minHeight: 95, paddingBottom: 25 }}>
                                    <>
                                        <Grid item xs={3} sx={{ display: "flex", flexWrap: "wrap" }}>
                                            <Typography
                                                sx={{ fontSize: 16, marginLeft: 0 }}
                                                color="text.secondary"
                                                gutterBottom
                                            >
                                                {translation.Selected_Tags} :
                                            </Typography>
                                        </Grid>
                                        {!!selectedTags?.length && (
                                            <Grid item xs={9} style={{ display: "flex", flexWrap: "wrap" }}>
                                                <Tags includedInDashboard={false} />
                                            </Grid>)}
                                    </>
                                </Grid>
                                <GenericSelect
                                    options={tags}
                                    width={"100%"}
                                    placeholder={translation.Search_For_Tag}
                                    components={{
                                        Option: props => {
                                            return (
                                                <div className="tags_icon_modify">
                                                    <MenuItem onClick={() => handleAddition(props.data)}>
                                                        {props.data.label} ({props.data.contentCount})
                                                    </MenuItem>
                                                </div>
                                            );
                                        },
                                    }}
                                />
                            </div>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCloseEditTagsModal}>{translation.Cancel}</Button>
                    <Button className="default__dashboard_btn" onClick={saveSelectedNewTags}>{translation.Suggestion_Send_Button}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DashboardContent;
