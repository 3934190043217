import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { TiDelete } from "react-icons/ti";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { GetWatchLaterCountAction } from "../../redux/action/GlobalActions.js";

import play from "../../assets/images/home-page-play-icon.svg";
import starRating from "../../assets/images/star-icon-video.svg";
import video from "../../assets/icons/Icons/Label=Video.svg";
import podcast from "../../assets/icons/Icons/Label=Podcast.svg";
import article from "../../assets/icons/Icons/Label=Article.svg";
import { GetSpeakerFromConferenceAction } from "../../redux/action/SpeakerAction.js";
import TagView from "../smallComponents/TagView.jsx";
import "./VideoCardHistory.scss";

const VideoCardHistory = ({
  item,
  appearanceType,
  type,
  isSaved,
  publicationDate,
  contentType,
  playlistDeleteContent,
  typePlayList,
  isPlayList,
  playListCatType,
  index,
  searchScreen
}) => {
  let date;
  const dispatch = useDispatch();
  let isSavedTemp = true;
  isSaved === undefined ? (isSaved = isSavedTemp) : (isSavedTemp = isSaved);
  const { watchLaterCount } = useSelector(state => state.globalReducer);
  const [loader, setLoader] = useState(false);


  if (publicationDate) {
    date = new Date(publicationDate);
  } else if (item?.createdOn !== undefined) {
    date = new Date(item?.createdOn);
  } else if (item?.created) {
    date = new Date(item?.created);
  } else {
    date = new Date(item?.publicationDate);
  }

  useEffect(() => {
    setLoader(false);
  }, [item]);

  const ifWatchLater = val => {
    if (val === 2) dispatch(GetWatchLaterCountAction(watchLaterCount - 1));
  };

  const durationFNC = obj => {
    if (!obj) return "0";
    const timeTable = obj.substring(0, obj.indexOf(".") !== -1 ? obj.indexOf(".") : obj.length).split(":");
    const hh = timeTable[0];
    const mm = timeTable[1];
    const ss = timeTable[2];
    if (hh === "00") {
      return mm + ":" + ss;
    } else {
      return hh + ":" + mm + ":" + ss;
    }
  };

  const renderTheAvatar = (item, searchScreen) => {
    if (searchScreen) {
      return (
        <>
          {(item?.speakersResponse && item?.speakersResponse[0] && item?.speakersResponse[0]?.firstName) &&
            <Avatar
              className="videoCardHistory-row__top--logo"
              src={item?.speakersResponse ? item?.speakersResponse[0]?.avatar : item?.avatar}
            />
          }
          {item?.speakersResponse && item?.speakersResponse[0] ? item?.speakersResponse[0]?.firstName + " " + item?.speakersResponse[0]?.lastName : null}
        </>
      )
    } else {
      return (
        <>
          {(item?.speakers && item?.speakers[0] && item?.speakers[0]?.firstName) &&
            <Link className={"videoCardHistory-speaker-link"} to={"/speaker/" + item?.speakers[0]?.id} 
                  onClick={() => dispatch(GetSpeakerFromConferenceAction(window.location.pathname))} 
            >
              <Avatar
                className="videoCardHistory-row__top--logo"
                src={item?.speakers ? item?.speakers[0]?.avatar : item?.avatar}
              />
              {item?.speakers && item?.speakers[0] ? item?.speakers[0]?.firstName + " " + item?.speakers[0]?.lastName : null}
            </Link>
          }
        </>);
    }
  };

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);


  const barStyle = {
    width: `${clamp(item.percentCompleted, 0, 100) / 2}%`,
    height: '10px',
    backgroundColor: '#b3ab8e',
    transform: 'translateY(-50%)',
    zIndex: 1,
  };

  return (
    (item !== undefined && item !== null) &&
    <>
      <div className={"videoCardHistory"}>
        <Link to={`/conference/${item?.catalogItemId}`} className="videoCardHistory-container">
          <div className="videoCardHistory-row__top">

            <div className={"videoCardHistory-row__top__poster"} style={{
              backgroundImage: `url("${item.image}")`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}>
              <div className="videoCardHistory-row__top--info">
                <Link
                  to={`/conference/${item?.catalogItemId}`}
                  className="videoCardHistory-row__top__play"
                >
                  <img src={play} alt="play" className="videoCardHistory-row__top__play--logo" />
                </Link>
              </div>

              <div className="videoCardHistory-row__top__poster_bottom">
                <img src={(item.mediaTypeId || item.mediaType) === 1 ? video : ((item.mediaTypeId || item.mediaType) === 2 ? podcast : article)} />
                <span className="videoCardHistory-row__top__poster_bottom_label">
                  {durationFNC(item.duration)}
                </span>
              </div>
            </div>
            <div style={barStyle}></div>
            <div className="videoCardHistory-row__text--label--speakername">
              {renderTheAvatar(item, searchScreen)}
            </div>
          </div>
          <div className="videoCardHistory-row__text">
            <div className="videoCardHistory-row__text__top_2">
              <div className="videoCardHistory-row__text--label">{item?.name && item?.name.length > 100 ? `${item?.name.substring(0, 100)}...` : item?.name}</div>
            </div>
            <div className="videoCardHistory-row__text__top">
              <div className="videoCardHistory-fullSummary">
                {/* {item?.fullSummary} */}
                {/* If longer than 300 characters, truncate with ellipsis */}
                {item?.fullSummary && item?.fullSummary.length > 400 ? `${item?.fullSummary.substring(0, 400)}...` : item?.fullSummary}
              </div>
              <TagView tagData={item?.tags} />
              {/* {item?.rating !== 0 &&
                <div className="row__text__bottom--recommended">
                  <img src={starRating} />
                  <span>{item?.rating}</span>
                </div>} */}
            </div>
          </div>
        </Link>

      </div>
    </>
  );
};

VideoCardHistory.propTypes = {
  item: PropTypes.any.isRequired,
  userId: PropTypes.any,
  appearanceType: PropTypes.any,
  isSaved: PropTypes.any,
  ind: PropTypes.any,
};

export default VideoCardHistory;
