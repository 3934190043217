import React, { useEffect, useRef } from 'react';

const DownloadedVideo = ({ videoBlob }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoBlob instanceof Blob) {
      const videoSrc = URL.createObjectURL(videoBlob);
      videoRef.current.src = videoSrc;

      // Request fullscreen mode when the video is ready to play
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.requestFullscreen();
      };

      return () => {
        URL.revokeObjectURL(videoSrc);
      };
    }
  }, [videoBlob]);

  return (
    <div style={containerStyle}>
      <video ref={videoRef} style={videoStyle} controls playsInline>
        <source type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const containerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
  zIndex: 9999,
};

const videoStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
};

export default DownloadedVideo;