import axios from "axios";


const cache = new Map();
const CACHE_DURATION = 2000; // 300ms cache duration

export function getConference(request, id) {
  const cacheKey = `${request}${id}`;
  const now = Date.now();

  if (cache.has(cacheKey)) {
    const { promise, timestamp } = cache.get(cacheKey);
    if (now - timestamp < CACHE_DURATION) {
      return promise;
    }
  }

  const promise = new Promise((resolve, reject) => {
    axios.get(request + id, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  cache.set(cacheKey, { promise, timestamp: now });

  return promise;
}


export function RelatedVideos(request, id, limit, page, preferredLanguages) {

  let preferredLanguageString = '';
  if(Array.isArray(preferredLanguages) && preferredLanguages.length > 0) {
    for (const language of preferredLanguages) {
      preferredLanguageString += `&preferredLanguage=${language}`;
    }
  }

  request = request
  .replace("{catalogItemId}", id)
  .replace("{limit}",limit)
  .replace("{page}", page)
  .replace("{PreferredLanguageIds}", preferredLanguageString);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getComments(request, id, page, limit) {
  
   request = request
     .replace("{catalogitemId}", id)
     .replace("{limit}",limit)
     .replace("{page}", page);

  return new Promise((resolve, reject) => {
    axios
      .get(request,{ withCredentials: true })//teh back needs the second param
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function postWatchHistory(request, id, cursor) {
  return new Promise((resolve, reject) => {
    axios
      .post(request, { contentId: id, lastCursorTime: cursor }, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function patchWatchHistory(request, id, duration, cursor) {
  return new Promise((resolve, reject) => {
    axios
      .patch(request, { id: id, duration: duration, lastCursorTime : cursor }, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getWatchHistoryByContentId(request, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(request + id, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDownloadUrl(request, contentId, quality = 'stream_h264_hq_url') {
  request = request.replace("{contentId}", contentId);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { 
        params: { quality },
        withCredentials: true 
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data.streamingUrl);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*export function getRow(requests, type, category, pageParams) {
  return new Promise((resolve, reject) => {
    axios
      .post(requests, pageParams, {
        params: {type: type, category: category },
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
*/