import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router";

import DownloadedVideo from '../components/Download/DownloadedVideo';

const DownloadedVideoPage = () => {
    const { id } = useParams();
    const [conference, setConference] = useState('')

    function setupIndexedDB() {
        return new Promise((resolve, reject) => {
          const request = indexedDB.open("ConferenceDatabase", 2);
      
          request.onerror = () => reject(request.error);
          request.onsuccess = () => resolve(request.result);
      
          request.onupgradeneeded = event => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains("conferences")) {
              db.createObjectStore("conferences", { keyPath: "id" });
            }
          };
        });
      }

    async function getConferenceData(conferenceId) {
        const db = await setupIndexedDB();
        return new Promise((resolve, reject) => {
          const transaction = db.transaction("conferences", "readonly");
          const store = transaction.objectStore("conferences");
          const request = store.get(conferenceId);
      
          request.onsuccess = () => {
            if (request.result) {
              resolve(request.result);
            } else {
              reject('No data found for ID: ' + conferenceId);
            }
          };
          request.onerror = () => reject(request.error);
        });
    }
    useEffect(() => {
        getConferenceData(id).then( data => {
            setConference(data)
        })
    }, []);


    return (
        <div>
            <DownloadedVideo 
                videoBlob={conference.videoBlob} 
            />
        </div>
    );
}

export default DownloadedVideoPage;