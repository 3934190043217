import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getTags } from "../../servicesRedux/SearchService";
import {getUserTags} from "../../servicesRedux/GlobalService";
import TagClose from "../../assets/images/tag_close_icon.svg"
import requests from "../axios/Requests";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import TagSelect from "./TagSelect"

import { X } from 'phosphor-react';
import TextField from '@mui/material/TextField';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { MenuItem } from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";

const UserTags = (props) => {
  const { setTagList } = props;
  const scrollFixedValue = 0;

  const { translation } = useSelector((state) => state.languageReducer);

  const [tagsFilterSearch, setTagsFilterSearch] = useState('');
  const [checkState, SetCheckState] = useState(false);
  const [currentPageTags, setCurrentPageTags] = useState(1);
  const [totalPageTags, setTotalPageTags] = useState(0);
  const [dropDownTags, setDropDownTags] = useState(props?.tags ?? []);
  const [selectedChipTags, setSelectedChipTags] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isOnScreen, setIsOnScreen] = useState(false);
  const handleCheckInfo = async (val) => SetCheckState(val);

  useEffect(() => {
      getTags(requests.GetTags, tagsFilterSearch, 100, 1).then((item) => {
        setCurrentPageTags(2);
        setDropDownTags([...item.data]);
        setTotalPageTags(item.totalPages);
        setHasLoaded(item.data.length);
      });
    
  }, [tagsFilterSearch]);

  useEffect(() => {
    setTagList(selectedChipTags);
  }, [selectedChipTags]);

  useEffect(() => {
    if (currentPageTags <= totalPageTags && isOnScreen) {
      getTags(requests.GetTags, tagsFilterSearch, 100, currentPageTags).then((item) => {
        setCurrentPageTags(currentPageTags + 1);
        const temp = dropDownTags;
        temp.push(...item.data);
        const dataTemp = { ...item, data: temp };
        setDropDownTags(dataTemp.data);
        setHasLoaded(dataTemp.data.length);
      });
    }
  }, [isOnScreen]);

  const handleformData = async (data) => {
    SetCheckState(false);
  };

  const StyledChip = withStyles({
    root: {
      backgroundColor: '#F7EED8 !important',
      color: '#000000 !important',
      alignSelf: 'center !important',
      alignItems: 'center !important',
      textTransform: 'capitalize !important',
      marginLeft: '0 !important',
      display: 'flex !important',
      justifyContent: 'space-between !important',
      borderRadius: '16px !important',
    },
  })(Chip);

  useEffect(() => {
    getUserTags(requests.GetUserTags).then((item) => {
      setSelectedChipTags([...item]);
      setHasLoaded(item.length);
    });
  }, []);

  const deleteTagFromSelectedChipTags = (tag) => {
    const temp = selectedChipTags;
    const index = temp.indexOf(tag);
    if (index > -1) {
      temp.splice(index, 1);
    }
    setSelectedChipTags([...temp]);
  }

  return (
      <div className="admin__interest personal__interest">
        {!props.titleDisable && <h6>{translation.Your_Personal_Interests}</h6>}


        <div className="your__personal-Interests">
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
            {selectedChipTags.map((item, index) => {
              return (
                <StyledChip deleteIcon={<X weight="bold" size={"15"} color='#000000' />} label={item.label} sx={{ backgroundColor: "#F7EED8" }} onDelete={() => deleteTagFromSelectedChipTags(item)} />
              )
            }
            )}
          </Stack>

          {/* <div className={`admin__categorie__category`}>
            <div className="admin__categorie__category--title">
              <p>{'Web3.0'}</p>
              <img src={TagClose} />
            </div>
          </div> */}
          {/* <Link className="admin__account__contentManagement--button"></Link> */}
          {/* <Autocomplete
            disablePortal
            id="standard-basic"
            options={tempTags}
            label={translation.Add_More}
            variant="standard"
            renderInput={(params) => <TextField
              {...params}
              label={translation.Add_More}
              variant="standard"
              InputProps={{ disableUnderline: true }} />}
          >
          </Autocomplete> */}
          <TagSelect
            choosenList={dropDownTags}
            setTagsFilterSearch={setTagsFilterSearch}
            GetformData={handleformData}
            comboID={5}
            checkInfo={handleCheckInfo}
            setHasLoaded={setHasLoaded}
            setIsOnScreen={setIsOnScreen}
            selectedChipTags={selectedChipTags}
            setSelectedChipTags={setSelectedChipTags}
            hasLoaded={hasLoaded}
          />

        </div>
      </div>
  )
}

export default UserTags;