import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import Box from '@mui/material/Box';
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import requests from "../axios/Requests";
import { getTheDataForReportingOnNumberOfActiveUsers } from "../../servicesRedux/AdminService";

import { getMonthName, fixDateString } from "../../hooks/utils";

import { styles } from "./DashboardComponentStyles";

const HomepageActiveComponent = props => {
    const { 
        classes, 
        startDate, 
        endDate, 
        selectedCompany, 
        selectedDateRange,
        changeOfMonthlySelectionEndPoint,
        setIsLoading
    } = props;   
    const [dataNumberOfUsers, setDataNumberOfUsers] = useState([]);
    const [dataNumberOfActualUsers, setDataNumberOfActualUsers] = useState([]);
    const optionsOnNumberOfActiveUsers = {
        title: `${(changeOfMonthlySelectionEndPoint ? "Monthly" : "Daily")} active users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,
        hAxis: { title: (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), titleTextStyle: { color: "#333" }, minValue: 1 },
        vAxis: { title: 'Active Users', minValue: 1 },
        chartArea: { width: "50%", height: "70%" },
        colors: ['#FB7A21'],
    };

    useEffect(() => {
        setIsLoading(true);
        getTheDataForReportingOnNumberOfActiveUsers((changeOfMonthlySelectionEndPoint ? requests.GetNoOfActiveUsersForThisMonth : requests.GetNoOfActiveUsers), startDate, endDate, selectedCompany).then(items => {
            let tempUsers = [];
            tempUsers.push([
                (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), 
                `Nb of active users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                { role: "style" }
        ]);
            if (changeOfMonthlySelectionEndPoint) {
                items.kpiNbOfMonthlyActiveUser.forEach(item => tempUsers.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.nbOfActiveUser, 
                    ""
                ]));
            } else {                
                items.kpiNbOfDailyActiveUser.forEach(item => tempUsers.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfActiveUser, 
                    ""
                ]));
            }            
            setDataNumberOfUsers(tempUsers);             
            setDataNumberOfActualUsers(items.uniqueActiveUserCount);
            setIsLoading(false);
        });       
    }, [startDate, endDate, selectedCompany, changeOfMonthlySelectionEndPoint]);

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1', display: 'flex', flexDirection: 'column' }} className="home__page-charts-row other__page-charts-row-extended">                           
                <Paper 
                    className={classes.paper} 
                    sx={{ width: 175, overflow: 'hidden' }}
                >                    
                    <Card className={classes.card}>
                        <CardContent style={{ minHeight: 170, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                            <Typography className={classes.title} color="textSecondary">{`Number of Active users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`}</Typography>                            
                            <Typography className={classes.subTitle} component="p">{dataNumberOfActualUsers}</Typography>
                            <Typography className={classes.subTitle} component="p"></Typography>                                
                        </CardContent>
                    </Card>
                </Paper>
                <Paper className={classes.tablePaper}>
                    {dataNumberOfUsers.length > 0 &&
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="600px"
                        data={dataNumberOfUsers}
                        options={optionsOnNumberOfActiveUsers}
                    />}                                                
                </Paper>
            </Box>           
        </>
    );
}

export default withStyles(styles)(HomepageActiveComponent);