import * as types from "../constant/index";
import Fr_Register from "../../resources/fr-FR.json";

export default function reducer(
  state = {
    language: "",
    translation: Fr_Register,
  },
  action
) {
  switch (action.type) {
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case types.GET_TRANSLATION:
      return {
        ...state,
        translation: action.payload,
      };

    default:
      return state;
  }
}
