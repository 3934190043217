// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * TagsNestedList page *                                       *
 * ?   TagsNestedList : user can choose mulitple-filters search for any conference contains in the database   *
 *                        TODO: Change the checkbox List    *
 */
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Checkbox } from "@mui/material";
import { MdOutlineSearch } from "react-icons/md";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import { ClickAwayListener } from "@material-ui/core";

import PaginatorNoRedux from "../pagination/PaginatorNoRedux";

import useDebounce from "../../hooks/Debounce";

import "./TagSelect.scss";

const styles = (theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        background: "#CCC",
        color: "black",
    },
    nested: {
        zIndex: "3",
        backgroundColor: "#f8f7f6",

    },
});

const TagSelect = (props) => {
    const { classes, choosenList, GetformData, checkInfo, setTagsFilterSearch, setHasLoaded, setIsOnScreen, hasLoaded, selectedChipTags, setSelectedChipTags } = props;
    const [open, setOpen] = useState(false);
    const initialFormData = Object.freeze([]);
    const [filterList, setFilterList] = useState(initialFormData);
    const [tagsList] = useState(choosenList);
    const [mapState, setMapState] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    useEffect(() => {
        GetformData && GetformData(filterList, "tags");
    }, [filterList]);

    useEffect(() => {
        if (debouncedSearchTerm !== undefined && debouncedSearchTerm.length > 1) {
            setTagsFilterSearch(debouncedSearchTerm);
        }
        if (debouncedSearchTerm !== undefined && debouncedSearchTerm.length === 0) {
            setTagsFilterSearch("");
        }
    }, [debouncedSearchTerm]);

    /* **************************************** Display/Hide ComboBox items ************************************************ */
    const showCheckBoxList = () => setOpen(!open);

    const onItemClick = (id) => {
        setMapState(true);
        checkExistance(id);
    };

    /* ************************************* Check value Existance into List ********************************************** */
    const checkExistance = (id) => {
        const temp = filterList.find((fl) => fl === id);
        if (temp === undefined) {
            checkInfo(true);
            setFilterList([...filterList, id]);
        } else {
            checkInfo(true);
            setFilterList(filterList.filter((fl) => fl !== id));
        }
    };

    /* ************************************* Searching Tags into combobox list ********************************************** */
    const onChangeTagsinput = (e) => {
        setSearchTerm(e.target.value);
        // setMapState(true);
        // var searchedValue = e.target.value.toLowerCase();
        // var temp = choosenList.filter(
        //   (f) =>
        //     f.label.toLowerCase().includes(searchedValue)
        // );
        // setTagsList(temp);
    };

    /* ************************************* Verifiy value checked into List ********************************************** */
    const getChecked = (id) => {
        if (id !== undefined && id !== null) {
            const temp = filterList.find((fl) => fl === id);
            if (temp === undefined) {
                return false;
            } else {
                return true;
            }
        }
    };

    const appendSelectedChipTags = (tag) => {
        if (!selectedChipTags.includes(tag)) {
            setSelectedChipTags([...selectedChipTags, tag]);
        }
    };

    const useStyles = makeStyles((theme) => ({
        listitemroot: {
            backgroundColor: "#f8f7f6",
        },
        listroot: {
            backgroundColor: "#f8f7f6",
            marginTop: "10px",
        },
    }));
    

    const listClasses = useStyles();

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <>
                <List 
                    className="NestedListFilter-root-Speaker NestedListFilter-root-tags"
                    classes={{
                        root: listClasses.listroot,
                    }}
                    >
                    <ListItem
                        button
                        onClick={() => showCheckBoxList()}
                        classes={{
                            root: listClasses.listitemroot,
                        }}
                        >
                        
                        <ListItemText primary={"Add more..."} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <ListItem className="custom__checkbox--nested-taglist">
                        <CustomCheckBox 
                            val={open}
                            searchTerm={searchTerm}
                            onChangeTagsinput={onChangeTagsinput}
                        />
                    </ListItem>
                </List>
                <List className="NestedListFilter-root-Speaker NestedListFilter-root-tags">
                    {(mapState === false || tagsList?.length === 0
                        ? choosenList
                        : tagsList
                    )?.map((item, index) => {
                        return (
                            <Collapse
                                className="nested_collapseList"
                                component="li"
                                in={open}
                                timeout="auto"
                                unmountOnExit
                            >
                                <div
                                    onClick={() => appendSelectedChipTags(item)}
                                    style={{ background: 'transperent' }}
                                >
                                    <ListItem button key={index} className={classes.nested}>
                                        <ListItemText 
                                            key={index} 
                                            primary={item?.label} 
                                            />
                                    </ListItem>
                                </div>
                                {(choosenList.length === index + 1 || tagsList.length === index + 1)
                                    && <PaginatorNoRedux
                                        setHasLoaded={setHasLoaded}
                                        setIsOnScreen={setIsOnScreen}
                                        hasLoaded={hasLoaded}
                                    />}
                            </Collapse>
                        );
                    })}
                </List>
            </>
        </ClickAwayListener>
    );
};

TagSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    choosenList: PropTypes.object,
    GetformData: PropTypes.func,
    comboID: PropTypes.object,
    checkInfo: PropTypes.func,
};

export default memo(withStyles(styles)(TagSelect));


const CustomCheckBox = (props) => {
    const InputStyles = {
        root: {
            backgroundColor: "#f8f7f6",
            marginTop: "10px",
            width: "100%",
        },
        input: {
            width: "100%",
        },
    }

    const StyledInput = withStyles(InputStyles)(Input);

    return (
        props.val === true && (
            <div>
                <Input
                    type="text"
                    style={{ marginLeft: "0px" }}
                    endAdornment={
                        <InputAdornment position="end">
                            <MdOutlineSearch size={20} style={{ marginRight: 10 }} />
                        </InputAdornment>
                    }
                    onChange={props.onChangeTagsinput}
                />
                <div style={{ margin: 20 }}></div>
            </div>
        )
    );
};