import React from 'react'
import UserTags from '../userTags/UserTags'
import { forwardRef } from 'react';

const PersonalInterestSelect = forwardRef(({ tags, setTagList, translation }, ref) => {

    return (
        <div>
            <h1>{translation.Select_Personal_Interest}</h1>
            <br />
            <p>{translation.Choose_Options}</p>
            <UserTags tags={tags} setTagList={setTagList} titleDisable={true} />
        </div>
    )
})

export default PersonalInterestSelect