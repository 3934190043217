import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { GetWatchLaterCountAction } from "../../redux/action/GlobalActions.js";

import play from "../../assets/images/home-page-play-icon.svg";
import video from "../../assets/icons/Icons/Label=Video.svg";
import podcast from "../../assets/icons/Icons/Label=Podcast.svg";
import article from "../../assets/icons/Icons/Label=Article.svg";
import { GetSpeakerFromConferenceAction } from "../../redux/action/SpeakerAction.js";
import TagView from "./DownloadedTagView.jsx";
import "./DownloadedVideoCard.scss"; // Assure-toi d'importer le bon fichier CSS pour le style

const DownloadedVideoCard = ({
  item,
  appearanceType,
  type,
  isSaved,
  publicationDate,
  contentType,
  playlistDeleteContent,
  typePlayList,
  isPlayList,
  playListCatType,
  index,
  searchScreen
}) => {
  let date;
  const dispatch = useDispatch();
  let isSavedTemp = true;
  isSaved === undefined ? (isSaved = isSavedTemp) : (isSavedTemp = isSaved);
  const { watchLaterCount } = useSelector(state => state.globalReducer);
  const [loader, setLoader] = useState(false);

  if (publicationDate) {
    date = new Date(publicationDate);
  } else if (item?.createdOn !== undefined) {
    date = new Date(item?.createdOn);
  } else if (item?.created) {
    date = new Date(item?.created);
  } else {
    date = new Date(item?.publicationDate);
  }

  useEffect(() => {
    setLoader(false);
  }, [item]);

  const ifWatchLater = val => {
    if (val === 2) dispatch(GetWatchLaterCountAction(watchLaterCount - 1));
  };

  const durationFNC = obj => {
    if (!obj) return "0";
    const timeTable = obj.substring(0, obj.indexOf(".") !== -1 ? obj.indexOf(".") : obj.length).split(":");
    const hh = timeTable[0];
    const mm = timeTable[1];
    const ss = timeTable[2];
    if (hh === "00") {
      return mm + ":" + ss;
    } else {
      return hh + ":" + mm + ":" + ss;
    }
  };

  const renderTheAvatar = (item, searchScreen) => {
    if (searchScreen) {
      return (
        <>
          {(item?.speakerResponse && item?.speakerResponse[0] && item?.speakerResponse[0]?.firstName) &&
            <Avatar
              className="downloadedVideoCard-row__top--logo"
              src={item?.speakerResponse ? item?.speakerResponse[0]?.image : item?.image}
            />
          }
          {item?.speakerResponse && item?.speakerResponse[0] ? item?.speakerResponse[0]?.firstName + " " + item?.speakerResponse[0]?.lastName : null}
        </>
      )
    } else {
      return (
        <>
          {(item?.speaker && item?.speaker[0] && item?.speaker[0]?.firstName) &&
            <Link className={"downloadedVideoCard-speaker-link"} to={"/speaker/" + item?.speaker[0]?.id} 
                  onClick={() => dispatch(GetSpeakerFromConferenceAction(window.location.pathname))} 
            >
              <Avatar
                className="downloadedVideoCard-row__top--logo"
                src={item?.speaker ? item?.speaker[0]?.image : item?.image}
              />
              {item?.speaker && item?.speaker[0] ? item?.speaker[0]?.firstName + " " + item?.speaker[0]?.lastName : null}
            </Link>
          }
        </>);
    }
  };

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);


  const barStyle = {
    width: `${clamp(item.percentCompleted, 0, 100) / 2}%`,
    height: '10px',
    backgroundColor: '#b3ab8e',
    transform: 'translateY(-50%)',
    zIndex: 1,
  };

  return (
    (item !== undefined && item !== null) &&
    <>
      <div className={"downloadedVideoCard"}>
        <Link to={`/downloaded/${item.contentId}`} className="downloadedVideoCard-container">
          <div className="downloadedVideoCard-row__top">
            <div className={"downloadedVideoCard-row__top__poster"} style={{
              backgroundImage: `url("${item.conference.thumbnailURL}")`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}>
              <div className="downloadedVideoCard-row__top--info">
                <Link
                  to={`/downloaded/${item.contentId}`}
                  className="downloadedVideoCard-row__top__play"
                >
                  <img src={play} alt="play" className="downloadedVideoCard-row__top__play--logo" />
                </Link>
              </div>

              <div className="downloadedVideoCard-row__top__poster_bottom">
                <img src={
                  item.conference.filename.endsWith('.mp4') ? video :
                  item.conference.filename.endsWith('.mp3') ? podcast :
                  item.conference.filename.endsWith('.pdf') ? article : null
                } />
                <span className="downloadedVideoCard-row__top__poster_bottom_label">
                  {durationFNC(item.duration)}
                </span>
              </div>
            </div>
            <div className="downloadedVideoCard-row__text--label--speakername">
              {renderTheAvatar(item, searchScreen)}
            </div>
          </div>
          <div className="downloadedVideoCard-row__text">
            <div className="downloadedVideoCard-row__text__top_2">
              <div className="downloadedVideoCard-row__text--label">{item?.name && item?.name.length > 100 ? `${item?.name.substring(0, 100)}...` : item?.infoConference.name}</div>
            </div>
            <div className="downloadedVideoCard-row__text__top">
              <div className="downloadedVideoCard-fullSummary">
                {item?.fullSummary && item?.fullSummary.length > 400 ? `${item?.fullSummary.substring(0, 400)}...` : item?.infoConference.fullSummary}
              </div>
              <TagView tagData={item?.tag || []} />  {/* Assure que tagData est toujours un tableau */}
            </div>
          </div>
        </Link>

      </div>
    </>
  );
};

DownloadedVideoCard.propTypes = {
  item: PropTypes.any.isRequired,
  userId: PropTypes.any,
  appearanceType: PropTypes.any,
  isSaved: PropTypes.any,
  ind: PropTypes.any,
};

export default DownloadedVideoCard;
