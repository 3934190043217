import * as types from "../constant/index";


export default function reducer(
    state = {
        content: [],
        channel: [],
        allSocialMedia: [],
        loadingSocialMedia: false,
    },
    action
) {

    switch (action.type) {
        case types.GET_CONTENT:
            return {
                ...state,
                content: action.payload,
            };
        case types.GET_CHANNEL:
            return {
                ...state,
                channel: action.payload,
            };
        case types.GET_ALL_SOCIAL_MEDIA:
            return {
                ...state,
                loadingSocialMedia: true,
            };

        case types.GET_ALL_SOCIAL_MEDIA_SUCCESS:
            return {
                ...state,
                allSocialMedia: action.payload,
                loadingSocialMedia: false,
            };

        default:
            return state;
    }
}