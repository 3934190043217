import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import { InputLabel } from "@mui/material";
import { countries } from "countries-list";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from "js-cookie";
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSelector } from "react-redux";
import { getSettings } from "../../servicesRedux/SettingsService";
import useReplacer from "../../hooks/replacer";
import TermsAndConditions from "./TermsAndConditions";

import requests from "../axios/Requests";
import { postRegistration, resendConfirmationEmail } from "../../servicesRedux/AuthentificationService";

import "./Registration.scss";

export default function Registration() {
  const { translation } = useSelector(state => state.languageReducer);

  const [accountType, setAccountType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");

  const [accountTypeError, setAccountTypeError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [countryError, setCountryError] = useState("");

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState("");

  const [promotionalEmails, setPromotionalEmails] = useState(false);
  const [promotionalEmailsError, setPromotionalEmailsError] = useState("");

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [canCheck, setCanCheck] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [validationState, setValidationState] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [settings, setSettings] = useState(null);
  const navigate = useNavigate();
  const replacer = useReplacer();
  useEffect(() => {
    if (Cookies.get("Payload")) {
      navigate("/");
    }
  }, []);



  useEffect(() => {
    getSettings(requests.GetSettings).then((res) => {
      if(!res.registrationFormState){
        //redirect to login page
        navigate("/login");
      }

        setSettings(res)
    }
    );
  }, []);

  useEffect(() => {

    //password validation, at least 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character
    if (password) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(password)) {
        setPasswordError(
          translation.setPasswordError
        );
      } else {
        setPasswordError("");
      }
    }
  }, [password, isFormSubmitted]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    //email validation
    if (email && !isValidEmail(email)) {
      setEmailError(translation.setEmailError);

    } else {
      setEmailError("");
    }
  }, [email]);

  useEffect(() => {
    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      passwordError ||
      companyNameError ||
      countryError ||
      acceptTermsError ||
      promotionalEmailsError
    ) {
      setIsFormValid(false);
      setCanCheck(true);
    } else {
      setIsFormValid(true);
      setCanCheck(true);
    }
  }, [
    firstNameError,
    lastNameError,
    emailError,
    passwordError,
    companyNameError,
    countryError,
    acceptTermsError,
    promotionalEmailsError,
  ]);

  const handleCountryChange = (event) => {
    const country = event;
    setCountry(country);
    if (isFormSubmitted && !country) {
      setCountryError(translation.setCountryError);
    } else {
      setCountryError("");
    }
  };
  const handleSubmit = (e) => {
    let valid = true;
    e.preventDefault();
    if (!firstName) {
      setFirstNameError(translation.setFirstNameError);
      valid = false;
    }
    if (!lastName) {
      setLastNameError(translation.setLastNameError);
      valid = false;
    }
    if (!email) {
      setEmailError(translation.setEmailError);
      valid = false;
    }
    if (!password) {
      setPasswordError(translation.setPasswordError);
      valid = false;
    }
    if (!companyName && accountType === "company") {
      setCompanyNameError(translation.setCompanyNameError);
      valid = false;
    }
    if (!country) {
      setCountryError(translation.setCountryError);
      valid = false;
    }
    if (!acceptTerms) {
      setAcceptTermsError(translation.setAcceptTermsError);
      valid = false;
    }
    if (!accountType) {

      setAccountTypeError(translation.setAccountTypeError);
      valid = false;
    }
    setIsFormSubmitted(valid);
  };

  useEffect(() => {
    let valid = false;
    if (canCheck) {
      if (firstName) {
        setFirstNameError("");
        valid = true;
      }
      if (lastName) {
        setLastNameError("");
        valid = true;
      }
      if (email && isValidEmail(email)) {
        setEmailError("");
        valid = true;
      }
      if (companyName) {
        setCompanyNameError("");
        valid = true;
      }
      if (country) {
        setCountryError("");
        valid = true;
      }
      if (acceptTerms) {
        setAcceptTermsError("");
        valid = true;
      }
      if (promotionalEmails) {
        setPromotionalEmailsError("");
        valid = true;
      }
      if (accountType) {
        setAccountTypeError("");
        valid = true;
      }
      if (passwordError) {
        valid = false;
      }
      setIsFormValid(valid);
    }
  }, [
    firstName, lastName, email, password, companyName, country, acceptTerms, promotionalEmails, accountType, isFormSubmitted
  ]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    snackbar: {
      // Add custom styles for Snackbar
      maxWidth: '100%',
      margin: '0 auto',
    },
    alert: {
      // Add custom styles for Alert
      fontSize: '18px',
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Barlow',
    },
  }));

  const classes = useStyles();


  useEffect(() => {
    if (isFormValid && isFormSubmitted && canCheck && validationState) {
      const registrationData = {
        accountTypeId: accountType === "company" ? 2 : 3,
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        companyName: companyName,
        nationality: country.value,
        role: "Manager",
        IsTOSAccepted: acceptTerms,
        TOSAcceptedDate: new Date(),
        IsPrivacyAccepted: acceptTerms,
        PrivacyAcceptedDate: new Date(),
        IsPromotionalEmailAccepted: promotionalEmails,
        PromotionalEmailAcceptedDate: new Date(),
      };
      postRegistration(requests.Registration, registrationData).then((response) => {
        if (response?.data?.status === "Success") {
          setCanCheck(false);
          setThankYou(true);
        }
        setValidationState(false);
      }).catch((error) => {
        setIsFormValid(false);
        if (error.response.data.message === "User already exists!") {
          setEmailError(translation.userExists);
          setSnackbarMessage(translation.userExists);
        }
        else {
          setSnackbarMessage(error.response.data.message)
        }
        setSnackbarOpen(true);
        setValidationState(false);
      })
    }
  }, [isFormValid, isFormSubmitted, canCheck, validationState]);

  return (
    <div className="b2b-registration">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        style={{
          marginTop: 0,
        }}
      >
        <Grid container spacing={2} className="reg__background--container">
          <Grid item xs={12} md={6} lg={6} className="reg__left--background">

            <div className="reg__left--background--internal">
              <img className="login--logo" src={settings?.logoUrl} alt="logo" />
              {!thankYou ? (
                <>
                  <h1 className="b2b-registration__container__header__title">
                    {translation.signUpHeader}
                  </h1>
                  <p className="b2b-registration__container__header__description">
                    {translation.signUpDescription} <Link to="/login">{translation.signInLink}</Link>
                  </p>
                  <br />
                  <p className="b2b-registration__container__header__description-reg">{translation.registerAs}</p>

                  <form
                    className="b2b-registration__container__form"
                    onSubmit={handleSubmit}
                  >
                    <div className="b2b-registration-checkbox">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={accountType === "company"}
                            onChange={(e) => setAccountType("company")}
                            name="accountType"
                            color="primary"
                            style={{
                              transform: "scale(1.3)",
                              paddingLeft: "11px",
                            }}
                          />
                        }
                        label={translation.aCompany}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={accountType === "individual"}
                            onChange={(e) => setAccountType("individual")}
                            name="accountType"
                            color="primary"
                            style={{
                              transform: "scale(1.3)",
                            }}
                          />
                        }
                        label={translation.anIndividual}
                      />
                      {accountTypeError && <p className={"terms_error"}>{accountTypeError}</p>}

                    </div>
                    <div className="b2b-registration__container__form__input-name">
                      <TextField
                        id="firstName"
                        label={translation.firstName}
                        className="b2b-registration__container__form__input"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        error={isFormSubmitted ? !firstName : ""}
                        helperText={firstNameError}
                        variant="outlined"
                        fullWidth
                      />

                      <TextField
                        id="lastName"
                        label={translation.lastName}
                        className="b2b-registration__container__form__input"
                        style={{
                          marginLeft: "10px",
                        }}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        error={isFormSubmitted ? !lastName : ""}
                        helperText={lastNameError}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                    <TextField
                      id="email"
                      label={translation.email}
                      className="b2b-registration__container__form__input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={isFormSubmitted ? emailError !== "" : ""}
                      helperText={emailError}
                      variant="outlined"
                      fullWidth
                    />

                    <TextField
                      id="password"
                      label={translation.password}
                      type={showPassword ? 'text' : 'password'}
                      className="b2b-registration__container__form__input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      variant="outlined"
                      fullWidth
                      helperText={passwordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="toggle password visibility"
                              onClick={handleTogglePasswordVisibility}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {accountType === "company" && (
                      <TextField
                        id="companyName"
                        label={translation.companyName}
                        className="b2b-registration__container__form__input"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        error={isFormSubmitted ? !companyName : ""}
                        helperText={companyNameError}
                        variant="outlined"
                        fullWidth
                      />
                    )}

                    {/* <TextField
                id="country"
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                error={isFormSubmitted && !country}
                helperText={countryError}
                variant="outlined"
                margin="normal"
                fullWidth
                required
                /> 
                //country select
                */}
                    <FormControl
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      error={isFormSubmitted ? !country : ""}
                      style={{ marginTop: "5px" }}
                    >
                      <InputLabel id="country-select-label">{translation.countryLabel}</InputLabel>

                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: "56px",
                            fontSize: '26px',
                            fontWeight: '600',
                            fontFamily: 'Barlow',
                          }),
                        }}
                        className="b2b-registration__container__form__input_select"
                        value={country}
                        options={Object.entries(countries).map(([key, value]) => ({ value: key, label: value.name }))}
                        onChange={handleCountryChange}
                        placeholder={translation.selectCountry}
                      />
                      <FormHelperText>{countryError}</FormHelperText>
                    </FormControl>

                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={acceptTerms}
                          // onChange={(e) => setAcceptTerms(e.target.checked)}
                          error={isFormSubmitted ? !acceptTerms : ""}
                          name="acceptTerms"
                          color="primary"
                        />
                      }
                      error={isFormSubmitted && !acceptTerms}
                    /> */}

                    <TermsAndConditions translation={translation} acceptTerms={acceptTerms} setAcceptTerms={setAcceptTerms} />
                    {acceptTermsError && <p className={"terms_error"}>{acceptTermsError}</p>}

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={promotionalEmails}
                          onChange={(e) => setPromotionalEmails(e.target.checked)}
                          name="promotionalEmails"
                          color="primary"
                          style={{
                            transform: "scale(1.3)",
                            paddingLeft: "11px",
                          }}
                        />
                      }
                      label={translation.Accept_Promotional_Emails}
                      error={isFormSubmitted ? !promotionalEmails : ""}
                      helperText={promotionalEmailsError}
                    />

                    <Button
                      type="submit"
                      className="b2b-registration__container__form__button"
                      variant="contained"
                      fullWidth
                      sx={{ mt: 3, mb: 2 }}
                      disabled={!isFormValid}
                      onClick={() => setValidationState(true)}
                    >
                      {translation.signUpButton}
                    </Button>
                  </form>
                </>
              ) : (
                <ThankYou translation={translation} email={email} replacer={replacer} />
              )}
            </div>

          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        className={classes.snackbar} // Apply custom styles to Snackbar
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={'error'}

          className={classes.alert} // Apply custom styles to Alert
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}


function ThankYou(props) {
  const { email, translation } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    snackbar: {
      // Add custom styles for Snackbar
      maxWidth: '100%',
      margin: '0 auto',
    },
    alert: {
      // Add custom styles for Alert
      fontSize: '18px',
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Barlow',
    },
  }));

  const classes = useStyles();

  const handleResendEmail = () => {

    resendConfirmationEmail(requests.ResendConfirmationEmail, email)
      .then((response) => {
        const { status, message } = response.data;

        switch (status) {
          case 'Success':
            setSnackbarMessage(translation.successMessage);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            break;
          case 'Error':
            if (message === 'email is invalid') {
              setSnackbarMessage(translation.emailInvalidMessage);
            } else if (message === 'registration confirmation is already done!') {
              setSnackbarMessage(translation.confirmationAlreadyDoneMessage);
            } else {
              setSnackbarMessage(translation.unknownErrorMessage);
            }
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            break;
          default:
            setSnackbarMessage(translation.unknownErrorMessage);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);

        }
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error(error);
        setSnackbarMessage(translation.resendConfirmationError);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  return (
    <div className="thank-you">
      <h1>{props.replacer(translation.Thank_You_Register)}</h1>
      <br />
      <p>{translation.Receive_Email_Shortly}</p>
      <br />
      <p>{translation.Receive_Email_Confirm}</p>
      <br />
      <p>{translation.Receive_Email_Not_Received}</p>
      <div className="thank-you_links">
        <a onClick={handleResendEmail}>{translation.Resend_Confirmation_Email}</a>
        <a href="https://topcream.global/contact/" target="_blank">{translation.Contact_Us}</a>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        className={classes.snackbar} // Apply custom styles to Snackbar
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          className={classes.alert} // Apply custom styles to Alert
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}