import * as React from "react";
import CommentForm from "./CommentForm";
import "./Comments.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import ReplyComponent from "./reply/Reply.jsx";
import { getAllRepliesByIDAction } from "../../../redux/action/CommentsAction";
import { getAllRepliesByID } from "../../../servicesRedux/CommentService";
import { useDispatch, useSelector } from "react-redux";
import requests from "./../../axios/Requests";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Comment = ({
  comment,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  currentUser,
  translation
}) => {
  const isEditing =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "editing";
  const fiveMinutes = 300000;
  const commentsLimit = 10;
  const timePassed = new Date() - new Date(comment.createdAt) > fiveMinutes;
  dayjs.extend(utc);

  let canDelete = currentUser.userId === comment.userID;
  const canEdit = currentUser.userId === comment.userID && !timePassed;
  const { Reply } = useSelector((state) => state.commentsReducer);
  const [open, setOpen] = React.useState(false);
  const [openReplies, setOpenReplies] = React.useState(false);
  const [showReplyInput, setShowReplyInput] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    setOpen(false);
    deleteComment(comment.id);
  };

  const handleReplies = (id) => {
    var checkExistance = Reply?.find((r) => r.id === id) ? true : false;
    if (openReplies === true && !checkExistance) {
      getAllRepliesByID(requests.GetAllReplies, id, commentsLimit, 1).then(
        (item) => {
          dispatch(getAllRepliesByIDAction(item, id));
        }
      );
    }
    setOpenReplies(!openReplies);
  };

  const repliesComponent = (idComment) => {
    // var checkExistance = Reply.find((r) => r.id === idComment) ? true : false;

    var data = Reply?.find((r) => r.id === idComment);
    if (
      data !== undefined &&
      data?.id !== undefined &&
      data?.id !== null &&
      openReplies
    ) {
      var rows = data?.row.data;
      return (
        <>
          {rows.map((content, index) => (
            <div key={index}>
              <ReplyComponent
                Reply={content}
                index={index}
                idUser={currentUser.userId}
                parentID={comment.id}
                translation={translation}
              />
            </div>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  const showComponent = () => {
    setShowReplyInput(!showReplyInput);
  };

  React.useEffect(() => {
    repliesComponent(comment.id);
    setOpenReplies(true);
  }, [Reply]);

  const viewAllReplies = (id, childCount) => {
    return (
      <div>
        {childCount > 0 && (
          <div
            className="comment__reply"
            onClick={() => {
              handleReplies(id);
            }}
          >
            View All Replies({childCount})
          </div>
        )}
      </div>
    );
  };

  React.useEffect(() => {
    viewAllReplies();
  }, [comment.childCount]);

  const relativeTime = date => {
    const now = new Date();
    const localDatej = dayjs.utc(date)

    const seconds = Math.floor((now.getTime() - new Date(localDatej).getTime()) / 1000);

    const intervals = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
    const names = translation.datesNames;
    const namesPlural = translation.datesNamesPlural;
    const lang = translation.language;
  
    const ago = lang === 'en' ? translation.dateSuffixPrefix : '';
    const ilYa = lang === 'fr' ? translation.dateSuffixPrefix : '';
  
    for (let i = 0; i < intervals.length; i++) {
      if (seconds >= intervals[i]) {
        const interval = Math.floor(seconds / intervals[i]);
        return (
          ilYa +
          interval +
          ' ' +
          (interval > 1 ? namesPlural[i] : names[i]) +
          ago
        );
      }
    }
    return ilYa + (Math.floor(seconds) <= 0 ? 0 : Math.floor(seconds)) + ' ' + translation.seconds + ago;
  };

  return (
    <div key={comment.id} className="comment">
      <div className="comment__container" style={{
        backgroundImage: `url(${comment.avatar !== "string" && comment.avatar !== "" && comment.avatar !== null ? comment.avatar : "/images/placeholder_avatar.png"})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '40px',
        borderRadius: '50%',
        backgroundSize: 'cover'
      }} />
      <div className="comments__text">
        <div className="comment__upper">
          <div className="comment__upper--user">
            {comment.firstName} {comment.lastName}
          </div>
          <div className="comment__date">
            {/* dayjs */}
            {relativeTime(comment.created)}
          </div>
        </div>
        {!isEditing && (
          <div
            className={
              comment.body === null ? "comment__text__deleted" : "comment__text"
            }
          >
            {comment.body === null ? "Deleted Comment" : comment.body}
          </div>
        )}
        {isEditing && (
          <CommentForm
            submitLabel="Update Comment"
            hasCancelButton
            parentID={comment.id}
            isEditing={isEditing}
            initialText={comment.body}
            handleSubmit={(e) => {
              updateComment(e, comment.id);
            }}
            handleCancel={() => {
              setActiveComment(null);
            }}
            currentUser={currentUser}
          />
        )}
        {/* <div className="comment__actions">
          {
            <div className="comment__action">
              {viewAllReplies(comment.id, comment.childCount)}

              <div
                onClick={() => {
                  setActiveComment({ id: comment.id, type: "replying" });
                  setShowReplyInput(true);
                }}
                className="comment__reply"
              >
                {translation.Conference_Reply}
              </div>
            </div>
          }
          {canEdit && canDelete && (
            <>
              <div
                className="comment__action"
                onClick={() =>
                  setActiveComment({ id: comment.id, type: "editing" })
                }
                style={{ cursor: "pointer" }}
              >
                <FiEdit />
              </div>
              <div
                className="comment__action"
                onClick={() => {
                  handleClickOpen();
                }}
                style={{ cursor: "pointer" }}
              >
                <MdDeleteForever size={15} />
              </div>
            </>
          )}
        </div> */}

        {repliesComponent(comment.id)}
        {showReplyInput && (
          <CommentForm
            submitLabel="Reply ..."
            hasCancelButton
            isEditing={isEditing}
            initialText={""}
            handleCancel={() => {
              setActiveComment(null);
            }}
            currentUser={currentUser}
            isReply={true}
            parentID={comment.id}
            showComponent={showComponent}
          />
        )}
        {open && (
          <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              Are you sure you want to delete this comment ?
            </DialogTitle>

            <DialogActions style={{ justifyContent: "space-evenly" }}>
              <Button onClick={handleClose} style={{ color: "#b6aa90" }}>
                No
              </Button>
              <Button
                onClick={handleConfirmDelete}
                style={{ color: "#b6aa90" }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default Comment;
