//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * "New" page *                                                  *
 * ?                      "New" it a page named "new-on-TopCream" in the app                                    *
 * ?         it a page that displays the new videos available on topCream sorted by publication date.           *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Row from "../components/row/Row";

import "../theme/New/New.scss";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, paddingLeft: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const New = () => {
  const [value, setValue] = useState(0);
  const { translation } = useSelector(state => state.languageReducer);

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <div className="new">
      <h1 className="new--title">{translation.New_Releases}</h1>
      <div className="row__title-second-half row__title-second-half-extended">
        <Button 
          className={`${value == 0 ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`} 
          onClick={() => handleChange(0)}
        >{translation.New_Video}</Button>
        <Button 
          className={`${value == 1 ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`} 
          onClick={() => handleChange(1)}
        >{translation.New_Podcast}</Button>
      </div>
      <TabPanel value={value} index={0}>
        <div className={`new__commun ${value === 0 ? "newIsActive" : null}`}>
          <Row contentType={"NewOnTopCream"} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        UPCOMING ...
      </TabPanel>
    </div>
  );
};

export default New;
