import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import "./Registration.scss";
import { useParams } from "react-router";
import requests from "../axios/Requests";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { confirmRegistration } from "../../servicesRedux/AuthentificationService";

export default function RegistrationConfirm() {
    const { id } = useParams();
    const { translation } = useSelector(state => state.languageReducer);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [alreadyConfirmed, setAlreadyConfirmed] = useState(false);

    useEffect(() => {
        confirmRegistration(requests.RegistrationConfirmation, id).then(
            (response) => {
                setIsConfirmed(true);
            }
        )
            .catch((error) => {
                console.log(error.response)
                if (error.response.status === 400) {
                    if (error.response.data?.errors?.confirmationToken) {
                        setIsConfirmed(false);
                    }
                    if(error.response.data.message === "registration confirmation is already done!")
                    {
                        setIsConfirmed(true);
                        setAlreadyConfirmed(true);
                    }
                }
            });

    }, [])

    return (
        <Box
            sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            style={{
                marginTop: 0,
            }}
        >
            <Grid container spacing={2} className="reg__background--container">
                <Grid item xs={12} md={6} lg={6} className="reg__left--background">
                    <div className="reg__left--background--internal">
                        <img className="login--logo" src={logo} alt="logo" />
                        <div className="confirmed_registration">
                            {isConfirmed ?
                                (
                                    <>
                                        <span className="email_confirm">{alreadyConfirmed ? translation.confirmationAlreadyDoneMessage : translation.Email_confirmed}</span>
                                        <div className="thank-you_links">
                                            <Link
                                                to="/login"
                                            >
                                                {translation.Click_here_sign_in}
                                            </Link>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <span className="email_confirm">{translation.Email_not_confirmed_error}</span>
                                )
                            }
                        </div>
                    </div>

                </Grid>
            </Grid>
        </Box>
    )
}