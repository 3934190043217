import React from "react"
import ContentLoader from "react-content-loader"

import useBreakpoints from "../../hooks/UseBreakpoint";

import "./Video.scss"

const DEFAULT_RATIO = [16, 9];

const VideoSkeleton = props => {
    let numberLines = 10;
    const point = useBreakpoints();

    const getRatioStyles = ratio => {
        if (!ratio) {
            return {};
        }
        return { paddingBottom: (ratio[1] / ratio[0]) * 100 + "%" };
    }

    const renderContentLoaderWidth = mode => {
        switch(mode) {
            case "xs":
                return "500";
            case "sm":
                return "600";
            case "md":
                return "750";
            case "ml":
                return "1280";
            case "lg":
                return "1600";
          }
    };

    return (
        <div
            className={"video__skeleton--conrtainer"}
            style={getRatioStyles(DEFAULT_RATIO)}
        >
            <ContentLoader 
                speed={2}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect width="100%" height="100%" />
                {new Array(numberLines).fill(" ").map((_, i) => {
                    return (
                        <rect x="105" y={i * 20 + 20} rx="5" ry="5" width={renderContentLoaderWidth(point)} height="12" />
                    )}
                )}                
            </ContentLoader>
        </div>
    )
}

export default VideoSkeleton