import { useState } from "react";
import { useParams } from "react-router";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Avatar from "@mui/material/Avatar";

import requests from "../../axios/Requests";
import {
  postComment,
  updateCommentByID,
} from "../../../servicesRedux/CommentService";
import {
  AddCommentsAction,
  AddReplyAction,
  AddChildCountCommentsByIDAction,
  newReplyStateAction,
  UpdateCommentsByIDAction,
} from "../../../redux/action/CommentsAction";
import { useTracking } from "react-tracking";
import Constants from "../../constants/Constants";

import commentIcon from "../../../assets/images/reply_original.svg";

import "./Comments.scss";

const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  handleCancel,
  initialText = "",
  isEditing,
  currentUser,
  isReply,
  parentID,
  showComponent,
  hideComponent,
  translation,
  intervenantImage
}) => {
  const { id } = useParams();
  const userId = Cookies.get("userID");
  const { comments } = useSelector((state) => state.commentsReducer);
  const dispatch = useDispatch();
  const [text, setText] = useState(initialText);
  const tracking = useTracking();
  const onSubmit = (event) => event.preventDefault();

  const responseAPIComment = (item) => {
    const newComment = {
      avatar: currentUser?.avatar,
      body: text,
      firstName: currentUser?.firstName,
      id: item.id,
      lastName: currentUser?.lastName,
      userID: item?.accountUserId,
      created: item?.created,
    };
    dispatch(AddCommentsAction(newComment));
  };

  const responseAPIReplyComment = (item) => {
    const newReply = {
      avatar: currentUser?.avatar,
      body: text,
      firstName: currentUser?.firstName,
      id: item.id,
      lastName: currentUser?.lastName,
      repliedTo: parentID,
    };

    dispatch(AddReplyAction(parentID, newReply));
    dispatch(newReplyStateAction(true));
    dispatch(AddChildCountCommentsByIDAction(parentID));
  };

  const handleKeyDown = async (event) => {
    if (
      (event.key === "Enter" || event.type === "click") &&
      text !== "" &&
      text !== undefined
    ) {
      const comment = {
        catalogItemId: id,
        accountUserId: userId,
        comment: text,
      };
      if (!isEditing && !isReply) {
        postComment(requests.PostComment, comment).then((item) => responseAPIComment(item));
        setText("");
        tracking.trackEvent(
          {
            UserEventId: Number(event.key === "Enter" ? Constants.UserEvent.KEY_DOWN.enum : Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.ADD_COMMENT_TO_MEDIA.enum),
            DataId: id,
            DataTypeId: Number(Constants.UserLogDataType.CATALOGITEM_ID.enum),
            MediaTypeId: Number(Constants.MediaType.Video),
            Note1: text,
          }
        );
      } else if (!isEditing && isReply) {
        showComponent(false);
        const reply = { 
          catalogItemId: id,
          accountUserId: userId,
          comment: text,
          repliedTo: parentID,
        };
        postComment(requests.PostComment, reply).then((item) => responseAPIReplyComment(item));
        setText("");
        tracking.trackEvent(
          {
            UserEventId: Number(event.key === "Enter" ? Constants.UserEvent.KEY_DOWN.enum : Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.ADD_COMMENT_TO_MEDIA.enum),
            DataId: id,
            DataTypeId: Number(Constants.UserLogDataType.CATALOGITEM_ID.enum),
            MediaTypeId: Number(Constants.MediaType.Video),
            ToId: parentID,
          }
        );
      } else {
        const temp = comments.data.find((c) => c.id === parentID);
        temp.body = text;
        dispatch(UpdateCommentsByIDAction(temp));
        handleCancel(event);
        updateCommentByID(requests.PutComment, parentID, text);
        setText("");
        tracking.trackEvent(
          {
            UserEventId: Number(event.key === "Enter" ? Constants.UserEvent.KEY_DOWN.enum : Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.UPDATE_COMMENT_TO_MEDIA.enum),
            DataId: id,
            DataTypeId: Number(Constants.UserLogDataType.CATALOGITEM_ID.enum),
            MediaTypeId: Number(Constants.MediaType.Video),
            Note1: "Update Comment for commentId: " + parentID,
          }
        );
      }
    }
  };

  return (
    <form onSubmit={onSubmit} className="commentForm--form">
      <div className="commentForm__addComment">        
        <Avatar
          src={intervenantImage.avatar}
          alt="userName"
          aria-haspopup="true"
        />
        <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
          <InputLabel htmlFor="standard-adornment-password">{translation.Leave_Comment}</InputLabel>
          <Input
            type={'text'}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}            
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={(e) => handleKeyDown(e)}>
                  <img src={commentIcon} style={{
                    marginBottom: 10
                  }} />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {hasCancelButton && (
          <button
            className="commentForm__addComment--button--cancel"
            type="button"
            onClick={(e) => {
              !isEditing ? showComponent(false) : handleCancel(e);
            }}
          >
            <img
              className="commentForm__addComment--button--cancel--img"
              src="https://th.bing.com/th/id/OIP.Zgp0JSH8GinijjVpbzOokQHaHa?pid=ImgDet&rs=1"
              alt="#"
            />
          </button>
        )}
      </div>
    </form>
  );
};

export default CommentForm;
