import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import Box from '@mui/material/Box';
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import requests from "../axios/Requests";
import { getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions } from "../../servicesRedux/AdminService";

import { fixDateString } from "../../hooks/utils";

import { styles } from "./DashboardComponentStyles";

const VideosCompletedComponent = props => {
    const { 
        classes, 
        startDate, 
        endDate, 
        selectedCompany, 
        selectedDateRange,
        changeOfMonthlySelectionEndPoint,
        setIsLoading
    } = props;   
    const [dataNumberOfVideosCompleted, setDataNumberOfVideosCompleted] = useState([]);
    const [dataNumberOfVideosCompletedUnique, setDataNumberOfVideosCompletedUnique] = useState(null);
    const [dataNumberOfVideosCompletedUniqueAsPercentage, setDataNumberOfVideosCompletedUniqueAsPercentage] = useState(null);
    const [dataNumberOfVideosLaunchedUnique, setDataNumberOfVideosLaunchedUnique] = useState(null);
    const optionsOnNumberOfVideosCompleted = {
        title: `${(changeOfMonthlySelectionEndPoint ? "Monthly" : "Daily")} videos completed ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,
        hAxis: { title: (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), titleTextStyle: { color: "#333" } },
        vAxis: { title: 'Videos videos completed', viewWindow: { min: 0 }, ticks: [0, 5, 10, 15] },
        chartArea: { width: "50%", height: "70%" },
        colors: ['#FB7A21', '#4285f4'],
    };

    useEffect(() => {
        let tempVideos = [];  
        if (changeOfMonthlySelectionEndPoint) {
            setIsLoading(true);
            getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions(requests.GetNoOfDailyVideosCompleted, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Month", 
                    `Nb of videos completed ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,                    
                    `Nb of videos launched ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,                     
                    { role: "style" }
                ]);
                items.dailyVideoCompletedPercentage.forEach(item => tempVideos.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfVideosCompleted,
                    item.nbOfVideoLauched,
                    ""
                ]));
                setDataNumberOfVideosCompleted(tempVideos);             
                setDataNumberOfVideosCompletedUnique(items.totalCompletedNb);
                setDataNumberOfVideosCompletedUniqueAsPercentage(items.totalCompletedPercentage);   
                setDataNumberOfVideosLaunchedUnique(items.totalLaunchedNb);
                setIsLoading(false);
            });           
        } else {
            setIsLoading(true);
            getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions(requests.GetNoOfDailyVideosCompleted, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Day", 
                    `Nb of videos completed ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,                      
                    `Nb of videos launched ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,                     
                    { role: "style" }
                ]);
                items.dailyVideoCompletedPercentage.forEach(item => tempVideos.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfVideosCompleted,
                    item.nbOfVideoLauched,
                    ""
                ]));
                setDataNumberOfVideosCompleted(tempVideos);             
                setDataNumberOfVideosCompletedUnique(items.totalCompletedNb);     
                setDataNumberOfVideosCompletedUniqueAsPercentage(items.totalCompletedPercentage); 
                setDataNumberOfVideosLaunchedUnique(items.totalLaunchedNb);              
                setIsLoading(false);
            });                       
        }
    }, [startDate, endDate, selectedCompany, changeOfMonthlySelectionEndPoint]);

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1', display: 'flex', flexDirection: 'column' }} className="home__page-charts-row other__page-charts-row-extended">                           
                <Paper 
                    className={classes.paper} 
                    sx={{ width: 200, overflow: 'hidden' }}
                >                    
                    <Card className={classes.card}>
                        <CardContent style={{ minHeight: 170, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                            <Typography className={classes.title} color="textSecondary">{`Number of videos completed ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`}</Typography>                            
                            <Typography className={classes.subTitle} component="p">{dataNumberOfVideosCompletedUnique && dataNumberOfVideosCompletedUnique} / {dataNumberOfVideosLaunchedUnique && dataNumberOfVideosLaunchedUnique}</Typography>
                            <Typography className={classes.subTitle} component="p">{'(' + ((dataNumberOfVideosCompletedUniqueAsPercentage !== undefined && dataNumberOfVideosCompletedUniqueAsPercentage !== null) ? dataNumberOfVideosCompletedUniqueAsPercentage.toFixed(2) : "0") + '%' + ')'}</Typography>                                
                        </CardContent>
                    </Card>
                </Paper>
                <Paper className={classes.tablePaper}>
                    {dataNumberOfVideosCompleted.length > 0 &&
                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="600px"
                        data={dataNumberOfVideosCompleted}
                        options={optionsOnNumberOfVideosCompleted}
                    />}                                                
                </Paper>
            </Box>           
        </>
    );
}

export default withStyles(styles)(VideosCompletedComponent);