import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IoMdAddCircle } from "react-icons/io";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import ImCross from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import LinearProgress from '@mui/material/LinearProgress';

import IsoTextfield from "./IsoTextfield";
import PlaylistCheckbox from "./PlaylistCheckbox";

import requests from "../axios/Requests";
import { createPlaylist } from "../../servicesRedux/PlaylistService";
  
import style from "./DescriptionsPlaylistModal-Style";

const CommonPlaylistModalContent = props => {
    const { 
        likes,
        loading,
        contentId, 
        handleClose, 
        playlists, 
        setPlaylists, 
        mobileView,
        playlistStandard, 
        setPlaylistStandard, 
        openNewPlaylistPopupProp,
        triggerTheRealTimeUpdateOnLikes 
    } = props;
    const [openNewPlaylistPopup, setOpenNewPlaylistPopup] = useState(false);
    const { translation } = useSelector(state => state.languageReducer);
    const [isLoading, setIsLoading] = useState(false);
    console.log(likes)

    const handleNewPlaylistPopup = () => setOpenNewPlaylistPopup(true);

    const favPlayList = (type, name) => {
        if (type === 2 || type === 3) {
            return translation.PlayListName.find(p => p.type === type).label;
        } else {
            return name;
        }
    };

    const addNewPlaylist = (playlistName, errorCallback, setHasLoaded) => {
        if (playlistName.trim().length === 0) {
            errorCallback(translation.Playlist_name_not_empty);
            setHasLoaded(false);
            return;
        }
    
        createPlaylist(requests.CreatePlaylist, playlistName).then(item => {
            setHasLoaded(false);
            const newPlaylist = {
                ...item.data,
                checked: true,
                toAdd: true,
            };
            setOpenNewPlaylistPopup(false);
            setPlaylists([...playlists, newPlaylist]);
        })
        .catch(error => {
            if (error.response.status === 409) {
                setHasLoaded(false);
                errorCallback(translation.Already_in_playlist);
            }
        });
    };   

    return (
        <Box sx={mobileView ? {...style.boxStyleMobile} : {...style.boxStyle}} className={`${mobileView ? 'description__playlists--container--mobile-view' : ''}`}>
            <div className="description__playlists--container">
                <h2 id="parent-modal-title">
                    {translation.Playlist_save}              
                </h2>
                {!mobileView &&
                <ImCross
                    size={20}
                    onClick={handleClose}
                    className="description__playlists--exit"
                />}                
            </div>
            <br />
            <FormGroup>
                <Stack
                    sx={{ overflow: "auto", maxHeight: "40vh" }}
                    container
                    alignItems="stretch"
                    spacing={0.5}
                >
                    {playlistStandard.length > 0 && (
                    <>
                        {playlistStandard.map(playlist => (
                            <PlaylistCheckbox
                                likes={likes}
                                checked={playlist.checked}
                                toAdd={playlist.toAdd}
                                playlistName={favPlayList(playlist.type, playlist.name)}
                                playlistId={playlist.id}
                                standard={true}
                                type={playlist.type}
                                contentId={contentId}
                                playlists={playlists}
                                triggerTheRealTimeUpdateOnLikes={triggerTheRealTimeUpdateOnLikes}
                                setPlaylists={setPlaylists}
                                playlistStandard={playlistStandard}
                                setPlaylistStandard={setPlaylistStandard}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                        ))}
                        <Divider />
                    </>
                    )}

                    {playlists.map(playlist => (
                        <PlaylistCheckbox
                            checked={playlist.checked}
                            toAdd={playlist.toAdd}
                            playlistName={playlist.name}
                            playlistId={playlist.id}
                            type={playlist.type}
                            contentId={contentId}
                            playlists={playlists}
                            triggerTheRealTimeUpdateOnLikes={triggerTheRealTimeUpdateOnLikes}
                            setPlaylists={setPlaylists}
                            playlistStandard={playlistStandard}
                            setPlaylistStandard={setPlaylistStandard}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    ))}
                </Stack>
            </FormGroup>
            {(loading || isLoading) && <LinearProgress color="inherit" className="progress_bar" />}   
            <div className="description__playlists_page_new_playlist--container">
                {!openNewPlaylistPopup && !openNewPlaylistPopupProp ? (
                    <div
                        className="description__playlists_page_new_playlist"
                        onClick={handleNewPlaylistPopup}
                    >
                        <IoMdAddCircle
                            className="description__playlist_edit_icon"
                            size={30}
                        />
                        <p className="description__playlists_page_new_playlist__btn_p">{translation.Playlist_create}</p>
                    </div>
                ) : (
                    <IsoTextfield 
                        addNewPlaylist={addNewPlaylist} 
                        loadingCheck={props.loadingCheck} 
                        translation={translation} 
                        style={style}
                    />
                )}
            </div>
        </Box>
    )    
}

export default CommonPlaylistModalContent;