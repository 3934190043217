import * as types from "../constant";

export function GetBannerAction(payload) {
    return async (dispatch) => {
        dispatch({ type: types.GET_BANNER, payload });
    };
}

export function GetRowAction(payload) {
    return async (dispatch) => {
        dispatch({ type: types.GET_ROW, payload });
    };
}