import React from "react"
import ContentLoader from "react-content-loader"

const LinkedArticleSkeleton = (props) => (
    <ContentLoader 
        speed={2}
        width={'100%'}
        height={'600px'}
        viewBox="0 0 100% 600"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
    <rect width="100%" height="600" />
    </ContentLoader>
)

export default LinkedArticleSkeleton