// React Component: DirectionsPopUp.js

import React from 'react';
import './DirectionsPopUp.scss';
import { useSelector } from "react-redux";

const DirectionsPopUp = ({setContinue}) => {
  const {translation} = useSelector((state) => state.languageReducer);

  return (
    <div className="directions-popup-container">
      <div className="directions">
        <p>{translation.AI_explain_popup_1}</p>
        <p>{translation.AI_explain_popup_2}</p>
        <p>{translation.AI_explain_popup_3}</p>
      </div>
      <button className="continue-button" onClick={setContinue}>{translation.continue_magic}</button>
    </div>
  );
}

export default DirectionsPopUp;
