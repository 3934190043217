import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { VscFilePdf } from "react-icons/vsc";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";

import requests from "../components/axios/Requests";
import {
  getAllCategories,
  getAllSpeakers,
  getAllChannels,
  updateContent,
  createUploadJob,
  updateUploadJob,
  getAllTags,
  updateDailyMotionThumbnail,
  getTenant
} from "./../servicesRedux/GlobalService";
import {
  GetAllCategoriesAction,
  GetAllSpeakersAction,
  GetAllChannelsAction,
  GetNewContentAction,
  getAllTagsAction,
  setOpenPersonalizedDialog,
} from "../redux/action/GlobalActions";

import { getContentById } from "../servicesRedux/ContentManagementService";

import CurationContent from "./../components/ModifyApp/CurationContent";
import CurationCreation from "./../components/ModifyApp/CurationCreation";
import CurationGraphical from "../components/ModifyApp/CurationGraphical";
import azure from "../components/UploadApp/build/azure-storage.blob";
import PersonalizedDialog from "../components/async/PersonalizedDialog";
import Constants from "../components/constants/Constants";
import Video from "../components/video/Video";
import EditorialContent from "../components/CurationComponents/EditorialContent";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import "../theme/ModifyApp/ModifyApp.scss";

const uploadType = {
  video: "video",
  thumbnail: "thumbnail",
};


let curationUploadType = [
  {
    id: 0,
    value: "video",
    label: "video",
    disabled: false,
    MediaType: 1,
    uploadString: "videos",
  },
  {
    id: 1,
    value: "pdf",
    label: "pdf",
    disabled: false,
    MediaType: 3,
    uploadString: "documents",
  },
  {
    id: 2,
    value: "podcast",
    label: "podcast",
    disabled: true,
    MediaType: 2,
    uploadString: "podcasts",
  },
];

export default function UploadApp() {
  const dispatch = useDispatch();
  const [content, setContent] = useState(null);
  const {
    allSpeakers,
    newContent,
    allTags,
    openPersonalizedDialog,
    allChannels,
  } = useSelector((state) => state.globalReducer);
  const navigate = useNavigate();
  const [processState, setProcessState] = useState(0);
  const [contentResponse, setContentResponse] = useState({});
  const [missedAttributs, setMissedAttributs] = useState([]);
  const [errors, setErrors] = useState({});
  const [contentProcessStatusId, setContentProcessStatusId] = useState(0);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [newFileName, setNewFileName] = useState("");
  const { id } = useParams();
  const [configuration, setConfiguration] = React.useState({});

  useEffect(() => {
    getTenant(requests.GetTenant).then(item => setConfiguration(item));
  }, []);
  let AZUREuploadStringURL = configuration?.azureContainerURL;

  dayjs.extend(utc);
  function toSeconds(time) {
    var parts = time.split(':');
    return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
  }

  const getDefaultChannel = (id) => {
    let obj = allChannels.find((all) => all.id === id);
  };

  useEffect(() => {
    getAllCategories(requests.getAllCategories).then((item) =>
      dispatch(GetAllCategoriesAction(allCategoriesList(item)))
    );
    getAllSpeakers(requests.getAllSpeakersCuration).then((item) =>
      dispatch(GetAllSpeakersAction(allSpeakersList(item)))
    );
    getAllChannels(requests.GetAllChannels).then((item) =>
      dispatch(GetAllChannelsAction(allChannelsList(item)))
    );
    getAllTags(requests.GetAllTagsCuration).then((item) => {
      dispatch(getAllTagsAction(allTagsList(item)));
    });
    getContentById(requests.GetContentById, id).then((data) => {
      var tempCat = data.category.find((c) => c.parentId === undefined);
      if (tempCat) {
        tempCat.value = tempCat.id;
        tempCat.label = tempCat.label_fr || tempCat.label_en;
      }

      var tempSubCat = data.category.find(
        (c) =>
          c.parentId === data.category.find((c) => c.parentId === undefined)?.id
      );
      var tempSubSubCat = null;
      if (tempSubCat) {
        tempSubCat.value = tempSubCat.id;
        tempSubCat.label = tempSubCat.label_fr || tempSubCat.label_en;

        tempSubSubCat = data.category.find(
          (c) =>
            c.parentId ===
            data.category.find(
              (c) =>
                c.parentId ===
                data.category.find((c) => c.parentId === undefined)?.id
            )?.id
        );
      }

      if (tempSubSubCat) {
        tempSubSubCat.value = tempSubSubCat.id;
        tempSubSubCat.label = tempSubSubCat.label_fr || tempSubSubCat?.label_en;
      }
      setContentProcessStatusId(data.contentProcessStatusId);
      let useful = {
        FilePath: data.contentMedia.filename ?? " ",
        language: data.language,
        keyWord: data.tags,
        creatorName: data.curation[0]?.name,
        speaker: data.contributors.filter((c) => c.type === "SPK"),
        channels: data?.channels,
        author: data.contributors.filter((c) => c.type === "AUT"),
        location: data.location,
        authorizedContent: data.authorizedContent || false,
        catalogItemId: data.catalogItemId,
        title: data.name,
        fullSummary: data.fullSummary,
        status: data.status,
        videoName: data.contentMedia.filename,
        category: tempCat,
        subCategory: tempSubCat,
        subsubCategory: tempSubSubCat,
        publicationDate: data.createdOn,
        createdOn: data.createdOn,
        startCuration: data?.curation[0]?.start,
        endCuration: data?.curation[0]?.end,
        contentId: data?.id,
        channelId: data?.channelId,
        defaultChannel: getDefaultChannel(data?.channelId),
        thumbnailGenerationTime: data.thumbnailGenerationTime,
        mediaTypeId: data?.mediaTypeId,
        contentMedia: data.contentMedia,
        comment: data?.comment,
        contentRating: data?.rating,
        publicationDateStart:
          data.publicationDateStart !== "0001-01-01T00:00:00Z"
            ? data.publicationDateStart
            : "",
        publicationDateEnd:
          data.publicationDateEnd !== "0001-01-01T00:00:00Z"
            ? data.publicationDateEnd
            : "",
        contentAdminStatusId: data.contentAdminStatusId,
        contentProcessStatusId: data.contentProcessStatusId,
        isAct: data.isAct,
        isExpert: data.isExpert,
        isDiscover: data.isDiscover,
        bookmark: data.contentBookmarks,
        highlight: data.contentHighlights,
        heroBanner: data.heroBanner === "" ? null : data.heroBanner,
        isHighlighted: data.isHighlighted,
        isTopCreamContent: data.isTopCreamContent && data.clientName !== "topcream",
        videoDuration: toSeconds(data.duration),
        generateOpenAI: data.contentOpenAIProcessStatusId === 0 || data.contentOpenAIProcessStatusId === 1 || data.contentOpenAIProcessStatusId === 2,
        contentOpenAIProcessStatusId: data.contentOpenAIProcessStatusId,
        externalContentLink: data.externalContentLink ?? null,
        isExternalContent: data.isExternalContent ?? false,
        isPublic: data.isPublic ?? false,
        isDailyMotion: data.isDailyMotion,


      };
      if (useful.isTopCreamContent) {
        alert("This is a TopCream content. You can't edit it.");
      }
      dispatch(GetNewContentAction(useful));

      setContent(useful);
    });
  }, []);

  const allCategoriesList = (list) => {
    var newListObject = [];
    if (list) {
      list.map((item, index) => {
        var temp = item;
        temp.value = item.parentId;
        temp.label = item.category;
        temp.subCategory = getAllSubCategoriesList(item.subCategory);
        newListObject.push(temp);
      });
    }

    return newListObject;
  };

  const getAllSubCategoriesList = (list) => {
    var newListObject = [];
    if (list) {
      list.map((item, index) => {
        var temp = item;
        temp.value = item.subCategoryId;
        temp.subSubCategory = getAllSubSubCategoriesList(item.subSubCategory);

        newListObject.push(temp);
      });
    }

    return newListObject;
  };

  const getAllSubSubCategoriesList = (list) => {
    var newListObject = [];
    if (list) {
      list.map((item, index) => {
        var temp = {};
        temp = item;
        temp.value = index;

        newListObject.push(temp);
      });
    }

    return newListObject;
  };

  const allSpeakersList = (list) => {
    var newListObject = [];
    if (list) {
      list.map((item, index) => {
        var temp = item;
        temp.value = item.id;
        temp.label = item?.persons?.firstName + " " + item?.persons?.lastName;
        newListObject.push(temp);
      });
    }

    return newListObject;
  };

  const allChannelsList = (list) => {
    var newListObject = [];
    if (list) {
      list.map((item, index) => {
        var temp = item;
        temp.value = item.id;
        temp.label = item.name;
        newListObject.push(temp);
      });
    }

    return newListObject;
  };

  const getAllChannelsID = (list) => {
    if (list.length < 1) return undefined;
    var idList = [];
    list?.map((item, index) => {
      idList.push(item.id);
    });
    return idList;
  };

  const allTagsList = (list) => {
    var newListObject = [];
    if (list) {
      list.map((item, index) => {
        newListObject.push({
          value: item.id,
          label: item.label,
          label_fr: item.label_fr,
          contentCount: item.contentCount,
        });
      });
    }
    return newListObject;
  };

  useEffect(() => {
    dispatch(
      GetNewContentAction({
        ...newContent,
        speaker:
          newContent.speaker ||
          allSpeakers.find((c) => c.id === content?.speaker.id),
      })
    );
  }, [allSpeakers, content]);

  const getAllContributorsID = (list) => {
    if (list?.length < 1 || !list) return undefined;
    var idList = [];
    list.map((item, index) => {
      idList.push(item.id);
    });
    return idList;
  };

  const personalizedLabels = (list) => {
    var strList = [];
    list.map((item, index) => {
      switch (item) {
        case "title":
          strList.push("- Fill a Title");
          break;
        case "category":
          strList.push("- Choose a Category");
          break;
        case "subCategory":
          strList.push("- Choose a Sub-Category");
          break;
        case "subSubCategory":
          strList.push("- Choose a SubSubCategory");
          break;
        case "FilePath":
          strList.push("- Upload a Video");
          break;
        case "Speakers":
          strList.push("- Add Speakers");
          break;
        case "thunbnailURL":
          strList.push("- Upload a Thumbnail");
          break;
        case "location":
          strList.push("- Choose a Location");
          break;
        case "language":
          strList.push("- Choose a Language");
          break;
        case "keywords":
          strList.push("- Please add some Tags");
          break;
        case "status":
          strList.push("- Choose a Status");
          break;
        case "startCuration":
          strList.push("- Add Start-Curation Date");
          break;
        case "endCuration":
          strList.push("- Add End-Curation Date");
          break;
        case "publicationDate":
          strList.push("- Add Publication-Curation Date");
          break;
        case "summary":
          strList.push("- Fill a Summary");
          break;
        case "publicationDateStart":
          strList.push("- Fill Start Publication Date");
          break;
        case "publicationDateEnd":
          strList.push("- Fill End Publication Date");
          break;
        case "contentRating":
          strList.push("- Add Rating");
          break;
        case "externalContentLink":
          strList.push("- External content link must be filled");
          break;
        case "externalContentLinkHttps":
          strList.push("- External content link must start with https://");
        default:
          break;


      }
    });
    return strList;
  };

  const verifyForm = (data) => {
    let valid = false;
    let counter = 0;
    let nonValidKeys = [];
    for (const [key, value] of Object.entries(data)) {
      if (value !== undefined && value !== null && value !== "") {
        counter = counter + 1;
      } else {
        if (
          key !== "contentId" &&
          key !== "creator" &&
          key !== "FilePath" &&
          key !== "thunbnailURL" &&
          key !== "thumnailGenerationTime" &&
          key !== "Channel" &&
          key !== "channel" &&
          key !== "channelId" &&
          key !== "creator" &&
          key !== "comment" &&
          key !== "isAct" &&
          key !== "isExpert" &&
          key !== "isDiscover" &&
          key !== "heroBanner" &&
          key !== "isExternalContent" &&
          key !== "externalContentLink" &&
          key !== "isPublic"
        ) {
          nonValidKeys.push(key);
        }
      }
    }

    if (data.isExternalContent === true
      && (data.externalContentLink === null || data.externalContentLink === "" || data.externalContentLink === undefined)
    ) {
      valid = false;
      nonValidKeys.push("externalContentLink");
    }
    if (data.isExternalContent) {
      if (!data?.externalContentLink?.startsWith('https://')) {
        valid = false;
        nonValidKeys.push("externalContentLinkHttps");
      }
    }
    setMissedAttributs(personalizedLabels(nonValidKeys));

    if (nonValidKeys.length === 0) {
      valid = true
    }
    else {
      valid = false
    }
    if (valid === false) {
      setDisableSubmit(false);
      dispatch(setOpenPersonalizedDialog(true));
    }
    return valid;
  };

  let thumbnailURL = content?.contentMedia?.contentMediaURL[0]?.thumbnailURL;
  let baseURL = thumbnailURL ? new URL(thumbnailURL).origin : '';

  let uploadStringURL = baseURL + "/";

  function extractAssetIdFromLinkBanner(link) {
    // Try to match the asset ID pattern first
    const regex = /asset-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
    const match = link.match(regex);
    if (match) {
      return match[0] + "/";
    }

    // If no asset ID, extract the first segment after "/content/"
    // Adjusted regex to capture only up to the first "/" after "content/"
    const contentRegex = /\/content\/([^\/]*)/;
    const contentMatch = link.match(contentRegex);
    if (contentMatch) {
      // The captured group [1] contains the segment immediately after "/content/"
      const segmentAfterContent = contentMatch[1];
      // Return this segment with "/content/[segment]/banners/"
      // No need to remove trailing slash here as we're capturing up to the first slash
      return `content/${segmentAfterContent}/banners/`;
    }

    return null;
  }

  const getFilePathThumbnailBanner = (type, newName) => {
    switch (type) {
      case "banner":
        return (newContent?.banner !== undefined && newContent?.banner !== null && newContent?.banner !== "")
          ? (
            AZUREuploadStringURL +
           "videos/banners/" +
            newName
          ).toLowerCase()
          : null;
      default:
        return;
    }
  };

  function getFilenameFromUrl(url) {
    // Use the "lastIndexOf" method to find the position of the last slash in the URL
    var lastSlashIndex = url.lastIndexOf("/");

    // Check if a slash was found
    if (lastSlashIndex !== -1) {
      // Use the "substring" method to return the part of the URL after the last slash
      return url.substring(lastSlashIndex + 1);
    } else {
      // If no slash was found, return the original URL
      return url;
    }
  }

  function getUrlFromUrlWithoutFilename(url) {
    // Use the "lastIndexOf" method to find the position of the last slash in the URL
    let newUrl = new URL(url);

    var lastSlashIndex = newUrl.pathname.lastIndexOf("/");

    // Check if a slash was found
    if (lastSlashIndex !== -1) {
      // Use the "substring" method to return the part of the URL before the last slash
      return newUrl.pathname.substring(1, lastSlashIndex + 1);
    } else {
      return url;
    }
  }

  const imgFileNameOnBlob = content?.contentMedia?.contentMediaURL[0]?.thumbnailURL
    ?
    getFilenameFromUrl(content?.contentMedia?.contentMediaURL[0]?.thumbnailURL)
    :
    "";

  const heroFileNameOnBlob = content?.heroBanner ? getFilenameFromUrl(content?.heroBanner) : "";
  const urlFileOnBlob = content?.contentMedia?.contentMediaURL[0]
    ?.thumbnailURL
    ? getUrlFromUrlWithoutFilename(
      content?.contentMedia?.contentMediaURL[0]?.thumbnailURL
    )
    : "";


  function generateUniqueName(fileName) {
    // Split the file name and extension
    let parts = fileName.split(".");
    let name = parts[0];
    let ext = parts[1];
    // Regular expression to match anything that is not a number
    let regExp = /[^0-9]/;
    if (regExp.test(name)) {
      let currentTimestamp = Date.now();
      let randomNum = Math.floor(Math.random() * 100000000);
      let newFileName = currentTimestamp + "" + randomNum + ".jpg";
      return newFileName;
    }
    return fileName;
  }
  useEffect(() => {
    if (imgFileNameOnBlob) {
      setNewFileName(generateUniqueName(imgFileNameOnBlob));
    }
  }, [imgFileNameOnBlob]);

  //newContent?.banner ? randDate.getTime().toString() + Math.floor(Math.random() * 100000).toString() + "." + newContent?.banner?.name?.split('.').pop() : "";
  const upload = () => {
    let temp = [];
    newContent?.keyWord.forEach((st) => {
      if (!st.id) {
        let tag = st;
        tag.id = st.value;
        delete tag.value;
        temp.push(tag);
      } else {
        temp.push(st);
      }
    });
    let randDate = new Date();
    const newBannerName = newContent?.banner ? randDate.getTime().toString() + Math.floor(Math.random() * 100000).toString() + "." + newContent?.banner?.name?.split('.').pop() : "";
    let keywords = temp.map((item) => item.label).join(",");

    let dataToSend = {
      FilePath: newContent?.FilePath ?? ".",
      heroBanner:
        newContent?.heroBanner?.toLowerCase()
        ??
        (newContent?.banner
          ? getFilePathThumbnailBanner("banner", newBannerName)
          : null
        ),
      thunbnailURL: newContent?.thumbnailFile ? newFileName : imgFileNameOnBlob ?? ".",
      thumnailGenerationTime: newContent.thumnailGenerationTime,
      language: newContent?.language,
      mediaType: newContent?.mediaTypeId,
      //Start Curation / End Curation / Publication Date
      title: newContent?.title,
      category:
        ((newContent?.category && newContent?.category[0]?.label_en) ||
          newContent?.category?.category) ??
        content?.category?.label_en ??
        "",

      subCategory:
        (newContent.selectedSubCategory !== undefined
          ? newContent.selectedSubCategory
          : newContent?.defaultSubCategory?.label_en) ??
        content?.subCategory?.label_en ??
        "",
      subSubCategory:
        newContent.selectedSubSubCategory === null ? "" :
          (newContent.selectedSubSubCategory !== undefined
            ? newContent?.selectedSubSubCategory
            : newContent?.defaultSubSubCategory?.label_en) ??
          content?.subsubCategory?.label_en ??
          "",
      location: newContent.location,
      keywords: keywords,
      summary: newContent.fullSummary,
      creator: newContent.creatorName,
      publicationDate: newContent.createdOn,
      Channel: newContent?.channelId,
      Speakers:
        newContent.speaker !== undefined && newContent?.speaker?.length > 0
          ? getAllContributorsID(newContent.speaker)
          : null,
      contentId: newContent.contentId,
      Status: newContent.status,
      ContentAdminStatus: newContent.contentAdminStatusId,
      startCuration: newContent.startCuration,
      endCuration: newContent.endCuration,
      publicationDateStart: newContent.publicationDateStart,
      publicationDateEnd: newContent.publicationDateEnd,
      isNewVideo:
        newContent.isNewVideo !== undefined ? newContent.isNewVideo : false,
      comment: newContent.comment ?? " ",
      contentRating:
        !newContent.contentRating || newContent.contentRating === 0
          ? null
          : newContent.contentRating,
      isAct: newContent.isAct,
      isExpert: newContent.isExpert,
      isDiscover: newContent.isDiscover,
      bookmark: newContent.bookmark ?? [],
      highlight: newContent.highlight ?? [],
      isHighlighted: newContent.isHighlighted ?? false,
      ContentOpenAIProcessStatusId: newContent.contentOpenAIProcessStatusId === 2 ? (newContent?.generateOpenAI ? 2 : -1) : (newContent?.generateOpenAI ? 1 : -1),
      isExternalContent: newContent.isExternalContent,
      externalContentLink: newContent.externalContentLink,
      isPublic: newContent.isPublic,
    };
    if (verifyForm(dataToSend) === true) {
      updateContent(requests.UpdateContent, dataToSend).then((item) => {


        if (newContent?.isDailyMotion) {
          if (newContent.thumbnailFile) {
          const newThumbnailName = randDate.getTime().toString() + Math.floor(Math.random() * 100000).toString() + "." + newContent?.thunbnailURL?.split('.').pop();
          let dailyMotionURL = (AZUREuploadStringURL + "videos/thumbnails/" + newThumbnailName)
          DM_uploadThumbnailBlob(item, newThumbnailName, dailyMotionURL, dataToSend?.contentId)
        }
        }
        else {
          if (newContent.thumbnailFile) {
            uploadBlob(item, uploadType.thumbnail);
          }
          
        }

        if (newContent.banner) {
          if (heroFileNameOnBlob !== '')
            uploadBlob(item, "banner");
          else
            uploadBannerBlob(item, "banner", {}, newBannerName);
        }
        setTimeout(() => {
          navigate("/dashboard/contents");
        }, 1000);
      });
    }
  };
  function extractPathAfterContent(url) {
    // Parse the URL to get the pathname
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;

    // Find the index of "content/" and adjust to get the substring after it
    // Adding 8 to move past "content/" itself (7 characters + 1 for the slash)
    const startIndex = pathname.indexOf('content/') + 8;

    // Extract the substring from startIndex to the end
    // If "content/" is not found, or it's at the end of the pathname, return an empty string
    const pathAfterContent = startIndex > 7 ? pathname.substring(startIndex) : '';

    return pathAfterContent;
  }

  const uploadBlob = (response, typeBlob) => {
    let file;
    let blobUri;
    let containerName;
    let sasToken;
    let blobService;
    let customBlockSize;
    let finishedOrError;
    let speedSummary;
    let thumbnailURL
    const typeUpl = curationUploadType.find((cut) => cut.id === 0).uploadString;

    switch (typeBlob) {

      case uploadType.thumbnail:
        containerName = response.containerName;
        thumbnailURL = response?.data?.contentMedia?.contentMediaURL[0]?.thumbnailURL;
        let thumbFolder = '';
        if (thumbnailURL) {
          const urlObj = new URL(thumbnailURL);
          thumbFolder = extractPathAfterContent(`${urlObj.protocol}//${urlObj.hostname}${urlObj.pathname.substring(0, urlObj.pathname.lastIndexOf('/') + 1)}`);
        }

        file = newContent.thumbnailFile;
        blobUri = response.accountUrl;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;
        let newFile = new File([file], newFileName)

        blobService.deleteBlob(
          containerName,
          thumbFolder + imgFileNameOnBlob,
          function (error) {
            if (error) {
              // Handle the error
              console.error(
                "An error occurred while deleting the blob:",
                error
              );
            } else {
              // The blob was deleted successfully
              console.log("The blob was deleted successfully");

              speedSummary = blobService.createBlockBlobFromBrowserFile(
                containerName,
                thumbFolder + newFileName,
                newFile,
                { blockSize: customBlockSize },
                function (error, result, response) {
                  finishedOrError = true;
                  if (error) {
                    alert("Error when uploading Thumbnail");

                    setErrors({
                      ...errors,
                      thumbnailError: error,
                    });
                  } else {
                    //alert("Thumbnail Uploaded Successfully");
                    console.log("Thumbnail Uploaded Successfully")
                  }
                }
              );
            }
          }
        );
        break;

      case "banner":

        thumbnailURL = response?.data?.contentMedia?.contentMediaURL[0]?.thumbnailURL;
        let bannerFolder = 'videos/banners/';
       
        //remove spaces from bannerFolder
        bannerFolder = bannerFolder.replace(/\s/g, '');
        file = newContent.banner;
        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;
        let newFileBanner = new File([file], heroFileNameOnBlob)
        blobService.deleteBlob(
          containerName,
          bannerFolder + heroFileNameOnBlob,
          function (error) {
            if (error) {
              // Handle the error
              console.error(
                "An error occurred while deleting the blob:",
                error
              );
            } else {
              // The blob was deleted successfully
              console.log("The blob was deleted successfully");         

              speedSummary = blobService.createBlockBlobFromBrowserFile(
                containerName,
                bannerFolder + newFileBanner.name,
                newFileBanner,
                { blockSize: customBlockSize },
                function (error, result, response) {
                  finishedOrError = true;
                  if (error) {
                    alert("Error when uploading Banner");

                    setErrors({
                      ...errors,
                      bannerError: error,
                    });
                  } else {
                    //alert("Thumbnail Uploaded Successfully");
                  }
                }
              );
            }
          }
        );
        break;

      default:
        return null;
    }
  };

  function extractPathAfterContentBanner(url) {
    // Parse the URL to get the pathname
    const urlObj = new URL(url);
    let pathname = urlObj.pathname;

    // Find the index of "content/" and adjust to get the substring after it
    // Adding 8 to move past "content/" itself (7 characters + 1 for the slash)
    const startIndex = pathname.indexOf('content/') + 8;

    // Extract the substring from startIndex to the end
    // If "content/" is not found, or it's at the end of the pathname, return an empty string
    let pathAfterContent = startIndex > 7 ? pathname.substring(startIndex) : '';

    // Check if the path contains "/thumbnails/", and if so, remove just this segment
    const thumbnailsIndex = pathAfterContent.indexOf('/thumbnails/');
    if (thumbnailsIndex !== -1) {
      // Keep everything before "/thumbnails/" and concatenate with everything after
      pathAfterContent = pathAfterContent.substring(0, thumbnailsIndex) +
        pathAfterContent.substring(thumbnailsIndex + '/thumbnails/'.length);
    }

    // Ensure we remove any leading or trailing slashes after manipulation
    return pathAfterContent.replace(/^\/|\/$/g, '');
  }

  const uploadBannerBlob = (response, typeBlob, data, newThumbnailName) => {

    let bannerFolder = 'videos/banners/';
    //remove spaces from bannerFolder
    bannerFolder = bannerFolder.replace(/\s/g, '');

    let file;
    let blobUri;
    let containerName;
    let sasToken;
    let blobService;
    let customBlockSize;
    let finishedOrError;
    let speedSummary;

    const typeUpl = curationUploadType.find(
      (cut) => cut.id === newContent?.mediaTypeId - 1
    ).uploadString;


    switch (typeBlob) {
      case "banner":
        file = newContent.banner;
        blobUri = response.accountUrl;
        containerName = response.containerName;
        sasToken = response.sasToken;
        blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
        customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;
        finishedOrError = false;

        const newFileBanner = new File([file], newThumbnailName);

        speedSummary = blobService.createBlockBlobFromBrowserFile(
          containerName,
          bannerFolder + newThumbnailName,
          newFileBanner,
          { blockSize: customBlockSize },
          function (error, result, response) {
            finishedOrError = true;
            if (error) {
              alert("Error when uploading Banner");

              setErrors({
                ...errors,
                bannerError: error,
              });
            }

          }
        );
        break;
      default:
        return null;
    }
  };
  //////////////////////////////////////////// DAILY MOTION UPLOAD

  const DM_uploadThumbnailBlob = (response, newThumbnailName, dailyMotionURL, contentId) => {
    const file = newContent.thumbnailFile;
    const blobUri = response.accountUrl;
    const containerName = response.containerName;
    const sasToken = response.sasToken;
    const blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
    const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
    blobService.singleBlobPutThresholdInBytes = customBlockSize;
    let finishedOrError = false;

    const newFile = new File([file], newThumbnailName);
    const typeUpl = curationUploadType.find((cut) => cut.id === newContent?.mediaTypeId - 1).uploadString;
    const blobPath = typeUpl + "/thumbnails/" + newThumbnailName.toLowerCase();

    // Function to upload or retry upload
    function uploadOrRetry(retryCount = 0, maxRetries = 1) {
      blobService.createBlockBlobFromBrowserFile(
        containerName,
        blobPath,
        newFile,
        { blockSize: customBlockSize },
        function (error, result, response) {
          if (error && retryCount < maxRetries) {
            console.log("Upload failed, attempting retry:", retryCount + 1);
            uploadOrRetry(retryCount + 1); // Retry upload
          } else {
            // Check if upload was successful by verifying blob properties
            verifyUpload(blobPath, retryCount, maxRetries);

          }
        }
      );
    }

    // Function to verify upload success
    function verifyUpload(blobName, retryCount, maxRetries) {
      blobService.getBlobProperties(containerName, blobName, function (error, properties) {
        if (error || !properties) {
          if (retryCount < maxRetries) {
            console.log("Verification failed, attempting retry:", retryCount + 1);
            uploadOrRetry(retryCount + 1, maxRetries); // Retry upload
          } else {
            alert("Failed to upload and verify thumbnail after retries.");
            setErrors({
              ...errors,
              thumbnailError: "Verification failed after retries.",
            });
          }
        } else {
          finishedOrError = true;
          // Proceed with any further logic required after successful upload and verification
          updateDailyMotionThumbnail(requests.UpdateDailyMotionThumbnail, dailyMotionURL, contentId);

        }
      });
    }

    // Initial attempt
    uploadOrRetry();
  };

  // const DM_uploadBannerBlob = (response, newThumbnailName) => {
  //   const file = newContent.banner;
  //   const blobUri = response.accountUrl;
  //   const containerName = response.containerName;
  //   const sasToken = response.sasToken;
  //   const blobService = azure.createBlobServiceWithSas(blobUri, sasToken);
  //   const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
  //   blobService.singleBlobPutThresholdInBytes = customBlockSize;
  //   let finishedOrError = false;

  //   const newFileBanner = new File([file], newThumbnailName);
  // const typeUpl = curationUploadType.find((cut) => cut.id === 0).uploadString;

  //   const speedSummary = blobService.createBlockBlobFromBrowserFile(
  //     containerName,
  //     typeUpl + "/banners/" + newThumbnailName,
  //     newFileBanner,
  //     { blockSize: customBlockSize },
  //     function (error, result, response) {
  //       finishedOrError = true;
  //       if (error) {
  //         alert("Error when uploading Banner");
  //         setErrors({
  //           ...errors,
  //           bannerError: error,
  //         });
  //       }
  //       updateDailyMotionThumbnail(requests.UpdateDailyMotionThumbnail, dailyThumbURL);
  //     }
  //   );
  // };

  //////////////////////////////////////////// DAILY MOTION UPLOAD


  const displayProcess = (process, data) => {
    createUpload(data);
  };
  const createUpload = (data) => {
    createUploadJob(requests.CreateUploadJob, data).then((item) => { });
  };
  const validationSchema = () =>
    Yup.object().shape({
      all: Yup.string().required("Required"),
    });

  useEffect(() => {
    if (processState.toString().includes("100") && contentResponse) {
      var request = {
        JobId: contentResponse.JobId,
        ContentId: contentResponse.contentId,
        Status: processState == 100 ? "Completed" : "Inprocess",
        Completion: processState,
        VideoURL: contentResponse.VideoURL,
      };

      updateUploadJob(requests.UpdateUploadJob, request);
    }
  }, [processState && contentResponse]);

  const displayMediaWithType = (val) => {
    switch (val) {
      case Constants.MediaType.NotFound:
        return <></>;
      case Constants.MediaType.Video:
        return (
          <>
            {content.contentMedia?.contentMediaURL[0]?.url !== undefined ? (
              <div className="curationVideoPlayer">
                <Video
                  props={{
                    videoURL: content?.contentMedia?.contentMediaURL[0]?.url,
                    transcriptURL:
                      content?.contentMedia?.contentMediaURL[0]?.transcriptURL,
                    autoPlay: false,
                    displayLogo: true,
                    isDailyMotion: content?.isDailyMotion
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        );

      case Constants.MediaType.Podcast:
        return (
          <>
            {content.contentMedia?.contentMediaURL[0]?.url !== undefined ? (
              <div className="curationVideoPlayer">
                <video className="audioplayer" controls>
                  <source
                    src={content?.contentMedia?.contentMediaURL[0]?.url}
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio element.
                </video>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      case "thumbnail":
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "100%",
                height: "100%",
              },
            }}
          >
            <Typography
              sx={{ fontSize: 16, fontWeight: 800 }}
              color="text.secondary"
              gutterBottom
            >
              Current thumbnail
            </Typography>
            <Grid container style={{ p: 0 }}>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {content?.contentMedia?.contentMediaURL[0]?.thumbnailURL && (
                  <img
                    className="thumbnail_preview"
                    src={
                      content?.contentMedia?.contentMediaURL[0]?.thumbnailURL// +
                     // "?" +
                    //  Math.random().toString()
                    }
                  />
                )}
              </Container>
            </Grid>

          </Box>
        );

      case Constants.MediaType.PDF:
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "100%",
                height: "100%",
              },
            }}
            autoComplete="off"
            noValidate
          >
            <a
              href={content.contentMedia?.contentMediaURL[0]?.url}
              target="self"
              rel="noopener noreferrer"
              download={content.title}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "24px",
                  marginLeft: 20,
                  alignItems: "center",
                }}
              >
                {content.title}
                <VscFilePdf size={40} color="black" />
              </div>
            </a>
          </Box>
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    displayMediaWithType();
  }, [content?.mediaTypeId]);
  return (
    <Formik
      initialValues={{
        submit: false,
      }}
      validationSchema={validationSchema}
      id="upload-form"
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <form
          id="upload-form"
          onSubmit={(e) => {
            e.preventDefault();
            if (e?.target?.id === "upload-form") {
              setDisableSubmit(true);
              upload();
            }
          }}
        >
          <div className="uploadAppContainer">
            {content && (
              <>
                <CurationContent
                  content={content}
                  defaultCategory={newContent.defaultCategory}
                  allTags={allTags}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      m: 1,
                      width: "100%",
                      height: "100%",
                    },
                  }}
                  autoComplete="off"
                  noValidate
                >
                  <Paper
                    elevation={5}
                    sx={{ padding: 2 }}
                    className="parent_paper"
                  >
                    <Typography
                      sx={{ fontSize: 16, fontWeight: 800 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Thumbnail
                    </Typography>
                    {contentProcessStatusId === 13 || (contentProcessStatusId === 19 && newContent?.isExternalContent) ? (
                      <Grid
                        container
                        align="center"
                        justify="center"
                        spacing={2}
                        xs={12}
                        style={{ p: 0 }}
                      >
                        {newContent.isTopCreamContent ? (
                          <Grid item xs={12} sm={12}>
                            {displayMediaWithType("thumbnail")}
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs={6} sm={6}>
                              {displayMediaWithType("thumbnail")}
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <CurationGraphical />
                            </Grid>
                          </>
                        )}


                      </Grid>
                    ) : (
                      <Typography
                        sx={{ fontSize: 16, fontWeight: 800 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {
                          contentProcessStatusId === 19 && !newContent?.isExternalContent ?
                            "This content has been identified as a duplicate."
                            :
                            contentProcessStatusId === 21 ?
                              "This content has a Low quality video."
                              :
                              contentProcessStatusId !== 13 &&
                              "This content is currently being processed and its thumbnail and content preview (video..) are not ready yet. Please come back in a few minutes when the content will be ready for display in case you want to preview it or modify its thumbnail here."
                        }
                      </Typography>
                    )}
                    {/* ////// */}
                    <Grid container>
                      <Grid item xs={2} sx={{ display: "flex" }}>
                        <Typography
                          sx={{ fontSize: 18, marginLeft: 5 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          Is external content ?
                        </Typography>
                      </Grid>
                      <Grid item xs={8} sx={{ display: "flex" }} alignItems="center">
                        <Checkbox checked={newContent?.isExternalContent || false} onClick={() => {
                          dispatch(
                            GetNewContentAction({
                              ...newContent,
                              isExternalContent: !newContent?.isExternalContent,
                            })
                          );
                        }} />
                      </Grid>
                    </Grid>
                    {newContent.isExternalContent && (
                      <Grid container>
                        <Grid item xs={2} sx={{ display: "flex" }}>
                          <Typography
                            sx={{ fontSize: 18, marginLeft: 5 }}
                            color="text.secondary"
                            gutterBottom
                          >
                            External content link :
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            id="outlined-basic"
                            label={newContent?.externalContentLink ? "" : "https://"}
                            value={newContent?.externalContentLink || ""}
                            variant="outlined"
                            sx={{ width: "76%" }}
                            size="small"
                            onChange={(e) => {
                              dispatch(
                                GetNewContentAction({
                                  ...newContent,
                                  externalContentLink: e.target.value,
                                })
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {/* /////// */}
                  </Paper>
                  {contentProcessStatusId === 13 && (
                    <Paper
                      elevation={5}
                      sx={{ padding: 2 }}
                      className="parent_paper"
                    >
                      <Typography
                        sx={{ fontSize: 16, fontWeight: 800 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Media preview
                      </Typography>
                      <Grid container align="center" justify="center" xs={12}>
                        <Grid item xs={12} sm={12}>
                          {displayMediaWithType(content?.mediaTypeId)}
                        </Grid>
                      </Grid>
                    </Paper>
                  )}
                </Box>
                <EditorialContent />
                <CurationCreation />
              </>
            )}
            <button
              type="submit"
              className={
                (disableSubmit || newContent.isTopCreamContent)
                  ? "submit-modify-disabled"
                  : "submit-modify"
              }
              id="upload-form"
              disabled={disableSubmit || newContent.isTopCreamContent}
            >
              update
            </button>
          </div>
          {openPersonalizedDialog && (
            <PersonalizedDialog
              open={openPersonalizedDialog}
              title={"Required data"}
              missedAttributs={missedAttributs}
            />
          )}
        </form>
      )}
    </Formik>
  );
}
