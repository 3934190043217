import * as types from "../constant/index";


export default function reducer(
    state = {
        user: []
    },
    action
) {

    switch (action.type) {
        case types.GET_USER:
            return {
                ...state,
                user: action.payload,
            };

        default:
            return state;
    }
}