import React, { useEffect, useState } from "react";
import requests from "../axios/Requests";
import { Stack } from "@mui/material";
import { withStyles } from '@mui/styles';
import { Chip } from "@mui/material";

import { getCategories } from "../../servicesRedux/GlobalService";

export default function UserCategories(props) {
    const [categories, setCategories] = useState(props.categories ?? []);
    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
        getCategories(requests.GetCategories).then(item => {
            setCategories(item)
            setSelectedCategories(item.interestLabelEn)
        })
    }, []);

    useEffect(() => {
        props.categoryCallback(selectedCategories)
        // add isSelected to the category in categories.all that matches the id in selectedCategories
        const updatedCategories = categories?.all?.map(item => {
            const isSelected = selectedCategories?.some(selectedItem => selectedItem === item.id);
            return { ...item, isSelected };
        })
        setCategories({ ...categories, all: updatedCategories });
    }, [selectedCategories]);

    const SelectedChip = withStyles({
        root: {
            backgroundColor: '#F7EED8 !important',
            color: '#000000 !important',
            alignSelf: 'center !important',
            alignItems: 'center !important',
            textTransform: 'capitalize !important',
            marginLeft: '0 !important',
            display: 'flex !important',
            justifyContent: 'space-between !important',
            borderRadius: '16px !important',
        },
    })(Chip);

    const UnselectedChip = withStyles({
        root: {
            backgroundColor: '#FFFFFF !important',
            color: '#000000 !important',
            alignSelf: 'center !important',
            alignItems: 'center !important',
            textTransform: 'capitalize !important',
            marginLeft: '0 !important',
            display: 'flex !important',
            justifyContent: 'space-between !important',
            borderRadius: '16px !important',
        },
    })(Chip);

    const handleSelect = item => {
        const catId = item.id;
        setSelectedCategories([...selectedCategories, catId])
    };

    const handleDeselect = item => {
        const catId = item.id;
        setSelectedCategories(selectedCategories.filter(item => item !== catId))
    };

    return (
        <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1, marginTop: "16px" }}>
            {categories?.all?.map((item, index) => {
                return (
                    item.isSelected ?
                        <SelectedChip label={props.lang === "En" ? item.label_En : item.label_Fr} onClick={() => handleDeselect(item)} /> :                        
                            (item.label_En !== '' || item.label_Fr !== '') &&
                            <UnselectedChip label={props.lang === "En" ? item.label_En : item.label_Fr} onClick={() => handleSelect(item)} />
                )
            })}
        </Stack>
    );
}