//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * App layout *                                                  *
 * ?                      This component is not a specific page, it contains our whole application              *
 * ?                      We can place global component here to style our app                                   *
 * ?                      For example we got the sidebar and navbar here because we want them everywhere        *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import Cookies from "js-cookie";

import NavBar from "../components/navbar/NavBar";
import Redirector from "../core/guard/Redirector";
import Footer from "../components/footer/Footer";
import requests from "../components/axios/Requests";
import {
  getAllPlaylistsAction,
  setLoadingStatePlaylists,
} from "../redux/action/PlaylistAction";
import {
  getPlaylists,
  createPlaylistStandard,
} from "../servicesRedux/PlaylistService";
import { getCategories } from "../servicesRedux/GlobalService.js";
import { GetCategoriesAction } from "../redux/action/GlobalActions";
import { setSelectedContentsAction } from "../redux/action/ExploreAiAction";
import { getTokenByID } from "../servicesRedux/AuthentificationService";

import "../App.css";

const pageParams = {
  limit: 200,
  page: 1,
};

const AppLayout = () => {
  const dispatch = useDispatch();
  const [newProfileImage, setNewProfileImage] = useState("");
  const { loadingStatePlaylist } = useSelector(state => state.playlistReducer);
  const [categories, setCategories] = useState(null);
  const [myInfo, setMyInfo] = useState(null);
  const [publicConference, setPublicConference] = useState(undefined);


  const location = useLocation();
  const payload = Cookies.get("Payload");
  
  useEffect(() => {
    const checkAppVersion = async () => {
      try {
        await getTokenByID(requests.GetSettings);
        
        if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: 'CHECK_CACHE_VERSION',
            version: localStorage.getItem("front_app_version")
          });
        }
        
        // Update the last check timestamp and set tokenFetched to true
        sessionStorage.setItem('tokenFetchedTimestamp', Date.now().toString());
        sessionStorage.setItem('tokenFetched', 'true');
      } catch (error) {
        console.error("Error checking app version:", error);
      }
    };
  
    const tokenFetched = sessionStorage.getItem('tokenFetched');
    const tokenFetchedTimestamp = sessionStorage.getItem('tokenFetchedTimestamp');
    const currentTime = Date.now();
    const thirtyMinutesInMs = 30 * 60 * 1000;
  
    if (!tokenFetched) {
      // Initial check
      checkAppVersion();
    } else if (!tokenFetchedTimestamp || (currentTime - parseInt(tokenFetchedTimestamp)) > thirtyMinutesInMs) {
      // Periodic check every 30 minutes
      checkAppVersion();
    }
  
  }, [location.pathname]);

  useEffect(() => {
    if((payload == null || payload === "Provided username and/or password is incorrect") && location.pathname.includes("/conference/")){
      setPublicConference(true);
    }
    else{
      setPublicConference(false);
    }
    
    dispatch(setSelectedContentsAction([]))

  }, [location.pathname]);

  useEffect(() => {
    if ((loadingStatePlaylist === false && location.pathname.indexOf("conference") === -1) && (!publicConference && publicConference !== undefined) ) {
      getPlaylists(requests.GetMyPlaylists, pageParams).then((item) => {
        var res = item.data.filter((i) => i.type === 3 || i.type === 2);
        if (res.length === 0) {
          createPlaylistStandard(requests.CreatePlaylist, "Watch Later", 2).then(
            (item) => {
              createPlaylistStandard(
                requests.CreatePlaylist,
                "My Favorites",
                3
              ).then((itemFav) => { });
            }
          );
        }
        dispatch(getAllPlaylistsAction(item));
        dispatch(setLoadingStatePlaylists(true));
      });
    }
  }, [loadingStatePlaylist, publicConference]);

  useEffect(() => {
    if((!publicConference && publicConference !== undefined)){
        getCategories(requests.GetCategories).then(item => {
          dispatch(GetCategoriesAction(item));    
          setCategories(item.all);  
        });    
    }
  }, [publicConference]);

  const setProfileImage = props => setNewProfileImage(props);

  return (
    <div className="appLayout">
        <Redirector />
      <div className="navbar">
        <NavBar newProfileImage={newProfileImage} setMyInfo={setMyInfo} />
      </div>
      <div className="main">
        {(!(!publicConference && publicConference !== undefined) || categories) && <Outlet context={{ setProfileImage, myInfo }} />}
      </div>
      <div className="footer">
        <Footer/>
      </div>
    </div>
  );
};

export default AppLayout;