//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * ResearchBar component *                                       *
 * ?                       This is a research bar working with the navbar, it is inside the search page         *
 * ?                       Right now (04/27) you can only search by clicking enter                              *
 *                         TODO: Add onClick function to trigger the search button                              *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useLocation } from "react-router";
import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";

import useDebounce from "../../hooks/Debounce";

import { useDispatch, useSelector } from "react-redux";
import { GetSearchedValue } from "../../redux/action/SearchAction";

import "./ResearchBar.scss";

const ResearchBar = props => {
  const { searchedValue, checkInfo } = props;
  const [searchTerm, setSearchTerm] = useState(searchedValue ?? "");
  const { translation } = useSelector(state => state.languageReducer);
  const debouncedSearchTerm = useDebounce((searchTerm), 800);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchedValue === "") {
      setSearchTerm("");
    }
  }, [searchedValue]);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && debouncedSearchTerm.length > 3) {
      handleOnChange(debouncedSearchTerm);
    }
    if (debouncedSearchTerm !== undefined && debouncedSearchTerm.length === 0) {
      handleOnChange("");
    }
  }, [debouncedSearchTerm]);

  const handleOnChange = event => {
    checkInfo(true);
    const trimmedEvent = event.trim();

    if (trimmedEvent  !== "") {
      location.state = { provenance: "searchBar" };
    }

    if (trimmedEvent === "") {
      dispatch(GetSearchedValue(""));
    } else {
      dispatch(GetSearchedValue(trimmedEvent));
    }
  };
 
  return (
    <Box sx={{ flexGrow: 1, paddingTop: "6px" }}>
      <SearchBar
        value={searchTerm || ""}
        onChange={val => setSearchTerm(val)}
        placeholder={translation.Search_Placeholder}
        onCancelSearch={() => setSearchTerm('')}
      />       
    </Box>
  );
};

ResearchBar.propTypes = {
  research: PropTypes.node,
  searchedValue: PropTypes.any,
};

export default ResearchBar;