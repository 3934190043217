// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * ResetPassword page *                                          *
 * ?                      To reset the password for the end user sees this page first                           *
 * ?                                                                                                            *
 */
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormControl } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Alert as MuiAlert } from "@material-ui/lab";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { resetPassword } from "../core/services/api/authService/AuthService";
import AuthGuard from "../core/guard/AuthGuard";

import Loader from "../components/async/Loader";
import MultiLanguage from "../components/language/MultiLanguage";

import logo from "../assets/images/logo.png";
import { getSettings } from "../servicesRedux/SettingsService";
import requests from "../components/axios/Requests";

import "../theme/login/Login.scss";

const theme = createTheme();
const Alert = styled(MuiAlert)(spacing);

export const ResetPassword = () => {
  const [loader, setLoader] = useState(false);
  const [isResetPasswordRequested, setIsResetPasswordRequested] = useState(false);
  const { translation } = useSelector((state) => state.languageReducer);
  const [settings, setSettings] = useState(null);
  const tenant = useSelector(state => state.tenantReducer.tenant);

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(translation.Login_EmailNotValid)
        .max(255)
        .required(translation.Login_EmailError)
    });

  const handleResetPassword = (values, { setErrors, setStatus, setSubmitting }) => {
    setLoader(true);
    tryResetPassword(values, { setErrors, setStatus, setSubmitting });
  };

  useEffect(() => {
    getSettings(requests.GetSettings).then((res) => {
      setSettings(res);
    }
    );
  }, []);

  const tryResetPassword = async (values, { setErrors, setStatus, setSubmitting }) => {
    try {
      await resetPassword(
        {
          Email: values.email,
        },
      );
      setLoader(false);
      setIsResetPasswordRequested(true);
    } catch (error) {
      setLoader(false);
      const message = error || translation.Login_FormError;
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  const backToResetPassword = () => setIsResetPasswordRequested(false);

  return (
    <div className="login">
      <div className="login--connect">
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%"
            }}
            style={{
              marginTop: 0
            }}
          >
            {!isResetPasswordRequested ?  
            <>
              <Formik
                initialValues={{
                  email:
                    Cookies.get("email") !== undefined
                      ? Cookies.get("email")
                      : "",
                  submit: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleResetPassword}
              >
                {loader ? (
                  <Loader />
                ) : (
                  ({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <Box
                      component="form"
                      noValidate
                      sx={{ mt: 1 }}
                      onSubmit={handleSubmit}
                    >                      
                      <Grid container spacing={2} className="auth__background--container">
                        <Grid item xs={12} md={6} lg={6} className="auth__left--background">
                          <div className="auth__left--background--internal">
                            <img className="login--logo" src={settings?.logoUrl} alt="logo" />                          
                            <Typography
                              className="login--connect--typography--no-italic"
                              component="h1"
                              variant="h5"
                            >
                              {translation?.Reset_Password}
                              <span className="forget__pass--hint">{translation?.Forget_Password_Hint}</span>
                            </Typography>

                            <FormControl className="admin__form-control">
                              <label>{translation.Email}</label>                        
                              <TextField
                                margin="normal"
                                required
                                fullWidth
                                type="email"
                                name="email"
                                variant="outlined"
                                label={translation?.Login_Email}
                                value={values.email}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </FormControl>  

                            {errors.submit && (
                              <Alert mb={5} severity="warning" className="login__alert--container">
                                {translation.Incorrect_Credentials}
                              </Alert>
                            )}

                            <Button
                              className="login--connect--button"
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ mt: 3, mb: 2 }}
                              disabled={isSubmitting}
                              onClick={(e) => {
                                AuthGuard(e);
                              }}
                            >
                              {translation?.Continue}
                            </Button>

                            <MultiLanguage isFromLogin={true} />

                          </div>                          
                        </Grid>
                        <Grid className="auth__right-background" item xs={12} md={6} lg={6}>
                          <Box
                            component={Grid}
                            item
                            xs={12}
                            display={{ xs: "none", md: "block", lg: "block" }}
                          >
                          </Box>          
                        </Grid>
                      </Grid>
                    </Box>
                  )
                )}
              </Formik>
            </> : 
            <>
              <Grid container spacing={2} className="auth__background--container">
                <Grid item xs={12} md={6} lg={6} className="auth__left--background">
                  <div className="auth__left--background--internal">
                    <img className="login--logo" src={settings?.logoUrl} alt="logo" />        
                    <Typography
                      className="login--connect--typography login--connect--typography--extended"
                      component="h1"
                      variant="h5"
                    >
                      {translation?.Email_sent_message}
                    </Typography>
                    <span className="login--connect--typography--extended email__sent--success--message">{translation?.Email_Sent_detailed_message}</span>
                    <Box
                      component="div"
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <p className="login--grid--paragraph">
                        {translation?.Check_Link_Receieved}
                        <Link to="/reset-password" onClick={backToResetPassword} variant="body2">{translation?.Resend_Link}</Link>                    
                      </p>
                      <Grid container>
                        <Grid item xs>
                          <MultiLanguage isFromLogin={true} />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Box
                          component={Grid}
                          item
                          xs={12}
                          display={{ xs: "none", md: "block", lg: "block" }}
                        >
                        </Box>          
                      </Grid>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </>
            }
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default ResetPassword;
