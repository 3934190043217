import { FormControl } from '@mui/material';

import version from "../../version";
import useReplacer from "../../hooks/replacer";

import "./Footer.scss"

export default function Footer() {

    const year = new Date().getFullYear();
    const replacer = useReplacer();

    return (
        <div className="footer_heigh_container">
            <div className="footer_height-spacer"></div>
            <div className="footer_in">
                <div></div>
                <div className="footer__container">
                    <div className="footer__link">
                        <a href="https://topcream.global/contact/" target="_blank">Contact Us</a>
                        </div>
                    <span className="footer_separator"> - </span>
                    <div className="footer__link">
                        <a href="https://topcream.global" target="_blank">About</a>
                    </div>
                    <span className="footer_separator"> - </span>
                    <div className="footer__link-disabled">
                        <a to="/privacy">Privacy</a>
                    </div>
                    <span className="footer_separator"> - </span>
                    <div className="footer__link-disabled">
                        <a to="/terms-of-service">Terms of Service</a>
                    </div>
                    <span className="footer_separator"> - </span>
                    <p>© {year} {replacer("{FULL_CUSTOMER_NAME}")}</p>
                </div>
                <div className="site_version_container">
                    <FormControl className="admin__form-control">
                        <h6 style={{ textAlign: "center" }}>{version}</h6>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}