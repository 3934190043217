import React, { useEffect, useState } from "react";
import ImCross from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";

import { getPeople } from "../../servicesRedux/GlobalService";
import requests from "../axios/Requests";
import PersonCard from "../person/PersonCard.jsx";
import { 
  getRecommended, 
  postRecommendation, 
  getRecommendationReceipients, 
  deleteRecommendedReceipientId 
} from "../../servicesRedux/RecommendedService";

import useDebounce from "../../hooks/Debounce";
import { useTracking } from "react-tracking";
import Constants from "../constants/Constants";

import logo from "../../assets/images/logo.png";
import "./TopCreamShare.scss";

const TopCreamShare = props => {
  const [open, setOpen] = useState(false);
  const { userId, contentId } = props;
  const [people, setPeople] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const { translation } = useSelector(state => state.languageReducer);  
  const [pageNumber] = useState(1);
  const [totalPages] = useState();
  const pageParams = {
    limit: 100,
    page: totalPages >= pageNumber ? pageNumber : 1,
  };
  const [receipients, setReceipients] = useState([]);
  const [receipientsAsObjects, setReceipientsAsObjects] = useState([]);
  const [previousReceipients, setPreviousReceipients] = useState([]);
  const [deleteReceipeintIds, setDeleteReceipeintIds] = useState([]);
  const [, setRecommendationId] = useState("");
  const [, setRemovalIncluded] = useState(false);
  const [personModalOpen, setPersonModalOpen] = useState(false);
  const { id } = useParams();
  const tracking = useTracking();
  const debouncedSearchTerm = useDebounce(search, 500);

  const findPeople = () => {  
    getPeople(requests.GetPeople, search, "colleagues").then((item) => setPeople(item.data));
    enableSearch();
  };

  useEffect(() => {
    getRecommended(requests.GetRecommendations, pageParams).then((item) => setRecommendationId(item?.data[0]?.id));
    getReceipeintsThenAssignToObjects();    
  }, [contentId]);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && debouncedSearchTerm.length > 2) {
      findPeople();
    } else {
      if (debouncedSearchTerm.length == 0) getPeople(requests.GetPeople, search, "colleagues").then((item) => setPeople(item.data));
    }
  }, [debouncedSearchTerm]);

  const enableSearch = () => setIsSearching(true);

  const modalOpener = () => {
    setPersonModalOpen(true);
    enableSearch();
    getReceipeintsThenAssignToObjects();
  };

  const handleClose = () => setOpen(false);

  const handleShareContentModalClose = () => setPersonModalOpen(false);

  const userSelection = async userId => {
    let receipientsTemp = [];
    if (receipients.find(uid => uid == userId)) {
      setIsLoading(true);
      setRemovalIncluded(true);
      setDeleteReceipeintIds([...deleteReceipeintIds, userId]);
      receipientsTemp = receipients.filter(uid => uid != userId);
      setReceipients([...new Set(receipientsTemp)]);        
    } else {
      setIsLoading(true);
      receipientsTemp = [...receipients, userId];      
      setReceipients([...new Set(receipientsTemp)]);
    }
    setIsLoading(false);        
  };

  const sendShareContentReccomendation = async () => {
    const toRemove = new Set(previousReceipients);
    const difference = receipients.filter( x => !toRemove.has(x) );
    const recommendationIdsToDelete = receipientsAsObjects.filter(eachRes => deleteReceipeintIds.includes(eachRes.recipientId) ? eachRes.recommendationId : '');
    const recommendationIds = recommendationIdsToDelete.length > 0 && recommendationIdsToDelete.map(e => e.recommendationId);

    if (recommendationIdsToDelete.length > 0) {
      setIsLoading(true);
      await deleteRecommendedReceipientId(
        requests.RemoveRecommendation,
        recommendationIds,
      ).then(_ => {    
        setIsLoading(false);
        getReceipeintsThenAssignToObjects();
        setPersonModalOpen(false);           
        setOpen(true);               
      });  
      recommendationIds.map(delRecommendation => {
        tracking.trackEvent(
          {
            UserEventId: Number(Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.SHARE_MEDIA.enum),
            DataId: id,
            DataTypeId: Number(Constants.UserLogDataType.CATALOGITEM_ID.enum),
            ToId: delRecommendation,
            Note1: "Delete Share Playlist"
          }
        );
      });                
    }

    if (difference.length > 0) {
      setIsLoading(true);
      postRecommendation(
        requests.PostRecommendation,
        difference,
        id,
      ).then(async _ => {
        setIsLoading(false);
        setPersonModalOpen(false);
        getReceipeintsThenAssignToObjects();           
        setOpen(true);       
      }); 
      difference.map(diff => {
        tracking.trackEvent(
          {
            UserEventId: Number(Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.SHARE_MEDIA.enum),
            DataId: id,
            DataTypeId: Number(Constants.UserLogDataType.CATALOGITEM_ID.enum),
            ToId: diff
          }
        );
      });     
    }
  };

  const getReceipeintsThenAssignToObjects = () => {
    getRecommendationReceipients(requests.RecommendationReceipients, contentId).then(recpientsRes => {
      setReceipients(recpientsRes.map(r => r.recipientId != undefined && r.recipientId));
      setReceipientsAsObjects(recpientsRes);
      setPreviousReceipients(recpientsRes.map(r => r.recipientId != undefined && r.recipientId));
    });  
  };

  return (
    <div className="top-cream-share__container">
      <div className="top-cream-share">
        <div onClick={modalOpener} className="top-cream-share__logo">
          <img src={logo} alt="logo" className="top-cream-share__logo__img" />
        </div>
      </div>

      <Dialog
        className={`top-cream-share__container-modal-box-conference-page modal-dialog top-cream-share__search_modal ${props.mobileLayout === true ? 'top-cream-share__search_modal-mobile' : ''}`}
        onClose={handleShareContentModalClose}
        open={personModalOpen}
      >
        <div className="top-cream-share__search">
          <input
            type="text"
            placeholder="Search"
            className="top-cream-share__search__input"
            onChange={e => setSearch(e.target.value)}
          />
          <ImCross
            size={20}
            className="description__playlists--exit modal__close"
            onClick={handleShareContentModalClose}
          />
        </div>
        <DialogContent className="top-cream-share__search-dialog-content">
          <DialogContentText>
            <div className="top-cream-share__search-modal__container">
              {people && people.length !== 0 ? (
                <div className="top-cream-share__people">              
                  {people && people.map && people.map((item, i) => userId != item.userId && (
                    <PersonCard
                      userSelection={userSelection}
                      avatar={item.avatar}
                      firstName={item.firstName}
                      lastName={item.lastName}
                      userId={item.userId}
                      recommendationsList={receipients}
                    />
                  ))}     
                </div>
              ) : (
                <div className="top-cream-share__search-modal__no-result">
                  {isSearching ? "No results" : "Search for people"}
                </div>
              )}          
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <div className="top-cream-share__search-modal__container__action__panel">
            {isLoading && <CircularProgress size={20} color="inherit" className="progress_bar_circular progress_bar_circular-extended" />}                     
            <button
              disabled={isLoading}            
              onClick={sendShareContentReccomendation}
              className="linkedVideo--button linkedVideo--button--extended top-cream-share__people__send_btn"
            >
              {translation.Suggestion_Send_Button}
            </button>
          </div> 
        </DialogActions>        
      </Dialog>

      <Dialog
        className='modal-dialog top-cream-share__search_modal__share__container_dialog'
        open={open}
        onClose={handleClose}
      >
        <div className="top-cream-share__search_modal__share__container">
          <ImCross
            size={20}
            className="description__playlists--exit modal__close"
            onClick={handleClose}
          />
        </div>
        <DialogContent>
          <DialogContentText>
            <h3 className="success__modal-box-text">{translation.Content_Shared}</h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <button 
            onClick={handleClose}
            className="linkedVideo--button linkedVideo--button--extended"
          >
            {translation.Close}
          </button>
        </DialogActions>        
      </Dialog>
    </div>
  );
};

export default TopCreamShare;
