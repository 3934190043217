import React from 'react';
import './ComparisonChat.scss';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Loader from '../async/Loader';
import { useSelector } from "react-redux";

const ComparisonChat = ({ runAIcomparison, comparisonResult, handleClose, contents }) => {


  const aiResult = useSelector((state) => state.exploreAiReducer.aiResult);
  const isGenerating = useSelector((state) => state.exploreAiReducer.isGenerating)





  return (
    <div className="comparison-chat">
      <button className="chat-closeButton" onClick={handleClose}>✕</button>
      <div className="chat-header">
        {aiResult !== "" && "Synthesis of" }
        {aiResult !== "" && ` "` + contents.map(content => content.item.name).join(`" and "`) + `"`}
      </div>
      <div className="chat-content">
        {aiResult === "" && <Loader />}
        {aiResult === "" && 
        <div className='chat_imminent_action'> Brace for impact... because at TOP CREAM, action is imminent! </div>}
        <ReactMarkdown>{aiResult}</ReactMarkdown>
        {isGenerating && (
          <div className="animated-dot">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}
      </div>
      {/* <div className="chat-bar"></div> */}
    </div>
  );
};

export default ComparisonChat;