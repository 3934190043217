import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { TiDelete } from "react-icons/ti";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { GetWatchLaterCountAction } from "../../redux/action/GlobalActions.js";

import { truncate } from "../../hooks/utils.js";

import play from "../../assets/images/home-page-play-icon.svg";
// import starRating from "../../assets/images/star-icon-video.svg";
import video from "../../assets/icons/Icons/Label=Video.svg";
import podcast from "../../assets/icons/Icons/Label=Podcast.svg";
import article from "../../assets/icons/Icons/Label=Article.svg";

import PlaylistRemove from "../../assets/images/playlist-remove.svg";

import "./VideoCard.scss";

const VideoCard = ({
  item,
  appearanceType,
  type,
  isSaved,
  publicationDate,
  contentType,
  playlistDeleteContent,
  typePlayList,
  isPlayList,
  playListCatType,
  index,
  searchScreen
}) => {
  let date;
  const dispatch = useDispatch();
  let isSavedTemp = true;
  isSaved === undefined ? (isSaved = isSavedTemp) : (isSavedTemp = isSaved);
  const { watchLaterCount } = useSelector(state => state.globalReducer);
  const [loader, setLoader] = useState(false);
  const videoStyle = {
    width: appearanceType === "row" ? "auto" : "18vw",
  };

  if (publicationDate) {
    date = new Date(publicationDate);
  } else if (item?.createdOn !== undefined) {
    date = new Date(item?.createdOn);
  } else if (item?.created) {
    date = new Date(item?.created);
  } else {
    date = new Date(item?.publicationDate);
  }

  useEffect(() => {
    setLoader(false);
  }, [item]);

  const ifWatchLater = val => {
    if (val === 2) dispatch(GetWatchLaterCountAction(watchLaterCount - 1));
  }; 

  const durationFNC = obj => {
    if (!obj) return "0";
    const timeTable = obj.substring(0, obj.indexOf(".") !== -1 ? obj.indexOf(".") : obj.length).split(":");
    const hh = timeTable[0];
    const mm = timeTable[1];
    const ss = timeTable[2];
    if (hh === "00") {
      return mm + ":" + ss;
    } else {
      return hh + ":" + mm + ":" + ss;
    }
  };

  const renderTheAvatar = (item, searchScreen) => {
    if (searchScreen) {
      return (
        <>
          {(item?.speakersResponse && item?.speakersResponse[0] && item?.speakersResponse[0]?.firstName) &&
            <Avatar
              className="row__top--logo"
              src={item?.speakersResponse ? item?.speakersResponse[0]?.avatar : item?.avatar}
            />
            }
            {item?.speakersResponse && item?.speakersResponse[0] ? truncate(item?.speakersResponse[0]?.firstName, 15) +  " " + truncate(item?.speakersResponse[0]?.lastName, 15) : null}
        </>            
      )  
    } else {      
      return (
        <>
        {(item?.speakers && item?.speakers[0] && item?.speakers[0]?.firstName) &&
          <Avatar
            className="row__top--logo"
            src={item?.speakers ? item?.speakers[0]?.avatar : item?.avatar}
          />
          }
          {item?.speakers && item?.speakers[0] ? truncate(item?.speakers[0]?.firstName, 15) +  " " + truncate(item?.speakers[0]?.lastName, 15) : null}
      </>);
    }
  };

  return (
    (item !== undefined && item !== null) &&
    <>
      <div className={type === "TopTcVideo" ? "videoCard_top-videos" : "videoCard"} style={videoStyle}>
        {type === "TopTcVideo" && (
          index+1 <= 9 ? 
            <h1 className="videoCard_top-videos_number">{index + 1}</h1> :
            <h1 className="videoCard_top-videos_number-double_digits">{index + 1}</h1>          
        )}
        <Link to={`/conference/${item?.catalogItemId}`} className="container">
          <div className="row__top">
            <div className="row__top--info">
              <Link
                to={`/conference/${item?.catalogItemId}`}
                className="row__top__play"
              >
                <img src={play} alt="play" className="row__top__play--logo" />
              </Link>
            </div>
            <div className={type === "TopTcVideo" ? (index+1 <= 9 ? "row__top__poster_top-videos" : "row__top__poster_top-videos-double_digits") : "row__top__poster"} style={{
              backgroundImage: `url("${item.image}")`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}>
              <div className="row__top__poster_bottom">
                {type !== "TopTcVideo" && <img src={(item.mediaTypeId || item.mediaType) === 1 ? video : ((item.mediaTypeId || item.mediaType) === 2 ? podcast : article)} />}
                <span className="row__top__poster_bottom_label">
                  {durationFNC(item.duration)}
                </span>
              </div>
            </div>
          </div>
          <div className="row__text">
            <div className="row__text__top">
              <div className="row__text--label">{item?.name.length > 32 ? truncate(item?.name, 32) : item?.name}</div>
            </div>
            <div className="row__text__top">
              <div className="row__text--label--speakername">
                {renderTheAvatar(item, searchScreen)}                
              </div>
              {/* {item?.rating !== 0 &&
                <div className="row__text__bottom--recommended">
                  <img src={starRating} />
                  <span>{item?.rating}</span>
                </div>} */}
            </div>
          </div>
        </Link>

        {contentType === "Playlist" && playlistDeleteContent?.deleteContentState ? (
          !loader ? (
            <div
              className="row__text__bottom__remove"
              onClick={(e) => {
                playlistDeleteContent?.deleteContentCallback(item?.contentId);
                setLoader(true);
                ifWatchLater(typePlayList);
              }}
            >
              <TiDelete size={"2.5em"} className={"row__selected__remove"} />
            </div>
          ) : (
            <div className="row__text__bottom__remove">
              <CircularProgress
                size={20} 
                color="inherit"
                className="progress_bar_circular"
              />
            </div>
          )
        ) : isPlayList === true && (
          <>
            <div
              className="row__text__bottom__remove"
              onClick={e => {
                playlistDeleteContent?.deleteContentCallback(item?.contentId);
                setLoader(true);
                ifWatchLater(typePlayList);
              }}
            >
              {playListCatType == "my" && <img src={PlaylistRemove} style={{
                width: 30,
                height: 30,
                marginTop: 5,
                marginRight: 0
              }} />}
            </div>
          </>
        )}
      </div>
    </>
  );
};

VideoCard.propTypes = {
  item: PropTypes.any.isRequired,
  userId: PropTypes.any,
  appearanceType: PropTypes.any,
  isSaved: PropTypes.any,
  ind: PropTypes.any,
};

export default VideoCard;
