export const styles = {
    card: {
        minWidth: 200,
        minWidth: 150,
        minHeight: 235,
        maxWidth: 200
    },
    title: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 12,
        height: 60
    },
    tinyTitle: {
        marginBottom: 16,
        fontSize: 22,
        fontWeight: 500,
        height: 15
    },
    tinyTitleExtended: {
        fontSize: 16,
        fontWeight: 500,
    },
    subTitle: {
        fontSize: 27,
        fontWeight: 500,
        height: 45,
        textAlign: 'center'
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        marginRight: 25,
        minHeight: 235,
        backgroundColor: 'transparent !important',
        boxShadow: 'none',
        textAlign: 'center',
        marginBottom: 25,
        minWidth: 200
    },
    cardContent: {
        display: 'flex', 
        flexDirection: 'column'
    },
    cardContentExtended: {
        paddingBottom: 0,        
    },
    tablePaper: {
        width: '99%', 
        overflow: 'hidden'
    },
    cardContentExtended: {
        paddingBottom: 0
    },
    tablePaperExtended: {
        width: '99%', 
        overflow: 'hidden',
        marginRight: 25
    },
};