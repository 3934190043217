import React, { useRef, useState } from "react";
import { Avatar } from "@mui/material";
import ImCross from "@mui/icons-material/Close";

import "./PersonCard.scss";

const PersonCard = props => {
    const { userSelection, avatar, firstName, lastName, userId, recommendationsList } = props;
    const spanRef = useRef();
    const [checked, setChecked] = useState(false);

    return (
        <div className="top-cream-share__people__person" >
            <div className="top-cream-share__people__person__info">
                <div className="top-cream-share__people__person__img">
                    <Avatar
                        className="avatar_img"
                        src={avatar || ""}
                        alt="avatar"
                    >
                    </Avatar>                    
                </div>
                <div className={`top-cream-share__people__person__name top-cream-share__people__person__${userId}`}>
                    {firstName} {lastName}
                    <p>Utilisateur TopCream</p>
                </div>
            </div>
            <div className="top-cream-share__hasReco__container">
                <div 
                    onClick={() => {userSelection(userId); setChecked(checked)}} 
                    className={(recommendationsList.indexOf(userId) !== -1) ? "top-cream-share__hasReco--taupe" : "top-cream-share__hasReco--gray top-cream-share__hasReco--gray--extended"}
                >
                    <div>
                        {(recommendationsList.indexOf(userId) !== -1) ? 
                        <ImCross 
                            size={20} 
                            className="description__playlists--exit modal__close description__playlists--exit--color--extended" 
                            ref={spanRef} 
                        /> :
                        <ImCross size={20} />}
                    </div>
                </div>
            </div>                       
        </div>
    );
};

export default PersonCard;