import React from "react";
import styled from "styled-components/macro";

import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;,
  position: absolute,
  width: '100%',
  top: '-100px'
`;

function Loader() {
  return (
    <Root>
      <CircularProgress size={20} className="custom__loading--loader" m={2} size={80} style={{'color': '#B6AA90'}}/>
    </Root>
  );
}

export default Loader;
