// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * NestedList component *                                        *
 * ?          Nested is a way found to create a multiple input choice usable to change the research filter      *
 */
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';

import "./NestedCombo.scss";

const styles = () => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: "#CCC",
    color: "black",
  },
  nested: {
    zIndex: "3",
  },
  noSpace: {
    padding: "0px",
  }
});

const NestedCombo = props => {
  const { classes, GetformData, checkInfo, data, checkedState, setCheckedState } = props;
  const [open, setOpen] = useState(false);
  const initialFormData = Object.freeze([]);
  const [filterList, setFilterList] = useState(initialFormData);
  const items = data;

  // useEffect(() => {
  //   GetformData([1, 2, 3], data.list[0].id);
  // }, []);

  useEffect(() => {
    const checkedIndexes = checkedState.reduce((acc, checked, index) => {
      if (checked) {
        acc.push(index+1);
      }
      return acc;
    }, []);
    if (filterList.length > 0) {
      GetformData(checkedIndexes, data.list[0].id);
      checkInfo(true);  
    }
  }, [filterList]);

  const checkExistance = (value, index) => {
    const temp = filterList.find(fl => fl === value);
    if (temp === undefined) {
      checkInfo(true);
      setFilterList([
        ...filterList,
        value,
      ]);
    } else {
      setFilterList(filterList.filter(fl => fl !== value));
    }
    const updatedCheckedState = checkedState.map((item, j) => j === index - 1 ? !item : item);
    setCheckedState(updatedCheckedState);
  };

  const showCheckBoxList = () => setOpen(!open);

  return (
    <div className="NestedCombo-root">
      {items?.list?.map(list => {
        return (
          <List className={'NestedCombo'} key={list.id}>
            {list?.items?.map((item) => {
              return (
                <>
                  {item.subitems != null ? (
                    <div key={item.id}>
                      <List className={'NestedCombo_inline'} disablePadding>
                        {item.subitems.map((sitem, index) => {
                          return (
                            <div
                              onClick={() => checkExistance(sitem.value, index)}
                              key={index++}
                              className={`${classes.noSpace} row__title-second-half row__title-second-half-extended`}
                            >
                              <ListItem
                                button
                                key={sitem.id}
                                className={`${classes.nested} button__wrapper--list-item`}
                                style={{
                                  paddingLeft: 0,
                                  paddingRight: 0
                                }}
                              >
                                <Button 
                                  key={sitem.id}
                                  className={`${checkedState[index] ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`} 
                                ><span>{sitem.name}</span></Button>                                    
                              </ListItem>
                            </div>
                          );
                        })}
                      </List>
                    </div>
                  ) : (
                    <ListItem button onClick={() => showCheckBoxList()} key={item.id}>
                      <ListItemText primary={item.name} />
                    </ListItem>
                  )}
                </>
              );
            })}
          </List>
        );
      })}
    </div>
  );
};

NestedCombo.propTypes = {
  classes: PropTypes.object.isRequired,
  GetformData: PropTypes.func,
  checkInfo: PropTypes.any,
};

export default memo(withStyles(styles)(NestedCombo));
