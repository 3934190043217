// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Requests component *                                          *
 * ?                                    Requests contain every api request we are doing                         *
 * ?                                    We centralized everything to make it more clear                         *
 */
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

var URL = "";
if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
  // It switches between prod and dev env
  URL = "http://localhost:5207";
} else {
  URL = "";
}

const API_KEY = "api";
var requests = "";

// Every api request should be put there

try {
  requests = {
    // ////////////////// GET ////////////////////////////
    GetConference: `${URL}/${API_KEY}/conference/`,
    RelatedVideos: `${URL}/${API_KEY}/conference/{catalogItemId}/relatedVideos?Limit={limit}&Page={page}{PreferredLanguageIds}`,
    GetContent: `${URL}/${API_KEY}/contents?section={type}&Limit={limit}&Page={page}&category={category}&{MediaType}{PreferredLanguageIds}`, // valide
	  GetContentCurationForOldDashboard: `${URL}/${API_KEY}/contents/curation`,
    GetContentCuration: `${URL}/${API_KEY}/admin/users?query&sortBy=5&orderBy=true&accountId=&roleName=Curator&page=1&limit=1000`,
    GetContentChiefCuration: `${URL}/${API_KEY}/admin/users?query=&sortBy=5&orderBy=true&Limit=1000&Page=1&accountId=&roleName=Curator-Manager`,
    GetContentById: `${URL}/${API_KEY}/content/{id}`, 
    GetViewCount: `${URL}/${API_KEY}/Conference/GetViewCount?id=`,
    GetContributor: `${URL}/${API_KEY}/Speaker/GetContributor?id=`,
    GetChannelById: `${URL}/${API_KEY}/channel/{id}`,
    GetRecommendationsCount: `${URL}/${API_KEY}/Recommendation/Count`,
    GetStatusSummary: `${URL}/${API_KEY}/Content/StatusSummary`,
    GetMyPlaylists: `${URL}/${API_KEY}/me/playlists?Limit={limit}&Page={page}`,
    GetMyPlaylistContents: `${URL}/${API_KEY}/me/playlists/{id}/contents?Limit={limit}&Page={page}&{MediaType}`,
    PutPlaylistByID: `${URL}/${API_KEY}/me/playlists/{id}`,
    GetPlaylistsWithContent: `${URL}/${API_KEY}/me/playlists/content/{id}`,
    // GetFollowerCount: `${URL}/${API_KEY}/Conference/GetFollowerCount?id=`,
    GetIntervenant: `${URL}/${API_KEY}/speakers?catalogItemId={catalogItemId}`,
    GetTags: `${URL}/${API_KEY}/tags?orderby={orderby}&filter={filter}&Limit={limit}&Page={page}`,
    GetInfoConference: `${URL}/${API_KEY}/InfoConference/`,
    GetResearch: `${URL}/${API_KEY}/Search?Limit={limit}&Page={page}`,
    GetUser: `${URL}/${API_KEY}/me`,
    GetSpeaker: `${URL}/${API_KEY}/speakers?catalogItemId={catalogItemId}`,
    GetSpeakerById: `${URL}/${API_KEY}/Speaker/`,
    GetComments: `${URL}/${API_KEY}/comments?id={catalogitemId}&Limit={limit}&Page={page}`,
    GetAllReplies: `${URL}/${API_KEY}/comments/{id}?Limit={limit}&Page={page}&filter=repliedTo`,
    GetSavedContent: `${URL}/${API_KEY}/Conference/GetSavedContent?Limit={limit}&Page={page}`,
    GetCategories: `${URL}/${API_KEY}/categories`,
    GetSpeakersByDurationCategory: `${URL}/${API_KEY}/contents?Section=FollowingSpeakers&{MediaType}&Date={duarionType}{PreferredLanguageIds}&limit={limit}&page={page}`,
    // GetChapters: `${URL}/${API_KEY}/Conference/Chapters/`,
    // GetHighlights: `${URL}/${API_KEY}/Conference/Highlight/`,
    GetDownloaded: `${URL}/${API_KEY}/me/downloaded?Limit={limit}&Page={page}`,
    GetSubscriptions: `${URL}/${API_KEY}/subscriptions`,
    GetWatchLaterCount: `${URL}/${API_KEY}/Conference/GetWatchLaterCount?id=`,
    GetMeWatchlater: `${URL}/${API_KEY}/me/watch-later?Limit={limit}&Page={page}`,
    GetContentBySpeaker: `${URL}/${API_KEY}/contents/speaker/{id}?Limit={limit}&Page={page}`,
    GetContentByChannel: `${URL}/${API_KEY}/contents/channel/{id}?Limit={limit}&Page={page}`,
    getAllSpeakers: `${URL}/${API_KEY}/speaker/getallspeakers`,
    getAllSpeakersCuration : `${URL}/${API_KEY}/Contributor?type=spk`,
    getAllContributors: `${URL}/${API_KEY}/Contributor?type=aut`,
    GetReview: `${URL}/${API_KEY}/Conference/Review/`,
    GetHasReviewed: `${URL}/${API_KEY}/Conference/HasReviewed/`,
    GetPeople: `${URL}/${API_KEY}/Person/People?name={name}&filter={filter}`,
    GetPerson: `${URL}/${API_KEY}/person/{id}`,
    UpdatePerson: `${URL}/${API_KEY}/person/{id}`,
    UpdateChannel: `${URL}/${API_KEY}/channel/{id}`,
    GetRecommendations: `${URL}/${API_KEY}/recommendations?Limit={limit}&Page={page}`,
    DeleteRecommendations: `${URL}/${API_KEY}/recommendation/`,
    GetLanguage: `${URL}/${API_KEY}/Multilang/`,
    GetChannelCount: `${URL}/${API_KEY}/channel/Subscriber?id={id}`,
    GetTenant: `${URL}/${API_KEY}/Tenant/Configuration`,
    GetAllChannels: `${URL}/${API_KEY}/channels`,
    GetAllSocialMedia: `${URL}/${API_KEY}/social-media`,
    GetMySpeakersForManage: `${URL}/${API_KEY}/me/subscriptions/speakers`,
    PostMySpeakersForManageView: `${URL}/${API_KEY}/me/subscription/speaker/{id}`,
    DeleteMySpeakersForManageView: `${URL}/${API_KEY}/me/subscription/speaker/{id}`,
    GetSpeakerSubscriptions: `${URL}/${API_KEY}/me/subscriptions/speakers`,

    Registration: `${URL}/${API_KEY}/authenticate/register`,
    RegistrationConfirmation: `${URL}/${API_KEY}/authenticate/register-confirmation?confirmationToken={confirmationToken}`,
    ResendConfirmationEmail: `${URL}/${API_KEY}/authenticate/resend-confirmationemail?email={email}`,

    // ////////////////// POST ////////////////////////////
    PostSpeakerSubscriptions: `${URL}/${API_KEY}/me/subscription/speaker/{speakerId}`,
    PostLogin: `${URL}/${API_KEY}/Authenticate/login`,
    PostComment: `${URL}/${API_KEY}/comment/`,
    PutComment: `${URL}/${API_KEY}/comment/`,
    PostUserCategory: `${URL}/${API_KEY}/Category/InterestCategory/{category}`,
    CreateContent: `${URL}/${API_KEY}/LoadData/CreateContent`,
    CreatePlaylist: `${URL}/${API_KEY}/me/playlists`,
    UpdatePlaylist: `${URL}/${API_KEY}/me/playlists/{id}`,
    ResetPassword: `${URL}/${API_KEY}/account/password-reset`,
    ChangePassword: `${URL}/${API_KEY}/account/password`,
    // PostSavedContent: `${URL}/${API_KEY}/Conference/PostSavedContent`, // Take two parameters : catalogitemid and accountuserid
    PostSavedContent: `${URL}/${API_KEY}/Conference/PostSavedContent?catalogitemid={catalogItemId}`, // Take two parameters : catalogitemid and accountuserid
    PostWatchLater: `${URL}/${API_KEY}/me/watch-later`,
    PostContentIntoPlaylist: `${URL}/${API_KEY}/me/playlists/{id}/contents`,
    DeleteWatchLater: `${URL}/${API_KEY}/me/watch-later/{id}`,
    GetMeInformation: `${URL}/${API_KEY}/me`,

    PostWatchHistory: `${URL}/${API_KEY}/me/watchhistory`,
    PatchWatchHistory: `${URL}/${API_KEY}/me/watchhistory`,
    GetWatchHistoryByContent: `${URL}/${API_KEY}/me/watchhistory/content/`,
    GetWatchHistory: `${URL}/${API_KEY}/me/watchhistory/contents?Limit={limit}&Page={page}`,

    PostReview: `${URL}/${API_KEY}/Conference/Review/`,
    PostRecommendation: `${URL}/${API_KEY}/recommendation`,
    RemoveRecommendation: `${URL}/${API_KEY}/recommendation`,
    PostSubscription: `${URL}/${API_KEY}/Subscription?channelId={channelId}`,
    CreateCategory: `${URL}/${API_KEY}/category/`,
    CreateSpeaker: `${URL}/${API_KEY}/Person/Contributor/`,
    UpdateSpeaker: `${URL}/${API_KEY}/Person/Contributor?contributorId=`,
    CreateUploadJob: `${URL}/${API_KEY}/LoadData/CreateUploadJob`,
    UpdateUploadJob: `${URL}/${API_KEY}/LoadData/UpdateUploadJob`,
    UpdateUploadJob: `${URL}/${API_KEY}/LoadData/UpdateUploadJob`,
    CreateChannel: `${URL}/${API_KEY}/channel/`,

    PostSubscriptionChannel: `${URL}/${API_KEY}/me/subscription/channel/{channelId}`,
    DeleteSubscriptionChannel: `${URL}/${API_KEY}/me/subscription/channel/{channelId}`,
    GetSubscriptionsChannel: `${URL}/${API_KEY}/me/subscriptions/channel`,

    // ////////////////// DELETE ////////////////////////////
    DeleteSpeakerSubscriptions: `${URL}/${API_KEY}/me/subscription/speaker/{speakerId}`,
    DeleteSavedContent: `${URL}/${API_KEY}/conference?catalogitemid={catalogItemId}`, // Take two parameters : catalogitemid and accountuserid
    DeleteComment: `${URL}/${API_KEY}/comment/`,
    DeleteReview: `${URL}/${API_KEY}/Conference/Review/`,
    DeleteUserCategory: `${URL}/${API_KEY}/Category/InterestCategory/{category}`,
    DeleteSubscription: `${URL}/${API_KEY}/Subscription/{channelId}`,
    DeletePlaylist: `${URL}/${API_KEY}/me/playlists/{id}`,
    DeleteContentFromPlaylist: `${URL}/${API_KEY}/me/playlists/{id}/contents/{contentId}`,
    GetUsersForSharedPlaylist: `${URL}/${API_KEY}/playlistshared/{id}/users`,
    DeleteUsersSharedPlaylist: `${URL}/${API_KEY}/playlistshared/{playlistSharedId}`,
    PostUsersToSharePlaylist: `${URL}/${API_KEY}/me/playlistshared`,
    GetSharedPlaylist: `${URL}/${API_KEY}/me/playlistshared`,
    GetContentSharedPlaylist: `${URL}/${API_KEY}/playlistshared/{id}/contents?Limit={limit}&Page={page}`,
    DeleteSharedPlaylist: `${URL}/${API_KEY}/playlistshared`,

    // ////////////////// PUT ////////////////////////////
    PutPerson: `${URL}/${API_KEY}/person`,
    PatchPerson: `${URL}/${API_KEY}/person/{personId}`,
    getAllCategories: `${URL}/${API_KEY}/category/subcategory`,
    UpdateContent: `${URL}/${API_KEY}/LoadData/UpdateContent`,
    GetAllTagsCuration: `${URL}/${API_KEY}/tags/GetAllTagsCuration`,
    UpdateTags: `${URL}/${API_KEY}/tag/{id}`,
    DeleteTags : `${URL}/${API_KEY}/tag/{id}`,
    CreateTags: `${URL}/${API_KEY}/tag`,
    PostUserTags: `${URL}/${API_KEY}/me/tag`,
    GetUserTags: `${URL}/${API_KEY}/me/tags`,
    PatchProfile : `${URL}/${API_KEY}/profile`,

    /////////////////// PATCH ////////////////////////////
    PatchPlaylistName: `${URL}/${API_KEY}/me/playlists/{id}`,

    //Suggestion
    PostSuggestion: `${URL}/${API_KEY}/suggestions`,
    //Authentification
    FirstTimeLogin: `${URL}/${API_KEY}/account/first-login`,

    // Getting the receipeints from recommendations
    RecommendationReceipients: `${URL}/${API_KEY}/recommendation/receipient?contentId=`,

    // Get the iso languages
    LoadISOEntries: `${URL}/${API_KEY}/iso/countries/content`,
    
    LogUserActivity: `${URL}/${API_KEY}/account/activities`,

    //settings
    GetSettings: `${URL}/${API_KEY}/settings`,

    // users
    Users: `${URL}/${API_KEY}/admin/users?query={query}&sortBy={sortBy}&orderBy={orderBy}&Limit={limit}&Page={page}&accountId={accountId}&roleName={roleName}`,
    ChangeUserStatus: `${URL}/${API_KEY}/admin/accountuser?accountUserId={accountUserId}&isActive={isActive}`,
    DeleteAdminUser: `${URL}/${API_KEY}/admin/user/{accountUserId}`,    
    AdminAddRole: `${URL}/${API_KEY}/account/users/{userId}/role/{roleId}`,
    AdminRemoveRole: `${URL}/${API_KEY}/account/users/{userId}/role/{roleId}`,
    AdminAddUser: `${URL}/${API_KEY}/admin/users`,
    GetAllRoles: `${URL}/${API_KEY}/account/roles`,
    GetAllGroupsPerAccount: `${URL}/${API_KEY}/accountGroup/{accountId}`,
    GetCompanies: `${URL}/${API_KEY}/account/all`,
    GetISOLanguages: `${URL}/${API_KEY}/ISO/Language`,
    getContentPost: `${URL}/${API_KEY}/contents/curationv2?Limit={limit}&Page={page}`,
    patchContentStatusTagsUpdate: `${URL}/${API_KEY}/contents/content/{contentId}`, 

    //onboarding
    PatchOnBoardingStep: `${URL}/${API_KEY}/me/onboarding/{step}`,
    PostOnBoardingDone: `${URL}/${API_KEY}/me/onboardingdone`,

    // Reporting dashboard
    GetNoOfRegisteredUsers: `${URL}/${API_KEY}/reporting/nbofdailyregisteredusers?startDate={startDate}&endDate={endDate}&accountId={accountId}`,
    GetNoOfActiveUsers: `${URL}/${API_KEY}/reporting/nbofdailyactiveusers?startDate={startDate}&endDate={endDate}&accountId={accountId}`,

    GetNoOfRegisteredUsersForThisMonth: `${URL}/${API_KEY}/reporting/nbofmonthlyregisteredusers?startDate={startDate}&endDate={endDate}&accountId={accountId}`,
    GetNoOfActiveUsersForThisMonth: `${URL}/${API_KEY}/reporting/nbofmonthlyactiveusers?startDate={startDate}&endDate={endDate}&accountId={accountId}`,   
    
    GetNoOfLoggedInUsers:  `${URL}/${API_KEY}/reporting/loggedinonceusercount?accountId={accountId}`,
    
    GetNoOfDailyVideosViewed: `${URL}/${API_KEY}/reporting/nbofdailyvideoviewed?startDate={startDate}&endDate={endDate}&accountId={accountId}`,
    GetNoOfMonthlyVideosViewed: `${URL}/${API_KEY}/reporting/nbofmonthlyvideoviewed?startDate={startDate}&endDate={endDate}&accountId={accountId}`,

    GetNoOfDailyVideosMinutes: `${URL}/${API_KEY}/reporting/nbofdailyvideominutes?startDate={startDate}&endDate={endDate}&accountId={accountId}`,
    GetNoOfMonthlyVideosMinutes: `${URL}/${API_KEY}/reporting/nbofmonthlyvideominutes?startDate={startDate}&endDate={endDate}&accountId={accountId}`,

    GetNoOfDailyVideosCompleted: `${URL}/${API_KEY}/reporting/getnbofdailyvideocompleted?startDate={startDate}&endDate={endDate}&accountId={accountId}`,
    GetNoOfMonthlyVideosCompleted: `${URL}/${API_KEY}/reporting/getnbofmonthlyvideocompleted?startDate={startDate}&endDate={endDate}&accountId={accountId}`,    

    GetWatchAverageTime: `${URL}/${API_KEY}/reporting/getaveragewatchtime?startDate={startDate}&endDate={endDate}&accountId={accountId}`,

    CompareContentsByCatalogItemId: `${URL}/${API_KEY}/openaisummarize/comparecontentsbycatalogitemid`,
    AiStreaming: `${URL}/${API_KEY}/OpenAiSummarize/CompareContentsByCatalogItemIdStreaming`,
    UpdateDailyMotionThumbnail: `${URL}/${API_KEY}/loaddata/updatedailymotionthumbnail`,
    GetStreamingUrl: `${URL}/${API_KEY}/content/{contentId}/stream`,
  };  
} catch (error) {}

export default requests;
