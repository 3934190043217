import React from "react";

import "../switching/Intervenants.scss"


export const SpeakerPreview = ({translation, picture, speaker}) => {
    

    return (
      <div className={"buttonWithPic_full"}>
        <>
          <div className="buttonWithPic__button" >
            <div
              className="buttonWithPic__button--img"
              style={{
                backgroundImage: `url(${
                    picture !== null && picture !== undefined && picture !== ""
                      ? picture
                      : "/images/placeholder_avatar.png"
                })`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '50%',
                width: '60px',
                height: '60px'
              }}              
            >
            </div>
            <p className="buttonWithPic__button--text">
              <span>{(speaker?.firstName || "") + " " + (speaker?.lastName || "")}</span>
              <span>{translation.ViewPage}</span>
            </p>            
          </div>
        </>
      </div>
    );
};
