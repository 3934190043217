import * as types from "../constant/index";

export default function reducer(
  state = {
    conference: [],
    conferenceByTag: [],
    // chapters: [],
    // highlights: [],
  },
  action
) {
  switch (action.type) {
    case types.GET_CONFERENCE:
      return {
        ...state,
        conference: action.payload,
      };
    // case types.GET_CHAPTERS:
    //   return {
    //     ...state,
    //     chapters: action.payload,
    //   };
    // case types.GET_HIGHLIGHTS:
    //   return {
    //     ...state,
    //     highlights: action.payload,
    //   };
    case types.GET_CONFERENCE_BY_TAG:
      return {
        ...state,
        conferenceByTag: action.payload,
      };
    default:
      return state;
  }
}
