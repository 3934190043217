import axios from "axios";

export function getRecommended(requests, pageParams) {
  requests = requests
    .replace("{limit}", pageParams.limit)
    .replace("{page}", pageParams.page);

  return new Promise((resolve, reject) => {
    axios
      .get(requests, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function deleteRecommended(requests, id) {
  requests = requests.replace("{id}", id);

  return new Promise((resolve, reject) => {
    axios
      .delete(requests, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function deleteRecommendedReceipientId(requests, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(requests,  { 
        data: {recommendationId : id}, 
        withCredentials: true 
      })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function postRecommendation(requests, recipientId, id) {
  const data = {
    receipientId: Array.isArray(recipientId) ? recipientId : [recipientId],
    catalogItemId: id,
    createdDate: new Date(),
  };
  return new Promise((resolve, reject) => {
    axios
      .post(requests, data, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    }
  );
};

export function getRecommendationReceipients(requests, recipientId) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests + recipientId, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};