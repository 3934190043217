import React, { useEffect, useState } from "react";
import "../theme/contentManagement/ContentManagement.scss";
import { getContentCurationList } from "../servicesRedux/ContentManagementService";
import requests from "../components/axios/Requests";
import ContentTable from "../components/contentTable/ContentTable";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Constants from "../components/constants/Constants";
import Cookies from "js-cookie";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import useDebounce from "../hooks/Debounce";


const ContentManagement = () => {
  const [initialList, setInitialList] = useState([]);
  const [searchedList, setSearchList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [heroBannerValue, setHeroBannerValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const roles = Cookies.get("role");
  const navigate = useNavigate();
  const pageParams = {
    limit: 1000000,
    page: 1,
  };
  const type = "Management";

  const loadContent = () => {
    getContentCurationList(requests.GetContentCurationForOldDashboard).then((item) => {
      handleResponse(item);
    });
  };
  const toUploadApp = () => {
    window.location.href = "/curationUploadApp";
  };
  useEffect(() => {
    loadContent();
  }, []);
  const handleResponse = (item) => {
    let isCuratorManager = roles.indexOf("Curator-Manager") > 0;

    let listInProcess = item?.filter(
      (l) => l.contentAdminStatusId === Number(Constants.ContentAdminStatus.IN_PROGRESS.enum)
    );
    let listSubmittedForReview = item?.filter(
      (l) => l.contentAdminStatusId === Number(Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.enum)
    );
    let listApproved = item?.filter(
      (l) => l.contentAdminStatusId === Number(Constants.ContentAdminStatus.APPROVED.enum)
    );
    let listRejected = item?.filter(
      (l) => l.contentAdminStatusId === Number(Constants.ContentAdminStatus.REJECTED.enum)
    );
    let listApprovedForDistribution = item?.filter(
      (l) => l.contentAdminStatusId === Number(Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.enum)

    );
    let listDuplicate = item?.filter(
      (l) => l.contentProcessStatusId === Number(Constants.ContentProcessStatus.DUPLICATE.enum)
    );

    let initL =  [
      { content: listInProcess, title: Constants.ContentAdminStatus.IN_PROGRESS.labelDashboard },
      { content: listSubmittedForReview, title: Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.labelDashboard }
    ]
    .concat(isCuratorManager ?
    [
      { content: listRejected, title: Constants.ContentAdminStatus.REJECTED.labelDashboard },
      { content: listApproved, title: Constants.ContentAdminStatus.APPROVED.labelDashboard },
      { content: listApprovedForDistribution, title: Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.labelDashboard },
      { content: listDuplicate, title: Constants.ContentProcessStatus.DUPLICATE.labelDashboard},
    ]
    :
    []);

    setInitialList(initL);

    let searchL = [
      { content: listInProcess, title: Constants.ContentAdminStatus.IN_PROGRESS.labelDashboard },
      { content: listSubmittedForReview, title: Constants.ContentAdminStatus.SUBMITTED_FOR_REVIEW.labelDashboard }
    ]
    .concat(isCuratorManager ?
    [
      { content: listRejected, title: Constants.ContentAdminStatus.REJECTED.labelDashboard },
      { content: listApproved, title: Constants.ContentAdminStatus.APPROVED.labelDashboard },
      { content: listApprovedForDistribution, title: Constants.ContentAdminStatus.APPROVED_FOR_DISTRIBUTION.labelDashboard },
      { content: listDuplicate, title: Constants.ContentProcessStatus.DUPLICATE.labelDashboard},
    ]
    :
    []);

    setSearchList(searchL);

  };
  
  const handleRadioGroup = (event) => 
  {
    if (event.target.value === heroBannerValue) {
      setHeroBannerValue("");
      handleFilter(debouncedSearchTerm, "");
    } else {
      setHeroBannerValue(event.target.value);
      handleFilter(debouncedSearchTerm, event.target.value);
    }
    
  }

  const handleFilter = (val, heroBanner) => {
    
    if ((val === "" || val === " " || val === undefined) && (heroBanner === undefined || heroBanner === "")) {
      setSearchList(initialList);
    } else {
     
      const updatedList = initialList.map((item, index) => {
        if(heroBanner === "Active")
        {
          return {
            content: item.content.filter(
              (im) =>
                (val === "" || (im.curator?.toLowerCase().includes(val.toLowerCase()) ||
                im.name?.toLowerCase().includes(val.toLowerCase()) ||
                im.fullSummary?.toLowerCase().includes(val.toLowerCase()))) &&
                (im.heroBanner === true && im.isHighlighted === true)
            ),
            title: item.title,
          };
        }
        else if(heroBanner === "InActive")
        {
          return {
            content: item.content.filter(
              (im) =>
                (val === "" || (im.curator?.toLowerCase().includes(val.toLowerCase()) ||
                im.name?.toLowerCase().includes(val.toLowerCase()) ||
                im.fullSummary?.toLowerCase().includes(val.toLowerCase()))) &&
                (im.heroBanner === true && im.isHighlighted === false)
            ),
            title: item.title,
          };
        }
        else
        {
          return {
            content: item.content.filter(
              (im) =>
                (val !== "" && (im.curator?.toLowerCase().includes(val.toLowerCase()) ||
                im.name?.toLowerCase().includes(val.toLowerCase()) ||
                im.fullSummary?.toLowerCase().includes(val.toLowerCase()))) 
                
            ),
            title: item.title,
          };
        }
        
      });

      setSearchList(updatedList);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      handleFilter(debouncedSearchTerm, heroBannerValue);
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="curator__app_content_display">
      <h1 className="content--title">Content curation application</h1>
      <div style={{ display: "flex" }}>
        <button className="refresh" onClick={loadContent}>
          Refresh List
        </button>
        <button className="toUploadApp" onClick={toUploadApp}>
          Create new content
        </button>
        <div className="containterInputContentManagement display-flex">
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            variant="filled"
            size="small"
            className="inputContentManagement"
            placeholder="Search ..."
            // onChange={onChange}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          
         
<FormControl style={{marginLeft : "15px"}} >
      <FormLabel id="demo-row-radio-buttons-group-label checkbox-label">Hero Banner</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        style={{marginTop: "-10px"}}
        value={heroBannerValue}
      >
        <FormControlLabel value="Active" control={<Radio onClick={handleRadioGroup}/>} label="Active" />
        <FormControlLabel value="InActive" control={<Radio onClick={handleRadioGroup}/>} label="In-Active" />
        
        
      </RadioGroup>
    </FormControl>

          
        </div>

        <IoMdArrowRoundBack
          className="arrowButton"
          size={25}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>

      {searchedList.map((item, index) => {
        return (
          <ContentTable content={item.content} title={item.title} key={index} />
        );
      })}
    </div>
  );
};
export default ContentManagement;