import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { GetNewContentAction } from "../../redux/action/GlobalActions";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./CurationGraphical.scss"
import imgePlaceholder from "../../assets/images/solid-color-image.jpeg"


export default function CurationGraphical(props) {
  const dispatch = useDispatch();
  const { newContent } = useSelector((state) => state.globalReducer);
  const [handelThumbnail, setHandelThumbnail] = React.useState("true");
  const [thumbnail, setThumbnail] = React.useState("");

  
  const handleChangeThumbnailFile = (event) => {
    let file = event.target.files[0];

    if (file.size > 1000 * 1024) {  // Check if file size is greater than 500KB
      alert("File size exceeds 1MB. Please choose a smaller file.");
      event.target.value = '';  // Reset the value to allow re-selection of the same file
      resetThumbnail();
      return;
    }

    // Create an image to check dimensions for external content
    if (newContent.isExternalContent || newContent?.mediaTypeId === 2) {
      const img = new Image();
      img.onload = () => {
        if (img.width !== 1280 || img.height !== 720) {
          alert("Thumbnail dimensions should be 1280x720.");
          event.target.value = ''; // Reset the value
          resetThumbnail();
        } else {
          processFile(event, file); // Process the file if it meets the dimension requirements
        }
      };
      img.onerror = () => {
        alert("Failed to load image for dimension check.");
        event.target.value = ''; // Reset the value
        resetThumbnail();
      };
      const url = URL.createObjectURL(file);
      img.src = url;
    } else {

      const img = new Image();
      img.onload = () => {
        if (img.width !== 640 || img.height !== 360) {
          alert("Thumbnail dimensions should be 640x360.");
          event.target.value = ''; // Reset the value
          resetThumbnail();
        } else {
          processFile(event, file); // Directly process the file if not external content
        }
      };
      img.onerror = () => {
        alert("Failed to load image for dimension check.");
        event.target.value = ''; // Reset the value
        resetThumbnail();
      };
      const url = URL.createObjectURL(file);
      img.src = url;
    }
  };

  function processFile(event, file) {
    let path = event.target.value.split("\\").pop();
    const reader = new FileReader();
    reader.onload = (e) => {
      setThumbnail(e.target.result);
    };
    reader.readAsDataURL(file);

    dispatch(
      GetNewContentAction({
        ...newContent,
        thunbnailURL: path,
        thumbnailFile: file,
      })
    );
  }

  function resetThumbnail() {
    dispatch(
      GetNewContentAction({
        ...newContent,
        thunbnailURL: '',
        thumbnailFile: '',
      })
    );
    setThumbnail("");
  }

  useEffect(() => {
    // Function to check the thumbnail's dimensions
    const checkThumbnailDimensions = () => {
      if (newContent.isExternalContent && newContent.thumbnailFile) {
        const img = new Image();
        const url = URL.createObjectURL(newContent.thumbnailFile);
        img.src = url;

        img.onload = () => {
          if (img.width !== 1280 || img.height !== 720) {
            alert("Thumbnail dimensions should be 1280x720.");
            resetThumbnail();
            // Handle the case where dimensions are not as expected
            // You might want to reset the thumbnail or prompt the user to select a new one
          } else {
            // Thumbnail meets the dimension requirements
            console.log("Thumbnail dimensions are valid.");
          }
        };
        img.onerror = (e) => {
          alert("Failed to load image for dimension check.");
          // Handle image loading error
        };
        // Assuming thumbnailURL is a path to the thumbnail file,
        // You may need to adjust how you obtain the URL based on your application's structure
      }
    };

    checkThumbnailDimensions();
  }, [newContent.isExternalContent]);

  return (
    <>
      {newContent && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 0.5,
              width: "100%",
              height: "100%",
            },
          }}
        >
          <Paper elevation={3}>

          </Paper>
          <Typography
            sx={{ fontSize: 16, fontWeight: 800 }}
            color="text.secondary"
            gutterBottom
          >
            New thumbnail
          </Typography>

          <Grid container>
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >

              <div className="image_filename">
                <label
                  htmlFor="thumbnail-upload"
                >
                  {thumbnail ? <img className="modify_selected_image" src={thumbnail} alt="thumbnail" /> :
                    <div className="container_placeholder">
                      <img className="placeholder" src={imgePlaceholder} alt="thumbnail" />
                      <div className="text_div">
                        <div className="video-name">
                          {newContent.isExternalContent || newContent?.mediaTypeId === 2 ?
                            "Select thumbnail file (1280x720px JPG):"
                            :
                            "Select thumbnail file (640x360px JPG):"
                          }
                          
                          {newContent?.thunbnailURL ?? ""}
                        </div>                        <p>(It will be updated when you update this content page)</p>

                      </div>
                    </div>}
                </label>
                {newContent?.thunbnailURL}
              </div>

            </Container>

            {handelThumbnail === "true" ? (
              <div className="upload_file">

                {/* <label
                  htmlFor="thumbnail-upload"
                  className="upload_file--modify"
                >
                  select thumbnail
                </label> */}

                <input
                  className="grey-placeholder"
                  id="thumbnail-upload"
                  type="file"
                  accept=".jpg,.jpeg"
                  style={{ display: "none" }}
                  onChange={handleChangeThumbnailFile}
                />
              </div>
            ) : handelThumbnail === "false" ? (
              <div className="upload_file">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <input
                    type="time"
                    name="ThumnailGenerationTime"
                    id="ThumnailGenerationTime"
                    defaultValue={newContent.thumbnailGenerationTime}
                    step="0.1"
                  />
                </LocalizationProvider>
              </div>
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
}
