import * as React from "react";
import "../Comments.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
// import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCommentByIDAction,
  deleteReplyByIDAction,
  RemoveChildCountCommentsByIDAction
} from "../../../../redux/action/CommentsAction";
import requests from "../../../axios/Requests";
import { deleteCommentByID } from "../../../../servicesRedux/CommentService";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ReplyComponent(props) {
  const { Reply, index, idUser, parentID } = props;
  const {replyState}=useSelector((state)=>state.commentsReducer);
  const dispatch = useDispatch();
  // const isEditing =
  // activeComment &&
  // activeComment.id === comment.id &&
  // activeComment.type === "editing";
  // const isReplying =
  //   activeComment &&
  //   activeComment.id === comment.id &&
  //   activeComment.type === "replying";
  const fiveMinutes = 300000;
  //   const timePassed = new Date() - new Date(comment.createdAt) > fiveMinutes;

  let canDelete = (idUser === Reply.userID) || (replyState);

  //   const canEdit = currentUser.userId === comment.userID && !timePassed;
  // const replyId = parentId ? parentId : comment.id;
  // const createdAt = new Date(comment.createdAt).toLocaleDateString();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    setOpen(false);
    // deleteReply(Reply.id);
    dispatch(deleteReplyByIDAction(Reply.id, parentID));
    dispatch(RemoveChildCountCommentsByIDAction(parentID));
    deleteComment(Reply.id);
  };

  const deleteComment = (commentId) => {
    dispatch(deleteCommentByIDAction(commentId));
    deleteCommentByID(requests.DeleteComment, commentId);
  };
  return (
    <div key={Reply.id} className="comment">
      <div className="comment__container">
        <img
          className="comments_reply_container--img"
          src={
            Reply.avatar !== "string" &&
            Reply.avatar !== "" &&
            Reply.avatar !== null
              ? Reply.avatar
              : "/images/placeholder_avatar.png"
          }
          alt=""
        />
      </div>

      <div className="comments__text">          
          <div className="comment__upper--user">
            {Reply.firstName} {Reply.lastName}
          </div>

        <div className="comment__actions">
        <div className="comment_reply_text">{Reply.body}</div>
        {canDelete && (
          <div
            className="comment__action"
            onClick={() => {
              handleClickOpen();
            }}
            style={{ cursor: "pointer" }}
          >
            <MdDeleteForever size={15} />
          </div>
        )}
        </div>

        {/* {isEditing && (
              <CommentForm
                submitLabel="Mis à Jour"
                hasCancelButton
                isEditing={isEditing}
                initialText={comment.body}
                handleSubmit={(text) => updateComment(text, comment.id)}
                handleCancel={() => {
                  setActiveComment(null);
                }}
                currentUser={currentUser}
              />
            )} */}
        {/* <div className="comment__actions">
              {canEdit && (
                <div
                  className="comment__action"
                  onClick={() =>
                    setActiveComment({ id: comment.id, type: "editing" })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <FiEdit />
                </div>
              )}

              {!canDelete && !canEdit && (
                <div className="comment__action">
                  <div className="comment__reply" onClick={handleReplies}>view All Replies</div>
                  <div
                    onClick={() =>
                      setActiveComment({ id: comment.id, type: "replying" })
                    }
                    className="comment__reply"
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
     */}

        {/* {replies.length > 0 && (
              <div className="replies">
                {replies &&
                  replies.map((reply) => (
                    <Comment
                      comment={reply}
                      key={reply.id}
                      setActiveComment={setActiveComment}
                      activeComment={activeComment}
                      updateComment={updateComment}
                      deleteComment={deleteComment}
                      addComment={addComment}
                      parentId={comment.id}
                      replies={[]}
                      currentUserId={currentUserId}
                    />
                  ))}
              </div>
            )} */}
        {open && (
          <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              Are you sure you want to delete this reply ?
            </DialogTitle>

            <DialogActions style={{ justifyContent: "space-evenly" }}>
              <Button onClick={handleClose} style={{ color: "#b6aa90" }}>
                No
              </Button>
              <Button
                onClick={handleConfirmDelete}
                style={{ color: "#b6aa90" }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
}
