import axios from "axios";

export function getSearch(requests, research, formData, pageParams) {
  let keywords = [];
  formData.keywordsList?.map(item => keywords.push(item.id));  
  const SearchRequest = {
    query: research === "" ? undefined : research,
    interestsList: formData.interestsList,
    KeyWordsList: keywords[0] ? keywords : formData.keywordsList,
    publicationDate: formData.publicationList && formData.publicationList.pop(),
    RecommendedList: formData.recommendedList,
    SpeakerList: formData.speakerList,
    durationsearchList : formData.durationsearchList,
    mediatypesList: formData.mediatypesList,
    localization: formData.localization,
    preferredLanguageIds: formData.language,
    discoverExpertACTSearch: formData.discoverExpertACTSearch === undefined ? {"isDiscover": false, "isExpert": false, "isACT": false} : formData.discoverExpertACTSearch
  };
  requests = requests
    .replace("{limit}", pageParams.limit)
    .replace("{page}", pageParams.page);
    return new Promise((resolve, reject) => {
    axios
      .post(requests, SearchRequest, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllTags(requests) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTags(requests, filter, limit, page, orderby) {
  requests = requests
    .replace("{limit}", limit ?? 15)
    .replace("{page}", page ?? 1)
    .replace("orderby={orderby}&", orderby ? 'orderby={orderby}&' : "")
    .replace("{orderby}", orderby)
    .replace("filter={filter}&", filter ? 'filter={filter}&' : "")
    .replace("{filter}", filter);

  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status >= 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
