import React from "react";

import "./svg-colors.scss";

const HeartSVG = () => (
    <svg
      className="share-list-icon" 
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        stroke="#656565"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 18.25S1.625 13 1.625 6.625A4.875 4.875 0 0111 4.75v0a4.875 4.875 0 019.375 1.875C20.375 13 11 18.25 11 18.25z"
      ></path>
    </svg>
);

export default HeartSVG;