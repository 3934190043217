import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import "../smallComponents/TagView.scss"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { RelatedVideoTags, SearchRelated } from "../../redux/action/RelatedVideoAction.js";

const TagView = (props) => {
    const { tagData } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Stack className="tagStack" direction="row" spacing={1}>
            {tagData.map((tag, index) => (
                <Chip
                    key={index}
                    label={tag}
                    variant="outlined"
                    className="tagChip"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(RelatedVideoTags({id: index, label: tag}));
                        dispatch(SearchRelated(true));
                        navigate("/search");
                    }}
                />
            ))}
        </Stack>
    );
};

export default TagView;
