import * as types from "../constant/index";


export default function reducer(
    state = {
        loadNext: false,
        hasLoaded: false,
        isOnScreen: false,
    },
    action
) {

    switch (action.type) {

        case types.LOAD_NEXT:
            return {
                ...state,
                loadNext: action.payload,
            };

        case types.HAS_LOADED:
            return {
                ...state,
                hasLoaded: action.payload,
            };

        case types.IS_ON_SCREEN:
            return {
                ...state,
                isOnScreen: action.payload,
            };
        default:
            return state;
    }
}