import * as types from "../constant/index";

export default function reducer(
  state = {
    removeContentId: [],
    editing: false,
    updatedPlaylist: {},
    allPlayLists: [],
    loadingStatePlaylist: false,
    isSubscribedState: null,
    isLikedState: null,
    likesCount: 0
  },
  action
) {
  switch (action.type) {
    case types.GET_REMOVE_CONTENT_ID:
      return {
        ...state,
        removeContentId: action.payload,
      };
    case types.GET_EDITING_PLAYLIST:
      return {
        ...state,
        editing: action.payload,
      };
    case types.GET_UPDATED_PLAYLIST_ITEM:
      return {
        ...state,
        updatedPlaylist: action.payload,
      };
    case types.GET_ALL_PLAYLISTS:
      return {
        ...state,
        allPlayLists: action.payload,
      };
    case types.LOADING_STATE_PLAYLISTS:
      return {
        ...state,
        loadingStatePlaylist: action.payload,
      };
    case types.IS_SUBSCRIBED_STATE:
      return {
        ...state,
        isSubscribedState: action.payload,
      };
    case types.IS_LIKED_STATE:
      return {
        ...state,
        isLikedState: action.payload,
      };
    case types.LIKES_COUNT:
      return {
        ...state,
        likesCount: action.payload,
      };
    default:
      return state;
  }
}
