import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from "@material-ui/core";
import { Chip, Stack } from "@mui/material";

import requests from "../components/axios/Requests";
import { GetAllSocialMediaAction } from "../redux/action/ChannelAction";
import { getSpeakerById } from "../servicesRedux/SpeakerService";
import Social from "../components/social/Social";
import Row from "../components/row/Row";
import { TabPanel } from "../components/TabPanel/TabPanel";
import FollowSpeakerButton from "../components/switching/FollowSpeakerButton";

import PersonPlaceholder from "../assets/images/person-placeholder.jpg";

import "../theme/speakerPage/SpeakerPage.scss";

const styledPeronSubscribed = {
  marginTop: 4,
  whiteSpace: "nowrap",
};

const SpeakerPage = () => {
  const dispatch = useDispatch();
  const { allSocialMedia, loadingSocialMedia } = useSelector(state => state.channelReducer);
  const { translation } = useSelector(state => state.languageReducer);
  const { isFromConference, isFromMySpeakers } = useSelector(state => state.speakerReducer);
  const { id } = useParams();
  const [person, setPerson] = useState([]);
  const [value, setValue] = useState(1);
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (id !== undefined && id !== null) {
      getSpeakerById(requests.GetSpeakerById, id).then(item => setPerson(item));
    }
  }, [id]);

  useEffect(() => {
    dispatch(GetAllSocialMediaAction());
  }, []);

  const handleChange = newValue => setValue(newValue);

  const StyledChip = withStyles({
    root: {
      backgroundColor: '#F7EED8',
      color: '#000000',
      alignSelf: 'center',
      alignItems: 'center',
      textTransform: 'capitalize',
      marginLeft: '0 !important',
      display: 'flex',
      justifyContent: 'space-between',
    },
  })(Chip);

  return (
    <div className="channel">
      <div className="speaker__banner">
        {person?.persons !== undefined && person?.persons !== null && (
          <Box className="speaker__container" sx={{ flexGrow: 1, padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2} lg={2}>
                <Box
                  component={Grid}
                  item
                  xs={12}
                  display={{ xs: "none", md: "block", lg: "block" }}
                >
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <>
                  <Grid item xs={12} md={12} lg={12} className="speakers__profile-image-container" style={{
                    display: 'flex'
                  }}>
                    <Grid item xs={12} md={4} lg={4} className="speaker__avatar-wrapper">
                      <div className="speaker_person_avatar--img-wrapper">
                        <div class="speaker_person_avatar" style={{
                          backgroundImage: `url(${person?.persons?.image ?? PersonPlaceholder})`
                        }}>
                        </div>
                      </div>
                      {isFromConference && (
                      <div className="exit__button only__mobile">
                        <IconButton onClick={() => navigate(isFromConference)}>
                          <CloseIcon fontSize="large" />
                        </IconButton>
                      </div>)}                       
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} className="speaker_details-wrapper">
                      <Typography variant="h4" className="speaker__name">
                        {person?.persons?.firstName && person?.persons?.firstName.length > 10 ? `${person?.persons?.firstName.substring(0, 10)}...` : person?.persons?.firstName}
                        {" "}
                        {person?.persons?.lastName && person?.persons?.lastName.length > 10 ? `${person?.persons?.lastName.substring(0, 10)}...` : person?.persons?.lastName}
                      </Typography>
                      <Typography variant="h5" className="speaker__title">
                        {person?.persons?.title}
                      </Typography>
                      <FollowSpeakerButton translation={translation} speaker={person} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} sx={styledPeronSubscribed}>                                      
                    <div className="speaker__informations">
                      <div className="speaker__informations__videos">
                        <div className="speaker__informations--summary">
                          <p className="sepaker__biography">{person?.persons?.biography}</p>
                          <div className="speaker__informations--social-media">
                            <Social
                              allSocialMedia={allSocialMedia}
                              personSocialMedia={person?.persons?.personSocialMedia}
                              loadingSocialMedia={loadingSocialMedia}
                            />
                          </div>
                          <div className="talks__about-section">
                            <span>{translation.Talks_About}</span>
                            <div className="tabs__section">
                              <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
                                {tags?.map((item, index) => <StyledChip label={item.label} sx={{ backgroundColor: "#F7EED8" }} />)}
                              </Stack>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                                       
                  </Grid>
                </>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Box
                  component={Grid}
                  item
                  xs={12}
                  display={{ xs: "none", md: "block", lg: "block" }}
                >
                  {isFromConference && (
                    <div className="exit__button only__desktop">
                      <IconButton onClick={() => navigate(isFromConference)}>
                        <CloseIcon fontSize="large" />
                      </IconButton>
                    </div>
                  )}  
                  {isFromMySpeakers && (
                    <div className="exit__button only__desktop">
                      <IconButton onClick={() => navigate(isFromMySpeakers)}>
                        <CloseIcon fontSize="large" />
                      </IconButton>
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}         
      </div>
      <div className="row__title-second-half row__title-second-half-extended speakers__row--buttons-section">
        <Button
          className={`${value == 1 ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`}
          onClick={() => handleChange(1)}
        >{`${translation.New_Video}`}</Button>
        <Button
          className={`${value == 2 ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`}
          onClick={() => handleChange(2)}
        >{translation.New_Podcast}</Button>
      </div>
      <div className="speaker__informations--videos--title">
        <div className="speaker-page__row__container">
          <TabPanel value={value} index={0}>
            <div className={`downloaded__commun ${value === 0 && "downloadedIsActive"}`}>
              {person?.id && (
                <Row
                  contentType={"channel"}
                  appearanceType={"row"}
                  callback={setTags}
                  person={person}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={`downloaded__commun ${value === 1 && "downloadedIsActive"}`}>
              {person?.id && (
                <Row
                  contentType={"channel"}
                  appearanceType={"row"}
                  callback={setTags}
                  person={person}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className={`downloaded__commun ${value === 2 && "downloadedIsActive"}`}>
              PODCASTS ...
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default SpeakerPage;
