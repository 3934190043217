import VideoCard from "./VideoCard";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";
// import IconButton from "@mui/material/IconButton";
import { TiDelete } from "react-icons/ti";
// import CircularProgress from "@mui/material/CircularProgress";
import { X } from "phosphor-react";
import "./RecommendedVideoCard.scss";

export default function RecommendedVideoCard(props) {

  const { item, handleClick } = props;
  const { translation } = useSelector((state) => state.languageReducer);
  const date = new Date(item.createdOn);

  const nbrDays = () => {
    const currentDate = new Date();
    /**!!!!!! diffTime is in absolute value because there are videos with a creation date ("created") later than the current date
     *  delete Math.abs when this problem is solved an back !!!!!!*/
    const diffTime = Math.abs(currentDate - date);
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays;
  };

  const DateString = () => {
    if (nbrDays() < 1) {
      const nbrHours = nbrDays() * 24;
      if (nbrHours > 1) {
        return (
          <div>
            {" "}
            {translation.VideoCard_PluralHour.replace(
              "@number@",
              Math.floor(nbrHours)
            )}{" "}
          </div>
        );
      } else {
        return (
          <div>
            {" "}
            {translation.VideoCard_PluralMinutes.replace(
              "@number@",
              Math.floor(nbrHours * 60)
            )}{" "}
          </div>
        );
      }
    } else {
      if (nbrDays() < 7) {
        return (
          <div>
            {" "}
            {translation.VideoCard_PluralDays.replace(
              "@number@",
              Math.floor(nbrDays())
            )}{" "}
          </div>
        );
      } else if (nbrDays() > 7 && nbrDays() < 30) {
        return (
          <div>
            {" "}
            {translation.VideoCard_PluralWeeks.replace(
              "@number@",
              Math.floor(nbrDays() / 7)
            )}{" "}
          </div>
        );
      } else if (nbrDays() > 30 && nbrDays() < 365) {
        return (
          <div>
            {" "}
            {translation.VideoCard_PluralMonths.replace(
              "@number@",
              Math.floor(nbrDays() / 30)
            )}{" "}
          </div>
        );
      } else if (nbrDays() > 365) {
        return (
          <div>
            {" "}
            {translation.VideoCard_PluralYear.replace(
              "@number@",
              Math.floor(nbrDays() / 365)
            )}{" "}
          </div>
        );
      } else {
        /* lines should be deleted when the getDownload (GetSavedContent) response from the back adds the "created" field to the data */
        return (
          <div> {translation.VideoCard_PluralYear.replace("@number@", 2)} </div>
        );
      }
    }
  };

  return (
    <div className="recommended_videocard">
      <div className="delete_recommended_videocard" >
        <div className="recommended_videocard_owner_info">
          <Avatar
            className="recommended_videocard_owner_avatar"
            src={item.ownerAvatar}
            alt="Remy Sharp"
          />
          <div className="recommended_videocard_owner__name">
            {item.ownerFirstName} {item.ownerLastName}{" "}
            {translation.Recommendation_IsRecommending}
            {DateString()}
          </div>
        </div>
        <X size={'2.5em'} className="recommended_delete" onClick={() => { handleClick(item.id) }} />

      </div>
      <div className="recommended_videocard_content">
        <VideoCard
          isSaved={item.content.isSaved}
          item={item.content}
          appearanceType={"row"}
        />
      </div>
    </div>
  );
}
