import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import requests from "../axios/Requests";
import { GetTranslation, SetLanguage } from "../../redux/action/LanguageAction";
import { patchPersonByID } from "../../servicesRedux/AdminService";

import Fr_Register from "../../resources/fr-FR.json";
import En_Register from "../../resources/en-US.json";

import "./MultiLanguage.scss";

export const MultiLanguage = (props) => {
  const { isFromLogin } = props;
  const { language } = useSelector(state => state.languageReducer);
  const { userRedux } = useSelector((state) => state.authentificationReducer);
  const dispatch = useDispatch();
  const languageList = ["FR", "En"];
  const languageListFull = ["Français", "English"];
  const languageSystem = localStorage.getItem("language") || languageList.find((item) => item.includes(window.navigator.language)) || languageList[1];

  const handleChange = (e) => {
    const splitLangLogin = isFromLogin ? (e.value === "Français" ? "FR" : "En") : "";
    const personRequest = {
      language: isFromLogin ? splitLangLogin === "En" ? "en-US" : "fr-FR" : e.value === "En" ? "en-US" : "fr-FR",
    };

    if (!isFromLogin) {
      patchPersonByID(requests.PatchPerson, userRedux.id, personRequest);
    }

    switch (isFromLogin ? (e.value === "Français" ? "FR" : "En") : e.value) {
      case "En":
        localStorage.setItem("language", "En");
        dispatch(GetTranslation(En_Register));

        break;
      case "fr-FR":
        localStorage.setItem("language", "FR");
        dispatch(GetTranslation(Fr_Register));
      case "FR":
        localStorage.setItem("language", "FR");
        dispatch(GetTranslation(Fr_Register));
        break;
      default:
        dispatch(GetTranslation(Fr_Register));
    }
    dispatch(SetLanguage( isFromLogin ? splitLangLogin : e.value));
  };

  return (
    <div className={isFromLogin ? "language__menu__login" : "language__menu"}>
      {isFromLogin ?
        <Dropdown
          placeholder={(language || languageSystem) === "FR" ? "Français" : "English"}
          list={languageListFull}
          options={languageListFull}
          onChange={handleChange}
          value={language === "FR" ? "Français" : "English"}
          className="language__dropdown"
        />
        :
        <Dropdown
          placeholder={language || languageSystem}
          list={languageList}
          options={languageList}
          onChange={handleChange}
          value={language}
          className="language__dropdown"
        />
      }
    </div>
  );
};

export default MultiLanguage;
