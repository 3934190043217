//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * VideoEnLien component *                                       *
 * !                                       Naming should be changed ASAP                                        *
 * ?                                 VideoEnLien is used in the conference page                                 *
 * ?               It's use like a youtube design, showing the recommendation based on the current video        *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";

import requests from "../../components/axios/Requests";
import { RelatedVideos } from "../../servicesRedux/ConferenceService.js";
import { SearchRelated } from "../../redux/action/RelatedVideoAction";

import starRating from "../../assets/images/star-icon-video.svg";
import video from "../../assets/icons/Icons/Label=Video.svg";
import podcast from "../../assets/icons/Icons/Label=Podcast.svg";
import article from "../../assets/icons/Icons/Label=Article.svg";

import "./LinkedVideo.scss";

const LinkedVideo = props => {
  const dispatch = useDispatch();
  const { contentId, translation, setLinkedVideoHasLoaded } = props;
  const [content, setContent] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const navigate = useNavigate();

  const { userRedux } = useSelector(state => state.authentificationReducer);

  const pageParams = {
    "limit": 10,
    "page": totalPages >= pageNumber ? pageNumber : 1,
  };
  useEffect(() => {
    content?.data && setLinkedVideoHasLoaded(true);
  }, [content]);

  useEffect(() => {
    RelatedVideos(requests.RelatedVideos, contentId, pageParams.limit, pageParams.page, userRedux.preferredLanguageIds).then((item) => setContent(item));
  }, []);

  useEffect(() => {
    if (content.currentPage !== undefined) {
      setTotalPages(content.totalPages);
      setPageNumber(content.currentPage);
    }
  }, [content]);

  const goToConference = (id) =>{
    if(props.isAuthenticated)
      window.location.href = `/conference/${id}`;
  }

  const redirectToSearch = () => {
    dispatch(SearchRelated(true));
    navigate("/search");
  };

  const durationFNC = obj => {
    if (!obj) return "0";
    const timeTable = obj.substring(0, obj.indexOf(".") !== -1 ? obj.indexOf(".") : obj.length).split(":");
    const hh = timeTable[0];
    const mm = timeTable[1];
    const ss = timeTable[2];
    if (hh === "00") {
      return mm + ":" + ss;
    } else {
      return hh + ":" + mm + ":" + ss;
    }
  };

  return (
    (contentId !== null && contentId !== undefined) &&
    <div className={content?.data ? "linkedVideo" : "linkedVideo-disable"}>
      {content?.totalItems > 0 && contentId !== null && contentId !== undefined && (<h3 className="linkedVideo--styling">{translation.Related_Content}</h3>)}

      {content?.data?.map((item, index) => {
        return (
          <div className="relatedVideo" key={index}>
            <div onClick={() => goToConference(item.catalogItemId)} style={{
                backgroundImage: `url(${item.image})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '6.75em',
                width: '12em'
            }}>
              <div className="row__top__poster_bottom">
                  {<img src={(item.mediaTypeId || item.mediaType) === 1 ? video : ((item.mediaTypeId || item.mediaType) === 2 ? podcast : article)} />}
                  <span className="row__top__poster_bottom_label">                                            
                  {durationFNC(item.duration)}
                  </span>
              </div>
            </div>
            <div className="relatedVideo__background" style={{
              width: '65%'
            }}>
              <div
                className="relatedVideo--text"
                onClick={() => goToConference(item.catalogItemId)}
              >
                {item.conferenceName.length > 50 ? item.conferenceName.substring(0, item.conferenceName.length - 40).concat('...') : item.conferenceName}
              </div>
              <div className="row__text__top">
                <div className="row__text--label--speakername">
                  {(item?.speakers && item?.speakers[0] && item?.speakers[0]?.firstName) &&
                  <Avatar
                    className="row__top--logo"
                    src={item?.speakers ? item?.speakers[0]?.avatar : item?.avatar}
                  />}                  
                  {item?.speakers && item?.speakers[0] ? item?.speakers[0]?.firstName +  " " + item?.speakers[0]?.lastName : null}
                </div>
                {item?.rating !== 0 &&
                  <div className="row__text__bottom--recommended">
                    <img src={starRating} />
                    <span>{item?.rating}</span>
                  </div>}
              </div>              
            </div>
          </div>
        );
      })}

      {content?.totalItems > 10 && contentId !== null && contentId !== undefined && props.isAuthenticated && (
        <button onClick={redirectToSearch} className="linkedVideo--button">{translation.Conference_SeeMore}</button>
      )}
    </div>
  )
};

export default LinkedVideo;
