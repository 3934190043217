import React from "react"
import ContentLoader from "react-content-loader"

import useBreakpoints from "../../hooks/UseBreakpoint";

const IntervenantsSkeleton = props => {
    const point = useBreakpoints();

    const renderContentLoaderWidth = mode => {
        switch(mode) {
            case "xs":
                return "600";
            case "sm":
                return "750";
            case "md":
                return "950";
            case "ml":
                return "1280";
            case "lg":
                return "1600";
        }
    };

    return (
        <ContentLoader 
            speed={2}
            width={'100%'}
            height={'320px'}
            viewBox="0 0 100% 320"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect width={renderContentLoaderWidth(point)} height="320" />
        </ContentLoader>
    )
}

export default IntervenantsSkeleton