import { React } from "react";
import { useDispatch } from "react-redux";
import { secondsToTime } from "../../hooks/utils";
import play from "../../assets/images/small-play-icon.svg";

import { GetVideoCurrentTime} from  "../../redux/action/VideoCurrentTimeAction";

export const Highlights = (props) => {
  const dispatch = useDispatch();
  const { label, start, end, imgsrc } = props;

  const convertedStartTimeIndex = secondsToTime(start);
  const convertedEndTimeIndex = secondsToTime(end);

  const startTime = () => dispatch(GetVideoCurrentTime({toggle: true, time: start}));


  return (
    <div className="chapters" onClick={startTime}>      
      <div style={{
        backgroundImage: `url("${imgsrc}")`,
        backgroundPosition: 'left',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain'
      }} className="chapters__background-image"
    >    
        <img src={play} alt="play" className="row__top__play--logo" />      
      </div>
      <div className="chapters__other-information">
        <h3>{label}</h3>
          <p className="chapters--timer">
            {convertedStartTimeIndex.m}:{convertedStartTimeIndex.s} - {convertedEndTimeIndex.m}:{convertedEndTimeIndex.s}
          </p>
      </div>
    </div>
  );
};