//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Navbar component *                                            *
 * ?                                    Navbar is a global component used on everypage (top of the page)        *
 * ?                          It contains 3 things : 6 topics of interest / research bar / administration       *
 * ?                                    It's sticky and will always be present on the page                      *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from "@mui/material/Toolbar";
import Cookies from "js-cookie";

import requests from "../axios/Requests";
import { GetTokenByIdUser } from "../../redux/action/AuthentificationAction";
import { SetTenantAction } from "../../redux/action/TenantAction.js";
import { getMe } from "../../servicesRedux/AuthentificationService";
import { patchPersonByID } from "../../servicesRedux/AdminService";
import { GetWatchLaterCountAction } from "../../redux/action/GlobalActions";
import { getTenant } from "../../servicesRedux/GlobalService.js";
import { getSettings } from "../../servicesRedux/SettingsService";

import useBreakpoints from '../../hooks/UseBreakpoint';
import { theme } from "../../hooks/theme";
import MobileNavigation from "./HomeApp/MobileNavigation";
import MediumDesktopNavigation from "./HomeApp/MediumDesktopNavigation";
import DesktopNavigation from "./HomeApp/DesktopNavigation";
import MediumLargeDesktopNavigation from "./HomeApp/MediumLargeDesktopNavigation";

import "./NavBar.scss";

const NavBar = ({ newProfileImage, setMyInfo }) => {
  const location = useLocation();
  const [tenant, setTenant] = useState("");
  const [publicConference, setPublicConference] = useState(undefined);

  const dispatch = useDispatch();
  const languageList = ["Fr", "En"];
  const navigate = useNavigate();
  const lang = localStorage.getItem("language") || languageList.find(item => item.includes(window.navigator.language)) || languageList[1];
  const payload = Cookies.get("Payload");

  useEffect(() => {
    if ((payload == null || payload === "Provided username and/or password is incorrect") && location.pathname.includes("/conference/")) {
      setPublicConference(true);
    } else {
      setPublicConference(false);
    }
  }
    , [location.pathname]);

  useEffect(() => {
    if (!tenant)
      getSettings(requests.GetSettings).then((res) => {
        setTenant({ logoURL: res.logoUrl });
      }
      );
  }, []);

  const styles = {
    toolbarMargin: {
      ...theme.mixins.toolbar,
      marginBottom: "3em",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "1.25em",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "2em",
      },
      [theme.breakpoints.down("md")]: {
        marginBottom: "2em",
      },
      [theme.breakpoints.down("lg")]: {
        marginBottom: "1.25em",
      },
      [theme.breakpoints.down("xl")]: {
        marginBottom: "2em",
      },
    },
    logo: {
      height: "8em",
      [theme.breakpoints.down("md")]: {
        height: "7em",
      },
      [theme.breakpoints.down("xs")]: {
        height: "5.5em",
      },
    },
    logoContainer: {
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    tabs: {
      marginLeft: "auto",
      "& .MuiButtonBase-root.MuiTab-root": {
        fontSize: 20,
      },
      "& .Mui-selected": {
        backgroundColor: "transparent",
        color: "#000",
        opacity: 0.7,
        borderRadius: 2,
      },
    },
    tab: {
      ...theme.typography.tab,
      minWidth: 10,
      marginLeft: "25px",
      color: "white",
    },
    hamburgerMenuIcon: {
      height: "50px",
      width: "50px",
    },
    menuIconContainer: {
      marginLeft: "auto",
      color: '#000',
      "&:hover": {
        opacity: 1,
      },
    },
    appbar: {
      zIndex: theme.zIndex.modal + 1,
      backgroundColor: 'transparent',
      color: '#000',
      width: '100%',
      boxShadow: 'none',
      padding: '0 70px 0 70px ',
      zIndex: 1
    },
  };
  const point = useBreakpoints();

  useEffect(() => {
    if ((!publicConference && publicConference !== undefined))
      requestForMeInformation();
  }, [location, publicConference]);

  const requestForMeInformation = () => {
    const personRequest = {
      language: lang === "En" ? "en-US" : "fr-FR",
    };
    getMe(requests.GetMeInformation).then(item => {
      if (!item?.onBoardingDone) { navigate("/onboarding"); }
      if (!Cookies.get("roles")) {
        Cookies.set("roles", JSON.stringify(item.roles));
      }
      Cookies.set("accountType", item.accountType);
      dispatch(GetTokenByIdUser(item));
      dispatch(GetWatchLaterCountAction(item.watchLaterCount));
      patchPersonByID(requests.PatchPerson, item.id, personRequest);
      setMyInfo(item);
    });
    getTenant(requests.GetTenant).then(item => {
      setTenant(item)
      dispatch(SetTenantAction(item));
    }
    );
  };

  const renderNavigationOnBreakPoint = mode => {
    switch (mode) {
      case "xs":
      case "sm":
        return (
          <MobileNavigation
            tenant={tenant}
            redirectTo={redirectTo}
            newProfileImage={newProfileImage}
            publicConference={publicConference}
          />);
      case "md":
        return (
          <MediumDesktopNavigation
            tenant={tenant}
            redirectTo={redirectTo}
            newProfileImage={newProfileImage}
            publicConference={publicConference}
          />);
      case "ml":
        return (
          <MediumLargeDesktopNavigation
            tenant={tenant}
            redirectTo={redirectTo}
            newProfileImage={newProfileImage}
            publicConference={publicConference}
          />);
      case "lg":
      case "xl":
        return (
          <DesktopNavigation
            tenant={tenant}
            redirectTo={redirectTo}
            newProfileImage={newProfileImage}
            publicConference={publicConference}
          />);
    }
  };

  const redirectTo = path => navigate(path);

  return (
    <>
      <AppBar
        sx={styles.appbar}
        color="secondary"
        elevation={9}
        position="relative"
      >
        <Toolbar disableGutters={true} >
          {renderNavigationOnBreakPoint(point)}
        </Toolbar>
      </AppBar>
    </>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;