import React from "react";
import Button from "@mui/material/Button";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DownloadIcon from '@mui/icons-material/Download';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

const PdfViewerButtonsWrapper = ({
  page,
  setPage,
  pdfDocument,
  scale,
  setScale,
  setFullscreen,
  onDocumentDownload,
  fullScreen
})=> {
    
  const zoomStyle = {
    marginLeft: 10,
    cursor: 'pointer'
  };

  return (
      <div className="pdfWrapper-button-wrappers">
          <div className="navigation">
            <div className="navigation-button-wrappers">
                <Button
                  className="pdf-navigation--buttons"
                  type="button"
                  sx={{ mt: 3, mb: 2, "& .MuiButton-startIcon": { margin: "0px" }, minWidth:0 }} 
                  style={{...zoomStyle, opacity: page === 1 ? 0.5 : 1}} 
                  disabled={page === 1} 
                  onClick={() => setPage(page - 1)}
                  startIcon={<ArrowBackIosIcon className="description__recommend__icon" />}
                />

                <span className="navigation-text">Page {page} of {pdfDocument && pdfDocument.numPages}</span>

                <Button
                    className="pdf-navigation--buttons"
                    type="button"
                    sx={{ mt: 3, mb: 2, "& .MuiButton-startIcon": { margin: "0px" }, minWidth:0 }} 
                    style={{...zoomStyle, opacity: page === pdfDocument && pdfDocument.numPages ? 0.5 : 1}} 
                    disabled={page >= pdfDocument && pdfDocument.numPages}
                    onClick={() => page != pdfDocument.numPages && setPage(page + 1)}
                    startIcon={<ArrowForwardIosIcon className="description__recommend__icon" />}
                />

                <Button
                  className="pdf-navigation--buttons"
                  type="button"
                  style={{...zoomStyle, opacity: scale === 2 ? 0.5 : 1}} 
                  disabled={scale == 2}
                  onClick={() => setScale(scale + 0.25)}
                  startIcon={<ZoomInIcon className="description__recommend__icon" />}
                  sx={{ mt: 3, mb: 2, "& .MuiButton-startIcon": { margin: "0px" }, minWidth:0 }} 
                />

                <Button
                  className="pdf-navigation--buttons"
                  type="button"
                  sx={{ mt: 3, mb: 2, "& .MuiButton-startIcon": { margin: "0px" }, minWidth:0 }} 
                  style={{...zoomStyle, opacity: scale === 1.25 ? 0.5 : 1}} 
                  disabled={scale == 1.25}
                  onClick={() => setScale(scale - 0.5)}
                  startIcon={<ZoomOutIcon className="description__recommend__icon" />}
                />

                {!fullScreen ?
                  <Button
                    className="pdf-navigation--buttons"
                    type="button"
                    sx={{ mt: 3, mb: 2, "& .MuiButton-startIcon": { margin: "0px" }, minWidth:0 }} 
                    style={{...zoomStyle}} 
                    onClick={() => setFullscreen(true)}
                    startIcon={<FullscreenIcon className="description__recommend__icon" />}
                  /> :                                                                     
                  <Button
                    className="pdf-navigation--buttons"
                    type="button"
                    sx={{ mt: 3, mb: 2, "& .MuiButton-startIcon": { margin: "0px" }, minWidth:0 }} 
                    style={{...zoomStyle}} 
                    onClick={() => setFullscreen(false)}
                    startIcon={<CloseFullscreenIcon className="description__recommend__icon" />}
                  />
                }

                <Button
                  className="pdf-navigation--buttons"
                  type="button"
                  sx={{ mt: 3, mb: 2, "& .MuiButton-startIcon": { margin: "0px" }, minWidth:0 }} 
                  style={{...zoomStyle}} 
                  onClick={() => onDocumentDownload()}
                  startIcon={<DownloadIcon className="description__recommend__icon" />}
                />
            </div>
          </div>
        </div>
    )
};

export default PdfViewerButtonsWrapper;