import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Typography from '@mui/material/Typography';
import ImCross from "@mui/icons-material/Close";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import IntervenantsSkeleton from "./IntervenantsSkeleton";
import { ButtonWithPic } from "./ButtonWithPic";

import SideBarRight from "../../components/sideBarRight/SideBarRight";

import "./Intervenants.scss";
import "../sideBarRight/SideBarRight.scss";

const Intervenants = props => {
  const {
    intervenant,
    infoConference,
    translation,
    channel,
    chapters,
    staticimg,
    transcriptList,
    highlights,
    following,
    isAuthenticated
  } = props;
  const [, setExpanded] = useState('');
  const [displayModal, setDisplayModal] = useState(false);

  const closeModalBox = () => {
    setDisplayModal(!displayModal);
    const root = document.getElementsByTagName('html')[0];
    root.classList.remove('addiontal-overlay-class');
    setExpanded("panel1");
  };

  const openModalBox = () => {
    window.scrollTo(0, 0);
    const root = document.getElementsByTagName('html')[0];
    root.classList.add('addiontal-overlay-class');
    setDisplayModal(!displayModal);
  };

  return (
    <div>
      {!infoConference?.fullSummary ? (
        <IntervenantsSkeleton />
      ) : (
        <>
          <div className="intervenants--container">
            <div className="intervenants">
              <div className="content_owner-wrapper content_owner-wrapper__mobile-view only__mobile-view" onClick={() => openModalBox()}>
                <Typography onClick={() => openModalBox()} className="comments__heading-p  video__details-p">
                  {translation.Video_Details}
                  <UnfoldMoreIcon />
                </Typography>
              </div>

              {displayModal &&
                <div className={`Modal Intervenants__modal-box ${displayModal ? "Show" : ""}`}>
                  <div className="video__details__mobile-layout-close">
                    <ImCross
                      size={20}
                      className="description__playlists--exit modal__close"
                      onClick={() => closeModalBox()}
                    />
                  </div>

                  <SideBarRight
                    chapters={chapters}
                    highlights={highlights}
                    staticimg={staticimg}
                    translation={translation}
                    transcriptList={transcriptList}
                  />
                </div>
              }
              <div className={`Overlay ${displayModal ? "Show" : ""}`} onClick={() => closeModalBox()}></div>
              <div className="intervenants_speaker">
                {intervenant?.map((intervenant, index) => (
                  intervenant?.firstName !== undefined && intervenant?.lastName !== undefined && (
                    <ButtonWithPic
                      channel={channel}
                      src_icon={intervenant?.image || ""}
                      texte={(intervenant?.firstName || "") + " " + (intervenant?.lastName || "")}
                      intervenantId={intervenant?.id}
                      translation={translation}
                      following={following}
                    />
                  )
                ))}
              </div>
              {isAuthenticated ? (
              <Link to={`/channel/${channel.id}`} className={(channel.id) ? "content_owner-wrapper content_owner-wrapper-mobile-view" : (channel.id ? "content_owner-wrapper_empty" : "")}>

                {(channel.id) && (
                  <>
                    <div className="content_owner-avatar-container">
                      <Avatar
                        alt="userName"
                        aria-haspopup="true"
                      />
                    </div>
                    <div>
                      <p>{channel?.name}</p>
                      <p>{translation.Content_Owner}</p>
                    </div>
                  </>
                )}
              </Link>
              ) : (
                <span className={(channel.id) ? "content_owner-wrapper content_owner-wrapper-mobile-view" : (channel.id ? "content_owner-wrapper_empty" : "")}> 
                  {(channel.id) && (
                    <>
                      <div className="content_owner-avatar-container">
                        <Avatar
                          alt="userName"
                          aria-haspopup="true"
                        />
                      </div>
                      <div>
                        <p>{channel?.name}</p>
                        <p>{translation.Content_Owner}</p>
                      </div>
                    </>
                  )}
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(Intervenants);
