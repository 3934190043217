//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                              *
 *                                              * Admin KPI Dashboard Page *                                     *
 * ?                      An admin user can see all the KPI, this page is where they will find them              *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import VideocamIcon from '@mui/icons-material/Videocam';
import ChatIcon from '@mui/icons-material/Chat';
import SourceIcon from '@mui/icons-material/Source';
import PaidIcon from '@mui/icons-material/Paid';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GenericSearchSelect from 'react-select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from "moment";
import { DateRangePicker } from 'rsuite';
import LinearProgress from "@mui/material/LinearProgress";
import Cookies from "js-cookie";

import requests from "../components/axios/Requests";
import { getCompanies } from "../servicesRedux/AdminService";
import { getTokenByID } from "../servicesRedux/AuthentificationService";
import Constants from "../components/constants/Constants"

import HomePageComponent from "../components/dashBoardKPIComponents/HomePageComponent";
import RegisteredUsersComponent from "../components/dashBoardKPIComponents/RegisteredUsersComponent";
import ActiveUsersComponent from "../components/dashBoardKPIComponents/ActiveUsersComponent";
import VideosViewedComponent from "../components/dashBoardKPIComponents/VideosViewedComponent";
import VideosInMinutesComponent from "../components/dashBoardKPIComponents/VideosInMinutesComponent";
import VideosCompletedComponent from "../components/dashBoardKPIComponents/VideosCompletedComponent"

import "../theme/dashboard/dashboard.scss";
import "../theme/dashboard/kpi-dashboard.scss";

const DashboardReporting = () => {
    const todaysDate = new Date();
    const { translation } = useSelector(state => state.languageReducer);
    const [currentlySelectedInternalSection, setCurrentlySelectedInternalSection] = useState("homepage");
    const [openFirstMenuBlock, setOpenFirstMenuBlock] = useState(false);
    const [openSecondMenuBlock, setOpenSecondMenuBlock] = useState(false);
    const [openThirdMenuBlock, setOpenThirdMenuBlock] = useState(false);
    const [openFourthMenuBlock, setOpenFourthMenuBlock] = useState(false);
    const [kpiDashboardComponent, setKpiDashboardComponent] = useState(null);
    const [selectedFilteringEventState, setSelectedFilteringEventState] = useState("Personalized");
    const [startDate, setStartDate] = useState(`${moment(new Date()).format('MM-DD-YYYY')} ${Constants.TimeConstraints.startingSlot}`);
    const [endDate, setEndDate] = useState(`${moment(new Date()).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`);
    const [endDateTemp, setEndDateTemp] = useState(`${moment(new Date()).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`);
    const [startDateTemp, setStartDateTemp] = useState(`${moment(new Date()).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`);
    const [companies, setCompanies] = useState([]);        
    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedCompanyAsObj, setSelectedCompanyAsObj] = useState({});
    const [selectedDateRange, setSelectedDateRange] = useState("Personalized");
    const [changedTheDateSelectionView, setChangedTheDateSelectionView] = useState(false);
    const [selectedComponentForMoreDetails, setSelectedComponentForMoreDetails] = useState("");
    const [changeOfMonthlySelectionEndPoint, setChangeOfMonthlySelectionEndPoint] = useState(false);
    const { afterToday } = DateRangePicker;    
    const [isLoading, setIsLoading] = useState(false);
    const roles = Cookies.get("roles") && JSON.parse(Cookies.get("roles"));
    
    useEffect(() => {
        let firstDayForLat30 = new Date();
        let endDayForLat30 = new Date();
        const lastThirtyDaysStartDate = firstDayForLat30.getDate() - 30;
        firstDayForLat30.setDate(lastThirtyDaysStartDate);
        setStartDate(`${moment(firstDayForLat30).format('MM-DD-YYYY')} ${Constants.TimeConstraints.startingSlot}`);
        
        const lastThirtyDaysEndDate = endDayForLat30.getDate();
        endDayForLat30.setDate(lastThirtyDaysEndDate);        
        setEndDate(`${moment(endDayForLat30).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`);
    
        getTokenByID(requests.GetMeInformation).then(item => {            
            getCompanies(requests.GetCompanies).then(items => {
                const allCompanies = items.map(c => {
                    return {
                        value: c.id,
                        label: c.label
                    }
                });
                if (item.roles && (item.roles.includes("Admin"))) {                
                    setCompanies([{
                        value: '',
                        label: 'All Customers'
                    }, ...allCompanies]);                    
                } else {
                    setCompanies([...companies, ...allCompanies]);
                    setSelectedCompany(items[0]?.id);            
                }                        
            });             
        });        
    }, []);

    useEffect(() => {
        if (selectedComponentForMoreDetails) setCurrentlySelectedInternalSection(selectedComponentForMoreDetails);
    }, [selectedComponentForMoreDetails]);

    const switchCaseToSetKPIComponent = () => {
        switch(currentlySelectedInternalSection) {
            case "homepage":
            default:
                setOpenFirstMenuBlock(false);
                setOpenSecondMenuBlock(false);
                setKpiDashboardComponent(<HomePageComponent 
                    startDate={startDate} 
                    endDate={endDate} 
                    selectedCompany={selectedCompany}
                    selectedDateRange={selectedDateRange}
                    selectedCompanyAsObj={selectedCompanyAsObj}
                    sendMoreDetailsComponentToContext={sendMoreDetailsComponentToParentState}
                    changeOfMonthlySelectionEndPoint={changeOfMonthlySelectionEndPoint}
                    setIsLoading={setIsLoading}
                />);            
                break; 
            case "homepageRegistered":
                setOpenFirstMenuBlock(true);
                setOpenSecondMenuBlock(false);
                setKpiDashboardComponent(<RegisteredUsersComponent 
                    startDate={startDate}
                    endDate={endDate} 
                    selectedCompany={selectedCompany}
                    selectedDateRange={selectedDateRange}
                    sendMoreDetailsComponentToContext={sendMoreDetailsComponentToParentState}
                    changeOfMonthlySelectionEndPoint={changeOfMonthlySelectionEndPoint}
                    setIsLoading={setIsLoading}
                />);
                break;
            case "homepageActive":
                setOpenFirstMenuBlock(true);
                setOpenSecondMenuBlock(false);
                setKpiDashboardComponent(<ActiveUsersComponent 
                    startDate={startDate} 
                    endDate={endDate} 
                    selectedCompany={selectedCompany}
                    selectedDateRange={selectedDateRange}
                    sendMoreDetailsComponentToContext={sendMoreDetailsComponentToParentState}
                    changeOfMonthlySelectionEndPoint={changeOfMonthlySelectionEndPoint}
                    setIsLoading={setIsLoading}
                />);
                break;              
            case "VideosViewed":
                setOpenFirstMenuBlock(false);
                setOpenSecondMenuBlock(true);
                setKpiDashboardComponent(<VideosViewedComponent 
                    startDate={startDate} 
                    endDate={endDate} 
                    selectedCompany={selectedCompany}
                    selectedDateRange={selectedDateRange}
                    sendMoreDetailsComponentToContext={sendMoreDetailsComponentToParentState}
                    changeOfMonthlySelectionEndPoint={changeOfMonthlySelectionEndPoint}
                    setIsLoading={setIsLoading}
                />);
                break;
            case "VideosInMinutes":
                setOpenFirstMenuBlock(false);
                setOpenSecondMenuBlock(true);
                setKpiDashboardComponent(<VideosInMinutesComponent 
                    startDate={startDate} 
                    endDate={endDate} 
                    selectedCompany={selectedCompany}
                    selectedDateRange={selectedDateRange}
                    sendMoreDetailsComponentToContext={sendMoreDetailsComponentToParentState}
                    changeOfMonthlySelectionEndPoint={changeOfMonthlySelectionEndPoint}
                    setIsLoading={setIsLoading}
                />);
                break;                
            case "VideosCompleted":
                setOpenFirstMenuBlock(false);
                setOpenSecondMenuBlock(true);
                setKpiDashboardComponent(<VideosCompletedComponent 
                    startDate={startDate} 
                    endDate={endDate} 
                    selectedCompany={selectedCompany}
                    selectedDateRange={selectedDateRange}
                    sendMoreDetailsComponentToContext={sendMoreDetailsComponentToParentState}
                    changeOfMonthlySelectionEndPoint={changeOfMonthlySelectionEndPoint}
                    setIsLoading={setIsLoading}
                />);
                break;                
        }
    };

    useEffect(() => {
        if ((roles.includes(Constants.Roles.MANAGER)) && (selectedCompany !== "")) {
            switchCaseToSetKPIComponent(); 
        } 
        
        if (!roles.includes(Constants.Roles.MANAGER)) {
            switchCaseToSetKPIComponent();
        } 
    }, [currentlySelectedInternalSection, startDate, endDate, selectedCompany]);

    const sendMoreDetailsComponentToParentState = compName => setSelectedComponentForMoreDetails(compName);

    const handleOpenFirstMenuBlock = () => setOpenFirstMenuBlock(!openFirstMenuBlock);

    const handleOpenInternalMenuBlock = section => setCurrentlySelectedInternalSection(section);

    const backToHomePage = () => {
        setCurrentlySelectedInternalSection("homepage");
        setSelectedDateRange("Today");
        setSelectedFilteringEventState("Today");
        setSelectedCompany("");
        setSelectedCompanyAsObj({});
        const firstDate = new Date();
        const todayWithUTCFormat = moment(firstDate).format('MM-DD-YYYY');
        setStartDate(`${todayWithUTCFormat} ${Constants.TimeConstraints.startingSlot}`);
        const otherDate = `${moment(firstDate).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`;
        setEndDate(otherDate);  
        setChangeOfMonthlySelectionEndPoint(false);                            
    };

    const handleOpenSecondMenuBlock = () => setOpenSecondMenuBlock(!openSecondMenuBlock);

    const handleOpenThirdMenuBlock = () => setOpenThirdMenuBlock(!openThirdMenuBlock);

    const handleOpenFourthMenuBlock = () => setOpenFourthMenuBlock(!openFourthMenuBlock);

    const handleOpenFifthMenuBlock = () => {};

    const menuCloseAndTriggerEvent = (popupState, text) => {
        let firstDate = null;
        let otherDate = null;
        popupState.close();
        setSelectedDateRange(text);
        switch(text) {
            case "Today":                
                firstDate = new Date();
                const todayWithUTCFormat = moment(firstDate).format('MM-DD-YYYY');
                setStartDate(`${todayWithUTCFormat} ${Constants.TimeConstraints.startingSlot}`);

                otherDate = `${moment(firstDate).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`;
                setEndDate(otherDate);  

                setChangeOfMonthlySelectionEndPoint(false);
                break;                
            case "Yesterday":
                firstDate = new Date();
                const modifiedStartDateForYesterdayOption = firstDate.getDate() - 1;
                firstDate.setDate(modifiedStartDateForYesterdayOption);
                setStartDate(`${moment(firstDate).format('MM-DD-YYYY')} ${Constants.TimeConstraints.startingSlot}`);

                otherDate = new Date();                
                const modifiedOtherDateForYesterdayOption = otherDate.getDate() - 1;
                otherDate.setDate(modifiedOtherDateForYesterdayOption);                
                setEndDate(`${moment(otherDate).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`);

                setChangeOfMonthlySelectionEndPoint(false);
                break;                                
            case "Last 7 days":
                firstDate = new Date();
                const modifiedStartDateForLast7 = firstDate.getDate() - 7;
                firstDate.setDate(modifiedStartDateForLast7);
                setStartDate(`${moment(firstDate).format('MM-DD-YYYY')} ${Constants.TimeConstraints.startingSlot}`);

                otherDate = new Date();                
                const modifiedOtherDateForLast7 = otherDate.getDate() - 1;
                otherDate.setDate(modifiedOtherDateForLast7);                
                setEndDate(`${moment(otherDate).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`);

                setChangeOfMonthlySelectionEndPoint(false);
                break;                                                
            case "This month":                
                firstDate = new Date();
                const firstDayForThisMonth = new Date(firstDate.getFullYear(), firstDate.getMonth(), 1);
                setStartDate(`${moment(firstDayForThisMonth).format('MM-DD-YYYY')} ${Constants.TimeConstraints.startingSlot}`);                

                otherDate = new Date();    
                const otherDayForThisMonth = new Date(otherDate.getFullYear(), otherDate.getMonth(), otherDate.getDate());                                
                setEndDate(`${moment(otherDayForThisMonth).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`);

                setChangeOfMonthlySelectionEndPoint(false);  
                break; 
            case "Last month":
                firstDate = new Date();
                const firstDayForLastMonth = new Date(firstDate.getFullYear(), firstDate.getMonth() - 1, 1);
                setStartDate(`${moment(firstDayForLastMonth).format('MM-DD-YYYY')} ${Constants.TimeConstraints.startingSlot}`);                

                otherDate = new Date();    
                const otherDayForLastMonth = new Date(otherDate.getFullYear(), otherDate.getMonth(), 0);                                
                setEndDate(`${moment(otherDayForLastMonth).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`);

                setChangeOfMonthlySelectionEndPoint(false);  
                break;                                                                                
            case "Last 12 months":                                                
                firstDate = new Date();
                const firstDayForLast12months = new Date(firstDate.getFullYear() - 1, firstDate.getMonth(), 1);
                setStartDate(`${moment(firstDayForLast12months).format('MM-DD-YYYY')} ${Constants.TimeConstraints.startingSlot}`);                

                otherDate = new Date();    
                const otherDayForLast12months = new Date(otherDate.getFullYear(), otherDate.getMonth(), 0);                                
                setEndDate(`${moment(otherDayForLast12months).format('MM-DD-YYYY')} ${Constants.TimeConstraints.endingSlot}`);

                setChangeOfMonthlySelectionEndPoint(true);
                break;                               
            case "Personalized":
                setChangeOfMonthlySelectionEndPoint(false);
                setChangedTheDateSelectionView(true);
                break;                                                                                                                                             
        }
        setSelectedFilteringEventState(text);
    };

    const handleChangeCustomer = event => {
        setSelectedCompany(event.value);
        setSelectedCompanyAsObj(event);
    };

    const handleClickCloseDateSelectionView = () => {
        setChangedTheDateSelectionView(false);
        if (changeOfMonthlySelectionEndPoint) setChangeOfMonthlySelectionEndPoint(false);
    };

    const submitDateRangeForAnalytics = () => {
        const firstDayWithUTCFormat = moment(startDateTemp).utc().format('MM-DD-YYYY');
        const secondDayWithUTCFormat = moment(endDateTemp).utc().format('MM-DD-YYYY');
        setStartDate(firstDayWithUTCFormat);
        setEndDate(secondDayWithUTCFormat);
        setChangedTheDateSelectionView(false);
    };

    const updateTheDateRange = update => {
        if (update) {
            const [start, end] = update;
            if (start === end) {
                let tempStart = start;
                let tempEnd = end;
                setStartDateTemp(tempStart.setUTCHours(0, 0, 1));
                setEndDateTemp(tempEnd.setUTCHours(11, 59, 59));    
            } else {
                setStartDateTemp(start);
                setEndDateTemp(end);    
            }
        } else {
            setStartDateTemp(new Date());
            setEndDateTemp(new Date(todaysDate - 1));  
        }
    };

    const dateRangeChangeHandler = () => {
        setStartDate(null);
        setEndDate(null);
    };

    return (
        <>
            <div className="dashboard__reporting_wrapper">  
                <div className="dashboard__left_panel dashboard__left_panel-overriden">
                    <nav aria-label="secondary mailbox folders">                        
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader className="home__link" onClick={() => backToHomePage()} component="p" id="nested-list-subheader">                                    
                                    {"Top Cream Analytics"}
                                </ListSubheader>
                            }>
                            <ListItemButton onClick={handleOpenFirstMenuBlock}>
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                                {openFirstMenuBlock ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openFirstMenuBlock} timeout="auto">
                                <List component="div" disablePadding>
                                    <ListItemButton 
                                        selected={currentlySelectedInternalSection === "homepageRegistered"} 
                                        onClick={() => handleOpenInternalMenuBlock("homepageRegistered")} 
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemText primary="Registered" />
                                    </ListItemButton>
                                    <ListItemButton 
                                        selected={currentlySelectedInternalSection === "homepageActive"} 
                                        onClick={() => handleOpenInternalMenuBlock("homepageActive")} 
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemText primary="Active" />
                                    </ListItemButton>
                                    <ListItemButton disabled sx={{ pl: 4 }}>
                                        <ListItemText primary="Retention Rate" />
                                    </ListItemButton>
                                    <ListItemButton disabled sx={{ pl: 4 }}>
                                        <ListItemText primary="Demographics" />
                                    </ListItemButton>
                                    <ListItemButton disabled sx={{ pl: 4 }}>
                                        <ListItemText primary="Signed up for trials" />
                                    </ListItemButton>
                                    <ListItemButton disabled sx={{ pl: 4 }}>
                                        <ListItemText primary="Login & never logged" />
                                    </ListItemButton>
                                </List>
                            </Collapse>

                            <ListItemButton onClick={handleOpenSecondMenuBlock}>
                                <ListItemIcon>
                                    <VideocamIcon />
                                </ListItemIcon>
                                <ListItemText primary="Video consumption" />
                                {openSecondMenuBlock ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openSecondMenuBlock} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton 
                                        selected={currentlySelectedInternalSection === "VideosViewed"} 
                                        onClick={() => handleOpenInternalMenuBlock("VideosViewed")} 
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemText primary="Video launched" />
                                    </ListItemButton>
                                    <ListItemButton 
                                        selected={currentlySelectedInternalSection === "VideosInMinutes"} 
                                        onClick={() => handleOpenInternalMenuBlock("VideosInMinutes")} 
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemText primary="Watch time" />
                                    </ListItemButton>
                                    <ListItemButton 
                                        selected={currentlySelectedInternalSection === "VideosCompleted"} 
                                        onClick={() => handleOpenInternalMenuBlock("VideosCompleted")} 
                                        sx={{ pl: 4 }}
                                    >
                                        <ListItemText primary="Completion Rate" />
                                    </ListItemButton>
                                    <ListItemButton disabled sx={{ pl: 4 }}>
                                        <ListItemText primary="Popularity(shared, views)" />
                                    </ListItemButton>
                                </List>
                            </Collapse>

                            <ListItemButton disabled onClick={handleOpenThirdMenuBlock}>
                                <ListItemIcon>
                                    <ChatIcon />
                                </ListItemIcon>
                                <ListItemText primary="Engagement" />
                                {openThirdMenuBlock ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openThirdMenuBlock} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="Interaction" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="Following speakers" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="CTR" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="Conversion rate" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="Engagement" />
                                    </ListItemButton>
                                </List>
                            </Collapse>

                            <ListItemButton disabled onClick={handleOpenFourthMenuBlock}>
                                <ListItemIcon>
                                    <SourceIcon />
                                </ListItemIcon>
                                <ListItemText primary="Content" />
                                {openFourthMenuBlock ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={openFourthMenuBlock} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="Interaction" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="Following speakers" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="CTR" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="Conversion rate" />
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemText primary="Engagement" />
                                    </ListItemButton>
                                </List>
                            </Collapse>

                            <ListItemButton disabled onClick={handleOpenFifthMenuBlock}>
                                <ListItemIcon>
                                    <PaidIcon />
                                </ListItemIcon>
                                <ListItemText primary="Revenue" />
                            </ListItemButton>
                        </List>
                    </nav>                                        
                </div>
                <div className="dashboard__right_panel">
                    <div className="display__loader">
                        {isLoading && <LinearProgress color="inherit" className="progress_bar" />}
                    </div>                                       
                    <Box 
                        sx={{ width: '100%', typography: 'body1' }} 
                        className="home__page-charts-row" 
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <Box 
                            sx={{ width: '25%', typography: 'body1' }} 
                            className="home__page-charts-row home__page-charts-row-extended" 
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                        >
                            <Typography variant="h6" component="div" style={{ fontWeight: 400 }}>
                                {currentlySelectedInternalSection === "homepage" ? "Top Cream Analytics " + translation.SideBar_Home + " Dashboard" : "Top Cream Analytics"}
                            </Typography>                        
                        </Box> 
                        <Box 
                            sx={{ width: '75%', typography: 'body1' }} 
                            className="home__page-charts-row" 
                            style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '1%' }}
                        >
                            <GenericSearchSelect 
                                options={companies} 
                                className="other__drop-downs"
                                placeholder={translation.customer}
                                value={companies.length === 1 ? companies[0] : companies.filter(s => s.value === selectedCompany ? s : null)}
                                onChange={handleChangeCustomer}
                            />                   
                            <PopupState variant="popover" popupId="demo-popup-menu" style={{ marginRight: 50, color: '#000' }}>
                                {popupState => (
                                    <>
                                        <Button variant="text" {...bindTrigger(popupState)} style={{ color: '#000' }}>
                                            {selectedFilteringEventState}
                                            <ArrowDropDownIcon style={{ position: 'relative', top: '-2px' }} fontSize="small" />
                                        </Button>
                                        <Menu {...bindMenu(popupState)}>
                                            <MenuItem onClick={() => menuCloseAndTriggerEvent(popupState, 'Today')}>Today</MenuItem>
                                            <MenuItem onClick={() => menuCloseAndTriggerEvent(popupState, 'Yesterday')}>Yesterday</MenuItem>
                                            <MenuItem onClick={() => menuCloseAndTriggerEvent(popupState, 'Last 7 days')}>Last 7 days</MenuItem>
                                            <MenuItem onClick={() => menuCloseAndTriggerEvent(popupState, 'This month')}>This month</MenuItem>
                                            <MenuItem onClick={() => menuCloseAndTriggerEvent(popupState, 'Last month')}>Last month</MenuItem>
                                            <MenuItem onClick={() => menuCloseAndTriggerEvent(popupState, 'Last 12 months')}>Last 12 months</MenuItem>
                                            <MenuItem onClick={() => menuCloseAndTriggerEvent(popupState, 'Personalized')}>Personalized</MenuItem>
                                        </Menu>
                                    </>
                                )}
                            </PopupState>                        
                        </Box>                          
                    </Box>                                                       
                    {kpiDashboardComponent}
                </div>
            </div>

            <Dialog open={changedTheDateSelectionView}>
                <DialogTitle>{'Date range'}</DialogTitle>
                <DialogContent style={{ minWidth: 255, width: "100%", minHeight: 200 }}>
                    <DateRangePicker
                        appearance="default" 
                        format="yyyy-MM-dd"
                        placeholder={'Select date'}
                        placement={"bottomStart"}
                        style={{ width: 200, marginRight: 25, backgroundColor: "#f8f7f6" }}
                        onChange={update => updateTheDateRange(update)}
                        onClean={dateRangeChangeHandler} 
                        disabledDate={afterToday()}
                        value={[new Date(startDateTemp), new Date(endDateTemp)]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCloseDateSelectionView}>{translation.Cancel}</Button>
                    <Button onClick={submitDateRangeForAnalytics} className="default__reset-btn">{translation.Suggestion_Send_Button}</Button>                                                
                </DialogActions>
            </Dialog>                          
        </>
    );
};

export default DashboardReporting;
