import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import BookmarkIcon from "@mui/icons-material/Bookmark";

import requests from "../axios/Requests";

import { GetWatchLaterCountAction } from "../../redux/action/GlobalActions.js";
import {
  deleteContentFromPlaylist,
  postContentIntoPlaylist,
} from "../../servicesRedux/PlaylistService";

const WatchLaterButton = props => {
  const { item } = props;
  const dispatch = useDispatch();
  const [isSavedState, setIsSavedState] = useState(item?.isSaved);
  const { watchLaterCount } = useSelector(state => state.globalReducer);
  const { allPlayLists } = useSelector(state => state.playlistReducer);
  
  const handleSave = async (e, idVideo, typePlayList, contentId) => {
    e.stopPropagation();
    if (isSavedState === false) {
      try {
        postContentIntoPlaylist(
          requests.PostContentIntoPlaylist,
          allPlayLists?.data?.find((p) => p.type === 2)?.id,
          item?.id ?? item?.contentId
        ).then(res => {
          setIsSavedState(true);
          dispatch(GetWatchLaterCountAction(watchLaterCount + 1));
        });
      } catch (error) {
        console.error(error.message);
      }
    } else if (isSavedState === true) {
      try {
        deleteContentFromPlaylist(
          requests.DeleteContentFromPlaylist,
          allPlayLists?.data?.find(p => p.type === 2)?.id,
          item?.id ?? item?.contentId
        ).then((res) => {
          setIsSavedState(false);
          dispatch(GetWatchLaterCountAction(watchLaterCount - 1));
        });
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  return (
    <Button
      variant="outlined"
      className={!isSavedState ? "description__recommend__icon" : "description__recommend__icon__not__active"}
      size="small"
      onClick={e => handleSave(e, item?.catalogItemId, {}, item?.contentId)}
    ><BookmarkIcon /></Button>
  );
}

export default WatchLaterButton;