import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IoMdAddCircle } from "react-icons/io";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@material-ui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import { AlertContainer } from "../alertContainer";

import PlaylistAddIcon from "../../assets/images/playlist-icon-add.svg";

export const CreatePlaylistInputModal = props => {
    const [open, setOpen] = useState(false);
    const [playlistName, setPlaylistName] = useState("");
    const [isError, setIsError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { translation } = useSelector((state) => state.languageReducer);
  
    const MyButton = styled(Button)({
        background: "#ccc",
        border: 0,
        color: "white",
        color: "black",
        "&:hover": {
          backgroundColor: "#b6aa90",
          color: "white",
        },
    });

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };    

    const handleOpen = () => setOpen(true);
  
    const handleClose = () => {
      setOpen(false);
      setIsError("");
    };
  
    const handleChange = event => {
      setPlaylistName(event.target.value);
      setIsError("");
    };
  
    const handleSubmitPlaylist = async () => {
      setOpen(true);
      await props.addPlaylist(playlistName, setIsError, setIsLoading);
      setOpen(false);
    };
  
    const handleKeyDown = event => {
      if (event.key === "Enter") {
        handleSubmitPlaylist();
      }
    };
  
    return (
      <div className="playlist_new_container">
        <div className="playlists_page_new_playlist">
          <img src={PlaylistAddIcon} className="playlist_edit_icon" onClick={handleOpen} />
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: 400 }}>
            <h2 id="parent-modal-title">
              {translation.Playlist_Creation_Request}
            </h2>
            <br />
            <TextField
              required
              id="outlined-basic"
              variant="outlined"
              size="small"
              placeholder={translation.Playlist_Name_Placeholder}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
              inputProps={{ maxLength: 40 }}
            />
  
            <br />
            <br />
  
            {isLoading ? (
              <LinearProgress color="inherit" className="progress_bar" />
            ) : (
              <MyButton onClick={handleSubmitPlaylist} fullWidth>
                {translation.Create}
              </MyButton>
            )}
            {isError && <AlertContainer errorMessage={isError} />}
          </Box>
        </Modal>
      </div>
    );
  };