import * as types from "../constant";

export function GetTokenByIdUser(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_USERTOKENBYID, payload });
  };
}

export function postFirstTimeLoginAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_FIRST_TIME_LOGIN_RESPONSE, payload });
  };
}

// Called when the user logout to destroy all the store's data
export function destroySession(payload) {
  return async (dispatch) => {
    dispatch({ type: types.DESTROY_SESSION, payload });
  };
}
