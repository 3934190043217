// GetUserConst //

export const GET_USERTOKENBYID = "GET_USERTOKENBYID";

// Homepage //

export const GET_BANNER = "GET_BANNER";

export const GET_ROW = "GET_ROW";

// Login/Redirect //

export const REDIRECT_URL = "REDIRECT_URL";
// Global //

export const GET_SIDEBAR = "GET_SIDEBAR";
export const GET_ISSAVED = "GET_ISSAVED";
export const GET_NAVBAR = "GET_NAVBAR";
export const GET_NAVBARFILTER = "GET_NAVBARFILTER";
export const GET_USERINTERESTS = "GET_USERINTERESTS";
export const GET_INTERESTS = "GET_INTERESTS";
export const GET_WATCHLATER = "GET_WATCHLATER";
export const GET_WATCHLATERCOUNT = "GET_WATCHLATERCOUNT";
export const GET_CATEGORY_TYPE = "GET_CATEGORY_TYPE";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_RECOMMENDATION_COUNT = "GET_RECOMMENDATION_COUNT";
export const UPDATE_RECOMMENDATION_COUNT = "UPDATE_RECOMMENDATION_COUNT";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const SET_PROGRESS_PERCENT = "SET_PROGRESS_PERCENT";
export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const SET_OPEN_PERSONALIZED_DIALOG = "SET_OPEN_PERSONALIZED_DIALOG";

// Admin //
export const GET_USER = "GET_USER";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const GET_FIRST_TIME_LOGIN_RESPONSE = "GET_FIRST_TIME_LOGIN_RESPONSE";
export const DESTROY_SESSION = "DESTROY_SESSION";

// Conference //
export const GET_CONFERENCE = "GET_CONFERENCE";
export const GET_CONFERENCE_BY_TAG = "GET_CONFERENCE_BY_TAG";
// export const GET_HIGHLIGHTS = "GET_HIGHLIGHTS";
// export const GET_CHAPTERS = "GET_CHAPTERS";

// Downloaded //
export const GET_DOWNLOADED = "GET_DOWNLOADED";
// Recommended //

export const GET_RECOMMENDED = "GET_RECOMMENDED";

// Search //
export const GET_SEARCH = "GET_SEARCH";
export const GET_ALLTAGS = "GET_ALLTAGS";
export const GET_ALL_SPEAKERS = "GET_ALL_SPEAKERS";
export const GET_ALL_CONTRIBUTORS = "GET_ALL_CONTRIBUTORS";
export const GET_SEARCHED_VALUE = "GET_SEARCHED_VALUE";
export const GET_TAGS = "GET_TAGS";

// Speaker //
export const GET_SPEAKER_TOTAL_ITEMS = "GET_SPEAKER_TOTAL_ITEMS";
//GET_SPEAKER_FROM_CONFERENCE
export const GET_SPEAKER_FROM_CONFERENCE = "GET_SPEAKER_FROM_CONFERENCE";
export const GET_SPEAKER_FROM_MY_SPEAKERS = "GET_SPEAKER_FROM_MY_SPEAKERS";
//Channel
export const GET_CONTENT = "GET_CONTENT";
export const GET_CHANNEL = "GET_CHANNEL";
export const GET_ALL_CHANNELS = "GET_ALL_CHANNELS";
export const GET_ALL_SOCIAL_MEDIA = "GET_ALL_SOCIAL_MEDIA";
export const GET_ALL_SOCIAL_MEDIA_SUCCESS = "GET_ALL_SOCIAL_MEDIA_SUCCESS";

//Pagination
export const LOAD_NEXT = "LOAD_NEXT";
export const HAS_LOADED = "HAS_LOADED";
export const IS_ON_SCREEN = "IS_ON_SCREEN";

//Comments
export const GET_COMMENTS = "GET_COMMENTS";
export const DELETE_COMMENT_BY_ID = "DELETE_COMMENT_BY_ID";
export const UPDATE_COMMENTS = "UPDATE_COMMENTS";
export const ADD_COMMENTS = "ADD_COMMENTS";
export const ADD_REPLY = "ADD_REPLY";
export const GET_ALL_REPLIES_BY_ID = "GET_ALL_REPLIES_BY_ID";
export const DELETE_REPLY_BY_ID = "DELETE_REPLY_BY_ID";
export const ADD_CHILD_COUNT_COMMENT = "ADD_CHILD_COUNT_COMMENT";
export const REMOVE_CHILD_COUNT_COMMENT = "REMOVE_CHILD_COUNT_COMMENT";
export const NEW_REPLY_STATE = "NEW_REPLY_STATE";

//Video
export const GET_VIDEO_CURRENTTIME = "GET_VIDEO_CURRENTTIME";

//Language
export const SET_LANGUAGE = "SET_LANGUAGE";
export const GET_TRANSLATION = "GET_TRANSLATION";
export const GET_TYPE_TRANSLATION = "GET_TYPE_TRANSLATION";

//Related Video
export const RELATED_VIDEO_TAGS = "RELATED_VIDEO_TAGS";
export const SEARCH_RELATED = "SEARCH_RELATED";
//Subscription
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
//Curation
export const GET_NEW_CONTENT = "GET_NEW_CONTENT";

//Playlist
export const GET_REMOVE_CONTENT_ID = "GET_REMOVE_CONTENT_ID";
export const GET_EDITING_PLAYLIST = "GET_EDITING_PLAYLIST";
export const GET_UPDATED_PLAYLIST_ITEM = "GET_UPDATED_PLAYLIST_ITEM";
export const GET_ALL_PLAYLISTS = "GET_ALL_PLAYLISTS";
export const LOADING_STATE_PLAYLISTS = "LOADING_STATE_PLAYLISTS";
export const IS_SUBSCRIBED_STATE = "IS_SUBSCRIBED_STATE";
export const IS_LIKED_STATE = "IS_LIKED_STATE";
export const DISPATCHED_PLAYLIST = "DISPATCHED_PLAYLIST";
export const LIKES_COUNT = "LIKES_COUNT";

//ViewAll Page
export const GET_VIEWALL = "GET_VIEWALL";

export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LANGUAGES_FOR_SEARCH = "GET_LANGUAGES_FOR_SEARCH";

//MediaType
export const LOADING_STATES_HOMEPAGE = "LOADING_STATES_HOMEPAGE";
export const LOADING_STATES_SUBSCRIPTIONS = "LOADING_STATES_SUBSCRIPTIONS";

//Category Filter
export const GET_CATEGORY_FILTER = "GET_CATEGORY_FILTER";

//Tenant
export const GET_TENANT = "GET_TENANT";

//Offline Download
export const CONFERENCE_TO_DOWNLOAD = "CONFERENCE_TO_DOWNLOAD";
//ExploreAI
export const SET_IS_SELECTING = "SET_IS_SELECTING";
export const SET_SELECTED_CONTENTS = "SET_SELECTED_CONTENTS";
export const SET_RUN_AI_COMPARISON = "SET_RUN_AI_COMPARISON";
export const SET_AI_RESULTS = "SET_AI_RESULTS";

export const SET_IS_GENERATING = "SET_IS_GENERATING";

export const CAN_RUN_COMPARISON = "CAN_RUN_COMPARISON";
