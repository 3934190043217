import * as React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { GetNewContentAction } from "../../redux/action/GlobalActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const labels = {
  0: "",
  1: "Should be rejected",

  2: "These talks may be ok but they need to be edited, opening needs to be cut out, or they need to start further in to the talk",

  3: "Not a great talk",

  4: "Not best of breed but acceptable and can default to these talks",

  5: "Best of breed talk for the category. Ideally these would be the only talks we use",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function RatingStars(props) {
  const { defaultValue } = props;
  const [value, setValue] = React.useState(defaultValue ?? 0);
  const [hover, setHover] = React.useState(-1);
  const { newContent } = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();
  const dispatchValue = (val) => {
    if (!val) return;

    dispatch(
      GetNewContentAction({
        ...newContent,
        contentRating: val,
      })
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        name="hover-feedback"
        value={value}
        precision={1}
        disabled={newContent.isTopCreamContent}
        getLabelText={getLabelText}
        sx={{
          fontSize: 24,
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
          dispatchValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55, fontSize: 32 }} />}
        icon={<StarIcon style={{ fontSize: 32 }} />}
      />

      {value !== null && (
        <Box sx={{ ml: 5 }}>{labels[hover !== -1 ? hover : value]}</Box>
      )}
    </Box>
  );
}
