import * as types from "../constant/index";

const initialState = {
  isSelecting: false,
  selectedContents: [],
  runAIcomparison: false,
  canRunComparison: false,
  isGenerating: false,
  aiResult : ""
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_IS_SELECTING:
      return { ...state, isSelecting: action.payload };
    case types.SET_SELECTED_CONTENTS:
      return { ...state, selectedContents: action.payload };
    case types.SET_RUN_AI_COMPARISON:
      return { ...state, runAIcomparison: action.payload };
    case types.CAN_RUN_COMPARISON:
      return { ...state, canRunComparison: action.payload };
    case types.SET_IS_GENERATING:
      return { ...state, isGenerating: action.payload };
    case types.SET_AI_RESULTS:
      return { ...state, aiResult: action.payload };
    default:
      return state;
  }
}