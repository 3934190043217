import * as types from "../constant/index";

export default function reducer(
    state = {
        redirectURL: "",
    },
    action
    ) {
    switch (action.type) {
        case types.REDIRECT_URL:
            return {
                ...state,
                redirectURL: action.payload,
            };

        default:
        return state;
    }
}
