import React, { useEffect, useRef } from "react";

export default function PaginatorNoRedux(props) {

  const { setHasLoaded, setIsOnScreen, hasLoaded } = props;
  const bottom = useRef(false);
  const bottomDivRef = React.createRef();

  let observerBottom = useRef(null);
  useEffect(() => {
    observerBottom.current = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          bottom.current = true;
          setHasLoaded(true);
          setIsOnScreen(true);
        } else {
          bottom.current = false;
          setHasLoaded(false);
          setIsOnScreen(false);
        }
      },
      { threshold: 0.9 }
    );
  }, []);

  useEffect(() => {
    hasLoaded > 8 && observerBottom.current.observe(bottomDivRef.current);
  }, [hasLoaded]);

  return <div ref={bottomDivRef}><br/></div>;
}
