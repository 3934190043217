//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Video component *                                             *
 * ?                      A simple component to render video it's used for the conference page                  *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useEffect, useRef, useState, memo } from "react";
import requests from "../axios/Requests";
import { postWatchHistory, patchWatchHistory, getWatchHistoryByContentId } from "../../servicesRedux/ConferenceService";

import Control from "../../components/video/Control";
import PlayIcon from "./PlayIcon";
import AzureMP from "./AzureComponent/mp-component.jsx";
import DailymotionPlayer from "./DailymotionPlayer.jsx";
import "./Video.scss";

const Video = memo(videoProps => {
  const azureRef = useRef();
  const hasLoadedRef = useRef(false);
  const watchHistoryIdRef = useRef(null);
  const watchCursorTimeRef = useRef(null);
  const setWatchHistoryRef = data => {
    watchHistoryIdRef.current = data;
  };
  const triggerAmountRef = useRef(0);
  const currentTimeRef = useRef(0);
  const currentTimeDiffRef = useRef(0);
  const hasSeekedRef = useRef(false);
  const [isDailymotion, setIsdailymotion] = useState(videoProps.props.isDailyMotion)
  const toSeconds = time => {
    if (time.length < 8) {
      return time;
    }
    const parts = time.split(':');
    return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
  };

  const playbackSpeedParams = {
    enabled: true,
    initialSpeed: 1.0,
    speedLevels: [
      { name: "x3.0", value: 3.0 },
      { name: "x2.0", value: 2.0 },
      { name: "x1.75", value: 1.75 },
      { name: "x1.5", value: 1.5 },
      { name: "x1.25", value: 1.25 },
      { name: "normal", value: 1.0 },
      { name: "x0.75", value: 0.75 },
      { name: "x0.5", value: 0.5 },
      { name: "x0.25", value: 0.25 },
    ],
  };

  useEffect(() => {
    setIsdailymotion(videoProps.props.isDailyMotion);
  }, [videoProps])

  const toTime = second => {
    let hours = Math.floor(second / 3600);
    let minutes = Math.floor((second - (hours * 3600)) / 60);
    let seconds = second - (hours * 3600) - (minutes * 60);
    seconds = Number(seconds.toPrecision(4));
    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    if (hours < 0 || minutes < 0 || seconds < 0 || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return "00:00:00";
    }
    return hours + ':' + minutes + ':' + seconds;
  };

  useEffect(() => {
    if (videoProps?.calledComponent) videoProps.setVideoHasLoaded(true);
  }, [videoProps.calledComponent]);

  useEffect(() => {
    if (videoProps?.props?.videoURL) {
      if (videoProps?.props?.videoURL.endsWith('.mp3')) {
        console.log('videoProps?.props?.videoURL', videoProps?.props?.videoURL)
        // Add the custom class to the .vjs-tech element
        const vjsTechElement = document.querySelector('.azuremediaplayer .vjs-tech');
        if (vjsTechElement) {
          vjsTechElement.classList.add('audio_background'); // Replace 'your-custom-class' with your class name

          // Set the background image URL dynamically
          const thumbnailURL = videoProps?.props?.thumbnailURL;
          vjsTechElement.style.backgroundImage = `url('${thumbnailURL}')`;
        }
      }
    }
  }, [currentTimeRef]);

  return (
    <div>
      <Control player={azureRef} />
      {videoProps.props !== "" ? (
        <div className="video__play" 
        style={{
          marginTop: '13px', // Add margin-top for iOS devices
        }}>
          {/* <PlayIcon
            displayLogo={true}
            azureRef={azureRef}
          /> */}

          <div className="video">
            {/*{player&&<RatingPopup player={player}/>}*/}
            {!isDailymotion ?
              <AzureMP
                skin="amp-flush"
                ref={azureRef}
                poster={videoProps?.props?.videoURL?.endsWith('.mp3') ? videoProps?.props?.thumbnailURL : ''}
                events={{
                  loadeddata: e => {
                    if (videoProps.props.contentId && !hasLoadedRef.current) {
                      hasLoadedRef.current = true;
                      const result = getWatchHistoryByContentId(requests.GetWatchHistoryByContent, videoProps.props.contentId);
                      result.then(res => {
                        azureRef.current.player.currentTime(toSeconds(res.lastCursorTime))
                        watchCursorTimeRef.current = res.lastCursorTime;
                        setWatchHistoryRef(res.id);
                        azureRef.current.player.play();
                      });
                    }
                  },
                  playing: _ => {
                    if (!hasSeekedRef.current) {
                      let result;
                      if (!watchHistoryIdRef.current && !watchCursorTimeRef.current ? true : false) {
                        result = postWatchHistory(requests.PostWatchHistory, videoProps.props.contentId, watchCursorTimeRef.current ?? "00:00:01");
                      }
                      currentTimeRef.current = azureRef?.current?.player?.currentTime();
                      currentTimeDiffRef.current = 0;
                      hasSeekedRef.current = false;
                      result?.then(res => setWatchHistoryRef(res.id));
                    }
                    if (videoProps?.props?.videoURL) {
                      if (videoProps?.props?.videoURL.endsWith('.mp3')) {
                        // Add the custom class to the .vjs-tech element
                        const vjsTechElement = document.querySelector('.azuremediaplayer .vjs-tech');
                        if (vjsTechElement) {
                          vjsTechElement.classList.add('audio_background'); // Replace 'your-custom-class' with your class name

                          // Set the background image URL dynamically
                          const thumbnailURL = videoProps.props.thumbnailURL;
                          vjsTechElement.style.backgroundImage = `url('${thumbnailURL}')`;
                        }
                      }
                    }
                  },
                  seeking: _ => {
                    hasSeekedRef.current = true;
                    currentTimeRef.current = azureRef?.current?.player?.currentTime();
                  },
                  seeked: _ => {
                    if (hasSeekedRef.current) {
                      hasSeekedRef.current = false;
                    }
                  },
                  timeupdate: _ => {
                    triggerAmountRef.current += 1;

                    if (triggerAmountRef.current === 40) {
                      triggerAmountRef.current = 0;
                      patchWatchHistory(requests.PatchWatchHistory, watchHistoryIdRef.current, toTime(azureRef?.current?.player?.currentTime() - currentTimeRef.current), toTime(azureRef?.current?.player?.currentTime()))
                      currentTimeRef.current = azureRef?.current?.player?.currentTime();
                      currentTimeDiffRef.current = 0;
                    }
                  },
                  ended: _ => {
                    patchWatchHistory(requests.PatchWatchHistory, watchHistoryIdRef.current, toTime(azureRef?.current?.player?.currentTime() - currentTimeRef.current), toTime(azureRef?.current?.player?.currentTime()))
                  }
                }}
                options={{ playbackSpeed: playbackSpeedParams, autoPlay: videoProps.props.autoPlay }}
                src={[
                  {
                    src: videoProps?.props?.videoURL ? videoProps?.props?.videoURL : "",
                    type: videoProps?.props?.videoURL ? (videoProps?.props?.videoURL.endsWith('.mp3') ? 'audio/mpeg' : 'application/vnd.ms-sstr+xml') : "",
                  },
                ]}
                tracks={[
                  {
                    kind: "captions",
                    src: videoProps?.props?.transcriptURL?.replace(
                      "/insights.json",
                      "/transcript.vtt"
                    ),
                    srcLang: "en",
                    label: "subtitles",
                  },
                ]}
              />
              :
              <DailymotionPlayer
                videoId={videoProps?.props?.videoURL}
                videoProps={videoProps}
                playerId="xsqvm"
                params={{
                  startTime: 0,
                  mute: false,
                }}
              />
            }
          </div>

        </div>
      ) : (
        <div>Video not found</div>
      )}
    </div>
  );
});

export default Video;
