import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import ChapterComponent from "./ChapterComponent";
import HighlightComponent from "./HighlightComponent";

import HeroContent from "./HeroContent";


export default function EditorialContent(props) {

    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                    m: 1,
                    width: "100%",
                    height: "100%",
                },
            }}
        >
            <Paper elevation={5} sx={{ padding: 2 }}>

                <Typography
                    sx={{ fontSize: 16, fontWeight: 800 }}
                    color="text.secondary"
                    gutterBottom
                >
                    Editorial choices
                </Typography>

                <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
                    <ChapterComponent />
                </Paper>
                <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
                    <HighlightComponent />
                </Paper>

                <HeroContent />
            </Paper>
        </Box>
    )
}