import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router';

import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';

import "./OnBoarding.scss"
import Grid from "@mui/material/Grid";
import logo from "../../assets/images/logo.png";

import CorpoInterestSelect from './CorpoInterestSelect';
import PersonalInterestSelect from './PersonalInterestSelect';
import SelectLanguage from './SelectLanguage';
import EnjoyTC from './EnjoyTC';
import requests from "../axios/Requests";

import { getMe } from "../../servicesRedux/AuthentificationService";
import { GetTokenByIdUser } from "../../redux/action/AuthentificationAction";
import { useDispatch, useSelector } from "react-redux";
import { patchProfile, patchOnBoardingStep, postOnBoardingDone } from "../../servicesRedux/GlobalService";

import Cookies from "js-cookie";

const OnBoarding = () => {
    const dispatch = useDispatch();
    const [profile, setProfile] = useState({});
    useEffect(() => {
        requestForMeInformation();
    }, []);

    const requestForMeInformation = () => {
        getMe(requests.GetMeInformation).then(item => {
            if (!Cookies.get("roles")) {
                Cookies.set("roles", JSON.stringify(item.roles));
            }
            Cookies.set("accountType", item.accountType);
            dispatch(GetTokenByIdUser(item));
            setProfile(item);
        });
    };

    return (
        <div className='corpo-onboarding-root'>
            <Grid container spacing={2} className="corpo-onboarding">
                <Grid item xs={12} md={6} lg={6} className="corpo-onboarding--background">
                    <div className='corpo-onboarding-content'>
                        <img className="corpo-onboarding-logo" src={logo} alt="logo" />

                        <OnboardingStepper profile={profile}/>

                    </div>
                </Grid>
            </Grid>
        </div>
    )

}

export default OnBoarding;


const OnboardingStepper = ({profile}) => {
    const dispatch = useDispatch();
    const { translation } = useSelector(state => state.languageReducer);
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [direction, setDirection] = useState('left');

    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [preferredLanguages, setPreferredLanguages] = useState([]);
    
    useEffect(() => {
        setActiveStep(profile?.onBoardingStep)
    }, [profile]);

    const handleNext = () => {
        setDirection('left');
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        patchOnBoardingStep(requests.PatchOnBoardingStep, activeStep + 1);
    };

    const handleBack = () => {
        setDirection('right');
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePatchProfile = () => {
        
        handleNext();

        const change = {
            userId: profile.userId,
            personId: profile.id,
            lastName: profile.lastName,
            firstName: profile.firstName,
            email: profile.email,
            phone: profile.phone,
            label: profile.label,
            preferredLanguageIds: preferredLanguages,
        };
        //parse tags, create an array only of tags id
        
        const userProfile = {
            userPersonRequest: change,
            interestIds: categories,
            tagIds: tags.map(tag => tag.id),
        };
    
        patchProfile(requests.PatchProfile, userProfile).then(item => {
            dispatch(GetTokenByIdUser(item));
        });
    }

    const handleDoneOnboarding = () => {
        postOnBoardingDone(requests.PostOnBoardingDone).then(_ => {
            navigate('/');
        });

    }

    return (
        <Box sx={{ overflow: 'hidden', width: '100%' }}>
            <div className='slider_content'>
                <Slide in={activeStep === 0} direction={direction}>
                    <div>
                        {activeStep === 0 && <CorpoInterestSelect translation={translation} categories={categories} setCategoriesCallback={setCategories} interestType={"corporate"} />}
                    </div>
                </Slide>
                <Slide in={activeStep === 1} direction={direction}>
                    <div>
                        {activeStep === 1 && <PersonalInterestSelect translation={translation} tags={tags} setTagList={setTags} />}
                    </div>
                </Slide>
                <Slide in={activeStep === 2} direction={direction}>
                    <div>
                        {activeStep === 2 && <SelectLanguage preferredLanguage={preferredLanguages} setPreferredLanguagesCallback={setPreferredLanguages}/>}
                    </div>
                </Slide>
                <Slide in={activeStep === 3} direction={direction}>
                    <div>
                        {activeStep === 3 && <EnjoyTC handleDoneOnboarding={handleDoneOnboarding}/>}
                    </div>
                </Slide>
            </div>
            <MobileStepper
                variant="dots"
                steps={4}
                position="bottom"
                activeStep={activeStep}
                className='stepper'
                sx={{
                    '.MuiMobileStepper-dot': {
                        backgroundColor: 'white',
                        border: '1px solid black',
                        borderRadius: '50%',
                    },
                    '.MuiMobileStepper-dotActive': {
                        backgroundColor: 'black',
                    },
                }}
                nextButton={
                    <Button
                        size="small"
                        onClick={activeStep === 3 ? handleDoneOnboarding : handlePatchProfile}
                        sx={{ backgroundColor: 'transparent', color: 'black', height: '40px' }}
                    >
                        {activeStep === 3 ? translation.Button_Get_Started : translation.Button_Next}
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        sx={{ backgroundColor: 'transparent', color: 'black', height: '40px' }}
                    >
                        {translation.Button_Back}
                    </Button>
                }
            />

        </Box>
    );
};