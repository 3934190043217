import axios from "axios";

import Constants from "../components/constants/Constants";

export function getAllSpeakers(requests, speakerValue) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${requests}${speakerValue !== undefined ? "?filter=" + speakerValue : ""}`, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getSpeakerById(requests, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(requests + id, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getAllSpeakersByDuration(request, mediaType, duarionType, preferredLanguages, limit, page) {
  let preferredLanguageString = '';
  if(Array.isArray(preferredLanguages) && preferredLanguages.length > 0) {
    for (const language of preferredLanguages) {
      preferredLanguageString += `&PreferredLanguageIds=${language}`;
    }
  }
  const mediaTypeString = mediaType ? mediaType.map(type => {
    const key = Object.keys(Constants.MediaType).find(k => k.toLowerCase() === type);
    return Constants.MediaType[key] || Constants.MediaType.NotFound;
  })
  .join('&MediaType=')
  : [];
  const queryStringmediaType = mediaTypeString !== [] ? (`MediaType=${mediaTypeString === '' ? 0 : mediaTypeString}`) : '';  
  request = request
            .replace("{MediaType}", queryStringmediaType)
            .replace("{duarionType}", duarionType)
            .replace("{PreferredLanguageIds}", preferredLanguageString)
            .replace("{limit}", limit)
            .replace("{page}", page);

  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getAllMySpeakersForManageView(request) {
  return new Promise((resolve, reject) => {
    axios
      .get(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteMySpeakersForManageView(request, id) {
  request = request.replace("{id}", id);  
  return new Promise((resolve, reject) => {
    axios
      .delete(request, { withCredentials: true })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}