import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import Box from '@mui/material/Box';
import moment from "moment";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import requests from "../axios/Requests";
import { getTheDataForReportingOnNumberOfRegisteredUsers } from "../../servicesRedux/AdminService";

import { getMonthName, fixDateString } from "../../hooks/utils";

import { styles } from "./DashboardComponentStyles";

const HomePageRegisteredComponent = props => {
    const { 
        classes, 
        startDate, 
        endDate, 
        selectedCompany, 
        selectedDateRange,
        changeOfMonthlySelectionEndPoint,
        setIsLoading
     } = props;    
    const [dataNumberOfRegisteredUsers, setDataNumberOfRegisteredUsers] = useState([]);
    const [dataNumberOfActualRegisteredUsers, setDataNumberOfActualRegisteredUsers] = useState([]);
    const optionsOnNumberOfRegisteredUsers = {
        title: `${(changeOfMonthlySelectionEndPoint ? "Monthly" : "Daily")} registered users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,
        hAxis: { title: (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), titleTextStyle: { color: "#333" }, minValue: 1 },
        vAxis: { title: 'Registered users', minValue: 1 },
        chartArea: { width: "50%", height: "70%" },
        colors: ['#498bc2'],
    };
    
    useEffect(() => {
        setIsLoading(true);
        getTheDataForReportingOnNumberOfRegisteredUsers((changeOfMonthlySelectionEndPoint ? requests.GetNoOfRegisteredUsersForThisMonth : requests.GetNoOfRegisteredUsers), startDate, endDate, selectedCompany).then(items => {            
            let tempUsers = [];
            tempUsers.push([
                (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), 
                `Nb of registered users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                { role: "style" }
            ]);
            if (changeOfMonthlySelectionEndPoint) {
                items.forEach(item => tempUsers.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.nbOfRegisteredUser, 
                    ""
                ]));
            } else {                
                items.forEach(item => tempUsers.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfRegisteredUser, 
                    ""
                ]));
            }            
            setDataNumberOfRegisteredUsers(tempUsers);
            setDataNumberOfActualRegisteredUsers(items.nbOfRegisteredUser);
            const regUsers = items.map(item => item.nbOfRegisteredUser);            
            setDataNumberOfActualRegisteredUsers(regUsers.reduce((a, b) => a + b, 0));
            setIsLoading(false);
        });  
    }, [startDate, endDate, selectedCompany, changeOfMonthlySelectionEndPoint]);

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1', display: 'flex', flexDirection: 'column' }} className="home__page-charts-row other__page-charts-row-extended">                
                <Paper 
                    className={classes.paper} 
                    sx={{ width: 175, overflow: 'hidden' }}
                >                    
                    <Card className={classes.card}>
                        <CardContent style={{ minHeight: 170, display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                            <Typography className={classes.title} color="textSecondary">{`Number of Registered users ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`}</Typography>                            
                            <Typography className={classes.subTitle} component="p">{dataNumberOfActualRegisteredUsers}</Typography>
                            <Typography className={classes.subTitle} component="p"></Typography>                                
                        </CardContent>
                    </Card>
                </Paper>            
                <Paper className={classes.tablePaper}>
                    {dataNumberOfRegisteredUsers.length > 0 &&
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="600px"
                        data={dataNumberOfRegisteredUsers}
                        options={optionsOnNumberOfRegisteredUsers}
                    />}                                                
                </Paper>
            </Box>           
        </>
    );
}

export default withStyles(styles)(HomePageRegisteredComponent);