// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * NestedListSpeakers page *                                       *
 * ?   NestedListSpeakers : user can choose mulitple-filters search for any conference contains in the database   *
 *                        TODO: Change the checkbox List    *
 */
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox } from "@mui/material";
import { MdOutlineSearch } from "react-icons/md";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import { ClickAwayListener } from "@material-ui/core";

import "./NestedLists.scss";

const styles = () => ({
  root: {
    width: "100%",
    maxWidth: 360,
    background: "#CCC",
    color: "black",
  },
  nested: {
    zIndex: "3",
  },
});

const NestedListSpeakers = props => {
  const { classes, choosenList, GetformData, comboID, checkInfo, includedInSearch, checkState, propogateSpeakersAPI } = props;
  const [open, setOpen] = useState(false);
  const initialFormData = Object.freeze([]);
  const [filterList, setFilterList] = useState(initialFormData);
  const [speakerList, setSpeakerList] = useState([]);
  const [mapState, setMapState] = useState(false);
  const { translation } = useSelector(state => state.languageReducer);
  const [currentlyuSearchingSpeakerName, setCurrentlyuSearchingSpeakerName] = useState("");

  useEffect(() => {
    GetformData(filterList, comboID);
  }, [filterList]);

  /* **************************************** Display/Hide ComboBox items ************************************************ */
  const showCheckBoxList = () => setOpen(!open);

  const onItemClick = id => {
    setMapState(true);
    checkExistance(id);
  };

  /* ************************************* Check value Existance into List ********************************************** */
  const checkExistance = id => {
    const temp = filterList.find(fl => fl === id);
    if (temp === undefined) {
      setFilterList([...filterList, id]);
    } else {
      setFilterList(filterList.filter(fl => fl !== id));
    }
    checkInfo(!checkState);
  };

  /* ************************************* Searching Speaker into combobox list ********************************************** */
  const onChangeSpeakerinput = e => {    
    setMapState(true);
    const searchedValue = e.target.value.toLowerCase();    
    if (searchedValue) {
      setCurrentlyuSearchingSpeakerName(searchedValue);
      const temp = choosenList.filter(f => (f.persons.firstName && f.persons.firstName.toLowerCase().includes(searchedValue)) || (f.persons.lastName && f.persons.lastName.toLowerCase().includes(searchedValue)));
      setSpeakerList(temp);        
    } else {
      setCurrentlyuSearchingSpeakerName("");
      setSpeakerList(choosenList);
    }
    propogateSpeakersAPI(searchedValue);
  };

  /* ************************************* Verifiy value checked into List ********************************************** */
  const getChecked = id => {
    if (id !== undefined && id !== null) {
      const temp = filterList.find(fl => fl === id);
      if (temp === undefined) {
        return false;
      } else {
        return true;
      }
    }
  };

  const customCheckBox = (val) => {
    return (
      val === true && (
        <div>
          <Input
            type="text"
            endAdornment={
              <InputAdornment position="end">
                <MdOutlineSearch size={20} style={{ marginRight: 10 }} />
              </InputAdornment>
            }
            onChange={onChangeSpeakerinput}
          />
          <div style={{ margin: 20, backgroundColor: includedInSearch ? "transparent" : "#f8f7f6" }}></div>
        </div>
      )
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <List className="NestedListFilter-root-Speaker NestedListFilter-root-Speaker-extended">
          <ListItem button onClick={() => showCheckBoxList()}>
            <ListItemText primary={translation.Conference_Speakers} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <ListItem className={`${!includedInSearch ? 'custom__checkbox--nested-taglist-extended' : 'custom__checkbox--nested-taglist'}`}>
            {customCheckBox(open)}
          </ListItem>
        </List>
        <List className="NestedListFilter-root-Speaker NestedListFilter-root-Speaker-extended">
          {((mapState === false || speakerList?.length === 0 || currentlyuSearchingSpeakerName === "")
            ? choosenList
            : speakerList
          )?.map((item, index) => {
            const speakerName = (item.persons.firstName != null && item.persons.lastName != null) && item.persons.firstName + " " + item.persons.lastName;
            return (
              <Collapse
                className="nested_collapseList"
                component="li"
                in={open}
                timeout="auto"
                unmountOnExit
              >
                {(item.persons.firstName != null && item.persons.lastName != null) && 
                <div
                  onClick={() => onItemClick(item.id)}
                  style={{ backgroundColor: includedInSearch ? "transparent" : "#f8f7f6" }}
                >
                  <ListItem button key={index} className={classes.nested} style={{ backgroundColor: includedInSearch ? "transparent" : "#f8f7f6" }}>
                    <ListItemText key={index} primary={speakerName} style={{ marginRight: '0px !important', backgroundColor: includedInSearch ? "transparent" : "#f8f7f6" }} />
                    <Checkbox
                      checked={getChecked(item.id)}
                      className="speaker__checkbox"
                      style={{ color: "#b6aa90", marginRight: '0px !important' }}
                    />
                  </ListItem>
                </div>
              }
              </Collapse>
            );
          })}
        </List>
      </div>
    </ClickAwayListener>
  );
};

NestedListSpeakers.propTypes = {
  classes: PropTypes.object.isRequired,
  choosenList: PropTypes.object,
  GetformData: PropTypes.func,
  comboID: PropTypes.object,
  checkInfo: PropTypes.func,
  includedInSearch: PropTypes.bool
};

export default memo(withStyles(styles)(NestedListSpeakers));
