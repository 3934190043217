import axios from "axios";
import Constants from "../components/constants/Constants";

export function getBanner(requests, type, category, preferredLanguages) {

  let preferredLanguageString = '';
  if(Array.isArray(preferredLanguages) && preferredLanguages.length > 0) {
    for (const language of preferredLanguages) {
      preferredLanguageString += `&PreferredLanguageIds=${language}`;
    }
  }

  requests = requests.replace('&category={category}', category ? '&category={category}' : '');
  requests = requests.replace("{type}", type)
  .replace("{limit}", 1)
  .replace("{page}", 1)
  .replace("{category}", category)
  .replace("{MediaType}", '')
  .replace("{PreferredLanguageIds}", preferredLanguageString);

  return new Promise((resolve, reject) => {
    axios
      .get(
        requests, { withCredentials: true}
      )
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const cache = new Map();
const CACHE_DURATION = 4000; // 300ms cache duration

export function getRow(requests, type, pageParams, category, mediaType, preferredLanguages) {
  const cacheKey = JSON.stringify({ requests, type, pageParams, category, mediaType, preferredLanguages });
  const now = Date.now();

  if (cache.has(cacheKey)) {
    const { promise, timestamp } = cache.get(cacheKey);
    if (now - timestamp < CACHE_DURATION) {
      return promise;
    }
  }

  let preferredLanguageString = '';
  if(Array.isArray(preferredLanguages) && preferredLanguages.length > 0) {
    for (const language of preferredLanguages) {
      preferredLanguageString += `&PreferredLanguageIds=${language}`;
    }
  }

  const mediaTypeString = mediaType ? mediaType.map(type => {
    const key = Object.keys(Constants.MediaType).find(k => k.toLowerCase() === type);
    return Constants.MediaType[key] || Constants.MediaType.NotFound;
  })
  .join('&MediaType=')
  : [];

  const queryString = mediaTypeString !== [] ? (`MediaType=${mediaTypeString === '' ? 0 : mediaTypeString}`) : '';

  requests = requests.replace('&category={category}', category ? '&category={category}' : '');
  requests = requests.replace("{type}", type)
                     .replace("{limit}", pageParams.limit)
                     .replace("{page}", pageParams.page)
                     .replace("{category}", category ?? '')
                     .replace("{MediaType}", queryString)
                     .replace("{PreferredLanguageIds}", preferredLanguageString);

  const promise = new Promise((resolve, reject) => {
    axios
      .get(requests, {
        withCredentials: true,
      })
      .then(response => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  cache.set(cacheKey, { promise, timestamp: now });

  return promise;
}
