import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@material-ui/styles";
import LinearProgress from "@mui/material/LinearProgress";

import AlertContainer from "./AlertContainer";

import style from "./DescriptionsPlaylistModal-Style";

const MyButton = styled(Button)(style.myButton);

const IsoTextfield = props => {
    const [isError, setIsError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [playlistName, setPlaylistName] = useState("");

    const handleChange = event => {
      setPlaylistName(event.target.value);
      setIsError("");
    };

    const handleSubmitPlaylist = () => {
      setIsLoading(true);
      props.addNewPlaylist(playlistName, setIsError, setIsLoading);
      setPlaylistName("");
    };

    const handleKeyDown = event => {
      if (event.key === "Enter") {
        handleSubmitPlaylist();
      }
    };

    return (
      <div className="description__playlists_page_new_playlist--create">
        <TextField
          id="outlined-basic"
          label={props.translation.Playlist_name}
          variant="outlined"
          value={playlistName}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className="new__playlist_input_text"
        />
        {isLoading ? (
          <LinearProgress color="inherit" className="progress_bar" />
        ) : (
          <MyButton
            variant="contained"
            onClick={handleSubmitPlaylist}
            disabled={isLoading}
            className="description__playlists_page_new_playlist_button new__playlist_input_btn"
          >
            {props.translation.Create}
          </MyButton>
        )}
        {isError && <AlertContainer errorMessage={isError} />}
      </div>
    );
};

export default IsoTextfield;