import * as types from "../constant";

export function GetSearchAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_SEARCH, payload });
  };
}

export function GetAllTagsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ALLTAGS, payload });
  };
}
export function GetTagsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_TAGS, payload });
  };
}

export function GetSearchedValue(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_SEARCHED_VALUE, payload });
  };
}
