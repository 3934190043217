import axios from "axios";


export function getContent(requests, type, idUser) {
  return new Promise((resolve, reject) => {
    axios.post(requests + type, {params: { userID: idUser }, withCredentials:true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}



export function getContentBySpeaker(requests, pageParams ,speakerID) {

  requests = requests

  .replace("{id}",speakerID)

  .replace("{limit}",pageParams.limit)

  .replace("{page}", pageParams.page);

  return new Promise((resolve, reject) => {

    axios.get(requests, { withCredentials:true })

      .then((response) => {

        if (response.status === 500) {

          reject(response.data);

        }

        resolve(response.data);

      })

      .catch((error) => {

        reject(error);

      });

  });

}

export function getChannelById(requests, contributorId) {
  requests = requests.replace("{id}", contributorId);
  return new Promise((resolve, reject) => {
    axios.get(requests, { withCredentials:true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllSocialMedia(requests) {
  return new Promise((resolve, reject) => {
    axios.get(requests, { withCredentials:true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}