import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import "./TextfieldWithTime.scss"
import { IconButton } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import RemoveIcon from '@mui/icons-material/Remove';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import Typography from "@mui/material/Typography";

export default function TextfieldWithTimeHighlight(props) {

    const [label, setLabel] = useState(props.label ?? "")
    const [labelFr, setLabelFr] = useState(props.labelFr ?? "")
    const [startTime, setStartTime] = useState(props.startTime ?? "00:00:01")
    const [endTime, setEndTime] = useState(props.endTime ?? "00:00:01")

    const [confirmed, setConfirmed] = useState(false)

    useEffect(() => {
        props.handleChangeHighlight({ label: label, labelFr: labelFr, startTime: startTime, endTime: endTime }, props.index)
    }, [confirmed])

    useEffect(() => {
        checkTime();
        setConfirmed(false)
    }, [label, labelFr, startTime, endTime])

    //function that checks that the start time is before the end time
    const checkTime = () => {
        if (startTime > endTime) {
            //set the end time to the start time
            setEndTime(startTime)
        }
    }

    function toSeconds(time) {
        var parts = time.split(':');
        return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
    }

    function toTime(seconds) {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds - (hours * 3600)) / 60);
        var seconds = Math.round(seconds - (hours * 3600) - (minutes * 60));
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    const handleTimeChange = (time, func) => {
        if (toSeconds(time) > props.duration) {
            time = toTime(props.duration)
        }
        else {
            time = time
        }
        func(time)
    }

    return (
        <Grid item xs={10} sx={{ display: "flex", marginTop: "8px" }} alignItems="center">
            <TextField
                disabled={props.isTopCreamContent}
                id="outlined-basic" value={label} label={"english"} style={{ width: 300 }} variant="outlined" onChange={(e) => setLabel(e.target.value)} />
            <span className="spacer" />
            <TextField disabled={props.isTopCreamContent}
                id="outlined-basic" value={labelFr} label={"french"} style={{ width: 300 }} variant="outlined" onChange={(e) => setLabelFr(e.target.value)} />
            <input disabled={props.isTopCreamContent}
                value={startTime} className={"time_input"} type="time" step={1} onChange={(e) => handleTimeChange(e.target.value, setStartTime)} />
            <Typography sx={{ fontSize: 14 }}
            >to</Typography>
            <input disabled={props.isTopCreamContent} value={endTime} className={"time_input"} type="time" step={1} onChange={(e) => handleTimeChange(e.target.value, setEndTime)} />
            <>
                {!props.isTopCreamContent &&
                    <>
                        <IconButton
                            variant="contained" size="small"
                            onClick={() => props.handleRemoveHighlight(props.index)}
                        >
                            <RemoveIcon sx={{ fontSize: "30px" }} />
                        </IconButton>
                        <IconButton
                            variant="contained" size="small"
                            onClick={() => setConfirmed(!confirmed)}
                        >
                            {confirmed ? <DoneAllOutlinedIcon color={"primary"} sx={{ fontSize: "30px" }} /> : <DoneOutlinedIcon sx={{ fontSize: "30px" }} />}
                        </IconButton>
                    </>
                }
            </>
        </Grid>
    )
}