import React, { useState, useEffect } from "react";
import { forwardRef } from 'react';
import { useSelector } from "react-redux";
import UserCategories from "../userCategories/UserCategories.jsx";

const CorpoInterestSelect = forwardRef(({categories, interestType, setCategoriesCallback, translation}, ref) => {
    const languageList = ["Fr", "En"];
    const lang = localStorage.getItem("language") || languageList.find(item => item.includes(window.navigator.language)) || languageList[1];
    const userRedux = useSelector((state) => state.userReducer);
    const h1 = interestType === "corporate" ? translation.Select_Corporate_Interest : translation.Select_Personal_Interest;
    const p = interestType === "corporate" ? translation.Choose_Company_Subscribed_Categories : translation.Choose_Subscribed_Categories;

    return (
        <div ref={ref} className="corporate-interest-select">
            <h1>{h1}</h1>
            <br/>
            <p>{p}</p>
            <UserCategories categories={categories} categoryCallback={setCategoriesCallback} lang={lang} userRedux={userRedux} />
        </div>
    );
});


export default CorpoInterestSelect;