import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LoadNextAction,
  IsOnScreenAction,
} from "../../redux/action/PaginationAction";

export default function Paginator(props) {
  const dispatch = useDispatch();
  const { hasLoaded } = useSelector((state) => state.paginationReducer);

  const bottom = useRef(false);
  const bottomDivRef = React.createRef();

  let observerBottom = useRef(null);
  useEffect(() => {
    observerBottom.current = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          bottom.current = true;
          dispatch(LoadNextAction(true));
          dispatch(IsOnScreenAction(true));
        } else {
          bottom.current = false;
          dispatch(LoadNextAction(false));
          dispatch(IsOnScreenAction(false));
        }
      },
      { threshold: 0.9 }
    );
  }, []);

  useEffect(() => {
    hasLoaded > 8 && observerBottom.current.observe(bottomDivRef.current);
  }, [hasLoaded]);

  return <div ref={bottomDivRef}><br/></div>;
}
