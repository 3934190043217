import * as types from "../constant/index";

export default function reducer(
    state = {
        loadingStatesHomepage: [],
        loadingStatesSubscriptions: [],
    },
    action
) {
    switch (action.type) {

        case types.LOADING_STATES_HOMEPAGE:
            if (action.payload === "remove") {
                return {
                    ...state,
                    loadingStatesHomepage: [],
                };
            }
            else {
                const loadingStatesSet = new Set([...state.loadingStatesHomepage, action.payload]);
                return {
                    ...state,
                    loadingStatesHomepage: [...loadingStatesSet],
                };
            }
        case types.LOADING_STATES_SUBSCRIPTIONS:
            if (action.payload === "remove") {
                return {
                    ...state,
                    loadingStatesSubscriptions: [],
                };
            }
            else {
                const loadingStatesSet = new Set([...state.loadingStatesSubscriptions, action.payload]);
                return {
                    ...state,
                    loadingStatesSubscriptions: [...loadingStatesSet],
                };
            }
            

        default:
            return state;
    }
}
