import * as types from "../constant/index";

export default function reducer(

    state = {
        userRedux: [],
        firstTimeLoginResponse: [],
    },
    action
) {
    switch (action.type) {
        case types.GET_USERTOKENBYID:
            return {
                ...state,
                userRedux: action.payload,
            };
        case types.GET_FIRST_TIME_LOGIN_RESPONSE:
            return {
                ...state,
                firstTimeLoginResponse: action.payload,
            };
        default:
            return state;
    }
}