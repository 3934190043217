import React, { Component, createRef } from "react";
import classNames from "classnames";
import { ArrowCounterClockwise, ArrowClockwise, Play, Pause } from "phosphor-react";
import loadLibrary from "./load-library";

import "./mp-component.css";

const DEFAULT_SKIN = "amp-default";
const DEFAULT_RATIO = [16, 9];
const point = window.innerWidth;

class AzureMP extends Component {

  constructor(props) {
    super(props);
    this.videoNode = createRef();
    this.state = {
      controlsVisible: false,
      isPlaying: false,
    };
  }

  handleVideoClick = () => {
    this.showControls();
  };

  showControls() {
    //if this.state.controlsVisible is true
    if (this.state.controlsVisible) {
      if (this.player.paused()) {
        this.player.play();
      } else {
        this.player.pause();
      }
    }
    this.setState({ controlsVisible: true });
    clearTimeout(this.hideControlsTimeout);
    this.hideControlsTimeout = setTimeout(() => {
      this.setState({ controlsVisible: false });
    }, 3000);
  }

  destroyPlayer() {
    this.player && this.player.dispose();
  }

  componentWillUnmount() {
    this.destroyPlayer();
    if (this.observer) {
      this.observer.disconnect();
    }
  }




  componentDidMount() {
    const { skin } = this.props;
    this.initialization = loadLibrary(skin).then(() => {
      this.createPlayer();
      this.setVideo();
      // Set up the MutationObserver
      const parent = this.player.el().parentElement;
      this.observer = new MutationObserver(this.checkControlsVisibility);
      this.observer.observe(parent, { attributes: true, attributeFilter: ["class"] });
    });
    
  }

  checkControlsVisibility = () => {
    const parent = this.player.el().parentElement;
    if (parent.classList.contains("vjs-user-active")) {
      this.setState({ controlsVisible: true });
    } else if (parent.classList.contains("vjs-user-inactive")) {
      this.setState({ controlsVisible: false });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.src[0]?.src != this?.props?.src[0]?.src) {
      this.initialization.then(() => this.setVideo());
    }
  }

  setVideo() {
    const { src, tracks } = this.props;
    this.player.src(src, tracks);
    this.player.poster(this.props.poster)
  }

  isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  createPlayer() {
    const { options, onInstanceCreated, events } = this.props;
    function isIOS() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    }

    const defaultOptions = {
      "nativeControlsForTouch": isIOS(),
      enableFullscreen: (point < 1024 ? false : true),
      disableFullscreenButton: (point < 1024 ? false : true),
      controls: true,
      autoplay: this?.props?.options?.autoPlay ?? true,
      muted: false,
      logo: { enabled: false },
      playbackSpeed: {
        enabled: true
      }
    };
    this.player = window.amp(
      this.videoNode.current,
      Object.assign({}, defaultOptions, options)
    );
    // for each loop for every item in events array, attach event listener
    if (events)
      for (const [event, func] of Object.entries(events)) {
        this.player.addEventListener(event, func);
      }
    onInstanceCreated && onInstanceCreated(this.player);
    if (point < 1024) this.player.exitFullscreen();
  }

  getRatioStyles(ratio) {
    if (!ratio) {
      return {};
    }
    return { paddingBottom: (ratio[1] / ratio[0]) * 100 + "%" };
  }

  playPause = () => {
    if (this.player.paused()) {
      this.player.play();
      // this.setState({ isPlaying: true });
      this.setState({ isPlaying: true });
    } else {
      this.player.pause();
      this.setState({ isPlaying: false });
    }
  };

  rewind = () => {
    const currentTime = this.player.currentTime();
    this.player.currentTime(currentTime - 10);
  };

  fastForward = () => {
    const currentTime = this.player.currentTime();
    this.player.currentTime(currentTime + 10);
  };



  render() {
    const {
      skin = DEFAULT_SKIN,
      adaptRatio = DEFAULT_RATIO,
      cornerPlayBtn,
    } = this.props;
    const { controlsVisible } = this.state;
    const overlayClass = classNames("overlay", {
      "visible": controlsVisible,
    });
    const iconSize = 48;
    return (
      <div
        style={this.getRatioStyles(adaptRatio)}
        className="azure-mp-container"
        onTouchEnd={this.isAndroid() && ( document.fullscreenElement && this.playPause ) } 
      >
        <video
          ref={this.videoNode}
          className={classNames("azuremediaplayer", `${skin}-skin`, {
            "amp-big-play-centered": !cornerPlayBtn,
            "controls-visible": controlsVisible,
          })}
          webkit-playsinline="true"
          playsInline={true}
        />
        {/* Add the overlay and buttons */}

        {this.isAndroid() && (
          controlsVisible && (
            <div className={overlayClass}>
              <button className="overlay-btn rewind" onTouchEnd={this.rewind}>
                <div className="icon-wrapper">
                  <ArrowCounterClockwise size={iconSize} />
                  <span>-10</span>
                </div>
              </button>
              <button className="overlay-btn play-pause" onTouchEnd={this.playPause}>
                {this.player && !this.player.paused() ? <Pause size={iconSize} /> : <Play size={iconSize} />}
              </button>
              <button className="overlay-btn fast-forward" onTouchEnd={this.fastForward}>
                <div className="icon-wrapper">
                  <ArrowClockwise size={iconSize} />
                  <span>+10</span>
                </div>
              </button>
            </div>
          )
        )}
      </div>
    );
  }
}

export default AzureMP;
