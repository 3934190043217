import * as types from "../constant";

export function GetRemoveContentIdAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_REMOVE_CONTENT_ID, payload });
  };
}

export function EditingAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_EDITING_PLAYLIST, payload });
  };
}

export function updatedPlaylistAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_UPDATED_PLAYLIST_ITEM, payload });
  };
}

export function getAllPlaylistsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ALL_PLAYLISTS, payload });
  };
}

export function setLoadingStatePlaylists(payload) {
  return async (dispatch) => {
    dispatch({ type: types.LOADING_STATE_PLAYLISTS, payload });
  };
}

export function isSubscribedStateAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.IS_SUBSCRIBED_STATE, payload });
  };
}

export function isLikedStateAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.IS_LIKED_STATE, payload });
  };
}

export function checkAndUpdateLikesCountAction(payload) {
  return async (dispatch) => {
    dispatch({ type: types.LIKES_COUNT, payload });
  };
}