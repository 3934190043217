//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * Description small component *                                 *
 * ?                                    Description contain many things inside it here is the list              *
 * ?                          Description is used in the conference page to give information on the video       *
 * ?               Like/Save/share buttons || Speakers/Keywords/Comments || Overview of the video's stats       *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import ImCross from "@mui/icons-material/Close";
import { FacebookShareButton, LinkedinShareButton, FacebookIcon, LinkedinIcon } from "react-share";
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import Tags from "../../components/switching/Tags";
import Intervenants from "../../components/switching/Intervenants";
import Comments from "../../components/switching/comments/Comments";

import requests from "../axios/Requests";
import {
  getPlaylists,
  getPlaylistsWithContent,
  postContentIntoPlaylist,
  deleteContentFromPlaylist,
} from "../../servicesRedux/PlaylistService";
import { GetWatchLaterCountAction } from "../../redux/action/GlobalActions.js";
import { getAllPlaylistsAction, checkAndUpdateLikesCountAction } from "../../redux/action/PlaylistAction";
import { GetConferenceAction } from "../../redux/action/ConferenceAction";
import { getConference } from "../../servicesRedux/ConferenceService.js";

import TopCreamShare from "../tcshare/TopCreamShare";
import { getChannelCount } from "../../servicesRedux/SubscriptionService.js";
import { getRecommendationReceipients } from "../../servicesRedux/RecommendedService";
import CommonPlaylistModalContent from "./CommonPlaylistModalContent";
import ViewCount from "./ViewCount";
import {
  isLikedStateAction,
  isSubscribedStateAction,
} from "../../redux/action/PlaylistAction";
import UnauthorizedConferenceModal from "../conferencePageComponents/UnauthorizedConferenceModal.jsx";

import "./Description.scss";
import "../../theme/search/Search.scss";

const DescriptionsSmallScreen = props => {
  const {
    id,
    tags,
    isLoading,
    setIsLoading,
    viewCount,
    speaker,
    infoConference,
    userID,
    contributor,
    transcriptList,
    thumbnailURL,
    translation,
    channel,
    contentId,
    isSaved,
    isliked,
    triggerGetConferenceApi,
    chapters,
    staticimg,
    highlights,
    point,
    comments,
    following,
    isAuthenticated,
    isContentExternalLink,
    externalContentLink,
    handleDownload,
    isDownloaded
  } = props;
  let keyWords = [];

  const dispatch = useDispatch();
  const [like, setLike] = useState(false);
  const [isSubscribed] = useState(false);
  const [isSavedContent, setIsSavedContent] = useState(isSaved ?? false);
  const { watchLaterCount } = useSelector(state => state.globalReducer);
  const { isSubscribedState, isLikedState, likesCount } = useSelector(state => state.playlistReducer);
  const [, setReceipients] = useState([]);
  const url = new URL(window.location.href);
  const shareUrl = url.origin + "/share" + url.pathname;
  const [, setChannelCount] = useState(0);
  const [likeCount, setLikeCount] = useState(likesCount);
  const { allPlayLists } = useSelector(state => state.playlistReducer);
  tags?.forEach(element => keyWords.push(element.toLowerCase().trim()));
  const [triggerUpdatingOfPlaylistModalBox, setTriggerUpdatingOfPlaylistModalBox] = useState(false);
  const [triggerLikesCountIncrementationOrDecrementation, setTriggerLikesCountIncrementationOrDecrementation] = useState(false);
  const [contentIdRef, setContentIdRef] = useState("");
  const [alert, setAlert] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalPlaylist, setDisplayModalPlaylist] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [, setOpen] = useState(false);
  const [openNewPlaylistPopup, setOpenNewPlaylistPopup] = useState(false);
  const [, setIsError] = useState("");
  const [playlists, setPlaylists] = useState([]);
  const [playlistStandard, setPlaylistStandard] = useState([]);
  const [, setPlaylistsWithContent] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = (e) => {
    if(isAuthenticated) return;
    e.stopPropagation(); // Stop the event from propagating further
    setModalOpen(true);
  };
  const handleCloseModal = () => setModalOpen(false);

  const pageParams = {
    limit: 20,
    page: 1,
  };

  useEffect(() => {
    const clearMessage = setTimeout(() => {
      setAlert("");
    }, 5000);
    return () => clearTimeout(clearMessage);
  }, [alert]);

  useEffect(() => {
    isSaved && setIsSavedContent(isSaved);
  }, [isSaved]);

  useEffect(() => {
    setLikeCount(likesCount);
  }, [likesCount]);

  useEffect(() => {
    if (triggerLikesCountIncrementationOrDecrementation) {
      setLikeCount(likeCount => likeCount + 1);
      dispatch(isLikedStateAction(true));
    } else {
      if (likeCount != 0) setLikeCount(likeCount => likeCount - 1);
      dispatch(isLikedStateAction(false));
    }
    triggerGetConferenceApi();
  }, [triggerLikesCountIncrementationOrDecrementation])

  useEffect(() => {
    if (isSubscribedState !== null && isSubscribedState !== undefined) {
      setIsSavedContent(isSubscribedState);
    }
  }, [isSubscribedState]);

  useEffect(() => { }, []);

  useEffect(() => {
    if (channel && isAuthenticated) {
      getChannelCount(requests.GetChannelCount, channel?.id).then((item) => setChannelCount(item));
    }
  }, [isSubscribed, channel]);

  useEffect(() => {
    if (isLikedState !== null && isLikedState !== undefined) {
      setLike(isLikedState);
    }
  }, [isLikedState]);

  useEffect(() => {
    if (isliked !== null && isliked !== undefined) {
      setLike(isliked);
    }
  }, [isliked]);

  useEffect(() => {
    setIsLoading(true);
    getConference(requests.GetConference, id).then(cItem => {
      dispatch(GetConferenceAction(cItem));
      contentId && getPlaylistsWithContent(requests.GetPlaylistsWithContent, contentId).then(item => {
        setPlaylistsWithContent(item);
        getPlaylists(requests.GetMyPlaylists, pageParams).then(playlists => {
          const temp = playlists.data.map(playlist => ({
            ...playlist,
            checked: false,
            toAdd: false,
          }));
          const temp2 = temp.map(playlistElement => {
            const matchingPlaylist = item.find(x => x === playlistElement.id);
            if (matchingPlaylist) {
              playlistElement.checked = true;
              playlistElement.toAdd = false;
            }
            return playlistElement;
          });
          dispatch(getAllPlaylistsAction(playlists));
          setPlaylistStandard(temp2.filter(t => t.type === 2 || t.type === 3));
          setPlaylists(temp2.filter(t => t.type !== 2 && t.type !== 3));
          setIsLoading(false);
        });
      });
    });
  }, [contentId, props.triggerUpdatingOfPlaylistModalBox, displayModalPlaylist]);

  const handleClick = likeStatus => {
    if (likeStatus) {
      setLike(true);
      setLikeCount(likeCount => likeCount + 1);
      dispatch(isLikedStateAction(true));
      dispatch(checkAndUpdateLikesCountAction(likesCount + 1));
      postContentIntoPlaylist(
        requests.PostContentIntoPlaylist,
        allPlayLists?.data?.find(p => p.type === 3)?.id,
        contentId
      ).then(() => {
        setTriggerUpdatingOfPlaylistModalBox(!triggerUpdatingOfPlaylistModalBox);
      });
    } else {
      setLike(false);
      if (likeCount != 0) setLikeCount(likeCount => likeCount - 1);
      dispatch(isLikedStateAction(false));
      dispatch(checkAndUpdateLikesCountAction(likesCount));
      deleteContentFromPlaylist(
        requests.DeleteContentFromPlaylist,
        allPlayLists?.data?.find(p => p.type === 3)?.id,
        contentId
      ).then(() => {
        setTriggerUpdatingOfPlaylistModalBox(!triggerUpdatingOfPlaylistModalBox);
      });
    }
  };

  const handleSave = isSavedContentStatus => {
    if (!isSavedContentStatus) {
      try {
        postContentIntoPlaylist(
          requests.PostContentIntoPlaylist,
          allPlayLists?.data?.find(p => p.type === 2).id,
          contentId
        ).then(_ => {
          setIsSavedContent(true);
          triggerGetConferenceApi();
          dispatch(GetWatchLaterCountAction(watchLaterCount + 1));
          setTriggerUpdatingOfPlaylistModalBox(!triggerUpdatingOfPlaylistModalBox);
        });
        dispatch(isSubscribedStateAction(true));
      } catch (error) {
        console.error(error.message);
      }
    } else {
      try {
        deleteContentFromPlaylist(
          requests.DeleteContentFromPlaylist,
          allPlayLists?.data?.find(p => p.type === 2).id,
          contentId
        ).then(() => {
          setIsSavedContent(false);
          triggerGetConferenceApi();
          dispatch(GetWatchLaterCountAction(watchLaterCount - 1));
          setTriggerUpdatingOfPlaylistModalBox(!triggerUpdatingOfPlaylistModalBox);
        });
        dispatch(isSubscribedStateAction(false));
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const handleClose = () => {
    if (props.mobileLayout === false || props.mobileLayout === undefined) {
      setOpen(false);
    } else {
      props.closeAddToPlaylist();
    }
    setOpenNewPlaylistPopup(false);
    setIsError("");
  };

  const VideoInfoCard = () => {
    return (
      <div className="shar__VideoCard__info">
        <img
          src={thumbnailURL}
          alt="videoImage"
          className="shar__VideoCard__info--img"
        />
        <div className="shar__VideoCard__info__aligne">
          <div className="shar__VideoCard__info--speaker">
            {speaker?.lastName} {speaker?.firstName}
          </div>
          <div className="shar__VideoCard__info--viewcount">
            {translation.VideoCard_Views.replace(
              "@number@",
              viewCount === undefined ? 0 : viewCount
            )}
          </div>
          <Avatar
            src={speaker?.image}
            className="shar__VideoCard__info--avatar"
          />
        </div>
        <div className="shar__VideoCard__info--title">
          {infoConference?.name}
        </div>
      </div>
    );
  };

  const copyLink = e => {
    navigator.clipboard.writeText(url);
    e.currentTarget.innerHTML = `Copied &#10004`;
  };

  const triggerTheRealTimeUpdateOnLikes = status => setTriggerLikesCountIncrementationOrDecrementation(status);

  const showShareModal = () => {
    if(isAuthenticated) {
      window.scrollTo(0, 0);
      const root = document.getElementsByTagName('html')[0];
      root.classList.add('addiontal-overlay-class');
      getRecommendationReceipients(requests.RecommendationReceipients, contentId).then(recpientsRes => {
        setContentIdRef(contentId);
        setReceipients(recpientsRes.map(r => r.recipientId != undefined && r.recipientId));
        setShareModalOpen(true);
      });
    }
  };

  const handleAddToPlaylist = () => {
    if(isAuthenticated) {
      window.scrollTo(0, 0);
      const root = document.getElementsByTagName('html')[0];
      root.classList.add('addiontal-overlay-class');
      setDisplayModalPlaylist(true);
    }
  };

  const closeAddToPlaylist = () => {
    const root = document.getElementsByTagName('html')[0];
    root.classList.remove('addiontal-overlay-class');
    setDisplayModalPlaylist(false);
    setOpenNewPlaylistPopup(false);
  };

  const closeModalBox = () => {
    setDisplayModal(!displayModal);
    const root = document.getElementsByTagName('html')[0];
    root.classList.remove('addiontal-overlay-class');
  };

  const openModalBox = () => {
    window.scrollTo(0, 0);
    const root = document.getElementsByTagName('html')[0];
    root.classList.add('addiontal-overlay-class');
    setDisplayModal(!displayModal);
  };

  const closeShareModalBox = () => {
    setShareModalOpen(false);
    const root = document.getElementsByTagName('html')[0];
    root.classList.remove('addiontal-overlay-class');
  };

  const updateOpenNewPlaylistPopupProp = () => setOpenNewPlaylistPopup(true);

  return (
    <div className="description">
      <UnauthorizedConferenceModal
        open={modalOpen}
        handleClose={handleCloseModal}
        severity={"error"}
        translation={translation}/>
      <div className="description__left description__small-screen-wrapper">
        <div>
          <p className="intervenants--title">
            {infoConference?.name}{isContentExternalLink && ":"}
            {isContentExternalLink && ' '}
            {isContentExternalLink &&
              <span
                className="intervenants--title externalLink_smallFont"
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => window.open(externalContentLink, '_blank')}
              >
                {translation.External_content_click_here}
              </span>
            }
          </p>
          <p className="intervenants--text">{infoConference?.fullSummary}</p>
        </div>
        <div className="description__stack"  onClick={handleOpen}>
          <div className="description__viewcount description__viewcount-main-wrapper">
            <ViewCount
              isLoading={isLoading}
              views={viewCount}
              like={like}
              likes={likeCount}
              translation={translation}
              handleClick={handleClick}
              isSavedContent={isSavedContent}
              handleSave={handleSave}
              showShareModal={showShareModal}
              handleAddToPlaylist={handleAddToPlaylist}
              infoConference={infoConference}
              point={point}
              isDownloaded={isDownloaded}
              handleDownload={handleDownload}
            />
          </div>
        </div>
        {isAuthenticated &&

          <div className="comments__sections-wrapper-small-screen">
            <Typography onClick={() => openModalBox()} className="comments__heading-p">
              {translation.Conference_Comments} • {comments.totalItems}
              <UnfoldMoreIcon />
            </Typography>
            {displayModal &&
              <div className={`Modal ${displayModal ? "Show" : ""}`}>
                <div className="comments__mobile-layout-close">
                  <ImCross
                    size={20}
                    className="description__playlists--exit modal__close"
                    onClick={() => closeModalBox()}
                  />
                </div>
                <Comments
                  id={id}
                  currentUserId="1"
                  translation={translation}
                  intervenantImage={speaker?.image}
                />

              </div>
            }
            <div className={`Overlay ${displayModal ? "Show" : ""}`} onClick={() => closeModalBox()}></div>
          </div>
        }
        <div className="description__switching" onClick={handleOpen}>
          <Intervenants
            id={id}
            intervenant={speaker}
            contributor={contributor}
            channel={channel}
            infoConference={infoConference}
            transcriptList={transcriptList}
            translation={translation}
            chapters={chapters}
            highlights={highlights}
            staticimg={staticimg}
            following={following}
          />
        </div>
        <div className="conferences__video--tags-container">
          <Tags tags={keyWords.sort()} isConference={true} includedInDashboard={false} />
        </div>
        <div className="description__left__container">
          <Link
            to={`/channel/${channel?.id}`}
            className="description__informations__name"
          >
          </Link>
        </div>
      </div>

      {displayModalPlaylist &&
        <div className={`Modal ${displayModalPlaylist ? "Show" : ""}`}>
          <div className="comments__mobile-layout-close">
            <ImCross
              size={20}
              className="description__playlists--exit modal__close"
              onClick={() => closeAddToPlaylist()}
            />
          </div>
          {" "}
          <div className="modal__content">
            <div className="modal__content__header">
              <h1 className="research--modalTitle">
                {translation.Playlist_add}
              </h1>
            </div>
            {isLoading ? <LinearProgress color="inherit" className="progress_bar" /> :
              <div style={{
                marginTop: 50
              }}>
                <CommonPlaylistModalContent
                  likes={likeCount}
                  handleClose={handleClose}
                  playlists={playlists}
                  setPlaylists={setPlaylists}
                  contentId={contentId}
                  playlistStandard={playlistStandard}
                  setPlaylistStandard={setPlaylistStandard}
                  triggerTheRealTimeUpdateOnLikes={triggerTheRealTimeUpdateOnLikes}
                  mobileView={true}
                  openNewPlaylistPopupProp={openNewPlaylistPopup}
                  updateOpenNewPlaylistPopupProp={updateOpenNewPlaylistPopupProp}
                />
              </div>}
          </div>
        </div>
      }

      {shareModalOpen &&
        <div className={`Modal ${shareModalOpen ? "Show" : ""}`}>
          <div className="comments__mobile-layout-close">
            <ImCross
              size={20}
              className="description__playlists--exit modal__close"
              onClick={() => closeShareModalBox()}
            />
          </div>
          {isLoading && <LinearProgress color="inherit" className="progress_bar" />}
          {" "}
          <div className="modal__content">
            <div className="modal__content__header">
              <h1 className="research--modalTitle">
                {translation.Conference_Share}
              </h1>
            </div>
            <div className="share__modal-box-mobile">
              <VideoInfoCard />
              <div className="research--modal__container">
                <FacebookShareButton
                  url={`${shareUrl}`}
                  quote={infoConference?.fullSummary}
                  title={infoConference?.name}
                  hashtag={"#TopCream"}
                  description={"facebook"}
                  className="share-button"
                >
                  <FacebookIcon size={48} round />
                  <div className="share-button--text">Facebook</div>
                </FacebookShareButton>
                <LinkedinShareButton
                  url={`${shareUrl}`}
                  title={infoConference?.name}
                  className="share-button"
                >
                  <LinkedinIcon size={48} round />
                  <div className="share-button--text">Linkedin</div>
                </LinkedinShareButton>
                <TopCreamShare
                  userId={userID}
                  mobileLayout={true}
                  contentId={contentIdRef}
                />
              </div>
              <div className="shar__VideoCard__copyLink">
                {translation.Conference_CopyLink}
                <div className="shar__VideoCard__copyLink--content">
                  <input type="url" value={`${shareUrl}`} />
                  <button
                    className="shar__VideoCard__copyLink--copy"
                    onClick={copyLink}
                  >
                    {translation.Conference_Copy}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className={`Overlay ${shareModalOpen ? "Show" : ""}`} onClick={() => closeShareModalBox()}></div>
    </div>
  );
};

export default DescriptionsSmallScreen;
