import React from "react"
import ContentLoader from "react-content-loader"

import useBreakpoints from "../../hooks/UseBreakpoint";

const LinkedVideoSkeleton = props => {
    const point = useBreakpoints();

    const renderContentLoaderWidth = mode => {
        switch(mode) {
          case "xs":
            return "400";
          case "sm":
            return "500";
          case "md":
            return "750";
          case "ml":
            return "1280";
          case "lg":
            return "1600";
        }
    };

    return (
        <ContentLoader 
            speed={2}
            width={750}
            height={'600px'}
            viewBox="0 0 100% 600"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect width={renderContentLoaderWidth(point)} height="600" />
        </ContentLoader>
    )
}

export default LinkedVideoSkeleton