import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from "react-redux";

import requests from "../../components/axios/Requests";
import { postSpeakerSubscription, deleteSpeakerSubscription } from "../../servicesRedux/SubscriptionService";
import { GetSpeakerFromConferenceAction } from "../../redux/action/SpeakerAction";

import "./Intervenants.scss";

export const ButtonWithPic = ({ src_icon, texte, intervenantId, translation, channel, following }) => {
    const [loading, setLoading] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      setTimeout(()=>{
        setIsFollowing(following);
      }, 1000);
    }, [following])

    const handleFollow = () => {
        setLoading(true);
        if (isFollowing) {
          deleteSpeakerSubscription(requests.DeleteSpeakerSubscriptions, intervenantId).then(() => {
            setIsFollowing(false);
            setLoading(false);
          });
        } else {
          postSpeakerSubscription(requests.PostSpeakerSubscriptions, intervenantId).then(() => {
            setIsFollowing(true);
            setLoading(false);
          });
        }
    };
    
    return (
      <div className={channel.id ? "buttonWithPic" : "buttonWithPic_full"}>
        <Link to={`/speaker/${intervenantId}`} onClick={() => dispatch(GetSpeakerFromConferenceAction(window.location.pathname))} >
          <div className="buttonWithPic__button" >
            <div
              className="buttonWithPic__button--img"
              style={{
                backgroundImage: `url(${
                  src_icon !== null && src_icon !== undefined && src_icon !== ""
                      ? src_icon
                      : "/images/placeholder_avatar.png"
                })`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '50%',
                width: '60px',
                height: '60px'
              }}              
            >
            </div>
            <p className="buttonWithPic__button--text">
              <span>{texte}</span>
              <span>{translation.ViewPage}</span>
            </p>            
          </div>
        </Link>
        <button onClick={handleFollow} className={`${isFollowing ? 'buttonWithPicFollowButton buttonWithPicFollowingButton'  : 'buttonWithPicFollowButton'}`}>
          {loading ? <CircularProgress size={20} color="inherit" style={{color:"#f8f7f6"}} /> : (isFollowing ? translation.Following : translation.Follow) }
        </button>
      </div>
    );
};
