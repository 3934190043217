//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * ScrollToTop component *                                       *
 * ?                       Pretty simple, whenever a change in the url is made, it will put the window back up  *
                                                                                                                */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ children }) {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Always redirect us to the top of the window
    }, [pathname]);

    return children;
}