import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./ReportWebVitals";
import store from "./redux/store/index";
import App from "./App";
import DownloadManager from "./components/Download/DownloadManager";

import "./index.css";

// const events = configureStore(eventsReducer)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>

    <React.StrictMode>
      <DownloadManager />
      <App />
    </React.StrictMode>
  </Provider>,

);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();