import React, { useEffect, useState } from 'react';
import './AiExplore.scss';
import ExploreButton from './ExploreButton';
import SelectAIExploreContent from './SelectAIExploreContent';
import ComparisonChat from './ComparisonChat';
import { useDispatch, useSelector } from "react-redux";
import { setIsSelectingAction, setSelectedContentsAction, setRunAIComparisonAction, setAIResultAction } from "../../redux/action/ExploreAiAction";
import { compareContentsByCatalogItemId } from '../../servicesRedux/ExploreAIService';
import DirectionsPopUp from './DirectionsPopUp';
const AiExplore = () => {
  const [showSelectContent, setShowSelectContent] = useState(false);
  const selectedContents = useSelector((state) => state.exploreAiReducer.selectedContents);
  const runAIcomparison = useSelector((state) => state.exploreAiReducer.runAIcomparison);
  const aiResult = useSelector((state) => state.exploreAiReducer.aiResult);
  const tenant = useSelector((state) => state.tenantReducer.tenant);
  const isSelecting = useSelector((state) => state.exploreAiReducer.isSelecting);
  const {translation} = useSelector((state) => state.languageReducer);

  const [loadingComparison, setLoadingComparison] = useState(false);
  const [hasSeenDirections, setHasSeenDirections] = useState(
    () => JSON.parse(localStorage.getItem('hasSeenDirections')) || false
  );
  const [showDirections, setShowDirections] = useState(false);
  const dispatch = useDispatch();

  const handleExploreClick = () => {
    setShowSelectContent(true);
    dispatch(setIsSelectingAction(true));
  };

  const handleSelectContentClose = () => {
    setShowSelectContent(false);
    dispatch(setIsSelectingAction(false));
    dispatch(setSelectedContentsAction([]));

    if (aiResult !== "") {
      dispatch(setIsSelectingAction(true));
      setShowSelectContent(true);
    }

  };

  useEffect(() => {
    if (aiResult !== "") {
      dispatch(setIsSelectingAction(true));
      setShowSelectContent(true);
    }
  }, [aiResult]);

  useEffect(() => {
    // if (runAIcomparison) {
    //   setLoadingComparison(true);
    //   const catalogItemIds = selectedContents.map(content => content.item.catalogItemId);
    //   compareContentsByCatalogItemId(catalogItemIds).then((comparisonResult) => {
    //     // Handle the comparison result
    //     console.log(comparisonResult);
    //     dispatch(setAIResultAction(comparisonResult))
    //     setLoadingComparison(false);
    //   })
    //     .catch((error) => {
    //       // Handle any errors
    //       console.error(error);
    //       setLoadingComparison(false);

    //     });
    //   dispatch(setRunAIComparisonAction(false));
    // }
  }, [runAIcomparison]);

  const handleCloseChat = () => {
    dispatch(setAIResultAction(""));
    setShowSelectContent(false);
    dispatch(setIsSelectingAction(false));
    dispatch(setSelectedContentsAction([]));
    dispatch(setRunAIComparisonAction(false));
  }

  useEffect(() => {
    localStorage.setItem('hasSeenDirections', JSON.stringify(hasSeenDirections));
  }, [hasSeenDirections]);

  const setContinue = () => {
    setHasSeenDirections(true);
    setShowDirections(false)
  };

  return (
    <div>
      {tenant.aiSynthesis ?
        <div className="ai-explore">
          {showSelectContent || isSelecting ? (

            hasSeenDirections ?

            <SelectAIExploreContent translation={translation} doesResultExist={aiResult !== ""} onClick={handleSelectContentClose} />

            :
            <DirectionsPopUp setContinue={setContinue}/>

          ) : (
            showDirections ?
            <DirectionsPopUp setContinue={setContinue}/>
            :
            <ExploreButton orientation='right' onClick={handleExploreClick} setShowDirections={setShowDirections} >
              {translation.explore_ai}
            </ExploreButton>
          )}
        </div>
        :
        <></>
        }

      {(selectedContents.length > 1 && tenant.aiSynthesis && (runAIcomparison || loadingComparison) || aiResult !== "") && <ComparisonChat runAIcomparison={runAIcomparison} contents={selectedContents} handleClose={handleCloseChat} comparisonResult={aiResult} />}
    </div>
  );
};

export default AiExplore;