import React from "react";
import { useDispatch } from "react-redux";

import { GetTranslation } from "../../redux/action/LanguageAction";
import Fr_Register from "../../resources/fr-FR.json";
import En_Register from "../../resources/en-US.json";

const LanguageSelect = () => {
  const dispatch = useDispatch();
  const languageList = ["FR", "En"];
  const language = localStorage.getItem("language") || languageList.find(item => item.includes(window.navigator.language))|| languageList[1];

  switch (language) {
    case "En":
      dispatch(GetTranslation(En_Register));
      break;
    case "FR":
      dispatch(GetTranslation(Fr_Register));
      break;
    default:
      dispatch(GetTranslation(En_Register));
  }
  return <></>;
}

export default LanguageSelect;