import React from 'react';
import './ExploreButton.scss';
import iButton from '../../assets/icons/Icons/iButton.svg';

const ExploreButton = ({ orientation = 'right', children, onClick, setShowDirections }) => {
  return (
    <>
      <button className={`exploreButton ${orientation}`} onClick={onClick}>
        {children}
      </button>
      <button className="aiInfo_icon" onClick={() => setShowDirections(true)}>
        <img src={iButton} alt="iButton" />
      </button>
    </>
  );
};

export default ExploreButton;