import axios from "axios";

export function getDownloaded(requests, pageParams) {
  requests = requests
    .replace("{limit}", pageParams.limit)
    .replace("{page}", pageParams.page);
  return new Promise((resolve, reject) => {
    axios
      .post(requests, "", { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUserWatchLaterList(requests, pageParams) {
  requests = requests
    .replace("{limit}", pageParams.limit)
    .replace("{page}", pageParams.page);

  return new Promise((resolve, reject) => {
    axios
      .get(requests, { withCredentials: true })
      .then((response) => {
        if (response.status === 500) {
          reject(response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
