import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import Box from '@mui/material/Box';
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import requests from "../axios/Requests";
import { 
    getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions,
    getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions
} from "../../servicesRedux/AdminService";

import { getMonthName, fixDateString } from "../../hooks/utils";

import { styles } from "./DashboardComponentStyles";

const VideosViewedComponent = props => {
    const { 
        classes, 
        startDate, 
        endDate, 
        selectedCompany, 
        selectedDateRange,
        changeOfMonthlySelectionEndPoint,
        setIsLoading
    } = props;   
    const [dataNumberOfVideosViewed, setDataNumberOfVideosViewed] = useState([]);
    const [dataNumberOfVideosViewedUnique, setDataNumberOfVideosViewedUnique] = useState(null);
    const optionsOnNumberOfVideosViewed = {
        title: `${(changeOfMonthlySelectionEndPoint ? "Monthly" : "Daily")} videos launched ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,
        hAxis: { title: (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), titleTextStyle: { color: "#333" }, minValue: 1 },
        vAxis: { title: 'Videos launched', minValue: 1 },
        chartArea: { width: "50%", height: "70%" },
        colors: ['#FB7A21'],
    };

    useEffect(() => {
        let tempVideos = [];
        setIsLoading(true);
        if (changeOfMonthlySelectionEndPoint) {
            getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions(requests.GetNoOfMonthlyVideosViewed, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Month", 
                    `Nb of videos launched ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);                
                items.kpiNbOfMonthlyVideoViewed.forEach(item => tempVideos.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.nbOfVideos, 
                    ""
                ]));
                setDataNumberOfVideosViewed(tempVideos);             
                setDataNumberOfVideosViewedUnique(items.uniqueVideoViewedCount);  
                setIsLoading(false);
            });
        } else {
            getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions(requests.GetNoOfDailyVideosViewed, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Day", 
                    `Nb of videos launched ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                items.kpiNbOfDailyVideoViewed.forEach(item => tempVideos.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfVideos, 
                    ""
                ]));
                setDataNumberOfVideosViewed(tempVideos);     
                setDataNumberOfVideosViewedUnique(items.uniqueVideoViewedCount);  
                setIsLoading(false);                            
            });           
        }
    }, [startDate, endDate, selectedCompany, changeOfMonthlySelectionEndPoint]);

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1', display: 'flex', flexDirection: 'column' }} className="home__page-charts-row other__page-charts-row-extended">                           
                <Paper 
                    className={classes.paper} 
                    sx={{ width: 175, overflow: 'hidden' }}
                >                    
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Typography className={classes.title} color="textSecondary">{`Number of videos launched ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`}</Typography>                            
                            <Typography className={classes.subTitle} component="p">{dataNumberOfVideosViewedUnique}</Typography>
                            <Typography className={classes.subTitle} component="p"></Typography>
                        </CardContent>
                    </Card>
                </Paper>
                <Paper className={classes.tablePaper}>
                    {dataNumberOfVideosViewed.length > 0 &&
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="600px"
                        data={dataNumberOfVideosViewed}
                        options={optionsOnNumberOfVideosViewed}
                    />}                                                
                </Paper>
            </Box>           
        </>
    );
}

export default withStyles(styles)(VideosViewedComponent);