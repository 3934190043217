import React from 'react';
import { Modal, Box, Alert, Button, Link } from '@mui/material';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const UnauthorizedConferenceModal = ({ open, handleClose, severity, translation }) => (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
    >
        <Box sx={modalStyle}>
            <Alert severity={severity} onClose={handleClose}>
                {translation.Not_Authorized_Content}
            </Alert>
            <div style={{ textAlign: 'center', marginTop: '10px' }}> 
                <Link
                    href="/login"
                    color="inherit"
                    underline="none"
                    onClick={handleClose}
                    style={{ display: 'inline-block', fontSize: '14px', fontWeight: 'bold' }}  
                >
                    {translation.Click_here_sign_in}
                </Link>
            </div>
        </Box>
    </Modal>
);

export default UnauthorizedConferenceModal;
