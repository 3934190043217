// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                             *
 *                                              * FirstLogin page *                                             *
 * ?                      When a user try to  login for the first time                                          *
 */
// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useNavigate } from "react-router";
import "../theme/login/Login.scss";
import Loader from "../components/async/Loader";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import MultiLanguage from "../components/language/MultiLanguage";
import { useDispatch } from "react-redux";
import Link from "@mui/material/Link";
import requests from "../components/axios/Requests";
import { postFirstTimeLogin } from "../servicesRedux/AuthentificationService";
import { postFirstTimeLoginAction } from "../redux/action/AuthentificationAction";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styled from "styled-components/macro";
import { getSettings } from "../servicesRedux/SettingsService";

const theme = createTheme();
const Alert = styled(MuiAlert)(spacing);

export const signOut = () => localStorage.removeItem("interv");

export const FirstLogin = () => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [settings, setSettings] = useState(null);

  const userID = Cookies.get("userID");

  const { translation } = useSelector((state) => state.languageReducer);
  const dispatch = useDispatch();

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(translation.Login_EmailNotValid)
        .required(translation.Login_EmailError),
    });

  const history = useNavigate();

  useEffect(() => {
    getSettings(requests.GetSettings).then((res) => {
      setSettings(res);
    }
    );
  }, []);

  const handleFirstLogin = (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    postFirstTimeLogin(requests.FirstTimeLogin, { email: values.email })
      .then((item) => {
        dispatch(postFirstTimeLoginAction(values.email));
        setSuccess(true);
      })
      .catch((error) => {
        setStatus({ success: false });
        setErrors({ submit: error.message });
        setSubmitting(false);
      });
  };

  const redirectToHome = () => history("/");

  function renderSwitch(param) {
    switch (param.replace(/\D/g, "")) {
      case "404":
        return translation?.Login_EmailNotFound;
        break;
      case "409":
        const trans = translation?.Login_AlreadyLoggedOnce;
        const wordArray = trans.split("@");
        const html = (
          <div>
            {wordArray[0]}
            <Link href="/" underline="hover" color="Highlight">
              {wordArray[1]}
            </Link>
            {wordArray[2]}
          </div>
        );
        return html;
        break;
      default:
        return param;
        break;
    }
  }


  useEffect(() => {
    if (userID !== undefined) {
      redirectToHome();
    }
  }, [userID])

  if (!userID) {
    return (
      <div className="login">
        <img className="login--logo" src={settings?.logoUrl} alt="logo" />
        <div className="login--connect">
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  className="login--connect--typography"
                  component="h1"
                  variant="h5"
                >
                  {translation?.Login_Welcome}
                </Typography>

                {!success ? (
                  <Formik
                    initialValues={{
                      email:
                        Cookies.get("email") !== undefined
                          ? Cookies.get("email")
                          : "",
                      submit: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleFirstLogin}
                  >
                    {loader ? (
                      <Loader />
                    ) : (
                      ({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                      }) => (
                        <Box
                          component="form"
                          noValidate
                          sx={{ mt: 1 }}
                          onSubmit={handleSubmit}
                        >
                          {errors.submit && (
                            <Alert severity="warning">
                              {renderSwitch(errors.submit)}
                            </Alert>
                          )}
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="email"
                            name="email"
                            variant="outlined"
                            label={translation?.Login_Email}
                            value={values.email}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />

                          <Button
                            className="login--connect--button"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isSubmitting}
                          // onClick={(e) => {
                          //   AuthGuard(e);
                          // }}
                          >
                            {translation?.First_Login_Connect_Submit_Button ??
                              "<Submit>"}
                          </Button>
                          <div style={{ display: "flex" }}>
                            <p className="login--grid--paragraph">
                              {translation?.Already_Have_account}
                            </p>
                            <div className="login--grid--link">
                              <Link
                                href="/"
                              >
                                {translation?.Login}
                              </Link>
                            </div>
                          </div>

                          <Grid container>
                            <Grid item xs>
                              <MultiLanguage isFromLogin={true} />
                            </Grid>
                          </Grid>
                        </Box>
                      )
                    )}
                  </Formik>
                ) : (
                  <div className="first_time_login_success">
                    <Alert severity="success">
                      {translation?.First_Login_Receive_Mail}
                    </Alert>
                    <div>
                      back to{" "}
                      <div className="login--grid--link">

                        <Link
                          className="first_time_login_success_back"
                          onClick={redirectToHome}
                        >
                           {translation?.Login}
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </Box>
            </Container>
          </ThemeProvider>
        </div>
      </div>
    );
  } else {
    return <>{history("/")}</>;
  }
};

export default FirstLogin;
