import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import playIcon from "../../assets/images/play.svg";

const PlayIconBlocked = videoProps => {
    const icon = useRef();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [remainingTime, setRemainingTime] = useState(null);
    const { translation } = useSelector(state => state.languageReducer);

    const playVideo = () => {
        setIsVideoPlaying(true)
    };

    useEffect(() => {
        if (!videoProps.isContentAllowed.isContentAllowed) {
            const trialPeriodInMs = videoProps.isContentAllowed.trialPeriod * 24 * 60 * 60 * 1000;
            const startTime = new Date(videoProps.isContentAllowed.trailFirstContentDate).getTime();
            //mock data for testing 10 seconds
            const intervalId = setInterval(() => {
                const remainingTimeInMs = startTime + trialPeriodInMs - Date.now();
                if (remainingTimeInMs <= 0) {
                    clearInterval(intervalId);
                    setRemainingTime(0);
                    return;
                }
                const remainingDays = Math.floor(remainingTimeInMs / (24 * 60 * 60 * 1000));
                const remainingHours = Math.floor((remainingTimeInMs % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
                const remainingMinutes = Math.floor((remainingTimeInMs % (60 * 60 * 1000)) / (60 * 1000));
                const remainingSeconds = Math.floor((remainingTimeInMs % (60 * 1000)) / 1000);
                setRemainingTime(`${remainingDays}d ${remainingHours}h ${remainingMinutes}m ${remainingSeconds}s`);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [videoProps.isContentAllowed.isContentAllowed, videoProps.isContentAllowed.trailFirstContentDate, videoProps.isContentAllowed.trialPeriod]);

    useEffect(() => {
        if (remainingTime === "0d 0h 0m 0s" || remainingTime === 0) {
            window.location.reload();
        }
    }, [remainingTime])
    //replace {nbVideo} in translation.Subscription_Video_Limit_1 by the number of videos allowed, videoProps.isContentAllowed.freeContentNumber
    // and {nbDays} by the number of days allowed, videoProps.isContentAllowed.trialPeriod
    function transformVideoLimit1() {
        if (translation?.Subscription_Video_Limit_1) {
            return translation?.Subscription_Video_Limit_1.replace("{nbVideo}", videoProps.isContentAllowed.freeContentNumber).replace("{nbDays}", videoProps.isContentAllowed.trialPeriod);
        }
    }
    //replace {date} in translation.Subscription_Video_Limit_2 with remaining time, remainingTime
    //and format it based on translation?.language, if it's "fr" then format it as "jours heures minutes ss"
    // if it's "en" then format it as "days hours minutes ss"
    function transformVideoLimit2() {
        if (translation?.Subscription_Video_Limit_2) {
            if (translation?.language === "fr") {
                return translation?.Subscription_Video_Limit_2.replace("{date}", 
                    remainingTime.replace("d", " jours").replace("h", " heures").replace("m", " min"));
            } else {
                return translation?.Subscription_Video_Limit_2.replace("{date}", 
                    remainingTime.replace("d", " days").replace("h", " hours").replace("m", " min"));
            }
        }
    }
    return (
        <>
            {(videoProps?.displayLogo && !isVideoPlaying) ?
                <div className="video__logo displayed" onClick={playVideo} ref={icon}>
                    <div className="video__button">
                        <img src={playIcon} alt="play" className="banner--play" />
                    </div>
                </div>
                :
                <div className="video__logo displayed" onClick={playVideo} ref={icon}>
                    {remainingTime && <div className="blocked_wait">
                        <br />
                        {transformVideoLimit1()}
                        <br />
                        {transformVideoLimit2()}
                    </div>}
                </div>
            }
        </>
    )
}

export default PlayIconBlocked;
