import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import Paper from '@mui/material/Paper';
import { Chart } from "react-google-charts";
import Box from '@mui/material/Box';
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import requests from "../axios/Requests";
import { 
    getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions,
    getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions
} from "../../servicesRedux/AdminService";

import { getMonthName, constructTimeSlotBasedOnAverageWatchTime, fixDateString } from "../../hooks/utils";

import { styles } from "./DashboardComponentStyles";

const VideosInMinutesComponent = props => {
    const { 
        classes, 
        startDate, 
        endDate, 
        selectedCompany, 
        selectedDateRange,
        changeOfMonthlySelectionEndPoint,
        setIsLoading
    } = props;   
    const [dataNumberOfVideosInMinutes, setDataNumberOfVideosInMinutes] = useState([]);
    const [dataNumberOfVideosAverage, setDataNumberOfVideosAverage] = useState([]);
    const [watchAverageTime, setWatchAverageTime] = useState(null);
    const optionsOnNumberOfVideosInMinutesFirstChart = {
        title: `${(changeOfMonthlySelectionEndPoint ? "Monthly" : "Daily")} Total video watch ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,
        hAxis: { title: (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), titleTextStyle: { color: "#333" }, minValue: 1 },
        vAxis: { title: 'Total videos watch time in munutes', minValue: 1 },
        chartArea: { width: "50%", height: "70%" },
        colors: ['#FB7A21'],
    };
    const optionsOnNumberOfVideosInMinutesSecondChart = {
        title: `${(changeOfMonthlySelectionEndPoint ? "Monthly" : "Daily")} Average video watch time ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`,
        hAxis: { title: (changeOfMonthlySelectionEndPoint ? "Month" : "Day"), titleTextStyle: { color: "#333" }, minValue: 1 },
        vAxis: { title: 'Average time in munutes', minValue: 1 },
        chartArea: { width: "50%", height: "70%" },
        colors: ['#3d6ecf'],
    };

    useEffect(() => {
        let tempVideos = [];
        let tempVideosAvarage = [];
        setIsLoading(true);
        if (changeOfMonthlySelectionEndPoint) {
            getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions(requests.GetNoOfMonthlyVideosMinutes, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Month", 
                    `Total video watch time ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                tempVideosAvarage.push(["Month", `Average video watch time ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, { role: "style" }]);
                items.kpiNbOfMonthlyVideoMinutes.forEach(item => tempVideos.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.nbOfVideoMinutes, 
                    ""
                ]));
                items.kpiNbOfMonthlyVideoMinutes.forEach(item => tempVideosAvarage.push([
                    getMonthName(item.month) + " " + item.year, 
                    item.avgOfVideoMinutes, 
                    ""
                ]));
                setDataNumberOfVideosInMinutes(tempVideos);
                setDataNumberOfVideosAverage(tempVideosAvarage);
                setIsLoading(false);
            });
        } else {
            getTheDataForReportingOnNumberOfVideosOnDailyForDifferentConditions(requests.GetNoOfDailyVideosMinutes, startDate, endDate, selectedCompany).then(items => {
                tempVideos.push([
                    "Day", 
                    `Total video watch time ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                tempVideosAvarage.push([
                    "Day", 
                    `Average video watch time ${selectedDateRange === "Personalized" ? ("between " + fixDateString(startDate) + " - " + fixDateString(endDate)) : ("for " + selectedDateRange)}`, 
                    { role: "style" }
                ]);
                items.kpiNbOfDailyVideoMinutes.forEach(item => tempVideos.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.nbOfVideoMinutes, 
                    ""
                ]));
                items.kpiNbOfDailyVideoMinutes.forEach(item => tempVideosAvarage.push([
                    moment(item.day).format('MM-DD-YY'), 
                    item.avgOfVideoMinutes, 
                    ""
                ]));
                setDataNumberOfVideosInMinutes(tempVideos);
                setDataNumberOfVideosAverage(tempVideosAvarage);
                setIsLoading(false);
            });
        }
        getTheDataForReportingOnNumberOfVideosOnMonthlyForDifferentConditions(requests.GetWatchAverageTime, startDate, endDate, selectedCompany).then(item => setWatchAverageTime(item));        
    }, [startDate, endDate, selectedCompany, changeOfMonthlySelectionEndPoint]);

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1', display: 'flex', flexDirection: 'column' }} className="home__page-charts-row other__page-charts-row-extended">                           
                <Paper 
                    className={classes.paper}  
                    sx={{ width: 175, overflow: 'hidden' }}                    
                >                    
                    <Card className={classes.card}>
                        <CardContent className={classNames(classes.cardContent, classes.cardContentExtended)}>
                            <Typography className={classes.title} color="textSecondary">{`Average watch time ${selectedDateRange === "Personalized" ? "between " + fixDateString(startDate) + " - " + fixDateString(endDate) : "for " + selectedDateRange}`}</Typography>                                                            
                            <Typography className={classes.tinyTitle} style={{ marginBottom: 15 }} component="p">{watchAverageTime && "Avg: " + constructTimeSlotBasedOnAverageWatchTime(watchAverageTime?.average)}</Typography>
                            <Typography className={classes.tinyTitleExtended} component="p">{"(hh:mm:ss)"}</Typography>
                            <Typography className={classes.tinyTitle} style={{ marginBottom: 15 }} component="p">{watchAverageTime && "Tot: " + constructTimeSlotBasedOnAverageWatchTime(watchAverageTime?.total)}</Typography>                                
                            <Typography className={classes.tinyTitleExtended} component="p">{"(hh:mm:ss)"}</Typography>
                        </CardContent>
                    </Card>
                </Paper>                
            </Box>
            <Box sx={{ width: '100%', typography: 'body1' }} style={{ justifyContent: 'flex-start' }} className="home__page-charts-row">
                <Paper className={classes.tablePaper}>
                    {dataNumberOfVideosAverage.length > 0 &&
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="600px"
                        data={dataNumberOfVideosAverage}
                        options={optionsOnNumberOfVideosInMinutesSecondChart}
                    />}                                                
                </Paper>
                <Paper className={classes.tablePaper}>
                    {dataNumberOfVideosInMinutes.length > 0 &&
                    <Chart
                        chartType="AreaChart"
                        width="100%"
                        height="600px"
                        data={dataNumberOfVideosInMinutes}
                        options={optionsOnNumberOfVideosInMinutesFirstChart}
                    />}                                                
                </Paper> 
            </Box>
        </>
    );
}

export default withStyles(styles)(VideosInMinutesComponent);