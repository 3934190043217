import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from "react-redux";
import { SetLoadingStatesHomepage } from "../../redux/action/MediaTypeAction";
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation } from "react-router-dom";

import "./ContentTypeToggle.scss";

const ContentTypeToggle = (props) => {
    const { translation, setActiveTypeCallback, homePage } = props;
    const [activeType, setActiveType] = useState(['video', 'podcast', 'pdf']);
    const dispatch = useDispatch();
    const { view } = useSelector((state) => state.viewallReducer);
    const { loadingStatesHomepage, loadingStatesSubscriptions } = useSelector((state) => state.mediaTypeReducer);
    const { categories } = useSelector((state) => state.globalReducer);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const categoriesId = categories.map(item => item.id);
        const isAllCategories = categoriesId.every(item => loadingStatesSubscriptions.includes(item));
        location.pathname === "/watch-later" ? setLoading(true) : setLoading(isAllCategories)
    }, [loadingStatesSubscriptions])

    useEffect(() => {
        const viewApi = view.map(item => item.api ?? 'Categories');
        // check if the loadingStates array contain all the view.api
        // if !activeType.includes("video") remove "TopTcVideo" from loadingStates array
        const viewApiFiltered = activeType.includes("video") ? viewApi : viewApi.filter(item => item !== "TopTcVideo");
        const isAllApi = viewApiFiltered.every(item => loadingStatesHomepage.includes(item));
        // if the loadingStates array contain all the view.api, set loading to false
        location.pathname === "/watch-later" ? setLoading(true) : setLoading(isAllApi)
    }, [loadingStatesHomepage])

    useEffect(() => {
        setActiveTypeCallback(activeType);
    }, [activeType]);

    const types = [
        { type: 'video', label: translation.Videos },
        { type: 'podcast', label: translation.Podcasts },
        { type: 'pdf', label: translation.Articles }
    ];

    const addTypeToActiveType = type => {
        setActiveType(prevActiveType => {
            if (prevActiveType.includes(type)) {
                return prevActiveType.filter(t => t !== type);
            } else {
                return [...prevActiveType, type];
            }
        });
        dispatch(SetLoadingStatesHomepage("remove"));
    };

    return (
        <div className="myspeakers__manage-header my__speakers--content-wrapper-title row__title-second-half my__subscriptions-wrapper content__wrapper_buttons_container">
            {!homePage && <div className="content__type_toggle_wrapper"><span>{translation.Admin_Subscription}</span></div>}  
            <div className="content__wrapper_tabs_buttons_container">
            {types.map(({ type, label }) => (
                <Button
                    key={type}
                    disabled={!loading}
                    className={`${activeType.includes(type) ? 'row__title-second-half-selected' : 'row__title-second-half-unselected'}`}
                    onClick={() => addTypeToActiveType(type)}
                >
                    {label}
                </Button>
            ))}
            </div> 
        </div>
    )
}

export default ContentTypeToggle;