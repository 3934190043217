import * as types from "../constant/index";

export default function reducer(
  state = {
    sidebar: [],
    navbar: [],
    navbarFilter: "",
    isSaved: false,
    watchLaterCount: "",
    recommandationsCount: "",
    categories: [],
    allCategories: [],
    allSpeakers: [],
    allChannels: [],
    allContributors: [],
    newContent: {},
    progressPercent: 0,
    allTags: [],
    languages: [],
    openPersonalizedDialog: false,
    dispatchedPlayList: false,
    allLanguages: []
  },
  action
) {
  switch (action.type) {
    case types.GET_SIDEBAR:
      return {
        ...state,
        sidebar: action.payload,
      };

    case types.GET_NAVBAR:
      return {
        ...state,
        navbar: action.payload,
      };

    case types.GET_NAVBARFILTER:
      return {
        ...state,
        navbarFilter: action.payload,
      };

    case types.GET_CATEGORIES:  
      const categories = [...new Set(action.payload.all)];
      const uniqueCategories = categories;  
      return {
        ...state,
        categories: uniqueCategories,
      };

    case types.GET_LANGUAGES:  
      const languages = [...new Set(action.payload.all)];
      return {
        ...state,
        languages: languages,
      };

    case types.GET_LANGUAGES_FOR_SEARCH:  
      const allLanguages = [...new Set(action.payload.all)];
      return {
        ...state,
        allLanguages: allLanguages,
      };      

    case types.GET_WATCHLATERCOUNT:
      return {
        ...state,
        watchLaterCount: action.payload,
      };
    case types.GET_RECOMMENDATION_COUNT:
      return {
        ...state,
        recommandationsCount: action.payload,
      };
    case types.UPDATE_RECOMMENDATION_COUNT:
      return {
        ...state,
        recommandationsCount: action.payload,
      };

    case types.GET_ISSAVED:
      return {
        ...state,
        isSaved: action.payload,
      };

    case types.GET_ALL_CATEGORIES:
      return {
        ...state,
        allCategories: action.payload,
      };
    case types.GET_ALL_SPEAKERS:
      return {
        ...state,
        allSpeakers: action.payload,
      };
    case types.GET_ALL_CHANNELS:
      return {
        ...state,
        allChannels: action.payload,
      };

    case types.GET_ALL_CONTRIBUTORS:
      return {
        ...state,
        allContributors: action.payload,
      };

    case types.GET_NEW_CONTENT:
      return {
        ...state,
        newContent: action.payload,
      };
    case types.SET_PROGRESS_PERCENT:
      return {
        ...state,
        progressPercent: action.payload,
      };
    case types.GET_ALL_TAGS:
      return {
        ...state,
        allTags: action.payload,
      };
    case types.SET_OPEN_PERSONALIZED_DIALOG:
      return {
        ...state,
        openPersonalizedDialog: action.payload,
      };
    case types.DISPATCHED_PLAYLIST:
      return {
        ...state,
        dispatchedPlayList: action.payload,
      };

    default:
      return state;
  }
}
