import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";

import requests from "../axios/Requests";
import {
  getPlaylists,
  getPlaylistsWithContent,
} from "../../servicesRedux/PlaylistService";
import { getConference } from "../../servicesRedux/ConferenceService.js";
import { GetConferenceAction } from "../../redux/action/ConferenceAction";
import { getAllPlaylistsAction } from "../../redux/action/PlaylistAction";

import CommonPlaylistModalContent from "./CommonPlaylistModalContent";

const DescriptionsPlaylistModal = props => {
  const dispatch = useDispatch();
  const { id, open, setOpen, triggerTheRealTimeUpdateOnLikes, likes } = props;
  const [, setIsError] = useState("");
  const [playlists, setPlaylists] = useState([]);
  const [, setOpenNewPlaylistPopup] = useState(false);
  const [playlistStandard, setPlaylistStandard] = useState([]);
  const [, setPlaylistsWithContent] = useState([]);
  const contentId = props.contentId;
  const [isLoading, setIsLoading] = useState(false);
  const pageParams = {
    limit: 20,
    page: 1,
  };

  useEffect(() => {
    setIsLoading(true);
    getConference(requests.GetConference, id).then(cItem => {
      dispatch(GetConferenceAction(cItem));
      contentId && getPlaylistsWithContent(requests.GetPlaylistsWithContent, contentId).then(item => {
        setPlaylistsWithContent(item);
        getPlaylists(requests.GetMyPlaylists, pageParams).then(playlists => {
          const temp = playlists.data.map(playlist => ({
            ...playlist,
            checked: false,
            toAdd: false,
          }));
          const temp2 = temp.map(playlistElement => {
            const matchingPlaylist = item.find(x => x === playlistElement.id);
            if (matchingPlaylist) {
              playlistElement.checked = true;
              playlistElement.toAdd = false;
            }
            return playlistElement;
          });
          dispatch(getAllPlaylistsAction(playlists));
          setPlaylistStandard(temp2.filter(t => t.type === 2 || t.type === 3));
          setPlaylists(temp2.filter(t => t.type !== 2 && t.type !== 3));
          setIsLoading(false);
        });
      });
    });        
  }, [contentId, props.triggerUpdatingOfPlaylistModalBox]);

  const handleClose = () => {
    if (props.mobileLayout === false || props.mobileLayout === undefined) {
      setOpen(false);
    } else {
      props.closeAddToPlaylist();
    }
    setOpenNewPlaylistPopup(false);
    setIsError("");  
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby={`parent-modal-description ${props.mobileLayout === true ? 'addto__playlist-modalbox-container-title' : ''}`}
        aria-hidden={props.mobileLayout === true ? true : false}
        className={`addto__play_list_content_modal ${props.mobileLayout === true ? 'addto__playlist-modalbox-container' : ''}`}
      >
        <CommonPlaylistModalContent 
          likes={likes}
          loading={isLoading}
          handleClose={handleClose}
          playlists={playlists}
          setPlaylists={setPlaylists}
          contentId={contentId}
          playlistStandard={playlistStandard}
          setPlaylistStandard={setPlaylistStandard}
          triggerTheRealTimeUpdateOnLikes={triggerTheRealTimeUpdateOnLikes}
        />
      </Modal>    
    </div>
  );
};

export default memo(DescriptionsPlaylistModal);
