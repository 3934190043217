import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import "./TagView.scss"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { RelatedVideoTags, SearchRelated } from "../../redux/action/RelatedVideoAction.js";


//    dispatch(RelatedVideoTags(conference.tag));

const TagView = (props) => {
    const { tagData } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <Stack className="tagStack" direction="row" spacing={1}>
            {tagData.map((tag) => (
                <Chip
                    key={tag.id}
                    label={tag.label}
                    variant="outlined"
                    className="tagChip"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(RelatedVideoTags({id: tag.id, label: tag.label}));
                        dispatch(SearchRelated(true));
                        navigate("/search");
                    }}
                />
            ))}
        </Stack>
    );
};

export default TagView;