import React, { useState, useEffect } from "react";
import {
  MdOutlineDeleteForever,
  MdOutlineShare,
  MdOutlineDriveFileRenameOutline,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { BiCheckCircle } from "react-icons/bi";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { styled } from "@material-ui/styles";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ImCross from "@mui/icons-material/Close";

import PersonCard from "../person/PersonCard.jsx";
import { AlertContainer } from "../../components/alertContainer";
import Row from "../row/Row";

import {
  deletePlaylist,
  deleteContentFromPlaylist,
  patchPlaylistName,
  GetContentSharedPlaylist
} from "../../servicesRedux/PlaylistService";
import { updatedPlaylistAction } from "../../redux/action/PlaylistAction";
import requests from "../../components/axios/Requests";
import { EditingAction } from "../../redux/action/PlaylistAction";

import { 
  postUsersSharePlaylist,
  getUsersForSharedPlaylist,
  deleteSharedPlaylist
} from "../../servicesRedux/PlaylistService";
import { useTracking } from "react-tracking";
import Constants from "../constants/Constants";
import PlaylistEditIcon from "../../assets/images/pencil-icon-playlist.svg";

import "./Playlist.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MyButton = styled(Button)({
  background: "#ccc",
  border: 0,
  color: "white",
  color: "black",
  "&:hover": {
    backgroundColor: "#b6aa90",
    color: "white",
  },
});

export const Playlist = props => {
  const dispatch = useDispatch();
  const { userId, people, setSearch, playlistMainType, triggerPlaylistsReload } = props;
  const { id, name, ownerFirstName, ownerLastName, playlistId, contentCount, type } = props.item;
  const [isModify, setIsModify] = useState(false);
  const [deletePlaylistState, setDeletePlaylistState] = useState(false);
  const [hasBeenDeletedId, setHasBeenDeletedId] = useState("");
  const [deleteContentState, setDeleteContentState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [playListName, setPlaylistName] = useState(name ?? "");
  const [isError, setIsError] = useState("");
  const { translation } = useSelector(state => state.languageReducer);
  const [personModalOpen, setPersonModalOpen] = useState(false);
  const [receipients, setReceipients] = useState([]);
  const [isSearching] = useState(false);
  const [receipientsAsObjects, setReceipientsAsObjects] = useState([]);
  const [previousReceipients, setPreviousReceipients] = useState([]);
  const [, setRemovalIncluded] = useState(false);
  const [deleteReceipeintIds, setDeleteReceipeintIds] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [pageNumber] = useState(1);
  const [totalPages] = useState();
  const [sharedPalylistContentDetails, setSharedPalylistContentDetails] = useState({});
  const tracking = useTracking();
  
  const pageParams = {
    limit: 10,
    page: totalPages >= pageNumber ? pageNumber : 1,
  };
    
  useEffect(() => {
    if (id && playlistMainType == "my") getReceipeintsThenAssignToObjects(id);    
    if (id && playlistMainType == "shared") {
      const mediaType = "&MediaType=1&MediaType=2&MediaType=3";
      GetContentSharedPlaylist(
        requests.GetContentSharedPlaylist,
        playlistId,
        pageParams,
        mediaType
      ).then(item => setSharedPalylistContentDetails(item));                 
    }
  }, []);

  const deleteContentCallback = contentId => {
    setIsLoading(true);
    deleteContentFromPlaylist(
      requests.DeleteContentFromPlaylist,
      id,
      contentId
    ).then(_ => {
      setHasBeenDeletedId(contentId);
      triggerPlaylistsReload();
    });
  };

  const modifyPlaylist = () => {
    setIsModify(!isModify);
    const idToSend = !isModify ? id : false;
    dispatch(EditingAction(idToSend));
    if (playlistMainType == "my") setDeleteContentState(!deleteContentState);
  };

  const deleteThisPlaylist = async () => {
    setIsLoading(true);
    if (playlistMainType == "my") {
      await deletePlaylist(requests.DeletePlaylist, id).then((item) => {
        setDeletePlaylistState(!deletePlaylistState);
        setIsLoading(false);
      });  
    } else {
      await deleteSharedPlaylist(
        requests.DeleteSharedPlaylist,
        id,
      ).then(_ => {    
        setDeletePlaylistState(!deletePlaylistState);
        setIsLoading(false);
      });       
    }
  };

  const editPlaylistName = () => handleOpen();

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIsError("");
  };

  const handleChange = event => {
    setPlaylistName(event.target.value);
    setIsError("");
  };

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      UpdatePlaylistName();
      setOpen(false);
    }
  };

  const handlePlatlistShareModal = () => {
    props.clearTheSearch();    
    setPersonModalOpen(false);    
  };

  const UpdatePlaylistName = () => {
    setIsLoading(true);
    patchPlaylistName(requests.PatchPlaylistName, id, playListName, type).then(item => {
      if (item === 409) {
        setIsError("Playlist with this name already exists");
      } else {
        const updatedPlaylist = {
          id: item.id,
          name: item.name,
          contentCount: item.contentCount,
        };
        dispatch(updatedPlaylistAction(updatedPlaylist));
        setIsLoading(false);
        setOpen(false);
      }
    });
  };

  const favPlayList = (name, type) => {
    if (type === 2 || type === 3) {
      return translation.PlayListName.find((p) => p.type === type).label;
    } else {
      return name;
    }
  };

  const userSelection = async userId => {
    let receipientsTemp = [];
    if (receipients.find(uid => uid == userId)) {
      setIsLoading(true);
      setRemovalIncluded(true);
      setDeleteReceipeintIds([...deleteReceipeintIds, userId]);
      receipientsTemp = receipients.filter(uid => uid != userId);
      setReceipients([...new Set(receipientsTemp)]);        
    } else {
      setIsLoading(true);
      receipientsTemp = [...receipients, userId];      
      setReceipients([...new Set(receipientsTemp)]);
    }
    setIsLoading(false);        
  };

  const sendSharePlaylistToUsers = async () => {
    const toRemove = new Set(previousReceipients);
    const difference = receipients.filter( x => !toRemove.has(x) );
    const playlistSharedIdsToDelete = receipientsAsObjects.filter(eachRes => deleteReceipeintIds.includes(eachRes.recipientId) ? eachRes.playlistSharedId : '');
    const playlistSharedIds = playlistSharedIdsToDelete.length > 0 && playlistSharedIdsToDelete.map(e => e.playlistSharedId);

    if (playlistSharedIdsToDelete.length > 0) {
      setIsLoading(true);
      await deleteSharedPlaylist(
        requests.DeleteSharedPlaylist,
        playlistSharedIds,
      ).then(_ => {    
        setIsLoading(false);
        getReceipeintsThenAssignToObjects(id);
        setPersonModalOpen(false);  
        setOpenConfirmation(true);         
      });    
         
      deleteReceipeintIds.map(delReceipeint => {
        tracking.trackEvent(
          {
            UserEventId: Number(Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.SHARE_PLAYLIST.enum),
            DataId: id,
            DataTypeId: Number(Constants.UserLogDataType.PLAYLIST_ID.enum),
            ToId: delReceipeint,
            Note1: "Delete Share Playlist"
          }
        );
      });

    }

    if (difference.length > 0) {
      setIsLoading(true);
      postUsersSharePlaylist(
        requests.PostUsersToSharePlaylist,
        difference,
        id,
      ).then(async _ => {
        setIsLoading(false);
        setPersonModalOpen(false);
        setOpenConfirmation(true);
      });   
      
      difference.map(diff => {
        tracking.trackEvent(
          {
            UserEventId: Number(Constants.UserEvent.CLICK.enum),
            UserActionId: Number(Constants.UserAction.SHARE_PLAYLIST.enum),
            DataId: id,
            DataTypeId: Number(Constants.UserLogDataType.PLAYLIST_ID.enum),
            ToId: diff
          }
        );
      });
      
    }
  };

  const openPersonDialog = id => {
    setPersonModalOpen(true);
    getReceipeintsThenAssignToObjects(id);
  };

  const getReceipeintsThenAssignToObjects = id => {
    getUsersForSharedPlaylist(requests.GetUsersForSharedPlaylist, id).then(recpientsRes => {
      setReceipients(recpientsRes.map(r => r.recipientId != undefined && r.recipientId));
      setReceipientsAsObjects(recpientsRes);
      setPreviousReceipients(recpientsRes.map(r => r.recipientId != undefined && r.recipientId));
    });  
  };

  const handleCloseConfirmation = () => {
    setReceipients([]);
    setOpenConfirmation(false);
    props.clearTheSearch();    
  };

  return (
    <div>
      {!deletePlaylistState && (
        <div className={`playlist ${contentCount > 0 ? '' : 'playlist__empty-row'}`}>
          <div className="playlist_label">
            <div className="playlist_label_title">    
              {!isModify ? (
                (
                  <>
                    <div className="playlist_edit_flex">
                      <span>{favPlayList(name, type)}</span>
                      {(type !== 2 && type !== 3 && playlistMainType === "my") && 
                      <div className="playlist_edit" onClick={modifyPlaylist}>
                        <img src={PlaylistEditIcon} className="playlist_edit_icon" />                        
                      </div>}
                    </div>
                    {(type !== 2 && type !== 3 && playlistMainType !== "shared") && 
                    <div
                      className="playlist_edit_flex"
                      onClick={() => openPersonDialog(id)}
                    >
                      <MdOutlineShare
                        size={25}
                        className="playlist_edit_icon"
                      />
                      <span className="sharing__text">{translation.Conference_Share}</span>
                    </div>}                     
                  </>
                )
              ) : (
                <>
                  {isLoading ? (                    
                    <LinearProgress color="inherit" className="progress_bar" />
                  ) : (
                    <>
                      <div className="playlist_edit_flex">
                        <span>{favPlayList(name, type)}</span>
                        <div className="playlist_edit" onClick={modifyPlaylist}>
                          <BiCheckCircle className="playlist_edit_icon" size={25} />
                        </div>
                        {type !== 2 && type !== 3 && (
                          <>
                            <div
                              className="playlist_edit_flex"
                              onClick={deleteThisPlaylist}
                            >
                              <MdOutlineDeleteForever
                                className="playlist_edit_icon"
                                size={25}
                              />
                            </div>
                            {
                              props.playlistMainType == "my" &&
                              <>
                                <div
                                  className="playlist_edit_flex"
                                  onClick={editPlaylistName}
                                >
                                  <MdOutlineDriveFileRenameOutline
                                    size={25}
                                    className="playlist_edit_icon"
                                  />
                                </div>
                              </>
                            }
                          </>
                        )}
                      </div>
                      {(type !== 2 && type !== 3 && playlistMainType !== "shared") && 
                      <div
                        className="playlist_edit_flex"
                        onClick={() => openPersonDialog(id)}
                      >
                        <MdOutlineShare
                          size={25}
                          className="playlist_edit_icon"
                        />
                        <span className="sharing__text">{translation.Conference_Share}</span>
                      </div>}     
                    </>             
                  )}
                </>
              )}                          
            </div>            
            <div className="playlist_line--container">
              <div className={`${playlistMainType == 'my' ? 'playlist_content-count' : 'playlist_content-count-extended'}`}>
              {playlistMainType == "my" ? 
                <>                  
                  <p>{contentCount} {contentCount > 1 ? `${translation.Contents}` : `${translation.Content}`}</p>
                </> : 
                <>
                  <p className="playlist_content-count">
                  {sharedPalylistContentDetails.totalItems} {sharedPalylistContentDetails.totalItems > 1 ? translation.Contents : translation.Content} {' '} 
                  {sharedPalylistContentDetails.totalItems > 1 ? translation.Playlist_Shared_By_Plural : translation.Playlist_Shared_By} {ownerFirstName} {ownerLastName}</p>
                </>
              }
              </div>
              <div className="playlist_line_end"/>
            </div>
          </div>
          {(contentCount > 0) &&
            <div className="playlist_content">
              <Row
                title={favPlayList(name, type)}
                appearanceType={"row"}
                contentType={"Playlist"}
                playlistId={playlistMainType == "shared" ? playlistId : id}
                playlistDeleteContent={{
                  deleteContentCallback,
                  deleteContentState,
                  hasBeenDeletedId,
                }}
                playListCatType={playlistMainType}
                typePlayList={type}
                isPlayList={true}
              />
            </div>          
          }
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>          
          <h2 id="parent-modal-title">
            {translation.Playlist_Creation_Request}
          </h2>          
          <br />
          <TextField
            required
            id="outlined-basic"
            variant="outlined"
            size="small"
            placeholder={translation.Playlist_Name_Placeholder}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            fullWidth
            value={playListName}
            inputProps={{ maxLength: 40 }}
          />

          <br />
          <br />

          {isLoading ? (
            <LinearProgress color="inherit" className="progress_bar" />
          ) : (
            <MyButton onClick={UpdatePlaylistName} fullWidth>
              {translation.Rename_PlayList}
            </MyButton>
          )}
          {isError && <AlertContainer errorMessage={isError} />}
        </Box>
      </Modal>

      <Dialog
        className='modal-dialog'
        open={openConfirmation}
        onClose={handleCloseConfirmation}
      >
        <div className="top-cream-share__search_modal__share__container">
          <ImCross
            size={20}
            className="description__playlists--exit modal__close"
            onClick={handleCloseConfirmation}
          />
        </div>
        <DialogContent>
          <DialogContentText>
            <h3 className="success__modal-box-text">{translation.Playlist_Shared}</h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <button 
            onClick={handleCloseConfirmation}
            className="linkedVideo--button linkedVideo--button--extended"
          >
            {translation.Close}
          </button>
        </DialogActions>        
      </Dialog>

      <Dialog
        className='modal-dialog top-cream-share__search_modal'
        onClose={handlePlatlistShareModal}
        open={personModalOpen}
      >
        <div className="top-cream-share__search_modal__share__container">
          <ImCross
            size={20}
            className="description__playlists--exit modal__close"
            onClick={handlePlatlistShareModal}
          />
        </div>
        <div className="top-cream-share__search">
          <input
            type="text"
            placeholder="Search"
            className="top-cream-share__search__input"
            onChange={e => setSearch(e.target.value)}
          />
        </div>
        <DialogContent>
            <DialogContentText>
              <div className="top-cream-share__search-modal__container">
                {people && people.length !== 0 ? (
                  <div className="top-cream-share__people">              
                    {people && people.map && people.map((item, i) => userId != item.id && (
                      <PersonCard
                        userSelection={userSelection}
                        avatar={item.avatar}
                        firstName={item.firstName}
                        lastName={item.lastName}
                        userId={item.id}
                        recommendationsList={receipients}
                      />
                    ))}     
                  </div>
                ) : (
                  <div className="top-cream-share__search-modal__no-result">
                    {isSearching ? "No results" : "Search for people"}
                  </div>
                )}          
              </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <div className="top-cream-share__search-modal__container__action__panel">
            {isLoading && <LinearProgress color="inherit" className="progress_bar" />}
            <button
              disabled={isLoading}            
              onClick={sendSharePlaylistToUsers}
              className="linkedVideo--button linkedVideo--button--extended"
            >
              {translation.Share_Playlist}
            </button>
          </div> 
        </DialogActions>        
      </Dialog>
    </div>
  );
}

export default Playlist;