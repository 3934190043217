import React, { Component, createRef } from "react";
import classNames from "classnames";

import loadLibrary from "./AzureComponent/load-library";

import "./AzureComponent/mp-component.css";

const DEFAULT_SKIN = "amp-default";
const DEFAULT_RATIO = [16, 9];
const point = window.innerWidth;

class AzureMP extends Component {

  constructor(props) {
    super(props);
    this.videoNode = createRef();
  }

  destroyPlayer() {
    this.player && this.player.dispose();
  }
  
  componentWillUnmount() {
    this.destroyPlayer();
  }

  componentDidMount() {
    const { skin } = this.props;
    this.initialization = loadLibrary(skin).then(() => {
      this.createPlayer();
    });
  }

  getRatioStyles(ratio) {
    if (!ratio) {
      return {};
    }
    return { paddingBottom: (ratio[1] / ratio[0]) * 100 + "%" };
  }

  render() {
    const {
      skin = DEFAULT_SKIN,
      adaptRatio = DEFAULT_RATIO,
      cornerPlayBtn,
    } = this.props;    
    return (
      <div
        style={this.getRatioStyles(adaptRatio)}
        className="azure-mp-container"
      >
        <video
          className={classNames("azuremediaplayer", `${skin}-skin`, {
            "amp-big-play-centered": !cornerPlayBtn,
          })}
          webkit-playsinline="true"
          playsInline={true}
        />
      </div>
    );
  }
}

export default AzureMP;
