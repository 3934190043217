import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import "./Tag.scss";

const Tags = props => {
  const { tags, GetformData, comboID, checkInfo, isConference, includedInDashboard, removeSelectedTagOnSearch, checkState } = props;
  const [tagsList, setTagsList] = useState([]);
  const [language] = useState(localStorage.getItem("language"));

  /* ************************************* Check value Existance into List ********************************************** */
  const onItemClick = val => {
    const temp = tagsList.find(tl => tl.id === val.id);
    if (temp === undefined) {
      setTagsList([...tagsList, val]);
    } else {
      const filteredList = tagsList.filter(tl => tl.id !== val.id);
      setTagsList(filteredList);      
    }
    checkInfo(!checkState);     
  };

  useEffect(() => {
    GetformData && GetformData(tagsList, 9);
  }, [tagsList]);

  const checkSelectedTag = val => {
    const temp = tagsList.find(tl => tl?.id === val?.id);
    if (temp !== undefined) {
      return "tagSelected";
    } else {
      return "tag";
    }
  };

  return (
    <div className={ isConference ? "global__tag--left" : !includedInDashboard ? "global__tag" : "specific__tags_area" } > 
      {tags && tags.length > 0 && comboID &&
        tags.map((item, index) => (
          <li
            className={checkSelectedTag(item)}
            onClick={() => onItemClick(item)}
            style={{ float: "left" }}
            key={index}
          >            
            {(item.label !== "" && item !== undefined) ? 
            (<span className="tag--title">{(language == "en-US" || "En") ? item.label : item.label_Fr}</span>) : 
            (<span className="tag--title"></span>)}
            {includedInDashboard && (
            <IconButton onClick={() => removeSelectedTagOnSearch(item)}>
              <CloseIcon fontSize="small" />
            </IconButton>)}            
          </li>
        ))}
      {tags && tags.length > 0 && !comboID &&
        tags
          .filter(t => t !== " ")
          .map((item, index) => (
            <li
              className={checkSelectedTag(item)}
              style={{ float: "left", cursor: "default" }}
              key={index}
            >
              {(item !== "" && item !== undefined) ? (<span className="tag--title">{item}</span>) : 
              (<span className="tag--title"></span>)}
            </li>
          ))}
    </div>
  );
};

Tags.propTypes = {
  tags: PropTypes.any,
  GetformData: PropTypes.any,
  comboID: PropTypes.any,
  checkInfo: PropTypes.any,
  includedInSearch: PropTypes.bool
};

export default Tags;
