import axios from "axios";

export function postSubscription(requests, channelId) {
    requests = requests.replace("{channelId}", channelId);
    return new Promise((resolve, reject) => {
        axios.post( requests, "" ,{withCredentials: true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function deleteSubscription(requests, channelId) {  
  requests = requests.replace("{channelId}", channelId);
    return new Promise((resolve, reject) => {
        axios.delete(requests,{withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getSubscriptions(requests) {
    return new Promise((resolve, reject) => {
        axios.get(requests, {withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getSpeakerSubscriptions(requests, id) {
    return new Promise((resolve, reject) => {
        axios.get(requests, {withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function postSpeakerSubscription(requests, id) {
  requests = requests.replace("{speakerId}", id);
    return new Promise((resolve, reject) => {
        axios.post(requests, {}, {withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function deleteSpeakerSubscription(requests, id) {
    requests = requests.replace("{speakerId}", id);
    return new Promise((resolve, reject) => {
        axios.delete(requests, {withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}
export function getChannelSubscriptions(requests) {
    return new Promise((resolve, reject) => {
        axios.get(requests, {withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function postChannelSubscription(requests, id) {
    requests = requests.replace("{channelId}", id);
    return new Promise((resolve, reject) => {
        axios.post(requests, {}, {withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function deleteChannelSubscription(requests, id) {
    requests = requests.replace("{channelId}", id);
    return new Promise((resolve, reject) => {
        axios.delete(requests, {withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getChannelCount(requests, id){
    requests = requests.replace("{id}",id)
    return new Promise((resolve, reject) => {
        axios.get(requests, {withCredentials:true}).then((response) => {
            if (response.status === 500) {
                reject(response.data);
            }
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    })
}