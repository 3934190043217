//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**                                                                                                              *
 *                                              * Admin Users Dashboard Page *                                   *
 * ?                      An admin user can see all the users, this page is where they will find them            *
 */
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect, useMemo } from "react";
import GenericSearchSelect from 'react-select';
import countryList from 'react-select-country-list';
import { useSelector } from "react-redux";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from "moment";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "react-ios-switch";
import { useOutletContext } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from 'react-toastify';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import "jspdf-autotable";
import jsPDF from "jspdf";
import Papa from "papaparse";
import "@react-pdf-viewer/core/lib/styles/index.css";
import 'react-toastify/dist/ReactToastify.css';
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import requests from "../components/axios/Requests";
import { 
    addRole, 
    removeRole, 
    getAccountGroups, 
    getRoles, 
    addNewUser, 
    getUsers, 
    changeUserActiveStatus, 
    doDeleteAdminUser 
} from "../servicesRedux/AdminService";

import { truncate } from "../hooks/utils.js";
import useDebounce from "../hooks/Debounce";

import ExampleCSV from "../assets/people.csv";
import "../theme/dashboard/dashboard.scss";

const DashboardUsers = () => {
    const { 
        queryOnDashboardUsers, 
        filterBasedOnRoleMobileView, 
        selectedRoleOnMobileLayout,
        queryOnCustomerChange,
        companiesList,
        myCompany
    } = useOutletContext();
    const countryOptions = useMemo(() => countryList().getData(), []);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);  
    const { translation } = useSelector(state => state.languageReducer);    
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const pageParams = { limit: 15 };
    const [currentFilter, setCurrentFilter] = useState("");
    const [isLoading, setIsLoading] = useState();
    const columns = [
        { id: 'createdOn', label: 'Creation', minWidth: 125 },
        { id: 'lastActivity', label: 'Last Activity', minWidth: 125 },
        { id: 'email', label: 'Email', minWidth: 150 },
        { id: 'firstName', label: 'First Name', minWidth: 150 },
        { id: 'lastName', label: 'Last Name', minWidth: 150 },        
        { id: 'company', label: 'Company', minWidth: 150 },
        { id: 'role', label: 'Role (s)', minWidth: 125 },
        { id: 'action', label: 'Action', minWidth: 110 },
        { id: 'active', label: 'Active', minWidth: 75 },
    ];
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const debouncedSearchTerm = useDebounce((queryOnDashboardUsers), 500);
    const [filterColumn, setFilterColumn] = useState("firstName");
    const [roles, setRoles] = useState([]);
    const [orderType, setOrderType] = useState(false);
    const [openDeleteConfirmaitonModal, setOpenDeleteConfirmaitonModal] = useState(false);    
    const [sortColumnId, setFilterColumnId] = useState(5);
    const [accountId, setAccountId] = useState(queryOnCustomerChange);
    const [roleName, setRoleName] = useState("");
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [changedTheRoleEditView, setChangedTheRoleEditView] = useState(false);
    const [accountIdToSetRoles, setAccountIdToSetRoles] = useState("");
    const [content, setContent] = useState({
        firstName: "",
        lastName: "",
        email: "",
        language: "",
        title: "",
        country: "",
        group: "",
        accountId: ""
    });
    const [rolesWithIds, setRolesWithIds] = useState([]);
    const [selectedUserRoles, setSelectedUserRoles] = useState([]);
    const [tabValue, setTabValue] = useState("1");
    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedGroup, setSelectedGroup] = useState({});
    const [accountGroups, setAccountGroups] = useState([]);
    const [submitted, setSubmitted] = useState(false);    
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [sortingStarted, setSortingStarted] = useState("");
    const [defaultRoleManipulated, setDefaultRoleManipulated] = useState([]);
    const [searchStarted, setSearchStarted] = useState(false);
    const languages = ([
        { label: "English (United States)", value: "en-US" },
        { label: "French (France)", value: "fr-FR" },
        { label: "Spanish (Spain)", value: "es-ES" },
    ]);
    const [multipleUsersBulk, setMultipleUsersBulk] = useState([]);
    const [multipleUsersBulkErrors, setMultipleUsersBulkErrors] = useState([]);
    const [isValidCSVFileToProcess, setIsValidCSVFileToProcess] = useState(false);
    const savedRolesInAppState = JSON.parse(Cookies.get("roles"));

    useEffect(() => {
        setIsLoading(true);
        getUsers(requests.Users, debouncedSearchTerm, sortColumnId, false, pageParams, page, accountId, roleName).then(items => {
            setUsers(items.data);
            setRowsPerPage(items.data.length);
            setRoles(helperToRenameSpecificRoleName(items.roles));
            setTotalRecords(items.totalItems);
            setTotalPages(items.totalPages);
            getRoles(requests.GetAllRoles).then(roles => setRolesWithIds(helperToRenameSpecificRoleName(roles)));
            setIsLoading(false);
        });  
    }, []);

    useEffect(() => {
        if (debouncedSearchTerm !== undefined && debouncedSearchTerm.length > 2) {
            setSearchStarted(true);
            getUsers(requests.Users, debouncedSearchTerm, sortColumnId, !orderType, pageParams, page, accountId, roleName).then(items => {
                setUsers(items.data);
                setRowsPerPage(items.data.length);
                setRoles(helperToRenameSpecificRoleName(items.roles));
                setTotalPages(items.totalPages);
                setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
                setIsLoading(false);
                setChangedTheRoleEditView(false);
            });  
        }
        if (searchStarted && queryOnDashboardUsers === '' && debouncedSearchTerm !== undefined && debouncedSearchTerm.length === 0) {
            getUsers(requests.Users, debouncedSearchTerm, sortColumnId, !orderType, pageParams, page, accountId, roleName).then(items => {
                setUsers(items.data);
                setRowsPerPage(items.data.length);
                setRoles(helperToRenameSpecificRoleName(items.roles));
                setTotalPages(items.totalPages);
                setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
                setSearchStarted(false);
                setIsLoading(false);
                setChangedTheRoleEditView(false);
            }); 
        }
    }, [debouncedSearchTerm]);
    
    useEffect(() => {
        if (queryOnCustomerChange !== "All" && queryOnCustomerChange !== "") {
            setAccountId(queryOnCustomerChange);
            setIsLoading(true);
            getUsers(requests.Users, debouncedSearchTerm, sortColumnId, !orderType, pageParams, 1, queryOnCustomerChange, roleName).then(items => {
                setPage(1);
                setUsers(items.data);
                setRowsPerPage(items.data.length);
                setRoles(helperToRenameSpecificRoleName(items.roles));
                setTotalPages(items.totalPages);
                setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
                setIsLoading(false);
                setChangedTheRoleEditView(false);
            });    
        } else if (queryOnCustomerChange !== "") {
            setIsLoading(true);
            getUsers(requests.Users, debouncedSearchTerm, sortColumnId, !orderType, pageParams, 1, "", "").then(items => {
                setPage(1);
                setUsers(items.data);
                setRowsPerPage(items.data.length);
                setRoles(helperToRenameSpecificRoleName(items.roles));
                setTotalPages(items.totalPages);
                setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
                setIsLoading(false);
                setChangedTheRoleEditView(false);
            });  
        }
    }, [queryOnCustomerChange, roleName]);    
    
    useEffect(() => {
        setCurrentFilter(selectedRoleOnMobileLayout)  
    }, [selectedRoleOnMobileLayout]);

    const isValidEmail = email => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const helperToRenameSpecificRoleName = roles => {
        const getIndexRole = roles && roles.findIndex(obj => obj.name === "Curator-Manager");
        roles[getIndexRole].name = "Chief Curator";
        return roles;
    };

    const handleClickOpenDeleteConfirmationModal = id => {
        setSelectedUser(id);
        setOpenDeleteConfirmaitonModal(true)
    };

    const handleClickCloseDeleteModal = () => {
        setSelectedUser(null);
        setOpenDeleteModal(false);
    };

    const handleClickCloseDeleteConfirmationModal = () => {
        setSelectedUser(null);
        setOpenDeleteConfirmaitonModal(false);
    };

    const handleClickDeleteConfirmationModal = () => {
        setOpenDeleteConfirmaitonModal(false);
        setOpenDeleteModal(true);
    };
      
    const handleChangePage = (_, newPage) => {
        setIsLoading(true);
        setPage(newPage);
        getUsers(requests.Users, debouncedSearchTerm, sortColumnId, orderType, pageParams, newPage, accountId, roleName).then(items => {
            setUsers(items.data);
            setRowsPerPage(items.data.length);
            setRoles(helperToRenameSpecificRoleName(items.roles));
            setTotalPages(items.totalPages);
            setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
            setIsLoading(false);
        }); 
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };

    const changeTheUserActiveStatus = (status, id) => {
        setIsLoading(true);
        setUsers(prevUsers => prevUsers.map(item => item.accountUserId === id ? {...item, isActive: status} : item ));
        changeUserActiveStatus(requests.ChangeUserStatus, id, status).then(_ => setIsLoading(false));
    };

    const filterBasedOnRole = role => {
        setUsers([]);
        setIsLoading(true);  
        setRoleName(role);
        setCurrentFilter(role);
        setPage(1);
        getUsers(requests.Users, debouncedSearchTerm, sortColumnId, orderType, pageParams, 1, queryOnCustomerChange, role).then(items => {
            setUsers(items.data);
            setRowsPerPage(items.data.length);
            setRoles(helperToRenameSpecificRoleName(items.roles));
            setTotalPages(items.totalPages);
            setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
            setIsLoading(false);
        });
    };

    const deleteAdminUser = () => {
        setIsLoading(true);
        setOpenDeleteModal(false);
        doDeleteAdminUser(requests.DeleteAdminUser, selectedUser).then(items => {
            getUsers(requests.Users, debouncedSearchTerm, sortColumnId, orderType, pageParams, page, accountId, roleName).then(items => {
                setUsers(items.data);
                setRowsPerPage(items.data.length);
                setRoles(helperToRenameSpecificRoleName(items.roles));
                setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
                setTotalPages(items.totalPages);
                getRoles(requests.GetAllRoles).then(roles => setRolesWithIds(helperToRenameSpecificRoleName(roles)));
                setIsLoading(false);
            }); 
        }).catch(e => {
            setSelectedUser(null);
            setIsLoading(false);
        }); 
    };

    const modifyTheRole = (userId, defaultRole) => {
        setAccountIdToSetRoles(userId);
        const defaultRoleManipulatedTemp = defaultRole.split(',');
        setDefaultRoleManipulated(defaultRoleManipulatedTemp);         
        setSelectedUserRoles(rolesWithIds.map(r => {
            const rl = {
                ...r,
                checked: defaultRoleManipulatedTemp.includes(r.name) ? true : false
            }
            return rl;
        }));
        setChangedTheRoleEditView(true);
    };

    const backToNormalUsersListing = () => {
        setChangedTheRoleEditView(false);
        setAccountIdToSetRoles(null);
        setIsLoading(true);
        getUsers(requests.Users, debouncedSearchTerm, sortColumnId, !orderType, pageParams, page, accountId, roleName).then(items => {
            setUsers(items.data);
            setRowsPerPage(items.data.length);
            setRoles(helperToRenameSpecificRoleName(items.roles));
            setTotalPages(items.totalPages);
            setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
            setIsLoading(false);
        });        
    };

    const handleChangeMultipleCheckboxesMenu = (userId, newVal, defaultRole, company) => {
        const {
            target: { value },
        } = newVal;
        const newRole = value.pop();
        const currentLoggedUserRole = JSON.parse(Cookies.get("roles"));   
        if (
            (currentLoggedUserRole.indexOf("Admin") === -1 && newRole.name === "Admin") || 
            (currentLoggedUserRole.indexOf("Manager") > -1 && newRole.name === "Curator") || 
            (currentLoggedUserRole.indexOf("Manager") > -1 && newRole.name === "Chief Curator") ||
            (currentLoggedUserRole.indexOf("Admin") > -1 && newRole.name === "Admin" && company !== myCompany)
        ) {
            toast("Not allowed!");
        } else {
            if (defaultRoleManipulated.indexOf(newRole.name) === -1) {         
                setIsLoading(true);  
                setDefaultRoleManipulated([
                    ...defaultRoleManipulated,
                    newRole.name
                ]); 
                setSelectedUserRoles(prevSelectedUserRoles => prevSelectedUserRoles.map(r => {
                    const rl = {
                        ...r,
                        checked: (r.name === newRole.name || r.name === defaultRole || r.checked) ? true : false
                    }
                    return rl;
                }));  
                addRole(requests.AdminAddRole, userId, newRole.id).then(_ => setIsLoading(false));
            } else {
                setIsLoading(true);  
                const defaultRoleManipulatedTemp = defaultRole.split(',');
                setDefaultRoleManipulated(defaultRoleManipulatedTemp.filter(r => r != newRole.name));
                setSelectedUserRoles(prevSelectedUserRoles => prevSelectedUserRoles.map(r => {
                    const rl = {
                        ...r,
                        checked: (newRole.name === r.name && r.checked) ? false : r.checked
                    }
                    return rl;
                })); 
                removeRole(requests.AdminRemoveRole, userId, newRole.id).then(_ => setIsLoading(false));
            }
        }           
    };

    const renderRole = (defaultRole, userId, company) => {
        let roleTextContainer = "";
        const regex = /Curator-Manager/gi;
        const currentLoggedUserRole = JSON.parse(Cookies.get("roles"));                
        const roleToBeChanged = defaultRole.replace(regex, 'Chief Curator');        
        if ((currentLoggedUserRole.indexOf("Admin") > -1) || (currentLoggedUserRole.indexOf("Manager") > -1)) {
            return (
                roleTextContainer = (changedTheRoleEditView && (accountIdToSetRoles === userId)) ?
                <div className="role__edit_select_container">
                    <FormControl sx={{ m: 1, width: 150 }}>
                        <InputLabel>{translation.role}</InputLabel>
                        <Select
                            multiple
                            MenuProps={MenuProps}
                            value={rolesWithIds.filter(e => e.name === roleToBeChanged)}
                            onChange={event => handleChangeMultipleCheckboxesMenu(userId, event, roleToBeChanged, company)}
                            input={<OutlinedInput label="Role(s)" />}                            
                        >                            
                            {selectedUserRoles && selectedUserRoles.map(role => (
                                <MenuItem className={`${((isLoading || currentLoggedUserRole.indexOf("Admin") === -1 && role.name === "Admin") || (currentLoggedUserRole.indexOf("Manager") > -1 && role.name === "Curator") || (currentLoggedUserRole.indexOf("Manager") > -1 && role.name === "Chief Curator") || (currentLoggedUserRole.indexOf("Admin") > -1 && role.name === "Admin" && company !== myCompany)) ? (isLoading ? 'please__wait_status' : 'disabled__menu_item') : ''}`} value={role}>
                                    <ListItemText className="role__name_list_text" primary={role.name} />                                    
                                    <Checkbox disabled={(isLoading || currentLoggedUserRole.indexOf("Admin") === -1 && role.name === "Admin") || (currentLoggedUserRole.indexOf("Manager") > -1 && role.name === "Curator") || (currentLoggedUserRole.indexOf("Manager") > -1 && role.name === "Chief Curator") || (currentLoggedUserRole.indexOf("Admin") > -1 && role.name === "Admin" && company !== myCompany)} checked={role.checked} />                                
                                </MenuItem>
                            ))}
                        </Select>                                            
                    </FormControl>
                    <Button className="role__edit_btn_default_btn add__users_btn" onClick={backToNormalUsersListing}>{translation.Conference_Save}</Button>
                </div> : 
                <span className="role__text_container">
                    <ModeEditOutlineOutlinedIcon onClick={() => modifyTheRole(userId, roleToBeChanged)} className="edit__item_users" />
                    <span>{roleToBeChanged}</span>
                </span>
            );
        } else {
            roleTextContainer = <span className="role__text_container">{roleToBeChanged}</span>;
        }        
        return roleTextContainer;
    };

    const renderRowsOnUsersTable = row => {
        return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.code} style={{ height: 50 }}>
                <TableCell>{moment(row.createdOn).utc().format('MM-DD-YYYY HH:mm')}</TableCell> 
                <TableCell>
                    <span className="last__activity_highlither">{moment(row.lastActivity).utc().format('MM-DD-YYYY')}</span>
                    {" "}
                    {moment(row.lastActivity).utc().format('HH:mm')}
                </TableCell>                                            
                <TableCell>{row.email.toLowerCase()}</TableCell>                                            
                <TableCell>{`${truncate(row.firstName, 15)}`}</TableCell>                                            
                <TableCell>{`${truncate(row.lastName, 15)}`}</TableCell>                                            
                <TableCell>{`${truncate(row.company, 15)}`}</TableCell>                                            
                <TableCell>{renderRole(row.roles, row.userId, row.company)}</TableCell>                                            
                <TableCell className="actions_td">
                    <DeleteForeverOutlinedIcon 
                        fontSize="large" 
                        className="normal"
                        onClick={() => handleClickOpenDeleteConfirmationModal(row.accountUserId)}                         
                    />
                </TableCell>                                            
                <TableCell className="actions_td active__data-column-users-page">
                    <Switch                                                                        
                        offColor="red"             
                        handleColor="white"
                        checked={row.isActive}
                        style={{ height: 25 }}           
                        onColor="rgb(76, 217, 100)"                        
                        onChange={checked => changeTheUserActiveStatus(checked, row.accountUserId)}
                        className={`userStatusSwitch ${row.isActive ? 'userStatusSwitch_on' : 'userStatusSwitch_off'}`}
                    />
                </TableCell>                                            
            </TableRow>
        );
    };

    const onChange = event => {
        setIsLoading(true);
        setContent({
          ...content,
          [event.target.name]: event.target.value,
        });
    };

    const handleChangeCustomer = event => {
        const accountId = event.target.value;
        setSelectedCompany(event.target.value);   
        setContent({
            ...content,
            accountID: accountId?.id || accountId,
        }); 
        getAccountGroups(requests.GetAllGroupsPerAccount, (accountId?.id || accountId)).then(e => setAccountGroups(e));
    };

    const handleChangeGroup = event => {
        const group = event.target.value;
        setContent({
            ...content,
            group: group?.id,
        });     
        setSelectedGroup(event.target.value)
    };

    const changeCountrySelection = e => {
        setContent({
            ...content,
            country: e.label,
        });
        setSelectedCountry(e);
    };

    const changeLanguageSelection = e => {
        setContent({
            ...content,
            language: e.target.value,
        });
        setSelectedLanguage(e.target.value);
    };

    const handleAddUserModalBox = () => {
        if (companiesList && companiesList[0]) {
            setSelectedCompany(companiesList[0]);
            getAccountGroups(requests.GetAllGroupsPerAccount, companiesList[0]?.id).then(e => {
                setAccountGroups(e);
                setContent({
                    ...content,
                    group: e[0] ? e[0]?.id : "",
                });  
                e && setSelectedGroup(e[0]);
                setShowAddUserModal(true);
            });
        }        
    };

    const hideTheUserAddModal = () => {
        clearForm();
        setShowAddUserModal(false);        
        setSubmitted(false);
        setTabValue("1");
        setMultipleUsersBulk([]);
        setMultipleUsersBulkErrors([]);    
        setIsValidCSVFileToProcess(false);
    }; 

    const submitAdminUser = () => {   
        setSubmitted(true);         
        if (
            content.firstName && 
            content.lastName && 
            (content.email && isValidEmail(content.email)) &&
            content.language && 
            content.country
        ) {            
            setIsLoading(true);
            if (content.accountId === "") content.accountId = selectedCompany?.id;
            addNewUser(requests.AdminAddUser, content).then(_ => {                
                setIsLoading(false);  
                setSubmitted(false);
                setShowAddUserModal(false);                
                clearForm();
                toast("New user has been added!");
                getUsers(requests.Users, debouncedSearchTerm, 5, false, pageParams, page, accountId, roleName).then(items => {
                    setUsers(items.data);
                    setRowsPerPage(items.data.length);
                    setRoles(helperToRenameSpecificRoleName(items.roles));
                    setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
                    setTotalPages(items.totalPages);
                    getRoles(requests.GetAllRoles).then(roles => setRolesWithIds(helperToRenameSpecificRoleName(roles)));
                    setIsLoading(false);
                });                  
            });
        } else {
            setTimeout(() => {
                setSubmitted(false);   
            }, 1500);           
        }
    };
    
    const submitAdminUserBulk = () => {   
        setIsLoading(true);  
        setSubmitted(true); 
        addNewUser(requests.AdminAddUser, multipleUsersBulk).then(r => {
            const errorObjects = (r.error.map(e => {
                return {
                    error: e
                }
            }));
            setMultipleUsersBulkErrors(errorObjects);
            getUsers(requests.Users, debouncedSearchTerm, 5, true, pageParams, page, accountId, roleName).then(items => {
                setUsers(items.data);
                setRowsPerPage(items.data.length);
                setRoles(helperToRenameSpecificRoleName(items.roles));
                setTotalRecords(items.totalItems);
                setTotalPages(items.totalPages);
                getRoles(requests.GetAllRoles).then(roles => setRolesWithIds(helperToRenameSpecificRoleName(roles)));
                setIsLoading(false);  
                setSubmitted(false); 
                const howManySuccessUsers = multipleUsersBulk.length - r.error.length;
                if (howManySuccessUsers === 0) {
                    toast(`Couldn't process the request! Please check the error report.`);    
                } else {
                    toast(`${(multipleUsersBulk.length - r.error.length)} users has been added!`);
                }                
            });                      
        }); 
    };

    const clearForm = () => {
        setContent({
            firstName: "",
            lastName: "",
            email: "",
            language: "",
            title: "",
            country: "",
            group: "",
            accountId: ""
        });
        setSelectedCompany("");
        setSelectedCountry("");
        setSelectedGroup("");
        setSelectedLanguage("");
    };

    const handleSort = column => {
        let columnId = null;
        switch(column) {
            case "":
                columnId = 0;
            case "firstName":
                columnId = 1;
                break;
            case "lastName":
                columnId = 2;
                break;       
            case "email":
                columnId = 3;
                break;      
            case "lastActivity":
                columnId = 4;
                break;                                         
            case "createdOn":
                columnId = 5;
                break;                  
            case "company":
                columnId = 7;
                break;                                  
        }
        setUsers([]);
        setIsLoading(true);
        setFilterColumn(column);
        setFilterColumnId(columnId);
        setSortingStarted(true);
        setOrderType(sortingStarted ? !orderType : orderType);
        getUsers(requests.Users, debouncedSearchTerm, columnId, (sortingStarted ? !orderType : orderType), pageParams, page, queryOnCustomerChange, roleName).then(items => {
            setUsers(items.data);
            setRowsPerPage(items.data.length);
            setTotalRecords(items.roles.filter(r => r.name === "All")[0].count);
            setTotalPages(items.totalPages);
            setIsLoading(false);
        });        
    };

    const renderTheRoleCount = roleName => {
        const regex = /Curator-Manager/gi;
        const roleToBeChanged = roleName.replace(regex, 'Chief Curator');   
        const role = roles && roles.filter(e => e.name === roleToBeChanged);
        if (role.length > 0) {
            return '(' + role[0]?.count + ')';
        } else {
            return '';
        }
    };

    const handleTabChange = (_, newValue) => setTabValue(newValue);  

    const changeHandler = event => {
        let tempNewUserRecord = {};
        let isValidRecord = true;
        if (event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || event.target.files[0].type === "text/csv") {
            Papa.parse(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    const rowsArray = [];
                    const valuesArray = [];

                    results.data.map(d => {
                        rowsArray.push(Object.keys(d));
                        valuesArray.push(Object.values(d));
                    });

                    setMultipleUsersBulk(results.data.map(e => {            
                        const dataKeys = Object.keys(e);
                        dataKeys.map(k => {
                            if (
                                (e[k] === '') && 
                                (
                                    k === 'firstName' || 
                                    k === 'lastName' || 
                                    k === 'email' || 
                                    k === 'language' || 
                                    k === 'country' ||
                                    !isValidEmail(e.email)
                                )
                            ) {
                                toast(`${k} is is missing or invalid for the email address of ${e.email}`); 
                                isValidRecord = false;
                                setIsValidCSVFileToProcess(false);
                                return;
                            }
                        });            
                        if (isValidRecord) {
                            if (selectedCompany !== "" && savedRolesInAppState.includes("Admin")) {
                                tempNewUserRecord.accountID = selectedCompany;
                            }
                            setIsValidCSVFileToProcess(true);
                            tempNewUserRecord = e;
                            if (!roles.includes(e.role)) {                    
                                tempNewUserRecord.role = "User";
                                return tempNewUserRecord;
                            } else if (e.role === "Admin") {
                                tempNewUserRecord.role = "Manager";
                                return tempNewUserRecord;
                            } else {
                                return tempNewUserRecord;
                            }
                        } else {
                            setIsValidCSVFileToProcess(false);
                            toast('Invalid file format! or missing required data');
                        }
                    }));                     
                },
            });             
        } else {
            toast('Invalid file format!');
        }              
    };

    const downloadErrorReport = () => {
        let rows = [];
        const JsonObject = JSON.parse(JSON.stringify(multipleUsersBulkErrors));        
        const pdf = new jsPDF("p", "pt", "a4");
        const columns = [
          "Errors",
        ];        
        for (let i = 0; i < JsonObject.length; i++) {
            let temp = [
                JsonObject[i].error,
            ];
            rows.push(temp);
        }
        pdf.text(235, 40, "Error report");
        pdf.autoTable(columns, rows, {
            startY: 65,
            theme: "grid",
            styles: {
                font: "times",
                halign: "left",
                cellPadding: 3.5,
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                textColor: [0, 0, 0]
            },
            headStyles: {
                textColor: [0, 0, 0],
                fontStyle: "normal",
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                fillColor: [166, 204, 247]
            },
            alternateRowStyles: {
                fillColor: [212, 212, 212],
                textColor: [0, 0, 0],
                lineWidth: 0.5,
                lineColor: [0, 0, 0]
            },
            rowStyles: {
                lineWidth: 0.5,
                lineColor: [0, 0, 0]
            },
            tableLineColor: [0, 0, 0]
        });
        pdf.save("Error Report");
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="dashboard__users_wrapper dashboard__users_wrapper-extended">                            
                <div className="dashboard__left_panel">
                    <nav aria-label="secondary mailbox folders">                        
                        <List>
                            <ListItem>                               
                                <button type="button" className="add__users_btn" onClick={handleAddUserModalBox}>
                                    {translation.Add_User}
                                </button>                               
                            </ListItem> 
                            <ListItem className="only__mobile">
                                <Box sx={{ minWidth: 150 }}>
                                    <FormControl fullWidth className="role__mobile_drop_down">
                                        <InputLabel>{translation.role}</InputLabel>
                                        <Select
                                            label="role"
                                            value={currentFilter}                                            
                                            onChange={filterBasedOnRoleMobileView}
                                        >
                                            <MenuItem value={''}>{translation.all}</MenuItem>
                                            <MenuItem value={'Admin'}>{translation.admin}</MenuItem>
                                            <MenuItem value={'Manager'}>{translation.manager}</MenuItem>
                                            <MenuItem value={'Curator'}>{translation.curator}</MenuItem>
                                            <MenuItem value={'Chief Curator'}>{translation.chief_curator}</MenuItem>
                                            <MenuItem value={'User'}>{translation.user}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>                                
                            </ListItem>                           
                            <ListItem className="only__desktop">
                                <ListItemButton onClick={() => filterBasedOnRole('')} selected={currentFilter === ""} component="button">
                                    <ListItemText primary={`${translation.all} (${totalRecords})`} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem className="only__desktop">
                                <ListItemButton onClick={() => filterBasedOnRole('Admin')} selected={currentFilter === "Admin"} component="button">
                                    <ListItemText primary={`${translation.admin} ${renderTheRoleCount("Admin")}`} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem className="only__desktop">
                                <ListItemButton onClick={() => filterBasedOnRole('Manager')} selected={currentFilter === "Manager"} component="button">
                                    <ListItemText primary={`${translation.manager} ${renderTheRoleCount("Manager")}`} />
                                </ListItemButton>
                            </ListItem>
                            {roles.filter(e => e.name === "Curator")[0]?.count != 0 &&
                            <ListItem className="only__desktop">
                                <ListItemButton onClick={() => filterBasedOnRole('Curator')} selected={currentFilter === "Curator"} component="button">
                                    <ListItemText primary={`${translation.curator} ${renderTheRoleCount("Curator")}`} />
                                </ListItemButton>
                            </ListItem>}
                            {roles.filter(e => (e.name === "Curator-Manager" || e.name === "Chief Curator"))[0]?.count != 0 &&
                            <ListItem className="only__desktop">
                                <ListItemButton onClick={() => filterBasedOnRole('Curator-Manager')} selected={currentFilter === "Curator-Manager"} component="button">
                                    <ListItemText primary={`${translation.chief_curator} ${renderTheRoleCount("Curator-Manager")}`} />
                                </ListItemButton>
                            </ListItem>}
                            <ListItem className="only__desktop">
                                <ListItemButton onClick={() => filterBasedOnRole('User')} selected={currentFilter === "User"} component="button">
                                    <ListItemText primary={`${translation.user} ${renderTheRoleCount("User")}`} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>                                        
                </div>
                <div className="dashboard__right_panel">                     
                    <Paper className="table__paper" sx={{ width: '100%', overflow: 'hidden', backgroundColor: '#f8f7f6' }}>
                        <div className="display__loader">
                            {isLoading && <LinearProgress color="inherit" className="progress_bar" />}
                        </div> 
                        {users &&
                        <>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(column => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                    {...((column.id == "createdOn" || column.id == "firstName" || column.id == "lastName" || column.id == "email" || column.id == "company" || column.id == "lastActivity") ? { onClick: () => { handleSort(column.id) }} : {})}
                                                    className={`${column.id == "createdOn" || column.id == "firstName" || column.id == "lastName" || column.id == "email" || column.id == "company" || column.id == "lastActivity" ? 'users__table_header_column' : 'users__table_header_normal_data_column'}`}
                                                >
                                                    <span>{column.label}</span>
                                                    {(column.id == "createdOn" || column.id == "firstName" || column.id == "lastName" || column.id == "email" || column.id == "company" || column.id == "lastActivity") ?
                                                    (orderType === true && (sortingStarted && filterColumn === column.id)) ? <ArrowDropUpIcon fontSize="medium" /> : <ArrowDropDownIcon fontSize="medium" /> : null}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(users && currentFilter !== '') && users.filter(item => item.roles.indexOf(currentFilter) > -1).map(row => renderRowsOnUsersTable(row))}
                                        {(users && currentFilter === '') && users.map(row => renderRowsOnUsersTable(row))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={totalRecords}
                                rowsPerPage={rowsPerPage}                           
                                rowsPerPageOptions={[]}
                                onPageChange={handleChangePage}
                                labelRowsPerPage=''
                                page={page}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                backIconButtonProps={{ disabled: page === 1 }}
                                nextIconButtonProps={{ disabled: page >= totalPages }}
                            />
                            {totalPages > 0 && <div className="rows__page_by_page_wrapper">{'page ' + page + ' of ' + totalPages}</div>}
                        </>}                        
                    </Paper>

                    <Dialog open={openDeleteModal} onClose={handleClickCloseDeleteModal}>
                        <DialogTitle>{translation.confirm_user_deletion}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{translation.delete_first_question}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClickCloseDeleteModal}>{translation.Cancel}</Button>
                            <Button onClick={deleteAdminUser}>{translation.Yes}</Button>
                        </DialogActions>
                    </Dialog> 

                    <Dialog open={openDeleteConfirmaitonModal} onClose={handleClickCloseDeleteConfirmationModal}>
                        <DialogTitle>{translation.delete_user}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{translation.delete_second_question}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClickCloseDeleteConfirmationModal}>{translation.Cancel}</Button>
                            <Button onClick={handleClickDeleteConfirmationModal}>{translation.Yes}</Button>
                        </DialogActions>
                    </Dialog>     

                    <Dialog 
                        open={showAddUserModal} 
                        disableAutoFocus
                        keepMounted
                        disableEscapeKeyDown
                    >
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                        <Tab label={translation.Add_User} value="1" />                                    
                                        <Tab label="Users CSV upload" value="2" />                                    
                                    </TabList>
                                </Box>
                                <TabPanel value="1">                                    
                                    <DialogContent className="add__user_DialogContent">                            
                                        <TextField 
                                            onChange={onChange} 
                                            name="firstName" 
                                            label={translation.First_Name}
                                            value={content.firstName}
                                            sx={{mb: 3}} 
                                            fullWidth 
                                            size="small"
                                            className={`admin__account__details--input admin__account__details--input--extended ${
                                                (submitted && 
                                                  (content?.firstName === undefined || 
                                                  content?.firstName === null || 
                                                  content?.firstName === '')
                                                ) ? 'error__border_indicator' : ''}`}
                                        />
                                        <TextField 
                                            onChange={onChange} 
                                            name="lastName" 
                                            label={translation.Last_Name}
                                            sx={{mb: 3}} 
                                            fullWidth 
                                            size="small"
                                            value={content.lastName}
                                            className={`admin__account__details--input admin__account__details--input--extended ${
                                                (submitted && 
                                                  (content?.lastName === undefined || 
                                                  content?.lastName === null || 
                                                  content?.lastName === '')
                                                ) ? 'error__border_indicator' : ''}`}
                                        />
                                        <TextField 
                                            onChange={onChange} 
                                            name="email" 
                                            label={translation.Email}
                                            sx={{mb: 3}} 
                                            fullWidth 
                                            size="small"
                                            value={content.email}
                                            className={`admin__account__details--input admin__account__details--input--extended ${
                                                (submitted && 
                                                  (content?.email === undefined || 
                                                  content?.email === null || 
                                                  content?.email === '') ||
                                                  (content?.email && !isValidEmail(content?.email))
                                                ) ? 'error__border_indicator' : ''}`}
                                        />                                        
                                        <Box sx={{ minWidth: 150 }}>
                                            <FormControl fullWidth className="customer__mobile_drop_down" style={{ marginBottom: 5 }}>
                                                <InputLabel>{"Select the language"}</InputLabel>
                                                <Select
                                                    label="Select the language"
                                                    onChange={changeLanguageSelection}
                                                    value={selectedLanguage}
                                                    style={{
                                                        height: 40
                                                    }}
                                                    className={`countries__drop_down_selection language__drop_down_selection ${
                                                        (submitted && 
                                                          (content?.language === undefined || 
                                                          content?.language === null || 
                                                          content?.language === '')
                                                        ) ? 'error__border_indicator_custom' : ''}`}                                                
                                                >
                                                {languages.map(e => <MenuItem value={e.value}>{e.label}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <TextField 
                                            onChange={onChange} 
                                            name="title" 
                                            label={translation.Title_Example} 
                                            sx={{mb: 3}} 
                                            fullWidth 
                                            size="small"
                                            value={content.title}
                                            className={`admin__account__details--input admin__account__details--input--extended`}
                                        />                                          
                                        <GenericSearchSelect 
                                            options={countryOptions} 
                                            value={selectedCountry} 
                                            onChange={changeCountrySelection} 
                                            placeholder={translation.Select_The_Country}
                                            className={`countries__drop_down_selection ${
                                                (submitted && 
                                                  (content?.country === undefined || 
                                                  content?.country === null || 
                                                  content?.country === '')
                                                ) ? 'error__border_indicator_custom' : ''}`}
                                        />
                                        <Box sx={{ minWidth: 150 }}>
                                            <FormControl fullWidth className="customer__mobile_drop_down" style={{ marginBottom: 15 }}>
                                                <InputLabel>{translation.Select_The_Company}</InputLabel>
                                                <Select
                                                    label={translation.Select_The_Company}
                                                    onChange={handleChangeCustomer}
                                                    value={selectedCompany}
                                                    style={{
                                                        height: 40
                                                    }}
                                                >
                                                {companiesList.map(e => <MenuItem value={e}>{e.label}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box sx={{ minWidth: 150 }}>
                                            <FormControl fullWidth className="customer__mobile_drop_down" style={{ marginBottom: 25 }}>
                                                <InputLabel>{translation.Select_The_Group}</InputLabel>
                                                <Select
                                                    label={translation.Select_The_Group}
                                                    onChange={handleChangeGroup}
                                                    value={selectedGroup}
                                                    style={{
                                                        height: 40
                                                    }}
                                                >
                                                {accountGroups.map(e => <MenuItem value={e}>{e.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={hideTheUserAddModal}>{translation.Suggestion_Cancel_Button}</Button>
                                        <button type="button" className="add__users_btn_in_modal" onClick={submitAdminUser}>
                                            {translation.Add_User}
                                            {submitted && <CircularProgress
                                                size={20} 
                                                color="inherit"
                                                className="progress_bar_circular"
                                            />}
                                        </button> 
                                    </DialogActions>                                    
                                </TabPanel>
                                <TabPanel value="2">
                                    <DialogContent className={`add__user_DialogContentDefault ${multipleUsersBulkErrors && multipleUsersBulkErrors.length > 1 ? 'add__user_DialogContent' : ''}`}>
                                        {(savedRolesInAppState.length > 0 && savedRolesInAppState.includes("Admin")) && 
                                        <Box sx={{ minWidth: 150 }} style={{ marginTop: 5 }}>
                                            <FormControl fullWidth className="customer__mobile_drop_down__users_section">
                                                <InputLabel>{translation.By_Customer}</InputLabel>
                                                <Select
                                                    label={translation.By_Customer}
                                                    onChange={handleChangeCustomer}
                                                    value={selectedCompany}
                                                    style={{ marginBottom: 15 }}
                                                >
                                                {companiesList.map(e => <MenuItem value={e.id}>{e.label}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Box>}                                        
                                        <input
                                            type="file"
                                            name="file"
                                            onChange={changeHandler}
                                            accept=".csv"
                                            style={{ display: "block", margin: "10px auto" }}
                                        />
                                         {multipleUsersBulk.length >= 1001 && (
               <div className="error-message" style={{ color: 'red' }}>
    File size exceeds 1000.
</div>

            )}
                                        {multipleUsersBulkErrors && multipleUsersBulkErrors.length > 1 &&
                                        <Card sx={{ minWidth: 275 }}>                                            
                                            <CardContent>
                                                {multipleUsersBulkErrors && multipleUsersBulkErrors.map(e => (
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>{e.error}</Typography>                                                
                                                ))}                                                
                                            </CardContent>           
                                            <CardActions>
                                                <Button size="small" onClick={downloadErrorReport}>Download Error Report</Button>
                                            </CardActions>                                 
                                        </Card>}
                                        <div className="sample_csv_download_btn_container">
                                            <a href={ExampleCSV} className="add__users_btn_in_modal" download="Example-CSV-document" target="_blank" rel="noreferrer">
                                                Download Sample .csv file
                                            </a>                                        
                                        </div>
                                    </DialogContent>
                                    <DialogActions style={{
                                        justifyContent: "space-between"
                                    }}>
                                        <Button onClick={hideTheUserAddModal}>{translation.Suggestion_Cancel_Button}</Button>
                                        <button disabled={multipleUsersBulk.length === 0 || multipleUsersBulk.length >= 1001 || !isValidCSVFileToProcess} type="button" className="add__users_btn_in_modal add__users_btn_in_modal_bulk" onClick={submitAdminUserBulk}>
                                            {'Upload'}
                                            {submitted && <CircularProgress
                                                size={20} 
                                                color="inherit"
                                                className="progress_bar_circular"
                                            />}
                                        </button>  
                                    </DialogActions>                                                                       
                                </TabPanel>                              
                            </TabContext>
                        </Box>                      
                    </Dialog> 
                </div>
            </div>
        </>
    );
};

export default DashboardUsers;
