import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@mui/material";
import TextfieldWithTime from "./TextfieldWithTime";
import { useDispatch, useSelector } from "react-redux";
import { GetNewContentAction } from "../../redux/action/GlobalActions";



export default function ChapterComponent(props) {
    const [chapters, setChapters] = useState([]);
    const { newContent } = useSelector(
        (state) => state.globalReducer
    );
    const dispatch = useDispatch();
    useEffect(() => {
        newContent.bookmark ?? setChapters([{ label: "Introduction", labelFr: "Introduction", time: "00:00:01" }])
    }, [])

    useEffect(() => {
        if (newContent.bookmark) {
            let oldChapters = JSON.parse(JSON.stringify(newContent.bookmark ?? []));
            oldChapters.forEach((chapter, index) => {
                if (chapter.time === undefined) {
                    chapter.time = toTime(chapter.timeIndex);
                }
            })
            setChapters(oldChapters)
        }
    }, [])


    function toSeconds(time) {
        var parts = time.split(':');
        return parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
    }

    //inverse of toSeconds
    function toTime(seconds) {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds - (hours * 3600)) / 60);
        var seconds = seconds - (hours * 3600) - (minutes * 60);
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        return hours + ':' + minutes + ':' + seconds;
    }

    const handleAddChapter = () => {
        setChapters([...chapters, { label: "", labelFr: "", time: "00:00:00" }])
    }

    const handleChangeChapter = (chapter, index) => {
        let newChapters = [...chapters]
        newChapters[index] = chapter
        setChapters(newChapters)
    }

    const handleRemoveChapter = (index) => {
        let newChapters = [...chapters]
        newChapters.splice(index, 1)
        setChapters(newChapters)
    }

    useEffect(() => {
        //create a copy of chapters and set all time in chapters to seconds and change field name to timeIndex
        let newChapters = JSON.parse(JSON.stringify(chapters))
        newChapters.forEach((chapter, index) => {
            chapter.timeIndex = toSeconds(chapter.time)
            //remove time
        })
        //sort chapters by timeIndex
        newChapters.sort((a, b) => a.timeIndex - b.timeIndex)
        //set new chapters to redux

        //delete time
        newChapters.forEach((chapter, index) => {
            delete chapter.time
        })

        //filter out chapters with empty label
        newChapters = newChapters.filter(chapter => chapter.label !== "")

        dispatch(GetNewContentAction(
            {
                ...newContent,
                bookmark: newChapters
            }
        ));
    }, [chapters])


    return (
        <>
            <Grid container alignItems="center">

                <Grid item xs={2} sx={{ display: "flex" }} alignItems="center">
                    <Typography
                        sx={{ fontSize: 18, marginLeft: 5, marginTop: 1 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Chapters
                    </Typography>
                    {!newContent.isTopCreamContent &&
                        <IconButton
                            variant="contained" size="small"
                            onClick={() => handleAddChapter()}
                        >
                            <AddIcon sx={{ fontSize: "30px" }} />
                        </IconButton>
                    }
                </Grid>

                <Grid item xs={10} sx={{ display: "flex", flexDirection: "column" }}>
                    {chapters.map((chapter, index) => (
                        <TextfieldWithTime
                            handleChangeChapter={handleChangeChapter}
                            handleRemoveChapter={handleRemoveChapter}
                            isTopCreamContent={newContent.isTopCreamContent}
                            index={index}
                            label={chapter.label}
                            labelFr={chapter.labelFr}
                            time={chapter.time}
                            duration={newContent.videoDuration}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    )
}