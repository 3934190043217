import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

import requests from "../../axios/Requests";
import { getComments } from "../../../servicesRedux/ConferenceService";
import {
  deleteCommentByIDAction,
  GetCommentsAction,
  UpdateCommentsByIDAction,
} from "../../../redux/action/CommentsAction";
import { getUser } from "../../../servicesRedux/AdminService";
import { GetUserAction } from "../../../redux/action/AdminAction";
import {
  deleteCommentByID,
  updateCommentByID,
} from "../../../servicesRedux/CommentService";

import CommentForm from "./CommentForm";
import Comment from "./Comment";

const Comments = props => {
  const { id, translation } = props;
  const dispatch = useDispatch();
  const [backendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const { user } = useSelector(state => state.adminReducer);
  const [commentsPage, setCommentsPage] = useState(1);
  const commentsLimit = 10;
  const { comments } = useSelector((state) => state.commentsReducer);

  // useEffect(() => {
  //   getUser(requests.GetUser).then(item => dispatch(GetUserAction(item)));
  // }, []);

  const getReplies = (commentId) =>
    backendComments
      .filter((backendComment) => backendComment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );

  const updateComment = (text, commentId) => {
    updateCommentByID(requests.PutComment, commentId, text);
    var temp = comments.data.find((c) => c.id === commentId);
    temp.body = text;
    dispatch(UpdateCommentsByIDAction(temp));
    setActiveComment(null);
  };

  const deleteComment = commentId => {
    dispatch(deleteCommentByIDAction(commentId));
    deleteCommentByID(requests.DeleteComment, commentId);
  };

  const showMore = () => {
    setCommentsPage((prev) => (prev <= comments.totalPages ? prev + 1 : prev));
    getComments(requests.GetComments, id, commentsPage, commentsLimit).then(item => {
        item = {
          ...item,
          data: [...comments.data, ...item.data],
        };
        dispatch(GetCommentsAction(item));
      }
    );
  };

  useEffect(() => {
    getComments(requests.GetComments, id, commentsPage, commentsLimit).then(
      (item) => {
        dispatch(GetCommentsAction(item));
      }
    );
    getUser(requests.GetUser).then((item) =>
      dispatch(GetUserAction(item))
    );
    setCommentsPage((prev) => prev + 1);
  }, []);

  return (
    <div className="comments__sections-wrapper">
      <CommentForm 
        submitLabel={translation.Conference_ShowMore} 
        currentUser={user} 
        translation={translation}
        intervenantImage={user}
      />

      {comments.data?.map(item => (
        <Comment
          key={item.id}
          comment={item}
          replies={getReplies(item.id)}
          activeComment={activeComment}
          setActiveComment={setActiveComment}
          deleteComment={deleteComment}
          updateComment={updateComment}
          currentUser={user}
          translation={translation}
        />
      ))}
      {comments.totalItems > commentsLimit && (
        <div className="showML">
          <Button
            onClick={showMore}
            className={`more-comments-button`}
            variant="contained"
          >
            {translation.Conference_SeeMore}
          </Button>
        </div>
      )}
    </div>
  );
};

Comments.propTypes = {
  id: PropTypes.any,
};

export default Comments;
