export const secondsToTime = secs => {
  const hours = Math.floor(secs / (60 * 60)); 
  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);
  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const obj = {
    h: hours < 10 ? "0" + hours : hours,
    m: minutes < 10 ? "0" + minutes : minutes,
    s: seconds < 10 ? "0" + seconds : seconds,
  };
  return obj;
};

export const truncate = (string, length) => string.length >= length ? `${string.substring(0, length)}...` : string;

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const getMonthName = monthNumber => {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', { month: 'short' });
};

export const constructTimeSlotBasedOnAverageWatchTime = timeString => {
  if (timeString !== "00:00:00") {
    const parts = timeString.split(".");
    return parts[0];
  } else {
    return timeString;
  }
};

export const fixDateString = dateString => {
  const dateParts = dateString.split(' ');
  return dateParts[0];
};
