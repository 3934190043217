//jss array export

export default {
    boxStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30em",
        bgcolor: "background.paper",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    },
    boxStyleMobile: {
        position: "relative",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30em",
        pt: 2,
        px: 4,
        pb: 3,
        marginTop: "40%",
        height: "300px",
        overflowY: "auto"
    },
    myButton: {
        border: 0,
        margin: "1em",
        color: "white",
        color: "black",
        "&:hover": {
            backgroundColor: "#514F4F !important",
            color: "white",
        },
    },
}