import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react';
import requests from "../components/axios/Requests.js";
import { getTenant } from "../servicesRedux/GlobalService.js";
import { SetTenantAction } from "../redux/action/TenantAction.js";

// Define a custom hook
export function useReplacer() {
  const tenant = useSelector(state => state.tenantReducer.tenant);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tenant) {
      getTenant(requests.GetTenant).then((item) => {
        dispatch(SetTenantAction(item)); // Make sure SetTenantAction is imported correctly
      });
    }
  }, [tenant, dispatch]);

  // Return a function from the hook for replacing the string
  return (string) => {
    if(!string || string === '' || string === null || string === undefined) return string; // Return the string as is if it is empty or null or undefined
    if (tenant && tenant.fullCustomerName) {
      return string.replace('{FULL_CUSTOMER_NAME}', tenant.fullCustomerName);
    }
    return string; // Return the string as is if tenant or tenant.fullCustomerName is not available
  };
}

export default useReplacer;