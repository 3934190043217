import * as types from "../constant";

export function SetLoadingStatesHomepage(payload) {
    return async (dispatch) => {
        dispatch({ type: types.LOADING_STATES_HOMEPAGE, payload });
    };
}

export function SetLoadingStatesSubscriptions(payload) {
    return async (dispatch) => {
        dispatch({ type: types.LOADING_STATES_SUBSCRIPTIONS, payload });
    };
}