import * as types from "../constant/index";


export default function reducer(

    state = {
        categoryFilter: '',
    },
    action
) {

    switch (action.type) {
        case types.GET_CATEGORY_FILTER:
            return {
                ...state,
                categoryFilter: action.payload,
            };


        default:
            return state;
    }
}